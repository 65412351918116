import EquipInfo from "./EquipInfo";
import DriverInfo from "./DriverInfo";

import "./AssignedInfoCard.sass";
import { Col, Row } from "reactstrap";

const DriverCard = ({ trip }) => {
  return (
    <div className="assigned-info-card">
      <Row>
        <Col lg={trip.coDriverId ? 4 : 6}>
          {/* {trip.unitNumber */}
          <EquipInfo trip={trip} />
        </Col>
        <Col lg={trip.coDriverId ? 4 : 6}>
          {trip.driverId && (
            <DriverInfo
              driverUserId={trip.driverCard.userId || ""}
              firstName={trip.driverFirstName}
              lastName={trip.driverLastName}
              driverNumber={trip?.driverNumber || ""}
              driverId={trip.driverId}
              driverPhone={trip.driverPhone}
            />
          )}
        </Col>
        <Col lg={4}>
          {trip.coDriverId && (
            <DriverInfo
              firstName={trip.coDriverFirstName}
              lastName={trip.coDriverLastName}
              driverNumber={trip?.coDriverNumber || ""}
              driverId={trip.coDriverId}
              driverPhone={trip.coDriverPhone}
              driverUserId={trip.coDriverCard.userId || ""}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DriverCard;
