import TripMap from "components/trip-map/TripMap";
import IPCard from "./IPCard";

import "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/InprogressTrip.sass";

export default function InprogressTrip({ currentTrip, getTrips }) {
  const tripsList = [...currentTrip.stops];
  let inprogFound = false;

  return (
    <div className="trip-display-body-flex">
      <div className="">
        {tripsList.map((stop, index) => {
          let c;
          if (inprogFound) {
            c = "Pending";
          } else if (stop.actualDeparture) {
            c = "Completed";
          } else {
            c = "Inprogress";
            inprogFound = true;
          }
          stop.progress = c;
          return (
            <IPCard
              stop={stop}
              index={index}
              stopLength={tripsList.length}
              getTrips={getTrips}
              key={index}
            />
          );
        })}
      </div>
      {tripsList && <TripMap tripStops={tripsList} currentTrip={currentTrip} />}
    </div>
  );
}
