import { useState } from "react";
import "./MilerSwitch.sass"

const MilerSwitch = ({ value, onChange, disabled, name }) => {

    const [checked, setChecked] = useState(value || false)

    const handleChange = (checked) => {
        setChecked(checked)
        onChange(checked)
    }

    return (
        <div className="custom-control custom-switch">
            <input
                type="checkbox"
                name={name}
                className="custom-control-input"
                id={`${name}`}
                onChange={(e) => handleChange(e.target.checked)}
                checked={checked}
                disabled={disabled}
                data-testid="switch"
                
            />
            <label
                className="custom-control-label"
                htmlFor={`${name}`}
            ></label>
        </div>
    )
}


export default MilerSwitch