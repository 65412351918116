export const digitsToCurrency = (digits) => {
  let a = parseFloat(digits ?? 0);
  let opposite = parseFloat(digits ? digits * -1 : 0);
  const currency =
    digits < 0
      ? `($${opposite.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")})`
      : "$" + a.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return currency;
};

// e.g $23000 to $23k
export const toKCurrencyFormatter = (currencyInDigits) => {
  const formatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    style: "currency",
    // maximumSignificantDigits: 6,
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "USD",
    //roundingIncrement: 2,
    roundingMode: "ceil",
  });
  let convertedCurrency = formatter.format(currencyInDigits ?? 0);
  return convertedCurrency;
};
