import { getCookie } from "./cookies";

export const hasPermission = (permissionName) => {
  if (permissionName.includes("none") || permissionName.includes("undefined")) {
    return true;
  }
  const allPermissions = getCookie("UserPermissions") || [];
  const permission = allPermissions.find(
    (item) => item.name === permissionName
  );
  return permission?.enabled;
};
