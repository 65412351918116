import React from "react";
import { Route, Switch } from "react-router";
import ProtectedRoute from "services/ProtectedRoute";
import routes from "./routes";
import { getCookie } from "utils/cookies";
const NotFoundPage = React.lazy(() => import("../NotFoundPage"));
import FragmentSupportingSwitch from "../FragmentSupportingSwitch";
import { hasPermission } from "utils/hasPermission";

const RoutesRenderer = () => {
  const { subscription_plan, carrierId } = getCookie("userInfo") || {};

  const plans = [
    { short: "unify", long: "Miler Unify (TMS + Maintenance)" },
    { short: "ramify", long: "Miler Ramify (TMS + Maintenance)" },
    { short: "tms", long: "Miler Amplify (TMS)" },
    { short: "mnt", long: "Miler Rectify Plus (Maintenance)" },
    { short: "4pl", long: "Miler 4PL" },
    { short: "free", long: "Miler Rectify (Free)" },
  ];
  const currentPlan = plans.find((p) => p.long === subscription_plan)?.short;
  const carrierInfo = getCookie("carrierInfo") || {};

  return (
    <Switch>
      <React.Suspense fallback="">
        <FragmentSupportingSwitch>
          {routes
            .filter((route) => {
              const permision = Object.keys(route).includes("hasPermission")
                ? route.hasPermission(hasPermission)
                : true; // if hasPermission property is missing just return true
              const hasInCarrier = Object.keys(route).includes("hasInCarrier")
                ? route.hasInCarrier(carrierInfo)
                : true; // if hasInCarrier property is missing just return true
              if (permision && hasInCarrier) {
                if (route.path == "/ediLogs" && carrierId != 2) return false;
                if (route.showFor.includes(currentPlan)) {
                  return route;
                }
              }
            })
            .map((r, i) => (
              <ProtectedRoute
                key={i}
                exact
                path={r.path}
                title={r.title}
                component={r.component}
              />
            ))}
          <Route component={NotFoundPage} />
        </FragmentSupportingSwitch>
      </React.Suspense>
    </Switch>
  );
};

export default RoutesRenderer;
