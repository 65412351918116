export default (state, action) => {
  switch (action.type) {
    case "carrierCharges":
      return {
        ...state,
        carrierCharges: [...action.payload],
        initialCarrierCharges: [...action.payload],
      };
    case "search":
      return {
        ...state,
        searchedValue: action.payload,
      };
    case "setCurrent":
      return {
        ...state,
        current: action.payload,
        initialCurrentCarrierCharges: action.payload,
        selectedApprovedCarrierCharges: [],
      };
    case "setCurrentArId":
      return {
        ...state,
        currentArId: action.payload,
      };
    case "removeProcess":
      return {
        ...state,
        checkedProcessCarrierCharges: state.checkedProcessCarrierCharges.filter(
          (pi) => pi.invoiceId != action.payload.invoiceId
        ),
      };
    case "setChecked":
      return {
        ...state,
        checkedCarrierCharges: state.checkedCarrierCharges.find(
          (cs) => cs.invoiceId === action.payload.invoiceId
        )
          ? state.checkedCarrierCharges.filter(
              (cs) => cs.invoiceId !== action.payload.invoiceId
            )
          : [...state.checkedCarrierCharges, action.payload],
      };
    case "setAllChecked":
      return {
        ...state,
        checkedCarrierCharges:
          state.checkedCarrierCharges.length === action.payload.length
            ? []
            : [...action.payload],
      };
    case "setAllProcessChecked":
      return {
        ...state,
        checkedProcessCarrierCharges: [...action.payload],
      };
    case "resetCurrent":
      return {
        ...state,
        checkedCarrierCharges: [],
      };
    case "resetProcessCurrent":
      return {
        ...state,
        checkedProcessCarrierCharges: [],
      };
    case "try":
      return {
        ...state,
        try: state.try + 1,
      };
    case "filterCarrierCharges":
      let firstMod = state.initialCarrierCharges.filter((item) => {
        return (
          item.accountReceivables.filter((itm) => {
            return (
              itm.invoices.filter((object) =>
                object.shipmentNumber
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
              ).length !== 0
            );
          }).length !== 0
        );
      });
      firstMod = firstMod.map((item) => {
        return {
          ...item,
          accountReceivables: item.accountReceivables.map((itm) => {
            return {
              ...itm,
              invoices: itm.invoices.filter((object) =>
                object.shipmentNumber
                  .toLowerCase()
                  .includes(action.payload.toLowerCase())
              ),
            };
          }),
        };
      });
      let cur = firstMod.find((fm) => fm.shipperId === state.current.shipperId);
      return {
        ...state,
        invoices: [...firstMod],
        current: firstMod.length !== 0 ? (cur ? cur : firstMod[0]) : {},
      };
    case "checkAllProcess":
      let modChecks = state.current.invoices.filter(
        (s) => s.status === "Approved"
      );
      return {
        ...state,
        allApprovedChecked:
          state.selectedApprovedCarrierCharges.length === modChecks.length
            ? false
            : true,
        selectedApprovedCarrierCharges:
          state.selectedApprovedCarrierCharges.length === modChecks.length
            ? []
            : [...modChecks],
        processVerify:
          state.selectedApprovedCarrierCharges.length === modChecks.length
            ? false
            : true,
      };
    case "handleProcessChecked":
      let modAChecks = state.current.invoices.filter(
        (s) => s.status === "Approved"
      );
      const firAP = state.selectedApprovedCarrierCharges.find(
        (cs) => cs.invoiceId === action.payload.invoiceId
      )
        ? state.selectedApprovedCarrierCharges.filter(
            (cs) => cs.invoiceId !== action.payload.invoiceId
          )
        : [...state.selectedApprovedCarrierCharges, action.payload];
      return {
        ...state,
        selectedApprovedCarrierCharges: firAP,
        processVerify: firAP.length !== 0 ? true : false,
        allApprovedChecked:
          state.selectedApprovedCarrierCharges.length === modAChecks.length
            ? false
            : true,
      };
    default:
      return state;
  }
};
