import { useContext } from "react";
import { Navbar, Nav, NavItem, NavLink } from "reactstrap";

import QuickCreate from "layout/toolbar/quickCreate/QuickCreate";
import NotifComponent from "layout/toolbar/notifications/NotifComponent";
import ProfileDropdown from "./profileDropdownn/ProfileDropdown";

// css
import "react-toastify/dist/ReactToastify.css";
import "./ContentToolbar.sass";
import { ToolBarContext } from "./context/ToolbarContext";
import MessagingComponent from "./messaging/MessagingComponent";

const ContentTopbar = () => {
  const { state } = useContext(ToolBarContext);

  return (
    <Navbar
      style={{
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
      }}
      className="navbar content-toolbar"
      expand="xs"
    >
      <div className="content-toolbar-header">
        {state.count !== null ? (
          <div className="content-toolbar-header__name">
            <div className="content-toolbar-header__title">{state.current}</div>
            <div className="content-toolbar-header__search">
              {state.count} Total
            </div>
          </div>
        ) : (
          <div className="content-toolbar-header__name">
            <div className="content-toolbar-header__title">{state.current}</div>
            {/* <div className='content-toolbar-header__search'>
              <span style={{ visibility: "hidden" }}>
                {new Date().toDateString()}
              </span>
            </div> */}
          </div>
        )}
      </div>
      <Nav className="ml-auto navbar__c" navbar>
        <NavItem className="navbar-frights-action">
          <NavLink>
            <QuickCreate />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <MessagingComponent />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <NotifComponent />
          </NavLink>
        </NavItem>
        <NavItem className="navbar-profile-container">
          <Nav className="align-items-center d-md-flex" navbar>
            <ProfileDropdown />
          </Nav>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default ContentTopbar;
