import { useState } from "react";
import classNames from "classnames";
import {
  Collapse,
  NavItem,
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./sidebar.sass";
import { hasPermission } from "utils/hasPermission";
import permissionDeniedToast from "utils/permissionDeniedToast";

const SubMenu = ({
  icon,
  title,
  items,
  flag,
  selectedNav,
  toggleSelectedNav,
  subMenuOpen,
  toggleSubMenu,
  permissionPostFix,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const permissionEnabled = hasPermission(`view.${permissionPostFix}`);

  const opened = subMenuOpen === title ? true : false;
  return (
    <div>
      {flag ? (
        <>
          <NavItem
            className={classNames("sidebar-menu-item-wrapper", {
              "menus-opened": opened,
              "menus-selected": selectedNav.parent === title,
            })}
          >
            <NavLink
              className={classNames("dropdown-toggle sidebar-menu-item w-100", {
                "item-disabled": !permissionEnabled,
              })}
              name={title}
              onClick={(e) => {
                if (permissionEnabled) {
                  toggleSubMenu(e);
                } else {
                  e.preventDefault();
                  permissionDeniedToast();
                }
              }}
            >
              <i className={`${icon} sidebar-menu-item-icon`} />
              {title}

              <i
                className="drop-icon mil-chevron-bg-down"
                onClick={(e) => {
                  if (permissionEnabled) {
                    toggleSubMenu(e);
                  } else {
                    e.preventDefault();
                    permissionDeniedToast();
                  }
                }}
                id={title}
              />
            </NavLink>
          </NavItem>
          <Collapse
            isOpen={opened}
            navbar
            className={classNames("items-menu", { "mb-1": opened })}
          >
            {items.map((item, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    tag={(props) => <Link {...props} />}
                    to={item.target}
                    className={classNames("sidebar-submenu-item", {
                      "selected-nav": selectedNav.nav === item.title,
                      "sub-item-disabled": !hasPermission(
                        `view.${item.permissionPostFix}`
                      ),
                    })}
                    name={item.title}
                    onClick={(e) => {
                      if (hasPermission(`view.${item.permissionPostFix}`)) {
                        toggleSelectedNav(e, title);
                      } else {
                        e.preventDefault();
                        permissionDeniedToast();
                      }
                    }}
                  >
                    {item.title}
                  </NavLink>
                </NavItem>
              );
            })}
          </Collapse>
        </>
      ) : (
        <NavItem
          className={classNames("sidebar-menu-item-wrapper", {
            "menus-selected": selectedNav.parent === title,
          })}
        >
          <Dropdown
            isOpen={!collapsed}
            toggle={toggle}
            className="sidebar-menu-item"
          >
            <DropdownToggle caret>
              <i className={`${icon} sidebar-menu-item-icon`} />
            </DropdownToggle>
            <DropdownMenu>
              {items.map((item, index) => {
                return (
                  <DropdownItem key={index}>
                    <NavItem>
                      <NavLink
                        tag={(props) => <Link {...props} />}
                        to={item.target}
                        className={classNames("sidebar-submenu-item", {
                          "selected-nav": selectedNav.nav === item.title,
                          "sub-item-disabled": !hasPermission(
                            `view.${item.permissionPostFix}`
                          ),
                        })}
                        name={item.title}
                        onClick={(e) => {
                          if (hasPermission(`view.${item.permissionPostFix}`)) {
                            toggleSelectedNav(e, title);
                          } else {
                            e.preventDefault();
                            permissionDeniedToast();
                          }
                        }}
                      >
                        {item.title}
                      </NavLink>
                    </NavItem>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      )}
    </div>
  );
};

export default SubMenu;
