
import "./shipmentTags.sass";

const ShipmentTags = ({ state }) => {
  return (
    <div className="shipment-tags miler-card my-2">
      <p className="shipment-tag-title mb-1 mt-0">Tags</p>
      <div className="tags-container">
        {state?.length > 0 &&
          state?.map((tag, index) => (
            <div className="tag" key={index}>
              <span>{tag.tagName}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShipmentTags;
