import InputMiler from "components/inputMiler/InputMiler";
import MilerSwitch from "components/switch/MilerSwitch";
import { Card, CardBody, CardTitle } from "reactstrap";

const RecurringShipmentFrequency = ({
  type,
  current,
  onChange,
  setCurrent,
}) => {
  const disableEdit = current?.canEdit == false || type === "marketPlace";

  return (
    <Card style={{ marginTop: 10 }} className="p-3">
      <fieldset disabled={disableEdit}>
        <CardBody className="shipment-detail-card">
          <CardTitle className="detail-header">
            <p className="shipment-detail-title">Frequency</p>
            <p className="shipment-detail-subtitle">Shipments per each day</p>
          </CardTitle>

          <div className="row">
            <div className="col col-lg-4 day-name">Monday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="monday"
                value={current?.monday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    monday: cheked,
                    mondayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                name="mondayQuantity"
                type="number"
                min={1}
                value={current?.monday ? current?.mondayQuantity : ""}
                onChange={onChange}
                disabled={!current?.monday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Tuesday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Tuesday"
                value={current?.tuesday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    tuesday: cheked,
                    tuesdayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                name="tuesdayQuantity"
                min={1}
                value={current?.tuesday ? current?.tuesdayQuantity : ""}
                onChange={onChange}
                disabled={!current?.tuesday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Wednesday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Wednesday"
                value={current?.wednesday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    wednesday: cheked,
                    wednesdayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                name="wednesdayQuantity"
                min={1}
                value={current?.wednesday ? current?.wednesdayQuantity : ""}
                onChange={onChange}
                disabled={!current?.wednesday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Thursday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Thursday"
                value={current?.thursday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    thursday: cheked,
                    thursdayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                min={1}
                name="thursdayQuantity"
                value={current?.thursday ? current?.thursdayQuantity : ""}
                onChange={onChange}
                disabled={!current?.thursday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Friday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Friday"
                value={current?.friday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    friday: cheked,
                    fridayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                name="fridayQuantity"
                min={1}
                value={current?.friday ? current?.fridayQuantity : ""}
                onChange={onChange}
                disabled={!current?.friday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Saturday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Saturday"
                value={current?.saturday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    saturday: cheked,
                    saturdayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                name="saturdayQuantity"
                min={1}
                value={current?.saturday ? current?.saturdayQuantity : ""}
                onChange={onChange}
                disabled={!current?.saturday}
              />
            </div>
          </div>

          <div className="row">
            <div className="col col-lg-4 day-name">Sunday</div>
            <div className="col col-lg-4 ">
              <MilerSwitch
                name="Sunday"
                value={current?.sunday}
                onChange={(cheked) => {
                  setCurrent({
                    ...current,
                    sunday: cheked,
                    sundayQuantity: cheked ? 1 : "",
                  });
                }}
              />
            </div>
            <div className="col col-lg-4">
              <InputMiler
                type="number"
                name="sundayQuantity"
                min={1}
                value={current?.sunday ? current?.sundayQuantity : ""}
                onChange={onChange}
                disabled={!current?.sunday}
              />
            </div>
          </div>
        </CardBody>
      </fieldset>
    </Card>
  );
};

export default RecurringShipmentFrequency;
