const expansivePageSearch = async ({
  searchedTerm,
  propName,
  initialList,
  getList,
  request,
  setterCallback,
}) => {
  let { offset } = request;
  let myList = initialList;
  let nextExists = myList?.length > 0;

  let filterFunction = () => {
    let multipleProps = Array.isArray(propName);
    let aa = [];
    if (multipleProps) {
      aa = myList.filter((item) => {
        let exists;
        for (let i = 0; i < propName.length; i++) {
          exists = JSON.stringify(item[propName[i]] || "")
            ?.toLowerCase()
            .includes(searchedTerm.toLowerCase().trim());
          if (exists) break;
        }
        return exists;
      });
    } else {
      aa = myList.filter((item) =>
        JSON.stringify(item[propName] || "")
          ?.toLowerCase()
          .includes(searchedTerm.toLowerCase().trim())
      );
    }
    return aa;
  };
  let filteredList = filterFunction();
  let i = 0;
  while (nextExists && !filteredList?.length && i < 9) {
    offset++;
    myList = await getList(offset);
    nextExists = myList?.length > 0;
    filteredList = filterFunction();
    i++;
  }
  setterCallback(filteredList);
};

export default expansivePageSearch;
