import axios from "axios";
import { getCookie } from "./cookies";

const downloadDocument = (url, isDirectory, customType) => {
  const cachedJwt = getCookie("cachedJwt") || {};
  const { userId, carrierId } = getCookie("userInfo") || {};

  axios({
    url: `https://dev-carrier.miler.com/Document/Download?documentUrl=${url}${
      isDirectory ? "&isDirectory=true" : ""
    }`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${cachedJwt.accessToken}`,
      "X-Miler-UserId": userId,
      "X-Miler-CarrierId": carrierId,
      version: 2,
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    let { type } = response.data;
    let fileType = type.substring(type.lastIndexOf("/") + 1);
    let fileName = url.substring(url.lastIndexOf("/") + 1);
    let fullName = fileName + "." + `${customType ? customType : fileType}`;

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fullName);
    document.body.appendChild(link);
    link.click();
  });
};

export default downloadDocument;
