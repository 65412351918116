import { useEffect, useState } from "react";
import { getElementsV2 } from "services/milerApi";
import {
  digitsToCurrency,
  toKCurrencyFormatter,
} from "utils/currencyFormatter";
import percentDeciConverter from "utils/percentDeciConverter";
import "../../../drivers/driverDetails/overview/Overview.sass";
import Assignment from "../assignment/Assignment";

const Overview = ({ vehicleId, vehicle, getVehicle, getVehicles }) => {
  const [vehicleDetail, setVehicleDetail] = useState();
  const getDashboardDetails = () => {
    getElementsV2({
      url: `/Equipment/Vehicle/${vehicleId}/dashboard`,
      setLoading: null
    }).then((response) =>
      setVehicleDetail(response.data)
    );
  };

  useEffect(getDashboardDetails, []);
  return (
    <div className="driver-overview-cardss">
      <Assignment
        vehicle={vehicle}
        getVehicle={getVehicle}
        getVehicles={getVehicles}
      />
      {vehicleDetail && (
        <>
          <div className="first_card_cont">
            <div className="cardd">
              <div className="title">Trips</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">On Going</div>
                  <div className="val">{vehicleDetail.trips.ongoing}</div>
                </div>
                <div className="itm">
                  <div className="tit">Scheduled</div>
                  <div className="val">{vehicleDetail.trips.scheduled}</div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Completed</div>
                  <div className="val">{vehicleDetail.trips.completed}</div>
                </div>
                <div className="itm">
                  <div className="tit">Total</div>
                  <div className="val">{vehicleDetail.trips.total}</div>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div className="title">Revenue</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last Pay Period</div>
                  <div className="val">
                    {toKCurrencyFormatter(vehicleDetail.revenue.lastPay)}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 4 Weeks</div>
                  <div className="val">
                    {toKCurrencyFormatter(vehicleDetail.revenue.last4Weeks)}
                  </div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last 3 Months</div>
                  <div className="val">
                    {toKCurrencyFormatter(vehicleDetail.revenue.last3Months)}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 12 Months</div>
                  <div className="val">
                    {toKCurrencyFormatter(vehicleDetail.revenue.last12Months)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="first_card_cont">
            <div className="cardd">
              <div className="title">Performance</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Freight Miles</div>
                  <div className="val">
                    {vehicleDetail.performance
                      ? percentDeciConverter(
                          vehicleDetail.performance.freightMiles
                        ) + `%`
                      : 0 + "%"}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Revenue Per Mile</div>
                  <div className="val">
                    {vehicleDetail.performance
                      ? digitsToCurrency(
                          vehicleDetail.performance.revenuePerMile
                        )
                      : `$0.00`}
                  </div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Cost Per Mile</div>
                  <div className="val">
                    {vehicleDetail.performance
                      ? digitsToCurrency(vehicleDetail.performance.costPerMile)
                      : `$0.00`}
                  </div>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div className="title">Maintenance Cost</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last Week</div>
                  <div className="val">
                    {vehicleDetail.maintenanceCost
                      ? toKCurrencyFormatter(
                          vehicleDetail.maintenanceCost.lastPay
                        )
                      : `$0.00`}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 4 Weeks</div>
                  <div className="val">
                    {vehicleDetail.maintenanceCost
                      ? toKCurrencyFormatter(
                          vehicleDetail.maintenanceCost.last4Weeks
                        )
                      : `$0.00`}
                  </div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last 3 Months</div>
                  <div className="val">
                    {vehicleDetail.maintenanceCost
                      ? toKCurrencyFormatter(
                          vehicleDetail.maintenanceCost.last3Months
                        )
                      : `$0.00`}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 12 Months</div>
                  <div className="val">
                    {vehicleDetail.maintenanceCost
                      ? toKCurrencyFormatter(
                          vehicleDetail.maintenanceCost.last12Months
                        )
                      : `$0.00`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
