export default (state, action) => {
  if (action.type === "draftNewServiceProvider") {
    return action.payload;
  }
  if (action.type === "geo") {
    return {
      ...state,
      geolocation: {
        latitude: action.lat,
        longitude: action.lng,
      },
    };
  }
  if (action && action.sameAs) {
    let copiedMailingAddress = JSON.parse(JSON.stringify(state.mailingAddress));
    let copiedMailingContact = JSON.parse(JSON.stringify(state.mailingContact));
    return {
      ...state,
      billingContact: copiedMailingContact,
      billingAddress: copiedMailingAddress,
    };
  } // change all to fetchedProvider
  else if (action && action.all) {
    return Object.assign({ ...state }, { ...action.all });
  } else if (action && action.type && action.category) {
    if (action.type === "billingPhone") {
      if(action.payload){
        return {
          ...state,
          billingContact: {
            ...state.billingContact,
            phones: [
              {
                phoneType: "Day Phone",
                phoneNumber: action.payload,
              },
            ],
          },
        };
      }else{
        return {...state, billingContact:{ ...state.billingContact, phones: []}}
      }
    } else if (action.type === "mailingPhone") {
      if(action.payload){
        return {
          ...state,
          mailingContact: {
            ...state.mailingContact,
            phones: [
              {
                phoneType: "Cell Phone",
                phoneNumber: action.payload,
              },
            ],
          },
        };
      }else{
        return {...state, mailingContact:{ ...state.mailingContact, phones: []}}
      }
    }
    if (action.type === "phoneNumber") {
      let modifiedCategory = JSON.parse(JSON.stringify(state[action.category]));
      modifiedCategory.phones[action.index] = action.value;
      return { ...state, [action.category]: modifiedCategory };
    } else if (action.type === "address") {
      return { ...state, [action.category]: action.value };
    }
    return {
      ...state,
      [action.category]: {
        ...state[action.category],
        [action.type]: action.value,
      },
    };
  } else if (action && action.type && !action.category) {
    return { ...state, [action.type]: action.value };
  } else {
    return { ...state };
  }
};
