import { Card, Row, Col } from "reactstrap";
import "./AllNotification.sass";
import BorderedText from "components/bordered-text/BorderedText";
import dateFormatter from "utils/dateFormater";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";

export default function AllNotification({
  notifications,
  handleDelete,
  setNotificationContent,
}) {
  return (
    <div className="all-notification">
      <Row className="d-flex justify-content-end mb-2">
        <Col xl="1">
          <i
            className="mil-close-bg"
            aria-hidden="true"
            style={{ fontSize: "1.8rem", cursor: "pointer"}}
            onClick={() => setNotificationContent(false)}
          ></i>
        </Col>
      </Row>

      {notifications.map((notif, index) =>{
        if(
        index<20
          ){
           return  <Card key={notif.CarrierNotificationId} className="main-notif-card">
                <div className="avatar-container">
                  <ProfilePlaceholder jointName={notif.Title.charAt(0)} />
                </div>
                <div className="message-body">
                    <span className="notif-title">{notif.Title.trim()}</span>
                    <BorderedText text={notif.Message.trim()} />
                  <div className="notif-date">
                  <BorderedText text={dateFormatter(notif.EventUtc, "MMM DD, YYYY")}/>
                  </div>
                  
                    
                  
                </div>
  
                <div className="trailing-body">
                  <i
                    className="mil-delete-o"
                    aria-hidden="true"
                    onClick={() => handleDelete(notif.CarrierNotificationId)}
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>
              
            </Card>
        }

      }
     
      )}
    </div>
  );
}
