import { createContext, useReducer } from "react";
import toolBarReducer from "./toolbarReducer";

const ToolBarContext = createContext();

const ToolBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toolBarReducer, {
    current: "",
    count: null,
  });

  return (
    <ToolBarContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ToolBarContext.Provider>
  );
};

export { ToolBarContext, ToolBarProvider };
