import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { Label } from "reactstrap";
import { Toast } from "services/Toast";
import { getElements } from "services/milerApi";
import { COLUMNS } from "./CustomeResData";
import SliderModal from "components/sliderModal/SliderModal";
import CustomerRating from "../customerRating/CustomerRating";

const SearchCustomer = ({ state, setState, setManualEntry }) => {
  const [usdotNumber, setUsdotNumber] = useState("");
  const [mcNumber, setMcNumber] = useState("");
  const [inputError, setInputError] = useState("");
  const [customersFound, setCustomersFound] = useState(null);
  const [customerRatingOpen, setCustomerRatingOpen] = useState(false);

  let toastCode;
  const searchCustomer = (search = "USDOT") => {
    let searchType = search;

    if (usdotNumber || mcNumber) {
      setInputError("");
      if (!toastCode) {
        toastCode = Toast.loading("Searching");
      }

      // If DOT is missing - only hit with MC
      if (!usdotNumber) {
        searchType = "MC";
      }

      getElements(
        `/Carrier/Customer/Activation/Search/?${
          searchType == "USDOT"
            ? `dot_number=${usdotNumber}`
            : `mc_number=${mcNumber}`
        }`
      )
        .then((response) => {
          if (response.data.length == 0 && searchType == "USDOT" && mcNumber) {
            searchCustomer("MC");
            return;
          }
          const customers = response.data?.map((carrier) => {
            return {
              ...carrier,
              action: (
                <div className="table-actions">
                  <MilerButton
                    title="Verify"
                    onClick={() => setCustomerRatingOpen(carrier)}
                  />
                  <MilerButton
                    btnDark
                    title="Add"
                    onClick={() => {
                      setState({
                        ...state,
                        ...carrier,
                        shipperName: carrier.company,
                        usdot: carrier.usdot || usdotNumber,
                        mcNumber: carrier.mcNumber || mcNumber,
                      });
                    }}
                  />
                </div>
              ),
            };
          });
          setCustomersFound(customers);
          Toast.close("", toastCode);
        })
        .catch(() => {
          Toast.close("", toastCode);
        });
    } else {
      setInputError("USDOT Number or MC Number is required");
    }
  };
  return (
    <div className="col-12 col-lg-12">
      <div className="miler-card mt-0">
        <div className="row">
          <div className="col col-md-3">
            <InputMiler
              label="USDOT Number"
              type="text"
              value={usdotNumber}
              required
              onChange={(e) => {
                setCustomersFound(null);
                setUsdotNumber(e.target.value);
              }}
              error={inputError}
              maxLength={8}
            />
          </div>
          <div className="col col-md-1 mt-4">OR</div>

          <div className="col col-md-3">
            <InputMiler
              label="MC Number"
              type="text"
              value={mcNumber}
              required
              onChange={(e) => {
                setCustomersFound(null);
                setMcNumber(e.target.value);
              }}
              error={inputError}
              maxLength={8}
            />
          </div>

          <div className="col-12 col-md-4 mt-4">
            <MilerButton
              btnDark
              title="Search Customer"
              onClick={() => searchCustomer()}
            />
          </div>
        </div>
      </div>

      {customersFound?.length > 0 && (
        <>
          <Label>Search Results</Label>
          <div className="summary-list small-table">
            <DataTable
              columns={COLUMNS}
              data={customersFound}
              responsive={true}
            />
          </div>
        </>
      )}

      {customersFound && customersFound.length == 0 ? (
        <div className="miler-card d-flex flex-column align-items-center justify-content-center">
          <Label className="mt-2">No results found.</Label>
          <MilerButton title="Manul Entry" onClick={() => setManualEntry()} />
        </div>
      ) : (
        <></>
      )}

      <SliderModal
        clickState={customerRatingOpen}
        setClickState={setCustomerRatingOpen}
      >
        <CustomerRating
          mcNumber={customerRatingOpen?.mcNumber ?? ""}
          usdotNumber={customerRatingOpen?.usdot ?? ""}
          setCustomerRatingOpen={setCustomerRatingOpen}
        />
      </SliderModal>
    </div>
  );
};

export default SearchCustomer;
