import Select from "react-select";

const Multiselector = ({
  name,
  value,
  disabled,
  error,
  options,
  onChange,
  placeholder,
}) => {
  const handleChange = (selectedOptions) => {
    let values = selectedOptions.map((so) => so.value);
    values = values.join(",");
    const event = {
      target: { value: values },
    };
    onChange(event);
  };

  const valueSetup = value
    ? value.split(",").map((val) => {
        val = val.trim();
        return { value: val, label: val };
      })
    : [];

  const optionSetup = options
    ? options.map((option) => {
        return {
          value: option.keyName || option,
          label: option.keyName || option,
        };
      })
    : [];

  return (
    <>
      <Select
        isMulti={true}
        value={valueSetup}
        isDisabled={disabled}
        name={name}
        options={optionSetup}
        onChange={handleChange}
        placeholder={placeholder || ""}
        hideSelectedOptions
        styles={{
          input: (baseStyles) => ({
            ...baseStyles,
            height: "30px",
            alignItems: "center",
          }),
        }}
      />
      <small
        style={{
          color: "red",
        }}
      >
        {error}
      </small>
    </>
  );
};

export default Multiselector;
