import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import RecurringForm from "./RecurringForm";

const NewCharge = ({ setOpenForm, updateRecurringList }) => {
  const [charge, setCharge] = useState({
    transactionType: "",
    amount: "",
    stopAfterDate: "",
    stopAfterAmount: "",
    emailBeforeLast: false,
    lastAmount: "",
    description: "",
  });

  const [chargeErrors, setChargeErrors] = useState({
    transactionType: "",
    amount: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setCharge({ ...charge, [name]: value });
    setChargeErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const onSave = () => {
    //form input error set-up
    let transErr = charge.transactionType ? "" : "Transactiontype's required";
    let amountErr = charge.amount ? "" : "Amount's required";
    setChargeErrors({ transactionType: transErr, amount: amountErr });

    //run only when there is no errors
    if (!transErr && !amountErr) {
      updateRecurringList({ type: "add", charge });
      setOpenForm(false);
    }
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Charge</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setOpenForm(false);
            }}
          />
          <MilerButton btnDark title="Add" onClick={onSave} />
        </div>
      </div>
      <div className="slider-display-body">
        <RecurringForm
          charge={charge}
          setCharge={setCharge}
          onChange={onChange}
          chargeErrors={chargeErrors}
        />
      </div>
    </div>
  );
};

export default NewCharge;
