import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import Loading from "components/loading/Loading";
import { getElements } from "services/serviceRepairApi";
import ShipmentEvent from "views/shipments/shipmentDetails/shipmentRecentActivity/shipmentEvent/ShipmentEvent";
import FailedToFetch from "components/loading/FailedToFetch";
import './RecentHistory.sass'
const RepairOrderRecentHistory = ({ repairOrderId }) => {
  const [repairOrderHistory, setRepairOrderHistory] = useState([]);

  const [loading, setLoading] = useState(Loading);

  useEffect(() => {
    getElements(`/RepairOrder/${repairOrderId}/History`)
      .then((response) => {
        setRepairOrderHistory(response.data || []);
        setLoading(false);
      })
      .catch((error) => {
        if (error) {
          setLoading(<FailedToFetch />);
        }
      });
  }, []);

  return (
    <main>
      {loading ? (
        <section>{loading}</section>
      ) : (
        <Card>
          <CardBody className="recent-activity-card">
            <CardTitle className="recent-activity-header">
              <p className="recent-activity-title">Recent Activity</p>
            </CardTitle>
            <Row className="recent-activity-body">
              {repairOrderHistory.length > 0 ? <section>
                {repairOrderHistory.map((repairOrder, index) => (
                <ShipmentEvent
                  eventName={repairOrder.eventName}
                  eventUtc={repairOrder.eventUtc}
                  eventBody={repairOrder.eventBody}
                  createdBy={repairOrder.createdBy}
                  key={index}
                />
              ))}
              </section> : <section className="recent_history_no_records">
                <p>There are no records to display</p>
              </section> }
             
            </Row>
          </CardBody>
        </Card>
      )}
    </main>
  );
};

export default RepairOrderRecentHistory;
