const { postApi } = require("./utils/axiosWrapper");
const { env, envEnum } = require("./utils/environment");
import { startSession, inValidateCookies, getCookie } from "utils/cookies";

const idpConfig = {
  baseUrls: {
    local: "https://authentication.miler.com/",
    dev: "https://authentication.miler.com/",
    qa: "https://authentication.miler.com/",
    staging: "https://authentication.miler.com/",
    prod: "https://authentication.miler.com/",
  },
  headers: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "*/*",
    },
  },
  timeout: 12000,
};

export async function getAccessToken(status) {
  var response = null;

  if (status === "REFRESH") {
    inValidateCookies(false);
    const refreshToken = getCookie("refreshToken");

    response = await postApi(
      "/oauth2/token?refresh_token=" +
        refreshToken +
        `&client_id=${getClientId()}&grant_type=refresh_token`,
      idpConfig,
      JSON.stringify({})
    );
  } else {
    inValidateCookies(true);

    let urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");

    if (!code) {
      redirectToLogin();
      //return is important here in order to prevent timeout/error in the try statement in getJwt()
      //while the redictTologin() above happens
      return { redirecting: true };
    }

    response = await postApi(
      "/oauth2/token?code=" +
        (code == null ? "" : code) +
        `&client_id=${getClientId()}&grant_type=authorization_code&redirect_uri=` +
        getRedirectURIOrigin(),
      idpConfig,
      JSON.stringify({})
    );
  }

  if (
    !response ||
    !response.data ||
    !response.data.access_token ||
    !response.data.expires_in
  ) {
    return {
      access_token: "",
      userId: 0,
      expires_in: "",
      token_type: "",
      expiresAt: new Date(),
    };
  }

  //This removes the code search parameter
  let urlParams = new URLSearchParams(window.location.search);

  let code = urlParams.get("code");
  if (window.location.pathname === "/" && code) {
    window.history.replaceState({}, "", getRedirectURIOrigin());
  }

  const expiresAt = new Date(Date.now() + response.data.expires_in * 1000);
  const refreshAt = new Date(
    // Upon user's activity, refresh 20 minutes before token actually expires.
    // User inactivity till the access token expires will force re-authentication
    Date.now() + (response.data.expires_in - 20 * 60) * 1000
  );

  const jwt = {
    accessToken: response.data.access_token,
    expiresIn: response.data.expires_in,
    idToken: response.data.id_token,
    refreshToken: response.data.refresh_token || getCookie("refreshToken"),
    expiresAt,
    refreshAt,
  };

  await startSession(jwt);

  //

  return jwt;
}

// Set a cachedJWT variable and check against it's expiration prior to every api call here. If the jwt has expired, we make a new one.
let cachedJwt = {};

export async function getJwt(status) {
  try {
    cachedJwt = await getAccessToken(status);
    return cachedJwt;
  } catch (ex) {
    if (console) {
    }
    return { authFailed: true };

    //redirectToLogin();
  }
}

export const checkJwtStatus = () => {
  const cachedJwt = getCookie("cachedJwt") || {};
  if (Object.keys(cachedJwt).length) {
    if (cachedJwt.refreshAt > new Date().toISOString()) return "VALID";
    else if (cachedJwt.expiresAt <= new Date().toISOString()) return "EXPIRED";
    else return "REFRESH";
  } else {
    return "MISSING";
  }
};

export async function redirectToLogin() {
  window.location =
    `https://authentication.miler.com/oauth2/authorize?client_id=${getClientId()}&response_type=code&scope=openid&redirect_uri=` +
    getRedirectURIOrigin();
}

export async function logoutRedirect(redirect_url) {
  let url = "https://miler.com";

  if (redirect_url && typeof redirect_url === "string") {
    url = getRedirectURIOrigin() + redirect_url;
  }
  cachedJwt = null;

  inValidateCookies(true);

  window.location = `https://authentication.miler.com/logout?client_id=${getClientId()}&logout_uri=${url}`;
}

export const getRedirectURIOrigin = () => {
  if (env === envEnum.local) return "http://localhost:3000";
  if (env === envEnum.dev) return "https://dev-app.miler.com";

  return "https://app.miler.com";
};

const getClientId = () => {
  if (env === envEnum.local) return "6t1p829eocgul52hmccdgtnm1e";
  if (env === envEnum.dev) return "6t1p829eocgul52hmccdgtnm1e";

  return "52kob42t0cnctte8t2ku1ngah6";
};
