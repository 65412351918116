import { useContext } from "react";
// import StatusValue from "components/filterTypes/StatusValue";
import { appContext } from "services/AppContext";
import FilterDateRange from "components/filterTypes/FilterDateRange";
import usaStates from "utils/usaStates";
import Filters from "components/filterTypes/StatusFilters";
import OnOffFilter from "components/filterTypes/OnOffFilter";

const ShipmentsFilter = ({ request, setRequest }) => {
  const { enums } = useContext(appContext);

  const values = enums.ShipmentStatus.map((x) => x.keyName);
  const states = usaStates;

  const onStatusChanged = (_request) => {
    setRequest({ ..._request, offset: 1 });
  };
  return (
    <div className="util-filter-cont">
      {/* <StatusValue
        request={request}
        setRequest={onStatusChanged}
        values={values}
      /> */}
      <Filters
        request={request}
        setRequest={onStatusChanged}
        title="shipment"
        values={values}
      />
      <FilterDateRange
        request={request}
        setRequest={setRequest}
        title="Pickup"
        fromProp="pickup_from"
        toProp="pickup_to"
        stateProp="pickup"
        states={states}
      />
      <FilterDateRange
        request={request}
        setRequest={setRequest}
        title="Dropoff"
        fromProp="dropoff_from"
        toProp="dropoff_to"
        stateProp="dropoff"
        states={states}
      />
      <OnOffFilter
        label="Overdue"
        on={request?.overdue}
        request={request}
        setRequest={setRequest}
        fromProp="overdue"
      />
    </div>
  );
};

export default ShipmentsFilter;
