import "./listMapToggle.sass";

const ListAndMapToggle = ({ showMap, setShowMap }) => {
  return (
    <div className="list-map-toggle">
      <div className={`${!showMap && "clicked"}`}>
        <i className="mil-menu" onClick={() => setShowMap(false)} />
      </div>
      <div className={`${showMap && "clicked"}`}>
        <i className="mil-location-f" onClick={() => setShowMap(true)} />
      </div>
    </div>
  );
};

export default ListAndMapToggle;
