import {useContext} from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import findError from "utils/findError";

const EquipmentForm = ({ vehicle, setVehicle }) => {
  const { enums } = useContext(appContext);
  let errors = [];

  const onChange = (e) => {
    const { value, name } = e.target;
    setVehicle({ ...vehicle, [name]: value });
  };
  return (
    <>
      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.unitNumber}
          type="text"
          onChange={(e) => onChange(e)}
          name="unitNumber"
          label="Unit Number"
          error={findError("UnitNumber", errors)}
          required
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.make}
          type="text"
          onChange={(e) => onChange(e)}
          name="make"
          label="Make"
          error={findError("Make", errors)}
          required
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.model}
          type="text"
          onChange={(e) => onChange(e)}
          name="model"
          label="Model"
          error={findError("Model", errors)}
          required
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.year}
          type="number"
          onChange={(e) => onChange(e)}
          name="year"
          label="Year"
          error={findError("Year", errors)}
          required
        />
      </div>
      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.unitType}
          type="select"
          options={enums.UnitType}
          onChange={(e) => onChange(e)}
          name="unitType"
          label="Unit Type"
          error={findError("unitType", errors)}
          required
        />
      </div>

      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.vin}
          type="text"
          onChange={(e) => onChange(e)}
          name="vin"
          label="Vin"
          error={findError("vin", errors)}
        />
      </div>

      <div className="col-12 col-lg-6 col-xl-6">
        <InputMiler
          value={vehicle.tireSize}
          type="number"
          onChange={(e) => onChange(e)}
          name="tireSize"
          label="Tire Size"
        />
      </div>
    </>
  );
};

export default EquipmentForm;
