import { useEffect, useState } from "react";
import Stepper from "components/stepper/Stepper";
import MilerButton from "components/milerButton/MilerButton";
import CarrierContacts from "./content/contact/Contact";
import "./onBoarding.sass";
import ProfileDocuments from "views/settings/carrierProfile/profile/ProfileDocuments";
import EldFormContent from "./content/ELD/Eld";
import GetStarted from "./content/getStarted/getStarted";
import OnBoardingCarrierProfile from "./content/profile/OnBoardingCarrierProfile";
import { getGatewayApi } from "services/gatewayApis";
import GoLive from "./content/live/goLive";
import FailedToFetch from "components/loading/FailedToFetch";

const OnBoarding = () => {
  const [currentStep, setCurrentStep] = useState();

  const [onboardingStatus, setOnBoardingSatus] = useState({});

  const [onBoardingLoading, setOnBoardingLoading] = useState(false);

  const [loading, setLoading] = useState();

  // const findStep = (res) => {
  //   let { profile_setup, eld_setup, paperwork_done, contact_setup } = res;

  //   if (!profile_setup && !eld_setup && !paperwork_done && !contact_setup) {
  //     setCurrentStep(0);
  //   } else if (!profile_setup) {
  //     setCurrentStep(1);
  //   } else if (!eld_setup) {
  //     setCurrentStep(4);
  //   } else if (!paperwork_done) {
  //     setCurrentStep(3);
  //   } else if (!contact_setup) {
  //     setCurrentStep(2);
  //   }
  //   setCurrentStep(5);
  // };

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const previousStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const fetchOnBoardingStatus = () => {
    getGatewayApi(`carrier/onboarding`)
      .then((res) => {
        const onboardingStatus = res.data;
        setOnBoardingSatus(onboardingStatus);
        setCurrentStep(0)
        setLoading(false)
      })
      .catch(() => {
        setLoading(<FailedToFetch />);
      });
  };
  const onBoardingSteps = [
    {
      title: "Get Started",
      icon: "",
      content: <GetStarted setCurrentStep={setCurrentStep} />,
      status: "finish",
    },
    {
      title: "Carrier Profile",
      icon: "",
      content: (
        <OnBoardingCarrierProfile setOnBoardingLoading={setOnBoardingLoading} />
      ),
      status:
        currentStep === 1 && !onboardingStatus?.profile_setup
          ? ""
          : !onboardingStatus?.profile_setup
          ? "error"
          : "finish",
    },
    {
      title: (
        <p className={currentStep === 2 && "is_current_step_active"}>
          Carrier Contacts
        </p>
      ),
      content: <CarrierContacts setOnBoardingLoading={setOnBoardingLoading} />,
      status:
        currentStep === 2 && !onboardingStatus?.contact_setup
          ? ""
          : !onboardingStatus?.contact_setup
          ? "error"
          : "finish",
    },
    {
      title: (
        <p className={currentStep === 3 && "is_current_step_active"}>
          Carrier Packet
        </p>
      ),
      content: (
        <section className="">
          <ProfileDocuments
            documentUrl={`/Carrier/CarrierPacket`}
            documentCategory="Company"
            docTitle="Carrier Packet"
            setOnBoardingLoading={setOnBoardingLoading}
          />
        </section>
      ),
      status:
        currentStep === 3 && !onboardingStatus?.paperwork_done
          ? ""
          : !onboardingStatus?.paperwork_done
          ? "error"
          : "finish",
    },
    {
      title: (
        <p className={currentStep === 4 && "is_current_step_active"}>
          ELD Setup
        </p>
      ),
      content: <EldFormContent setOnBoardingLoading={setOnBoardingLoading} />,
      status:
        currentStep === 4 && !onboardingStatus?.eld_setup
          ? ""
          : !onboardingStatus?.eld_setup
          ? "error"
          : "finish",
    },
    {
      title: (
        <p className={currentStep === 5 && "is_current_step_active"}>Go Live</p>
      ),

      content: <GoLive />,
    },
  ];

  useEffect(() => {
    fetchOnBoardingStatus();
  }, []);
  return (
    <main className="main_cont">
      <section className="main">
        <Stepper
          items={onBoardingSteps}
          currentStep={currentStep}
          nextStep={nextStep}
        />

        {loading ? (
          <>{loading}</>
        ) : (
          <>
            {onBoardingSteps[currentStep]?.content}
            {currentStep > 0 && !onBoardingLoading && (
              <section className="stepper_btn">
                <MilerButton onClick={previousStep} title="Back" />

                {currentStep === 5 ? (
                  <></>
                ) : (
                  <MilerButton onClick={nextStep} title="Next" btnDark />
                )}
              </section>
            )}
          </>
        )}
      </section>
    </main>
  );
};

export default OnBoarding;
