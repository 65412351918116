import { useEffect, useState, useReducer } from "react";

import MilerButton from "../../../../components/milerButton/MilerButton";
import ShipmentForm from "../../shipmentForm/ShipmentForm";
import { postElement, getElementsV2 } from "services/milerApi";
import shipmentContract from "views/shipments/shipmentForm/shipmentContract";
import shipmentReducer from "views/shipments/shipmentForm/reducer";

const DuplicateShipment = ({ setClickState, getShipments, id }) => {
  const [iniState, setIniState] = useState(false);

  const [shipment, dispatch] = useReducer(shipmentReducer, shipmentContract);
  const [errors, setFormErrors] = useState([]);
  const postShipment = () => {
    postElement(`/Shipment`, shipment).then((response) => {
      if (response.success) {
        setFormErrors([]);
        getShipments();
        setClickState(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };
  useEffect(() => {
    getElementsV2({
      url: `/Shipment/Id?shipmentId=${id}`,
      setLoading: null
    }).then((response) => {
      let emptyStopTimes = {
        actualArrival: "",
        actualArrivalLocal: "",
        actualDeparture: "",
        actualDepartureLocal: "",
        detentionEndUTC: "",
        detentionStartUTC: "",
        earliest_arrival: "",
        earliest_departure: "",
        latest_arrival: "",
        latest_departure: "",
        status: "Available",
      };
      let shipmentMod = response?.data;

      shipmentMod.stops.sort((a, b) => a.stopSequence - b.stopSequence);
      let shipmentModStop = shipmentMod.stops.map(
        (a) =>
          (a = {
            ...a,
            ...emptyStopTimes,
          })
      );
      shipmentMod.stops = shipmentModStop;
      dispatch({
        type: "initializeDuplicateShipment",
        payload: {
          ...shipmentMod,
          shipmentNumber: "",
          totalCharges: 0,
          charges: [],
          documents: [],
          shipmentId: ""
        },
      });
      setIniState(true);
    });
  }, []);
  // const saveToDraft = () => {
  //   localStorage.setItem("draftShipment", JSON.stringify(shipment));
  //   toast.success("Shipment saved to drafts");
  //   setClickState(false);
  // };
  return (
    shipment !== undefined &&
    iniState && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">Duplicate Shipment</div>
          <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
            <MilerButton
              icon="mil-close-bg"
              title="Cancel"
              onClick={() => {
                setClickState(false);
              }}
            />
            <MilerButton
              btnDark
              icon="mil-save-o"
              title="Save"
              onClick={postShipment}
            />
          </div>
        </div>

        <div className="slider-display-body">
          <ShipmentForm
            shipment={shipment}
            errors={errors}
            dispatch={dispatch}
          />
          <div className="d-flex justify-content-end add-shipment-btn">
            <MilerButton btnDark title="Save" onClick={postShipment} />
          </div>
        </div>
      </div>
    )
  );
};

export default DuplicateShipment;
