export const dateAndTimeObjectify = (value) => {
  if (value === undefined) {
    value = "";
  }
  const _value = value.trim().replace("Z", "");
  let dateTime = { date: "", time: "" };
  let splitArray = [];
  const dateTimeIndentify = (element) => {
    if (element.includes("-")) {
      dateTime.date = element;
    } else if (element.includes(":")) {
      dateTime.time = element;
    }
  };
  if (_value.includes("T")) {
    splitArray = _value.split("T");
  } else {
    splitArray = _value.split(" ");
  }
  splitArray.forEach(dateTimeIndentify);
  return dateTime;
};
