import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";

import AddressBookSearcher from "components/inputMiler/advanceSearch/AddressBookSearcher";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import classNames from "classnames";
import MilerButton from "components/milerButton/MilerButton";
import "./AddressToggle.sass";
import { postElement, putElement } from "services/milerApi";
import { geocodeLocationAddress } from "utils/geocodeLocation";

const Addresstoggle = ({
  address,
  findError,
  index,
  dispatch,
  calcShipmentDistance,
  shipmentType,
}) => {
  const [addressToggle, setAddressToggle] = useState("addressbook");

  useEffect(() => {
    shipmentType === "Intermodal" && setAddressToggle("addressbook");
  }, [shipmentType]);

  const onSearchedItemSelected = async (item) => {
    let address = item?.address || {};
    if (!address.longitude || !address.latitude) {
      //update Lng Lat of Selected Address if they are missing
      address = await geocodeLocationAddress(address.fullAddress).then(
        (res) => {
          return {
            ...address,
            longitude: res.longitude,
            latitude: res.latitude,
            countryCode: res.countryCode,
          };
        }
      );

      putElement(
        `Carrier/AddressBook/Address/${address.addressId}/GeoLocation`,
        address,
        2.0,
        { ignoreToast: true }
      ).then(() => {});
    }
    dispatch({
      type: "All",
      category: "stopAddress",
      index,
      value: address,
    });
    calcShipmentDistance(address);
  };

  const saveToBook = () => {
    const finalAddressPayload = {
      address: {
        ...address,
      },
    };
    postElement(`/Carrier/AddressBook`, finalAddressPayload);
  };

  return (
    <Card className="mx-2 my-2 address-toggle">
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title m-0">LOCATION</p>
          <div className="address-toggle-btn">
            <span
              className={classNames({
                active: addressToggle === "addressbook",
              })}
              onClick={() => setAddressToggle("addressbook")}
            >
              Search Addressbook
            </span>
            {shipmentType !== "Intermodal" && (
              <>
                <span className="separator">/</span>
                <span
                  className={classNames({
                    active: addressToggle === "google",
                  })}
                  onClick={() => {
                    setAddressToggle("google");
                  }}
                >
                  Search Address
                </span>
              </>
            )}
          </div>
        </CardTitle>
        {addressToggle === "addressbook" ? (
          <Row>
            <Col lg="12">
              <AddressBookSearcher
                onItemSelect={onSearchedItemSelected}
                label="Location Name"
                value={address.locationName || ""}
                error={findError(`Stops[${index}].Address.AddressLine`)}
                required
              />
            </Col>
            <Col lg="12">
              <InputMiler
                label="Location"
                disabled
                value={address.fullAddress || ""}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col lg="12">
                <AddressSearcher
                  address={address}
                  label="Location"
                  required
                  dispatch={(newAddress) => {
                    const address = {
                      ...newAddress,
                      locationName: "",
                      addressId: 0,
                    };
                    dispatch({
                      type: "All",
                      category: "stopAddress",
                      index,
                      value: address,
                    });
                    calcShipmentDistance(address);
                  }}
                  error={findError(`Stops[${index}].Address.AddressLine`)}
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-between">
              <InputMiler
                label="Location Name"
                value={address.locationName || ""}
                onChange={(e) =>
                  dispatch({
                    type: "locationName",
                    category: "stopAddress",
                    index,
                    value: e.target.value,
                  })
                }
              />

              <MilerButton
                title="Save To Addressbook"
                btnDark
                onClick={saveToBook}
                tooltip={
                  !address.addressLine || !address.locationName
                    ? "Location name is required"
                    : ""
                }
                disabled={!address.addressLine || !address.locationName}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Addresstoggle;
