import AssignTripToDriver from "components/assignTripToDriver/AssignTripToDriver";
import { useContext } from "react";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";

const AssignTrip = ({ setContent }) => {
  const { currentTrip, getTrips, getTrip } = useContext(ActivityContext);
  const onAssignCallBack = (res) => {
    if (res.success) {
      setContent(false);
      getTrips();
      getTrip(currentTrip.tripId);
    }
  };
  return (
    <AssignTripToDriver
      type="trip"
      setOpenAssign={setContent}
      data={{
        id: currentTrip.tripId,
        shipmentNumber: currentTrip.shipmentNumber,
        shipmentType: currentTrip.shipmentResponse?.shipmentType,
        shipperName: currentTrip.shipmentResponse?.shipperName,
      }}
      onAssignCallBack={onAssignCallBack}
    />
  );
};

export default AssignTrip;
