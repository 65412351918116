import InputMiler from "components/inputMiler/InputMiler";
import { Popover, PopoverBody } from "reactstrap";
import React, { useState, useEffect } from "react";
import MilerButton from "components/milerButton/MilerButton";
import FilterLabelBox from "./filterLabelBox/FilterLabelBox";
import joinStrings from "utils/joinStrings";

const StatusFilters = ({
  request,
  setRequest,
  fromProp,
  toProp,
  stateProp,
  values,
  getList = () => {},
  propName = "status",
  title = "Status",
}) => {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const [_datesRange, setDateRange] = useState({
    [fromProp]: request[fromProp] || "",
    [toProp]: request[toProp] || "",
  });

  const getValue = () => {
    let value = request[propName];
    let selectedEnum = values.find((x) => x.value === value);
    if (selectedEnum) {
      return selectedEnum.label;
    }
    return value;
  };

  const [query, setQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState(getValue());
  const [dedicateValue, setDedicatedValue] = useState("");
  const [referenceValue, setReferenceValue] = useState("");

  useEffect(() => {
    if (anchorEl === false && Object.values(_datesRange).find((i) => i)) {
      setDateRange({ ...request });
    }
  }, [anchorEl]);

  const cancleFilter = () => {
    setQuery("");
    setStatusFilter("");
    setDedicatedValue("");
    setReferenceValue("");
    setRequest({
      ...request,
      shipment_number: "",
      status: "",
      dedicated: "",
      reference_number: "",
    });
  };

  const toggle = () => {
    !values?.length && getList();
    setAnchorEl(!anchorEl);
  };

  const checkValuesExist = () => {
    if (request.dedicated) {
      return "Dedicated";
    } else if (request.dedicated === false) {
      return "Spot";
    } 
    if (
      request[propName] ||
      request.shipment_number ||
      request.reference_number
    ) {
      return true;
    } else return false;
  };

  let id = title.replace(/ /g, "");

  return (
    <>
      <FilterLabelBox
        checkValuesExist={checkValuesExist}
        toggle={toggle}
        cancleFilter={cancleFilter}
        title={title}
        id={id}
        filteredValue={joinStrings(
          [
            statusFilter,
            request.shipment_number,
            request.dedicated === true
              ? "Dedicated"
              : request.dedicated === false
              ? "Spot"
              : "",
            request.reference_number,
          ],
          ", "
        )}
      />

      <Popover placement="bottom" isOpen={anchorEl} target={id} toggle={toggle}>
        <PopoverBody>
          <div className="d-flex flex-column justify-content-center">
            {values && (
              <InputMiler
                value={statusFilter}
                name={stateProp}
                label={"Status"}
                type="select"
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
                options={values.sort() || []}
              />
            )}
            <InputMiler
              type="select"
              options={["Spot", "Dedicated"]}
              label="Revenue Type"
              value={
                dedicateValue === true
                  ? "Dedicated"
                  : dedicateValue === false
                  ? "Spot"
                  : null
              }
              onChange={(e) => {
                const value = e.target.value;
                const isDedicated =
                  value === "Dedicated"
                    ? true
                    : value === "Spot"
                    ? false
                    : null;

                setDedicatedValue(isDedicated);
              }}
            />
            <InputMiler
              narrow
              type="search"
              name="shipment_number"
              value={query}
              label={"Shipment #"}
              placeholder="Shipment#"
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <InputMiler
              type="search"
              name="reference_number"
              value={referenceValue}
              label="Reference #"
              placeholder="Reference #"
              onChange={(e) => {
                setReferenceValue(e.target.value);
              }}
            />

            <MilerButton
              title="Save"
              btnDark
              sm
              onClick={() => {
                setRequest({
                  ...request,
                  shipment_number: query?.trim(),
                  status: statusFilter,
                  reference_number: referenceValue?.trim(),
                  dedicated: dedicateValue,
                });

                setAnchorEl(false);
                toggle();
              }}
            />
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};
export default StatusFilters;
