import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import dateFormatter from "utils/dateFormater";
import "./MessageCard.sass";


const MessageCard = ({ message }) => {
  return (
    <div className="shipment_message_card_container">
      <div className="pic">
        <ProfilePlaceholder
          firstName={message.senderFirstName}
          lastName={message.senderLastName}
        />
      </div>
      <div className="main">
        <div className="title">
          <div className="name">
            {message.senderFirstName} {message.senderLastName}
          </div>
          <div className="dot"></div>
          <div className="status">{message.senderTitle}</div>
        </div>
        <div className="message">{message.message}</div>
        <div className="date_cot">
          <div className="date">
            {dateFormatter(message.messageReceivedUtc, "MMM DD, YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
