import { useContext } from "react";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import InputMiler from "components/inputMiler/InputMiler";
import DriverSearcher from "components/inputMiler/advanceSearch/DriverSearcher";
import { FormGroup, Input, Label } from "reactstrap";
import { appContext } from "services/AppContext";
import findError from "utils/findError";
const DATestsForm = ({
  state,
  dispatch,
  errors,
  driver,
  setTestCategory,
  testCategory,
  drugId,
}) => {
  const { enums } = useContext(appContext);

  return (
    <div>
      <div className="miler-card">
        <h6>General Details</h6>

        <div className="row">
          <div className="col-12 col-md-12">
            {drugId ? (
              <>
                <InputMiler
                  label="Driver"
                  required
                  disabled
                  value={driver?.driverName}
                />
              </>
            ) : (
              <DriverSearcher
                onItemSelect={(item) => {
                  dispatch({ name: "driver", value: item });
                }}
                label="Driver"
                error={findError("driverId", errors)}
                value={driver?.driverName || ""}
                required
              />
            )}
          </div>

          <div className="col-12 col-md-12">
            <InputMiler
              value={testCategory || ""}
              type="select"
              onChange={(e) => setTestCategory(e.target.value)}
              name="testCategory"
              label="Test Category"
              options={["Drug", "Alcohol"]}
              error={findError("testCategory", errors)}
              required
              disabled={drugId}
            />
          </div>
        </div>
      </div>

      <div className="miler-card">
        <h6>Test Details</h6>
        <div className="row">
          <div className="col col-md-6">
            <InputMiler
              value={state?.testType || ""}
              type="select"
              onChange={(e) =>
                dispatch({ name: "testType", value: e.target.value })
              }
              name="testType"
              label="Test Type"
              options={enums?.TestType}
              error={findError("testType", errors)}
              required
            />
          </div>
          <div className="col col-md-6">
            <InputMiler
              value={state?.testNumber || ""}
              type="text"
              onChange={(e) =>
                dispatch({ name: "testNumber", value: e.target.value })
              }
              name="testNumber"
              label="Test Number"
              error={findError("testNumber", errors)}
            />
          </div>
          <div className="col col-md-6">
            <DateTimeInput
              type="date"
              value={state?.orderedUtc}
              onChange={(value) => {
                dispatch({ name: "orderedUtc", value: value });
              }}
              name="orderedUtc"
              label="Ordered Date"
              error={findError("orderedUtc", errors)}
            />
          </div>

          <div className="col col-md-6">
            <DateTimeInput
              type="date"
              value={state?.testUtc}
              onChange={(value) => {
                dispatch({ name: "testUtc", value: value });
              }}
              name="testUtc"
              label="Collection Date"
              error={findError("testUtc", errors)}
              required
            />
          </div>
          <div className="col col-md-6">
            <InputMiler
              value={state?.testSampleType || ""}
              type="select"
              onChange={(e) =>
                dispatch({ name: "testSampleType", value: e.target.value })
              }
              name="testSampleType"
              label="Sample Type"
              options={enums?.TestSampleType}
              error={findError("testSampleType", errors)}
              required
            />
          </div>
          <div className="col-12 col-md-6">
            <InputMiler
              value={state?.status || ""}
              type="select"
              onChange={(e) =>
                dispatch({ name: "status", value: e.target.value })
              }
              name="status"
              label="Test Result"
              options={enums?.TestStatus}
              error={findError("status", errors)}
              required
            />
          </div>
          <div className="col col-md-6">
            <InputMiler
              value={state?.collectionBy || ""}
              type="text"
              onChange={(e) =>
                dispatch({ name: "collectionBy", value: e.target.value })
              }
              name="collectionBy"
              label="Collection By"
              error={findError("collectionBy", errors)}
            />
          </div>

          <div className="col col-md-6">
            <InputMiler
              value={state?.analyzedBy || ""}
              type="text"
              onChange={(e) =>
                dispatch({ name: "analyzedBy", value: e.target.value })
              }
              name="analyzedBy"
              label="Analyzed By"
              error={findError("analyzedBy", errors)}
            />
          </div>

          <div className="col-12 col-md-12">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="chainOfCustody"
                checked={Boolean(state?.chainOfCustody)}
                onChange={(e) =>
                  dispatch({ name: "chainOfCustody", value: e.target.checked })
                }
              />
              <Label for="chainOfCustody" className="shadow-none" check>
                Chain of Custody
              </Label>
            </FormGroup>
          </div>

          <div className="col-12 col-md-12 mt-2">
            <InputMiler
              name="notes"
              label="Notes"
              type="textarea"
              value={state?.notes || ""}
              onChange={(e) =>
                dispatch({ name: "notes", value: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DATestsForm;
