import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";

// if the pic is an avatar placeholder
// pass argument as "placeholder"
// else pass the url of the image
const CellBuilder = (head, foot, classes, pic, hasError) => {
  return (
    <div className="cell-elements">
      {pic === "placeholder" || pic === "placeholder error" ? (
        <ProfilePlaceholder jointName={head} error={Boolean(hasError)} />
      ) : null}
      {/* {pic === "placeholder error" ? (
        <ProfilePlaceholder error={hasError ? true : false} />
      ) : null} */}
      {/*  check if pic is not a placeholder and if its null 
       else supplies the url */}
      {pic !== "placeholder" && pic !== "placeholder error" && pic && (
        <img
          src={pic}
          alt="profile"
          style={{ maxWidth: "56px", maxHeight: "56px" }}
        />
      )}
      <div>
        {head && (
          <span className={`head font-weight-bold ${classes}`}>
            {head ?? ""}
          </span>
        )}

        {foot && <span style={{ color: "#808081" }}>{foot}</span>}
      </div>
    </div>
  );
};

export default CellBuilder;
