import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CarrierBilling from "./billing/Billing";
import CarrierShipments from "./CarrierShipment";
import CarrierProfile from "./Profile/Profile";
import Documents from "./Documents";
import Notes from "./Notes";
const CarrierDetails = ({ setCarrierDetailsOpen, carrier }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="slider-display vehicle-details">
      <div className="slider-display-header">
        <div className="vehicle-details-header">
          <div className="vehicle-details-info">
            <div>
              <div className="vhe-dtls__vehicle-number-status">
                <h3 className="vhe-dtls__vehicle-number">
                  {/* {state?.accidentId} */}
                </h3>
              </div>
              <span className="vhe-dtls__info-description">
                {/* {joinStrings(
                  [state?.driver?.name, state?.accidentNumber],
                  " | "
                )} */}
              </span>
            </div>
            <div className="vehicle-content__header__buttons">
              {["4", "5"].includes(activeTab) ? (
                <>
                  <MilerButton
                    title="Cancel"
                    icon="mil-close-bg"
                    onClick={() => {
                      setCarrierDetailsOpen(false);
                    }}
                  />
                </>
              ) : (
                <>
                  <MilerButton
                    title="Cancel"
                    icon="mil-close-bg"
                    onClick={() => {
                      setCarrierDetailsOpen(false);
                    }}
                  />
                  <MilerButton
                    btnDark
                    icon="mil-save-o"
                    title="Save"
                    onClick={() => {}}
                  />
                </>
              )}
            </div>
          </div>
          <div className="vehicle-content__tabs-container-items vehicle-details-tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Shipments
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Billing
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggle("4");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    toggle("5");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Notes
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>

      <div className="slider-display-body">
        <TabContent activeTab={activeTab}>
          {activeTab === "1" && (
            <TabPane tabId="1" className="mt-3">
              <CarrierProfile carrier={carrier} />
            </TabPane>
          )}

          {activeTab === "2" && (
            <TabPane tabId="2" className="mt-3">
              <CarrierShipments carrierId={carrier.carrierId} />
            </TabPane>
          )}
          {activeTab === "3" && (
            <TabPane tabId="3" className="mt-3">
              <CarrierBilling carrierId={carrier.carrierId} />
            </TabPane>
          )}
          {activeTab === "4" && (
            <TabPane tabId="4" className="mt-3">
              <Documents carrierId={carrier.carrierId} />
            </TabPane>
          )}
          {activeTab === "5" && (
            <TabPane tabId="5" className="mt-3">
              <Notes carrierId={carrier.carrierId} />
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default CarrierDetails;
