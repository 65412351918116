import { useState, useEffect, useContext } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import { getElementsV2, putElement } from "services/milerApi";
const Documents = ({ carrierId }) => {
  const [documents, setDocuments] = useState([]);
  const [loadingDocuments, setLoadingDocuments] = useState(<></>);

  const {
    enums: { DocumentTypeCarrier },
  } = useContext(appContext);

  const getDocuments = (id) => {
    getElementsV2({
      url: `/Provider/Carrier/${id}/Documents`,
      setLoading: setLoadingDocuments,
    }).then((response) => {
      setDocuments(response.data || []);
    });
  };

  const updateDocuments = () => {
    putElement(`/Provider/Carrier/${carrierId}/Documents`, documents).then(
      (response) => {
        if (response.success) {
          getDocuments(carrierId);
        }
      }
    );
  };

  const deleteDocuments = (documents, index) => {
    documents.splice(index, 1);
    setDocuments([...documents]);
    updateDocuments();
    return;
  };

  useEffect(() => {
    if (carrierId) {
      getDocuments(carrierId);
    }
  }, []);
  return (
    <>
      <AssociatedDocuments
        documents={documents || []}
        enums={DocumentTypeCarrier}
        documentCategory="Broker"
        addDoc={(doc) => {
          const docs = documents || [];
          docs.unshift(doc);
          updateDocuments();
          setDocuments(docs);
        }}
        loadingDocuments={loadingDocuments}
        delDoc={(index) => {
          deleteDocuments(documents, index);
        }}
      />
    </>
  );
};

export default Documents;
