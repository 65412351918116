import { useState } from "react";
import { getElementsV2 } from "services/milerApi";
import DriverDetails from "views/fleet/drivers/driverDetails/DriverDetails";
import Loading from "components/loading/Loading";

const DriverEditDetails = ({ driverId, setDriverDetails }) => {
  const [, setLoading] = useState(Loading);
  const [, setDrivers] = useState([]);
  const [fuelId] = useState(0);

  const getDrivers = () => {
    setLoading(Loading);
    getElementsV2({
      url: `/driver`,
      setLoading: setLoading
    })
      .then((response) => {
        setDrivers(response.data || []);
        return response;
      })
      .then(() => {})
      
  };
  return (
    <DriverDetails
      setDetails={setDriverDetails}
      driverId={driverId}
      getDrivers={getDrivers}
      fuelId={fuelId}
    />
  );
};

export default DriverEditDetails;
