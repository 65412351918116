import { useEffect, useState, useContext } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { putElement, getElements } from "services/serviceRepairApi";
import { appContext } from "services/AppContext";
import Loading from "components/loading/Loading";
const Documents = ({ id, repairOrderDocuments, setRepairOrderDocuments }) => {
  const [loadingStatus, setLoadingStatus] = useState({
    loadingRepairOrderDocuments: false,
  });

  const {
    enums: { MaintenanceDocumentType },
  } = useContext(appContext);

  const getRepairOrderDocuments = (id, load) => {
    if (load) {
      setLoadingStatus((prev) => ({
        ...prev,
        loadingRepairOrderDocuments: true,
      }));
    }
    getElements(`/RepairOrder/${id}/Documents`)
      .then((response) => {
        setRepairOrderDocuments(response.data);
        setLoadingStatus((prev) => ({
          ...prev,
          loadingRepairOrderDocuments: false,
        }));
      })
      .catch(() => {
        setLoadingStatus((prev) => ({
          ...prev,
          loadingRepairOrderDocuments: false,
        }));
      });
  };

  const updateRepairOrderDocuments = () => {
    if (id) {
      putElement(`/RepairOrder/${id}/Documents`, repairOrderDocuments)
        .then((response) => {
          if (response.success) {
            getRepairOrderDocuments(id, false);
          }
        })
        .catch(() => {});
    }
  };

  const deleteDocuments = (documents, index) => {
    documents.splice(index, 1);
    setRepairOrderDocuments([...documents]);
    updateRepairOrderDocuments();
    return;
  };

  useEffect(() => {
    if (id) {
      getRepairOrderDocuments(id, true);
    }
  }, [id]);

  const { loadingRepairOrderDocuments } = loadingStatus;
  return (
    <>
      {loadingRepairOrderDocuments ? (
        <section className="mt-4">
          <Loading />
        </section>
      ) : (
        <AssociatedDocuments
          documents={repairOrderDocuments}
          addDoc={(doc) => {
            const docs = repairOrderDocuments || [];
            docs.push(doc);
            updateRepairOrderDocuments();
            setRepairOrderDocuments(docs);
          }}
          delDoc={(index) => {
            deleteDocuments(repairOrderDocuments, index);
          }}
          enums={MaintenanceDocumentType}
          documentCategory="Maintenance"
          loadingRepairOrderDocuments={loadingRepairOrderDocuments}
        />
      )}
    </>
  );
};

export default Documents;
