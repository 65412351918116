import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getElements } from "services/milerApi";
import NewCustomer from "views/customers/newCustomer/NewCustomer";

const CustomerSearchInput = ({
  error,
  value,
  disable,
  onSelect = () => {},
}) => {
  const [openNewPayee, setOpenNewPayee] = useState(false);
  const [_selectedPayee, setSelectedPayee] = useState(value);

  const [customers, setCustomers] = useState([]);

  const onChange = (foundCustomer) => {
    if (foundCustomer) {
      if (foundCustomer.__isNew__) {
        setOpenNewPayee(foundCustomer.label);
        return;
      }
      setSelectedPayee(foundCustomer);

      onSelect(foundCustomer);
    } else {
      //clear input & selected value
      let newValue = {};
      setSelectedPayee(newValue);
      onSelect(newValue);
    }
  };

  const onPayeeCreated = (newCustomer) => {
    onChange(newCustomer);
  };

  const onInputChange = (value) => {
    value &&
      getElements(`/Shipper/Search?name=${value}`).then((response) => {
        setCustomers(
          (response.data || []).map((p) => ({
            ...p,
            label: p.customer_name || "",
          }))
        );
      });
  };

  return (
    <div>
      <label className="m-0">Customer Name</label>
      <span className="required-red">*</span>
      <CreatableSelect
        isClearable={true}
        onChange={onChange}
        onInputChange={onInputChange}
        options={customers}
        value={_selectedPayee}
        isDisabled={disable}
        styles={
          error && {
            control: (provided) => ({
              ...provided,
              borderColor: "red",
            }),
          }
        }
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <SliderModal
        clickState={Boolean(openNewPayee)}
        setClickState={setOpenNewPayee}
      >
        <NewCustomer
          setNewCustomerOpen={setOpenNewPayee}
          fxn={onPayeeCreated}
          customerName={openNewPayee}
        />
      </SliderModal>
    </div>
  );
};

export default CustomerSearchInput;
