import { useContext, useState, useEffect } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { getElementsV2, postElement } from "services/milerApi";
import { appContext } from "services/AppContext";
import InputMiler from "components/inputMiler/InputMiler";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import { getGatewayApi } from "services/gatewayApis";
import calculateStopsDistance from "utils/calculateStopsDistance";
import findError from "utils/findError";
import dayjs from "dayjs";
import axios from "axios";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import { toast } from "react-toastify";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StartTripModal = ({ setIsOpen }) => {
  const { currentTrip, getTrips, getTrip } = useContext(ActivityContext);
  const { enums } = useContext(appContext);
  const [errors, setFormErrors] = useState([]);
  const [offsetHours, setOffsetHours] = useState();
  const [addressType, setAddressType] = useState("1");
  const [emptyMiles, setEmptyMiles] = useState(0);

  let firstStop =
    currentTrip.stops
      .sort((a, b) => a.stopSequence - b.stopSequence)
      .find((x) => x.stopType === "Pickup") || {};

  let defaultStop = {
    stopId: 0,
    stopSequence: 0,
    stopType: "Start",
    address: null,
  };
  const [stop, setStop] = useState(defaultStop);
  const [trailerInfo, setTrailerInfo] = useState({
    trailerNumber: firstStop.trailerNumber || "",
    trailerType: firstStop.trailerType || "",
  });
  const [delayedInfo, setDalayedInfo] = useState({
    reason: "",
    note: "",
  });

  const startAddressSetup = (ad, getOffset) => {
    if (ad) {
      const addressTemplate = {
        state: "",
        countryCode: "US",
        ...ad,
      };

      let address = {
        ...addressTemplate,
        addressLine: ad.addressLine === "" ? "No street" : ad.addressLine,
        city: ad.city === "" ? "No city" : ad.city,
      };

      !getOffset && setOffsetHours(ad.timezoneOffset); //test for daylight hours
      const convertToMlsOrKm = (dist) => {
        setEmptyMiles(dist);
      };
      calculateStopsDistance([{ address }, firstStop], convertToMlsOrKm);
      setStop({
        ...defaultStop,
        address,
      });
    }
  };

  const getVehicleLocation = () => {
    //get latest_location
    if (addressType === "3") {
      setStop(firstStop);
    } else {
      let endpoint;
      if (addressType === "1") {
        endpoint = getElementsV2({
          url: `/Equipment/Vehicle/LastDelivery?unit_number=${currentTrip.unitNumber}`,
          setLoading: null,
        }).then((res) => {
          let address = res?.data?.address;
          return { address };
        });
      } else if (addressType === "2") {
        endpoint = getGatewayApi(
          `carrier/integration/eld/vehicles/location?include=gps&eld_id=${currentTrip.eldId}`
        )
          .then((r) => {
            let vehicle = r.data?.data || [];
            let address = vehicle[0]?.address || {};
            return { address };
          })
          .catch(() => {});
      }
      endpoint &&
        endpoint.then((res) => {
          if (res.address) {
            startAddressSetup(res.address, true);
            getOffsetHours(res.address);
          }
        });
    }
  };

  const onTrailerSelected = (item) => {
    if (item.newEntry) {
      setTrailerInfo({ ...trailerInfo, trailerNumber: item.value });
    } else {
      let items = item.equipment.split(" ");
      setTrailerInfo({
        ...trailerInfo,
        trailerNumber: items[0],
        trailerType: items[1],
        trailerId: item.id,
      });
    }
  };

  const onSubmit = () => {
    let requestBody = { ...stop, ...trailerInfo };
    let _emptyMiles = 0;

    if (addressType === "3") {
      //validate for actualArrival
      if (!stop.actualArrival) {
        toast.error("Actual Arrival is required");
        return;
      }
      //validate for Delayed and reason

      if (
        new Date(stop.latest_arrival?.replace("Z", "")) <
        new Date(stop.actualArrival?.replace("Z", ""))
      ) {
        if (!delayedInfo.note || !delayedInfo.reason) {
          toast.error("Delayed! Please provide Service Failure Reason & Note.");
          return;
        } else {
          let modifiedStopNotes = JSON.parse(
            JSON.stringify(stop.stopNotes || [])
          );
          modifiedStopNotes.push({
            stopNoteType: "ServiceFailure",
            note: delayedInfo.note,
          });
          requestBody = {
            ...requestBody,
            promiseDelayReason: delayedInfo.reason,
            stopNotes: modifiedStopNotes,
          };
        }
      }
    } else {
      _emptyMiles = emptyMiles;
      let submittedDate = dayjs().format("YYYY-MM-DD HH:mm");
      if (Number.isFinite(parseInt(offsetHours || 0))) {
        submittedDate = dayjs()
          .utc()
          .add(offsetHours, "hour")
          .format("YYYY-MM-DD HH:mm");
      }

      requestBody.earliest_arrival = submittedDate;
      requestBody.latest_arrival = submittedDate;
    }

    postElement(
      `/Shipment/Trip/${currentTrip.tripId}/Start?distanceEmpty=${_emptyMiles}`,
      requestBody
    ).then((res) => {
      if (res.success) {
        getTrips();
        getTrip(currentTrip.tripId);
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  };

  useEffect(getVehicleLocation, [addressType]);

  const getOffsetHours = (address) => {
    //NOTE:potential conflict google uses seconds while js Date is in miliseconds
    const { latitude, longitude } = address;
    let request = {
      timestamp: parseInt(Date.now() / 1000),
      location: `${latitude},${longitude}`,
      key: "AIzaSyD-TNuz_N5lhRXSUDeGFokYkOpkgkZ9t6w",
    };

    const queryStr = new URLSearchParams(request).toString();
    var config = {
      method: "get",
      url: `https://maps.googleapis.com/maps/api/timezone/json?${queryStr}`,
      headers: {},
    };

    if (latitude && longitude)
      axios(config)
        .then((res) => {
          if (res.data?.status === "OK") {
            const { dstOffset, rawOffset } = res.data;
            let hoursOffset = parseInt((rawOffset + dstOffset) / 3600);
            setOffsetHours(hoursOffset);
          } else {
            setOffsetHours(0);
          }
        })
        .catch(setOffsetHours(0));
    else setOffsetHours(0);
  };

  const onDelayedInfoChanged = (e) => {
    const { name, value } = e.target;
    setDalayedInfo({ ...delayedInfo, [name]: value });
  };

  const makeOnTime = () => {
    setStop({
      ...stop,
      actualArrival: stop.earliest_arrival,
    });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Start Trip</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setIsOpen(false);
            }}
          />
          <MilerButton title="Start Trip" btnDark onClick={onSubmit} />
        </div>
      </div>
      <div className="slider-display-body">
        <div className="miler-card">
          <div className="row">
            <div className="col col-12 col-lg-7">
              <AddressSearcher
                required
                address={stop.address}
                dispatch={startAddressSetup}
                error={findError(`Address`, errors)}
                disabled={addressType === "3"}
              />
            </div>
            <div className="col col-12 col-lg-5">
              <InputMiler
                label="Start From"
                value={addressType}
                type="select2"
                onChange={(e) => {
                  if (e.target.value === "2" && !currentTrip.eldId) {
                    toast.error("ELD is not setup!");
                  } else setAddressType(e.target.value);
                }}
                options={[
                  { keyName: "Last Dropoff Location", keyValue: 1 },
                  { keyName: "Current Vehicle Location", keyValue: 2 },
                  { keyName: "Pickup Location", keyValue: 3 },
                ]}
              />
            </div>
            <div className="col col-12 col-lg-7">
              <Equipmentsearcher
                onItemSelect={onTrailerSelected}
                label="Trailer Number"
                value={trailerInfo.trailerNumber}
                eqType="Trailer"
                full
              />
            </div>

            <div className="col col-12 col-lg-5">
              <InputMiler
                label="Trailer Type"
                value={trailerInfo.trailerType}
                type="select"
                required
                error={findError(`TrailerType`, errors)}
                options={enums.TrailerType}
                onChange={(e) => {
                  setTrailerInfo({
                    ...trailerInfo,
                    trailerType: e.target.value,
                  });
                }}
              />
            </div>
            {addressType === "3" && (
              <>
                <div className="col col-12 col-lg-7">
                  <DateTimeInput
                    label="Actual Arrival"
                    value={stop.actualArrival}
                    required
                    onChange={(value) => {
                      setStop({
                        ...stop,
                        actualArrival: value,
                      });
                    }}
                  />
                </div>
                <div className="col col-12 col-lg-5 d-flex align-items-center">
                  <MilerButton title="On Time" btnDark onClick={makeOnTime} />
                </div>

                <div></div>
              </>
            )}
          </div>
        </div>
        {addressType === "3" &&
          new Date(stop?.latest_arrival?.replace("Z", "")) <
            new Date(stop?.actualArrival?.replace("Z", "")) && (
            <div className="miler-card">
              <div className="row">
                <div className="col col-12 col-lg-5">
                  <InputMiler
                    type="select"
                    label="Service Failure Reason"
                    name="reason"
                    value={delayedInfo.reason}
                    onChange={onDelayedInfoChanged}
                    options={enums.PromiseDelayReason}
                    error
                    required
                  />
                </div>
                <div className="col col-12 col-lg-7">
                  <InputMiler
                    type="textarea"
                    label="Service Failure Note"
                    name="note"
                    value={delayedInfo.note}
                    onChange={onDelayedInfoChanged}
                    error
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default StartTripModal;
