import CellBuilder from "components/cellBuilderTable/CellBuilder";
import InputMiler from "components/inputMiler/InputMiler";
import SearcheableInput from "components/inputMiler/advanceSearch/SearcheableInput";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import { useContext, useEffect, useState, useReducer } from "react";
import DataTable from "react-data-table-component";
import { appContext } from "services/AppContext";
import { getGatewayApi } from "services/gatewayApis";
import "./Contact.sass";
import classNames from "classnames";
import CarrierReducer from "views/settings/carrierProfile/carrierContext/CarrierReducer";
import { updateCarrierCache } from "utils/updateCarrierCache";
import { putElement, getElements } from "services/milerApi";
import { getCookie } from "utils/cookies";
import dateFormatter from "utils/dateFormater";
import Loading from "components/loading/Loading";

const CarrierContacts = ({ setOnBoardingLoading }) => {
  const { subscription_plan } = getCookie("userInfo") || {};
  const { payFrequency } = getCookie("carrierInfo");


  const { enums } = useContext(appContext);
  const [users, setUsers] = useState([]);
  const [primaryContact, setPrimaryContact] = useState({});
  const [addContactOpen, setAddContactOpen] = useState();

  const [loading, setLoading] = useState(<Loading />);

  const defaultProfile = {
    billingAddress: {},
    mailingAddress: {},
    carrierSettingResponse: {
      factoringCompanyResponse: {},
    },
  };
  const defaultFactoring = {
    billingAddress: {},
    mailingAddress: {},
    mailingContact: {},
    billingContact: {},
  };

  const changePaymentDateFrequency = (payDate, payFrequency, typ) => {
    if (payDate && payFrequency) {
      let getFrequency =
        payFrequency === "Weekly"
          ? [1, "week"]
          : payFrequency === "bi Weekly"
          ? [2, "week"]
          : payFrequency === "Monthly"
          ? [1, "month"]
          : [0, "week"];

      const getNewDate = (a) => {
        let newDate =
          typ === "sub"
            ? dayjs(payDate).subtract(a[0], a[1])
            : dayjs(payDate).add(a[0], a[1]);
        return newDate;
      };

      return getNewDate(getFrequency).format("YYYY-MM-DD");
    }
  };

  const [state, dispatch] = useReducer(CarrierReducer, {
    carrierProfile: defaultProfile,
    factoring: defaultFactoring,
    errors: [],
  });

  const updateCarrier = () => {
    let modCarrierProfile = {
      ...state.carrierProfile,
      carrierSettingRequest: { ...state.carrierProfile.carrierSettingResponse },
    };


    let LastPay = changePaymentDateFrequency(
      dateFormatter(
        state.carrierProfile.carrierSettingResponse.nextPayUtc,
        "YYYY-MM-DD",
        true
      ),
      payFrequency,
      "sub"
    );
    modCarrierProfile = {
      ...modCarrierProfile,
      carrierSettingRequest: {
        ...modCarrierProfile.carrierSettingRequest,
        lastPayUTC: LastPay,
        nextPayUtc: dateFormatter(
          state.carrierProfile.carrierSettingResponse.nextPayUtc,
          "YYYY-MM-DD",
          true
        ),
      },
      companyLogoURL: state.carrierProfile.companyLogoURL,
    };
    delete modCarrierProfile.carrierSettingResponse;
    const url = `/Carrier`;
    putElement(url, modCarrierProfile).then((response) => {
      if (response.success) {
        updateCarrierCache();
        setAddContactOpen(false);
      } else if (response.vErrors) {
        dispatch({ type: "errorResponse", payload: response.vErrors });
        // setFormErrors(response.vErrors);
      }
    });
  };

  const getUsers = () => {
    getGatewayApi("carrier/admin/user/").then((r) => {
      let usersList = r.data.map((user) => ({
        ...user,
        label: user.FirstName + " " + user.LastName,
        value: user.userId,
      }));
      setUsers(usersList);
    });
  };
  const getSingleCarrier = () => {
    setOnBoardingLoading(true);
    let path =
      subscription_plan === "Miler Rectify (Free)" ? "/fleetowner" : "/carrier";
    getElements(path).then((res) => {
      dispatch({ type: "initialCarrierProfile", payload: res.data });
      setLoading(false);
      setOnBoardingLoading(false);
      return res;
    });
    //.catch((err) => setFormErrors(err));
  };
  const addContact = () => {
    dispatch({
      type:
        primaryContact?.index != undefined
          ? "editCarrierContact"
          : "addCarrierContact",
      payload: primaryContact,
    });
    updateCarrier();
  };

  const deleteContact = (dataItem, index) => {
    dispatch({
      type: "removeCarrierContact",
      payload: dataItem,
      itemIndex: index,
    });
    updateCarrier();
  };
  const handleChange = ({ name, value }) => {
    if (name == "selectedUser") {
      setPrimaryContact({
        ...primaryContact,
        firstName: value.FirstName,
        lastName: value.LastName,
        userId: value.userId,
        email: value.Email,
        primaryPhone: value.PhoneNumber,
      });
      return;
    }
    setPrimaryContact({ ...primaryContact, [name]: value });
  };
  let data = state?.carrierProfile.contacts || [];

  data = data.map((dataItem, index) => ({
    ...dataItem,
    actions: (
      <div className="table-actions">
        <i
          className="mil-edit"
          style={{ cursor: "pointer", fontSize: "17px", margin: "5px" }}
          onClick={() => {
            setPrimaryContact({ ...dataItem, index });
            setAddContactOpen(true);
          }}
        />
        {dataItem?.contactType?.toLowerCase() === "primary" ? (
          <></>
        ) : (
          <i
            className="mil-delete-o"
            style={{ cursor: "pointer", fontSize: "17px", margin: "5px" }}
            onClick={() => {
              deleteContact(dataItem, index);
            }}
          />
        )}
      </div>
    ),
  }));

  useEffect(() => {
    getUsers();
    getSingleCarrier();
  }, []);

  const COLUMNS = [
    {
      name: "NAME",
      grow: 1.7,
      selector: (row) => (
        <div className="accident-contact">
          <section className="accident-address-contact-section">
            <span className="accident-address-contact-title">
              {row.firstName} {row.lastName}
            </span>
          </section>
          <p className="accident-contact-form-state">{row.contactType}</p>
        </div>
      ),
    },
    {
      name: "CONTACT INFO",
      grow: 2.5,
      selector: (row) => (
        <div className="accident-contact">
          <section className="accident-address-contact-section">
            <span className="accident-address-contact-title">
              {row.primaryPhone}
            </span>
          </section>
          <p className="accident-contact-form-state">{row.email}</p>
        </div>
      ),
    },
    {
      name: "ACTIONS",
      grow: 1,
      cell: (row) => CellBuilder(row.actions),
      alignRight: true,
    },
  ];

  return (
    <>
      {loading ? (
        <section className="p-2">{loading}</section>
      ) : (
        <div className="miler-card">
          <div className="mt-5" style={{ width: "35%" }}></div>
          <div className="carrier-contacts">
            <div
              className={classNames({
                "no-records": !data?.length,
              })}
            >
              <DataTable data={data} columns={COLUMNS} />

              <div style={{ marginTop: 30 }}>
                <MilerButton
                  title="Add Contact"
                  icon="mil-plus"
                  onClick={() => setAddContactOpen(true)}
                />
              </div>
            </div>
          </div>
          <div></div>

          <SliderModal
            clickState={addContactOpen}
            setClickState={setAddContactOpen}
          >
            <div className="slider-display">
              <div className="slider-display-header">
                <div className="slider-header-title">
                  {primaryContact?.index != undefined
                    ? "Edit Contact"
                    : "New Contact"}
                </div>
                <div className="slider-header-buttons">
                  <MilerButton
                    title="Cancel"
                    onClick={() => {
                      setPrimaryContact({});
                      setAddContactOpen(false);
                    }}
                  />
                  <MilerButton
                    btnDark
                    title="Save"
                    onClick={() => {
                      addContact();
                    }}
                  />
                </div>
              </div>
              <div className="slider-display-body">
                <div className="miler-card">
                  <section className="row">
                    <div className="col col-12 col-xl-8">
                      <InputMiler
                        value={primaryContact?.contactType || ""}
                        type="select"
                        onChange={(e) => {
                          handleChange({
                            name: "contactType",
                            value: e.target.value,
                          });
                        }}
                        name="contactType"
                        label="Contact Type"
                        options={enums.CarrierContactType || []}
                        required
                      />
                    </div>
                  </section>
                  <section className="row">
                    <div className="col-12 col-lg-6">
                      <SearcheableInput
                        label="First Name"
                        placeholder=""
                        required
                        options={users}
                        inputValue={primaryContact.firstName || ""}
                        onSelect={(newValue) => {
                          //Selecting gives full name,
                          handleChange({
                            name: "selectedUser",
                            value: newValue,
                          });
                        }}
                        onTextChange={(e) => {
                          handleChange({
                            name: "firstName",
                            value: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <InputMiler
                        value={primaryContact.lastName || ""}
                        type="text"
                        onChange={(e) => {
                          handleChange({
                            name: "lastName",
                            value: e.target.value,
                          });
                        }}
                        name="lastName"
                        label="Last Name"
                        required
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-12 col-lg-6">
                      <InputMiler
                        value={primaryContact.email || ""}
                        type="text"
                        onChange={(e) => {
                          handleChange({
                            name: "email",
                            value: e.target.value,
                          });
                        }}
                        name="email"
                        label="Email"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <InputMiler
                        value={primaryContact.primaryPhone || ""}
                        type="phone"
                        onChange={(e) => {
                          handleChange({
                            name: "primaryPhone",
                            value: e.target.value,
                          });
                        }}
                        name="primaryPhone"
                        label="Phone Number"
                        autoComplete="off"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </SliderModal>
        </div>
      )}
    </>
  );
};

export default CarrierContacts;
