import { useContext, useState } from "react";
import BorderedText from "components/bordered-text/BorderedText";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import InprogressDispatchForm from "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/inprogressTripCard/InprogressDispatchForm";
import DetentionInfo from "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/inprogressTripCard/DetentionInfo";
import NoteInfo from "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/inprogressTripCard/NoteInfo";
import dateFormatter from "utils/dateFormater";

import { MockContext } from "../ActivityViewerContext";

import "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/inprogressTripCard/IPCard.sass";

const IPCard = ({ stop, index, stopLength }) => {
  const [newSlider, setNewSlider] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editDetaintion, setEditDeteintion] = useState(false);
  const { getTrip, currentTrip } = useContext(MockContext);

  let status;
  const updateTrip = () => getTrip(currentTrip.tripId);

  return (
    <div className="ip_card-container">
      <div className="ip_counter">
        <div
          className={`ip_number ${
            index + 1 === 1
              ? "green"
              : index + 1 === stopLength
              ? "red"
              : "yellow"
          }`}
        >
          {index + 1}
        </div>
        <div className="ip_line" />
        <div className="ip_arrow">&#8897;</div>
      </div>
      <div className="ip_card">
        <div className="ip_header">
          <div className="first">
            <div className="stopType">{stop.stopType}</div>
            <BorderedText text={stop.progress} classes={stop.progress} />
          </div>
          <div className="second">
            {stop.progress === "Inprogress" && (
              <>
                <span style={{ display: "none" }}>
                  {stop.actualArrival
                    ? (status = "Departed")
                    : (status = "Arrived")}
                </span>
                <MilerButton
                  title={stop.actualArrival ? "Depart" : "Arrive"}
                  btnDark
                  sm
                  onClick={() => setNewSlider(true)}
                />
              </>
            )}
            <ElipsisMenu>
              <MenuItem
                title="Add/View Notes"
                onClick={() => setEditNote(true)}
              />
              {!stop.detentionEndUTC &&
                (stop.actualArrival || stop.progress === "Inprogress") &&
                stop.stopType !== "Start" && (
                  <MenuItem
                    title={
                      stop.detentionStartUTC
                        ? "End Detention"
                        : "Start Detention"
                    }
                    onClick={() => setEditDeteintion(true)}
                  />
                )}
            </ElipsisMenu>
          </div>
        </div>

        <div className="ip_address">
          <div className="ip_add_title">Address</div>
          <div className="ip_address_body">
            <div className="ip_add_initial">{stop.address.locationName}</div>
            <div className="ip_address_line">{stop.address.fullAddress}</div>
          </div>
        </div>
        <div className="ip_time">
          <div className="ip_aTime">
            <div className="ip_aTime_title">Scheduled Arrival</div>
            <div className="ip_aTime_date">
              {dateFormatter(stop.earliest_arrival)}
            </div>
          </div>
          <div className="ip_dTime">
            <div className="ip_dTime_title">Scheduled Departure</div>
            <div className="ip_dTime_date">
              {dateFormatter(stop.latest_arrival)}
            </div>
          </div>
        </div>
        {(stop.actualDeparture || stop.actualArrival) && (
          <div className="ip_time">
            <div className="ip_aTime">
              <div className="ip_aTime_title">Actual Arrival</div>
              <div className="ip_aTime_date">
                {dateFormatter(stop.actualArrivalLocal)}
              </div>
            </div>
            <div className="ip_dTime">
              <div className="ip_dTime_title">Actual Departure</div>
              <div className="ip_dTime_date">
                {dateFormatter(stop.actualDepartureLocal)}
              </div>
            </div>
          </div>
        )}
      </div>
      <SliderModal
        clickState={newSlider}
        setClickState={setNewSlider}
        width="40"
      >
        <InprogressDispatchForm
          setClickState={setNewSlider}
          stop={stop}
          updateTrip={updateTrip}
          status={status}
        />
      </SliderModal>

      <SliderModal clickState={editNote} setClickState={setEditNote} width="40">
        <NoteInfo
          setEditNote={setEditNote}
          updateTrip={updateTrip}
          stop={stop}
        />
      </SliderModal>
      <SliderModal
        clickState={editDetaintion}
        setClickState={setEditDeteintion}
        width="40"
      >
        <DetentionInfo
          setEditDeteintion={setEditDeteintion}
          updateTrip={updateTrip}
          stop={stop}
        />
      </SliderModal>
    </div>
  );
};

export default IPCard;
