import Shipments from "views/shipments/Shipments";

const ShipmentRecurring = ({ shipmentId, activeTab }) => {
  const recurringShipemntUrl = `/Shipment/${shipmentId}/Recurring/Shipments`;
  return (
    <section>
      <Shipments
        url={recurringShipemntUrl}
        activeTab={activeTab}
      />
    </section>
  );
};

export default ShipmentRecurring;
