import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import classNames from "classnames";
import "./InputMiler.sass";
import Multiselector from "./Multiselector";

// to use a ref on element use innerRef instead of the normal 'ref'
const InputMiler = ({
  value,
  type,
  onChange,
  onBlur,
  onFocus,
  onKeyUp,
  onPaste,
  options,
  name,
  placeholder,
  label,
  narrow,
  error,
  hidden,
  innerRef,
  required,
  title,
  disabled,
  postfix,
  min,
  max,
  selectType,
  rows,
  cols,
  autoComplete = "off",
}) => {
  return (
    <FormGroup
      className={classNames("input-miler", {
        "d-inline-block": narrow,
        hidden: hidden,
      })}
    >
      {label && (
        <>
          <label>{label}</label>
          {required && <span className="required-red">*</span>}
        </>
      )}
      {type === "select" ? (
        <Input
          type="select"
          name={name}
          title={title}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          invalid={Boolean(error)}
          disabled={disabled}
        >
          <option></option>
          {options.map((item, index) => (
            <option
              value={item.keyName || item}
              key={index}
              selected={selectType === "notes" && item.keyName === "General"}
            >
              {item.keyName ?? item}
            </option>
          ))}
        </Input>
      ) : type === "selectFleet" ? (
        <Input
          type="select"
          name={name}
          title={title}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          invalid={Boolean(error)}
          disabled={disabled}
        >
          <option></option>
          {options?.map((item) => (
            <option value={item.fullName} key={item.userId}>
              {item.fullName}
            </option>
          ))}
        </Input>
      ) : type === "select2" ? (
        <Input
          type="select"
          name={name}
          title={title}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          invalid={Boolean(error)}
          disabled={disabled}
        >
          <option></option>
          {options.map((item, index) => (
            <option value={item.keyValue || item} key={index}>
              {item.keyName ?? item}
            </option>
          ))}
        </Input>
      ) : type === "multiSelect" ? (
        <Multiselector
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          error={error}
          placeholder={placeholder}
          selectType={selectType}
        />
      ) : type === "search" ? (
        <InputGroup
          className="search-field"
          invalid={Boolean(error).toString()}
        >
          <Input
            value={value}
            className={`shadow-none ${disabled && "disabled"}`}
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            onPaste={onPaste}
            title={title}
            name={name}
            placeholder={placeholder ? placeholder : "search"}
            invalid={Boolean(error)}
            disabled={disabled}
            autoComplete={autoComplete}
          ></Input>
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="search-field__search-icon">
              <i className="mil-search" aria-hidden="true"></i>
            </InputGroupText>
          </InputGroupAddon>
          <FormFeedback>{error}</FormFeedback>
        </InputGroup>
      ) : type === "radio" ? (
        <InputGroup className="radio-btn">
          <Input
            value={value}
            className="shadow-none"
            type="radio"
            onChange={onChange}
            onBlur={onBlur}
            title={title}
            name={name}
          ></Input>
        </InputGroup>
      ) : (
        <InputGroup>
          <Input
            value={value}
            type={type}
            onChange={onChange}
            name={name}
            title={title}
            placeholder={placeholder}
            invalid={Boolean(error)}
            innerRef={innerRef}
            disabled={disabled}
            onBlur={onBlur}
            min={min}
            max={max}
            rows={rows}
            cols={cols}
            className={!postfix ? "naked-input" : ""}
            onFocus={onFocus}
            autoComplete={autoComplete}
          />
          {postfix && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText className="input-postfix">
                {postfix}
              </InputGroupText>
            </InputGroupAddon>
          )}

          <FormFeedback>{error}</FormFeedback>
        </InputGroup>
      )}
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

export default InputMiler;
