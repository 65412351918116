import "./filterComp.sass";

const FilterLabelBox = ({
  checkValuesExist,
  toggle,
  cancleFilter,
  title,
  id,
  filteredValue,
}) => {
  return (
    <div
      className={`filter-label-box ${checkValuesExist() ? "is-filtered" : ""}`}
    >
      {checkValuesExist() && (
        <i
          className="mil-close-sm-o close-icon clickable"
          onClick={cancleFilter}
        />
      )}
      <span className="clickable" onClick={toggle} id={id}>
        <span className="filter-title">{title && title.charAt(0).toUpperCase() + title.slice(1)}</span>
        {checkValuesExist() ? (
          <>
            <span className="filter-title">
              {`${filteredValue?.length > 0 ? " | " : ""}`}
            </span>
            <span className="filter-value">{filteredValue}</span>
          </>
        ) : (
          <i className="mil-plus-circle-f add-icon" />
        )}
      </span>
    </div>
  );
};

export default FilterLabelBox;
