import { useEffect, useState } from "react";
import { getElements } from "services/milerApi";
import { toKCurrencyFormatter } from "utils/currencyFormatter";
import "./Overview.sass";

const Overview = ({ shipperId }) => {
  const [overviewDashbaord, setOverviewDashboard] = useState({});

  const getOverviewDashBoard = () => {
    getElements(`/Shipper/${shipperId}/Dashboard`)
      .then((response) => {
        setOverviewDashboard(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getOverviewDashBoard();
  }, []);

  return (
    <div className="driver-overview-cardss">
      <div className="first_card_cont">
        <div className="cardd">
          <div className="title">Shipments this Week</div>
          <div className="itm_cont">
            <div className="itm">
              <div className="val">{overviewDashbaord?.shipments?.total}</div>
              <div className="tit">Total</div>
            </div>
            <div className="itm">
              <div className="val">{overviewDashbaord?.shipments?.ongoing}</div>
              <div className="tit">On Going</div>
            </div>
          </div>
          <div className="itm_cont">
            <div className="itm">
              <div className="val">
                {overviewDashbaord?.shipments?.scheduled}
              </div>
              <div className="tit">Scheduled</div>
            </div>
            <div className="itm">
              <div className="val">
                {overviewDashbaord?.shipments?.completed}
              </div>
              <div className="tit">Completed</div>
            </div>
          </div>
        </div>
        <div className="cardd">
          <div className="title">Settlements</div>
          <div className="itm_cont">
            <div className="itm">
              <div className="val">
                {toKCurrencyFormatter(overviewDashbaord?.settlements?.lastWeek)}
              </div>
              <div className="tit">Last Pay Period</div>
            </div>
            <div className="itm">
              <div className="val">
                {toKCurrencyFormatter(
                  overviewDashbaord?.settlements?.last4Weeks
                )}
              </div>
              <div className="tit">Last 4 Weeks</div>
            </div>
          </div>
          <div className="itm_cont">
            <div className="itm">
              <div className="val">
                {toKCurrencyFormatter(
                  overviewDashbaord?.settlements?.last3Months
                )}
              </div>
              <div className="tit">Last 3 Months</div>
            </div>
            <div className="itm">
              <div className="val">
                {toKCurrencyFormatter(
                  overviewDashbaord?.settlements?.last12Months
                )}
              </div>
              <div className="tit">Last 12 Months</div>
            </div>
          </div>
        </div>
      </div>
      <div className="second_card_cont">
        <div className="cardd">
          <div className="title">Performance</div>
          <div className="itm_cont">
            <div className="itm">
              <div className="val">
                {overviewDashbaord?.performance?.communicationScore}%
              </div>
              <div className="tit">
                Communication
                <br />{" "}
              </div>
            </div>
            <div className="itm">
              <div className="val">
                {overviewDashbaord?.performance?.onTimePickUp}%
              </div>
              <div className="tit">OTP</div>
            </div>
            <div className="itm">
              <div className="val">
                {overviewDashbaord?.performance?.onTimeDelivery}%
              </div>
              <div className="tit">OTD</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
