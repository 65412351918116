import { Marker } from "@react-google-maps/api";

const VehicleTracker = ({ locations }) => {
  return locations.map((location, index) => {
    const { latitude: lat, longitude: lng } = location.address || {};
    const heading = location?.heading_degrees;
    const markerTypes = {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      color: "orange",
    };

    // if (!heading) {
    //   markerTypes.path = window.google.maps.SymbolPath.CIRCLE;
    //   markerTypes.color = "#808080";
    // }

    return (
      <Marker
        key={index}
        options={{
          icon: {
            path: markerTypes.path,
            scale: 5,
            fillColor: markerTypes.color,
            fillOpacity: 0.8,
            strokeWeight: 2,
            strokeColor: "white",
            rotation: heading,
          },
        }}
        title={location.unit_number}
        position={{ lat, lng }}
      />
    );
  });
};

export default VehicleTracker;
