import { useMemo } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import "./CardForm.sass";
import spinner from "assets/settings/spinner.gif";

import useResponsiveFontSize from "./responsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#777",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ onSubmit, paymentLoading }) => {
  const options = useOptions();

  return (
    <form onSubmit={onSubmit} className="iban_form_container">
      <div className="middle">
        <CardElement options={options} />
      </div>
      <div className="desc">
        Your card will not be charged at this time. It will be kept on file for
        future payment when a work order is completed by a service provider. By
        providing your payment information and confirming this payment method,
        you authorize (A) Miler, LLC and Stripe, our payment service provider,
        to send instructions to your bank to debit your account and (B) your
        bank to debit your account in accordance with those instructions. As
        part of your rights, you are entitled to a refund from your bank under
        the terms and conditions of your agreement with your bank. A refund must
        be claimed within 8 weeks starting from the date on which your account
        was debited. Your rights are explained in a statement that you can
        obtain from your bank. You agree to receive notifications for future
        debits up to 2 days before they occur.
      </div>
      <div className="btnn_container">
        <button type="submit" className="btnn">
          {paymentLoading ? (
            <div style={{ width: "65px" }}>
              <img
                src={spinner}
                alt="loading"
                style={{
                  width: "30px",
                  height: "20px",
                }}
              />
            </div>
          ) : (
            "Confirm"
          )}
        </button>
      </div>
    </form>
  );
};

export default CardForm;
