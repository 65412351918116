import AssociatedDocuments from "components/associatedDocumentsMaintaince/AssociatedDocuments";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useContext, useEffect, useState } from "react";
import { appContext } from "services/AppContext";
import PhoneInput from "react-phone-number-input";
import "components/phoneInputFieldStyle/PhoneInput.sass";
import "react-phone-number-input/style.css";
import { getElements } from "services/serviceRepairApi";
import ServiceCodes from "components/maintenance/serviceCodes/ServiceCodes";
import PaymentStripe from "components/carrierStripeCard/Stripe";
import "./ServiceRequestForm.sass";
import EquipComp from "./EquipComp";
import { getGatewayApi } from "services/gatewayApis";
import SliderModal from "components/sliderModal/SliderModal";
import MarkOnMap from "../markOnMap/MarkOnMap";

const ServiceRequestForm = ({
  findError,
  selectedServiceCodes,
  setSelectedServiceCodes,
  phoneNumberValue,
  setPhoneNumberValue,
  locations,
  vehicle,
  setVehicle,
  address,
  setAddress,
  setMapGps,
  serviceLocation,
  setServiceLocation,
  docList,
  posting,
  setPosting,
  addDoc,
  delDoc,
  newVehicle,
  providers,
  setProviders,
  setGps,
  payment,
  setPaymentTrigger,
  setPayment,
  paymentLoading,
  setPaymentLoading,
  errors,
}) => {
  const { enums } = useContext(appContext);
  const [markMapOpen, setMarkMapOpen] = useState(false);

  useEffect(() => {
    getElements("/ServiceProvider")
      .then((response) => {
        let pro = [];
        response.data.map(
          (p) =>
            (pro = [
              ...pro,
              {
                keyName: p.name,
                keyValue: p.serviceProviderId,
              },
            ])
        );
        return pro;
      })
      .then((res) => setProviders(res));
  }, []);

  const getVehicleExtId = (unitNumber) => {
    if (unitNumber) {
      getGatewayApi(
        `carrier/equipment/location?unit_number=${unitNumber}&include=gps`
      ).then(({ data }) => {
        if (data.gps && data.gps[0]) {
          var foundAddress = data.gps[0];
          setGps(foundAddress);
          setMapGps(foundAddress);

          let newAddress = foundAddress?.reverseGeo?.address || {};
          if (Object.keys(newAddress).length) {
            newAddress.countryCode = (
              newAddress.countryCode || ""
            ).toLowerCase();
            newAddress.fullAddress =
              foundAddress.reverseGeo.formattedLocation || "";
          }

          setAddress(newAddress);
        } else {
          setAddress({});
        }
      });
    }
  };

  useEffect(() => {}, [locations]);
  useEffect(() => {
    getVehicleExtId(vehicle.unitNumber);
  }, [vehicle.unitNumber]);

  useEffect(() => {
    if (address.latitude) {
      setGps({
        latitude: address.latitude,
        longitude: address.longitude,
      });
      setMapGps({
        latitude: address.latitude,
        longitude: address.longitude,
      });
    }
  }, [address]);


  return (
    <div className="slider-display-body">
      <div className="miler-card mt-0">
        <div className="miler-card-title">Unit Details</div>
        <div className="row">
          <EquipComp
            vehicle={vehicle}
            setVehicle={setVehicle}
            newVehicle={newVehicle}
            error={errors}
            errorName="UnitNumber"
          />
          <div className="col-12 col-lg-9 col-xl-9">
            <AddressSearcher
              address={address}
              dispatch={(newAddress) => setAddress(newAddress)}
              required
              error={findError("request.Address")}
              // error={"hey"}
            />
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <div className="bynn" style={{ marginTop: "23px" }}>
              <MilerButton
                title="Mark On Map"
                icon="mil-location-marker"
                onClick={() => setMarkMapOpen(true)}
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              name="locationType"
              label="LOCATION TYPE"
              options={enums.LocationType}
              type="select"
              value={serviceLocation.locationType}
              onChange={(e) =>
                setServiceLocation({
                  ...serviceLocation,
                  locationType: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              name="unitDrivable"
              label="UNIT DRIVABLE"
              type="select"
              options={["Yes", "No"]}
              value={serviceLocation.unitDrivable}
              onChange={(e) =>
                setServiceLocation({
                  ...serviceLocation,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              name="serviceRequestedUTC"
              label="SERVICE DATE & TIME"
              type="date"
              value={serviceLocation.serviceRequestedUTC}
              onChange={(e) =>
                setServiceLocation({
                  ...serviceLocation,
                  [e.target.name]: e.target.value,
                })
              }
              required
              error={findError("ServiceRequestedUTC")}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <label className="phone-input-label" htmlFor="phone">
              PHONE NUMBER
            </label>
            <PhoneInput
              className="phone-input"
              defaultCountry="US"
              international
              placeholder="Enter phone number"
              name="phone"
              value={phoneNumberValue}
              onChange={(e) => {
                setServiceLocation({
                  ...serviceLocation,
                  phone: phoneNumberValue,
                });
                setPhoneNumberValue(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="miler-card mt-0">
        <div className="miler-card-title">Service Task</div>
        <ServiceCodes
          setSelectedServiceCodes={setSelectedServiceCodes}
          selectedServiceCodes={selectedServiceCodes}
        />
      </div>

      <div className="miler-card mt-0">
        <div className="miler-card-title">Service Posting</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              name="servicePosting"
              label="Post Service To"
              type="select"
              options={["Market Place", "Specific Vendor"]} //{enums.ServicePosting}
              value={posting.servicePosting}
              disabled
              onChange={(e) =>
                setPosting({
                  note: posting.note,
                  [e.target.name]: e.target.value, //important
                })
              }
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            {posting.servicePosting === "Market Place" && (
              <InputMiler
                name="vendorOption"
                label="Vendor Option"
                type="select"
                error={findError("VendorOption")}
                options={["Dealer", "Any Vendor"]} //{enums.VendorOption}
                value={posting.vendorOption || ""}
                onChange={(e) =>
                  setPosting({
                    servicePosting: posting.servicePosting,
                    note: posting.note,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            )}
            {posting.servicePosting === "Specific Vendor" && (
              <InputMiler
                name="serviceProviderId"
                label="Select Vendor"
                type="select2"
                options={providers}
                value={posting.provider}
                onChange={(e) => {
                  setPosting({
                    servicePosting: posting.servicePosting,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            )}
          </div>
          <div className="col-12 col-lg-12 col-xl-12">
            <InputMiler
              name="note"
              label="Request Notes"
              type="textarea"
              value={posting.note}
              onChange={(e) =>
                setPosting({
                  ...posting,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
        </div>
      </div>
      <AssociatedDocuments
        documents={docList}
        addDoc={addDoc}
        delDoc={delDoc}
        yellowTitle
      />
      {payment.payment_details.isComplete !== true ? (
        <div className="miler-card mt-8">
          <div className="miler-card-title">Card Details</div>
          <PaymentStripe
            payment={payment}
            setPaymentTrigger={setPaymentTrigger}
            paymentTrigger={setPaymentTrigger}
            setPayment={setPayment}
            paymentLoading={paymentLoading}
            setPaymentLoading={setPaymentLoading}
          />
        </div>
      ) : (
        <div className="miler-card mt-8">
          <div className="miler-card-title">Card Details</div>
          <div className="service_card_details_filled_container">
            <div className="first">
              <div className="itm">
                <div className="value">
                  {payment.payment_details.payment.brand} ************
                  {payment.payment_details.payment.last4}
                </div>
              </div>
            </div>
            <div className="second">
              <div className="desc">
                Your card will not be charged at this time. It will be kept on
                file for future payment when a work order is completed by a
                service provider. By providing your payment information and
                confirming this payment method, you authorize (A) Miler, LLC and
                Stripe, our payment service provider, to send instructions to
                your bank to debit your account and (B) your bank to debit your
                account in accordance with those instructions. As part of your
                rights, you are entitled to a refund from your bank under the
                terms and conditions of your agreement with your bank. A refund
                must be claimed within 8 weeks starting from the date on which
                your account was debited. Your rights are explained in a
                statement that you can obtain from your bank. You agree to
                receive notifications for future debits up to 2 days before they
                occur.
              </div>
            </div>
          </div>
        </div>
      )}
      <SliderModal
        clickState={markMapOpen}
        setClickState={setMarkMapOpen}
        from="bottom"
      >
        <MarkOnMap setMarkMapOpen={setMarkMapOpen} address={address} />
      </SliderModal>
    </div>
  );
};

export default ServiceRequestForm;
