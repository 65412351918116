import { useEffect, useState } from "react";
import { getElements } from "services/milerApi";
import BillingSummary from "./BillingSummary";
import BillingHeader from "./BillingHeader";

import "./billing.sass";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";
import dayjs from "dayjs";

const Billing = ({ url }) => {
  let date = new Date();
  const todayMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const [loading, setLoading] = useState(Loading);
  const [billings, setBillings] = useState([]);
  const [paging, setPaging] = useState({});

  const [request, setRequest] = useState({
    fromUtc: dayjs(todayMonth).format("YYYY-MM-DD"),
    toUtc: dayjs().format("YYYY-MM-DD"),
    limit: 50,
    offset: 1,
  });

  const getBillings = () => {
    const { fromUtc, toUtc, limit, offset } = request;
    const queryStr = new URLSearchParams({
      fromUtc,
      toUtc,
      limit,
      offset,
    }).toString();
    getElements(`${url}?${queryStr}`)
      .then((res) => {
        setBillings(res?.data?.data);
        setLoading(false);
        setPaging({
          ...res?.data?.paging,
          received_records: res.data?.data.length,
        });
      })
      .catch(() => setLoading(<FailedToFetch />));
  };

  useEffect(getBillings, [request]);

  return (
    <div className="customer-billing">
      <BillingHeader request={request} setRequest={setRequest} />
      {loading ? (
        loading
      ) : (
        <BillingSummary
          billings={billings}
          paging={paging}
          request={request}
          setRequest={setRequest}
        />
      )}
    </div>
  );
};

export default Billing;
