export const freightClaimReducer = (state, action) => {
  switch (action.action) {
    case "setCurrent":
      return { ...action.value };
    case "claimType":
      return { ...state, claimType: action.value };
    case "shipmentId":
      return { ...state, shipmentId: action.value };
    case "shipmentNumber":
      return { ...state, shipmentNumber: action.value };
    case "sealNumber":
      return { ...state, sealNumber: action.value };
    case "sealIntact":
      return { ...state, sealIntact: action.value };
    case "isInsuranceNotified":
      return { ...state, isInsuranceNotified: action.value };
    case "driverId":
      return { ...state, driverId: action.value };
    case "driverNumber":
      return { ...state, driverNumber: action.value };
    case "vehicleId":
      return { ...state, vehicleId: action.value };
    case "trailerNumber":
      return { ...state, trailerNumber: action.value };
    case "trailerId":
      return { ...state, trailerId: action.value };
    case "driverName":
      return { ...state, driverName: action.value };
    case "unitNumber":
      return { ...state, unitNumber: action.value };
    case "claimUtc":
      return { ...state, claimUtc: action.value };
    case "status":
      return { ...state, status: action.value };
    case "notes":
      return { ...state, notes: action.value };
    case "addCost": {
      const costs = state.costs || [];

      costs.push(action.value);
      return { ...state, costs: costs };
    }
    case "removeCost": {
      const costs = state.costs;
      const index = costs.findIndex(
        (cost) => cost.invoiceNumber == action.value
      );
      costs.splice(index, 1);
      return { ...state, costs: costs };
    }
    case "updateCost": {
      const costs = state.costs;
      const { currentIndex, formValues } = action.value;
      costs.splice(currentIndex, 1, formValues);
      return { ...state, costs: costs };
    }
    case "addDocument": {
      const docs = state.documents || [];
      docs.push(action.value);
      return { ...state, documents: docs };
    }
    case "removeDocument": {
      const docs = state.documents;
      const index = docs.findIndex((doc) => doc.documentUrl == action.value);
      docs.splice(index, 1);
      return { ...state, documents: docs };
    }
    default:
      return { ...state };
  }
};
