import { useContext, useState, useRef, useEffect } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import "./InspectionForm.sass";
import { appContext } from "services/AppContext";
import NoteCard from "./NoteCard";
import dateFormatter from "utils/dateFormater";
const InspectionForm = ({ state, setState, inspectionId }) => {
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const [noteDetails, setNoteDetails] = useState({
    noteId: "",
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsTextareaFocused(false);
        setNoteDetails({
          ...noteDetails,
          noteId: "",
        });
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTextareaFocused]);
  const inputRef = useRef(null);

  const columns = [
    {
      name: "ITEM NAME",
      selector: (row) => row.itemName,
      sortable: true,
      grow: 1,
    },
    {
      name: "ITEM STATUS",
      selector: (row) => row.unitType,
      grow: 1,
      center: true,
      cell: (row) => (
        <div>
          {row.itemStatus === "Pass" ? (
            <section className="item_container">
              <div className="item_pass">
                <span className="txt">Pass</span>
                {row.note && (
                  <i
                    className="mil-message-o"
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      color: "none",
                    }}
                    onClick={() => {
                      setNoteDetails({
                        ...noteDetails,
                        noteId: row.inspectedItemId,
                      });
                      setIsTextareaFocused(true);
                    }}
                  />
                )}
              </div>
              {noteDetails.noteId === row.inspectedItemId && (
                <NoteCard
                  note={row.note}
                  setIsTextareaFocused={setIsTextareaFocused}
                  isTextareaFocused={isTextareaFocused}
                />
              )}
            </section>
          ) : (
            <section className="item_container">
              <div className="item_fail">
                <span className="txt">Fail</span>
                {row.note && (
                  <i
                    className="mil-message-o"
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      color: "none",
                    }}
                    onClick={() => {
                      setNoteDetails({
                        ...noteDetails,
                        noteId: row.inspectedItemId,
                      });
                      setIsTextareaFocused(true);
                    }}
                  />
                )}
              </div>
              {noteDetails.noteId === row.inspectedItemId && (
                <NoteCard
                  note={row.note}
                  setIsTextareaFocused={setIsTextareaFocused}
                  isTextareaFocused={isTextareaFocused}
                />
              )}
            </section>
          )}
        </div>
      ),
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      grow: 1,
    },
  ];

  const { enums } = useContext(appContext);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };
  return (
    <div>
      <Card>
        <CardBody className="shipment-detail-card">
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">VEHICLE INFORMATION</p>
          </CardTitle>
          <Row>
            <Col lg="4">
              <InputMiler
                label="Unit Number"
                name="unitNumber"
                value={state.unitNumber}
                onChange={handleChange}
                disabled={inspectionId}
              />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Unit Type"
                name="unitType"
                value={state.unitType}
                onChange={handleChange}
                disabled={inspectionId}

              />
            </Col>
            <Col lg="4">
              <InputMiler label="Make" name="make" value={state.make} disabled={inspectionId}   />
            </Col>
            <Col lg="4">
              <InputMiler label="Model" name="model" value={state.model}  disabled={inspectionId} />
            </Col>
            <Col lg="4">
              <InputMiler label="VIN" name="vin" value={state.vin}  disabled={inspectionId} />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Engine Type"
                name="engineType"
                value={state.engineType}
                onChange={handleChange}
                disabled={inspectionId} 

              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardBody className="shipment-detail-card">
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">Inspection</p>
          </CardTitle>
          <Row>
            <Col lg="4">
              <InputMiler
                label="Inspector Name"
                name="inspectorName"
                value={state.inspectorName}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Total Charges"
                name="totalCharges"
                value={state.totalCharges}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <Col lg="4">
              <InputMiler
                type="select"
                label="Inspection Status"
                name="inspectionStatus"
                value={state.inspectionStatus}
                options={enums.InspectionStatus}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Created"
                name="createdUtc"
                value={dateFormatter(state.createdUtc, "MMM DD, YYYY")}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Opened"
                name="openedUtc"
                value={state.openedUtc}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <Col lg="4">
              <InputMiler
                label="Closed"
                name="closedUtc"
                value={state.closedUtc}
                onChange={handleChange}
                disabled={inspectionId} 
              />
            </Col>
            <div className="inspection-details-table">
              <DataTable
                columns={columns}
                data={state.inspectionItems}
                responsive={true}
              />
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default InspectionForm;
