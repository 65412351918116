import SearchComp from "./SearchComp";

const DriverSearcher = ({ onItemSelect, label, error, value, placeholder,required }) => {
  const onSelect = (item) => {
    onItemSelect(item);
  };
  const searchResultItem = (item) => {
    const { driver, driverNumber, phone, status, email } = item.driver;
    return (
      <div
        className="advance-search__item container"
        key={item.id}
        onClick={() => onSelect(item)}
      >
        <h5 className="advance-search__item-title row">
          <span>{driver || ""}</span>
          <span style={{ fontWeight: "normal", marginLeft: "10px" }}>
            ({driverNumber || ""})
          </span>
        </h5>
        <div className=" row">{email || ""}</div>
        <div className="row justify-content-between">
          <section className="column">
            <label>Phone: </label>
            <span>{phone || ""}</span>
          </section>
          <section className="column">
            <label>Status: </label>
            <span>{status || ""}</span>
          </section>
        </div>
      </div>
    );
  };
  return (
    <div>
      <SearchComp
        label={label}
        searchResultItem={searchResultItem}
        url={`/Driver/Search?driverNumber`}
        error={error}
        value={value}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default DriverSearcher;
