import MilerButton from "components/milerButton/MilerButton";
import { useContext } from "react";
import "views/accounting/invoices_v2/invoicesDisplay/displayHeader/DisplayHeader.sass";
import { CarrierChargeContext } from "../../carrierChargeContext/CarrierChargeContext";
import { hasPermission } from "utils/hasPermission";

const DisplayHeader = ({ carrierCharge, setNewProcessInvoiceOpen }) => {
  const { state, dispatch } = useContext(CarrierChargeContext);

  return (
    <div className="settlement_display_header">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="first">
          <div className="name">
            {carrierCharge.carrierProfile &&
              carrierCharge.carrierProfile.company}
          </div>
          <div className="desc"></div>
        </div>
        <div className="second">
          <i className="mil-more-vertical" />
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginTop: "10px" }}
      >
        <div className="first_nav"></div>
        <div className="second_nav">
          <>
            {/* {state.current.carrierProfile &&
              state.current.carrierProfile.documentUrl && (
                <MilerButton
                  title="Download All"
                  btnDark
                  onClick={() => {
                    downloadInvoice(
                      state.current.carrierProfile &&
                        state.current.carrierProfile.documentUrl
                    );
                  }}
                />
              )} */}
            <MilerButton
              title="Process"
              btnDark
              onClick={() => {
                dispatch({
                  type: "setAllProcessChecked",
                  payload: [...state.current.charges],
                });
                setNewProcessInvoiceOpen(true);
              }}
              targetActionDenied={!hasPermission("edit.accounting")}
            />
          </>
        </div>
      </div>
    </div>
  );
};

export default DisplayHeader;
