import { useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { digitsToCurrency } from "utils/currencyFormatter";
import classnames from "classnames";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import { chargeContract } from "views/shipments/shipmentForm/shipmentContract";
import "./shipmentCharges.sass";
import ChargeForm from "./ChargeForm";
import CustomerRatingForm from "../customRating/CustomerRating";

const ChargesListRow = ({ state, dispatch, findError, containsErrors }) => {
  const [openForm, setOpenForm] = useState(false);
  const [showCustomerRatingForm, setShowCustomerRatingForm] = useState(false);
  return (
    <Card className="my-2 shipment-charges">
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title">Charges</p>
          <div className="d-flex align-items-center">
            <div className="shipment-detail-title ">
              Total: {digitsToCurrency(state.totalCharges)}
            </div>
          </div>
        </CardTitle>
        {state.charges.length ? (
          <Row style={{ margin: "0 7px" }}>
            <div className="shipment-form-charges">
              <div className="shipment-form-charges-title">
                <div>Type</div>
                <div>Rating</div>
                <div>Rate</div>
                <div>Unit</div>
                <div>Sub Total</div>
                <div>Action</div>
              </div>
              {state.charges.map((charge, index) => (
                <div
                  key={index}
                  className={classnames("shipment-form-charge-stop-item", {
                    "contains-errors": containsErrors(`Charges[${index}]`),
                  })}
                >
                  <div
                    className={`charges-table-row-title shipment-form-charge-stop-item-summary`}
                  >
                    <div>{charge.chargeType}</div>
                    <div>{charge.rating}</div>
                    <div>{digitsToCurrency(charge.rate)}</div>
                    <div>{charge.unit}</div>
                    <div>{digitsToCurrency(charge.subTotal)}</div>
                    <div className="shipment-form-stops-charges-actions">
                      <i
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        className="mil-edit-o"
                        //onClick={() => setOp(!op)}
                        onClick={() => setOpenForm({ _charge: charge, index })}
                      />
                      <i
                        className="mil-delete-o"
                        onClick={() =>
                          dispatch({ category: "deleteCharge", index })
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        ) : (
          <div className="no-charges-cont">
            {findError(`Charges`) ? (
              <div className="charges-error">{findError(`Charges`)}</div>
            ) : (
              <div className="no-charges"> No Charges Added</div>
            )}
          </div>
        )}
        <MilerButton
          title="New Charge"
          icon="mil-plus"
          onClick={() => setOpenForm({ _charge: chargeContract, index: -1 })}
        />

        <MilerButton
          title="Calculate Customer Rating"
          onClick={() => {
            setShowCustomerRatingForm(true);
          }}
        />
      </CardBody>
      <SliderModal
        clickState={Boolean(openForm)}
        setClickState={setOpenForm}
        from="bottom"
      >
        <ChargeForm
          setOpenForm={setOpenForm}
          state={openForm}
          dispatch={dispatch}
          findError={findError}
          shipment={state}
        />
      </SliderModal>
      <SliderModal
        clickState={showCustomerRatingForm}
        setClickState={setShowCustomerRatingForm}
        from="bottom"
        minHeight
      >
        <CustomerRatingForm
          id={state?.shipmentId}
          dispatch={dispatch}
          showCustomerRatingForm={showCustomerRatingForm}
          setShowCustomerRatingForm={setShowCustomerRatingForm}
          shipperId={state.shipperId}
        />
      </SliderModal>
    </Card>
  );
};

export default ChargesListRow;
