import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState, useReducer, useEffect } from "react";
import "./OnBoardingCarrierProfile..sass";
import image from "assets/logo/image.png";
import { postElement, getElements, putElement } from "services/milerApi";
import { getCookie } from "utils/cookies";
import { hasPermission } from "utils/hasPermission";
import CarrierReducer from "views/settings/carrierProfile/carrierContext/CarrierReducer";
import { updateCarrierCache } from "utils/updateCarrierCache";
import dateFormatter from "utils/dateFormater";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";

const OnBoardingCarrierProfile = ({ setOnBoardingLoading }) => {
  const { subscription_plan } = getCookie("userInfo") || {};

  const [loading, setLoading] = useState(<Loading />);
  const defaultProfile = {
    billingAddress: {},
    mailingAddress: {},
    carrierSettingResponse: {
      factoringCompanyResponse: {},
    },
  };
  const defaultFactoring = {
    billingAddress: {},
    mailingAddress: {},
    mailingContact: {},
    billingContact: {},
  };

  const changePaymentDateFrequency = (payDate, payFrequency, typ) => {
    if (payDate && payFrequency) {
      let getFrequency =
        payFrequency === "Weekly"
          ? [1, "week"]
          : payFrequency === "bi Weekly"
          ? [2, "week"]
          : payFrequency === "Monthly"
          ? [1, "month"]
          : [0, "week"];

      const getNewDate = (a) => {
        let newDate =
          typ === "sub"
            ? dayjs(payDate).subtract(a[0], a[1])
            : dayjs(payDate).add(a[0], a[1]);
        return newDate;
      };

      return getNewDate(getFrequency).format("YYYY-MM-DD");
    }
  };

  const [state, dispatch] = useReducer(CarrierReducer, {
    carrierProfile: defaultProfile,
    factoring: defaultFactoring,
    errors: [],
  });

  const updateCarrier = () => {
    let modCarrierProfile = {
      ...state.carrierProfile,
      carrierSettingRequest: { ...state.carrierProfile.carrierSettingResponse },
    };

    const { payFrequency } = getCookie("carrierInfo");

    let LastPay = changePaymentDateFrequency(
      dateFormatter(
        state.carrierProfile.carrierSettingResponse.nextPayUtc,
        "YYYY-MM-DD",
        true
      ),
      payFrequency,
      "sub"
    );
    modCarrierProfile = {
      ...modCarrierProfile,
      carrierSettingRequest: {
        ...modCarrierProfile.carrierSettingRequest,
        lastPayUTC: LastPay,
        nextPayUtc: dateFormatter(
          state.carrierProfile.carrierSettingResponse.nextPayUtc,
          "YYYY-MM-DD",
          true
        ),
      },
      companyLogoURL: state.carrierProfile.companyLogoURL,
    };
    delete modCarrierProfile.carrierSettingResponse;
    const url = `/Carrier`;
    putElement(url, modCarrierProfile).then((response) => {
      if (response.success) {
        updateCarrierCache();
        setAddContactOpen(false);
      } else if (response.vErrors) {
        dispatch({ type: "errorResponse", payload: response.vErrors });
        // setFormErrors(response.vErrors);
      }
    });
  };

  const findError = (inputError) => {
    let error = state?.errors?.find(
      (responseError) =>
        responseError?.key?.toLowerCase() === inputError?.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const getSingleCarrier = () => {
    setOnBoardingLoading(true);
    let path =
      subscription_plan === "Miler Rectify (Free)" ? "/fleetowner" : "/carrier";
    getElements(path)
      .then((res) => {
        dispatch({ type: "initialCarrierProfile", payload: res.data });
        setLoading(false);
        setOnBoardingLoading(false);
        return res;
      })
      .catch(() => {
        setLoading(<FailedToFetch />);
      });
  };
  const [, setSelectedFile] = useState();

  const handleImageChange = (e) => {
    let files = e.target.files;
    const formFile = new FormData();
    formFile.append("Document", files[0]);
    setSelectedFile({ file: formFile });

    postElement("/Document?DocumentCategory=Company", formFile, "", {
      ignoreToast: true,
    }).then((res) => {
      dispatch({ type: "uploadUrl", payload: res.response.data });
    });
  };

  const carrierInfo = getCookie("carrierInfo");

  const { companyLogoURL } = carrierInfo;

  useEffect(() => {
    getSingleCarrier();
  }, []);

  return (
    <>
      {loading ? (
        <section className="mt-3">{loading}</section>
      ) : (
        <section className="carrier_profile_profile_container">
          <div className="car">
            <div className="title_container">
              <h1 className="title">Company Details</h1>
              <p className="desc">
                Update your company information and other details here
              </p>
            </div>
            <div className="cont">
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="Company Name"
                    value={state.carrierProfile.company}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "company",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>

                <section className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="DBA"
                    value={state.carrierProfile.dba}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "dba",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </section>
                <section className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="USDOT"
                    value={state.carrierProfile.usdot}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "usdot",
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    error={findError("usdot")}
                  />
                </section>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="MC Number"
                    value={state.carrierProfile.mcNumber}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "mcNumber",
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    error={findError("mcNumber")}
                  />
                </div>

                <section className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="SCAC"
                    value={state.carrierProfile.scac}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "scac",
                          value: e.target.value,
                        },
                      })
                    }
                    required
                    error={findError("scac")}
                  />
                </section>
                <section className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="EIN"
                    value={state.carrierProfile.ein}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "ein",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </section>

                <section className="col-12">
                  <InputMiler
                    label="Website"
                    value={state.carrierProfile.website}
                    onChange={(e) =>
                      dispatch({
                        type: "changeMainCarrierProfile",
                        payload: {
                          name: "website",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </section>
                <section
                  className="col-12 col-lg-12 col-xl-12"
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {companyLogoURL ? (
                    <img
                      src={companyLogoURL}
                      alt="company_logo"
                      style={{
                        objectFit: "contain",
                        marginLeft: "-20px",
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                    />
                  ) : (
                    <img
                      src={image}
                      alt="company_logo"
                      style={{ marginRight: "16px" }}
                    />
                  )}
                  <div className="upload-btn-wrapper">
                    <MilerButton className="btn" title="Upload logo" />
                    <input
                      type="file"
                      name="myfile"
                      onChange={handleImageChange}
                    />
                  </div>
                  <MilerButton title="Remove" />
                </section>
              </div>
            </div>
          </div>
          <div className="car">
            <div className="title_container">
              <h1 className="title">Mailing Address</h1>
            </div>
            <div className="cont">
              <div>
                <AddressSearcher
                  address={state.carrierProfile.mailingAddress}
                  dispatch={(newAddress) => {
                    dispatch({ type: "mailingAddress", payload: newAddress });
                  }}
                />
              </div>
              <section className="row">
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.mailingAddress.city}
                    type="text"
                    disabled
                    name="city"
                    label="City"
                    required
                  />
                </div>
                <section className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.mailingAddress.state}
                    type="text"
                    disabled
                    name="state"
                    label="State"
                    required
                  />
                </section>
              </section>
              <section className="row">
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.mailingAddress.postalCode}
                    type="text"
                    disabled
                    name="postalCode"
                    label="Postal Code"
                    required
                  />
                </div>
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value="US"
                    type="select"
                    disabled
                    options={["US", "Canada"]}
                    name="countryCode"
                    label="Country"
                    required
                  />
                </div>
              </section>
            </div>
          </div>
          <section className="car">
            <div className="title_container">
              <h1 className="title">Billing Address</h1>
            </div>
            <div className="cont">
              <div className="billing_same">
                <input
                  type="checkbox"
                  className="check_same"
                  onClick={() =>
                    dispatch({
                      type: "billingAddress",
                      payload: state.carrierProfile.mailingAddress,
                    })
                  }
                />
                <div className="txt">Same as mailing address</div>
              </div>
              <div>
                <AddressSearcher
                  address={state.carrierProfile.billingAddress}
                  dispatch={(newAddress) => {
                    dispatch({ type: "billingAddress", payload: newAddress });
                  }}
                />
              </div>
              <section className="row">
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.billingAddress.city}
                    type="text"
                    disabled
                    name="city"
                    label="City"
                    required
                  />
                </div>
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.billingAddress.state}
                    type="text"
                    disabled
                    name="state"
                    label="State"
                    required
                  />
                </div>
              </section>
              <section className="row">
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value={state.carrierProfile.billingAddress.postalCode}
                    type="text"
                    disabled
                    name="postalCode"
                    label="Postal Code"
                    required
                  />
                </div>
                <div className="col col-12 col-xl-6">
                  <InputMiler
                    value="US"
                    type="select"
                    options={["US", "Canada"]}
                    name="countryCode"
                    label="Country"
                    disabled
                    required
                  />
                </div>
              </section>
            </div>
          </section>
          <div className="btm">
            <MilerButton title="Cancel" />
            <MilerButton
              title="Save"
              btnDark
              targetActionDenied={!hasPermission("edit.carrier.info")}
              onClick={() => updateCarrier()}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default OnBoardingCarrierProfile;
