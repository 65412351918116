import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getElements } from "services/milerApi";
import SliderModal from "components/sliderModal/SliderModal";
import NewFreightGroup from "../newFreight/NewFreight";
const FreightSearchInput = ({
  error,
  _selectedFreight,
  setSelectedFreight,
  onChangeFreight,
  freights,
  setFreights,
  value,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const onChange = (foundFreight) => {
    if (foundFreight) {
      setSelectedFreight({
        ..._selectedFreight,
        territoryName: foundFreight.label,
        territoryId: foundFreight.territoryId,
      });
      onChangeFreight(foundFreight.territoryId);
    } else {
      setSelectedFreight({
        ..._selectedFreight,
        territoryName: "",
        territoryId: "",
      });
      onChangeFreight("");
    }
  };

  const getFreights = async () => {
    return getElements(`Carrier/Terriroty`).then((response) => {
      setFreights(
        (response.data || []).map((p) => ({
          ...p,
          label: p.territoryName || "",
        }))
      );
      return response;
    });
  };

  const [freight, setFreight] = useState({
    territoryManager: "",
    territoryName: "",
  });

  useEffect(() => {
    getFreights().then((response) => {
      setSelectedFreight({
        ..._selectedFreight,
        territoryName: response.data.find(
          (el) => el.territoryId === _selectedFreight.territoryId
        )?.territoryName,
      });
    });
  }, []);

  return (
    <div>
      <label className="m-0">Territory</label>
      <CreatableSelect
        isClearable={true}
        onChange={onChange}
        placeholder="Search/Create Territory"
        onCreateOption={(e) => {
          setOpenForm(true);
          setFreight((prev) => ({ ...prev, territoryName: e }));
        }}
        value={value}
        options={freights.map((el) => {
          return { label: el.territoryName, territoryId: el.territoryId };
        })}
        styles={
          error && {
            control: (provided) => ({
              ...provided,
              borderColor: "red",
            }),
          }
        }
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <SliderModal
        clickState={openForm}
        setClickState={setOpenForm}
        from="bottom"
        height={50}
      >
        <NewFreightGroup
          setOpenFreightGroup={setOpenForm}
          setSelectedFreight={setSelectedFreight}
          getFreightGroups={getFreights}
          freight={freight}
          setFreight={setFreight}
        />
      </SliderModal>
    </div>
  );
};

export default FreightSearchInput;
