import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useState } from "react";
import findError from "utils/findError";

export const FuelSurChargeBracketForm = ({ setOpenForm, setData }) => {
  const [errors, setErrors] = useState([]);
  const [formValues, setFormValues] = useState({
    baseFuelPrice: "",
    maxFuelPrice: "",
    priceIncreasePerIncrement: "",
    priceInterval: "",
  });

  const handleInputChange = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value === "" ? "" : parseFloat(value),
    });
    setErrors((prevErrors) =>
      prevErrors.filter((error) => error.key !== field)
    );
  };

  const generateSurchargeBrackets = () => {
    let hasErrors = false;

    if (!formValues.baseFuelPrice) {
      setErrors((prevErrors) => [
        ...prevErrors,
        { key: "baseFuelPrice", errorMessage: "Base Fuel Price is required" },
      ]);
      hasErrors = true;
    }
    if (!formValues.maxFuelPrice) {
      setErrors((prevErrors) => [
        ...prevErrors,
        { key: "maxFuelPrice", errorMessage: "Maximum Fuel Price is required" },
      ]);
      hasErrors = true;
    }
    if (!formValues.priceIncreasePerIncrement) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          key: "priceIncreasePerIncrement",
          errorMessage: "Price Increase per Increment is required",
        },
      ]);
      hasErrors = true;
    }
    if (!formValues.priceInterval) {
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          key: "priceInterval",
          errorMessage: "Price Interval per Increment is required",
        },
      ]);
      hasErrors = true;
    }

    if (hasErrors) return;

    const baseFuelPrice = parseFloat(formValues.baseFuelPrice);
    const maxFuelPrice = parseFloat(formValues.maxFuelPrice);
    const priceIncreasePerIncrement = parseFloat(
      formValues.priceIncreasePerIncrement
    );
    const priceIntervalPerIncrement = parseFloat(formValues.priceInterval);

    const brackets = [];
    let currentStartingPrice = 0;
    let currentEndingPrice = baseFuelPrice;
    let currentSurcharge = 0;

    brackets.push({
      startingPrice: currentStartingPrice.toFixed(2),
      endingPrice: currentEndingPrice.toFixed(2),
      surcharge: currentSurcharge.toFixed(2),
    });

    while (currentEndingPrice + priceIncreasePerIncrement <= maxFuelPrice) {
      if (currentEndingPrice > maxFuelPrice) {
        currentEndingPrice = maxFuelPrice;
      }

      currentStartingPrice = currentEndingPrice;
      currentEndingPrice += priceIncreasePerIncrement;
      currentSurcharge += priceIntervalPerIncrement;

      brackets.push({
        startingPrice: currentStartingPrice.toFixed(2),
        endingPrice: currentEndingPrice.toFixed(2),
        surcharge: currentSurcharge.toFixed(2),
      });
    }

    setData((prev) => ({ ...prev, surcharges: brackets }));
    setOpenForm(false);
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Generate FSC brackets</div>
        <div className="slider-header-buttons">
          <MilerButton title="Close" onClick={() => setOpenForm(false)} />
          <MilerButton
            btnDark
            title="Build"
            onClick={generateSurchargeBrackets}
          />
        </div>
      </div>
      <div className="mb-4"></div>
      <Card className="mb-4 mx-3">
        <CardBody className="shipment-detail-card">
          <Row>
            <Col xs="6">
              <InputMiler
                label="Base Fuel Price"
                type="number"
                value={formValues.baseFuelPrice}
                error={findError("baseFuelPrice", errors)}
                required
                onChange={(e) =>
                  handleInputChange("baseFuelPrice", e.target.value)
                }
              />
            </Col>
            <Col xs="6">
              <InputMiler
                label="Maximum Fuel Price"
                type="number"
                required
                error={findError("maxFuelPrice", errors)}
                value={formValues.maxFuelPrice}
                onChange={(e) =>
                  handleInputChange("maxFuelPrice", e.target.value)
                }
              />
            </Col>
            <Col xs="6">
              <InputMiler
                label="Price Increase per Increment"
                type="number"
                required
                error={findError("priceIncreasePerIncrement", errors)}
                value={formValues.priceIncreasePerIncrement}
                onChange={(e) =>
                  handleInputChange("priceIncreasePerIncrement", e.target.value)
                }
              />
            </Col>
            <Col xs="6">
              <InputMiler
                label="Price Interval per Increment"
                type="number"
                required
                error={findError("priceInterval", errors)}
                value={formValues.priceInterval}
                onChange={(e) =>
                  handleInputChange("priceInterval", e.target.value)
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
