import Shipments from "views/shipments/Shipments";

const CustomerShipments = ({ shipperId }) => {
  return (
    <div>
      <Shipments url={`/Shipper/${shipperId}/Shipment`} useAsUtil />
    </div>
  );
};

export default CustomerShipments;
