import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { columns } from "./shipmentsData";
import BorderedText from "components/bordered-text/BorderedText";
import CustomPagination from "components/custom-pagination/CustomPagination";
import { digitsToCurrency } from "utils/currencyFormatter";
import ShipmentHeader from "./ShipmentHeader";
import {
  deleteElement,
  getElementsV2,
  postElement,
  putElement,
} from "services/milerApi";
import DuplicateShipment from "../shipmentDetails/duplicateShipment/DuplicateShipment";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import StickySlider from "components/sliderModal/StickySlider";
import dateFormater from "utils/dateFormater";
import MapsMiler from "components/mapMiler/MapsMiler";
import joinStrings from "utils/joinStrings";
import RecurringShipment from "../recurringShipment/RecurringShipment";
import UndoTripModal from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/UndoTripModal";
import NewFreightClaim from "views/compliance/freightClaims/newFreightClaim/NewFreightClaim";
import SliderModal from "components/sliderModal/SliderModal";
import { getCookie } from "utils/cookies";
import { hasPermission } from "utils/hasPermission";
import expansivePageSearch from "utils/expansivePageSearch";
import useDebounce from "hooks/useDebounce";
import getShipmentStatusColor from "../getShipmentStatusColor";
import ShipmentCharge from "../ShipmentCharge";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import AssignTripToDriver from "components/assignTripToDriver/AssignTripToDriver";
import { Toast } from "services/Toast";
import UnassignTripModal from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/UnassignTripModal";
import ExpandedContent from "./ExpanadableContent";

import "./ShipmentSummary.sass";
import ManifestShipment from "../shipmentDetails/duplicateShipment/ManifestShipment";
import { VscCombine } from "react-icons/vsc";

const Shipments = ({
  nextExists,
  shipmentsSummaryList,
  setShipmentContent,
  getShipments,
  setShipId,
  setRequest,
  request,
  loading,
  setLoading,
  useAsUtil,
  activeTab,
}) => {
  const { isMetric } = getCookie("carrierInfo");
  const [showMap, setShowMap] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [filteredShipment, setFilteredShipments] = useState(
    shipmentsSummaryList
  );

  const [duplicateShipmentOpen, setDuplicateShipmentOpen] = useState(false);
  const [currentDuplicateId, setCurrentDuplicateId] = useState(0);
  const [recurringShipmentOpen, setRecurringShipmentOpen] = useState(false);
  const [freightClaimOpen, setFreightClaimOpen] = useState(false);
  const [assignShipmentOpen, setAssignShipmentOpen] = useState(false);
  const [shipment, setShipment] = useState({});
  const [, setAction] = useState(false);
  const [openUndoModal, setOpenUndoModal] = useState(false);
  const [openUnAssignModal, setOpenUnAssignModal] = useState(false);
  const [manifest, setManifest] = useState(false);

  const [openCharge, setOpenCharge] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [shipmentId, setShipmeentId] = useState("");
  const duplicateShipment = (dupId) => {
    setCurrentDuplicateId(dupId);
    setDuplicateShipmentOpen(true);
  };

  const [openRecurringDropDown, setOpenRecurringDropDown] = useState(false);
  const [rowId, setRowId] = useState();

  const [recurringshipments, setRecurringShipments] = useState([]);
  const fetchRecurringShipments = (shipmentId) => {
    const findShipment = shipmentsSummaryList.find(
      (shipment) => shipment.shipmentId === shipmentId
    );

    setRecurringShipments(findShipment?.orders || []);
  };

  const [expandedRows, setExpandedRows] = useState([]);
  const handleRowClick = (row) => {
    if (row.isManifest) {
      const rowIndex = expandedRows.indexOf(row.shipmentId);

      if (rowIndex > -1) {
        // Row is expanded, so remove it from the array

        setOpenRecurringDropDown(false);

        setExpandedRows(expandedRows.filter((id) => id !== row.shipmentId));
      } else {
        // Row is not expanded, so add it to the array
        setOpenRecurringDropDown(true);

        expandedRows.push(row.shipmentId);

        setRowId(row.shipmentId);
        fetchRecurringShipments(row.shipmentId);
      }
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.isManifest,
      style: {
        borderLeft: "3px solid #198BF2",
      },
    },
  ];

  const recurringShipmentDropDown = () => {
    setOpenRecurringDropDown((prev) => !prev);
  };

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const makeShipmentRecurring = (data) => {
    setRecurringShipmentOpen(true);
    setShipment(data);
  };

  const delShipment = (id) => {
    deleteElement(`/shipment?shipmentId=${id}`).then(() => getShipments());
  };

  const hold = (id) => {
    postElement(`/Shipment/Hold?shipmentId=${id}&IsHold=true`).then(() => {
      getShipments();
    });
  };
  const lock = (data) => {
    postElement(
      `/Shipment/Lock?shipmentId=${data.shipmentId}&IsLocked=${!data.isLocked}`
    ).then(() => {
      getShipments();
    });
  };

  const undoShipment = () => {
    putElement(`Shipment/${shipment.shipmentId}/Undo`, {}).then((response) => {
      if (response.success) {
        getShipments();
      }
    });
  };

  const unAssignShipment = () => {
    const toastCode = Toast.loading("Please wait");
    getElementsV2({
      url: `/Shipment/${shipment.shipmentId}/Trip`,
      setLoading: null,
    }).then((res) => {
      let trips = res.data;
      if (trips?.length) {
        deleteElement(`/Shipment/Trip/${trips[0]?.tripId}/Assignment`, {}, "", {
          ignoreToast: true,
        }).then((response) => {
          Toast.success(response.response.data, toastCode);
          getShipments();
        });
      }
    });
  };

  useEffect(() => {
    setTableData(
      filteredShipment.map((data) => {
        let distance = `${data.distance} ${isMetric ? "km" : "mi"}`;
        let weight = `${data.weight} ${isMetric ? "kgs" : "lbs"}`;
        let pricePerMile = `${digitsToCurrency(
          data.distance && data.totalRate ? data.totalRate / data.distance : 0
        )} ${isMetric ? "/km" : "/mi"}`;
        return {
          scheduledPickupUTC: data.scheduledPickupUTC,
          scheduledDeliveryUTC: data.scheduledDeliveryUTC,
          nextScheduledUtc: data.nextScheduledUtc,
          shipmentNumber: data.shipmentNumber,
          isSplit: data.isSplit,
          shipmentId: data.shipmentId,
          isRecurring: data.isRecurring && !isFleetOwner,
          isManifest: data.isManifest,
          rowId: rowId,
          openRecurringDropDown: openRecurringDropDown,
          handleRowClick: handleRowClick,
          recurringShipmentDropDown: recurringShipmentDropDown,
          isDedicatedLane: data.isDedicatedLane && !isFleetOwner,
          makeShipmentRecurring: makeShipmentRecurring,
          shipperName: data.shipperName,
          trailerType: data.trailerType,
          shipmentMode: data.shipmentType,
          nextActivity: data.nextActivity,
          nextEtaType: data.nextEtaType,
          nextStop: data.nextStop,
          nextEtaUtc: dateFormater(data.nextEtaUtc, "MMM DD YYYY, HH:mm"),
          nextScheduledUtcTime:
            (data.nextScheduledUtc, "MMM DD YYYY, HH:mm", true),
          scheduledPickUpTime: dateFormater(
            data.scheduledPickupUTC,
            "MMM DD YYYY, HH:mm",
            true
          ),
          pickup: joinStrings(
            [data.pickupAddress?.city, data.pickupAddress?.state],
            ", "
          ),
          scheduledDeliveryTime: dateFormater(
            data.scheduledDeliveryUTC,
            "MMM DD YYYY, HH:mm",
            true
          ),
          dropoff: joinStrings(
            [data.deliveryAddress?.city, data.deliveryAddress?.state],
            ", "
          ),
          price: digitsToCurrency(data.totalRate),
          distance: distance,
          pricePerMile: pricePerMile,
          weight: weight,
          eta: (
            <>
              <i
                className={`mil-clock-f eta-icon ${data.pickupDeliveryStatus}`}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={data.pickupDeliveryStatus}
              ></i>
            </>
          ),
          status: (
            <BorderedText
              text={data.status}
              classes={getShipmentStatusColor(data.status)}
            />
          ),
          actions: (
            <div>
              <div className="table-actions">
                <i
                  className="mil-edit-o"
                  onClick={() => {
                    setShipId(data.shipmentId);
                    setShipmentContent(true);
                  }}
                  style={{ cursor: "pointer", fontSize: "21px" }}
                />

                {isFleetOwner && (
                  <ElipsisMenu>
                    <MenuItem
                      icon="mil-arrow-sm-right"
                      title="Open"
                      targetActionDenied={!hasPermission("edit.shipment")}
                      onClick={() => {
                        setAction(false);
                        setShipId(data.shipmentId);
                        setShipmentContent(true);
                      }}
                    />
                    {data.status == "Available" && (
                      <MenuItem
                        icon="mil-truck-o"
                        title="Assign"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          setShipment(data);
                          setAssignShipmentOpen(true);
                        }}
                      />
                    )}

                    {data.status == "Assigned" && (
                      <MenuItem
                        icon="mil-close-bg"
                        title="UnAssign"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          setShipment(data);
                          setOpenUnAssignModal(true);
                        }}
                      />
                    )}

                    {data.status != "Available" && data.status != "Void" && (
                      <MenuItem
                        icon="mil-arrow-corner-left"
                        title="Undo"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          setShipment(data);
                          setOpenUndoModal(true);
                        }}
                      />
                    )}
                  </ElipsisMenu>
                )}

                {!isFleetOwner && (
                  <ElipsisMenu>
                    <MenuItem
                      icon="mil-arrow-sm-right"
                      title="Open"
                      targetActionDenied={!hasPermission("edit.shipment")}
                      onClick={() => {
                        setAction(false);
                        setShipId(data.shipmentId);
                        setShipmentContent(true);
                      }}
                    />
                    {data.status != "Void" && (
                      <MenuItem
                        icon="mil-delete-o"
                        title="Void"
                        targetActionDenied={!hasPermission("delete.shipment")}
                        onClick={() => {
                          setAction(false);
                          delShipment(data.shipmentId);
                        }}
                      />
                    )}

                    {data.status != "Void" && (
                      <MenuItem
                        icon="mil-void-o"
                        title="Hold"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          hold(data.shipmentId);
                        }}
                      />
                    )}
                    {data.status != "Void" && (
                      <MenuItem
                        icon={
                          data.isLocked
                            ? "mil-lock-opened-o"
                            : "mil-lock-closed-o"
                        }
                        title={`${data.isLocked ? "Unlock" : "Lock"}`}
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          lock(data);
                        }}
                      />
                    )}

                    {data.status == "Available" && (
                      <MenuItem
                        icon="mil-truck-o"
                        title="Assign"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          setShipment(data);
                          setAssignShipmentOpen(true);
                        }}
                      />
                    )}

                    {data.status != "Available" && data.status != "Void" && (
                      <MenuItem
                        icon="mil-arrow-corner-left"
                        title="Undo"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => {
                          setShipment(data);
                          setOpenUndoModal(true);
                        }}
                      />
                    )}

                    <MenuItem
                      icon="mil-contract-f"
                      title="Duplicate"
                      targetActionDenied={!hasPermission("create.shipment")}
                      onClick={() => {
                        setAction(false);
                        duplicateShipment(data.shipmentId);
                      }}
                    />

                    {data.shipmentType === "PTL" && (
                      <MenuItem
                        importIcon={<VscCombine style={{ fontSize: "22px" }} />}
                        title="Add To Manifest"
                        targetActionDenied={!hasPermission("create.shipment")}
                        onClick={() => {
                          setManifest(data);
                        }}
                      />
                    )}

                    {data.isDedicatedLane && !data.isRecurring && (
                      <MenuItem
                        icon="mil-recurring-o"
                        title="Schedule Recurring Shipment"
                        targetActionDenied={!hasPermission("edit.shipment")}
                        onClick={() => makeShipmentRecurring(data)}
                      />
                    )}

                    {data.status != "Void" && (
                      <MenuItem
                        icon="mil-edit"
                        title="File Freight Claim"
                        targetActionDenied={!hasPermission("create.compliance")}
                        onClick={() => {
                          setAction(false);
                          setFreightClaimOpen(true);
                          setShipment(data);
                        }}
                      />
                    )}
                    {data.status === "Inprogress" && (
                      <MenuItem
                        icon="mil-void-f"
                        title="Mark TONU"
                        targetActionDenied={
                          !hasPermission("complete.shipment.trip")
                        }
                        onClick={() => {
                          setOpenCharge(true);
                          setShipmeentId(data.shipmentId);
                        }}
                      />
                    )}
                  </ElipsisMenu>
                )}
              </div>
            </div>
          ),
        };
      })
    );
  }, [filteredShipment, openRecurringDropDown]);
  const debounceSearch = useDebounce(searchedTerm, 500);

  const filterShipments = () => {
    const getSearchedShipments = async (offset = 1) => {
      const queryStr = new URLSearchParams({ ...request, offset }).toString();
      let a = await getElementsV2({
        url: `/Shipment?${queryStr}`,
        setLoading: setLoading,
      }).then((response) => {
        return response?.data || [];
      });

      return a;
    };

    expansivePageSearch({
      propName: "shipmentNumber",
      searchedTerm,
      getList: getSearchedShipments,
      initialList: shipmentsSummaryList,
      request,
      setterCallback: setFilteredShipments,
    });
  };

  useEffect(() => {
    filterShipments();
  }, [debounceSearch, shipmentsSummaryList]);

  return (
    <div className="summary-list">
      <ShipmentHeader
        listLength={tableData.length}
        getShipments={getShipments}
        setShowMap={setShowMap}
        showMap={showMap}
        searchedValue={searchedTerm}
        setSearchedValue={setSearchedTerm}
        setRequest={setRequest}
        request={request}
        useAsUtil={useAsUtil}
        recurringShipmentOpen={recurringShipmentOpen}
        activeTab={activeTab}
      />
      <StickySlider
        clickState={duplicateShipmentOpen}
        setClickState={setDuplicateShipmentOpen}
      >
        <DuplicateShipment
          setClickState={setDuplicateShipmentOpen}
          getShipments={getShipments}
          id={currentDuplicateId}
        />
      </StickySlider>
      <StickySlider clickState={manifest} setClickState={setManifest}>
        <ManifestShipment
          setClickState={setManifest}
          getShipments={getShipments}
          manifestData={manifest}
        />
      </StickySlider>

      <StickySlider
        clickState={recurringShipmentOpen}
        setClickState={setRecurringShipmentOpen}
      >
        <RecurringShipment
          setClickState={setRecurringShipmentOpen}
          getShipments={getShipments}
          shipment={shipment}
        />
      </StickySlider>

      <SliderModal
        clickState={freightClaimOpen}
        setClickState={setFreightClaimOpen}
      >
        <NewFreightClaim
          setNewFreightClaimOpen={setFreightClaimOpen}
          shipmentDetails={shipment}
        />
      </SliderModal>

      <SliderModal clickState={openCharge} setClickState={setOpenCharge}>
        <ShipmentCharge
          shipmentId={shipmentId}
          setOpenCharge={setOpenCharge}
          getShipments={getShipments}
        />
      </SliderModal>

      <SliderModal
        clickState={assignShipmentOpen}
        setClickState={setAssignShipmentOpen}
      >
        <AssignTripToDriver
          setOpenAssign={setAssignShipmentOpen}
          type={"Trip"}
          data={{
            id: shipment.shipmentId,
            shipmentNumber: shipment.shipmentNumber,
            shipmentType: shipment.shipmentMode,
            shipperName: shipment.shipperName,
          }}
          onAssignCallBack={() => {
            setAssignShipmentOpen(false);
            getShipments();
          }}
          fromShipment={true}
        />
      </SliderModal>

      {showMap ? (
        <div className="shipment-maps-miler">
          <MapsMiler type="trackingVehicles" />
        </div>
      ) : loading ? (
        loading
      ) : (
        <>
          <DataTable
            columns={columns}
            data={tableData}
            expandableRows
            expandableRowsComponent={({ data }) => (
              <ExpandedContent
                shipmentId={data.shipmentId}
                rowId={rowId}
                openRecurringDropDown={openRecurringDropDown}
                expandedRows={expandedRows}
                recurringshipments={recurringshipments}
                setShipId={setShipId}
                setShipmentContent={setShipmentContent}
              />
            )}
            expandableRowsHideExpander
            expandableRowExpanded={(row) =>
              expandedRows.includes(row.shipmentId)
            }
            onRowClicked={handleRowClick}
            conditionalRowStyles={conditionalRowStyles}
            onSort={(selectedColumn, sortDirection, rows) => {
              let sortedRows = selectedColumn.customSorter(
                selectedColumn,
                sortDirection,
                rows
              );
              setTableData(sortedRows);
            }}
          />
          <CustomPagination
            setRequest={setRequest}
            request={request}
            nextExists={nextExists}
          />
        </>
      )}
      {/* <FreightClaimModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shipmentId={id}
        insurer={insurer}
      /> */}

      <UndoTripModal
        open={openUndoModal}
        closeModal={setOpenUndoModal}
        undoTrip={undoShipment}
        title="Undo Shipment"
      />

      <UnassignTripModal
        open={openUnAssignModal}
        closeModal={setOpenUnAssignModal}
        unassign={unAssignShipment}
      />
    </div>
  );
};

export default Shipments;
