import InputMiler from "components/inputMiler/InputMiler";
import { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import { postElement } from "services/milerApi";
import { toast } from "react-toastify";
import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import findError from "utils/findError";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import ServiceFailureCard from "./stopDetails/ServiceFailureCard";

const InprogressDispatchForm = ({
  setClickState,
  stop,
  updateTrip,
  status,
}) => {
  const [stopData, setStop] = useState(JSON.parse(JSON.stringify(stop)));
  const [delay, setDelay] = useState(false);
  const [delayInfo, setDelayInfo] = useState({});
  const [errors, setFormErrors] = useState([]);
  const endpnt = stop.actualArrival ? "departure" : "arrival";

  const postDispatch = () => {
    let requestBody = { ...stopData };
    if (delay) {
      if (!delayInfo.ServiceFailure || !delayInfo.promiseDelayReason) {
        toast.error("Delayed! Please provide Service Failure Reason & Note.");
        return;
      } else {
        requestBody = {
          ...requestBody,
          promiseDelayReason: delayInfo.promiseDelayReason,
          stopNotes: [
            ...(requestBody.stopNotes || []),
            {
              stopNoteType: "ServiceFailure",
              note: delayInfo.ServiceFailure,
            },
          ],
        };
      }
    }

    postElement(
      `shipment/trip/stop/${stop.stopId}/${endpnt}`,
      requestBody
    ).then((res) => {
      if (res.success) {
        updateTrip();
        setClickState(false);
      } else if (res.vErrors) setFormErrors(res.vErrors);
    });
  };

  const onTrailerSelected = (item) => {
    if (item.newEntry) {
      setStop({ ...stopData, trailerNumber: item.value });
    } else {
      let items = item.equipment.split(" ");
      setStop({
        ...stopData,
        trailerNumber: items[0],
        trailerType: items[1],
        trailerId: item.id,
      });
    }
  };

  const findStopRef = (refType) => {
    const stopRef = stopData.stopReferences.find(
      (type) => type.stopReferenceType === refType
    );
    //value={findStopRef("BOLNumber")}
    return stopRef ? stopRef.reference : "";
  };

  const stopRefChange = (e) => {
    // reset on empty string
    const { name, value } = e.target;
    const stopRefsList = JSON.parse(JSON.stringify(stopData.stopReferences));
    const stopRef = stopRefsList.find(
      (type) => type.stopReferenceType === name
    );

    if (stopRef) {
      stopRef.reference = value;
    } else {
      stopRefsList.push({ stopReferenceType: name, reference: value });
    }
    setStop({ ...stopData, stopReferences: stopRefsList });
  };

  const findNote = (findType) => {
    const foundNote = stopData.stopNotes.find(
      (type) => type.stopNoteType === findType
    );
    return foundNote ? foundNote.note : "";
  };

  const serviceFailureOnChange = (e) => {
    const { name, value } = e.target;
    setDelayInfo({ ...delayInfo, [name]: value });
  };

  const stopNoteChange = (e) => {
    // reset on empty string
    const { name, value } = e.target;
    const newStopNotes = JSON.parse(JSON.stringify(stopData.stopNotes));
    const dispatchInfoNote = newStopNotes.find(
      (type) => type.stopNoteType === name
    );

    if (dispatchInfoNote) {
      dispatchInfoNote.note = value;
    } else {
      newStopNotes.push({ stopNoteType: name, note: value });
    }
    setStop({ ...stopData, stopNotes: newStopNotes });
  };

  const checkDelay = () => {
    if (endpnt === "arrival" && stopData.actualArrival) {
      if (
        new Date(stopData.latest_arrival.replace("Z", "")) <
        new Date(stopData.actualArrival.replace("Z", ""))
      ) {
        setDelay(true);
      } else {
        setDelay(false);
        setDelayInfo({});
      }
    }
  };

  useEffect(checkDelay, [stopData.actualArrival]);

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">
          {endpnt === "arrival" ? "Arrival" : "Departure"} Dispatch Info
        </div>
        <div>
          <MilerButton title="Cancel" onClick={() => setClickState(false)} />
          <MilerButton title="Save" onClick={postDispatch} btnDark />
        </div>
      </div>
      <div className="slider-display-body">
        <div className="miler-card">
          <div className="miler-card-title">Time</div>
          {!stop.actualArrival ? (
            <>
              <DateTimeInput
                label="Actual Arrival Time"
                value={stopData.actualArrival}
                error={findError("ActualArrival", errors)}
                required
                onChange={(value) => {
                  setStop({
                    ...stopData,
                    actualArrival: value,
                  });
                }}
              />
            </>
          ) : (
            <>
              <DateTimeInput
                label="Actual Departure Time"
                value={stopData.actualDeparture}
                error={findError("ActualDeparture", errors)}
                required
                onChange={(value) => {
                  setStop({
                    ...stopData,
                    actualDeparture: value,
                  });
                }}
              />
            </>
          )}
        </div>

        {stopData.actualArrivalLocal !== undefined ? (
          <div className="miler-card">
            {(status !== "Arrived" || delay) && (
              <div className="miler-card-title">Dispatch Details</div>
            )}
            <Row>
              {status !== "Arrived" && (
                <Col lg="6">
                  <Equipmentsearcher
                    onItemSelect={onTrailerSelected}
                    label="Loaded Trailer"
                    value={stopData.trailerNumber}
                    eqType="Trailer"
                  />
                </Col>
              )}

              {stop.actualArrival ? (
                <>
                  <Col lg="6">
                    <InputMiler
                      label="Pickup Order #"
                      name="PickUpNumber"
                      value={findStopRef("PickUpNumber")}
                      onChange={stopRefChange}
                    />
                  </Col>
                  <Col lg="6">
                    <InputMiler
                      label="BOL #"
                      name="BOLNumber"
                      value={findStopRef("BOLNumber")}
                      onChange={stopRefChange}
                    />
                  </Col>

                  <Col lg="6">
                    <InputMiler
                      label="Seal #"
                      name="Seal Number"
                      value={findStopRef("Seal Number")}
                      onChange={stopRefChange}
                    />
                  </Col>
                  <Col lg="12">
                    <InputMiler
                      label="Dispatch Info Note"
                      type="textarea"
                      name="DispatchInfo"
                      value={findNote("DispatchInfo")}
                      onChange={stopNoteChange}
                    />
                  </Col>
                </>
              ) : (
                <>
                  {status !== "Arrived" && (
                    <>
                      <Col lg="6">
                        <InputMiler
                          label="BOL #"
                          name="BOLNumber"
                          value={findStopRef("BOLNumber")}
                          onChange={stopRefChange}
                        />
                      </Col>

                      <Col lg="6">
                        <InputMiler
                          label="Delivery Order #"
                          value={findStopRef("DeliveryOrderNumber")}
                          name="DeliveryOrderNumber"
                          onChange={stopRefChange}
                        />
                      </Col>
                    </>
                  )}
                </>
              )}
            </Row>
          </div>
        ) : null}
        {delay && (
          <ServiceFailureCard
            reasonValue={delayInfo.promiseDelayReason}
            reasonOnchange={serviceFailureOnChange}
            noteValue={delayInfo.ServiceFailure}
            noteOnchange={serviceFailureOnChange}
          />
        )}
      </div>
    </div>
  );
};

export default InprogressDispatchForm;
