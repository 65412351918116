const recurringContract = {
  recurringShipmentId: 0,
  startUTC: "",
  endUTC: "",
  assignedDriverId: 0,
  stickyAssignment: false,
  monday: false,
  mondayQuantity: 1,
  tuesday: false,
  tuesdayQuantity: 1,
  wednesday: false,
  wednesdayQuantity: 1,
  thursday: false,
  thursdayQuantity: 1,
  friday: false,
  fridayQuantity: 1,
  saturday: false,
  saturdayQuantity: 1,
  sunday: false,
  sundayQuantity: 1,
  status: "New",
};

export default recurringContract;
