import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import InputMiler from "components/inputMiler/InputMiler";
import { FormGroup, Input, Label } from "reactstrap";
import { countryFullName } from "utils/countryFullName";
import "./ServiceProviderForm.sass";
import PhoneInput from "react-phone-number-input";
import findError from "utils/findError";


const NewServiceProviderForm = ({ state, dispatch, enums, errors }) => {
  return (
    <div className="slider-display-body customer-form">
      <div className="miler-card mt-0">
        <div className="miler-card-title">General</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="SERVICE PROVIDER NAME"
              name="serviceProviderName"
              value={state.serviceProviderName}
              onChange={(e) => {
                dispatch({ type: e.target.name, value: e.target.value });
              }}
              required
              error={findError("serviceProviderName", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="WEBSITE"
              name="website"
              value={state.website}
              onChange={(e) => {
                dispatch({ type: e.target.name, value: e.target.value });
              }}
              error={findError("website", errors)}
            />
          </div>
        </div>
      </div>

      <div className="miler-card">
        <div className="miler-card-title">MAILING INFORMATION</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <AddressSearcher
              address={state.mailingAddress}
              dispatch={(newAddress) => {
                dispatch({
                  type: "geo",
                  lat: newAddress.latitude,
                  lng: newAddress.longitude,
                });
                dispatch({
                  type: "address",
                  category: "mailingAddress",
                  value: newAddress,
                });
              }}
              error={findError("MailingAddress.AddressLine", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Address Type"
              type="select"
              name="addressType"
              value={state.mailingAddress.addressType}
              options={enums.AddressType}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "mailingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("MailingAddress.AddressType", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="City"
              disabled
              value={state.mailingAddress.city}
              error={findError("MailingAddress.City", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="State"
              disabled
              value={state.mailingAddress.state}
              error={findError("MailingAddress.State", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Country"
              disabled
              value={countryFullName(state.mailingAddress.countryCode)}
              error={findError("MailingAddress.CountryCode", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Postal Code"
              disabled
              value={state.mailingAddress.postalCode}
              error={findError("MailingAddress.PostalCode", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="First Name"
              name="firstName"
              value={state.mailingContact.firstName}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "mailingContact",
                  value: e.target.value,
                });
              }}
              error={findError("MailingContact.FirstName", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Last Name"
              name="lastName"
              value={state.mailingContact.lastName}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "mailingContact",
                  value: e.target.value,
                });
              }}
              error={findError("MailingContact.LastName", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Title"
              name="title"
              value={state.mailingContact.title}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "mailingContact",
                  value: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Email"
              name="email"
              value={state.mailingContact.email}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "mailingContact",
                  value: e.target.value,
                });
              }}
              error={findError("MailingContact.Email", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <label className="phone-input-label" htmlFor="phone">
              PHONE NUMBER
            </label>
            <PhoneInput
              className="phone-input"
              defaultCountry="US"
              international
              placeholder="Enter phone number"
              name="phone"
              value={state.mailingContact?.phones[0]?.phoneNumber}
              onChange={(e) => {
                dispatch({
                  type: "mailingPhone",
                  payload: e,
                  category: "phone",
                });
              }}
              error={findError("mailingContact.phones[0].phoneType", errors)}
            />
          </div>
        </div>
      </div>

      <div className="miler-card">
        <div className="miler-card-title">BILLING INFORMATION</div>
        <div className="row">
          <div className=" col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                checked={state.billingSameasMailing}
                onChange={(e) =>
                  dispatch({
                    type: "billingSameasMailing",
                    value: e.target.checked,
                  })
                }
              />
              <Label for="same" className="shadow-none" check>
                Same as Mailing
              </Label>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <AddressSearcher
              address={state.billingAddress}
              dispatch={(newAddress) =>
                dispatch({
                  type: "address",
                  category: "billingAddress",
                  value: newAddress,
                })
              }
              error={findError("BillingAddress.AddressLine", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Address Type"
              name="addressType"
              type="select"
              value={state.billingAddress.addressType}
              options={enums.AddressType}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("BillingAddress.AddressType", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="City"
              name="city"
              disabled
              value={state.billingAddress.city}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("BillingAddress.City", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="State"
              name="state"
              disabled
              value={state.billingAddress.state}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("BillingAddress.State", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Country"
              disabled
              name="countryCode"
              value={countryFullName(state.billingAddress.countryCode)}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("BillingAddress.CountryCode", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Postal Code"
              disabled
              name="postalCode"
              value={state.billingAddress.postalCode}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingAddress",
                  value: e.target.value,
                });
              }}
              error={findError("BillingAddress.PostalCode", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="First Name"
              name="firstName"
              value={state.billingContact.firstName}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingContact",
                  value: e.target.value,
                });
              }}
              error={findError("BillingContact.FirstName", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Last Name"
              name="lastName"
              value={state.billingContact.lastName}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingContact",
                  value: e.target.value,
                });
              }}
              error={findError("BillingContact.LastName", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Title"
              name="title"
              value={state.billingContact.title}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingContact",
                  value: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Email"
              name="email"
              value={state.billingContact.email}
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingContact",
                  value: e.target.value,
                });
              }}
              error={findError("BillingContact.Email", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <label className="phone-input-label" htmlFor="phone">
              PHONE NUMBER
            </label>
            <PhoneInput
              className="phone-input"
              defaultCountry="US"
              international
              placeholder="Enter phone number"
              name="phone"
              value={state.billingContact?.phones[0]?.phoneNumber}
              onChange={(e) => {
                dispatch({
                  type: "billingPhone",
                  payload: e,
                  category: "phone",
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewServiceProviderForm;
