import { Modal, ModalBody } from "reactstrap";
import { Avatar } from "antd";
import hangupCall from "../../../src/assets/call/phone-hangup.png";
import receiveCall from "../../assets/call/phone-call.png";
import { Tooltip } from "react-tooltip";

import "./dialerPad.sass";
const Dialer = ({
  open,
  closeModal,
  phoneNumber,
  firstName,
  lastName,
  onClick,
  callStatus,
}) => {
  const { iniateCall, calling } = callStatus;

  return (
    <Modal
      isOpen={Boolean(open)}
      toggle={() => closeModal(false)}
      cssModule={{
        border: "10px solid red",
      }}
    >
      <ModalBody>
        <main className="main_dialer">
          <section className="dialer">
            <section className="dialer_avatar">
              <Avatar
                size={45}
                style={{
                  alignSelf: "flex-end",
                  backgroundColor: "rosybrown",
                  fontWeight: "500",
                  fontSize: "15px",
                  textTransform: "uppercase",
                }}
              >
                {firstName.charAt(0)}
                {lastName.charAt(0)}
              </Avatar>
              {iniateCall && <p>Processing Call</p>}
              {calling && <p>Calling.....</p>}
              {!iniateCall && !calling && <p>Call</p>}
              <p className="dialer_phone_number">{phoneNumber}</p>
            </section>
          </section>
          <section className="dialer_icons">
            {calling ? (
              <img
                src={hangupCall}
                width="35px"
                height="35px"
                style={{
                  cursor: "pointer",
                }}
              />
            ) : (
              <>
                <Tooltip id="message-tooltip" place="bottom" />
                <img
                  src={receiveCall}
                  width="35px"
                  height="35px"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={onClick}
                  data-tooltip-id="message-tooltip"
                  data-tooltip-content="Dial"
                />
              </>
            )}
          </section>
        </main>
      </ModalBody>
    </Modal>
  );
};

export default Dialer;
