import CellBuilder from "components/cellBuilderTable/CellBuilder";
import { dateSorter, stringSorter } from "utils/tableSorter";

export const manifestColumn = [
  {
    selector: (row) => <div>{row.check}</div>,

    grow: 3,
  },
  {
    name: "SHIPMENT #",
    selector: (row) => row.shipmentNumber,
    sortable: true,
    cell: (row) =>
      CellBuilder(
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {row.shipmentNumber}
          {row.isDedicatedLane && (
            <i
              className="mil-recurring-o"
              style={{
                fontSize: "17px",
                marginLeft: "4px",
                color: row.isRecurring ? "#198BF2" : "#626365",
                cursor: "pointer",
              }}
              onClick={() => row.makeShipmentRecurring(row)}
              title={
                row.isRecurring
                  ? "Dedicated Revenue - Recurring"
                  : "Dedicated Revenue"
              }
            />
          )}
        </span>,
        row.carrierName
      ),
    grow: 7,
    sortFunction: () => {},
    customSorter: stringSorter,
  },
  {
    name: "MODE",
    selector: (row) => row.shipmentMode,
    grow: 2,
    cell: (row) => CellBuilder(row.shipmentMode, row.trailerType),
  },
  {
    name: "PICKUP",
    selector: (row) => row.scheduledPickupUTC,
    sortable: true,
    grow: 7,
    cell: (row) => CellBuilder(row.pickup, row.scheduledPickUpTime),

    sortFunction: () => {},
    customSorter: dateSorter,
  },
  {
    name: "DROPOFF",
    selector: (row) => row.scheduledDeliveryUTC,
    sortable: true,
    grow: 7,
    cell: (row) => CellBuilder(row.dropoff, row.scheduledDeliveryTime),

    sortFunction: () => {},
    customSorter: dateSorter,
  },
  {
    name: "DISTANCE",
    selector: (row) => row.distance,
    cell: (row) => CellBuilder(row.distance, row.weight),
    grow: 2,
  },
  {
    name: "PRICE",
    selector: (row) => row.price,
    cell: (row) => CellBuilder(row.price, row.priceRate, "blue"),
    grow: 2,
  },
  {
    name: "NEXT ETA",
    selector: (row) => row.nextScheduledUtc,
    sortable: true,
    customSorter: dateSorter,
    cell: (row) =>
      CellBuilder(
        <section>
          {row.nextActivity} {row.nextStop}
          {row.nextStop && <>{row.eta}</>}
        </section>,
        <p>
          {row.nextEtaUtc && <>{row.nextEtaType}:</>} {row.nextEtaUtc}
        </p>
      ),
    grow: 10,
  },
  {
    name: "STATUS",
    selector: (row) => row.status,
    grow: 2,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 1,
  },
];
