import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { getElementsV2 } from "services/milerApi";
import ShipmentEvent from "./shipmentEvent/ShipmentEvent";

import "./ShipmentRecentActivity.css";

const ShipmentRecentActivity = ({ shptDtls }) => {
  const [state, setState] = useState([]);
  const getHistory = () => {
    getElementsV2({
      url: `/Shipment/${shptDtls.shipmentId}/History`,
      setLoading: null,
    }).then((res) => {
      setState(res.data || []);
    });
  };

  useEffect(getHistory, [shptDtls.shipmentId]);
  return (
    <Card>
      <CardBody className="recent-activity-card">
        <CardTitle className="recent-activity-header">
          <p className="recent-activity-title">Recent Activity</p>
        </CardTitle>
        <Row className="recent-activity-body">
          {state.map((s) => (
            <ShipmentEvent
              eventName={s.eventName}
              eventUtc={s.eventUtc}
              eventBody={s.eventBody}
              createdBy={s.createdBy}
              key={s.shipmentId}
            />
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};

export default ShipmentRecentActivity;
