import { useContext, useEffect, useRef, useState } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import "../Messages.sass";
import { TbUserCancel } from "react-icons/tb";
import WhatsAppUsers from "./WhatsAppUsers";
import { WhatsAppContext } from "services/contexts/MessagingContext";
import { getElements, postElement } from "services/communicationsApi";
import DriverSearcher from "components/inputMiler/advanceSearch/DriverSearcher";

import Loading from "components/loading/Loading";
import { Avatar } from "antd";
import { RiSendPlaneFill } from "react-icons/ri";
import { ToolBarContext } from "layout/toolbar/context/ToolbarContext";
import WhatsApp from "./WhatsApp";

const WhatsAppWrapper = ({ driver }) => {
  const { getMessages, loading, setLoading } = useContext(WhatsAppContext);
  const { dispatch } = useContext(ToolBarContext);

  const messageInputRef = useRef();

  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");

  const [propDriver] = useState(driver);

  const [searchingDriver] = useState(false);

  const getColor = (index) => {
    const colors = [
      "#c18a41",
      "#7c5fcf",
      "#83a23e",
      "#c163ba",
      "#4da970",
      "#d1407e",
      "#48b2ce",
      "#cb513a",
      "#777fc7",
      "#c26a7d",
    ];

    const colorIndex = index <= 10 ? index : index % 10;

    return colors[colorIndex];
  };

  useEffect(() => {
    dispatch({ type: "setCurrent", payload: { current: "WhatsApp" } });
    setLoading(true);
    if (driver) {
      onDriverSelected({
        first_name: driver.driver_first_name || driver.firstName,
        last_name: driver.driver_last_name || driver.lastName,
        user_id: driver.driver_user_id || driver.driverId,
      });
    } else {
      getElements(`/Whatsapp/User`)
        .then((response) => {
          setDrivers(response.data);
          setFilteredDrivers(response.data);
          if (response.data.length) {
            response.data[0].bgColor = getColor(1);
            onDriverSelected(response.data[0]);
          } else {
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return () => {
      setLoading(true);
    };
  }, []);

  const onDriverSelected = (driver) => {
    setSelectedDriver(driver);
    getMessages(driver);
  };

  const handleSendMessage = () => {
    if (newMessage) {
      postElement(
        `/Whatsapp/User/${selectedDriver.whatsappSubscriberId}?messageBody=${newMessage}`
      ).then(() => {
        getMessages(selectedDriver);
        setNewMessage("");
      });
    }
  };

  const handleSearchDriver = (searchValue) => {
    if (searchValue) {
      const driversFound = drivers.filter((driver) =>
        JSON.stringify(driver)
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase())
      );
      setFilteredDrivers(driversFound);
    } else {
      setFilteredDrivers(drivers);
    }
  };
  if (loading) {
    return (
      <div style={{ marginTop: "2rem" }}>
        <Loading />
      </div>
    );
  }
  return (
    <div className="messages-wrapper">
      {!propDriver && !searchingDriver && drivers.length > 0 && (
        <div className="messages-side-nav">
          <div className="side-nav-header">
            <InputMiler
              type="search"
              placeholder={"Search driver id or name"}
              onChange={(e) => handleSearchDriver(e.target.value)}
            />
          </div>

          <WhatsAppUsers
            drivers={filteredDrivers}
            onDriverSelected={onDriverSelected}
            getColor={getColor}
          />
        </div>
      )}

      {searchingDriver && (
        <div className="messages-side-nav">
          <div className="m-3">
            <DriverSearcher
              placeholder="Search driver"
              onItemSelect={onDriverSearcherSelect}
            />
          </div>
        </div>
      )}
      {drivers.length > 0 ? (
        <div className="messages-main-container">
          <div className="container-head">
            <div className="chat-driver">
              <Avatar
                size={35}
                style={{
                  backgroundColor: selectedDriver.bgColor,
                  fontWeight: "500",
                  color: "#202F53",
                  fontSize: "15px",
                }}
              >
                {selectedDriver?.profileName?.slice(0, 2)}
              </Avatar>
              <div className="chat-driver-details">
                <div className="chat-driver-name" style={{ padding: "10px" }}>
                  {selectedDriver?.profileName}
                </div>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="loading-container">
              <Loading />
            </div>
          ) : (
            <WhatsApp getColor={getColor} drivers={drivers} />
          )}

          <div className="new-message-container">
            <textarea
              className="new-message-input"
              type="text"
              multiple
              name="newMessage"
              placeholder="Write message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              ref={messageInputRef}
            />
            <div className="send-button">
              <Avatar
                size={40}
                icon={<RiSendPlaneFill />}
                onClick={() => handleSendMessage()}
                style={{
                  backgroundColor: "#202F53",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="no-user-container">
          <TbUserCancel size={100} />
          <h6>
            Your Whatsapp account is not setup. Please contact us to enable
            Whatsapp for Business.
          </h6>
        </div>
      )}
    </div>
  );
};

export default WhatsAppWrapper;
