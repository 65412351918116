import { useContext, useState } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import MakeSelector from "../newVehicle/makeSelector/MakeSelector";
import CountryRegionInput from "components/country-region-input/CountryRegionInput";
import findError from "utils/findError";
import FleetSearchInput from "./FleetSearch";
import PayeeSelector from "components/payee-form/PayeeSelector";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import dateFormatter from "utils/dateFormater";
const VehicleForm = ({ setState, state, yearError, errors, setYearError }) => {
  const { enums } = useContext(appContext);

  const findMakeErrors = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const onChangeYear = (e) => {
    const min = new Date().getFullYear() - 70;
    const max = new Date().getFullYear() + 2;
    if (e.target.value > max || e.target.value < min) {
      setYearError("Invalid Year");
    } else {
      // let newarr = errors.filter(a => a.key !== 'Year')
      setYearError(null);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onChange = (e) => {
    if (e.target.name === "unitNumber") {
      if (e.target.value.length <= 6) {
        setState({ ...state, [e.target.name]: e.target.value });
        return;
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const onChangeFleet = (value) => {
    setState({ ...state, fleetId: value });
  };

  const [_selectedFleet, setSelectedFleet] = useState({
    fleetId: state.fleetId,
    fleetName: "",
  });

  const [fleets, setFleets] = useState([]);

  const onShipperSearch = (item) => {
    setSelectedFleet({
      fleetId: item.fleetId,
      fleetName: item.label,
    });
  };

  return (
    <div className="slider-display-body">
      <div className="miler-card mt-0">
        <div className="miler-card-title">General</div>
        <div className="row">
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.vehicleType}
              type="select"
              options={enums.VehicleType}
              onChange={onChange}
              name="vehicleType"
              label="Vehicle Type"
              error={findError("VehicleType", errors)}
              required
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.unitNumber}
              type="text"
              onChange={onChange}
              name="unitNumber"
              label="Unit Number"
              error={findError("UnitNumber", errors)}
              required
            />
          </div>
          <div className="col col-12 col-xl-6">
            <MakeSelector
              state={state}
              onChange={onChange}
              findError={findMakeErrors}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.model}
              type="text"
              onChange={onChange}
              name="model"
              label="Model"
              error={findError("Model", errors)}
              required
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.year}
              type="number"
              onChange={onChangeYear}
              name="year"
              label="Year"
              error={yearError ? yearError : findError("Year", errors)}
              required
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.vin}
              type="text"
              onChange={onChange}
              name="vin"
              label="Vin"
              error={findError("Vin", errors)}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.ownership}
              type="select"
              options={enums.VehicleOwnership}
              onChange={onChange}
              name="ownership"
              label="Ownership"
              error={findError("Ownership", errors)}
              required
            />
          </div>

          {(state.ownership === "Owner Operator Vehicle" ||
            state.ownership === "Leased Vehicle") && (
            <div className="col-12 col-xl-6 mb-3">
              <PayeeSelector
                selectedPayee={state.payee}
                onSelect={(payee) => {
                  setState({ ...state, accountId: payee?.accountId });
                }}
                newName={state.payee?.__isNew__ ? state.payee.label : ""}
                driverState={{
                  code: "",
                  email: "",
                }}
                error={findError("AccountId", errors)}
              />
            </div>
          )}

          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.isUtilityVehicle ? "Yes" : "No"}
              type="select"
              onChange={(e) =>
                setState({
                  ...state,
                  [e.target.name]: e.target.value === "Yes" ? true : false,
                })
              }
              name="isUtilityVehicle"
              options={["Yes", "No"]}
              label="Utility Vehicle?"
              error={findError("isUtilityVehicle", errors)}
            />
          </div>

          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.plateNumber}
              type="text"
              onChange={onChange}
              name="plateNumber"
              label="Plate Number"
              error={findError("PlateNumber", errors)}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <CountryRegionInput
              onChange={onChange}
              value={state.plateState}
              name="plateState"
              error={findError("PlateState", errors)}
              label="Plate State"
            />
          </div>
          <div className="col col-12 col-xl-6">
            {/* <InputMiler
              type="date"
              value={dateFormatter(state.plateExpiration, "YYYY-MM-DD", true)}
              onChange={handleDateChange}
              name="plateExpiration"
              label="Plate Expiration"
              error={findError("PlateExpiration", errors)}
            /> */}
            <DateTimeInput
              type="date"
              value={dateFormatter(state?.plateExpiration, "YYYY-MM-DD", true)}
              onChange={(value) => {
                setState({ ...state, plateExpiration: value });
              }}
              name="plateExpiration"
              label="Plate Expiration"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <DateTimeInput
              type="date"
              value={dateFormatter(state.annualExpiration, "YYYY-MM-DD", true)}
              onChange={(value) => {
                setState({ ...state, annualExpiration: value });
              }}
              name="annualExpiration"
              label="Annual Expiration"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <DateTimeInput
              type="date"
              value={dateFormatter(
                state.physicalExpiration,
                "YYYY-MM-DD",
                true
              )}
              onChange={(value) => {
                setState({ ...state, physicalExpiration: value });
              }}
              name="physicalExpiration"
              label="Phys Dam Expiration"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <DateTimeInput
              type="date"
              value={dateFormatter(state.ntlExpiration, "YYYY-MM-DD", true)}
              onChange={(value) => {
                setState({ ...state, ntlExpiration: value });
              }}
              name="ntlExpiration"
              label="NTL Expiration"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <FleetSearchInput
              _selectedFleet={_selectedFleet}
              setSelectedFleet={setSelectedFleet}
              fleets={fleets}
              setFleets={setFleets}
              onChangeFleet={onChangeFleet}
              value={{
                fleetId: _selectedFleet.fleetId,
                label: _selectedFleet.fleetName,
              }}
              onSelect={onShipperSearch}
              // error={findError(`ShipperId`)}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state?.capacity}
              type="number"
              onChange={onChange}
              name="capacity"
              label="Capacity"
            />
          </div>

          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.engineType}
              type="text"
              onChange={onChange}
              name="engineType"
              label="Engine Type"
              error={findError("EngineType", errors)}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.color}
              type="text"
              onChange={onChange}
              name="color"
              label="Color"
              error={findError("Color", errors)}
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.tireSize}
              onChange={onChange}
              name="tireSize"
              label="Tire Size"
              type="text"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.prePassNumber}
              type="text"
              onChange={onChange}
              name="prePassNumber"
              label="PrePass Number"
            />
          </div>

          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.hutSticker}
              type="text"
              onChange={onChange}
              name="hutSticker"
              label="HUT Sticker"
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.iftaSticker}
              type="text"
              onChange={onChange}
              name="iftaSticker"
              label="IFTA Sticker"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleForm;
