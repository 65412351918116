import { useContext, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "./components/sidebar/Sidebar";
import AppContent from "./layout/AppContent";
import AuthFailure from "services/authFailure/AuthFailure";
import "./App.sass";
import "assets/icon-fonts/icon-fonts.css";
import { checkJwtStatus, getJwt, logoutRedirect } from "services/Auth";
import { getElements } from "services/milerApi";
import { appContext } from "services/AppContext";
import TokenExpirationWarning from "services/tokenExpiration/TokenExpirationWarning";
import { ToastContainer } from "react-toastify";
import ServiceRequest from "views/public/request/ServiceRequest";
import OnBoarding from "services/onBoarding/onBoarding";
import "react-toastify/dist/ReactToastify.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { QUERY_CACHE_TIMEOUT } from "./constants/config";
import DriverRedirection from "services/driver-redirection/DriverRedirection";
import { SetupView } from "views/activityViewer/ActivtyViewer";
import ReactGA from "react-ga";
import RouteChangeTracker from "services/analytics/RouteChangeTracker";
import { getCookie } from "utils/cookies";
//import { firebaseConfig } from "firebase-config";
//import { MessagingContext } from "services/contexts/MessagingContext";
const { env, envEnum } = require("././services/utils/environment");

//import { initializeApp } from "firebase/app";
//import { getMessaging, onMessage, getToken } from "firebase/messaging";

//const app = initializeApp(firebaseConfig);
//const messaging = getMessaging(app);

const TRACKING_ID = env === envEnum.prod ? "GTM-NX7SLL9" : "";
ReactGA.initialize(TRACKING_ID);

function AppWrapper() {
  const [appContent, setAppContent] = useState(<div className="loading"></div>);

  const { getEnums } = useContext(appContext);
  //  const { messages, setMessages } = useContext(MessagingContext);

  // const getFireBaseToken = async () => {
  //   try {
  //     const currentToken = await getToken(messaging, {
  //       vapidKey:
  //         "BGV0sKXs7qncN5Axis21q4CAmGEesFaCYULIAFyBT0KoNPViluI7Uz0Jz5mxPJ5bPmzzKNo8N4Py5fOQS0OkUNc",
  //     });
  //     // May send Token to backed if need be
  //   } catch (err) {
  //   }

  //   onMessage(messaging, (payload) => {
  //     setMessages([...messages, payload]);
  //   });
  // };

  const getContent = () => {
    if (window.location.pathname.replace(/\/$/, "") === "/rectify") {
      return setAppContent(<ServiceRequest />);
    }
    if (
      window.location.pathname.replace(/\/$/, "") ===
      "/shipment/customer_tracking"
    ) {
      return setAppContent(<SetupView />);
    }

    getStatus();
  };

  const getStatus = () => {
    let status = checkJwtStatus();
    if (status === "VALID") {
      appSetup();
    } else {
      getJwt(status).then((response) => {
        if (response.redirecting) {
          //Do nothing as redirect happens
        } else if (response.authFailed) {
          setAppContent(
            <AuthFailure message="Sorry, your login attempt was not successful." />
          );
        } else if (!response.authFailed) {
          appSetup();
        }
      });
    }
  };

  const appSetup = () => {
    const cachedJwt = getCookie("cachedJwt") || {};
    const { carrierStatus } = getCookie("carrierInfo") || {};
    const {
      userId,
      userStatus,
      usertype,
      notFound,
      userError,
      is_new_federated_user,
    } = getCookie("userInfo") || {};
    if (userError) {
      setAppContent(
        <AuthFailure message="Oops! We could not load your user profile." />
      );
    } else if (notFound) {
      logoutRedirect("/inactivated.html?account=user-not-found"); //user-not-found
    } else if (is_new_federated_user) {
      logoutRedirect("/federated.html");
    } else if (cachedJwt.accessToken && userId) {
      //subscribe to carrier-topic

      // getFireBaseToken();

      getElements(`/Config/Enums`)
        .then((response) => {
          getEnums(response.data);

          if (userStatus?.toLowerCase() === "cancelled") {
            logoutRedirect("/inactivated.html"); //user
            return;
          } else if (carrierStatus?.toLowerCase() === "cancelled") {
            logoutRedirect("/inactivated.html?account=company"); //company
            return;
          } else if (usertype?.toLowerCase() === "carrier driver") {
            setAppContent(<DriverRedirection />);
            return;
          } else if (carrierStatus?.toLowerCase() === "onboarding") {
            setAppContent(<OnBoarding />);
          } else {
            setAppContent(<App />);
          }
        })

        .catch(() =>
          setAppContent(
            <AuthFailure message="Oops! There was an error while loading application resources." />
          )
        );
    } else {
      setAppContent(
        <AuthFailure message="Sorry, your login attempt was not successful." />
      );
    }
  };

  useEffect(getContent, []);
  return (
    <div>
      <>{appContent}</>
    </div>
  );
}

const App = () => {
  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: QUERY_CACHE_TIMEOUT,
      },
    },
  });

  return (
    <div className="app-wrapper">
      <TokenExpirationWarning />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={true}
        progress={undefined}
      />

      <div className="app d-flex">
        <SideBar />
        <RouteChangeTracker />
        <QueryClientProvider client={queryClient}>
          <AppContent />
        </QueryClientProvider>
      </div>
    </div>
  );
};

export default AppWrapper;
