import { getCookie } from "utils/cookies";

const axios = require("axios");
const apiConfig = require("./apiConfig");
const { env } = require("./environment");

function withSetBaseUrl(config) {
  const { baseUrls, ...other } = config;
  const newConfig = { ...other };
  if (baseUrls && baseUrls[env]) {
    newConfig.baseURL = baseUrls[env];
  }
  return newConfig;
}

function mergeConfig(config1, config2, version) {
  const cachedJwt = getCookie("cachedJwt") || {};
  const { userId, carrierId } = getCookie("userInfo") || {};
  const config = withSetBaseUrl(config1);
  const custom = withSetBaseUrl(config2);
  const mergedConfig = Object.assign({}, config, custom);
  mergedConfig.headers = Object.assign({}, config.headers, custom.headers);
  if (cachedJwt && cachedJwt.accessToken) {
    // TODO: review this for correct headers based on API implementation
    mergedConfig.headers = Object.assign({}, config.headers, custom.headers, {
      Authorization: `Bearer ${cachedJwt.accessToken}`,
      "X-Miler-UserId": userId,
      "X-Miler-CarrierId": carrierId,
      version: version ? version : 2,
    });
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
  if (!mergedConfig.baseURL) {
    mergedConfig.baseURL = "/";
  }

  return mergedConfig;
}

function axiosWrapper(config) {
  // Return public functions
  // The config param is optional on all.
  return {
    get(url, customConfig, version, maxRetries) {
      const axiosInstance = axios.create(
        mergeConfig(config, customConfig, version)
      );
      let retryCount = 0;
      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const max_retries = maxRetries || 0;
          //retry only if the error is timeout and max_retries is positive number
          if (
            retryCount < max_retries &&
            error.code === "ECONNABORTED" &&
            error.message.includes("timeout")
          ) {
            retryCount = retryCount + 1;
            return axiosInstance.request(error.config);
          }
          return Promise.reject(error);
        }
      );
      return axiosInstance.get(url);
    },

    post(url, customConfig, data, version) {
      return axios
        .create(mergeConfig(config, customConfig, version))
        .post(url, data);
    },

    put(url, customConfig, data, version) {
      return axios
        .create(mergeConfig(config, customConfig, version))
        .put(url, data);
    },

    patch(url, customConfig, data, version) {
      return axios
        .create(mergeConfig(config, customConfig, version))
        .patch(url, data);
    },

    del(url, customConfig, data, version) {
      return axios
        .create(mergeConfig(config, customConfig, version))
        .delete(url, { data });
    },
  };
}

const { get, post, put, patch, del } = axiosWrapper(apiConfig);

export const getApi = (paramString, config, version, maxRetries) =>
  get(paramString, config, version, maxRetries);

export const postApi = (paramString, config, data, version) =>
  post(paramString, config, data, version);

export const putApi = (paramString, config, data, version) =>
  put(paramString, config, data, version);

export const patchApi = (paramString, config, data, version) =>
  patch(paramString, config, data, version);

export const deleteApi = (paramString, config, data, version) =>
  del(paramString, config, data, version);
