import FleetForm from "../vehicleForm/FleetForm";
import MilerButton from "components/milerButton/MilerButton";
import { useState, useEffect } from "react";
import { getElements, postElement } from "services/milerApi";
const NewFleet = ({setOpenForm, getFleets,fleet, setFleet}) => {
  

  const [fleetManagerOptions, setFleetManagerOptions] = useState();

  const getFleetManagerOptions = () => {
    getElements("/Equipment/Fleet/Manager").then((res) => {
      setFleetManagerOptions(res.data);
    });
  };

  const findManager = fleetManagerOptions?.find(
    (manager) => manager.fullName === fleet.fleetManager
  );

  const [formErrors, setFormErrors] = useState();

  useEffect(() => {
    getFleetManagerOptions();
  }, []);

  const createFleet = async () => {
    return postElement(
      `/Equipment/Fleet?fleet_name=${fleet.fleetName}&fleet_manager_user_id=${findManager?.userId}`
    ).then((response) => {
      if (response.success) {
        getFleets();
        setOpenForm(false)
      } else if (response.vErrors) {
        setOpenForm(true)
        setFormErrors(response.vErrors)
      }
    });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Fleet</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setOpenForm(false);
            }}
          />
          <MilerButton btnDark title="Add" onClick={createFleet} />
        </div>
      </div>
      <div className="slider-display-body">
        <FleetForm
          setFleet={setFleet}
          fleet={fleet}
          fleetManagerOptions={fleetManagerOptions}
          formErrors = {formErrors}
        />
      </div>
    </div>
  );
};

export default NewFleet;
