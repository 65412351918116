import { useContext, useState } from "react";
import { deleteElement, postElement, putElement } from "services/milerApi";
import InprogressTrip from "./InprogressTrip/InprogressTrip";
import AvailableTrips from "./AvailableTrip/AvailableTrip";
import { ActivityContext } from "../../activityContext/ActivityContext";
import SliderModal from "components/sliderModal/SliderModal";
import ShipmentDetailsWrapper from "views/shipments/shipmentDetails/ShipmentDetailsWrapper";
import AssignDrivers from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/AssignTrip";
import StartTripModal from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/StartTripModal";
import SplitTrip from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/SplitTrip";
import DeliverTrip from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/DeliverTrip";
import AssignedInfoCard from "./assigned-info-card/AssignedInfoCard";
import TripHeader from "./TripHeader";
import EmptyStop from "./emptyStop/EmptyStop";
import "./TripDisplay.sass";
import UndoTripModal from "./tripMenu/UndoTripModal";
import UnassignTripModal from "./tripMenu/UnassignTripModal";
import AddTransaction from "./tripMenu/AddTransaction";
import SkipStopsFlow from "./tripMenu/SkipStopsFlow";
import CheckCalls from "./tripMenu/CheckCall/CheckCalls";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import ShipmentCharge from "views/shipments/ShipmentCharge";
export default function Trip({ closeTripSlider }) {
  const { currentTrip, getTrips, getTrip } = useContext(ActivityContext);
  const [shipmentContent, setShipmentContent] = useState(false);
  const [assignDriversOpen, setAssignDriversOpen] = useState(false);
  const [shipId, setShipId] = useState();
  const [startTrip, setStartTrip] = useState(false);
  const [spiltTripOpen, setSpiltTripOpen] = useState(false);
  const [deliverTripOpen, setDeliverTripOpen] = useState(false);
  const [unassignModal, setUnassignModal] = useState(false);
  const [undoModal, setUndoModal] = useState(false);
  const [addTransactionOpen, setAddTransactionOpen] = useState(false);
  const [skipStopsFlowOpen, setSkipStopsFlowOpen] = useState(false);
  const [stopsSkipped, setStopsSkipped] = useState(false);
  const [checkCallSliderOpen, setCheckCallSliderOpen] = useState(false);
  const [tracking, setTracking] = useState(false);

  const [openCharge, setOpenCharge] = useState(false);
  const acceptTrip = () => {
    postElement(`/shipment/trip/${currentTrip.tripId}/accept`).then(() => {
      getTrips();
      getTrip(currentTrip.tripId);
    });
  };

  const completeTrip = () => {
    postElement(`/shipment/trip/${currentTrip.tripId}/complete`, []).then(
      () => {
        getTrips();
        getTrip(currentTrip.tripId);
        setUndoModal(false);
      }
    );
  };

  const checkStopProgress = () => {
    let foundStop = currentTrip.stops.find(
      (stop) => stop.progress === "Pending" || stop.progress === "Inprogress"
    );
    if (foundStop) {
      setSkipStopsFlowOpen(true);
    }
  };
  const skipStopsProgress = () => {
    setStopsSkipped(true);
  };

  const undoTrip = () => {
    putElement(
      `Shipment/${currentTrip.shipmentResponse.shipmentId}/Undo?trip_id=${currentTrip.tripId}`,
      {}
    ).then(() => {
      getTrips();
      getTrip(currentTrip.tripId);
    });
  };

  const openDetails = () => {
    setShipId(currentTrip.shipmentResponse?.shipmentId);
    setShipmentContent(true);
  };

  const handleUnassign = () => {
    deleteElement(`Shipment/Trip/${currentTrip.tripId}/Assignment`).then(() => {
      getTrips();
      getTrip(currentTrip.tripId);
    });
  };

  const voidTrip = () => {
    deleteElement(
      `/shipment?shipmentId=${currentTrip.shipmentResponse.shipmentId}`
    ).then(() => {
      getTrips();
    });
  };

  const cancelTrip = () => {
    putElement(`/shipment/trip/${currentTrip.tripId}/void`, {}).then(() => {
      getTrips();
    });
  };

  const hold = () => {
    const { onHold, shipmentId } = currentTrip?.shipmentResponse || {};
    postElement(
      `/Shipment/Hold?shipmentId=${shipmentId}&IsHold=${Boolean(!onHold)}`
    ).then(() => {
      getTrips();
      getTrip(currentTrip.tripId);
    });
  };

  const refreshTripsPage = () => {
    getTrips();
    getTrip(currentTrip.tripId);
  };
  /* React.useEffect(() => {
    if (!currentTrip.tripId && trips[0]) getTrip(trips[0].tripId);
  }, [trips]); */

  return (
    <div className="avaliable_shipment container">
      <TripHeader
        currentTrip={currentTrip}
        handleUnassign={() => setUnassignModal(true)}
        openDetails={openDetails}
        setCheckCallSliderOpen={setCheckCallSliderOpen}
        setAssignDriversOpen={setAssignDriversOpen}
        acceptTrip={acceptTrip}
        setStartTrip={setStartTrip}
        completeTrip={completeTrip}
        setSpiltTripOpen={setSpiltTripOpen}
        undoTrip={() => setUndoModal(true)}
        setDeliverTripOpen={setDeliverTripOpen}
        setAddTransactionOpen={setAddTransactionOpen}
        checkStopProgress={checkStopProgress}
        hold={hold}
        voidTrip={voidTrip}
        cancelTrip={cancelTrip}
        closeTripSlider={closeTripSlider}
        setTracking={setTracking}
        setOpenCharge={setOpenCharge}
        tracking={tracking}
      />
      {currentTrip.driverFirstName && <AssignedInfoCard trip={currentTrip} />}
      {currentTrip.tripStatus === "Inprogress" ? (
        <InprogressTrip
          currentTrip={currentTrip}
          getTrips={getTrips}
          tracking={tracking}
        />
      ) : currentTrip.tripBasis !== "Empty" ? (
        <AvailableTrips getTrips={refreshTripsPage} stops={currentTrip?.stops || []} />
      ) : (
        <EmptyStop
          setStartTrip={setStartTrip}
          stops={currentTrip?.stops || []}
        />
      )}

      <UndoTripModal
        open={undoModal}
        closeModal={setUndoModal}
        undoTrip={undoTrip}
      />
      <UnassignTripModal
        open={unassignModal}
        closeModal={setUnassignModal}
        unassign={handleUnassign}
      />
      <SkipStopsFlow
        open={skipStopsFlowOpen}
        closeModal={setSkipStopsFlowOpen}
        skipStopsProgress={skipStopsProgress}
      />

      <SliderModal
        clickState={shipmentContent}
        setClickState={setShipmentContent}
      >
        <ShipmentDetailsWrapper
          setContent={setShipmentContent}
          shipmentId={shipId}
        />
      </SliderModal>
      <SliderModal
        clickState={assignDriversOpen}
        setClickState={setAssignDriversOpen}
        width="40"
      >
        <ErrorBoundary message="Assign Driver">
          <AssignDrivers setContent={setAssignDriversOpen} />
        </ErrorBoundary>
      </SliderModal>
      <SliderModal clickState={startTrip} setClickState={setStartTrip}>
        {" "}
        <StartTripModal
          isOpen={startTrip}
          setIsOpen={setStartTrip}
          getTrips={getTrips}
        />
      </SliderModal>
      {/* // */}
      <SliderModal
        width="50"
        clickState={checkCallSliderOpen}
        setClickState={setCheckCallSliderOpen}
      >
        <CheckCalls setCheckCallSliderOpen={setCheckCallSliderOpen} />
      </SliderModal>
      {/* // */}

      <SliderModal
        clickState={spiltTripOpen}
        setClickState={setSpiltTripOpen}
        width="40"
      >
        <SplitTrip setSpiltTripOpen={setSpiltTripOpen} getTrips={getTrips} />
      </SliderModal>
      <SliderModal
        clickState={deliverTripOpen || stopsSkipped}
        setClickState={stopsSkipped ? setStopsSkipped : setDeliverTripOpen}
        width="40"
      >
        <DeliverTrip
          setDeliverTripOpen={
            stopsSkipped ? setStopsSkipped : setDeliverTripOpen
          }
          stopsSkipped={stopsSkipped}
        />
      </SliderModal>
      <SliderModal
        clickState={addTransactionOpen}
        setClickState={setAddTransactionOpen}
      >
        <AddTransaction
          setClickState={setAddTransactionOpen}
          tripInfo={currentTrip}
        />
      </SliderModal>
      <SliderModal clickState={openCharge} setClickState={setOpenCharge}>
        <ShipmentCharge
          setOpenCharge={setOpenCharge}
          shipmentId={currentTrip?.shipmentResponse?.shipmentId}
        />
      </SliderModal>
    </div>
  );
}
