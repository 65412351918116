import InputMiler from "components/inputMiler/InputMiler";
import { useState, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import convertToNumber from "utils/convertToNumber";
import "./CustomerForm.sass";
import { getGatewayApi } from "services/gatewayApis";
import percentDeciConverter from "utils/percentDeciConverter";
import PhoneInput from "react-phone-number-input";
import classNames from "classnames";
import FreightSearchInput from "./FreightSearch";
import CustomerDocuments from "./CustomerDocument";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import SearchCustomer from "./SearchCustomer";
import { geocodeLocationAddress } from "utils/geocodeLocation";

const CustomerForm = ({ state, dispatch, enums, errors }) => {
  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };
  const [salesReps, setSalesReps] = useState([]);
  const isDisabled = ["Do Not Bill", "Do Not Deliver"].includes(
    state.setting.invoiceDeliveryType
  );
  const [useBoxOfficeAddress, setUseBoxOfficeAddress] = useState(false);
  const [useBoxOfficeAddressBilling, setUseBoxOfficeAddressBilling] = useState(
    false
  );

  const getSalesRep = () => {
    getGatewayApi("carrier/admin/user/").then((r) => {
      let filRep = r.data.filter((use) => use.UserType === "Carrier Dispatch");
      let usersList = filRep.map((user) => ({
        keyName: user.FirstName + " " + user.LastName,
        keyValue: user.userId,
      }));
      setSalesReps(usersList);
    });
  };

  const onChangeFreight = (value) => {
    dispatch({ type: "freightGroupId", value });
  };

  const [freights, setFreights] = useState([]);
  const [_selectedFreight, setSelectedFreight] = useState({
    territoryId: state?.territoryId || "",
    territoryName: state?.territoryName || "",
  });
  const [searchOrEnter, setSearchOrEnter] = useState("Search and Add");

  useEffect(() => {
    getSalesRep();
  }, []);

  useEffect(() => {
    if (
      state?.mailingAddress?.addressLine &&
      !state.mailingAddress.countryCode
    ) {
      geocodeLocationAddress(state.mailingAddress.fullAddress).then(
        (locationInfo) => {
          const newAddress = {
            ...state.mailingAddress,
            countryCode: locationInfo.countryCode,
          };

          dispatch({
            type: "address",
            category: "mailingAddress",
            value: newAddress,
          });
        }
      );
    }
  }, [state.mailingAddress]);

  useEffect(() => {
    if (
      !state.billingSameAsMailing &&
      state?.billingAddress?.addressLine &&
      !state.billingAddress.countryCode
    ) {
      geocodeLocationAddress(state.billingAddress.fullAddress).then(
        (locationInfo) => {
          const newAddress = {
            ...state.billingAddress,
            countryCode: locationInfo.countryCode,
          };

          dispatch({
            type: "address",
            category: "billingAddress",
            value: newAddress,
          });
        }
      );
    }
  }, [state.billingAddress]);

  return (
    <div className="customer-form">
      {!state.shipperId && (
        <div className="miler-card mt-0">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select"
                label="Customer Type"
                options={enums.CustomerType}
                value={state.customerType}
                onChange={(e) => {
                  dispatch({
                    type: "draftNewCustomer",
                    payload: {
                      ...state,
                      customerType: e.target.value,
                      shipperName: "",
                    },
                  });

                  if (e.target.value == "Shipper") {
                    setSearchOrEnter("Manual Entry");
                    dispatch({
                      type: "draftNewCustomer",
                      payload: {
                        customerType: e.target.value,
                        shipperName: "",
                        setting: {},
                        usdot: "",
                        mcNumber: "",
                        billingAddress: {},
                        mailingAddress: {},
                      },
                    });
                  } else {
                    setSearchOrEnter("Search and Add");
                  }
                }}
                required
                error={findError("CustomerType")}
              />
            </div>

            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select"
                label="Source"
                options={["Search and Add", "Manual Entry"]}
                value={searchOrEnter}
                onChange={(e) => {
                  setSearchOrEnter(e.target.value);
                }}
                disabled={
                  !state.customerType || state.customerType == "Shipper"
                }
                required
              />
            </div>

            {state.customerType &&
              state.customerType != "Shipper" &&
              searchOrEnter == "Search and Add" && (
                <SearchCustomer
                  setState={(newValues) => {
                    dispatch({
                      type: "draftNewCustomer",
                      payload: { ...newValues, setting: {} },
                    });
                  }}
                  setManualEntry={() => setSearchOrEnter("Manual Entry")}
                  state={state}
                />
              )}
          </div>
        </div>
      )}

      {(state.shipperName ||
        searchOrEnter == "Manual Entry" ||
        state.customerType == "Shipper" ||
        state.shipperId) && (
        <>
          <div className="miler-card mt-0">
            <div className="miler-card-title">GENERAL</div>
            <div className="row">
              {state.shipperId ? (
                <>
                  <div className="col-12 col-lg-6 col-xl-6">
                    <InputMiler
                      type="select"
                      label="Customer Type"
                      options={enums.CustomerType}
                      value={state.customerType}
                      name="customerType"
                      onChange={(e) => {
                        dispatch({
                          type: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      required
                      error={findError("CustomerType")}
                    />
                  </div>

                  <div className="col-12 col-lg-6 col-xl-6">
                    <InputMiler
                      label="Customer Name"
                      name="shipperName"
                      value={state.shipperName}
                      onChange={(e) => {
                        dispatch({
                          type: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      required
                      error={findError("ShipperName")}
                    />
                  </div>
                </>
              ) : (
                <div className="col-12 col-lg-12 col-xl-12">
                  <InputMiler
                    label="Customer Name"
                    name="shipperName"
                    value={state.shipperName}
                    onChange={(e) => {
                      dispatch({ type: e.target.name, value: e.target.value });
                    }}
                    required
                    error={findError("ShipperName")}
                  />
                </div>
              )}

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="select"
                  label="Invoice Delivery Type"
                  options={enums.InvoiceDeliveryType}
                  value={state.setting.invoiceDeliveryType}
                  onChange={(e) => {
                    dispatch({
                      type: "invoiceDeliveryType",
                      category: "setting",
                      value: e.target.value,
                    });
                  }}
                  required
                  error={findError("Setting.InvoiceDeliveryType")}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="select"
                  label="Invoice Requirement"
                  options={enums.InvoiceRequirement}
                  value={state.setting.invoiceRequirement}
                  onChange={(e) => {
                    dispatch({
                      type: "invoiceRequirement",
                      category: "setting",
                      value: e.target.value,
                    });
                  }}
                  error={findError("Setting.InvoiceRequirement")}
                  disabled={isDisabled}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="USDOT Number"
                  name="usdot"
                  value={state.usdot}
                  onChange={(e) => {
                    dispatch({ type: e.target.name, value: e.target.value });
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="MC Number"
                  name="mcNumber"
                  value={state.mcNumber}
                  onChange={(e) => {
                    dispatch({ type: e.target.name, value: e.target.value });
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="select2"
                  options={salesReps}
                  label="Sales Representative"
                  value={state.setting.salesRepresentative || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "salesRepresentative",
                      category: "setting",
                      value: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <FreightSearchInput
                  _selectedFreight={_selectedFreight}
                  setSelectedFreight={setSelectedFreight}
                  freights={freights}
                  setFreights={setFreights}
                  onChangeFreight={onChangeFreight}
                  value={{
                    freightGroupId: state?.territoryId || "",
                    label: _selectedFreight?.territoryName || "",
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="number"
                  label="Credit Limit"
                  value={state.setting.creditLimit || ""}
                  onChange={(e) => {
                    dispatch({
                      type: "creditLimit",
                      category: "setting",
                      value: convertToNumber(e.target.value),
                    });
                  }}
                  error={findError("Setting.CreditLimit")}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="number"
                  label="Credit Warning"
                  value={percentDeciConverter(state.setting.creditWarning)}
                  postfix="%"
                  onChange={(e) => {
                    let a = percentDeciConverter(e.target.value, "divide");
                    dispatch({
                      type: "creditWarning",
                      category: "setting",
                      value: a,
                    });
                  }}
                  error={findError("Setting.CreditWarning")}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="text"
                  name="primaryEmail"
                  label="Primary Email"
                  value={state.primaryEmail || ""}
                  onChange={(e) => {
                    dispatch({ type: e.target.name, value: e.target.value });
                  }}
                  error={findError("PrimaryEmail")}
                  required={state.setting.invoiceDeliveryType === "Email"}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  type="select"
                  label="Currency used"
                  options={enums.CurrencyType}
                  value={state.setting.currencyUsed}
                  onChange={(e) => {
                    dispatch({
                      type: "currencyUsed",
                      category: "setting",
                      value: e.target.value,
                    });
                  }}
                  error={findError("Setting.CurrencyUsed")}
                />
              </div>
            </div>
          </div>
          {state?.inNetwork && (
            <div className="customer_docs">
              <CustomerDocuments
                documentUrl={`Shipper/${state.shipperId}/CarrierPacket`}
                documentCategory="Company"
                docTitle="Carrier Packet"
              />
            </div>
          )}
          <div className="miler-card">
            <div className="miler-card-title">MAILING INFORMATION</div>
            <div className="row">
              <div className="col-12 col-lg-12 col-xl-12">
                <div className="d-flex aligin-items-center mb-1">
                  <div className="mr-3 ml-1">Address</div>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      name="check"
                      id="UseBoxOffice"
                      checked={useBoxOfficeAddress}
                      onChange={(e) => {
                        setUseBoxOfficeAddress(e.target.checked);
                      }}
                    />
                    <Label for="UseBoxOffice" className="shadow-none" check>
                      Use P.O. Box
                    </Label>
                  </FormGroup>
                </div>
                {!useBoxOfficeAddress && (
                  <AddressSearcher
                    address={state.mailingAddress}
                    hideLabel
                    dispatch={(newAddress) =>
                      dispatch({
                        type: "address",
                        category: "mailingAddress",
                        value: newAddress,
                      })
                    }
                    error={findError("MailingAddress.AddressLine")}
                  />
                )}
                {useBoxOfficeAddress && (
                  <InputMiler
                    value={state.mailingAddress?.addressLine}
                    onChange={(e) => {
                      dispatch({
                        type: "address",
                        category: "mailingAddress",
                        value: {
                          ...state.mailingAddress,
                          addressLine: e.target.value,
                        },
                      });
                    }}
                  />
                )}
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="City"
                  value={state.mailingAddress && state.mailingAddress.city}
                  error={findError("MailingAddress.City")}
                  disabled={!useBoxOfficeAddress}
                  onChange={(e) => {
                    dispatch({
                      type: "address",
                      category: "mailingAddress",
                      value: {
                        ...state.mailingAddress,
                        city: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="State"
                  value={state.mailingAddress && state.mailingAddress.state}
                  error={findError("MailingAddress.State")}
                  disabled={!useBoxOfficeAddress}
                  onChange={(e) => {
                    dispatch({
                      type: "address",
                      category: "mailingAddress",
                      value: {
                        ...state.mailingAddress,
                        state: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="Country"
                  value={
                    state.mailingAddress && state.mailingAddress.countryCode
                  }
                  error={findError("MailingAddress.CountryCode")}
                  type="select"
                  options={["us", "ca"]}
                  disabled={!useBoxOfficeAddress}
                  onChange={(e) => {
                    dispatch({
                      type: "address",
                      category: "mailingAddress",
                      value: {
                        ...state.mailingAddress,
                        countryCode: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="Postal Code"
                  value={
                    state.mailingAddress && state.mailingAddress.postalCode
                  }
                  error={findError("MailingAddress.PostalCode")}
                  disabled={!useBoxOfficeAddress}
                  onChange={(e) => {
                    dispatch({
                      type: "address",
                      category: "mailingAddress",
                      value: {
                        ...state.mailingAddress,
                        postalCode: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="First Name"
                  name="firstName"
                  value={state.mailingContact && state.mailingContact.firstName}
                  onChange={(e) => {
                    dispatch({
                      type: e.target.name,
                      category: "mailingContact",
                      value: e.target.value,
                    });
                  }}
                  error={findError("MailingContact.FirstName")}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="Last Name"
                  name="lastName"
                  value={state.mailingContact && state.mailingContact.lastName}
                  onChange={(e) => {
                    dispatch({
                      type: e.target.name,
                      category: "mailingContact",
                      value: e.target.value,
                    });
                  }}
                  error={findError("MailingContact.LastName")}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="Email"
                  name="email"
                  value={state.mailingContact && state.mailingContact.email}
                  onChange={(e) => {
                    dispatch({
                      type: e.target.name,
                      category: "mailingContact",
                      value: e.target.value,
                    });
                  }}
                  error={findError("MailingContact.Email")}
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <label className="phone-input-label" htmlFor="phone">
                  Phone Number
                </label>
                <PhoneInput
                  className={classNames("phone-input", {
                    "phone-error": findError(
                      `MailingContact.Phones[${0}].PhoneNumber`
                    ),
                  })}
                  defaultCountry="US"
                  international
                  value={state.mailingContact?.dayPhone}
                  placeholder="Enter phone number"
                  onChange={(e) => {
                    dispatch({
                      type: "dayPhone",
                      category: "mailingContact",
                      value: e,
                    });
                  }}
                  name="phoneNumber"
                />
                <span className="phone-error-message">
                  {findError(`MailingContact.Phones[${0}].PhoneNumber`)}
                </span>
              </div>
            </div>
          </div>

          <div className="miler-card">
            <div className="miler-card-title">BILLING INFORMATION</div>
            <div className="row mb-1">
              <div className=" col-md-4 ">
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="check"
                    checked={state.billingSameAsMailing}
                    onChange={(e) => {
                       dispatch({
                        type: "billingSameAsMailing",
                        value: e.target.checked,
                      })
                    }
                    }
                  />
                  <Label for="same" className="shadow-none" check>
                    Same as Mailing  
                  </Label>
                </FormGroup>
              </div>
            </div>

            {!state.billingSameAsMailing && (
              <div className="row">
                <div className="col-12 col-lg-12 col-xl-12">
                  <div className="d-flex aligin-items-center mb-1 mt-3">
                    <div className="mr-3 ml-1">Address</div>
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        name="check"
                        id="UseBoxOfficeBill"
                        checked={useBoxOfficeAddressBilling}
                        onChange={(e) => {
                          setUseBoxOfficeAddressBilling(e.target.checked);
                        }}
                      />
                      <Label
                        for="UseBoxOfficeBill"
                        className="shadow-none"
                        check
                      >
                        Use P.O. Box
                      </Label>
                    </FormGroup>
                  </div>
                  {!useBoxOfficeAddressBilling && (
                    <AddressSearcher
                      address={state.billingAddress}
                      dispatch={(newAddress) =>
                        dispatch({
                          type: "address",
                          category: "billingAddress",
                          value: newAddress,
                        })
                      }
                      error={findError("BillingAddress.AddressLine")}
                      hideLabel
                    />
                  )}
                  {useBoxOfficeAddressBilling && (
                    <InputMiler
                      value={state.billingAddress?.addressLine}
                      onChange={(e) => {
                        dispatch({
                          type: "address",
                          category: "billingAddress",
                          value: {
                            ...state.billingAddress,
                            addressLine: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </div>

                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="City"
                    name="city"
                    value={state.billingAddress && state.billingAddress.city}
                    disabled={!useBoxOfficeAddressBilling}
                    onChange={(e) => {
                      dispatch({
                        type: "address",
                        category: "billingAddress",
                        value: {
                          ...state.billingAddress,
                          city: e.target.value,
                        },
                      });
                    }}
                    error={findError("BillingAddress.City")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="State"
                    name="state"
                    value={state.billingAddress && state.billingAddress.state}
                    disabled={!useBoxOfficeAddressBilling}
                    onChange={(e) => {
                      dispatch({
                        type: "address",
                        category: "billingAddress",
                        value: {
                          ...state.billingAddress,
                          state: e.target.value,
                        },
                      });
                    }}
                    error={findError("BillingAddress.State")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="Country"
                    name="countryCode"
                    value={
                      state.billingAddress && state.billingAddress.countryCode
                    }
                    type="select"
                    options={["us", "ca"]}
                    disabled={!useBoxOfficeAddressBilling}
                    onChange={(e) => {
                      dispatch({
                        type: "address",
                        category: "billingAddress",
                        value: {
                          ...state.billingAddress,
                          countryCode: e.target.value,
                        },
                      });
                    }}
                    error={findError("BillingAddress.CountryCode")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="Postal Code"
                    name="postalCode"
                    value={
                      state.billingAddress && state.billingAddress.postalCode
                    }
                    disabled={!useBoxOfficeAddressBilling}
                    onChange={(e) => {
                      dispatch({
                        type: "address",
                        category: "billingAddress",
                        value: {
                          ...state.billingAddress,
                          postalCode: e.target.value,
                        },
                      });
                    }}
                    error={findError("BillingAddress.PostalCode")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="First Name"
                    name="firstName"
                    value={
                      state.billingContact && state.billingContact.firstName
                    }
                    onChange={(e) => {
                      dispatch({
                        type: e.target.name,
                        category: "billingContact",
                        value: e.target.value,
                      });
                    }}
                    error={findError("BillingContact.FirstName")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="Last Name"
                    name="lastName"
                    value={
                      state.billingContact && state.billingContact.lastName
                    }
                    onChange={(e) => {
                      dispatch({
                        type: e.target.name,
                        category: "billingContact",
                        value: e.target.value,
                      });
                    }}
                    error={findError("BillingContact.LastName")}
                  />
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <InputMiler
                    label="Email"
                    name="email"
                    value={state.billingContact && state.billingContact.email}
                    onChange={(e) => {
                      dispatch({
                        type: e.target.name,
                        category: "billingContact",
                        value: e.target.value,
                      });
                    }}
                    error={findError("BillingContact.Email")}
                  />
                </div>
                <div key="mailingphone" className="col-12 col-lg-6 col-xl-4">
                  <label className="phone-input-label" htmlFor="phone">
                    Phone Number
                  </label>
                  <PhoneInput
                    className={classNames("phone-input", {
                      "phone-error": findError(
                        `BillingContact.Phones[${0}].PhoneNumber`
                      ),
                    })}
                    defaultCountry="US"
                    international
                    value={state.billingContact?.dayPhone}
                    placeholder="Enter phone number"
                    onChange={(e) => {
                      dispatch({
                        type: "dayPhone",
                        category: "billingContact",
                        value: e,
                      });
                    }}
                    name="phoneNumber"
                  />
                  <span className="phone-error-message">
                    {findError(`BillingContact.Phones[${0}].PhoneNumber`)}
                  </span>
                </div>
                {/* <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label={`Phone Number`}
              name="phoneNumber"
              type="number"
              value={
                state.billingContact &&
                state.billingContact.phones[0].phoneNumber
              }
              onChange={(e) => {
                dispatch({
                  type: e.target.name,
                  category: "billingContact",
                  value: {
                    phoneType: "Day Phone",
                    phoneNumber: e.target.value,
                  },
                });
              }}
              error={findError(`BillingContact.Phones[${0}].PhoneNumber`)}
            />
          </div> */}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerForm;
