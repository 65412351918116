import findError from "utils/findError";
import { Card } from "reactstrap";
import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import DriverSearcher from "components/inputMiler/advanceSearch/DriverSearcher";
import InputMiler from "components/inputMiler/InputMiler";

const GeneralDetails = ({ dispatch, state, errors }) => {
  return (
    <div className="mt-3">
      <Card style={{ padding: "10px" }}>
        <p className="shipment-detail-title m-2">General Details</p>
        <div className="row p-3">
          <div className="col-12 col-lg-6">
            <InputMiler
              value={state?.shipmentNumber}
              label="Shipment #"
              error={findError("ShipmentNumber", errors)}
              onChange={(e) =>
                dispatch({ action: "shipmentNumber", value: e.target.value })
              }
            />
          </div>
          <div className="col-12 col-lg-6">
            <Equipmentsearcher
              value={state?.unitNumber}
              onItemSelect={(equipment) => {
                dispatch({
                  action: "vehicleId",
                  value: equipment.split.unitId,
                });
                dispatch({
                  action: "unitNumber",
                  value: equipment.split.unitNumber,
                });
                dispatch({
                  action: "driverId",
                  value: equipment.driver.driverId,
                });
                dispatch({
                  action: "driverName",
                  value: equipment.driver.name,
                });
              }}
              label="Vehicle"
              error={findError("TruckId", errors)}
              eqType="Vehicle"
            />
          </div>
          <div className="col-12 col-lg-6">
            <DriverSearcher
              value={state?.driverName}
              onItemSelect={(res) => {
                dispatch({ action: "driverId", value: res.id });
                dispatch({ action: "driverName", value: res.driver.driver });
                dispatch({
                  action: "driverNumber",
                  value: res.driver.driverNumber,
                });
              }}
              label="Driver"
              error={findError("driverNumber", errors)}
            />
          </div>

          <div className="col-12 col-lg-6">
            <Equipmentsearcher
              value={state?.trailerNumber}
              onItemSelect={(equipment) => {
                dispatch({
                  action: "trailerId",
                  value: equipment.split.unitId,
                });
                dispatch({
                  action: "trailerNumber",
                  value: equipment.split.unitNumber,
                });
              }}
              label="Trailer"
              error={findError("trailerId", errors)}
              eqType="Trailer"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GeneralDetails;
