import { useEffect, useState } from "react";
import { getElementsV2 } from "services/milerApi";
import SettlementCard from "./SettlementCard";
import "./DriverSettlement.sass";
import Loading from "components/loading/Loading";

const DriverSettlements = ({ driverId }) => {
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState()

  useEffect(() => {
    getSettlements()
  }, []);

  const getSettlements = () => {
    const promises = [];

    for (var i = 1; i < 9; i++) {
      const promise = getElementsV2(
      {
        url: `/Driver/${driverId}/settlement?offset=${i + 1}`,
        setLoading: setLoading
      }
      );
      promises.push(promise);
    }

    Promise.all(promises).then((settlementsBatch) => {
      const settlementsTemp = [];
      settlementsBatch.forEach((settlementResponse) => {
        settlementsTemp.push(settlementResponse.data);
      });
      setSettlements(settlements => {
        return [...settlements, ...settlementsTemp]
      });

    }).finally(() => {})
  };

  return (
    <div>
      {settlements.map((settlement, index) => (
        <SettlementCard settlement={settlement} key={`${index}`} />
      ))}

      { loading && <Loading />}
    </div>
  );
};

export default DriverSettlements;
