import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import SliderModal from "components/sliderModal/SliderModal";
import DriverEditDetails from "views/activities-v2/driverEditDetails/DriverEditDetail";
import "./DriverInfo.sass";
import Dialer from "components/call/dialer";
import { postCallElement } from "services/communicationsApi";
import EmailComponent from "components/email/Email";
const DriverInfo = ({
  firstName,
  lastName,
  driverNumber,
  driverId,
  driverPhone,
  driverUserId,
  driverEmail,
  coDriverEmail,
}) => {
  const fullName = `${firstName || ""} ${lastName || ""}`;
  const [driverDetails, setDriverDetails] = useState(false);
  const [driver, setDriver] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [open, setOpen] = useState(false);
  const [callStatus, setCallStatus] = useState({
    iniateCall: false,
    calling: false,
  });
  const handleCallPrompt = () => {
    setOpen(true);
  };

  const confirmSenderEmail = () => {
    if (driverEmail) {
      return driverEmail;
    }

    if (coDriverEmail) {
      return coDriverEmail;
    }

    return "";
  };
  const [openEmailComponent, setOpenEmailComponent] = useState(false);

  const makeCall = () => {
    setCallStatus({
      ...callStatus,
      iniateCall: true,
      calling: false,
    });
    postCallElement(`/Call/User/${driverUserId}`)
      .then((response) => {
        if (response.success) {
          setCallStatus({
            ...callStatus,
            iniateCall: false,
            calling: response.success,
          });
        }
      })
      .catch(() => {
        setCallStatus({
          ...callStatus,
          iniateCall: false,
        });
      });
  };

  return (
    <div className="activity-driver-card">
      <ProfilePlaceholder
        className="activity-driver-placeholder"
        jointName={fullName}
      />
      <div className="driver-info">
        <div className="driver-info-name">
          {fullName}{" "}
          {driverNumber ? (
            <span
              className="driver_number_link"
              onClick={() => {
                setDriver(driverId);
                setDriverDetails(true);
              }}
            >
              {driverNumber && `(${driverNumber})`}
            </span>
          ) : null}
        </div>
        <div className="driver-info-contact">
          <span className="">
            <MilerButton
              onClick={() => {
                handleCallPrompt();
                setPhoneNumber(driverPhone);
              }}
              icon="mil-phone-f"
              title="Calls"
              xs
            />
          </span>
          {open && (
            <Dialer
              open={open}
              closeModal={() => {
                setOpen(false);
                setCallStatus(false);
              }}
              phoneNumber={phoneNumber}
              firstName={firstName}
              lastName={lastName}
              onClick={() => makeCall()}
              callStatus={callStatus}
            />
          )}

          <span>
            <MilerButton
              onClick={() => {
                setOpenEmailComponent(true);
              }}
              icon="mil-email-at"
              title="Email"
              xs
            />
          </span>
          <span>
            <MilerButton icon="mil-email-o" title="Text" xs />
          </span>
        </div>
      </div>

      <SliderModal clickState={driverDetails} setClickState={setDriverDetails}>
        <DriverEditDetails
          driverId={driver}
          setDriverDetails={setDriverDetails}
        />
      </SliderModal>
      <SliderModal
        clickState={openEmailComponent}
        setClickState={setOpenEmailComponent}
        width="50"
      >
        <EmailComponent
          setOpenEmail={setOpenEmailComponent}
          senderEmailAddress={confirmSenderEmail()}
          carrierName={fullName}
          isDriverActivityCard={true}
        />
      </SliderModal>
    </div>
  );
};

export default DriverInfo;
