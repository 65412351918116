import ReactDOM from "react-dom";
import AppWrapper from "./App";
import { AppContext } from "services/AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { MessagingContextProvider } from "services/contexts/MessagingContext";
import { WhatsAppContextProvider } from "services/contexts/MessagingContext";
ReactDOM.render(
  <AppContext>
    <Router>
      <WhatsAppContextProvider>
        <MessagingContextProvider>
          <AppWrapper />
        </MessagingContextProvider>
      </WhatsAppContextProvider>
    </Router>
  </AppContext>,
  document.getElementById("root")
);
