import DateTimePicker from "react-datetime-picker";
import dateFormatter from "utils/dateFormater";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./date-time-input.sass";

const DateTimeInput = ({
  value,
  onChange,
  label,
  required,
  error,
  type,
  disabled,
  maxDate = new Date("9999-12-31"),
  minDate = new Date("1900-01-01"),
}) => {
  let format = "MM/dd/yyyy, HH:mm";
  type === "date"
    ? (format = "MM/dd/yyyy")
    : type === "time"
    ? (format = "HH:mm")
    : (format = "MM/dd/yyyy, HH:mm");

  return (
    <div className="date-time-input">
      <span>{label}</span>
      {required && <span className="required-red">*</span>}
      <DateTimePicker
        format={format}
        value={value ? new Date(dateFormatter(value, "", true)) : ""}
        onChange={(dateValue) => {
          onChange(dateFormatter(dateValue || ""));
        }}
        maxDate={type === "time" ? null : maxDate}
        minDate={type === "time" ? null : minDate}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        disableClock
        disableCalendar={type === "time"}
        disabled={disabled}
      />

      <small
        style={{
          color: "red",
        }}
      >
        {error}
      </small>
    </div>
  );
};

export default DateTimeInput;
