import DriverSearcher from "components/inputMiler/advanceSearch/DriverSearcher";
import MilerButton from "components/milerButton/MilerButton";
import { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { postElement } from "services/milerApi";

const AssignVehicleModal = ({
  isOpen,
  setIsOpen,
  getVehicles,
  getVehicle,
  fxn,
}) => {
  const { vehicleId, unitNumber, driver, coDriver } = isOpen;
  const closeModal = () => {
    setIsOpen(false);
    fxn();
    // setMainDriver({});
    // setDeputyDriver({});
  };
  const setDefaultValues = (driver) => {
    let a = { driverId: "", name: "" };
    if (driver) {
      a.driverId = driver.driverId || "";
      a.name = driver.name || "";
    }
    return a;
  };

  const [mainDriver, setMainDriver] = useState({
    driverId: "",
    name: "",
  });

  const [deputyDriver, setDeputyDriver] = useState({
    driverId: "",
    name: "",
  });

  const assignVehicle = () => {
    if (mainDriver.driverId || deputyDriver.driverId)
      postElement(
        `/Equipment/VehicleAssignment?vehicleId=${vehicleId}&driverId=${mainDriver.driverId}&coDriverId=${deputyDriver.driverId}`,
        {}
      )
        .then(() => {
          getVehicles();
          getVehicle();
        })
        .then(() => {
          closeModal();
        });
  };

  const IntiateDefault = () => {
    setMainDriver(setDefaultValues(driver));
    setDeputyDriver(setDefaultValues(coDriver));
  };

  useEffect(IntiateDefault, [isOpen]);
  return (
    <Modal isOpen={Boolean(isOpen)} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Assign Vehicle To Driver</ModalHeader>
      <ModalBody>
        <div className="text-center mb-3">
          Unit Number: <h5 className="d-inline-block">{unitNumber}</h5>
        </div>
        <DriverSearcher
          placeholder="Search by driver number"
          label="Driver"
          onItemSelect={(item) =>
            setMainDriver({ driverId: item.id, name: item.driver.driver })
          }
          value={mainDriver.name}
        />
        <DriverSearcher
          label="co-Driver"
          placeholder="Search by driver number"
          onItemSelect={(item) =>
            setDeputyDriver({ driverId: item.id, name: item.driver.driver })
          }
          value={deputyDriver.name}
        />
      </ModalBody>
      <ModalFooter>
        <MilerButton title="Assign" onClick={assignVehicle} />
      </ModalFooter>
    </Modal>
  );
};

export default AssignVehicleModal;
