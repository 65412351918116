import { useEffect, useState } from "react";
import Loading from "components/loading/Loading";
import { Card } from "reactstrap";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { digitsToCurrency } from "utils/currencyFormatter";
import "./CustomerRating.sass";
import { getGatewayApi } from "services/gatewayApis";

function CustomerRating({ usdotNumber, mcNumber, setCustomerRatingOpen }) {
  const [carrierRatingDetails, setCarrierRatingDetails] = useState();
  const [loading, setLoading] = useState(<Loading />);

  const getCarrierRating = () => {
    getGatewayApi(
      `carrier/integration/misc/fmcsa/carrier?dot_number=${
        usdotNumber ?? ""
      }&mc_number=${mcNumber ?? ""}`
    )
      .then((response) => {
        if (response.data?.data?.length) {
          setCarrierRatingDetails(response.data.data[0]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(<div>Unable to fetch.</div>);
      });
  };

  useEffect(() => {
    if (mcNumber || usdotNumber) getCarrierRating();
  }, [mcNumber, usdotNumber]);
  const getAuthorityStatusClass = (status) => {
    if (!status || status === "NONE") {
      return "";
    }
    return status === "ACTIVE" ? "authorised rate_title" : "not_authorised rate_title";
  };

  const getAuthorityDisplayText = (status) => {
    if (!status || status === "NONE") {
      return "_";
    }
    return status;
  };
  return (
    <div className="slider-display carrier-details carrier-ratings">
      <div className="slider-display-header">
        <div className="carrier-ratings-header">
          <div className="customer-details-info">
            <div>
              <div className="cst-rating-status">
                {carrierRatingDetails && (
                  <h6 className="cst-rating-number">
                    {`${capitalizeFirstLetter(
                      carrierRatingDetails?.company || ""
                    )} `}

                    <span
                      style={{ marginRight: "3px", textTransform: "lowercase" }}
                    >
                      {" "}
                      is{" "}
                    </span>
                    <p className="rating-status">
                      <span
                        className={`${
                          carrierRatingDetails?.authority_active
                            ? "authorised"
                            : "not_authorised"
                        }`}
                      >
                        {carrierRatingDetails?.authority_active
                          ? "Authorized"
                          : "Not Authorized"}
                      </span>
                      {carrierRatingDetails?.authority_type && (
                        <span
                          className="operation-details"
                          style={{ marginLeft: "4px" }}
                        >
                          {`${carrierRatingDetails?.authority_type}`}
                        </span>
                      )}
                    </p>
                  </h6>
                )}
              </div>
              <span className="cst-ratings__info-description">
                DOT #{" "}
                {`${capitalizeFirstLetter(
                  carrierRatingDetails?.dot_number?.toString() || ""
                )} `}
                {carrierRatingDetails?.docket_numbers?.length === 1 && (
                  <>
                    | MC #{" "}
                    {`${capitalizeFirstLetter(
                      carrierRatingDetails.docket_numbers[0]?.docket_number?.toString() ||
                        ""
                    )} `}
                  </>
                )}
              </span>
            </div>

            <i
              className="mil-close-bg drv-dtls__close"
              onClick={() => setCustomerRatingOpen(false)}
              style={{ fontSize: "1.8rem", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>

      <div className="slider-display-body">
        {loading ? (
          loading
        ) : (
          <>
            <Card>
              <div className="p-2">
                <h5 className="ratings_header_title">General</h5>
                <div className="row">
                  <div className="col col-md-4,">
                    <span className="rate_label">Address</span>
                    <p className="rate_title">
                      {carrierRatingDetails?.address
                        ? `${carrierRatingDetails.address.addressLine || ""}`
                        : "_"}
                    </p>
                    <p className="rate_address">
                      {carrierRatingDetails?.address
                        ? `${carrierRatingDetails.address.city || ""}, ${
                            carrierRatingDetails.address.state || ""
                          }  ${carrierRatingDetails.address.postalCode || ""}`
                        : "_"}
                    </p>
                  </div>
                  <div className="col col-md-4">
                    <span className="rate_label">DBA</span>
                    <p className="rate_title">
                      {carrierRatingDetails?.dba || "_"}
                    </p>
                  </div>
                  <div className="col col-md-4">
                    <span className="rate_label">Years of Service</span>
                    <p className="rate_title">
                      {carrierRatingDetails?.dot_aged
                        ? "2 years or more"
                        : "Less than 2 years"}
                    </p>
                  </div>
                </div>
              </div>
            </Card>

            <Card className="mt-3">
              <div className="p-2">
                <h5 className="ratings_header_title">Authority</h5>
                <div className="row">
                  <div className="col col-md-4">
                    <span className="rate_label">Common Carrier Authority</span>
                    <p
                      className={getAuthorityStatusClass(
                        carrierRatingDetails?.authority_status_common
                      )}
                    >
                      {getAuthorityDisplayText(
                        carrierRatingDetails?.authority_status_common
                      )}
                    </p>
                  </div>

                  <div className="col col-md-4">
                    <span className="rate_label">
                      Contract Carrier Authority
                    </span>
                    <p
                      className={getAuthorityStatusClass(
                        carrierRatingDetails?.authority_status_contract
                      )}
                    >
                      {getAuthorityDisplayText(
                        carrierRatingDetails?.authority_status_contract
                      )}
                    </p>
                  </div>

                  <div className="col col-md-4">
                    <span className="rate_label">Broker Authority</span>
                    <p
                      className={getAuthorityStatusClass(
                        carrierRatingDetails?.authority_status_broker
                      )}
                    >
                      {getAuthorityDisplayText(
                        carrierRatingDetails?.authority_status_broker
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>

            <Card className="mt-3">
              <div className="p-2">
                <h5 className="ratings_header_title">Cargo</h5>
                <div>
                  {carrierRatingDetails?.cargo_carried?.map((cargo) => (
                    <div
                      key={cargo?.id}
                      style={{
                        padding: "2px 6px",
                        border: "1px solid",
                        display: "inline-block",
                        backgroundColor: "#f3f3f3",
                        borderRadius: "2px",
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {cargo?.cargo}
                    </div>
                  ))}
                </div>
              </div>
            </Card>
            <Card className="p-2 mt-3">
              <div className="p-2">
                <h5 className="ratings_header_title">Insurance</h5>
                <div className="row">
                  <div className="col col-md-4,">
                    <span className="rate_label">Type</span>
                    <p className="rate_title">{"Bond"}</p>
                  </div>
                  <div className="col col-md-4">
                    <span className="rate_label">Amount Required</span>
                    <p className="rate_title">
                      {carrierRatingDetails?.insurance?.bond_insurance_required
                        ? digitsToCurrency(
                            carrierRatingDetails?.insurance
                              ?.bond_insurance_on_file
                          )
                        : "0"}
                    </p>
                  </div>
                  <div className="col col-md-4">
                    <span className="rate_label">Amount On-File</span>
                    <p className="rate_title">
                      {carrierRatingDetails?.insurance?.bond_insurance_on_file
                        ? digitsToCurrency(
                            carrierRatingDetails?.insurance
                              ?.bond_insurance_on_file
                          )
                        : "0"}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
}

export default CustomerRating;
