import { RiMessage2Fill } from "react-icons/ri";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

const MessagingComponent = () => {
  return (
    <div>
      <div className="pr-0" >
        <Tooltip title="Messages">
          {/* <AntBadge count={messages.length > 99 ? "99+" : messages.length}> */}
          <Link to="messages">
            <RiMessage2Fill size={32} fill="#202f53" />
          </Link>
          {/* </AntBadge> */}
        </Tooltip>
      </div>
    </div>
  );
};

export default MessagingComponent;
