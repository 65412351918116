import { useContext, useState, useEffect } from "react";
import SliderModal from "components/sliderModal/SliderModal";
import ShipmentSummary from "views/shipments/shipmentSummary/ShipmentSummary";
import { getElementsV2 } from "services/milerApi";
import ShipmentDetailsWrapper from "views/shipments/shipmentDetails/ShipmentDetailsWrapper";
import getCarrierId from "utils/getCarrierId";
import { ToolBarContext } from "layout/toolbar/context/ToolbarContext";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import { useLocation } from "react-router-dom";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";

const Shipments = ({ url, useAsUtil, activeTab }) => {
  const { dispatch } = useContext(ToolBarContext);
  const [shipmentContent, setShipmentContent] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(<></>);

  const [shipId, setShipId] = useState();

  const location = useLocation();

  const [request, setRequest] = useState({
    CarrierId: getCarrierId(),
    status: location.state?.status || "",
    pickup_from: "",
    pickup_to: "",
    dropoff_from: "",
    dropoff_to: "",
    limit: 50,
    offset: 1,
    shipment_number: "",
    reference_number: "",
    dedicated: null,
    overdue: location?.state?.overdue || false,
  });

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const getShipments = () => {
    if (!request.overdue) {
      delete request.overdue;
    }
    request.status = request.status.replace(" ", "");
    const queryStr = new URLSearchParams(request).toString();
    {
      !useAsUtil && dispatch({ type: "resetCount", payload: "Shipments" });
    }
    const shipmentUrl = isFleetOwner
      ? `/FleetOwner/Shipment`
      : url || "/Shipment";

    getElementsV2({
      url: `${shipmentUrl}?${queryStr}`,
      setLoading: setLoading,
    })
      .then((response) => {
        setShipments(response.data || []);

        return response;
      })
      .then((response) => {
        {
          !useAsUtil &&
            dispatch({
              type: "setCurrent",
              payload: {
                current: "Shipments",
                count: response?.data?.length || "",
              },
            });
        }
      })
  };

  useEffect(getShipments, [request, url]);

  return (
    <div className="shipment">
      <ErrorBoundary message="Shipments">
        <ShipmentSummary
          nextExists={shipments?.length >= request.limit}
          shipmentsSummaryList={shipments}
          setShipmentContent={setShipmentContent}
          setShipId={setShipId}
          getShipments={getShipments}
          setRequest={setRequest}
          request={request}
          loading={loading}
          setLoading={setLoading}
          useAsUtil={useAsUtil}
          activeTab={activeTab}
        />
        <SliderModal
          clickState={shipmentContent}
          setClickState={setShipmentContent}
        >
          <ShipmentDetailsWrapper
            getShipments={getShipments}
            setContent={setShipmentContent}
            shipmentId={shipId}
          />
        </SliderModal>
      </ErrorBoundary>
    </div>
  );
};

export default Shipments;
