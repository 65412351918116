import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getElements } from "services/milerApi";
import NewPayee from "./NewPayee";

const PayeeSelector = ({
  selectedPayee,
  onSelect,
  error,
  hideCreate = false,
  driveName,
}) => {
  const [openNewPayee, setOpenNewPayee] = useState(false);
  const [_selectedPayee, setSelectedPayee] = useState(
    selectedPayee?.accountType === "Owner Operator" && {
      ...(selectedPayee || {}),
      label: selectedPayee?.name || "",
    }
  );
  const [payees, setPayees] = useState([]);
  const getPayees = (value) => {
    if (value) {
      const lowerCasePayeeName = value.toLowerCase();
      getElements(
        `/Carrier/Payee/Search?name=${lowerCasePayeeName}&payeeType=OwnerOperator`
      ).then((response) => {
        setPayees(
          (response.data || []).map((p) => ({ ...p, label: p.name || "" }))
        );
      });
    }
  };

  const onChange = (payee) => {
    if (payee) {
      if (payee?.__isNew__) {
        setOpenNewPayee(true);
      }
      setSelectedPayee(payee);
      onSelect(payee);
    } else {
      let newValue = {};
      setSelectedPayee(newValue);
      onSelect(newValue);
    }
  };

  const onPayeeCreated = (payee) => {
    onChange(payee);
  };

  return (
    <div>
      <label className="m-0">Payee</label>
      {hideCreate ? "" : <span className="required-red">*</span>}
      <CreatableSelect
        isClearable={true}
        //include create button
        isValidNewOption={() => true}
        //label of the create button
        formatCreateLabel={() => (hideCreate ? "" : "Create New Payee")}
        //position of the create button
        createOptionPosition="first"
        onChange={onChange}
        onInputChange={getPayees}
        //Additional property to search by.
        options={payees}
        value={_selectedPayee}
        placeholder="search payee name or code"
      />
      {error && (
        <div style={{ color: "#dc3545", fontSize: "12.25px" }}>{error}</div>
      )}
      <SliderModal clickState={openNewPayee} setClickState={setOpenNewPayee}>
        <NewPayee
          setOpenNewPayee={setOpenNewPayee}
          state={{
            name: driveName,
          }}
          callback={onPayeeCreated}
        />
      </SliderModal>
    </div>
  );
};

export default PayeeSelector;
