import { useEffect, useRef } from "react";
import "./NoteCard.sass";

const NoteCard = ({ note,isTextareaFocused,setIsTextareaFocused }) => {
  const textareaRef = useRef(null);
//   const [isTextareaFocused, setIsTextareaFocused] = useState(true);

  useEffect(() => {
    function handleClickOutside(event) {
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        setIsTextareaFocused(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTextareaFocused]);
  return (
    <>
      {isTextareaFocused && (
        <section className="note_container" ref={textareaRef}>
          <p>{note}</p>
        </section>
      )}
    </>
  );
};

export default NoteCard;
