import CellBuilder from "components/cellBuilderTable/CellBuilder";
 import { TbArrowsSplit2 } from "react-icons/tb";
 

 export const masterShipmentColumns = [
    {
      name: "SHIPMENT #",
      selector: (row) => row.shipmentNumber,
      cell: (row) =>
        CellBuilder(
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
           
            {row.shipmentNumber}
            {row.isSplit && (
              <TbArrowsSplit2
                className="split-icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={"Split shipment"}
              />
            )}
           
          </span>,
          row.shipperName
        ),
      grow: 7,
    },
    {
      name: "MODE",
      selector: (row) => row.shipmentMode,
      grow: 2,
      cell: (row) => CellBuilder(row.shipmentMode, row.trailerType),
    },
    {
      name: "PICKUP",
      selector: (row) => row.scheduledPickupUTC,
      grow: 7,
      cell: (row) => CellBuilder(row.pickup, row.scheduledPickUpTime),
    },
    {
      name: "DROPOFF",
      selector: (row) => row.scheduledDeliveryUTC,
      grow: 7,
      cell: (row) => CellBuilder(row.dropoff, row.scheduledDeliveryTime),
    },
    {
      name: "DISTANCE",
      selector: (row) => row.price,
      cell: (row) => CellBuilder(row.distance, row.weight),
      grow: 2,
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
      cell: (row) => CellBuilder(row.price, row.pricePerMile, "blue"),
      grow: 2,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      grow: 2,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.actions,
      grow: 1,
    },
  ];