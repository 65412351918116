import { useEffect, useState } from "react";
import classnames from "classnames";
import "./drugTest.sass";
import DrugTable from "./DrugTable";
import { getElementsV2 } from "services/milerApi";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import alcoholColumns, { drugColumns } from "./columns";
import dateFormatter from "utils/dateFormater";
import Loading from "components/loading/Loading";
import SliderModal from "components/sliderModal/SliderModal";
import EditDATests from "./EditDATests";

const DrugsTest = ({ id, driverName, getDrivers }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState();
  const [alcoholTestSummary, setAlcoholTestSummary] = useState([]);
  const [drugTestSummary, setDrugTestSummary] = useState([]);

  const [drugId, setDrugId] = useState("");
  const [openEditSlider, setOpenEditSlider] = useState(false);
  const fetchDrugSummary = () => {
    getElementsV2({
      url: `/Compliance/Driver/${id}/Drug`,
      setLoading: setLoading
    })
      .then((response) => {
        if (response.success) {
          setDrugTestSummary(response.data || []);
        }
      })
  };

  const fetchAlcoholSummary = () => {
    getElementsV2({
      url: `/Compliance/Driver/${id}/Alcohol`,
      setLoading: setLoading
    })
      .then((response) => {
        if (response.success) {
          setAlcoholTestSummary(response.data || []);
        }
      })
  };
  useEffect(() => {
    if (activeTab === "1") {
      fetchDrugSummary();
    }
    if (activeTab === "2") {
      fetchAlcoholSummary();
    }
  }, [activeTab]);

  let alcoholData = alcoholTestSummary.map((data) => {
    return {
      alcoholTestId: data.alcoholTestId,
      testType: data.testType,
      orderedUtc: dateFormatter(data.orderedUtc, "MMM DD, YYYY"),
      alcoholTestUtc: dateFormatter(data.alcoholTestUtc, "MMM DD, YYYY"),
      alcoholTestStatus: data.alcoholTestStatus,
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            onClick={() => {
              setDrugId(data.alcoholTestId);
              setOpenEditSlider(true);
            }}
          />
          <i
            className="mil-more-vertical"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {}}
          ></i>
        </div>
      ),
    };
  });

  let drugData = drugTestSummary.map((data) => {
    return {
      drugTestId: data.drugTestId,
      testType: data.testType,
      orderedUtc: dateFormatter(data.orderedUtc, "MMM DD, YYYY"),
      drugTestUtc: dateFormatter(data.drugTestUtc, "MMM DD, YYYY"),
      drugTestStatus: data.drugTestStatus,
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              setOpenEditSlider(true);
              setDrugId(data.drugTestId);
            }}
          />
          <i
            className="mil-more-vertical"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {}}
          ></i>
        </div>
      ),
    };
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const refreshDrugAlocoholTable = () => {
    if (activeTab === "1") {
      fetchDrugSummary();
      getDrivers();
    } else {
      fetchAlcoholSummary();
      getDrivers();
    }
  };
  return (
    <section className="">
      <div className="ar_tabs-item ar-details-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames("ar_tabs-item", {
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
              style={{ cursor: "pointer" }}
            >
              Drug Test
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("ar_tabs-item", {
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
              style={{ cursor: "pointer" }}
            >
              Alcohol Test
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <section className="slider-display-body">
        <TabContent activeTab={activeTab}>
          {activeTab === "1" && (
            <TabPane tabId="1" className="mt-3">
              <Row>
                <Col sm="12" className="recurring-charges">
                  {loading ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <DrugTable columns={drugColumns} data={drugData} />
                  )}
                </Col>
              </Row>
            </TabPane>
          )}
          {activeTab === "2" && (
            <TabPane tabId="2" className="mt-3">
              <Row>
                <Col sm="12" className="recurring-charges">
                  {loading ? (
                    <section>
                      <Loading />
                    </section>
                  ) : (
                    <DrugTable columns={alcoholColumns} data={alcoholData} />
                  )}
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </section>
      <SliderModal
        clickState={openEditSlider}
        setClickState={setOpenEditSlider}
      >
        <EditDATests
          drugId={drugId}
          driverName={driverName}
          activeTab={activeTab}
          setOpenEditSlider={setOpenEditSlider}
          getDrivers={refreshDrugAlocoholTable}
        />
      </SliderModal>
    </section>
  );
};

export default DrugsTest;
