import { getCookie } from "utils/cookies";
import { Toast } from "./Toast";
import { env, envEnum } from "./utils/environment";
const axios = require("axios");

const getBaseUrl = () => {
  if (env === envEnum.prod) return "https://api.miler.com/";
  if (env === envEnum.dev || env === envEnum.local)
    return "https://dev-api.miler.com/";

  return "https://api.miler.com/";
};

const baseUrl = getBaseUrl();

const setupHeaders = () => {
  const { accessToken } = getCookie("cachedJwt") || {};
  const { userId, carrierId } = getCookie("userInfo") || {};
  return {
    "Content-Type": "application/json",
    Authorization: accessToken,
    "X-Miler-UserId": userId,
    "X-Miler-CarrierId": carrierId,
  };
};
const separateErrors = (errors) => {
  let fieldErrors = [];
  let customErrors = [];
  errors.forEach((error) => {
    if (error.key) {
      fieldErrors.push(error);
    } else {
      customErrors.push(error);
    }
  });
  return [fieldErrors, customErrors];
};

const errorsHandler = (error, toastCode) => {
  let vErrors; //validation errors if they exist
  let errorTitle = "Unhandle error: something went wrong";
  if (error?.response) {
    let response = error.response;

    if (response.status === 400 && response.data?.errors) {
      errorTitle = response.title || "400: Bad Request";

      let [a, b] = separateErrors(response.data.errors);
      vErrors = a;
      if (a.length) {
        let mainError = (
          <strong>
            There is one or more missing or invalid required fields
          </strong>
        );
        let errs = [];
        if (b.length) {
          errs = b.map((v) => {
            <p>{v.errorMessage}</p>;
          });
        }
        errorTitle = (
          <div>
            {mainError}
            {errs}
          </div>
        );
      }
    } else {
      errorTitle =
        response.title ||
        response.data.message || response.data ||
        `${response.status}: "Unhandle error: something went wrong"`;
    }
  }

  Toast.error(errorTitle, toastCode);

  return { success: false, vErrors };
};

export const getGatewayApi = (url) => {
  return axios.get(baseUrl + url, {
    headers: setupHeaders(),
  });
};

export const postGatewayApi = (url, data) => {
  const toastCode = Toast.loading("Please wait");
  return axios
    .post(baseUrl + url, data, {
      headers: setupHeaders(),
    })
    .then((response) => {
      let message = response.data.data ? response.data.data : response.data;
      Toast.success(message, toastCode);
      return { success: true, response };
    })
    .catch((error) => errorsHandler(error, toastCode));
};
