import { useState } from "react";
import DataTable from "react-data-table-component";
import { TbChevronDown, TbChevronUp, TbDownload } from "react-icons/tb";
import { digitsToCurrency } from "utils/currencyFormatter";
import { TransactionsColumsa } from "./AdditionalTransactionsData";
import { EarningsColumns } from "./EarningsData";
import { toast } from "react-toastify";
import DocDialog from "components/docDialog/docPreviewDialog";

const SettlementCard = ({ settlement }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [docDialog, setDocDialog] = useState(false);
  const [url, setUrl] = useState(null);

  //"shipmentstore.dev/e5cc421a-dda4-45f5-b206-0479abac701b"

  return (
    <div className="miler-card">
      <div className="settlement-detail">
        <div>{settlement.settlementDue}</div>
        <div>Settlement # {settlement.settlementId}</div>
        <div
          className={`head font-weight-bold ${
            settlement.totalSettlement < 0 ? "red" : "blue"
          }`}
        >
          {digitsToCurrency(settlement.totalSettlement || 0)}
        </div>
        <div>
          {settlement.documentUrl && (
            <TbDownload
              style={{
                fontSize: "20px",
                color: "#268d40",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                if (settlement.documentUrl) {
                  setUrl(settlement.documentUrl);
                  setDocDialog(true);
                } else {
                  toast.error("Document not available");
                }
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          {collapsed ? (
            <TbChevronDown style={{ fontSize: "20px", cursor: "pointer" }} />
          ) : (
            <TbChevronUp style={{ fontSize: "20px", cursor: "pointer" }} />
          )}
        </div>
      </div>

      {!collapsed && (
        <>
          {settlement.earnings && (
            <>
              <h6 className="mt-4 ml-2">Earnings</h6>
              <div className="miler-card">
                <DataTable
                  columns={EarningsColumns}
                  data={settlement.earnings || []}
                />
              </div>
            </>
          )}

          {settlement.additionalTransactions && (
            <>
              <h6 className="mt-4 ml-2">Additional Transactions</h6>
              <div className="miler-card">
                <DataTable
                  columns={TransactionsColumsa}
                  data={settlement.additionalTransactions || []}
                />
              </div>
            </>
          )}
        </>
      )}
      <DocDialog
        open={docDialog}
        url={url}
        onCancel={() => setDocDialog(false)}
      />
    </div>
  );
};

export default SettlementCard;
