import { useContext, useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";

export default function ReferenceModal({ isOpen, setIsOpen, dispatch, index }) {

  const [stopReferenceObj, setStopReferenceObj] = useState({
    stopReferenceType: "DeliveryOrderNumber",
    reference: "",
  });
  
  const addReference = async () => {
    if (
      stopReferenceObj.stopReferenceType === "" ||
      stopReferenceObj.reference === ""
    ) {
      return;
    }
    dispatch({
      index,
      category: "newStopReference",
      value: stopReferenceObj,
    });
    setStopReferenceObj({
      stopReferenceType: "DeliveryOrderNumber",
      reference: "",
    });
  };

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  

  const {
    enums: { StopReferenceType },
  } = useContext(appContext);

  return (
    <>
      <Modal isOpen={Boolean(isOpen)} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>REFERENCE</ModalHeader>
        <ModalBody>
          <Row className="align-items-end justify-content-around">
            <div className="column">
              <InputMiler
                options={StopReferenceType}
                type="select"
                label="REFERENCE TYPE"
                value={stopReferenceObj.stopReferenceType}
                onChange={(e) =>
                  setStopReferenceObj({
                    ...stopReferenceObj,
                    stopReferenceType: e.target.value,
                  })
                }
              />
            </div>
            <div className="column">
              <InputMiler
                label="REFERENCE VALUE"
                value={stopReferenceObj.reference}
                onChange={(e) =>
                  setStopReferenceObj({
                    ...stopReferenceObj,
                    reference: e.target.value,
                  })
                }
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <MilerButton
            icon="mil-save-o"
            onClick={() => {
              addReference();
              setIsOpen(false);
            }}
            title="Save"
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
