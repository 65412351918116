import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useContext } from "react";
import "./Profile.sass";
import { getCookie } from "utils/cookies";
import image from "assets/logo/image.png";
import { appContext } from "services/AppContext";
import percentDeciConverter from "utils/percentDeciConverter";

const CarrierProfile = ({ carrier }) => {
  const { enums } = useContext(appContext);
  const carrierInfo = getCookie("carrierInfo");
  const { companyLogoURL } = carrierInfo;
  //   const { state, dispatch } = useContext(CarrierContext);

  const handleImageChange = () => {
    // let files = e.target.files;
    // const formFile = new FormData();
    // formFile.append("Document", files[0]);
    // setSelectedFile({ file: formFile });
    // postElement("/Document?DocumentCategory=Company", formFile).then((res) => {
    //   dispatch({ type: "uploadUrl", payload: res.response.data });
    // });
  };

  //    const handleInputChange=()=>{

  //    }
  return (
    <>
      <section className="carrier_profile_profile_container">
        <div className="car">
          <div className="title_container">
            <h1 className="title">Company Details</h1>
            <p className="desc pr-4">
              Update your company information and other details here
            </p>
          </div>
          <div className="cont">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  disabled
                  label="Company Name"
                  value={carrier.company}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "company",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="Website"
                  disabled
                  value={carrier?.website}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "website",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="DBA"
                  disabled
                  value={carrier.dba}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "dba",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="EIN"
                  disabled
                  value={carrier.ein}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "ein",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="USDOT"
                  disabled
                  value={carrier.usdot}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "usdot",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="MC Number"
                  disabled
                  value={carrier.mcNumber}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "mcNumber",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="SCAC"
                  value={carrier?.scac}
                  disabled
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "scac",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <section className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  label="FF Number"
                  disabled
                  value={carrier?.ffNumber}
                  onChange={(e) =>
                    dispatch({
                      type: "changeMainCarrierProfile",
                      payload: {
                        name: "ffNumber",
                        value: e.target.value,
                      },
                    })
                  }
                />
              </section>
              <section
                className="col-12 col-lg-12 col-xl-12"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {companyLogoURL ? (
                  <img
                    src={companyLogoURL}
                    alt="company_logo"
                    style={{
                      objectFit: "contain",
                      marginLeft: "-20px",
                      maxWidth: "90px",
                      maxHeight: "90px",
                    }}
                  />
                ) : (
                  <img
                    src={image}
                    alt="company_logo"
                    style={{ marginRight: "16px" }}
                  />
                )}
                <div className="upload-btn-wrapper">
                  <MilerButton className="btn" title="Upload a file" />
                  <input
                    type="file"
                    name="myfile"
                    onChange={handleImageChange}
                    disabled
                  />
                </div>
                <MilerButton title="Remove" disabled />
              </section>
            </div>
          </div>
        </div>
      </section>
      <section className="general-carrier-settings">
        <div className="carrier_profile_general_container">
          <div className="car">
            <div className="title_container">
              <div className="title">Account Defaults</div>
            </div>
            <div className="cont">
              <div className="itm">
                <div className="name">
                  <div className="title_container">
                    <div className="title">Measurement System</div>
                    <div className="desc">
                      Default measurement to be used throughout the application.
                    </div>
                  </div>
                </div>
                <div className="value">
                  <InputMiler
                    type="select"
                    disabled
                    options={enums.MeasurementUnit}
                    value={
                      carrier.carrierSettingResponse?.measurementUnit
                        ? carrier?.carrierSettingResponse?.measurementUnit
                        : "Imperial"
                    }
                    onChange={(e) =>
                      dispatch({
                        type: "carrierSettingResponse",
                        payload: {
                          name: "measurementUnit",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>

              <div className="itm">
                <div className="name">
                  <div className="title_container">
                    <div className="title">Outgoing Email Address</div>
                    <div className="desc">
                      Default email for outgoing emails
                    </div>
                  </div>
                </div>
                <div className="value">
                  <InputMiler
                    disabled
                    value={carrier.carrierSettingResponse?.outgoingEmailAddress}
                    onChange={(e) =>
                      dispatch({
                        type: "carrierSettingResponse",
                        payload: {
                          name: "outgoingEmailAddress",
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className="itm">
                <div className="name">
                  <div className="title_container">
                    <div className="title">4PL Commission Rate</div>
                    <div className="desc">Commission Rate</div>
                  </div>
                </div>
                <div className="value">
                  <InputMiler
                    required
                    type="number"
                    postfix="%"
                    disabled
                    value={percentDeciConverter(
                      carrier.carrierSettingResponse?.commissionRate4PL
                    )}
                    onChange={(e) => {
                      dispatch({
                        type: "carrierSettingResponse",
                        payload: {
                          name: "commissionRate4PL",
                          value: percentDeciConverter(e.target.value, "divide"),
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarrierProfile;
