import InputMiler from "components/inputMiler/InputMiler";
import { useContext } from "react";
import { appContext } from "services/AppContext";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import dateFormatter from "utils/dateFormater";

const RecurringForm = ({ charge, setCharge, onChange, chargeErrors, chargeDetails }) => {
  const { enums } = useContext(appContext);

  const { transactionType, amount } = chargeErrors;
  return (
    <div className="miler-card mt-0">
      <div className="row">
        <div className="col col-12 col-lg-6">
          <InputMiler
            type="select"
            value={charge.transactionType}
            options={enums.TransactionType}
            onChange={onChange}
            name="transactionType"
            label="Transaction Type"
            required
            error={transactionType}
            disabled={chargeDetails}
          />
        </div>
        <div className="col col-12 col-lg-6">
          <InputMiler
            type="number"
            value={charge.amount}
            onChange={onChange}
            name="amount"
            label="Recurring Amount"
            required
            error={amount}
          />
        </div>

        <div className="col col-12 col-lg-6">
          <InputMiler
            type="select"
            value={charge.stopAfter}
            options={enums.StopAfter}
            onChange={onChange}
            name="stopAfter"
            label="Stop After"
          />
        </div>
        {!charge.stopAfter && (
          <div className="col col-12 col-lg-6">
            <InputMiler
              disabled
              type="number"
              value=""
              name="stopAfterCancel"
              label="Stop after I cancel"
            />
          </div>
        )}
        {charge.stopAfter === "Specified Amount" && (
          <div className="col col-12 col-lg-6">
            <InputMiler
              type="number"
              value={charge.stopAfterAmount}
              onChange={onChange}
              name="stopAfterAmount"
              label="Stop after total amount"
            />
          </div>
        )}

        {charge.stopAfter === "Specified Date" && (
          <div className="col col-12 col-lg-6">
            <DateTimeInput
              type="date"
              name="stopAfterDate"
              value={dateFormatter(charge.stopAfterDate, "YYYY-MM-DD", true)}
              onChange={(value) => {
                setCharge({
                  ...charge,
                  stopAfterDate: value,
                  stopAfterAmount: "",
                });
              }}
              label="Stop after date"
            />
          </div>
        )}
        <div className="col col-12 col-lg-12"></div>
        <div className="col col-12 col-lg-6">
          <InputMiler
            type="number"
            value={charge.lastAmount}
            onChange={onChange}
            name="lastAmount"
            label="Last Amount"
          />
        </div>
        <div className="col col-12 col-lg-6">
          <InputMiler
            value={charge.emailBeforeLast ? "Yes" : "No"}
            type="select"
            onChange={(e) => {
              setCharge({
                ...charge,
                emailBeforeLast: e.target.value === "Yes" ? true : false,
              });
            }}
            label="Email me before last payment"
            name="emailBeforeLast"
            options={["Yes", "No"]}
          />
        </div>
        <div className="col col-12 col-lg-6">
          <InputMiler
            type="textarea"
            value={charge.description}
            onChange={onChange}
            name="description"
            label="Description"
          />
        </div>
      </div>
    </div>
  );
};

export default RecurringForm;
