import DataTable from "react-data-table-component";
import billingColumns from "./billingData";
import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import { digitsToCurrency } from "utils/currencyFormatter";
import dateFormatter from "utils/dateFormater";
import { useState } from "react";
import DocDialog from "components/docDialog/docPreviewDialog";
import CustomPagination from "components/custom-pagination/CustomPagination";
import Loading from "components/loading/Loading";

const BillingSummary = ({ billings, request, paging, setRequest }) => {
  const [docDialog, setDocDialog] = useState(false);
  const [url, setUrl] = useState(null);

  let invoicesList = billings.map((data) => {
    let paidCurr = data.payment ? data.payment : 0;
    let amountCurr = data.amount ? data.amount : 0;
    let balanceCurr = amountCurr - paidCurr;
    return {
      shipment: data.invoiceNumber,
      invoiceDate: dateFormatter(data.invoiceUtc, "MMM DD, YYYY"),
      pickUpAddressLine: CellBuilder(
        data.pickupLocation,
        dateFormatter(data.pickupDate, "MMM DD, YYYY")
      ),
      dropOffAddressLine: CellBuilder(
        data.dropoffLocation,
        dateFormatter(data.deliveryDate, "MMM DD, YYYY")
      ),
      due: (
        <div className="ar_pending_due_date">
          <div className="title">
            {dateFormatter(data.dueDateUtc, "MMM DD, YYYY")}
          </div>
          <BorderedText
            classes={data.promise}
            text={data.promise === "Delayed" ? "Due Soon" : data.promise}
          />
        </div>
      ),
      charges: CellBuilder(digitsToCurrency(data.amount)),
      payment: data.payment && digitsToCurrency(data.payment),
      paymentDate: `${dateFormatter(data.paymentUtc, "MMM DD, YYYY")}`,
      balance: (
        <div className="ar_pending_due_date">
          <div className="title">{digitsToCurrency(balanceCurr)}</div>
          <BorderedText classes={data.status} text={data.status} />
        </div>
      ),
      actions: (
        <div className="table-actions">
          {data.invoiceUrl && (
            <i
              className="mil-arrow-sm-down"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setUrl(data.invoiceUrl);
                setDocDialog(true);
              }}
            />
          )}
          {/* <i className="mil-more-vertical" style={{ cursor: "pointer" }} /> */}
        </div>
      ),
    };
  });
  return (
    <div className="">
      <div className="summary-list">
        <DataTable
          columns={billingColumns}
          data={invoicesList}
          responsive={true}
        />
        {request.offset === paging.page ? (
          <CustomPagination
            setRequest={setRequest}
            request={{ ...request, ...paging }}
            showTotals={true}
            nextExists={request.offset < paging.page_count}
            rowsPerPage={[20, 50, 100]}
          />
        ) : (
          <div className="customer-billing-pagination-loader">
            <Loading />
          </div>
        )}
      </div>
      <DocDialog
        open={docDialog}
        url={url}
        onCancel={() => setDocDialog(false)}
      />
    </div>
  );
};

export default BillingSummary;
