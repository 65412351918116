export default {
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  driverType: "",
  // driverType: "Company Driver",
  gender: "",
  isHazmatCertified: false,
  isCdlDriver: true,
  hazmatExpiration: "",
  status: "Active",
  relationship: "",
  address: {
    addressId: 0,
    addressType: "WorkSite",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
    timezoneOffset: 0,
  },
  emergencyContactFirstName: "",
  emergencyContactLastName: "",
  emergencyContactTitle: "",
  emergencyContactPrefix: "",
  emergencyContactPostfix: "",
  emergencyContactDepartment: "",
  emergencyContactPhone: "",
  emergencyContactEmail: "",
  driverNumber: "",
  nextPayUTC: "",
  vehicleId: 0,
  licenseState: "",
  licenseNumber: "",
  licenseExpirationDate: "",
  hireDateUtc: "",
  terminationUtc: "",
  terminationReasonId: 0,
  lastDayWorked: "",
  // rehire: true
  payee: {},
};
