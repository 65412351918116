import VehicleForm from "../../vehicleForm/VehicleForm";

const VehicleDetails = ({ state, setState, errors, yearError, setYearError }) => {
  return (
    <div className="slider-display">
      {state && (
        <>
          <VehicleForm  setState={setState} setYearError={setYearError} yearError={yearError}
              state={state} errors={errors} />
        </>
      )}
    </div>
  );
};

export default VehicleDetails;
