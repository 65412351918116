import { useEffect, useRef, useState } from "react";

import InputMiler from "components/inputMiler/InputMiler";
import "./advancedSearch.sass";

const SearcheableInput = ({
  label,
  error,
  required,
  placeholder,
  onTextChange,
  onSelect,
  options,
  inputValue,
}) => {
  const [inputTextValue, setInputTextValue] = useState(inputValue);

  const [searchState, setSearch] = useState({
    result: [],
    searchDisplay: false,
  });

  const searchableRef = useRef();

  const handleChange = (e) => {
    let value = e.target.value;
    const filterdRes = options.filter((item) => item.label.includes(value));
    setSearch({
      result: filterdRes,
      searchDisplay: filterdRes.length > 0,
    });
    setInputTextValue(value);
    onTextChange(e, filterdRes.length > 0);
  };

  const handleSelect = (selectedValue) => {
    onSelect(selectedValue);
    setSearch({ ...searchState, searchDisplay: false });
  };

  useEffect(() => {
    setInputTextValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchableRef.current &&
        !searchableRef.current.contains(event.target)
      ) {
        setSearch({ result: searchState.result, searchDisplay: false });
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchableRef]);

  return (
    <div className="advanced-search" ref={searchableRef}>
      <InputMiler
        label={label}
        type="text"
        onChange={handleChange}
        value={inputTextValue}
        error={error}
        required={required}
        onFocus={() => {
          setSearch({
            result: searchState.result.length ? searchState.result : options,
            searchDisplay:
              (searchState.result.length || !inputTextValue) && true,
          });
        }}
        placeholder={placeholder || ""}
      />
      {searchState.searchDisplay && (
        <div className="advance-search__result">
          {searchState.result.map((item) => (
            <div
              onClick={() => {
                handleSelect(item);
              }}
              className="result-item"
              key={item.value}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearcheableInput;
