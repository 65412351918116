import React, { useContext } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";

export default function ShipmentReferenceModal({
  isOpen,
  setIsOpen,
  dispatch,
}) {
  const [
    stopShipmentReferenceObj,
    setStopShipmentReferenceObj,
  ] = React.useState({
    referenceType: "",
    referenceId: "",
  });

  const addReference = async () => {
    if (
      stopShipmentReferenceObj.referenceType === "" ||
      stopShipmentReferenceObj.referenceId === ""
    ) {
      return;
    }
    dispatch({
      category: "newStopShipmentReference",
      value: stopShipmentReferenceObj,
    });
    setStopShipmentReferenceObj({
      referenceType: "",
      referenceId: "",
    });
  };

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  const {
    enums: { ShipmentReferenceType },
  } = useContext(appContext);
  const filteredShipmentReference = ShipmentReferenceType.filter(
    (item) => !item.keyName.startsWith("Reference")
  );

  return (
    <>
      <Modal isOpen={Boolean(isOpen)} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>REFERENCE</ModalHeader>
        <ModalBody>
          <Row className="align-items-end justify-content-around">
            <div className="column">
              <InputMiler
                options={filteredShipmentReference}
                type="select"
                label="SHIPMENT REFERENCE TYPE"
                value={stopShipmentReferenceObj.referenceType}
                onChange={(e) =>
                  setStopShipmentReferenceObj({
                    ...stopShipmentReferenceObj,
                    referenceType: e.target.value,
                  })
                }
              />
            </div>
            <div className="column">
              <InputMiler
                label="SHIPMENT REFERENCE VALUE"
                value={stopShipmentReferenceObj.referenceId}
                onChange={(e) =>
                  setStopShipmentReferenceObj({
                    ...stopShipmentReferenceObj,
                    referenceId: e.target.value,
                  })
                }
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <MilerButton
            icon="mil-save-o"
            onClick={() => {
              addReference();
              setIsOpen(false);
            }}
            title="Save"
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
