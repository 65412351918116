import DataTable from "react-data-table-component";

export default function PackageInfo({ data }) {
  const columns = [
    {
      name: "TYPE",
      selector: (row) => row.packageType,
      grow: 2,
    },
    {
      name: "WEIGHT",
      selector: (row) => <div>{(row.weight ? Number(row.weight).toLocaleString() : '')}</div>,
      grow: 1,
    },
    {
      name: "QUANTITY",
      selector: (row) => row.quantity,
      grow: 3,
    },
    {
      name: "LENGTH (IN.)",
      selector: (row) => row.length,
      grow: 2,
    },
    {
      name: "WIDTH (IN.)",
      selector: (row) => row.width,
      grow: 1,
    },
    {
      name: "HEIGHT (IN.)",
      selector: (row) => row.height,
      grow: 1,
    },
   

    {
      name: "CUBIC FEET",
      selector: (row) => row.volume,
      grow: 1,
    },
  ];
  return (
    <div className="packaging-info">
      <div className="title">Packaging Info</div>
      <div>
        <DataTable columns={columns} data={data} />
      </div>
    </div>
  );
}
