import MilerButton from "components/milerButton/MilerButton";
import "./AuthFailure.sass";
import { inValidateCookies } from "utils/cookies";
import { getRedirectURIOrigin } from "services/Auth";

const reload = () => {
  inValidateCookies(true);
  window.location.reload();
};
const AuthFailure = ({ message }) => {
  window.history.replaceState({}, "", getRedirectURIOrigin());
  message = message || "Sorry, your login attempt was not successful.";
  return (
    <div className="auth-failure">
      <div className="auth-failure-content">
        <h4 className="auth-failure-message">{message}</h4>
        <MilerButton title="Refresh" btnDark onClick={reload} />
      </div>
    </div>
  );
};

export default AuthFailure;
