import { useState, useEffect } from "react";
import VehicleDriversCard from "./VehicleDriversCard";
import AssignDriversSearcher from "./AssignDriversSearcher";
import MilerButton from "components/milerButton/MilerButton";
import { getGatewayApi } from "services/gatewayApis";
import { getElements, postElement } from "services/milerApi";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";
import "./AssignTripToDriver.sass";
import joinStrings from "utils/joinStrings";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
const AssignTripToDriver = ({
  setOpenAssign,
  data,
  onAssignCallBack,
  type,
  fromShipment,
}) => {
  /*
  data = {
    id: tenderId ,
    shipmentNumber:  ,
    shipmentType: shipmentMode,
    shipperName: doesn't exist ,
  } 
  */

  const [loading, setLoading] = useState(Loading);

  const [tripId, setTripId] = useState(data.id);
  const [fetchedTripId, setFetchedTripId] = useState(false);
  const [avaliableVehDrivers, setAvaliableVehDrivers] = useState([]);
  const [selectedVehDrivers, setSelectedVehDrivers] = useState({
    vehicleId: "",
    driverId: "",
    coDriverId: "",
  });
  const [assignError, setAssignError] = useState();

  useEffect(() => {
    setAssignError();
  }, [selectedVehDrivers]);
  const getDrivers = () => {
    if (type.toLowerCase() === "trip") {
      getGatewayApi(`carrier/equipment/availability?trip_id=${tripId}`)
        .then((res) => {
          if (res) {
            setAvaliableVehDrivers(res.data);
            setLoading(false);
          }
        })
        .catch(() => setLoading(<FailedToFetch />));
    } else if (type.toLowerCase() === "marketplace") {
      getGatewayApi(
        `carrier/equipment/availability?trip_id=${tripId}&is_tender=True`
      )
        .then((res) => {
          if (res) {
            setAvaliableVehDrivers(res.data);
            setLoading(false);
          }
        })
        .catch(() => setLoading(<FailedToFetch />));
    } else {
      setLoading(false);
    }
  };

  const getTripFromShipment = () => {
    getElements(`/Shipment/${tripId}/Trip`).then((res) => {
      let trips = res.data;
      const tripId = trips[0]?.tripId;
      if (tripId) {
        setFetchedTripId(true);
        setTripId(tripId);
      }
    });
  };

  const AssignTripWithCheck = () => {
    const { vehicleId, driverId, coDriverId } = selectedVehDrivers;
    getElements(
      `/Shipment/Trip/${tripId}/AssignmentCheck?vehicleId=${vehicleId}&driverId=${driverId}&coDriverId=${coDriverId}`
    )
      .then(assignTrip())
      .catch((er) => {
        if (er.response?.data?.errors[0]?.errorMessage) {
          setAssignError(er.response?.data?.errors[0]?.errorMessage);
        }
      });
  };

  const assignTrip = (ignoreConflict) => {
    ignoreConflict = ignoreConflict ? true : false;

    const { vehicleId, driverId, coDriverId } = selectedVehDrivers;
    const isFleetOwner =
      getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

    let postUrl = `/Shipment/${type}/${tripId}/Assignment?vehicleId=${vehicleId}&driverId=${driverId}&coDriverId=${coDriverId}&ignore_conflict=${ignoreConflict}`;

    if (type == "Markeplace") {
      postUrl = isFleetOwner
        ? `/FleetOwner/${type}/${tripId}/Assignment?vehicleId=${vehicleId}&driverId=${driverId}&coDriverId=${coDriverId}&ignore_conflict=${ignoreConflict}`
        : `/Shipment/${type}/${tripId}/Assignment?vehicleId=${vehicleId}&driverId=${driverId}&coDriverId=${coDriverId}&ignore_conflict=${ignoreConflict}`;
    }

    postElement(postUrl).then(onAssignCallBack);
  };

  useEffect(() => {
    if (fromShipment && !fetchedTripId) {
      getTripFromShipment();
    } else {
      getDrivers();
    }
  }, [tripId]);

  return (
    <div className="assign-trip-driver slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">
          <div>Assign Vehicle</div>
          <div className="asd_header_details">
            {joinStrings(
              [data.shipmentNumber, data.shipperName, data.shipmentType],
              " | "
            )}
          </div>
        </div>
        <div className="slider-header-buttons">
          <MilerButton title="Cancel" onClick={() => setOpenAssign(false)} />
          <MilerButton
            title="Assign"
            btnDark
            onClick={() =>
              type.toLowerCase() === "trip"
                ? AssignTripWithCheck()
                : assignTrip(true)
            }
          />
        </div>
      </div>

      <div className="slider-display-body">
        <AssignDriversSearcher onItemSelect={setSelectedVehDrivers} value="" />
        {selectedVehDrivers.fromSearch && (
          <VehicleDriversCard
            assignError={assignError}
            assignTrip={assignTrip}
            driver={selectedVehDrivers}
            setSelectedVehDrivers={setSelectedVehDrivers}
            selectedVehDrivers={selectedVehDrivers}
            selectedVehicle={selectedVehDrivers.vehicleId}
          />
        )}
        {loading ? (
          loading
        ) : avaliableVehDrivers.length ? (
          avaliableVehDrivers.map((driver, index) => {
            if (
              driver.assignedVehicleID === selectedVehDrivers.vehicleId &&
              selectedVehDrivers.fromSearch
            )
              return <div key={index}></div>;
            else
              return (
                <VehicleDriversCard
                  key={index}
                  assignError={assignError}
                  assignTrip={assignTrip}
                  driver={driver}
                  setSelectedVehDrivers={setSelectedVehDrivers}
                  selectedVehDrivers={selectedVehDrivers}
                  selectedVehicle={selectedVehDrivers.vehicleId}
                />
              );
          })
        ) : (
          <h3 className="text-center my-5">No Available Vehicles.</h3>
        )}
      </div>
    </div>
  );
};

export default AssignTripToDriver;
