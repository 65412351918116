import BorderedText from "components/bordered-text/BorderedText";
import InputMiler from "components/inputMiler/InputMiler";
import { useContext, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { appContext } from "services/AppContext";
import DataTable from "react-data-table-component";
import { dateAndTimeObjectify } from "utils/dateAndTimeObjectify";
import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import classNames from "classnames";
import ReferenceModal from "components/shipment/shipmentForm/ReferenceModal";
import AddressToggle from "components/shipment/shipmentForm/AddressToggle";
import "../../../shipments/shipmentForm/ShipmentForm.sass";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import calculateStopsDistance from "utils/calculateStopsDistance";

const TripStops = ({ state, dispatch, findError, containsErrors }) => {
  return (
    <Card className="my-2">
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title">Stops</p>
          <div>
            <button
              className="shipment-detail-down-arrow"
              onClick={() => dispatch({ category: "newStop" })}
            >
              <i className="mil-plus" />
            </button>
          </div>
        </CardTitle>
        <Row style={{ margin: "0 7px" }}>
          <div className="stops-table shipment-form-stops">
            <div className="stops-table-title shipment-form-stops-title">
              <div>SORT</div>
              <div>Type</div>
              <div>Location</div>
              <div>Arrival</div>
              <div>Action</div>
            </div>
            {state.stops.map((stop, index) => (
              <RowTable
                stop={stop}
                index={index}
                dispatch={dispatch}
                findError={findError}
                containsErrors={containsErrors}
                newShipment={!state.shipmentId}
                key={index}
                stops={state.stops}
              />
            ))}
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

const RowTable = ({
  stop,
  index,
  dispatch,
  newShipment,
  findError,
  containsErrors,
  yellowTitle,
  stops,
}) => {
  const [op, setOp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const calcShipmentDistance = (address) => {
    const addresses = stops.map((s, i) => {
      if (i === index) return { address };
      else return { address: s.address };
    });
    const fullAddresses = addresses.filter((ad) => {
      if (
        ad?.address?.addressLine ||
        ad?.address?.countryCode ||
        ad?.address?.latitude
      ) {
        return true;
      }
    });

    fullAddresses.length > 1 &&
      calculateStopsDistance(addresses, (total) =>
        dispatch({ type: "distance", value: total })
      );
  };

  const onSearchedItemSelected = (item) => {
    let items = item.equipment.split(" ");
    dispatch({
      type: "trailerNumber",
      category: "stop",
      index,
      value: { trailerNumber: items[0], trailerType: items[1] },
    });
  };

  const {
    enums: { StopType, TrailerType, StopReason },
  } = useContext(appContext);

  const findInitialNote = () => {
    let initialNote = stop.stopNotes.find((n) => n.stopNoteType === "Initial");
    return initialNote ? initialNote.note : "";
  };

  let St;
  if (newShipment) St = StopType.slice(0, 2);
  else St = StopType;

  // const [stopReferenceObj, setStopReferenceObj] = React.useState({
  //   stopReferenceType: "DeliveryOrderNumber",
  //   reference: "",
  // });

  const stopTypeMappings = {
    pickup: [
      "Full Live Loading",
      "Partial Live Loading",
      "Retrieve Loaded Trailer",
      "Retrieve Empty Trailer",
    ],
    delivery: [
      "Full Live Unloading",
      "Partial Live Unloading",
      "Drop Loaded Trailer",
      "Drop Empty Trailer",
    ],
  };

  const filteredStopReasons = StopReason.filter((reason) => {
    const result = stopTypeMappings[stop.stopType?.toLowerCase()]?.includes(
      reason.keyName
    );
    return result;
  });

  const columns = [
    {
      name: "REFERENCE TYPE",
      selector: (row) => row.stopReferenceType,
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.stopReferenceType}</div>
      ),
    },
    {
      name: "REFERENCE",
      selector: (row) => row.reference,
      grow: 1,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.reference}</div>
      ),
    },
  ];

  return (
    <>
      <div
        className={classNames("shipment-form-charge-stop-item", {
          "contains-errors": containsErrors(`Stops[${index}]`),
        })}
      >
        <div
          className={`stops-table-row-title shipment-form-charge-stop-item-summary`}
        >
          <div>
            <i className="mil-menu" />
          </div>
          <div
            className="ship-detail-stops-type-text"
            style={{
              color:
                stop.stopType === "Pickup"
                  ? "#2ECD7D"
                  : stop.stopType === "Split"
                  ? "#EEA123"
                  : "#4a81d9",
            }}
          >
            {stop.stopType === "Pickup" ? "Pick up" : stop.stopType}
          </div>
          <div className="ship-detail-stops-type-container">
            <div className="ship-detail-stops-type-text">
              {stop.address.addressLine}
            </div>
            <BorderedText
              text={`${stop.address.city} ${stop.address.countryCode}`}
            />
          </div>
          <div>
            <div>{dateAndTimeObjectify(stop.scheduledDepartureLocal).date}</div>
            <BorderedText
              text={dateAndTimeObjectify(stop.scheduledDepartureLocal).time}
            />
          </div>
          <div className="shipment-form-stops-charges-actions">
            <i
              className="mil-delete-o"
              onClick={() => dispatch({ category: "deleteStop", index })}
              style={{ cursor: "pointer" }}
            />

            <i
              style={{ marginLeft: "5px", cursor: "pointer" }}
              className={op ? "mil-chevron-bg-up" : "mil-chevron-bg-down"}
              onClick={() => setOp(!op)}
            />
          </div>
        </div>
        {op && (
          <div className="shipment-form-charge-stop-item-details">
            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card">
                <CardTitle className="shipment-detail-header">
                  <p className="shipment-detail-title">GENERAL</p>
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <InputMiler
                      label="Stop Type"
                      options={St}
                      type="select"
                      value={stop.stopType}
                      onChange={(e) =>
                        dispatch({
                          type: "stopType",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      required
                      error={findError(`Stops[${index}].StopType`)}
                    />
                  </Col>
                  <Col lg="6">
                    <InputMiler
                      label="Trailer Type"
                      value={stop.trailerType}
                      type="select"
                      options={TrailerType}
                      onChange={(e) =>
                        dispatch({
                          type: "trailerType",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      error={findError(`Stops[${index}].TrailerType`)}
                    />
                  </Col>
                  <Col lg="6">
                    <InputMiler
                      label="Stop Reason"
                      options={filteredStopReasons}
                      type="select"
                      value={stop.stopReason}
                      onChange={(e) =>
                        dispatch({
                          type: "stopReason",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      error={findError(`Stops[${index}].StopReason`)}
                      disabled
                    />
                  </Col>
                  <Col lg="6">
                    <Equipmentsearcher
                      onItemSelect={onSearchedItemSelected}
                      label="Trailer Number"
                      value={stop.trailerNumber}
                      error={findError(`Stops[${index}].TrailerNumber`)}
                      eqType="Trailer"
                    />
                  </Col>

                  {stop.trailerType === "Reefer" && index === 0 && (
                    <>
                      <Col lg="6">
                        <InputMiler label="Min Temp" />
                      </Col>
                      <Col lg="6">
                        <InputMiler label="Max Temp" />
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card py-1">
                <CardTitle className="shipment-detail-header my-0">
                  <p
                    className={`shipment-detail-title my-0 ${
                      yellowTitle && "yellow"
                    }`}
                  >
                    REFERENCE
                  </p>
                  <div className="d-flex align-items-center">
                    <button
                      className="shipment-detail-down-arrow"
                      onClick={() => setIsOpen(true)}
                    >
                      <i className="mil-plus" />
                    </button>
                  </div>
                </CardTitle>
                {stop.stopReferences.length !== 0 && (
                  <div className="new-charge-container">
                    <DataTable
                      columns={columns}
                      data={stop.stopReferences}
                      className="shipment"
                      responsive={true}
                    />
                  </div>
                )}
              </CardBody>
            </Card>

            <AddressToggle
              address={stop.address}
              findError={findError}
              index={index}
              dispatch={dispatch}
              calcShipmentDistance={calcShipmentDistance}
            />

            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card">
                <CardTitle className="shipment-detail-header">
                  <p className="shipment-detail-title"> TIMES</p>
                </CardTitle>

                <Row>
                  <Col lg="12">
                    <DateTimeInput
                      label="Earliest Arrival"
                      error={findError(`Stops[${index}].earliest_arrival`)}
                      value={stop.earliest_arrival}
                      required
                      onChange={(value) => {
                        dispatch({
                          type: "earliest_arrival",
                          category: "stop",
                          index,
                          value: value,
                        });
                      }}
                    />
                  </Col>

                  {stop.stopId && (
                    <>
                      <Col lg="6">
                        <DateTimeInput
                          label="Latest Arrival"
                          error={findError(`Stops[${index}].latest_arrival`)}
                          value={stop.earliest_arrival || stop.latest_arrival}
                          onChange={(value) => {
                            dispatch({
                              type: "latest_arrival",
                              category: "stop",
                              index,
                              value: value,
                            });
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <DateTimeInput
                          label="Actual Departure"
                          error={findError(`Stops[${index}].ActualDeparture`)}
                          value={stop.actualDeparture}
                          onChange={(value) => {
                            dispatch({
                              type: "actualDeparture",
                              category: "stop",
                              index,
                              value: value,
                            });
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col lg="12">
                    <InputMiler
                      name="note"
                      label="Note"
                      type="textarea"
                      value={findInitialNote()}
                      onChange={(e) =>
                        dispatch({
                          type: "stopNote",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <ReferenceModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dispatch={dispatch}
        index={index}
      />
    </>
  );
};

export default TripStops;
