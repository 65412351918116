import { useState, createContext } from "react";
import { mockData } from "./mockData/MockData";

const MockContext = createContext();

const MockProvider = ({ children }) => {
  const [currentTrip, setCurrentTrip] = useState(mockData);

  // activity V2
  const getTrips = () => {};

  const getTrip = () => {};

  return (
    <MockContext.Provider
      value={{
        getTrips,
        getTrip,
        currentTrip,
        setCurrentTrip,
      }}
    >
      {children}
    </MockContext.Provider>
  );
};

export { MockProvider, MockContext };
