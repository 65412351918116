import {Provider} from "@react-spectrum/provider"
import  {DateRangePicker} from "@react-spectrum/datepicker";
import {theme} from "@react-spectrum/theme-default"
import { parseDate } from "@internationalized/date";
import dayjs from "dayjs";

const BillingHeader = ({ request, setRequest }) => {
  const handleBlCallback = (start, end) => {
    start = dayjs(start).format("YYYY-MM-DD");
    end = dayjs(end).format("YYYY-MM-DD");
    setRequest({ ...request, fromUtc: start, toUtc: end });
  };
  return (
    <div className="customer-billing-header">
      <Provider theme={theme} colorScheme="light">
        <DateRangePicker
          aria-label=""
          defaultValue={{
            start: parseDate(dayjs(request.fromUtc).format("YYYY-MM-DD")),
            end: parseDate(dayjs(request.toUtc).format("YYYY-MM-DD")),
          }}
          onChange={({ start, end }) => {
            setTimeout(() => {
              handleBlCallback(start.toDate(), end.toDate());
            }, 500);
          }}
        />
      </Provider>
    </div>
  );
};

export default BillingHeader;
