
// reactstrap components

import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const StepTwo = ({ state, setState }) => {
  return (
    <>
      <Form role="form">
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-unit">
                Unit Drivable
              </label>
              <select
                name="unitDrivable"
                className="form-control"
                value={state.unitType}
                onChange={(e) =>
                  setState({ ...state, unitType: e.target.value })
                }
              >
                <option>Trailer</option>
                <option>Tractor</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-make">
                Make
              </label>
              <Input
                name="make"
                id="input-make"
                className="form-control"
                type="text"
                value={state.make}
                onChange={(e) => setState({ ...state, make: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-model">
                Model
              </label>
              <Input
                name="model"
                className="form-control"
                type="text"
                value={state.model}
                onChange={(e) => setState({ ...state, model: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-year">
                Year
              </label>
              <Input
                name="year"
                id="input-year"
                className="form-control"
                type="text"
                value={state.year}
                onChange={(e) => setState({ ...state, year: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-vin">
                VIN
              </label>
              <Input
                name="vin"
                id="input-vin"
                className="form-control"
                type="text"
                value={state.vin}
                onChange={(e) => setState({ ...state, vin: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-odometer">
                Odometer
              </label>
              <Input
                name="odometer"
                id="input-odometer"
                className="form-control"
                type="text"
                value={state.odometer}
                onChange={(e) =>
                  setState({ ...state, odometer: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-tire-size">
                Tire Size
              </label>
              <Input
                name="tire-size"
                id="input-tire-size"
                className="form-control"
                type="number"
                value={state.tireSize}
                onChange={(e) =>
                  setState({ ...state, tireSize: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-unit">
                Unit Drivable
              </label>
              <select
                name="unitDrivable"
                className="form-control"
                value={state.unitDrivable === true ? "Yes" : "No"}
                onChange={(e) =>
                  setState({
                    ...state,
                    unitDrivable: e.target.value === "Yes" ? true : false,
                  })
                }
              >
                <option>Yes</option>
                <option>No</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default StepTwo;
