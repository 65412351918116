import React from "react";

const IconDisplay = React.lazy(() => import("views/iconDisplay/IconsDisplay"));
const Token = React.lazy(() => import("views/token/Token"));
const Drivers = React.lazy(() => import("views/fleet/drivers/Drivers"));
const Vehicles = React.lazy(() => import("views/fleet/vehicles/Vehicles"));
const Trailers = React.lazy(() => import("views/fleet/trailers/Trailers"));
const FuelCard = React.lazy(() => import("views/fleet/fuelCard/FuelCard"));
const FuelPrice = React.lazy(() => import("views/fleet/fuelPrice/FuelPrice"));
const FleetGroups = React.lazy(() =>
  import("views/fleet/fleetGroups/FleetGroups")
);
const FreightGroups = React.lazy(() =>
  import("views/freight/freightGroups/FreightGroups")
);

const NotificationSettings = React.lazy(() =>
  import("views/settings/notificationSettings/NotificationSetting")
);
const CarrierSettingV2 = React.lazy(() =>
  import("views/settings/carrierProfile/CarrierProfile")
);
const Factoring = React.lazy(() =>
  import("views/settings/factoring/Factoring")
);
const DataImport = React.lazy(() =>
  import("views/settings/dataImport/DataImport")
);
const IFTAReport = React.lazy(() =>
  import("views/fleet/IFTAReport/IFTAReport")
);
const Integration = React.lazy(() =>
  import("views/settings/integrations/Integrations")
);
const UserManagment = React.lazy(() =>
  import("views/settings/userManagment/UserManagment")
);
const RoleManagement = React.lazy(() =>
  import("views/settings/userManagment/roleManagement/RoleManagement")
);
const DashBoard = React.lazy(() => import("views/dashboard/Dashboard"));
const ActivitiesV2 = React.lazy(() => import("views/activities-v2/Activities"));
const Shipments = React.lazy(() => import("views/shipments/Shipments"));
const MarketPlace = React.lazy(() => import("views/marketplace/Marketplace"));
const Customers = React.lazy(() => import("views/customers/Customers"));
const Profile = React.lazy(() => import("views/profile/Profile"));
const Invoices = React.lazy(() =>
  import("views/accounting/invoices_v2/Invoices")
);
const SettlementsV2 = React.lazy(() =>
  import("views/accounting/settlements_v2/Settlements")
);
const AccountReceivable = React.lazy(() =>
  import("views/accounting/accountReceivable/AccountReceivable")
);
// const Accountpayable = React.lazy(() =>
//   import("views/accounting/accountPayable/AccountPayable")
// );

const ComplianceOverview = React.lazy(() =>
  import("views/compliance/dashboard/Overview")
);

const DriverLogs = React.lazy(() =>
  import("views/compliance/driverLogs/DriverLogs")
);
const EldDisconnections = React.lazy(() =>
  import("views/compliance/eldDisconnections/EldDisconnections")
);
const DrugAlcoholTests = React.lazy(() =>
  import("views/compliance/drugAlcoholTests/DrugAlcoholTests")
);
const FreightClaims = React.lazy(() =>
  import("views/compliance/freightClaims/FreightClaims")
);
const RoadsideInspections = React.lazy(() =>
  import("views/compliance/roadsideInspections/RoadsideInspections")
);
const Accidents = React.lazy(() =>
  import("views/compliance/accidents/Accidents")
);
const Report = React.lazy(() => import("views/reportsV2/Reports"));
const Payees = React.lazy(() => import("views/accounting/payees/Payees"));
const AddressBooks = React.lazy(() =>
  import("views/freight/addressBook/AddressBooks")
);
const PreferredLane = React.lazy(() =>
  import("views/freight/prefferedLane/PrefferedLane")
);
const MaintenanceOverview = React.lazy(() =>
  import("views/maintenance/overview/Overview")
);
const Inspections = React.lazy(() =>
  import("views/maintenance/inspection/Inspections")
);
const Parts = React.lazy(() => import("views/maintenance/part/Parts"));
const Vendors = React.lazy(() => import("views/maintenance/vendor/Vendors"));
const ServiceRequests = React.lazy(() =>
  import("views/maintenance/serviceRequest/ServiceRequests")
);
const ServiceTasks = React.lazy(() =>
  import("views/maintenance/serviceTask/ServiceTask")
);
const ServiceRepairs = React.lazy(() =>
  import("views/maintenance/serviceRepairs/ServiceRepairs")
);
const Providers = React.lazy(() =>
  import("views/maintenance/serviceProvider/Providers")
);
const Reminders = React.lazy(() =>
  import("views/maintenance/serviceReminders/Reminders")
);

const PurchaseOrders = React.lazy(() =>
  import("views/maintenance/purchaseOrders/PurchaseOrders")
);

const FleetOwners = React.lazy(() => import("views/fleet/fleetOwners/Owner"));

const Calls = React.lazy(() => import("views/messaging/calls/Calls"));
const EdiLogs = React.lazy(() => import("views/messaging/edi-logs/EdiLogs"));

const FilePreview = React.lazy(() =>
  import("views/documentPreview/FilePreview")
);
const Transactions = React.lazy(() =>
  import("views/accounting/transcations/Transcation")
);

const FuelSurchagePrograms = React.lazy(() =>
  import("views/fleet/fuelSurchage/FuelSurcharges")
);

import CarrierCharges from "views/accounting/carrierCharges/CarrierCharges";
import MessagesWrapper from "views/messaging/MessagesWrapper";
import WhatsAppWrapper from "views/messaging/whatsapp/WhatsAppWrapper";
import Carriers from "views/carriers/Carriers";

const routes = [
  {
    path: "/integration",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/icons",
    component: IconDisplay,
    showFor: "unify,ramify,tms,mnt,4pl,free",
  },
  {
    path: "/token",
    component: Token,
    showFor: "unify,ramify,tms,mnt,4pl,free",
  },

  {
    path: "/",
    title: "Dashboard",
    component: DashBoard,
    showFor: "free",
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: DashBoard,
    showFor: "free",
  },
  {
    path: "/",
    title: "Dashboard",
    component: DashBoard,
    showFor: "unify,ramify,tms",
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: DashBoard,
    showFor: "unify,ramify,tms",
  },

  {
    path: "/activity",
    title: "Activity",
    component: ActivitiesV2,
    showFor: "unify,ramify,tms,free",
  },

  {
    path: "/shipments",
    title: "Shipments",
    component: Shipments,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.shipment"),
  },
  {
    path: "/customers",
    title: "Customers",
    component: Customers,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.customer"),
  },
  {
    path: "/messages",
    component: MessagesWrapper,
    showFor: "unify,ramify,tms,4pl,free",
  },
  {
    path: "/whatsapp",
    component: WhatsAppWrapper,
    showFor: "unify,ramify,tms,4pl,free",
  },
  {
    path: "/calls",
    component: Calls,
    showFor: "unify,ramify,tms,4pl,free",
  },
  {
    path: "/ediLogs",
    component: EdiLogs,
    showFor: "unify,ramify,tms,4pl,free",
  },
  {
    path: "/profile",
    component: Profile,
    showFor: "unify,ramify,tms,4pl,free",
  },

  {
    path: "/marketplace",
    title: "Marketplace",
    component: MarketPlace,
    showFor: "unify,ramify,tms,4pl,free",
    hasInCarrier: (carrierInfo) => carrierInfo.hasMarketplace,
  },

  //Compliance
  {
    path: "/overview",
    component: ComplianceOverview,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/driverLogs",
    component: DriverLogs,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/roadsideInspections",
    component: RoadsideInspections,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/accidents",
    component: Accidents,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/daTests",
    component: DrugAlcoholTests,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/eldDisconnections",
    component: EldDisconnections,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  {
    path: "/freightClaims",
    component: FreightClaims,
    showFor: "unify",
    hasPermission: (hasPermission) => hasPermission("view.compliance"),
  },
  //

  {
    path: "/reports",
    title: "Reports",
    component: Report,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) =>
      hasPermission("view.report.shipments") ||
      hasPermission("view.report.accounting"),
  },

  //freight
  {
    path: "/addressBooks",
    title: "Addressbook | Lanes",
    component: AddressBooks,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/territory",
    title: "Territory | Lanes ",
    component: FreightGroups,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/preferredLane",
    title: "Preferred Lanes | Lanes ",
    component: PreferredLane,
    showFor: "unify,ramify,tms,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },

  //accounting
  {
    path: "/invoices",
    title: "Invoices | Accounting",
    component: Invoices,
    showFor: "unify,ramify,tms,free",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },
  {
    path: "/transactions",
    title: "Transactions | Accounting",
    component: Transactions,
    showFor: "unify,ramify,tms,free",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },
  {
    path: "/settlements",
    title: "Settlements | Accounting",
    component: SettlementsV2,
    showFor: "unify,ramify,tms,free",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },
  {
    path: "/accountReceivable",
    title: "Receivable | Accounting",
    component: AccountReceivable,
    showFor: "unify,ramify,tms,free",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },
  {
    path: "/payees",
    component: Payees,
    showFor: "unify,ramify,tms,free",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },
  {
    path: "/carrier_charges",
    title: "Carrier Charges | Accounting",
    component: CarrierCharges,
    showFor: "4pl",
    hasPermission: (hasPermission) => hasPermission("view.accounting"),
  },

  //

  {
    path: "/",
    title: "Carriers",
    component: Carriers,
    showFor: "4pl",
  },
  {
    path: "/carriers",
    title: "Carriers",
    component: Carriers,
    showFor: "4pl",
  },

  //settings
  {
    path: "/notification-settings",
    title: "Notifications | Settings",
    component: NotificationSettings,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/carrier-profile-settings",
    title: "Profile | Settings",
    component: CarrierSettingV2,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/carrier-profile-settings_v2",
    title: "Profile | Settings",
    component: CarrierSettingV2,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/data-import",
    title: "Data Import | Settings",
    component: DataImport,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/integration",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration/motive",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration/samsara",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration/quickbooks",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration/freshbooks",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/settings/integration/sage",
    title: "Intergration | Settings",
    component: Integration,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.carrier.info"),
  },
  {
    path: "/user-management",
    title: "Users | Settings",
    component: UserManagment,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("view.user"),
  },
  {
    path: "/role-management",
    component: RoleManagement,
    showFor: "unify,ramify,tms,mnt,4pl,free",
    hasPermission: (hasPermission) => hasPermission("edit.user.roles"),
  },
  {
    path: "/factoring",
    component: Factoring,
    showFor: "unify,ramify,tms,mnt,4pl,free",
  },

  //fleet
  {
    path: "/drivers",
    title: "Drivers | Fleet",
    component: Drivers,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/vehicles",
    title: "Vehicles | Fleet",
    component: Vehicles,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/trailers",
    title: "Trailers | Fleet",
    component: Trailers,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/fuel_card",
    title: "Fuel Card | Fleet",
    component: FuelCard,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/fuel_price",
    title: "Fuel Price | Fleet",
    component: FuelPrice,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/fuel_surcharges",
    title: "Fuel Surcharges",
    component: FuelSurchagePrograms,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/fleetgroups",
    title: "Fleet Groups | Fleet",
    component: FleetGroups,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/fleet_owner",
    title: "Owner | Fleet",
    component: FleetOwners,
    showFor: "unify,ramify,tms",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  {
    path: "/iftareport",
    title: "IFTA Report | Fleet",
    component: IFTAReport,
    showFor: "unify,ramify,tms,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.fleet"),
  },
  //

  //maintenance
  {
    path: "/maintenance_dashboard",
    component: MaintenanceOverview,
    showFor: "unify,ramify,mnt,free",
  },
  {
    path: "/inspections",
    component: Inspections,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/parts",
    component: Parts,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/vendors",
    component: Vendors,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/service_tasks",
    component: ServiceTasks,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/service_requests",
    component: ServiceRequests,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/service_repairs",
    component: ServiceRepairs,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/service_providers",
    component: Providers,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/service_reminders",
    component: Reminders,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/purchase_orders",
    component: PurchaseOrders,
    showFor: "unify,ramify,mnt,free",
    hasPermission: (hasPermission) => hasPermission("view.maintenance"),
  },
  {
    path: "/preview/",
    component: FilePreview,
    showFor: "unify,ramify,mnt,free",
  },
];

export default routes;
