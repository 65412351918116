import CellBuilder from "components/cellBuilderTable/CellBuilder";

export const columns = [
  {
    name: "Repair Order #",
    selector: (row) => row.id,
    grow: 1.4,
    cell: (row) => CellBuilder(row.id),
  },
  {
    name: "REPAIR ORDER DATE",
    selector: (row) => row.repairDate,
    grow: 1.9,
    cell: (row) => CellBuilder(row.repairDate),
  },

  {
    name: "SERVICES",
    selector: (row) => row.service,
    grow: 2.5,
    cell: (row) => CellBuilder(row.service),
  },
  {
    name: "TOTAL COST",
    selector: (row) => row.tCost,
    grow: 1.5,
    cell: (row) => CellBuilder(row.tCost),
  },

  {
    name: "STATUS",
    selector: (row) => row.status,
    grow: 1,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 1,
    center: true
  },
];
