import { useState, useEffect } from "react";

import InputMiler from "components/inputMiler/InputMiler";
import "components/inputMiler/advanceSearch/advancedSearch.sass";
import { getElements } from "services/serviceRepairApi";
import useDebounce from "hooks/useDebounce";
import Loading from "components/loading/Loading";

const Searchcomponent = ({
  url,
  searchResultItem,
  value,
  label,
  error,
  required,
  placeholder,
  newPart,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [realInputValue, setRealInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchState, setSearch] = useState({
    result: [],
    searchDisplay: false,
  });

  const debounceSearch = useDebounce(realInputValue, 500);
  let fetcher = (query) => {
    setLoading(true);
    getElements(`${url}=${query}`)
      .then((response) => {
        setLoading(false);
        setSearch({
          ...searchState,
          result: response.data,
          searchDisplay: true,
          inputValue: query,
        });
      })
      .catch(() => setLoading(false));
  };
  const changed = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setRealInputValue(value);
  };

  const clickAway = () => {
    setInputValue(value);
    setTimeout(() => {
      searchState.searchDisplay &&
        setSearch({ ...searchState, searchDisplay: false });
    }, 500);
  };

  useEffect(() => {
    debounceSearch && fetcher(debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return (
    <div className="advanced-search">
      <InputMiler
        label={label}
        type="search"
        onChange={changed}
        value={inputValue || ""}
        onBlur={clickAway}
        error={error}
        required={required}
        disabled={disabled}
        placeholder={placeholder || "search"}
      />
      {searchState.searchDisplay && (
        <div className="advance-search__result">
          {!searchState.result?.length && newPart
            ? newPart(debounceSearch)
            : null}
          {searchState.result.map((item, index) =>
            searchResultItem(item, index)
          )}
        </div>
      )}
      {!searchState.searchDisplay && loading && (
        <div className="advance-search__result w-100 p-5">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Searchcomponent;
