import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";

const UnassignTripModal = ({ open, closeModal, unassign }) => {
  const onSubmit = () => {
    closeModal(false);
    unassign();
  };
  return (
    <Modal isOpen={Boolean(open)} toggle={() => closeModal(false)}>
      <ModalHeader toggle={() => closeModal(false)}>Unassign Trip</ModalHeader>
      <ModalBody>
        <div>
          Are you sure you want to remove the vehicle and driver assignment for
          this trip?
        </div>
      </ModalBody>
      <ModalFooter>
        <MilerButton title="Unassign Trip" btnDark onClick={onSubmit} />
      </ModalFooter>
    </Modal>
  );
};

export default UnassignTripModal;
