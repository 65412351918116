import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useContext, useEffect, useState } from "react";
import { appContext } from "services/AppContext";
import { postElement } from "services/milerApi";
import findError from "utils/findError";

const AddTransaction = ({ setClickState, tripInfo }) => {
  const { tripId } = tripInfo;
  const { enums } = useContext(appContext);
  const [errors, setFormErrors] = useState([]);
  const [payeeErrors, setPayeeErrors] = useState(null);
  const [state, setState] = useState({
    transactionUtc: "",
    transactionAmount: "",
    transactionType: "",
    transactionCode: "",
    description: "",
    tripId,
    payeeId: "",
    payeeName: "",
  });
   const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const postTransaction = () => {
    const selectedPayee = tripInfo.payees.find(
      (p) => state.payeeName === p.payeeName
    );
    if (!selectedPayee && !selectedPayee?.payeeId) {
      setPayeeErrors("Payee  is required");
      return false;
    }
    if (selectedPayee && selectedPayee.payeeId) {
      postElement(
        `/Carrier/Payee/${selectedPayee.payeeId}/Transaction`,
        state
      ).then((response) => {
        if (response.success) {
          setClickState(false);
        } else if (response.vErrors) {
          setFormErrors(response.vErrors);
        }
        setPayeeErrors("");
      });
    }
  };
  useEffect(() => {
    if (tripInfo.payees && tripInfo.payees.length === 1) {
      setState((prevState) => ({
        ...prevState,
        payeeName: tripInfo.payees[0].payeeName,
      }));
    }
  }, [tripInfo.payees]);
  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Add Transaction</div>
        <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
          <MilerButton
            icon="mil-close-bg"
            title="Cancel"
            onClick={() => {
              setClickState(false);
            }}
          />
          <MilerButton
            btnDark
            icon="mil-save-o"
            title="Save"
            onClick={postTransaction}
          />
        </div>
      </div>
      <div className="slider-display-body customer-form">
        <div className="miler-card mt-0">
          <div className="miler-card-title">General</div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="date"
                label="Transaction Date"
                name="transactionUtc"
                value={state.transactionUtc}
                onChange={onChange}
                error={findError("TransactionUtc", errors)}
                required
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="number"
                label="Transaction Amount"
                name="transactionAmount"
                value={state.transactionAmount}
                onChange={onChange}
                required
                error={findError("TransactionAmount", errors)}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select"
                options={enums.TransactionType}
                label="Transaction Type"
                name="transactionType"
                value={state.transactionType}
                onChange={onChange}
                error={findError("transactionType", errors)}
                required
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select"
                options={
                  tripInfo.payees
                    ? tripInfo.payees.map((p) => p.payeeName || "")
                    : [""]
                }
                label="Payee"
                name="payeeName"
                value={state.payeeName}
                onChange={onChange}
                error={payeeErrors}
                required
              />
            </div>

            <div className="col-12 col-lg-12 col-xl-12">
              <InputMiler
                type="textarea"
                label="Description"
                name="description"
                value={state.description}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTransaction;
