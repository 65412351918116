import AssociatedNotes from "components/associatedNotes/AssociatedNotes";
import { useEffect, useState } from "react";
import { getElements, putElement } from "services/milerApi";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";

function Notes({ id }) {
  const [notes, setNotes] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState({
    loadingNotes: true,
    // loadingDocuments: true,
  });

  const { loadingNotes } = loadingStatus;

  const getNotes = (id, load) => {
    if (load) {
      setLoadingStatus((prev) => ({ ...prev, loadingNotes: true }));
    }
    const isFleetOwner =
      getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";
    getElements(
      isFleetOwner ? `/FleetOwner/Driver/${id}/Notes` : `/Driver/${id}/Notes`
    )
      .then((response) => {
        setNotes(response.data);
        setLoadingStatus((prev) => ({ ...prev, loadingNotes: false }));
      })
      .catch(() => {
        setLoadingStatus((prev) => ({ ...prev, loadingNotes: false }));
      });
  };

  const updateNotes = () => {
    getUserInfoCookieProp("subscription_plan") !== "Miler Rectify (Free)" &&
      putElement(`/Driver/${id}/Notes`, notes).then((response) => {
        if (response.success) {
          getNotes(id, false);
        }
      });
  };

  useEffect(() => {
    if (id) {
      getNotes(id, true);
    }
  }, []);

  const deleteNote = (note, index) => {
    note.splice(index, 1);
    setNotes([...note]);
    updateNotes();
    return;
  };
  return (
    <>
      <AssociatedNotes
        notes={notes || []}
        onAddNote={(note) => {
          const notess = notes || [];
          notess.unshift(note);
          updateNotes();
          setNotes(notess);
        }}
        loadingNotes={loadingNotes}
        hideSelectOption={true}
        deleteNote={deleteNote}
      />
    </>
  );
}

export default Notes;
