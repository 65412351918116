import { Component } from "react";
import joinStrings from "utils/joinStrings";
import "./ErrorBoundary.sass";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div
          className={joinStrings(["error-boundary", this.props.classes], " ")}
        >
          {/* <div className="error-info">{this.props.message} Error Ocurred</div> */}
          <p className="error-info">
            Sorry, we are having trouble loading your data. Please try reloading
            the page, or contact support at 866-497-2063 if the issue persists
          </p>
          <details className="error-details">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export { ErrorBoundary };
