import { useContext, useEffect, useState } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import { getElementsV2, putElement } from "services/milerApi";
import Loading from "components/loading/Loading";

const Documents = ({ documentUrl, documentCategory }) => {
  const {
    enums: { DocumentTypeFleet },
  } = useContext(appContext);

  const [documents, setDocuments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const addDoc = (docObject) => {
    let docs = [...documents, docObject];
    putElement(`${documentUrl}/Documents`, docs).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const delDoc = (index) => {
    let updatedDocuments = documents;
    updatedDocuments.splice(index, 1);
    putElement(`${documentUrl}/Documents`, updatedDocuments).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const getDocuments = (isLoad) => {
    if (isLoad) {
      setIsLoading(true);
    }
    getElementsV2({
      url: `${documentUrl}/Documents`,
      setLoading: setIsLoading
    })
      .then((response) => {
        setDocuments(response.data || []);
      })
  };

  useEffect(() => {
    getDocuments(true);
  }, [documentUrl]);

  return (
    <>
      {isLoading ? (
        <section className="mt-2">
          <Loading />
        </section>
      ) : (
        <AssociatedDocuments
          documents={documents}
          delDoc={delDoc}
          addDoc={addDoc}
          enums={DocumentTypeFleet}
          documentCategory={documentCategory}
        />
      )}
    </>
  );
};

export default Documents;
