import { useState, useEffect, useContext } from "react";
import { NavItem, NavLink, Nav, Button } from "reactstrap";
import classNames from "classnames";
import { NavLink as RRDNavLink } from "react-router-dom";
import { getCookie } from "utils/cookies";
import SubMenu from "./SubMenu";
import sidebarRoutes from "components/sidebar/sidebarRoutes";
import miler from "assets/logo/logo.png";
import { appContext } from "services/AppContext";
import { hasPermission } from "utils/hasPermission";
import permissionDeniedToast from "utils/permissionDeniedToast";
import "./sidebar.sass";

const createLinks = (
  filteredRoutes,
  flag,
  selectedNav,
  toggleSelectedNav,
  subMenuOpen,
  toggleSubMenu
) => {
  return filteredRoutes.map((prop, key) => {
    let permissionEnabled = hasPermission(`view.${prop.permissionPostFix}`);

    if (prop.permissionPostFix == "reports") {
      //Special case reports
      permissionEnabled =
        hasPermission(`view.report.shipments`) ||
        hasPermission(`view.report.accounting`);
    }

    if (prop.submenu.length > 0) {
      return (
        <SubMenu
          key={key}
          title={prop.label}
          icon={prop.icon}
          items={prop.submenu}
          flag={flag}
          selectedNav={selectedNav}
          toggleSelectedNav={toggleSelectedNav}
          subMenuOpen={subMenuOpen}
          toggleSubMenu={toggleSubMenu}
          permissionPostFix={prop.permissionPostFix}
        />
      );
    }

    return (
      <NavItem key={key} className="sidebar-menu-item-wrapper">
        <NavLink
          to={prop.path}
          tag={(props) => <RRDNavLink {...props} />}
          className={classNames("sidebar-menu-item", {
            "selected-nav": selectedNav.nav === prop.label,
            "item-disabled": !permissionEnabled,
          })}
          name={prop.label}
          onClick={(e) => {
            if (permissionEnabled) {
              toggleSelectedNav(e);
            } else {
              e.preventDefault();
              permissionDeniedToast();
            }
          }}
        >
          {prop.icon === "mil-activity_side" ? (
            <div className="sidebar-menu-item-icon_activity">
              <i className={`mil-activity_side sidebar-menu-item-icon `} />
            </div>
          ) : (
            <i className={`${prop.icon} sidebar-menu-item-icon`} />
          )}
          {flag === true ? prop.label : ""}
        </NavLink>
      </NavItem>
    );
  });
};

const SideBar = () => {
  const carrierInfo = getCookie("carrierInfo") || {};

  const { isMobile, appLogo,setCurrent,setClickedReport } = useContext(appContext);
  //fetch the carrieinfo from session storage to get company

  const [isOpen, setSidebarOpen] = useState(true);
  const [selectedNav, setSelectedNav] = useState({ nav: "", parent: "" });
  const [subMenuOpen, setSubMenuOpen] = useState("");

  const toggleSubMenu = (e) => {
    let nam = e.target.name || e.target.id;
    nam = subMenuOpen === nam ? "" : nam;
    setSubMenuOpen(nam);
  };

  const toggleSelectedNav = (e, parentNav) => {
    let nam = e.target.name;
    setSelectedNav({ parent: parentNav, nav: nam });
    setSubMenuOpen(parentNav);
    setClickedReport(null);
    setCurrent("");
  };

  const toggleSidebar = () => setSidebarOpen(!isOpen);

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  const { subscription_plan, carrierId } = getCookie("userInfo") || {};

  let filteredRoutes = [];

  if (subscription_plan === "Miler Unify (TMS + Maintenance)") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("unify")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("unify")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  } else if (subscription_plan === "Miler Ramify (TMS + Maintenance)") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("ramify")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("ramify")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  } else if (subscription_plan === "Miler 4PL") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("4pl")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("4pl")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  } else if (subscription_plan === "Miler Amplify (TMS)") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("tms")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("tms")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  } else if (subscription_plan === "Miler Rectify Plus (Maintenance)") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("mnt")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("mnt")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  } else if (subscription_plan === "Miler Rectify (Free)") {
    filteredRoutes = [];
    sidebarRoutes.forEach((route) => {
      if (route.showFor.includes("free")) {
        let modifiedSubMenu = route.submenu.filter((sub) =>
          sub.showFor.includes("free")
        );
        filteredRoutes.push({ ...route, submenu: modifiedSubMenu });
      }
    });
  }

  if (!carrierInfo.hasMarketplace) {
    filteredRoutes = filteredRoutes.filter(
      (route) => route.label != "Marketplace"
    );
  }

  filteredRoutes = filteredRoutes.map((route) => {
    if (route.path == "/communication" && carrierId != 2) {
      let modifiedSubMenu = route.submenu.filter(
        (sub) => sub.target != "/ediLogs"
      );
      return { ...route, submenu: modifiedSubMenu };
    } else {
      return route;
    }
  });

  return (
    <div
      className={classNames("  sidebar", {
        "is-open": isOpen,
      })}
    >
      <div className="d-flex sidebar-header justify-content-center">
        {isOpen && (
          <div>
            <img src={miler} alt="Miler LLC" className="sidebar-header-logo" />
          </div>
        )}
        <div>
          <Button className="btn btn-light " onClick={toggleSidebar}>
            <i className="mil-menu-fold sidebar-toggle-icon" />
          </Button>
        </div>
      </div>
      <div className="sidebar-menu-list">
        <Nav vertical className={classNames(" list-unstyled ")}>
          {createLinks(
            filteredRoutes,
            isOpen,
            selectedNav,
            toggleSelectedNav,
            subMenuOpen,
            toggleSubMenu
          )}
        </Nav>
      </div>
      <section className="sidebar-footer">
        {isOpen && (
          <>
            {appLogo ? (
              <img
                src={appLogo}
                className="sidebar-footer-logo"
                alt="company_logo"
              />
            ) : (
              <p className="company-title">{carrierInfo.company}</p>
            )}
          </>
        )}
      </section>
    </div>
  );
};

export default SideBar;
