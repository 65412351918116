import {
    getApi,
    postApi,
    putApi,
    deleteApi,
  } from "services/utils/axiosWrapper";
  import { Toast } from "./Toast";
  
  const apiConfig = {
    baseUrls: {
      local: "https://dev-communication.miler.com/",
      dev: "https://dev-communication.miler.com/",
      qa: "https://dev-communication.miler.com/",
      staging: "https://dev-communication.miler.com/",
      prod: "https://dev-communication.miler.com/",
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 6000,
  };
  
  const errorsHandler = (error, toastCode) => {
    let vErrors; //validation errors if they exist
    if (error.response) {
      let response = error.response;
      if (response.status === 400) {
        let title = response.title || "400: Validation Errors";
        Toast.error(title, toastCode)
        vErrors = response.data.errors;
      } else {
        let title = response.title || `${response.status}: Error`;
        Toast.error(title, toastCode)
      }
    } else {
      Toast.error("Unhandle error: something went wrong", toastCode)
    }
    return { success: false, vErrors };
  };
  
  export async function getElements(url) {
    return await getApi(url, apiConfig, "1.0");
  }

  export async function putElement(url, data) {
    const toastCode = Toast.loading("Please wait")
    return putApi(url, apiConfig, data, "1.0")
      .then((response) => {
        let message = response.data.data ? response.data.data : response.data;
  
        Toast.success(message, toastCode)
        return { success: true };
      })
      .catch((error) => errorsHandler(error, toastCode));
  }
  
  export async function postElement(url, data) {
    const toastCode = Toast.loading("Please wait")
    return postApi(url, apiConfig, data, "1.0")
      .then((response) => {
        let message = response.data.data ? response.data.data : response.data;
        Toast.success(message, toastCode)
        return { success: true, response };
      })
      .catch((error) => errorsHandler(error, toastCode));
  }
  export async function postCallElement(url, data) {
    return postApi(url, apiConfig, data, "1.0")
      .then((response) => {
        return { success: true, response };
      })
      .catch((error) => errorsHandler(error, toastCode));
  }
  export async function deleteElement(url) {
    const toastCode = Toast.loading("Please wait")
    return deleteApi(url, apiConfig, {}, "1.0")
      .then((response) => {
        let message = response.data.data ? response.data.data : response.data;
        Toast.success(message, toastCode)
      })
      .catch((error) => {
        Toast.error(error.response, toastCode)
      });
  }
  