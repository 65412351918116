import { useContext, useEffect, useState } from "react";
import "./IntegrationContent.sass";
import InputMiler from "components/inputMiler/InputMiler";
import { Col } from "reactstrap";
import { appContext } from "services/AppContext";
import Loading from "components/loading/Loading";
import { postGatewayApi } from "services/gatewayApis";
import { nanoid } from "nanoid";
import MilerButton from "components/milerButton/MilerButton";
import { getCookie } from "utils/cookies";
import { getElements } from "services/milerApi";
import ConfirmDialog from "components/confirmDialog/confirmDialog";

const EldFormContent = ({ setOnBoardingLoading }) => {
  const [loading, setLoading] = useState();

  const { subscription_plan } = getCookie("userInfo") || {};

  const [cardLoading, setCardLoading] = useState(<Loading />);

  const [eldState, setEldState] = useState({});

  const changed = (e) => {
    const { value, name } = e.target;
    setEldState({ ...eldState, [name]: value });
  };

  const getIntegrations = () => {
    setCardLoading(Loading);
    setOnBoardingLoading(true);
    let path =
      subscription_plan === "Miler Rectify (Free)"
        ? "/fleetowner/integration"
        : "/carrier/integration";
    getElements(path)
      .then((res) => {
        setEldState(res.data);
        setIntergrationDetail({
          ...intergrationDetail,
          initialAccountProvider: res.data.accountingProvider,
          initialEldProvider: res.data.eldProvider,
        });
        setCardLoading(false);
        setOnBoardingLoading(false);
      })
      //.then(() => t());
      .catch(() => setLoading(false));
  };

  const { enums } = useContext(appContext);
  const [showDialog, setShowDialog] = useState(false);

  let userInfo = getCookie("userInfo") || {};

  const [intergrationDetail, setIntergrationDetail] = useState({
    initialAccountProvider: "",
    initialEldProvider: "",
  });

  const connectEld = (code, providerType) => {
    setLoading(Loading);
    let providerEnumId = (enums?.ELD || []).find(
      (x) => x.keyName.toLowerCase() === providerType
    )?.keyValue;

    postGatewayApi(`carrier/integration/eld`, {
      eld_provider: providerEnumId,
      eld_token: code,
    })
      .then((res) => {
        if (res.success) {
          getIntegrations();
          setLoading(<i className="mil-check-circle motive-ok" />);
        } else {
          errorHandler();
        }
      })
      .catch(errorHandler);

    function errorHandler() {
      setLoading(<i className="mil-info-f motive-bad" />);
    }
  };
  const setUpEld = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var code = urlParams.get("code");

    if (code) {
      if (location.pathname === "/settings/integration/motive") {
        connectEld(code, "motive");
      } else if (location.pathname === "/settings/integration/samsara") {
        connectEld(code, "samsara");
      }
    }
  };

  const getEldCode = () => {
    let stateString = nanoid();
    window.open(
      `https://api.samsara.com/oauth2/authorize?client_id=fb2fc400-a87e-4bb8-bb7c-2270067266bb&state=${stateString}&response_type=code`,
      "_blank"
    );
  };

  const getMotiveCode = () => {
    let client_id =
      "13b0b47ce4ec73cda5c51671459589d024e57091530fec02598861f1de080cb3";
    let redirect_uri = "https://app.miler.com/settings/integration/motive";
    window.open(
      `https://api.gomotive.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=users.manage vehicles.manage messages.manage hos_logs.available_time hos_logs.hours_of_service locations.vehicle_locations_list locations.vehicle_locations_single locations.driver_locations ifta_reports.trips ifta_reports.summary companies.read forms.read form_entries.read dispatch_locations.manage dispatches.manage assets.read locations.asset_locations hos_logs.read`,
      "_blank"
    );
  };

  const revokeEldProvider = () => {
    let providerEnumId = (enums?.ELD || []).find(
      (x) => x.keyName.toLowerCase() === eldState.eldProvider.toLowerCase()
    )?.keyValue;

    const eldProviderInfo = {
      eld_provider: providerEnumId,
      eld_token: eldState.eldToken,
      eld_org_id: eldState.eldorgId,
    };
    postGatewayApi(`carrier/integration/eld/revoke`, eldProviderInfo).then(
      (response) => {
        if (response.success) {
          getIntegrations();
        }
      }
    );
  };

  const { initialEldProvider } = intergrationDetail;

  useEffect(() => {
    setUpEld();
    getIntegrations();
  }, []);

  useEffect(setUpEld, []);

  return (
    <div className="miler-card integration-content">
      {cardLoading ? (
        cardLoading
      ) : (
        <>
          <div className="miler-card-title">
            <div className="d-flex justify-content-between">
              <div>ELD</div>
              <>{loading}</>
              {eldState.eldToken && !loading && (
                <i className="mil-check-circle motive-ok" />
              )}
            </div>
          </div>
          <div className="row input_section">
            <Col>
              <InputMiler
                name="eldProvider"
                value={eldState.eldProvider}
                onChange={changed}
                label="Eld Provider"
                type="select"
                options={enums.ELD}
              />
            </Col>
            <Col>
              <InputMiler
                name="eldorgId"
                value={eldState.eldorgId}
                label={`${
                  eldState.eldProvider
                    ? `${eldState.eldProvider} Org ID`
                    : "Org ID"
                }`}
                disabled
              />
            </Col>
            <Col>
              <InputMiler
                name="eldToken"
                value={eldState.eldToken}
                label="Eld Token"
                disabled
              />
            </Col>
            {eldState.eldProvider &&
              eldState?.eldProvider !== initialEldProvider && (
                <section className="quickbook_install_btn">
                  <Col>
                    <MilerButton
                      btnDark
                      title="Install"
                      onClick={() => {
                        if (
                          (eldState.eldProvider || "").toLowerCase() ===
                          "samsara"
                        ) {
                          getEldCode();
                        }
                        if (
                          (eldState.eldProvider || "").toLowerCase() ===
                          "motive"
                        ) {
                          getMotiveCode();
                        }
                        if (
                          (eldState.eldProvider || "").toLowerCase() ===
                          "Verizon Connect Reveal".toLowerCase()
                        ) {
                          setShowDialog(true);
                        }
                      }}
                      disabled={!userInfo.admin_user}
                    />
                  </Col>
                </section>
              )}
            {eldState.eldToken && eldState?.eldProvider === initialEldProvider && (
              <section className="quickbook_install_btn">
                <Col>
                  <MilerButton
                    btnDark
                    title="Revoke"
                    onClick={() => revokeEldProvider()}
                    disabled={!userInfo.admin_user}
                  />
                </Col>
              </section>
            )}
          </div>
        </>
      )}
      {showDialog && (
        <ConfirmDialog
          open={showDialog}
          text="This integration requires implementation. Please contact our Support Team"
          onCancel={() => setShowDialog(false)}
          title={eldState.eldProvider}
          cancelText="Close"
        />
      )}
    </div>
  );
};

export default EldFormContent;
