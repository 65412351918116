import "./ShipmentEvent.sass";

import dateFormatter from "utils/dateFormater";

const ShipmentEvent = ({ eventName, eventUtc, eventBody, createdBy }) => {
  return (
    <div className="shipment_history_event_controller">
      <div className="first">
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <div className="second">
        <div className="date">{dateFormatter(eventUtc, "MMM DD, YYYY")}</div>
        <div className="time">
          {eventName === "Stop Departure" || eventName === "Stop Arrival"
            ? dateFormatter(eventUtc, "HH:mm", true)
            : dateFormatter(eventUtc, "HH:mm")}
        </div>
      </div>
      <div className="third">
        <div className="line"></div>
      </div>
      <div className="forth">
        <div className="name">{eventName}</div>
        <div className="value">
          <div>{eventBody}</div>
          <div>By {createdBy}</div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentEvent;
