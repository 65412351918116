import { useState } from "react";
import RecurringShipmentForm from "./RecurringShipmentForm";
import MilerButton from "components/milerButton/MilerButton";
import "./RecurringShipment.sass";
import { postElement } from "services/milerApi";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "../../fleet/vehicles/vehicleDetails/VehicleDetails.sass";
import recurringContract from "./recurringContract";

import { hasPermission } from "utils/hasPermission";
import ShipmentRecurring from "./recurringShipmentForm/ShipmentsRecurring";

const RecurringShipment = ({ setClickState, getShipments, shipment, type }) => {
  const shipmentId = shipment?.shipmentId;
  const defaultStartDate = shipment?.scheduledPickupUTC?.split("T")[0];

  const [current, setCurrent] = useState({
    ...recurringContract,
    startUTC: defaultStartDate,
    masterShipment: []
  });
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const onChange = (e) => {
    let { name, value } = e.target;
    setCurrent({ ...current, [name]: value });
  };
  const [errors, setErrors] = useState([]);

  const postRecurringShipment = () => {
    postElement(`/Shipment/${shipmentId}/Recurring`, current).then(
      (response) => {
        if (response.success) {
          getShipments();
          setClickState(false);
        } else {
          setErrors(response.vErrors || []);
        }
      }
    );
  };

  const isDisabled = current?.canEdit === false ? true : false;
  return (
    <div className="slider-display vehicle-details p-0">
      <div className="slider-display-header">
        <div className="vehicle-details-header">
          <div className="vehicle-details-info">
            <div>
              <div className="vhe-dtls__vehicle-number-status">
                <h3 className="vhe-dtls__vehicle-number">Recurring Shipment</h3>
              </div>
              <span className="vhe-dtls__info-description">
                {shipment?.shipmentNumber} | {shipment?.shipperName}
              </span>
            </div>
            <div className="vehicle-content__header__buttons">
              <MilerButton
                icon="mil-close-bg"
                title="Cancel"
                onClick={() => {
                  setClickState(false);
                }}
              />
              {activeTab !== "2" && (
                <MilerButton
                  btnDark
                  icon="mil-save-o"
                  title="Save"
                  targetActionDenied={!hasPermission("edit.shipment")}
                  onClick={() => postRecurringShipment()}
                  {...(type === "marketPlace" && { disabled: "disabled" })}
                  disabled={isDisabled}
                />
              )}
            </div>
          </div>
          <div className="vehicle-content__tabs-container-items vehicle-details-tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Recurrent Schedule
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("vehicle-content__tabs-item", {
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Recurring Shipment
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="slider-display-body">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mt-3">
            {activeTab === "1" && (
              <RecurringShipmentForm
                current={current}
                setCurrent={setCurrent}
                onChange={onChange}
                type={type}
                shipmentId={shipmentId}
                errors={errors}
                getShipments={getShipments}
              />
            )}
          </TabPane>
          <TabPane tabId="2" className="mt-3">
            {activeTab === "2" && (
              <ShipmentRecurring
                activeTab={activeTab}
                shipmentId={shipmentId}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default RecurringShipment;
