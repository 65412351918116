import dateFormatter from "utils/dateFormater";
import "./Overview.sass";
import { Card, CardBody, CardTitle } from "reactstrap";

import DataTable from "react-data-table-component";
import ServiceFailureCard from "../ServiceFailureCard";

const Overview = ({ stop, serviceFailure }) => {
  const { maxTemp, minTemp } = stop;
  const temperatures = ` ${
    minTemp !== undefined ? `Min ${minTemp}°F  ` : ""
  } ,  ${maxTemp !== undefined ? `  Max ${maxTemp}°F` : ""}`;

  const columns = [
    {
      name: "REFERENCE TYPE",
      selector: (row) => row.stopReferenceType,
      grow: 4,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.stopReferenceType}</div>
      ),
    },
    {
      name: "REFERENCE",
      selector: (row) => row.reference,
      grow: 4,
      center: true,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.reference}</div>
      ),
    },
  ];

  return (
    <div className="stop_card_details_overview">
      <div className="stop_card">
        <div className="title">Dispatch Info</div>
        <div className="items">
          <div className="itms">
            <div className="itm">
              <div className="title">Loaded Trailer #</div>
              <div className="value">{stop.trailerNumber}</div>
            </div>

            {stop.stopReason && (
              <div className="itm">
                <div className="title">Stop Reason</div>
                <div className="value">{stop.stopReason}</div>
              </div>
            )}
          </div>
          {stop.trailerType && (
            <div className="itms">
              {(maxTemp || minTemp) && (
                <div className="itm">
                  <div className="title">Temperature Controlled</div>
                  <div className="value">{temperatures}</div>
                </div>
              )}
              <div className="itm"></div>
            </div>
          )}
        </div>
      </div>
      <Card className="  my-2">
        <CardBody className="shipment-detail-card py-1">
          <CardTitle className="shipment-detail-header my-0">
            <p className="shipment-detail-title my-0">STOP REFERENCE</p>
          </CardTitle>

          <div className="new-charge-container">
            <DataTable
              columns={columns}
              data={stop.stopReferences}
              className="shipment"
              responsive={true}
              noDataComponent={
                <div style={{ color: "#868C97", padding: "30px" }}>
                  No references
                </div>
              }
            />
          </div>
        </CardBody>
      </Card>

      <div className="stop_card">
        <div className="title">Time</div>
        <div className="items">
          <div className="itms">
            <div className="itm">
              <div className="title">Earliest Arrival</div>
              <div className="value">
                {stop.earliest_arrival
                  ? dateFormatter(
                      stop.earliest_arrival,
                      "MMM DD YYYY, HH:mm",
                      true
                    )
                  : "N/A"}
              </div>
            </div>
            <div className="itm">
              <div className="title">Latest Arrival</div>
              <div className="value">
                {stop.latest_arrival
                  ? dateFormatter(
                      stop.latest_arrival,
                      "MMM DD YYYY, HH:mm",
                      true
                    )
                  : "N/A"}
              </div>
            </div>
          </div>
          {(stop.actualArrival ||
            stop?.checkCall?.etaRelative ||
            stop?.checkCall?.lastUpdatedRelative) && (
            <div className="itms">
              <div className="itm">
                <div className="title">
                  {stop.actualArrival ? "Actual Arrival" : "ETA"}
                </div>
                <div className="value">
                  {stop.actualArrival
                    ? dateFormatter(
                        stop.actualArrivalLocal,
                        "MMM DD YYYY, HH:mm"
                      )
                    : stop?.checkCall?.etaRelative}
                </div>
              </div>
              <div className="itm">
                <div className="title">
                  {stop.actualArrival ? "Actual Departure" : "ETA Updated"}
                </div>
                <div className="value">
                  {stop.actualArrival
                    ? dateFormatter(
                        stop.actualDepartureLocal,
                        "MMM DD YYYY, HH:mm"
                      )
                    : stop?.checkCall?.lastUpdatedRelative}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {stop?.promiseDelayReason && (
        <>
          <ServiceFailureCard
            reasonValue={serviceFailure.promiseDelayReason}
            reasonOnchange={() => {}}
            noteValue={serviceFailure.ServiceFailure}
            noteOnchange={() => {}}
            disabled={true}
          />
        </>
      )}
    </div>
  );
};

export default Overview;
