import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { columns } from "./ServiceHistoryData";
import "./ServiceHistory.sass";
import BorderedText from "components/bordered-text/BorderedText";
import { getElements } from "services/serviceRepairApi";
import CustomPagination from "components/custom-pagination/CustomPagination";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";
import dateFormatter from "utils/dateFormater";
import { digitsToCurrency } from "utils/currencyFormatter";
import SliderModal from "components/sliderModal/SliderModal";
import ServiceRepairDetails from "views/maintenance/serviceRepairs/serviceRepairDetails/ServiceRepairDetails";

const ServiceHistory = ({ id }) => {
  const [repairs, setRepairs] = useState([]);
  const [request, setRequest] = useState({
    Status: "Active",
    offset: 1,
    limit: 50,
  });
  const [loading, setLoading] = useState(Loading);
  const [currentStatus, setCurrentStatus] = useState("");
  const [repairOrdersOpen, setRepairOrderOpen] = useState(false);
  const [repairOrderDocuments, setRepairOrderDocuments] = useState([]);
  const [vehicle, setVehicle] = useState({
    unitNumber: "",
    vehicleType: "",
    make: "",
    model: "",
    year: "",
    plate: "",
    totalCharges: "",
    unitType: "",
    unitId: "",
  });

  let repairsList = repairs.map((data) => {
    return {
      id: data.repairOrderId,
      service: data.issue[0],
      tCost: digitsToCurrency(data.cost),
      repairDate: dateFormatter(data.repairOrderUtc, "YYYY-MM-DD", true),
      status: <BorderedText text={data.status} classes={data.status} />,
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            onClick={() => {
              setRepairOrderOpen(data.repairOrderId);
              setCurrentStatus(data.status);
            }}
            style={{
              fontSize: "21px",
              cursor: "pointer",
            }}
          ></i>
        </div>
      ),
    };
  });

  useEffect(() => {
    getElements(`/Equipment/Vehicle/${id}/RepairOrder`)
      .then((res) => {
        setRepairs(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(<FailedToFetch />);
      });
  }, []);

  return (
    <div className="ServiceRepairs">
      <div className="summary-list">
        {loading ? (
          loading
        ) : (
          <>
            <DataTable
              columns={columns}
              data={repairsList}
              responsive={true}
              nextExists={repairs?.length >= request.limit}
            />
            <CustomPagination setRequest={setRequest} request={request} />
          </>
        )}
      </div>
      <SliderModal
        clickState={repairOrdersOpen}
        setClickState={setRepairOrderOpen}
      >
        <ServiceRepairDetails
          setServiceRepairDetailsOpen={setRepairOrderOpen}
          getRepairs={() => {}}
          id={repairOrdersOpen}
          status={currentStatus}
          repairOrderDocuments={repairOrderDocuments}
          setRepairOrderDocuments={setRepairOrderDocuments}
          setCurrentStatus={setCurrentStatus}
          vehicle={vehicle}
          setVehicle={setVehicle}
        />
      </SliderModal>
    </div>
  );
};

export default ServiceHistory;
