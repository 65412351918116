import InputMiler from "components/inputMiler/InputMiler";

const FleetForm = ({setFleet, fleet, fleetManagerOptions}) => {
     
    const onChange = (e) => {
        const { name, value } = e.target;
        setFleet((prev) => ({ ...prev, [name]: value }));
      };
    

  return (
    <div className="miler-card mt-0">
    <div className="row">
      <div className="col col-12 col-lg-6">
        <InputMiler
          type="text"
          value={fleet?.fleetName}
          name="fleetName"
          label="Fleet Name"
          onChange={onChange}
        />
      </div>
      <div className="col col-12 col-lg-6">
        <InputMiler
          type="selectFleet"
          value={fleet?.fleetManager}
          options={fleetManagerOptions}
          name="fleetManager"
          label="Fleet Manager"
          onChange={onChange}
        />
      </div>
    </div>
  </div>
  );
};

export default FleetForm;
