import  { useEffect} from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Row,
  Col,
} from "reactstrap";
import GoogleMapReact from "google-map-react";
import CustomMarker from "components/customMarker/CustomMarker";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";

const StepThree = ({ state, setState }) => {
  const locationConfig = {
    center: {
      lat: state.address.latitude,
      lng: state.address.longitude,
    },
    zoom: 11,
  };
  useEffect(() => {}, [state.address]);
  return (
    <>
      <Form role="form">
        <Row>
          <Col lg="12">
            <FormGroup>
              <AddressSearcher
                address={state.address}
                dispatch={(newAddress) =>
                  setState({ ...state, address: newAddress })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div style={{ height: "50vh", width: "100%" }}>
              <GoogleMapReact
                defaultCenter={locationConfig.center}
                defaultZoom={locationConfig.zoom}
              >
                <CustomMarker
                  lat={state.address.latitude}
                  lng={state.address.longitude}
                  text={"A"}
                />
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default StepThree;
