import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import MilerButton from "components/milerButton/MilerButton";
import { useState, useContext, useReducer, useEffect } from "react";
import { appContext } from "services/AppContext";
import { putElement, getElements } from "services/milerApi";
import { hasPermission } from "utils/hasPermission";
import CustomerForm from "../customerForm/CustomerForm";
import customerReducer from "../customerForm/customerReducer";
import removeUnfilledObjs from "../customerForm/RemoveUnfilledObjs";
import { customer } from "./initialCustomer";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import "./customer-details.sass";
import Billing from "./billing/Billing";
import CustomerShipments from "./customer-shipments/CustomerShipments";
import Notes from "./notes/Notes";
import Overview from "./overview/Overview";
import Activation from "./activation/Activation";
import FuelSearchCharge from "./fuelSurcharge/FuelSurcharge";

const CustomerDetails = ({
  setCustomerDetailsOpen,
  shipperId,
  getCustomers,
}) => {
  const { enums } = useContext(appContext);
  const [errors, setFormErrors] = useState([]);
  const [activeTab, setActiveTab] = useState("Overview");

  const [state, dispatch] = useReducer(customerReducer, customer);
  const [fscrefresh, setFscRefresh] = useState(false);
  const [data, setData] = useState({
    useNationalAverage: true,
    fuelSurchargeName: "",
    applyOn: "Pickup",
    shipmentType: "Truckload",
    surcharges: [],
  });

  const put = () => {
    let modState = removeUnfilledObjs(state);
    putElement(`/Shipper?shipperId=${shipperId}`, modState).then((response) => {
      if (response.success) {
        getCustomers();
        setCustomerDetailsOpen(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const updateFuelSurcharge = async () => {
    putElement(`/Shipper/${shipperId}/FuelSurcharge`, data).then((response) => {
      if (response.success) {
        setFscRefresh((prev) => !prev);
        setFormErrors([]);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const getCustomerDetails = () => {
    getElements(`/Shipper/${shipperId}`).then((response) => {
      let shipper = response.data;
      if (shipper.mailingContact && shipper.mailingContact.phones)
        shipper.mailingContact.phones.filter((phone) => phone.phoneType);
      if (shipper.billingContact && shipper.billingContact.phones)
        shipper.billingContact.phones.filter((phone) => phone.phoneType);
      dispatch({ all: shipper });
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(getCustomerDetails, []);

  return (
    <div className="slider-display customer-details">
      <div className="slider-display-header customer-details-header">
        <div className="top-header">
          <div className="slider-header-title">{state.shipperName}</div>
          <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
            <MilerButton
              title="Cancel"
              onClick={() => setCustomerDetailsOpen(false)}
            />
            {activeTab == "Profile" && (
              <MilerButton
                btnDark
                title="Save"
                targetActionDenied={!hasPermission("edit.customer")}
                onClick={put}
              />
            )}
            {activeTab == "FuelSearchCharge" && (
              <MilerButton
                btnDark
                title="Save"
                targetActionDenied={!hasPermission("edit.accounting")}
                onClick={updateFuelSurcharge}
              />
            )}
          </div>
        </div>
        <div className="slider-tabs-navbar">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "Overview" ? "active" : ""}
                onClick={() => {
                  toggle("Overview");
                }}
                style={{ cursor: "pointer" }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Profile" ? "active" : ""}
                onClick={() => {
                  toggle("Profile");
                }}
                style={{ cursor: "pointer" }}
              >
                Profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Activation" ? "active" : ""}
                onClick={() => {
                  toggle("Activation");
                }}
                style={{ cursor: "pointer" }}
              >
                Activation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Shipments" ? "active" : ""}
                onClick={() => {
                  toggle("Shipments");
                }}
                style={{ cursor: "pointer" }}
              >
                Shipments
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Billing" ? "active" : ""}
                onClick={() => {
                  toggle("Billing");
                }}
                style={{ cursor: "pointer" }}
              >
                Billing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "Notes" ? "active" : ""}
                onClick={() => {
                  toggle("Notes");
                }}
                style={{ cursor: "pointer" }}
              >
                Notes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === "FuelSearchCharge" ? "active" : "tab_item"
                }
                onClick={() => {
                  if (hasPermission("edit.accounting")) {
                    toggle("FuelSearchCharge");
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                Fuel Surcharge
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
      <div className="slider-display-body">
        <TabContent activeTab={activeTab}>
          {activeTab === "Overview" && (
            <TabPane tabId="Overview" className="mt-3">
              <ErrorBoundary>
                <Overview shipperId={shipperId} />
              </ErrorBoundary>
            </TabPane>
          )}
          {activeTab === "Profile" && (
            <TabPane tabId="Profile" className="mt-3">
              <ErrorBoundary message="Edit Customer">
                <CustomerForm
                  state={state}
                  dispatch={dispatch}
                  enums={enums}
                  errors={errors}
                  setCustomerDetailsOpen={setCustomerDetailsOpen}
                />
              </ErrorBoundary>
            </TabPane>
          )}
          {activeTab === "Activation" && (
            <TabPane tabId="Activation" className="mt-3">
              <ErrorBoundary message="Activation">
                <Activation
                  shipperDetail={state}
                  shipperId={shipperId}
                  getCustomers={getCustomers}
                />
              </ErrorBoundary>
            </TabPane>
          )}
          {activeTab === "Shipments" && (
            <TabPane tabId="Shipments" className="mt-3">
              <ErrorBoundary>
                <CustomerShipments shipperId={shipperId} />
              </ErrorBoundary>
            </TabPane>
          )}
          {activeTab === "Billing" && (
            <TabPane tabId="Billing" className="mt-3">
              <ErrorBoundary>
                <Billing url={`/shipper/${shipperId}/invoice`} />
              </ErrorBoundary>
            </TabPane>
          )}

          {activeTab === "Notes" && (
            <TabPane tabId="Notes">
              <ErrorBoundary>
                <Notes id={shipperId} />
              </ErrorBoundary>
            </TabPane>
          )}
          {activeTab === "FuelSearchCharge" && (
            <TabPane tabId="FuelSearchCharge">
              <ErrorBoundary>
                <FuelSearchCharge
                  id={shipperId}
                  errors={errors}
                  data={data}
                  setData={setData}
                  fscrefresh={fscrefresh}
                />
              </ErrorBoundary>
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default CustomerDetails;
