import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./non-sticky-slider.sass";
import "./slider-display.sass";
const SliderModal = ({
  children,
  clickState,
  setClickState,
  width,
  from,
  height,
  hasMaxWidth,
}) => {
  const WIDTH = width ? width + "vw" : "60vw";

  const sliderHeight =
    height === 30
      ? "slider-quarter"
      : null || height === 50
      ? "slider-half"
      : null || height === 75
      ? "slider-three-quarter"
      : null;
  return (
    <div>
      <SlidingPane
        className={`non-sticky-slider ${sliderHeight} ${
          hasMaxWidth ? "hasMaxWidth" : ""
        }`}
        isOpen={clickState}
        hideHeader={true}
        width={WIDTH}
        from={from}
        height={height}
        onRequestClose={() => {
          setClickState(false);
        }}
      >
        {children}
      </SlidingPane>
    </div>
  );
};

export default SliderModal;
