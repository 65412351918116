import CellBuilder from "components/cellBuilderTable/CellBuilder";

export const COLUMNS = [
  {
    name: "Customer Name",
    selector: (row) => row.company,
    cell: (row) => CellBuilder(row.company),
  },
  {
    name: "USDOT",
    selector: (row) => row.usdot,
    cell: (row) => CellBuilder(row.usdot),
    grow: 0.8,
  },
  {
    name: "MC Number",
    selector: (row) => row.mcNumber,
    cell: (row) => CellBuilder(row.mcNumber),
    grow: 0.8,
  },

  {
    name: "Mailing Address",
    selector: (row) => row.mailingAddress?.fullAddress,
    cell: (row) => CellBuilder(row.mailingAddress?.fullAddress),
    grow: 1,
  },

  {
    name: "Billing Address",
    selector: (row) => row.billingAddress?.fullAddress,
    cell: (row) => CellBuilder(row.billingAddress?.fullAddress),
    grow: 1,
  },

  {
    name: "Actions",
    selector: (row) => row.action,
    grow: 2,
    center: true,
  },
];
