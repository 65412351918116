import MilerButton from "components/milerButton/MilerButton";
import Searchcomponent from "./SearchComp";
import BorderedText from "components/bordered-text/BorderedText";
import getPartStatusColor from "views/maintenance/part/getPartStatusColor";

const RepairPartSearcher = ({
  onItemSelect,
  label,
  value,
  error,
  required,
  setNewPartOpen,
}) => {
  const onSelect = (item) => {
    onItemSelect(item);
  };

  const newPart = (item) => {
    return (
      <div className="advance-search__item container equipm-searcher">
        <div className="advance-search__item-title  row justify-content-between  align-items-center pb-2">
          <span className="mr-4 mb-1" style={{ fontStyle: "italic" }}>
            {item}
          </span>
          <MilerButton
            title="Select"
            btnDark
            xs
            onClick={() => setNewPartOpen(item)}
          />
        </div>
        <div className=" row pb-2">
          <BorderedText
            text={"Non-Inventory"}
            classes={getPartStatusColor("Non-Inventory") + " sm"}
          />
        </div>
      </div>
    );
  };
  const searchResultItem = (item) => {
    const {
      partNumber,
      category,
      partDescription,
      quantityAtHand,
      reOrderPoint,
    } = item || {};
    return (
      <div className="advance-search__item container equipm-searcher">
        <div className="advance-search__item-title  row justify-content-between  align-items-center pb-2">
          <span className="mr-4 mb-1">{partNumber || "N/A"}</span>
          <MilerButton
            title="Select"
            btnDark
            xs
            onClick={() => onSelect(item)}
          />
        </div>
        {quantityAtHand || quantityAtHand === 0 ? (
          <div className=" row pb-2">
            <BorderedText
              text={
                quantityAtHand <= 0
                  ? "Out of Stock"
                  : quantityAtHand >= (reOrderPoint || 0)
                  ? "In Stock"
                  : "Low In Stock"
              }
              classes={
                getPartStatusColor(
                  quantityAtHand <= 0
                    ? "OutStock"
                    : quantityAtHand >= (reOrderPoint || 0)
                    ? "InStock"
                    : "LowStock"
                ) + " sm"
              }
            />
          </div>
        ) : (
          <></>
        )}
        {partDescription && (
          <div className=" row">
            <section className="column">
              <label>Description: </label>
              <span>{partDescription || ""}</span>
            </section>
          </div>
        )}
        {category && (
          <div className=" row">
            <section className="column">
              <label>Categoty: </label>
              <span>{category || ""}</span>
            </section>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <Searchcomponent
        label={label}
        searchResultItem={searchResultItem}
        url={`/Part/Search?partNumber`}
        value={value}
        error={error}
        required={required}
        newPart={newPart}
      />
    </div>
  );
};
export default RepairPartSearcher;
