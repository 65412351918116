import BorderedText from "components/bordered-text/BorderedText";
import "./ExpandableContent.sass";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import DataTable from "react-data-table-component";
import { getCookie } from "utils/cookies";
import { digitsToCurrency } from "utils/currencyFormatter";
import dateFormater from "utils/dateFormater";
import joinStrings from "utils/joinStrings";

const ExpandedContent = ({
  recurringshipments,
  setShipId,
  setShipmentContent,
}) => {
  const { isMetric } = getCookie("carrierInfo");

  let shipments = recurringshipments.map((data) => {
    let distance = `${data?.distance} ${isMetric ? "km" : "mi"}`;
    let weight = `${data.weight} ${isMetric ? "kgs" : "lbs"}`;
    let pricePerMile = `${digitsToCurrency(
      data.distance && data.totalRate ? data.totalRate / data.distance : 0
    )} ${isMetric ? "/km" : "/mi"}`;
    return {
      id: data.shipmentId,
      shipmentNumber: data.shipmentNumber,
      isSplit: data.isSplit,
      shipmentId: data.shipmentId,
      isRecurring: data.isRecurring,
      isDedicatedLane: data.isDedicatedLane,
      shipperName: data.shipperName,
      trailerType: data.trailerType,
      shipmentMode: data.shipmentType,
      scheduledPickUpTime: dateFormater(
        data.scheduledPickupUTC,
        "MMM DD, HH:mm",
        true
      ),
      pickup: joinStrings(
        [data.pickupAddress?.city, data.pickupAddress?.state],
        ", "
      ),
      scheduledDeliveryTime: dateFormater(
        data.scheduledDeliveryUTC,
        "MMM DD, HH:mm",
        true
      ),
      dropoff: joinStrings(
        [data.deliveryAddress?.city, data.deliveryAddress?.state],
        ", "
      ),
      distance: distance,
      price: digitsToCurrency(data.totalRate),
      pricePerMile: pricePerMile,
      weight: weight,
      dropDownRecurring: <></>,
      eta: (
        <>
          <i
            className={`mil-clock-f eta-icon ${data.pickupDeliveryStatus}`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={data.pickupDeliveryStatus}
          ></i>
          {CellBuilder(null, dateFormater(data.eta, "MMM DD, HH:mm"))}
        </>
      ),
      status: <BorderedText text={data.status} classes={data.status} />,
      actions: (
        <div>
          <div className="table-actions">
            <i
              className="mil-edit-o"
              onClick={() => {
                setShipId(data.shipmentId);
                setShipmentContent(true);
              }}
              style={{ cursor: "pointer", fontSize: "21px" }}
            />
          </div>
        </div>
      ),
    };
  });

  const columns = [
    {
      name: "SHIPMENT #",
      selector: (row) => row.shipmentNumber,
      sortable: true,
      cell: (row) =>
        CellBuilder(
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.shipmentNumber}
            {row.isSplit && (
              <TbArrowsSplit2
                className="split-icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={"Split shipment"}
              />
            )}
            {row.isDedicatedLane && (
              <i
                className="mil-recurring-o"
                style={{
                  fontSize: "17px",
                  marginLeft: "4px",
                  color: row.isRecurring ? "#198BF2" : "#626365",
                  cursor: "pointer",
                }}
                onClick={() => row.makeShipmentRecurring(row)}
                title={
                  row.isRecurring
                    ? "Dedicated Revenue - Recurring"
                    : "Dedicated Revenue"
                }
              />
            )}
          </span>,
          row.carrierName
        ),
      grow: 7,
      sortFunction: () => {},
    },
    {
      name: "MODE",
      selector: (row) => row.shipmentMode,
      grow: 2,
      cell: (row) => CellBuilder(row.shipmentMode, row.trailerType),
    },
    {
      name: "PICKUP",
      selector: (row) => row.scheduledPickupUTC,
      sortable: true,
      grow: 7,
      cell: (row) => CellBuilder(row.pickup, row.scheduledPickUpTime),

      sortFunction: () => {},
    },
    {
      name: "DROPOFF",
      selector: (row) => row.scheduledDeliveryUTC,
      sortable: true,
      grow: 7,
      cell: (row) => CellBuilder(row.dropoff, row.scheduledDeliveryTime),

      sortFunction: () => {},
    },
    {
      name: "DISTANCE",
      selector: (row) => row.distance,
      cell: (row) => CellBuilder(row.distance, row.weight),
      grow: 2,
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
      cell: (row) => CellBuilder(row.price, row.priceRate, "blue"),
      grow: 2,
    },
    {
      name: "NEXT ETA",
      selector: (row) => row.nextScheduledUtc,
      sortable: true,
      cell: (row) =>
        CellBuilder(
          <section>
            {row.nextActivity} {row.nextStop}
            {row.nextStop && <>{row.eta}</>}
          </section>,
          <p>
            {row.nextEtaUtc && <>{row.nextEtaType}:</>} {row.nextEtaUtc}
          </p>
        ),
      grow: 10,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      grow: 2,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.actions,
      grow: 1,
    },
  ];

  return (
    <main className="recurring-charges_ismanifest_orders">
      <div className="summary-list">
        <DataTable
          columns={columns}
          data={shipments}
          noTableHead
          noHeader
          customStyles={{
            rows: {
              style: {
                background: "#E5E7EB",
              },
            },
          }}
        />
      </div>
    </main>
  );
};

export default ExpandedContent;
