import { useContext } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { Card } from "reactstrap";
import { appContext } from "services/AppContext";


const Documents = ({ state, dispatch }) => {
    const {
        enums: { DocumentTypeShipment },
      } = useContext(appContext);
    return (
        <div className="mt-3">
            <Card style={{ padding: "10px" }}>
                <AssociatedDocuments
                    documents={state?.documents || []}
                    delDoc={(doc) => { dispatch({ action: 'removeDocument', value: doc }) }}
                    addDoc={(doc) => { dispatch({ action: 'addDocument', value: doc }) }}
                    enums={DocumentTypeShipment}
                    documentCategory="Shipment"
                />
            </Card>
        </div>
    )
}

export default Documents;