import { useState, useEffect } from "react";

import InputMiler from "components/inputMiler/InputMiler";
import { getElements } from "services/serviceRepairApi";
import "./Maintenance.sass";
import findError from "utils/findError";

const RepairItemSearchComp = ({
  url,
  searchResultItem,
  notFoundItem,
  value,
  label,
  error,
  searchState,
  setSearch,
}) => {
  const [inputValue, setInputValue] = useState();
  const [inputValueTemp, setInputValueTemp] = useState("");

  let fetcher = (query) => {
    getElements(`${url}=${query}`).then((response) => {
      setSearch({
        ...searchState,
        result: response.data,
        searchDisplay: true,
        inputValue: query,
      });
    });
  };
  const changed = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setInputValueTemp(value);
    value && fetcher(value);
  };
  const clickAway = () => {
    setInputValue(value);
    setTimeout(() => {
      searchState.searchDisplay &&
        setSearch({ ...searchState, searchDisplay: false });
    }, 500);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="advanced-search" style={{ width: "100%" }}>
      <InputMiler
        label={label || "Repair Item"}
        onChange={changed}
        value={inputValue || ""}
        error={findError("ServiceCode", error)}
        onBlur={clickAway}
        required
        type="search"
      />
      {searchState.searchDisplay && (
        <div className="vehicle-search__result">
          {searchState.result && searchState.result.length
            ? searchState.result.map((item, index) =>
                searchResultItem(item, index)
              )
            : notFoundItem({
                id: "-1",
                code: inputValueTemp,
                categoryName: "New Item",
              })}
        </div>
      )}
    </div>
  );
};

export default RepairItemSearchComp;
