
import { Card } from "reactstrap";
import dateFormatter from "utils/dateFormater";
import "./RepairSummaryComponet.sass";
const RepairSummaryComponent = ({ state }) => {
  return (
    <>
      <Card className="repair_summary_component_section">
        <main>
          <section className="repair_summary_header">
            <div className="repair_summary_title">Repair Summary</div>
          </section>
          <section className="repair_summary_content">
            <section className="content_info">
              <section>
                <p className="content_title">Requested</p>
                <p>
                  {dateFormatter(state?.requestedUTC, "MMM DD, YYYY", true) ||
                    "-"}
                </p>
              </section>

              <section>
                <p className="content_title">Repair Order Opened</p>
                <p>
                  {" "}
                  {dateFormatter(state?.openedUTC, "MMM DD, YYYY", true) || "-"}
                </p>
              </section>
            </section>

            <section className="content_info">
              <section className="content_info">
                <p className="content_title">Promised</p>
                <p>
                  {dateFormatter(state?.promisedUTC, "MMM DD, YYYY", true) ||
                    "-"}
                </p>
              </section>

              <section className="content_info">
                <p className="content_title">Repair Order Closed</p>
                <p>
                  {dateFormatter(state?.closedUTC, "MMM DD, YYYY", true) || "-"}
                </p>
              </section>
            </section>
          </section>
        </main>
      </Card>
    </>
  );
};

export default RepairSummaryComponent;
