import { Steps } from "antd";
import "./stepper.sass";

const Stepper = ({ items, currentStep, onChange }) => (
  <section className="steps">
    <Steps
      items={items}
      current={currentStep}
      size="small"
      onChange={onChange}
    />
  </section>
);

export default Stepper;
