import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import BorderedText from "components/bordered-text/BorderedText";
import MilerButton from "components/milerButton/MilerButton";
import "./TripDisplay.sass";
import { digitsToCurrency } from "utils/currencyFormatter";
import ETADisplay from "./etaDisplay/ETADisplay";
import { hasPermission } from "utils/hasPermission";
export default function TripHeader({
  currentTrip,
  handleUnassign,
  openDetails,
  setCheckCallSliderOpen,
  setAssignDriversOpen,
  acceptTrip,
  completeTrip,
  setStartTrip,
  setSpiltTripOpen,
  undoTrip,
  setDeliverTripOpen,
  setAddTransactionOpen,
  checkStopProgress,
  hold,
  voidTrip,
  cancelTrip,
  closeTripSlider,
  setTracking,
  tracking,
  setOpenCharge,
}) {
  const { onHold } = currentTrip?.shipmentResponse || {};

  const calRevenueRatio = () => {
    let chargesCost = currentTrip.shipmentResponse.charges.reduce(
      (total, charge) => {
        let amount = 0;
        charge.chargeType === "Line Haul"
          ? (amount = charge.subTotal)
          : (amount = 0);
        return total + amount;
      },
      0
    );
    if (
      currentTrip.revenue &&
      chargesCost &&
      currentTrip.shipmentResponse.isSplit
    ) {
      return `(${Math.round((currentTrip.revenue * 100) / chargesCost)}%)`;
    } else {
      return ``;
    }
  };
  return (
    <div className="header">
      <div className="description">
        <div className="first">
          <div className="title">Trip #{currentTrip.tripId}</div>
          <BorderedText
            text={currentTrip.tripStatus}
            classes={currentTrip.tripStatus}
          />
        </div>
        {currentTrip.shipmentResponse && (
          <div className="second">
            {`${currentTrip.shipmentResponse?.shipperName} | ${currentTrip.shipmentResponse?.shipmentType}`}
            {currentTrip.shipmentResponse?.weight !== 0 ||
              (undefined &&
                ` | ${currentTrip.shipmentResponse.weight} ${
                  currentTrip.shipmentResponse.unitWeight === "Pounds"
                    ? "lb"
                    : "kg"
                }`)}
            {currentTrip.shipmentResponse.totalCharges !== 0 &&
              ` | ${digitsToCurrency(
                currentTrip.revenue
              )}  ${calRevenueRatio()}`}
          </div>
        )}
        {currentTrip.tripStatus === "Inprogress" && (
          <ETADisplay currentTrip={currentTrip} />
        )}
      </div>
      <div className="ac_actions">
        {currentTrip.tripStatus === "Pending Assignment" ? (
          <MilerButton title="Accept" btnDark onClick={acceptTrip} />
        ) : currentTrip.tripStatus === "Delivered" ? (
          <MilerButton
            title="Complete"
            btnDark
            onClick={completeTrip}
            disabled={onHold}
            tooltip={onHold ? "On Hold" : null}
            targetActionDenied={!hasPermission("complete.shipment.trip")}
          />
        ) : currentTrip.tripStatus === "Available" ? (
          <MilerButton
            title="Assign Vehicle"
            btnDark
            onClick={() => {
              setAssignDriversOpen(true);
            }}
          />
        ) : currentTrip.tripStatus === "Inprogress" ? (
          currentTrip.stops[currentTrip.stops.length - 1].actualDeparture ? (
            <MilerButton
              title="Deliver Trip"
              btnDark
              onClick={() => setDeliverTripOpen(true)}
            />
          ) : !tracking ? (
            <MilerButton
              title="Track"
              btnDark
              onClick={() => setTracking(true)}
            />
          ) : (
            <MilerButton
              title="Check Calls"
              btnDark
              onClick={() => setCheckCallSliderOpen(true)}
            />
          )
        ) : currentTrip.tripStatus === "Assigned" ? (
          <MilerButton
            title="Start Trip"
            onClick={() => setStartTrip(true)}
            btnDark
          />
        ) : (
          <></>
        )}

        <ElipsisMenu>
          {currentTrip.tripStatus === "Available" ? (
            /* ----Available---- */
            <>
              <MenuItem title="Edit/View" onClick={openDetails} />
              <MenuItem
                title="Split"
                onClick={() => {
                  setSpiltTripOpen(true);
                }}
              />
              <MenuItem title="Void" onClick={voidTrip} />
              {onHold ? (
                <MenuItem title="Remove Hold" onClick={hold} />
              ) : (
                <MenuItem title="Hold" onClick={hold} />
              )}
              <MenuItem title="Lock" />
              <MenuItem title="Open Issue" />
            </>
          ) : currentTrip.tripStatus === "Assigned" ? (
            /* ----Assigned---- */
            <>
              {currentTrip.tripBasis === "Empty" ? (
                <MenuItem title="Void" onClick={cancelTrip} />
              ) : (
                <>
                  <MenuItem title="Unassign" onClick={handleUnassign} />
                  <MenuItem title="Edit/View" onClick={openDetails} />
                  <MenuItem
                    title="Split"
                    onClick={() => {
                      setSpiltTripOpen(true);
                    }}
                  />
                  <MenuItem title="Open Issue" />
                  {onHold ? (
                    <MenuItem title="Remove Hold" onClick={hold} />
                  ) : (
                    <MenuItem title="Hold" onClick={hold} />
                  )}
                  <MenuItem title="Undo" onClick={undoTrip} />
                </>
              )}
            </>
          ) : currentTrip.tripStatus === "Inprogress" ? (
            /* ----Inprogress---- */

            <>
              {(currentTrip.stops[currentTrip.stops.length - 1]
                .actualDeparture ||
                !tracking) && (
                <MenuItem
                  title="Check Call"
                  onClick={() => setCheckCallSliderOpen(true)}
                />
              )}
              {currentTrip.tripBasis === "Empty" ? (
                <MenuItem title="Void" onClick={cancelTrip} />
              ) : (
                <>
                  <MenuItem title="Edit/View" onClick={openDetails} />
                  <MenuItem
                    title="Split"
                    onClick={() => {
                      setSpiltTripOpen(true);
                    }}
                  />
                  <MenuItem title="Open Issue" />

                  {onHold ? (
                    <MenuItem title="Remove Hold" onClick={hold} />
                  ) : (
                    <MenuItem title="Hold" onClick={hold} />
                  )}
                  <MenuItem title="Undo" onClick={undoTrip} />
                </>
              )}
              <MenuItem
                title="Add Transaction"
                onClick={() => setAddTransactionOpen(true)}
              />
              {!onHold && (
                <MenuItem
                  title="Complete Trip"
                  onClick={checkStopProgress}
                  targetActionDenied={!hasPermission("complete.shipment.trip")}
                />
              )}
              {currentTrip.tripBasis === "Loaded" && (
                <MenuItem
                  title="Mark TONU"
                  targetActionDenied={!hasPermission("complete.shipment.trip")}
                  onClick={() => {
                    setOpenCharge(true);
                  }}
                />
              )}
            </>
          ) : currentTrip.tripStatus === "Delivered" ||
            currentTrip.tripStatus === "Hold" ? (
            /* ----Delivered | Hold---- */
            <>
              {currentTrip.tripBasis === "Empty" ? (
                <MenuItem title="Void" onClick={cancelTrip} />
              ) : (
                <>
                  <MenuItem title="Edit/View" onClick={openDetails} />

                  <MenuItem title="Open Issue" />
                  <MenuItem title="Undo" onClick={undoTrip} />
                </>
              )}
              <MenuItem
                title="Check Call"
                onClick={() => setCheckCallSliderOpen(true)}
              />

              {onHold ? (
                <MenuItem title="Remove Hold" onClick={hold} />
              ) : (
                <MenuItem title="Hold" onClick={hold} />
              )}

              <MenuItem
                title="Add Transaction"
                onClick={() => setAddTransactionOpen(true)}
              />
            </>
          ) : currentTrip.tripStatus === "Completed" ? (
            /* ----Completed---- */
            <>
              <MenuItem title="Edit/View" onClick={openDetails} />
              <MenuItem title="Open Issue" />
              <MenuItem
                title="Check Call"
                onClick={() => setCheckCallSliderOpen(true)}
              />
              <MenuItem title="hold" onClick={hold} />
              <MenuItem title="Add Transaction" />
            </>
          ) : (
            <>
              <MenuItem title="Unassign" onClick={handleUnassign} />
              <MenuItem title="Edit/View" onClick={openDetails} />
              <MenuItem
                title="Split"
                onClick={() => {
                  setSpiltTripOpen(true);
                }}
              />
              <MenuItem title="Undo" onClick={undoTrip} />
            </>
          )}
        </ElipsisMenu>
        {closeTripSlider && (
          <i
            className="mil-close-bg clickable close-icon"
            onClick={() => closeTripSlider(false)}
          ></i>
        )}
      </div>
    </div>
  );
}
