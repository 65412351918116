import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import VehicleForm from "../vehicleForm/VehicleForm";
import { postElement } from "../../../../services/milerApi";

import "./NewVehicle.sass";
import { hasPermission } from "utils/hasPermission";

const VehicleDetails = ({ setNewVehicle, getVehicles }) => {
  const [state, setState] = useState({
    isUtilityVehicle: false,
  });

  const [fleetOversizedMessage, setFleetOversizedMessage] = useState("");

  const [errors, setFormErrors] = useState([]);
  const [yearError, setYearError] = useState(null);
  const [addingVehicle, setAddingVehicle] = useState(false);

  const createVehicle = () => {
    setAddingVehicle(true);
    postElement(
      "/Equipment/Vehicle",
      state.ownership === "Owner Operator Vehicle" ||
        state.ownership === "Leased Vehicle"
        ? {
            ...state,
            accountID: state.accountId,
            unitNumber: state?.unitNumber?.trim(),
          }
        : {
            ...state,
            unitNumber: state?.unitNumber?.trim(),
            accountID: null,
            accountId: null,
          }
    ).then((response) => {
      setAddingVehicle(false);
      if (response.success) {
        getVehicles();
        setNewVehicle(false);
      } else if (response.responseStatus === 409) {
        setFleetOversizedMessage(response.vErrors);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  return (
    state && (
      <div className="slider-display new-vehicle">
        {fleetOversizedMessage && (
          <>
            <div className="aa"></div>
            <div className="vehicle-warning-comp-cont">
              <div className="vehicle-warning-comp">
                <p>{fleetOversizedMessage}</p>
                <MilerButton
                  sm
                  title="Cancel"
                  onClick={() => setFleetOversizedMessage("")}
                ></MilerButton>
                <span className="m-1"></span>
                <MilerButton
                  sm
                  btnDark
                  title={
                    <a href="https://subscription.miler.com/portal/milerapp">
                      Add Fleet Size
                    </a>
                  }
                ></MilerButton>
              </div>
            </div>
          </>
        )}
        <div className="slider-display-header">
          <div className="slider-header-title">New Vehicle</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="cancel"
              onClick={() => {
                localStorage.removeItem("draftVehicle");
                setNewVehicle(false);
              }}
            />

            <MilerButton
              btnDark
              disabled={yearError || addingVehicle}
              title={addingVehicle ? "Saving..." : "Save"}
              onClick={() => createVehicle()}
              targetActionDenied={!hasPermission("create.fleet")}
            />
          </div>
        </div>
        <div className="slider-display-cont">
          <VehicleForm
            setState={setState}
            setYearError={setYearError}
            yearError={yearError}
            state={state}
            errors={errors}
          />
        </div>
      </div>
    )
  );
};

export default VehicleDetails;
