import TripLine from "../TripLine";
import { GoogleMap } from "@react-google-maps/api";
import { mapStyles } from "constants/mapStyles";
import { useRef, useState } from "react";
import { postElement, putElement, deleteElement } from "services/milerApi";
import html2canvas from "html2canvas";
import { FiRefreshCw } from "react-icons/fi";
import { Tooltip } from "antd";
import "./SnapshotMap.sass";
export default function SnapshotMap({
  tripStops,
  tripId,
  shipmentId,
  tripMapSnapshot,
  getCurrentTrip,
  showRefreshIcon = false,
}) {
  const mapRef = useRef();

  const [snapshotUrl, setSnapShotUrl] = useState(tripMapSnapshot);
  const [loadSpiner, setLoadingSpinner] = useState(false);

  const deleteMapSnapShot = () => {
    deleteElement(`/Shipment/${shipmentId}/Snapshot`, {}, "", {
      ignoreToast: true,
    }).then(() => {
      getCurrentTrip();
    });
  };
  const getImage = async () => {
    if (mapRef.current === null || tripMapSnapshot) {
      return;
    }

    let savingUrl = shipmentId
      ? `/Shipment/${shipmentId}/Snapshot`
      : tripId
      ? `/Shipment/Trip/${tripId}/Snapshot`
      : "";
    const canvas = await html2canvas(mapRef.current, {
      useCORS: true,
    });
    const dataUrl = canvas.toDataURL("image/png", 1.0);

    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    const timeStamp = +new Date();

    const imageFile = new File(
      [u8arr],
      `trip-${tripId}-map-snapshot-${timeStamp}.png`,
      { type: mime }
    );

    const fileFormData = new FormData();
    fileFormData.append("Document", imageFile);

    const savedSnapshot = await postElement(
      `/Document?DocumentCategory=Company`,
      fileFormData,
      "",
      {
        ignoreToast: true,
      }
    );

    await putElement(
      savingUrl + `?snapshot_url=https://${savedSnapshot.response.data}`,
      {},
      "",
      { ignoreToast: true }
    ).then(() => {
      setSnapShotUrl(`https://${savedSnapshot.response.data}`);
    });
  };

  return (
    <div ref={mapRef} style={{ height: "200px" }}>
      {snapshotUrl ? (
        <section className="map_snapshot_info_section">
          {showRefreshIcon ? (
            <Tooltip placement="bottom" title="Refresh Map">
              <FiRefreshCw
                className="map_snapshot_refresh_icon"
                style={{
                  animation: loadSpiner && "spin 1s linear",
                  cursor: "pointer",
                }}
                fontSize="15"
                onClick={() => {
                  setLoadingSpinner(true);
                  deleteMapSnapShot();
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}

          <img style={{ height: "200px", width: "100%" }} src={snapshotUrl} />
        </section>
      ) : (
        <GoogleMap
          mapContainerClassName="map"
          zoom={4}
          // center={{
          //   lat: 41.666574490894995,
          //   lng: -93.32836457326965,
          // }}
          mapContainerStyle={{ height: "200px" }}
          options={{
            styles: mapStyles,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
          }}
        >
          <TripLine
            onLoad={() => {
              setTimeout(() => {
                if (tripId || shipmentId) {
                  getImage();
                }
              }, 2500);
            }}
            tripStops={tripStops}
          />
        </GoogleMap>
      )}
    </div>
  );
}
