import SliderModal from "components/sliderModal/SliderModal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteElement, putElement } from "services/serviceRepairApi";
import EditRepairItem from "./newRepairItem/EditRepairItem";
import "./RepairItems.sass";
import { digitsToCurrency } from "utils/currencyFormatter";

const RepairItems = ({
  repairItems,
  setRepairItems,
  fromDetails,
  editRepairOpen,
  setEditRepairOpen,
  getRepairDetails,
  repairOrder,
  repId,
}) => {
  const [selected, setSelected] = useState(-1);
  const [repairIndex, setRepairIndex] = useState(-1);

  const deleteRepair = (i) => {
    const modRepair = repairItems.filter((r, index) => index !== i);
    setRepairItems(modRepair);
  };
  const deleteFromRepair = (id, item) => {
    if (repairItems.length === 1) {
      toast.error(
        "At least one repair item is needed. Add another repair item to delete " +
          item.serviceCode
      );
      return;
    }

    putElement(`/RepairOrder/${repId}`, repairOrder, "", {
      ignoreToast: true,
    }).then((response) => {
      if (response.success) {
        deleteElement(`/RepairOrder/RepairItem/${id}`).then(() => {
          getRepairDetails();
        });
      }
    });
  };
  useEffect(() => {});
  return (
    <div className="service_repair_repair_items_container">
      {repairItems.length === 0 ? (
        <div className="service_repair_form_no_repair_item">No</div>
      ) : (
        <>
          <div className="header">
            <div className="exp_itm"></div>
            <div className="title">Item Title</div>
            <div className="labour">Labor</div>
            <div className="parts">Parts</div>
            <div className="total">Total</div>
            <div className="action">Action</div>
          </div>
          <div className="body">
            {repairItems.map((item, index) => (
              <>
                <div className={index % 2 === 0 ? "cell" : "cell_inv"}>
                  <div className="exp_itm">
                    {selected === index ? (
                      <i
                        className="mil-chevron-bg-up"
                        onClick={() => setSelected(-1)}
                      />
                    ) : (
                      <i
                        className="mil-chevron-bg-down"
                        onClick={() => setSelected(index)}
                      />
                    )}
                  </div>
                  <div className="title">{item.serviceCode}</div>
                  <div className="labour">
                    {digitsToCurrency(item.laborPrice)}
                  </div>
                  <div className="parts">
                    {digitsToCurrency(item.partsPrice)}
                  </div>
                  <div className="total">
                    {digitsToCurrency(item.totalCharges)}
                  </div>
                  <div className="action">
                    <i
                      className="mil-edit"
                      style={{ cursor: "pointer", marginRight: "15px" }}
                      onClick={() => {
                        setRepairIndex(index);
                        setEditRepairOpen(true);
                      }}
                    />
                    <i
                      className="mil-delete-o"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fromDetails
                          ? deleteFromRepair(item.repairItemId, item)
                          : deleteRepair(index);
                      }}
                    />
                  </div>
                </div>
                {selected === index && (
                  <div className="cell_desc">
                    <div className="head">{item.serviceCode}</div>
                    <div className="labour">
                      <div className="title">Labor</div>
                      <div className="tabl">
                        <div className="head">
                          <div className="hour">Hours</div>
                          <div className="rate">Rate</div>
                          <div className="total">Total</div>
                        </div>
                        <div className="bdy">
                          <div className="cell">
                            <div className="hour">
                              {item?.repairLineItems[0]?.unit}
                            </div>
                            <div className="rate">
                              {digitsToCurrency(
                                item.repairLineItems[0]?.rate || 0
                              )}
                            </div>
                            <div className="tot">
                              {digitsToCurrency(
                                item.repairLineItems[0]?.subtotal
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="parts">
                      <div className="title">Parts</div>
                      <div className="tabl">
                        <div className="head">
                          <div className="no">Part #</div>
                          <div className="part">Part</div>
                          <div className="quantity">Quantity</div>
                          <div className="rate">Rate</div>
                          <div className="tot">Total</div>
                        </div>
                        <div className="bdy">
                          {item.repairLineItems.map((rep, i) => {
                            return (
                              i !== 0 && (
                                <div className="cell">
                                  <div className="no">{rep?.partNumber}</div>
                                  <div className="part">
                                    {rep.partDescription}
                                  </div>
                                  <div className="quantity">{rep?.unit}</div>
                                  <div className="rate">
                                    {digitsToCurrency(rep?.rate)}
                                  </div>
                                  <div className="tot">
                                    {digitsToCurrency(rep?.subtotal)}
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="summary">
            <div className="first">Summary</div>
            <div className="sum_itm">
              <div className="value">
                {digitsToCurrency(
                  repairItems.reduce(
                    (acc, current) => acc + current.laborPrice,
                    0
                  )
                )}
              </div>
            </div>
            <div className="sum_itm">
              <div className="value">
                {digitsToCurrency(
                  repairItems.reduce(
                    (acc, current) => acc + current.partsPrice,
                    0
                  )
                )}
              </div>
            </div>
            <div className="sum_itm">
              <div className="value">
                {digitsToCurrency(
                  repairItems.reduce(
                    (acc, current) => acc + current.totalCharges,
                    0
                  )
                )}
              </div>
            </div>
            <div className="action"></div>
          </div>
        </>
      )}
      <SliderModal
        clickState={editRepairOpen}
        setClickState={() => {}}
        from="bottom"
      >
        <EditRepairItem
          setEditRepairOpen={setEditRepairOpen}
          repairItems={repairItems}
          setRepairItems={setRepairItems}
          repairIndex={repairIndex}
          fromDetails={fromDetails}
          editRepairOpen={editRepairOpen}
          getRepairDetails={getRepairDetails}
          repairOrder={repairOrder}
          repairOrderId={repId}
        />
      </SliderModal>
    </div>
  );
};

export default RepairItems;
