import { getCookie } from "./cookies";

export default async function calculateStopsDistance(
  stops,
  callback,
  combine = () => {}
) {
  let addressStrings = [];
  let _stops = [...stops];
  _stops
    .sort((a, b) => a.stopSequence - b.stopSequence)
    .forEach((stop) => {
      addressStrings.push(
        `${stop.address.addressLine} ${stop.address.city} ${stop.address.state} ${stop.address.countryCode}`
      );
    });
  const service = new window.google.maps.DistanceMatrixService();
  const { isMetric } = getCookie("carrierInfo");
  return service.getDistanceMatrix(
    {
      origins: addressStrings.slice(0, -1),
      destinations: addressStrings.slice(1),
      travelMode: "DRIVING",
      unitSystem: isMetric
        ? window.google.maps.UnitSystem.METRIC
        : window.google.maps.UnitSystem.IMPERIAL,
    },
    (response, status) => {
      if (status === "OK") {
        let totalDistance = 0;
        let eta;
        response.rows.forEach((row, index) => {
          let distanceInfo = row.elements[index];
          if (distanceInfo.status === "OK") {
            totalDistance += isMetric
              ? Math.round(distanceInfo.distance.value / 1000)
              : Math.round(distanceInfo.distance.value / 1609);
            eta = distanceInfo.duration.value;
          } else {
            // No Distance Returned
          }
        });

        //pass the value of distance to the dispatch
        eta && combine(eta, totalDistance);
        totalDistance && callback(totalDistance);
        return eta;
      }
    }
  );
}
