import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import { hasPermission } from "utils/hasPermission";

const SkipStopsFlow = ({ open, closeModal, skipStopsProgress }) => {
  const onSubmit = () => {
    closeModal(false);
    skipStopsProgress();
  };
  return (
    <Modal isOpen={Boolean(open)} toggle={() => closeModal(false)}>
      <ModalHeader toggle={() => closeModal(false)}>Complete Trip</ModalHeader>
      <ModalBody>
        <div>
          <p>
            You are attempting to complete a trip while at least one stop is
            pending. If you proceed, all pending pickups or deliveries
            will be completed as though they were completed as scheduled. For
            accurate pick/delivery time, we recommend using the stops workflow.
          </p>
          <p>
            Do you still wish to complete the trip with all its pending pickups or deliveries?
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <MilerButton title="Yes" btnDark onClick={onSubmit} targetActionDenied={!hasPermission("complete.shipment.trip")}/>
        <MilerButton title="No" onClick={() => closeModal(false)} />
      </ModalFooter>
    </Modal>
  );
};

export default SkipStopsFlow;
