import MilerButton from "components/milerButton/MilerButton";
import InputMiler from "components/inputMiler/InputMiler";
import { getGatewayApi } from "services/gatewayApis";
import { postElement } from "services/milerApi";
import { useEffect, useState } from "react";

const NewFreightGroup = ({
  freight,
  setOpenFreightGroup,
  getFreightGroups,
}) => {
  const [freightManager, setFreightManager] = useState([]);
  const [addNewFreight, setNewFreight] = useState({
    territoryName: freight?.territoryName || "",
    territoryManagerUserId: "",
  });
  const getFreightManager = () => {
    getGatewayApi("carrier/admin/user/").then((r) => {
      let usersList = r.data.map((user) => ({
        keyName: user.FirstName + " " + user.LastName,
        keyValue: user.userId,
      }));

      setFreightManager(usersList);
    });
  };

  const addFreight = async () => {
    return postElement(
      `/Carrier/Terriroty?group_name=${addNewFreight.territoryName}&freight_group_manager_user_id=${addNewFreight?.territoryManagerUserId}`
    ).then((response) => {
      if (response.success) {
        getFreightGroups();
        setOpenFreightGroup(false);
      } else if (response.vErrors) {
        setOpenForm(true);
        setFormErrors(response.vErrors);
      }
    });
  };

  useEffect(() => {
    getFreightManager();
  }, []);

  const onChnage = (event) => {
    const { name, value } = event.target;
    setNewFreight((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Add Territory</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="cancel"
            onClick={() => setOpenFreightGroup(false)}
          />

          <MilerButton btnDark title="Save" onClick={addFreight} />
        </div>
      </div>
      <div className="slider-display-body">
        <div className="miler-card mt-0">
          <div className="miler-card-title">General</div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                value={addNewFreight?.territoryName}
                type="text"
                name="territoryName"
                label="Territory Name"
                required
                onChange={onChnage}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                value={addNewFreight?.territoryManagerUserId}
                type="select2"
                options={freightManager}
                name="territoryManagerUserId"
                label="Territory Manager"
                required
                onChange={onChnage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFreightGroup;
