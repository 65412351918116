import InputMiler from "components/inputMiler/InputMiler";
import { useContext } from "react";
import { Col, Row } from "reactstrap";
import { appContext } from "services/AppContext";

const ServiceFailureCard = ({
  reasonValue,
  reasonOnchange,
  noteValue,
  noteOnchange,
  disabled,
}) => {
  const { enums } = useContext(appContext);

  return (
    <div className="miler-card">
      <div className="miler-card-title">Service Failure</div>
      <Row>
        <Col lg="12">
          <InputMiler
            type="select"
            label="Service Failure Reason"
            name="promiseDelayReason"
            value={reasonValue}
            onChange={reasonOnchange}
            options={enums.PromiseDelayReason}
            error
            required
            disabled={disabled}
          />
        </Col>
        <Col lg="12">
          <InputMiler
            type="textarea"
            label="Service Failure Note"
            name="ServiceFailure"
            value={noteValue}
            onChange={noteOnchange}
            disabled={disabled}
            error
          />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceFailureCard;
