import getStatusColor from "utils/getStatusColor";
const getPartStatusColor = (status) => {
  return getStatusColor(
    ["InStock", "OutStock", "LowStock", "Non-Inventory"],
    [
      "status_green1",
      "status_red",
      "status_dark_turquoise",
      "status_dark_violet",
    ],
    status
  );
};

export default getPartStatusColor;
