import DataTable from "react-data-table-component";
import "./CustomerRating.sass";
import { digitsToCurrency } from "utils/currencyFormatter";

const CustomerRatingTable = ({
  customerRatingData,
  selectedRows,
  setSelectedRows,
  loading,
}) => {
  const handleCheckboxChange = (row) => {
    const isRowSelected = selectedRows.some(
      (selected) => selected.chargeId === row.chargeId
    );

    if (isRowSelected) {
      setSelectedRows(
        selectedRows.filter((selected) => selected.chargeId !== row.chargeId)
      );
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const columns = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRows.some(
            (selected) => selected.chargeId === row.chargeId
          )}
          onChange={() => handleCheckboxChange(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { name: "Charge Type", selector: (row) => row.chargeType, grow: 2 },
    { name: "Rating", selector: (row) => row.rating },
    { name: "Unit", selector: (row) => row.unit },
    { name: "Rate", selector: (row) => digitsToCurrency(row.rate) },
    { name: "SubTotal", selector: (row) => digitsToCurrency(row.subTotal) },
  ];

  return (
    <section
      style={{
        padding: "10px",
      }}
      className="customer_rating_table"
    >
      {loading ? (
        loading
      ) : (
        <DataTable columns={columns} data={customerRatingData} />
      )}
    </section>
  );
};

export default CustomerRatingTable;
