import { useState, useContext, useEffect } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "components/phoneInputFieldStyle/PhoneInput.sass";
import CountryRegionInput from "components/country-region-input/CountryRegionInput";
import classNames from "classnames";
import "./DriverForm.sass";
import findError from "utils/findError";
import joinStrings from "utils/joinStrings";
import PayeeSelector from "components/payee-form/PayeeSelector";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import MilerButton from "components/milerButton/MilerButton";
import { hasPermission } from "utils/hasPermission";
import { getElementsV2 } from "services/milerApi";
import Loading from "components/loading/Loading";
import { getGatewayApi, postGatewayApi } from "services/gatewayApis";
import DataTable from "react-data-table-component";
import { columns } from "./ManageLogin";
import FailedToFetch from "components/loading/FailedToFetch";
import ResetSuccessDialog from "views/settings/userManagment/userDetails/ResetSuccess";

const DriverForm = ({ state, dispatch, errors, fromEdit }) => {
  const { enums } = useContext(appContext);
  const [isPasswordReset, setResetPassword] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(Loading);
  const [terminationReasons, setTerminationReasons] = useState([]);
  const [userLogs, setUserLogs] = useState([]);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [successLoader, setSuccessLoader] = useState(Loading);

  const getUsers = () => {
    getGatewayApi(
      `carrier/admin/identity?username=${encodeURIComponent(state.userName)}`
    )
      .then((response) => {
        setUserLogs([response.data]);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setLoading(<FailedToFetch message={error?.response?.data} />);
        } else {
          setLoading(<FailedToFetch />);
        }
      });
  };
  const reInvite = () => {
    postGatewayApi(
      `carrier/admin/identity/reinvite?username=${encodeURIComponent(
        state.userName
      )}`
    ).then(() => {});
  };
  const resetPassword = () => {
    setResetSuccess(true);
    setSuccessLoader(Loading);
    postGatewayApi(
      `carrier/admin/identity/reset?username=${encodeURIComponent(
        state.userName
      )}`
    )
      .then((response) => {
        if (response.success) {
          setResetSuccess(response.response.data);
          setSuccessLoader(false);
        } else {
          setSuccessLoader(<FailedToFetch />);
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          setSuccessLoader(<FailedToFetch message={error?.response?.data} />);
        } else {
          setSuccessLoader(<FailedToFetch />);
        }
      });
  };

  const getTerminationReasons = (terminationType) => {
    let terminationTypeId = enums.TerminationType.find((type) => {
      return type.keyName === terminationType;
    })?.keyValue;
    if (terminationTypeId) {
      getElementsV2({
        url: `/Driver/Termination/${terminationTypeId}`,
        setLoading: null,
      }).then((response) => {
        if (response.status === 200) {
          let reasonsModified = response.data.map((reason) => ({
            keyValue: reason.terminationReasonId,
            keyName: reason.terminationReason,
          }));
          setTerminationReasons(reasonsModified);
        }
      });
    }
  };

  const handlePasswordReset = () => {
    setResetPassword(true);
  };

  useEffect(() => {
    if (state.terminationType) {
      getTerminationReasons(state.terminationType);
    }
  }, []);

  const countryCode =
    (state?.address?.countryCode?.toLowerCase() === "us" && "US") ||
    (state?.address?.countryCode?.toLowerCase() === "ca" && "CA");
  const [countryOnView, setCountryOnView] = useState(countryCode || "");

  let countryForState = "";

  function handleCountryConversion(e) {
    e.target.value === "US"
      ? (countryForState = "us")
      : (countryForState = "ca");
    let countryMod = e.target.value || "US";
    setCountryOnView(countryMod);
    dispatch({
      type: "countryCode",
      category: "address",
      value: countryForState,
    });
  }

  return (
    <div className="slider-display-body">
      <div className="miler-card mt-0">
        <div className="miler-card-title">Driver Details</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.firstName}
              type="text"
              onChange={(e) =>
                dispatch({ type: "firstName", value: e.target.value })
              }
              name="firstName"
              label="First Name"
              required
              error={findError("FirstName", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.middleName}
              type="text"
              onChange={(e) =>
                dispatch({ type: "middleName", value: e.target.value })
              }
              name="middleName"
              label="Middle Name"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.lastName}
              type="text"
              onChange={(e) =>
                dispatch({ type: "lastName", value: e.target.value })
              }
              name="lastName"
              label="Last Name"
              error={findError("LastName", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.driverNumber}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "driverNumber",
                  value: e.target.value,
                })
              }
              name="driverNumber"
              label="Driver ID"
              required
              error={findError("DriverNumber", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.driverType}
              type="select"
              onChange={(e) =>
                dispatch({ type: "driverType", value: e.target.value })
              }
              name="driverType"
              required={true}
              options={enums.DriverType}
              label="Driver Type"
              error={findError("DriverType", errors)}
            />
          </div>
          {state.driverType === "Owner Operator Driver" && (
            <div className="col-12 col-lg-6 col-xl-4">
              <PayeeSelector
                newName={joinStrings([state?.firstName, state?.lastName], " ")}
                selectedPayee={state.payee}
                driverState={state}
                onSelect={(payee) =>
                  dispatch({
                    category: "payee",
                    type: "accountId",
                    value: payee?.accountId,
                  })
                }
                driveName={`${state?.firstName} ${state?.lastName}`}
                error={findError("AccountId", errors)}
              />
            </div>
          )}

          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.email}
              type="text"
              onChange={(e) => {
                dispatch({ type: "email", value: e.target.value });
              }}
              name="email"
              label="Email"
              error={findError("Email", errors)}
              required
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-4">
            <label className="phone-input-label" htmlFor="phone">
              Phone Number<span className="required-red">*</span>
            </label>
            <PhoneInput
              className={classNames("phone-input", {
                "phone-error": findError("Phone", errors),
              })}
              defaultCountry="US"
              international
              value={state.phone}
              placeholder="Enter phone number"
              onChange={(value) => {
                dispatch({ type: "phone", value });
              }}
              name="phone"
            />
            <span className="phone-error-message">
              {findError("Phone", errors)}
            </span>
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <DateTimeInput
              type="date"
              value={state.twicExpiration}
              onChange={(value) =>
                dispatch({
                  type: "twicExpiration",
                  value,
                })
              }
              name="twicExpiration"
              label="TWIC Expiration"
            />
          </div>
        </div>
      </div>

      <div className="miler-card">
        <div className="miler-card-title">Miler Driver Mobile Access</div>
        <div className=" row d-flex justify-content-between ">
          <div
            className={` col-12  row  ${
              state.driverAccess === true
                ? "driver-access-selected"
                : "miler-driver-card-access"
            }`}
          >
            <input
              type="checkbox"
              checked={state.driverAccess === true}
              name="allowMilerAccess"
              value="Allow"
              style={{
                width: "17px",
                height: "17px",
                marginRight: "8px",

                marginBottom: "5px",
              }}
              onChange={() => {
                dispatch({
                  type: "driverAccess",
                  value: !state.driverAccess,
                });
              }}
            />
            <p className="user-form-paragraph-title">
              Enable Access to Miler Driver
            </p>
            <div className="driver-access-info">
              This user will be able to login in to your Miler driver with the
              email address provided above. An email invitation with
              instructions to create a password will be sent immediately.
            </div>
          </div>
          <div
            className={` col-12  row  ${
              state.driverAccess === true
                ? "driver-access-selected"
                : "miler-driver-card-access"
            }`}
          >
            {state?.userName && (
              <div className="col-12 col-lg-12 col-xl-12 mb-3 d-flex justify-content-end">
                <MilerButton
                  title="Manage LogIn"
                  targetActionDenied={!hasPermission("reactivate.user")}
                  btnDark
                  onClick={() => {
                    setShowForm(true);
                    getUsers();
                  }}
                />
              </div>
            )}
            {showForm &&
              state?.userName &&
              (loading ? (
                <div className="col-12 col-lg-12 col-xl-12 mb-3 d-flex justify-content-center">
                  {loading}
                </div>
              ) : (
                <div className="miler-card mt-0">
                  <div className="row">
                    <DataTable
                      columns={columns(handlePasswordReset, reInvite)}
                      data={userLogs}
                      responsive={true}
                      loading
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="miler-card mt-0">
        <div className="miler-card-title">Safety Details</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.licenseNumber}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "licenseNumber",
                  value: e.target.value,
                })
              }
              name="licenseNumber"
              label="License Number"
              error={findError("LicenseNumber", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <CountryRegionInput
              onChange={(e) =>
                dispatch({
                  type: "licenseState",
                  value: e.target.value,
                })
              }
              value={state.licenseState}
              name="licenseState"
              label="License State"
              error={findError("LicenseState", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-4">
            <DateTimeInput
              type="date"
              value={state.licenseExpirationDate}
              onChange={(value) =>
                dispatch({
                  type: "licenseExpirationDate",
                  value,
                })
              }
              name="licenseExpirationDate"
              label="License Expiration Date"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <DateTimeInput
              type="date"
              value={state.hireDateUtc}
              onChange={(value) =>
                dispatch({
                  type: "hireDateUtc",
                  value,
                })
              }
              name="hireDateUtc"
              label="Hire Date"
            />
          </div>
          {fromEdit && state.status === "Inactive" && (
            <>
              <div className="col-12 col-lg-6 col-xl-4">
                <InputMiler
                  value={state.terminationType}
                  type="select"
                  onChange={(e) => {
                    dispatch({
                      type: "terminationType",
                      value: e.target.value,
                    });
                    getTerminationReasons(e.target.value);
                  }}
                  name="terminationType"
                  options={enums.TerminationType}
                  label="Termination Type"
                  error={findError("terminationType", errors)}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-4">
                <InputMiler
                  value={state?.terminationReasonId}
                  type="select2"
                  onChange={(e) => {
                    dispatch({
                      type: "terminationReasonId",
                      value: e.target.value,
                    });
                  }}
                  name="terminationReasonId"
                  options={terminationReasons}
                  label="Termination Reason"
                  error={findError("terminationReasonId", errors)}
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-4">
                <DateTimeInput
                  type="date"
                  value={state.terminationUtc}
                  onChange={(value) =>
                    dispatch({
                      type: "terminationUtc",
                      value,
                    })
                  }
                  name="terminationUtc"
                  label="Termination Date"
                />
              </div>

              <div className="col-12 col-lg-6 col-xl-4">
                <DateTimeInput
                  type="date"
                  value={state.lastDayWorked}
                  onChange={(value) =>
                    dispatch({
                      type: "lastDayWorked",
                      value,
                    })
                  }
                  name="lastDayWorked"
                  label="Last Day Worked"
                />
              </div>
            </>
          )}

          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.isCdlDriver ? "Yes" : "No"}
              type="select"
              onChange={(e) =>
                dispatch({
                  type: "isCdlDriver",
                  value: e.target.value === "Yes" ? true : false,
                })
              }
              name="isCdlDriver"
              options={["Yes", "No"]}
              label="CDL Driver?"
              error={findError("isCdlDriver", errors)}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.isHazmatCertified ? "Yes" : "No"}
              type="select"
              onChange={(e) =>
                dispatch({
                  type: "isHazmatCertified",
                  value: e.target.value === "Yes" ? true : false,
                })
              }
              name="isHazmatCertified"
              options={["Yes", "No"]}
              label="Hazmat Certified?"
              error={findError("isHazmatCertified", errors)}
            />
          </div>
          {state.isHazmatCertified ? (
            <div className="col-12 col-lg-6 col-xl-4">
              <DateTimeInput
                type="date"
                value={state.hazmatExpiration}
                onChange={(value) =>
                  dispatch({
                    type: "hazmatExpiration",
                    value,
                  })
                }
                name="hazmatExpiration"
                label="Hazmat Expiration Date"
              />
            </div>
          ) : null}
          <div className="col-12 col-lg-6 col-xl-4">
            <DateTimeInput
              type="date"
              value={state.nextReviewUtc}
              onChange={(value) =>
                dispatch({
                  type: "nextReviewUtc",
                  value,
                })
              }
              name="nextReviewUtc"
              label="Next Review Date"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <DateTimeInput
              type="date"
              value={state.medicalExpiration}
              onChange={(value) =>
                dispatch({
                  type: "medicalExpiration",
                  value,
                })
              }
              name="medicalExpiration"
              label="Medical Expiration Date"
            />
          </div>
        </div>
      </div>
      <div className="miler-card">
        <div className="miler-card-title">Home Address</div>
        <div>
          <AddressSearcher
            address={state.address}
            dispatch={(newAddress) => {
              const countryCode =
                (newAddress?.countryCode?.toLowerCase() === "us" && "US") ||
                (newAddress?.countryCode?.toLowerCase() === "ca" && "CA");

              dispatch({
                type: "address",
                value: newAddress,
              });
              setCountryOnView(countryCode);
            }}
            error={findError("Address.AddressLine", errors)}
          />
        </div>
        <div className="row">
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.address.city}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "city",
                  category: "address",
                  value: e.target.value,
                })
              }
              name="city"
              label="City"
              error={findError("Address.City", errors)}
              disabled
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.address.state}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "state",
                  category: "address",
                  value: e.target.value,
                })
              }
              name="state"
              label="State"
              error={findError("Address.State", errors)}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={state.address.postalCode}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "postalCode",
                  category: "address",
                  value: e.target.value,
                })
              }
              name="postalCode"
              label="Postal Code"
              error={findError("Address.PostalCode", errors)}
              disabled
            />
          </div>
          <div className="col col-12 col-xl-6">
            <InputMiler
              value={countryOnView}
              type="select"
              options={["US", "CA"]}
              onChange={(e) => handleCountryConversion(e)}
              name="countryCode"
              label="Country"
              error={findError("Address.CountryCode", errors)}
              disabled
            />
          </div>
        </div>
      </div>

      <div className="miler-card">
        <div className="miler-card-title">Emergency Contact</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.emergencyContactFirstName}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "emergencyContactFirstName",
                  value: e.target.value,
                })
              }
              name="emergencyContactFirstName"
              label="First Name"
              error={findError("emergencyContactFirstName", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.emergencyContactLastName}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "emergencyContactLastName",
                  value: e.target.value,
                })
              }
              name="emergencyContactLastName"
              label="Last Name"
              error={findError("emergencyContactLastName", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.relationship}
              type="select"
              onChange={(e) =>
                dispatch({ type: "relationship", value: e.target.value })
              }
              name="relationship"
              options={enums.RelationshipType}
              label="Relationship"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <label className="phone-input-label" htmlFor="phone">
              Phone Number
            </label>
            <PhoneInput
              className={classNames("phone-input", {
                "phone-error": findError("EmergencyContactPhone", errors),
              })}
              defaultCountry="US"
              international
              value={state.emergencyContactPhone}
              placeholder="Enter phone number"
              onChange={(value) => {
                dispatch({
                  type: "emergencyContactPhone",
                  value,
                });
              }}
              name="emergencyContactPhone"
            />
            <span className="phone-error-message">
              {findError("EmergencyContactPhone", errors)}
            </span>
          </div>
          <div className="col-12 col-lg-6 col-xl-4">
            <InputMiler
              value={state.emergencyContactEmail}
              type="text"
              onChange={(e) =>
                dispatch({
                  type: "emergencyContactEmail",
                  value: e.target.value,
                })
              }
              name="emergencyContactEmail"
              label="Email"
              error={findError("emergencyContactEmail", errors)}
            />
          </div>
        </div>
      </div>
      {isPasswordReset && (
        <ResetSuccessDialog
          resetSuccess={resetSuccess}
          open={isPasswordReset}
          closeModal={() => setResetPassword(false)}
          title="Reset Password"
          text={`The user password for ${state?.firstName} will be reset and the user will be notified?`}
          cancelText="No"
          submitText="Reset"
          onSubmit={() => resetPassword()}
          onCancel={() => setResetPassword(false)}
          successLoader={successLoader}
        />
      )}
    </div>
  );
};

export default DriverForm;
