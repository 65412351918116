import { useContext } from "react";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import BorderedText from "components/bordered-text/BorderedText";
import Maps from "components/mapMiler/MapsMiler";
import joinStrings from "utils/joinStrings";
import "./shipment-info-box.sass";

export default function ShipmentInfoBox() {
  const { currentTrip } = useContext(ActivityContext);

  let allTrailerTypes = [];
  let allTrailerFunc = () => {
    allTrailerTypes = [];
    currentTrip?.stops?.forEach((stop) => {
      if (stop.trailerType && !allTrailerTypes.includes(stop.trailerType)) {
        allTrailerTypes.push(stop.trailerType);
      }
    });
    return allTrailerTypes;
  };
  return (
    <div className="shipment-info-box">
      <div className="title">Shipment Info</div>
      <div className="shipment_map">
        <Maps
          type="snapshots"
          tripStops={currentTrip?.stops}
          tripId={currentTrip?.tripId}
          tripMapSnapshot={currentTrip?.snapshotUrl}
        />
      </div>

      <div className="description">
        <div className="first">
          <div className="contain">
            <div className="name">Customer</div>
            <div className="value">
              {currentTrip?.shipmentResponse?.shipperName}
            </div>
          </div>
          <div className="contain">
            <div className="name">Shipment Number</div>
            <div className="value">
              {currentTrip?.shipmentResponse?.shipmentNumber}
            </div>
          </div>
          <div className="contain">
            <div className="name">Shipment Type</div>
            <div className="value">
              {currentTrip?.shipmentResponse?.shipmentType}
            </div>
          </div>
        </div>
        <div className="first">
          <div className="contain">
            <div className="name">Equipment</div>
            <div className="value">{joinStrings(allTrailerFunc(), " | ")}</div>
          </div>
          <div className="contain">
            <div className="name">Commodity</div>
            <div className="value">
              {currentTrip?.shipmentResponse?.commodity || "None"}
              {/* {currentTrip.shipmentResponse?.commodity !== ""
                ? currentTrip.shipmentResponse?.commodity
                : "None"} */}
            </div>
          </div>
          <div className="contain">
            <div className="name">Distance</div>
            <div className="value">{`${
              currentTrip.shipmentResponse?.distance || ""
            } ${currentTrip.shipmentResponse?.unitDistance || ""}`}</div>
          </div>
        </div>
        <div className="third">
          <div className="contain">
            <div className="name">Add ons</div>
            <div>
              {currentTrip?.shipmentResponse?.liftgate && (
                <BorderedText classes="blue" text="Liftgate" />
              )}
              {currentTrip?.shipmentResponse?.twic && (
                <BorderedText classes="blue" text="TWIC" />
              )}
              {currentTrip?.shipmentResponse?.rollDoor && (
                <BorderedText classes="blue" text="Roll Door" />
              )}
              {currentTrip?.shipmentResponse?.tarps && (
                <BorderedText classes="blue" text="Tarps" />
              )}
              {currentTrip?.shipmentResponse?.driverAssist && (
                <BorderedText classes="blue" text="Driver Assist" />
              )}
              {currentTrip?.shipmentResponse?.team && (
                <BorderedText classes="blue" text="Team" />
              )}
              {currentTrip?.shipmentResponse?.highValue && (
                <BorderedText classes="blue" text="High Value" />
              )}
              {currentTrip?.shipmentResponse?.appointmentRequired && (
                <BorderedText classes="blue" text="Appointment Required" />
              )}
              {currentTrip?.shipmentResponse?.appointmentSet  && (
                <BorderedText classes="blue" text="Appointment Set" />
              )}
              {currentTrip?.shipmentResponse?.hazmat && (
                <BorderedText classes="blue" text="Hazmat" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
