import { useState, useEffect } from "react";

import InputMiler from "components/inputMiler/InputMiler";
import "./advancedSearch.sass";
import { getElementsV2 } from "services/milerApi";
import useDebounce from "hooks/useDebounce";
const Searchcomponent = ({
  url,
  searchResultItem,
  value,
  label,
  error,
  required,
  placeholder,
  newNumberInput,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [realInputValue, setRealInputValue] = useState("");

  const [searchState, setSearch] = useState({
    result: [],
    searchDisplay: false,
  });

  const debounceSearch = useDebounce(realInputValue, 500);
  let fetcher = (query) => {
    getElementsV2({ url: `${url}=${query}`, setLoading: null }).then(
      (response) => {
        setSearch({
          ...searchState,
          result: response.data || [],
          searchDisplay: true,
          inputValue: query,
        });
      }
    );
  };
  const changed = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setRealInputValue(value);
  };

  const clickAway = () => {
    setInputValue(value);
    setTimeout(() => {
      searchState.searchDisplay &&
        setSearch({ ...searchState, searchDisplay: false });
    }, 500);
  };

  useEffect(() => {
    debounceSearch && fetcher(debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return (
    <div className="advanced-search">
      <InputMiler
        label={label}
        type="search"
        onChange={changed}
        value={inputValue || ""}
        onBlur={clickAway}
        error={error}
        required={required}
        disabled={disabled}
        placeholder={placeholder || "search"}
      />
      {searchState.searchDisplay && (
        <div className="advance-search__result">
          {newNumberInput && newNumberInput(debounceSearch)}
          {searchState.result.map((item, index) =>
            searchResultItem(item, index)
          )}
        </div>
      )}
    </div>
  );
};

export default Searchcomponent;
