import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import "./NewEmptyTrip.sass";

const SelectedTripCard = ({ searchedDriver }) => {
  const {
    driver,
    coDriver,
    hasdriverpayee,
    hascdriverpayee,
    vehicleNumber,
  } = searchedDriver;

  const driverName = String(driver.name).split("");

  const coDriverName = String(coDriver.name).split("");

  const driverFirstName = driverName[0];

  const driverLastName = driverName[1];

  const coDriverFirstName = coDriverName[0];

  const coDriverLastName = coDriverName[1];

  return (
    <div className="selected-vehicle-Trip-Card">
      <div className="selected-vehicle-trip-card-title">{`#${vehicleNumber}`}</div>
      <hr />
      <div className="vehicle-trip-card-details">
        {driver?.driverId && (
          <div className="vehicle-driver-info">
            {hasdriverpayee ? (
              <ProfilePlaceholder
                firstName={driverFirstName}
                lastName={driverLastName}
              />
            ) : (
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Payee Not Setup For Driver"
              >
                <ProfilePlaceholder error />
              </div>
            )}

            <div>{`${driver?.name}`}</div>
          </div>
        )}
        {coDriver?.driverId && (
          <div className="vehicle-driver-info">
            {hascdriverpayee ? (
              <ProfilePlaceholder
                firstName={coDriverFirstName}
                lastName={coDriverLastName}
              />
            ) : (
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Payee Not Set up For Driver"
              >
                <ProfilePlaceholder error />
              </div>
            )}
            <div>{`${coDriver?.name}`}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectedTripCard;
