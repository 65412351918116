import { useContext } from "react";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import InputMiler from "components/inputMiler/InputMiler";
import { Card, CardBody, CardTitle } from "reactstrap";
import { appContext } from "services/AppContext";
import dateFormatter from "utils/dateFormater";
import findError from "utils/findError";

const RecurringShipmentDates = ({
  type,
  current,
  onChange,
  setCurrent,
  errors,
}) => {
  const disableEdit = current?.canEdit == false || type === "marketPlace";
  const { enums } = useContext(appContext);

  const getStatusEnums = () => {
    let statusValue = enums.LeadStatus;

    if (!current?.isRecurring) {
      statusValue = enums.LeadStatus.filter(
        (status) =>
          status?.keyName?.toLowerCase() === "new" ||
          status?.keyName?.toLowerCase() === "cancelled"
      );

      return statusValue;
    }

    return statusValue;
  };

  return (
    <Card>
      <CardBody className="shipment-detail-card">
        <fieldset disabled={disableEdit}>
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">Dates</p>
          </CardTitle>

          <div className="row">
            <div className="col-12 col-lg-4 col-xl-4">
              <DateTimeInput
                type="date"
                name="startUTC"
                label="Start date"
                required
                value={current?.startUTC}
                onChange={(value) => {
                  setCurrent({
                    ...current,
                    startUTC: value,
                  });
                }}
                error={findError("StartUTC", errors)}
              />
            </div>

            <div className="col-12 col-lg-4 col-xl-4">
              <DateTimeInput
                type="date"
                name="endUTC"
                label="End date"
                required
                value={current?.endUTC}
                onChange={(value) => {
                  setCurrent({
                    ...current,
                    endUTC: value,
                  });
                }}
                error={findError("EndUTC", errors)}
              />
            </div>

            {current?.respondBy && (
              <div className="col-12 col-lg-4 col-xl-4">
                <DateTimeInput
                  type="date"
                  name="respondBy"
                  label="Must respond by"
                  value={dateFormatter(current?.respondBy, "YYYY-MM-DD", true)}
                  onChange={onChange}
                />
              </div>
            )}

            <div className="col-12 col-lg-4 col-xl-4">
              <InputMiler
                type="select"
                name="status"
                label="Status"
                value={current.status}
                options={getStatusEnums()}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row">
            {current?.weeks !== null && current?.weeks !== undefined && (
              <div className="col-12 col-lg-4 col-xl-4">
                <InputMiler label="Weeks" value={current?.weeks} />
              </div>
            )}

            {current?.loadsPerWeek !== null &&
              current?.loadsPerWeek !== undefined && (
                <div className="col-12 col-lg-4 col-xl-4">
                  <InputMiler
                    label="Loads Per Week"
                    value={current?.loadsPerWeek}
                  />
                </div>
              )}
            {current?.totalEstimatedLoads !== null &&
              current?.totalEstimatedLoads !== undefined && (
                <div className="col-12 col-lg-4 col-xl-4">
                  <InputMiler
                    label="Estimated Total Shipments"
                    value={current?.totalEstimatedLoads}
                  />
                </div>
              )}
          </div>
        </fieldset>
      </CardBody>
    </Card>
  );
};

export default RecurringShipmentDates;
