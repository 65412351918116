import InputMiler from "components/inputMiler/InputMiler";
import { Popover, PopoverBody } from "reactstrap";
import React, { useState, useEffect } from "react";
import dateFormatter from "utils/dateFormater";
import MilerButton from "components/milerButton/MilerButton";
import FilterLabelBox from "./filterLabelBox/FilterLabelBox";

const FilterDateRange = ({
  request,
  setRequest,
  fromProp,
  toProp,
  stateProp,
  states,
  title,
  applyDefaultDate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const [_datesRange, setDateRange] = useState({
    [fromProp]: request[fromProp] || "",
    [toProp]: request[toProp] || "",
  });
  const cancleFilter = () => {
    let requestModified = JSON.parse(JSON.stringify(request));
    delete requestModified[stateProp];
    setRequest({
      ...requestModified,
      [fromProp]: applyDefaultDate ? applyDefaultDate : "",
      [toProp]: applyDefaultDate ? applyDefaultDate : "",
      offset: 1,
    });
  };

  const toggle = () => {
    setAnchorEl(!anchorEl);
  };

  useEffect(() => {
    if (anchorEl === false && Object.values(_datesRange).find((i) => i)) {
      setDateRange({ ...request });
    }
  }, [anchorEl]);

  const checkValuesExist = () => {
    if (request[fromProp] || request[toProp] || request[stateProp]) {
      return true;
    } else return false;
  };

  const onSave = () => {
    setRequest({ ...request, ..._datesRange, offset: 1 });
    toggle();
  };

  let filteredValue = "";

  const getFilteredValues = () => {
    if (request[fromProp] || request[toProp]) {
      filteredValue =
        dateFormatter(request[fromProp], "MMM DD") +
        " --> " +
        dateFormatter(request[toProp], "MMM DD");
      if (request[stateProp]) {
        filteredValue = filteredValue + ", " + request[stateProp];
      }
    } else {
      filteredValue = request[stateProp] ? request[stateProp] : "";
    }
    return filteredValue;
  };

  let id = title.replace(/ /g, "");
  return (
    <>
      <FilterLabelBox
        checkValuesExist={checkValuesExist}
        toggle={toggle}
        cancleFilter={cancleFilter}
        title={title}
        id={id}
        filteredValue={getFilteredValues()}
      />

      <Popover placement="bottom" isOpen={anchorEl} target={id} toggle={toggle}>
        <PopoverBody>
          <div className="d-flex flex-column justify-content-center">
            <InputMiler
              value={_datesRange[fromProp]}
              type="date"
              onChange={(e) =>
                setDateRange({ ..._datesRange, [fromProp]: e.target.value })
              }
              name={fromProp}
              label="From"
            />
            <InputMiler
              value={_datesRange[toProp]}
              type="date"
              onChange={(e) =>
                setDateRange({ ..._datesRange, [toProp]: e.target.value })
              }
              name={toProp}
              label="To"
            />
            {states && (
              <InputMiler
                value={_datesRange[stateProp]}
                name={stateProp}
                label={stateProp}
                type="select"
                onChange={(e) =>
                  setDateRange({
                    ..._datesRange,
                    [stateProp]: e.target.value,
                  })
                }
                options={states || []}
              />
            )}
            <MilerButton title="Save" btnDark sm onClick={onSave} />
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};
export default FilterDateRange;
