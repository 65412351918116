import CellBuilder from "components/cellBuilderTable/CellBuilder";

export const columns = [
  {
    name: "Inspection ID",
    selector: (row) => row.id,
    grow: 1,
    cell: (row) => CellBuilder(row.id),
  },
  {
    name: "INSPECTION DATE",
    selector: (row) => row.date,
    grow: 1,
  },
  {
    name: "FAILED ITEMS",
    selector: (row) => row.failedItems,
    grow: 1.5,
    cell: (row) => CellBuilder(row.failedItems),
    center: true
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 1,
    center: true
  },
];
