import CellBuilder from "components/cellBuilderTable/CellBuilder";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getElementsV2 } from "services/milerApi";
import { digitsToCurrency } from "utils/currencyFormatter";
import dateFormatter from "utils/dateFormater";
import "./Fuel.sass";

const Fuel = ({ fuelId }) => {
  const [fuels, setFuels] = useState([]);
  const columns = [
    {
      name: "TRANSACTION",
      selector: (row) => row.transaction,
      grow: 2,
    },
    {
      name: "UNIT",
      selector: (row) => row.unit,
      grow: 2,
    },
    {
      name: "TRIP",
      selector: (row) => row.trip,
      grow: 2,
    },
    {
      name: "TOTAL CHARGE",
      selector: (row) => row.charge,
      grow: 2,
    },
    {
      name: "VENDOR",
      selector: (row) => row.vendor,
      grow: 2,
    },
  ];
  let fuelsList = fuels.map((data) => {
    return {
      transaction: CellBuilder(
        dateFormatter(data.transactionDate, "YYYY-MM-DD", true),
        data.transactionNumber
      ),
      unit: CellBuilder(data.unitNumber, data.driverNumber),
      trip: CellBuilder(data.tripId),
      charge: CellBuilder(digitsToCurrency(data.totalCharge)),
      vendor: CellBuilder(data.vendor, `${data.city}, ${data.state}`),
    };
  });

  useEffect(() => {
    if (fuelId) {
      getElementsV2({
        url: `/Equipment/Vehicle/${fuelId}/FuelTransaction`,
        setLoading: null
      }).then((res) =>
        setFuels(res.data || [])
      );
    }
  }, []);

  return (
    <div className="driver_details_fuel_card_container">
      <div className="summary-list">
        <DataTable
          columns={columns}
          data={fuelsList}
          responsive={true}
          pagination
        />
      </div>
    </div>
  );
};

export default Fuel;
