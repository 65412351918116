import { useEffect, useState } from "react";
import { getGatewayApi } from "services/gatewayApis";
import { getElementsV2 } from "services/milerApi";
import { toKCurrencyFormatter } from "utils/currencyFormatter";
import "./Overview.sass";

const Overview = ({ driverId, hos, setHos }) => {
  const [driverDetail, setDriverDetail] = useState();
  const [calcValues, setCalcValues] = useState({
    breaks: 0,
    drive: 0,
    shift: 0,
    cycle: 0,
  });
  const getDashboardDetails = () => {
    getGatewayApi(`carrier/drivers/hos?driver_id=${driverId}`).then(
      (response) => {
        const modHos = response.data.find((res) => res.driver_id == driverId);
        if (modHos) {
          let breaks = (1 - modHos.BreakHoursRemaining / 8) * 100;
          let drive = (1 - modHos.DriveHoursRemaining / 11) * 100;
          let shift = (1 - modHos.ShiftHoursRemaining / 14) * 100;
          let cycle = (1 - modHos.CycleHoursRemaining / 70) * 100;
          setCalcValues({ breaks, drive, shift, cycle });
          setHos(modHos);
        }
      }
    );
    getElementsV2({
      url: `/Driver/${driverId}/dashboard`,
      setLoading: null
    })
      .then((response) => setDriverDetail(response.data))
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          //timeoutHandler()
        }
      });
  };

  useEffect(getDashboardDetails, []);

  const getMinutes = (hr) => {
    if (hr) {
      let modHr = parseInt((hr + "").split(".")[1]?.substring(0, 2));
      modHr = (modHr * 60) / 100;
      modHr = (modHr + "").split(".")[0];
      return !isNaN(modHr) ? modHr : 0;
    } else {
      return 0;
    }
  };

  return (
    <div className="driver-overview-cardss">
      {hos.driver_id && (
        <div className="third_card_cont">
          <div className="cardd">
            <div className="title">Service Hours</div>

            <div className="cardd">
              <div className="title">
                Summary{" "}
                <div className="dut_stat">
                  {hos.DutyStatus &&
                    hos.DutyStatus.charAt(0).toUpperCase() +
                      hos.DutyStatus.slice(1)}
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="val" style={{ color: "#32CD33" }}>
                    {hos.CycleHoursRemaining ? (
                      <>
                        {parseInt(hos.CycleHoursRemaining)}h{" "}
                        {hos.CycleHoursRemaining &&
                          getMinutes(hos.CycleHoursRemaining)}
                        m{" "}
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                  <div className="tit">
                    Cycle Hours Remaining <br />
                    (70H)
                  </div>
                </div>
                <div className="itm">
                  <div className="val" style={{ color: "#32CD33" }}>
                    {hos.CycleHoursRemainingTomorrow ? (
                      <>
                        {parseInt(hos.CycleHoursRemainingTomorrow)}h{" "}
                        {hos.CycleHoursRemainingTomorrow &&
                          getMinutes(hos.CycleHoursRemainingTomorrow)}
                        m
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                  <div className="tit">
                    Cycle Hours Remaining Tomorrow <br />
                    (70H)
                  </div>
                </div>
              </div>
            </div>
            <div className="cardd_time_cont">
              <div className="cardd_time">
                <div className="txt">
                  <div className="val">
                    {hos.BreakHoursRemaining ? (
                      <>
                        {hos.BreakHoursRemaining &&
                          parseInt(hos.BreakHoursRemaining)}
                        h{" "}
                        {hos &&
                          hos.BreakHoursRemaining &&
                          getMinutes(hos.BreakHoursRemaining)}
                        m{" "}
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                  <div className="lbl">
                    Hours Until Break <br /> (8H)
                  </div>
                </div>
                <div
                  className="prog"
                  style={{
                    height: "12px",
                    background: "#AB9BFD",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="colered"
                    style={{
                      height: "100%",
                      width: `${calcValues.breaks}%`,
                      background: "#EFF0F5",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <div className="cardd_time">
                <div className="txt">
                  <div className="val">
                    {hos.DriveHoursRemaining ? (
                      <>
                        {hos && parseInt(hos.DriveHoursRemaining)}h{" "}
                        {hos &&
                          hos.DriveHoursRemaining &&
                          getMinutes(hos.DriveHoursRemaining)}
                        m{" "}
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                  <div className="lbl">
                    Drive Hours Remaining <br />
                    (11H)
                  </div>
                </div>
                <div
                  className="prog"
                  style={{
                    height: "12px",
                    background: "#8EC9FA",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="colered"
                    style={{
                      height: "100%",
                      width: `${calcValues.drive}%`,
                      background: "#EFF0F5",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <div className="cardd_time">
                <div className="txt">
                  <div className="val">
                    {hos.ShiftHoursRemaining ? (
                      <>
                        {hos && parseInt(hos.ShiftHoursRemaining)}h{" "}
                        {hos &&
                          hos.ShiftHoursRemaining &&
                          getMinutes(hos.ShiftHoursRemaining)}
                        m{" "}
                      </>
                    ) : (
                      <>N/A</>
                    )}
                  </div>
                  <div className="lbl">
                    Shift Hours Remaining <br />
                    (14H)
                  </div>
                </div>
                <div
                  className="prog"
                  style={{
                    height: "12px",
                    background: "#FDC1A1",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="colered"
                    style={{
                      height: "100%",
                      width: `${calcValues.shift}%`,
                      background: "#EFF0F5",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {driverDetail && (
        <>
          <div className="first_card_cont">
            <div className="cardd">
              <div className="title">Trips this Week</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Total</div>
                  <div className="val">{driverDetail.trips.total}</div>
                </div>
                <div className="itm">
                  <div className="tit">On Going</div>
                  <div className="val">{driverDetail.trips.ongoing}</div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Scheduled</div>
                  <div className="val">{driverDetail.trips.scheduled}</div>
                </div>
                <div className="itm">
                  <div className="tit">Completed</div>
                  <div className="val">{driverDetail.trips.completed}</div>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div className="title">Earnings</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last Pay Period</div>
                  <div className="val">
                    {toKCurrencyFormatter(driverDetail.revenue.lastPay)}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 4 Weeks</div>
                  <div className="val">
                    {toKCurrencyFormatter(driverDetail.revenue.last4Weeks)}
                  </div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="tit">Last 3 Months</div>
                  <div className="val">
                    {toKCurrencyFormatter(driverDetail.revenue.last3Months)}
                  </div>
                </div>
                <div className="itm">
                  <div className="tit">Last 12 Months</div>
                  <div className="val">
                    {toKCurrencyFormatter(driverDetail.revenue.last12Months)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second_card_cont">
            <div className="cardd">
              <div className="title">Performance</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="val">
                    {driverDetail.performance.hosValidations}
                  </div>
                  <div className="tit">
                    HOS <br /> Violations
                  </div>
                </div>
                <div className="itm">
                  <div className="val">
                    {driverDetail.performance.onTimePickUp}%
                  </div>
                  <div className="tit">
                    On Time <br />
                    Pick Up
                  </div>
                </div>
                <div className="itm">
                  <div className="val">
                    {driverDetail.performance.onTimeDelivery}%
                  </div>
                  <div className="tit">
                    On Time <br /> Delivery
                  </div>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div className="title">Compliance</div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="val">
                    {driverDetail.compliance.roadsideInspections}
                  </div>
                  <div className="tit">
                    Roadside <br /> Inspections
                  </div>
                </div>

                <div className="itm">
                  <div className="val">
                    {driverDetail.compliance.incidentReports}
                  </div>
                  <div className="tit">
                    Accident <br /> Reports
                  </div>
                </div>
                <div className="itm">
                  <div className="val">
                    {driverDetail.compliance.eldDisconnections}
                  </div>
                  <div className="tit">
                    ELD <br /> Disconnections
                  </div>
                </div>
              </div>
              <div className="itm_cont">
                <div className="itm">
                  <div className="val_text">
                    {driverDetail.compliance?.lastDrugTestStatus}
                  </div>
                  <div className="tit">
                    Last Drug <br /> Test Result
                  </div>
                </div>
                <div className="itm">
                  <div className="val_text">
                    {driverDetail.compliance?.lastAlcoholTestStatus}
                  </div>
                  <div className="tit">
                    {/* Roadside <br /> Inspections */}
                    Last Alcohol <br /> Test Result
                  </div>
                </div>
                <div className="itm">
                  <div className="val_text">
                    {driverDetail.compliance?.safetyScore || " - "}
                  </div>
                  <div className="tit">Safety Score</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
