import { useContext } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import findError from "utils/findError";
import { Card } from "reactstrap";
import DateTimeInput from "components/datetime-input/DateTimeInput";


const ClaimDetails = ({ dispatch, state, errors }) => {
  const { enums } = useContext(appContext);

  return (
    <div className="mt-3">
      <Card style={{ padding: "10px" }}>
        <p className="shipment-detail-title m-2">Claim Details</p>
        <div className="row p-3">
          <div className="col-12 col-lg-6">
            <InputMiler
              value={state?.claimType || ""}
              type="select"
              options={enums.ClaimType}
              onChange={(e) =>
                dispatch({ action: "claimType", value: e.target.value })
              }
              name="claimType"
              label="Claim Type"
              error={findError("claimType", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6">
          <DateTimeInput
            type="date"
            value={state?.claimUtc}
            onChange={(value) =>
              dispatch({ action: "claimUtc", value: value })
            }
            name="claimUtc"
            label="Claim Date"
            error={findError("claimUtc", errors)}
            required
          />
            {/* <InputMiler
              type="date"
              label="Claim Date"
              name="claimDate"
              error={findError("ClaimDate", errors)}
              value={state?.claimUtc}
              onChange={(e) =>
                dispatch({ action: "claimUtc", value: e.target.value })
              }
              required
            /> */}
          </div>
          <div className="col-12 col-lg-6">
            <InputMiler
              value={state?.sealNumber || ""}
              type="text"
              onChange={(e) =>
                dispatch({ action: "sealNumber", value: e.target.value })
              }
              name="sealNumber"
              label="Seal Number"
              error={findError("sealNumber", errors)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputMiler
              value={
                state?.sealIntact == null ? "" : state.sealIntact ? "Yes" : "No"
              }
              type="select"
              options={["Yes", "No"]}
              onChange={(e) =>
                dispatch({
                  action: "sealIntact",
                  value: e.target.value == "Yes",
                })
              }
              name="sealIntact"
              label="Was Seal Intact"
              error={findError("sealIntact", errors)}
            />
          </div>
          <div className="col-12 col-lg-6">
            <InputMiler
              value={
                state?.isInsuranceNotified == null
                  ? ""
                  : state?.isInsuranceNotified
                  ? "Yes"
                  : "No"
              }
              type="select"
              options={["Yes", "No"]}
              onChange={(e) => {
                dispatch({
                  action: "isInsuranceNotified",
                  value: e.target.value == "Yes",
                });
              }}
              name="isInsuranceNotified1"
              label="Was this reported to insurance?"
              error={findError("isInsuranceNotified", errors)}
            />
          </div>
          {state?.freightClaimsId && (
            <div className="col-12 col-lg-6">
              <InputMiler
                value={state.status || ""}
                type="select"
                onChange={(e) =>
                  dispatch({ action: "status", value: e.target.value })
                }
                name="status"
                label="Status"
                options={enums.AccidentStatus}
                error={findError("Status", errors)}
              />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ClaimDetails;
