import BorderedText from "components/bordered-text/BorderedText";
import MilerButton from "components/milerButton/MilerButton";
import { useContext } from "react";
import DataTable from "react-data-table-component";
import { getElements, postElement } from "services/milerApi";
import { digitsToCurrency } from "utils/currencyFormatter";
import { CarrierChargeContext } from "../../carrierChargeContext/CarrierChargeContext";
import "views/accounting/invoices_v2/invoicesDisplay/newInvoice/NewInvoice.sass";
import { columns } from "../carrierDisplay/carrierData";
import dateFormatter from "utils/dateFormater";
import { BsExclamationTriangle } from "react-icons/bs";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import { hasPermission } from "utils/hasPermission";

const ProcessInvoice = ({ setProcessInvoiceOpen }) => {
  const { state, dispatch } = useContext(CarrierChargeContext);

  const processInvoice = () => {
    let ids = state.checkedProcessCarrierCharges.map((sp) => sp.invoiceId);
    postElement("/Carrier/Invoice/process", ids).then(() => {
      getElements(`/Carrier/Charges`, "2.0").then((res) => {
        setProcessInvoiceOpen(false);
        dispatch({ type: "carrierCharges", payload: res.data });
        dispatch({
          type: "setCurrent",
          payload: res.data.find(
            (a) =>
              a.carrierProfile.carrier_id ===
              state.current.carrierProfile.carrier_id
          )
            ? res.data.find(
                (a) =>
                  a.carrierProfile.carrier_id ===
                  state.current.carrierProfile.carrier_id
              )
            : res.data[0],
        });
      });
    });
  };

  let invoicesList = state.checkedProcessCarrierCharges.map((data) => {
    let paidCurr = data.payment ? data.payment : 0;
    let amountCurr = data.amount ? data.amount : 0;
    let balanceCurr = amountCurr - paidCurr;
    return {
      invoiceNumber: CellBuilder(data.invoiceNumber),
      trip: CellBuilder(
        data.shipmentNumber,
        dateFormatter(data.invoiceDateUTC, "date")
      ),
      shipment: CellBuilder(
        data.shipmentNumber,
        dateFormatter(data.invoiceDateUTC, "date")
      ),
      pickUp: CellBuilder(
        data.pickupAddress &&
          data.pickupAddress.addressLine &&
          data.pickupAddress.addressLine,
        data.pickupAddress.city &&
          `${data.pickupAddress.city}, ${data.pickupAddress.state}`
      ),
      dropOff: CellBuilder(
        data.deliveryAddress &&
          data.deliveryAddress.addressLine &&
          data.deliveryAddress.addressLine,
        data.deliveryAddress.city &&
          `${data.deliveryAddress.city}, ${data.deliveryAddress.state}`
      ),
      amount: (
        <div>
          <div className="settlement_revenue_row">
            {digitsToCurrency(data.amount)}
          </div>
        </div>
      ),
      charges: (
        <div>
          <div className="settlement_revenue_row">
            {digitsToCurrency(data.charge)}
          </div>
        </div>
      ),
      payment: CellBuilder(
        data.payment && digitsToCurrency(data.payment),
        `${dateFormatter(data.paymentUtc, "MMM DD, YYYY")}`,
        "blue"
      ),
      balance: CellBuilder(digitsToCurrency(balanceCurr), "", "blue"),
      status:
        data.status === "Failure" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BorderedText text={data.status} classes={data.status} />
            <BsExclamationTriangle
              style={{
                color: "#FA4747",
                width: "16px",
                height: "16px",
                marginLeft: "5px",
              }}
            />
          </div>
        ) : (
          <BorderedText text={data.status} classes={data.status} />
        ),
      actions: (
        <div className="table-actions">
          <i
            className="mil-delete-o"
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch({ type: "removeProcess", payload: data });
            }}
          />
        </div>
      ),
    };
  });

  return (
    <div className="new_settlement_container">
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">Process Invoices</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="Cancel"
              onClick={() => {
                setProcessInvoiceOpen(false);
                dispatch({ type: "try" });
              }}
            />
            <MilerButton
              btnDark
              title="Process"
              onClick={() => processInvoice()}
              disabled={state.checkedProcessCarrierCharges.length === 0}
              targetActionDenied={!hasPermission("edit.accounting")}
            />
          </div>
        </div>
        <div className="slider-display-body">
          <div className="first">
            <div className="name">{state.current.customerName}</div>
            {/* <div className="settlementDetailWrapper">
              <div className="type">
                #{state.current.accountPayableId} | {state.current.payeeType}
              </div>
            </div> */}

            {/* <div className="address">
              2338 Groveport Road Columbus, OH 43244
            </div> */}
          </div>
          <div className="second" style={{ marginTop: "30px" }}>
            {state.checkedProcessCarrierCharges.length === 0 ? (
              <div className="new_checked_settlement_zero">
                You have not selected any records
              </div>
            ) : (
              <div className="summary-list">
                <DataTable
                  columns={columns}
                  data={invoicesList}
                  responsive={true}
                  pagination
                />
              </div>
            )}
          </div>
          {state.checkedProcessCarrierCharges.length !== 0 && (
            <div className="third">
              <div className="stat-content">
                <div className="itm">
                  <div className="name">Shipments</div>
                  <div className="value">
                    {state.checkedProcessCarrierCharges.length}
                  </div>
                </div>
                <div className="itm">
                  <div className="name">Sub Total</div>
                  <div className="value">
                    $
                    {(
                      Math.round(
                        state.checkedProcessCarrierCharges
                          .map((r) => r.amount)
                          .reduce((prev, nxt) => prev + nxt) * 100
                      ) / 100
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="itm">
                  <div className="name">Other</div>
                  <div className="value">0</div>
                </div>
                <div className="itm">
                  <div className="name">Total Amount</div>
                  <div className="value">
                    $
                    {(
                      Math.round(
                        state.checkedProcessCarrierCharges
                          .map((r) => r.amount)
                          .reduce((prev, nxt) => prev + nxt) * 100
                      ) / 100
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default ProcessInvoice;
