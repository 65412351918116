import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import VehiclesDetails from "views/fleet/vehicles/vehicleDetails/VehicleDetails";
import ShipmentDetailsWrapper from "views/shipments/shipmentDetails/ShipmentDetailsWrapper";
import './DriverInfo.sass'

const EquipInfo = ({ trip }) => {
  let allTrailerNumbers = [];
  let allTrailerFunc = () => {
    allTrailerNumbers = [];
    trip?.stops.forEach((stop) => {
      if (
        stop.trailerNumber &&
        !allTrailerNumbers.includes(stop.trailerNumber)
      ) {
        allTrailerNumbers.push(stop.trailerNumber);
      }
    });
    return allTrailerNumbers;
  };

  allTrailerFunc();
  const [open, setOpen] = useState(false);

  const [openShipmentEdit, setOpenShipmentEdit] = useState(false);
  const [openVehicleDetails, setOpenVehicleDetails] = useState(false);

  return (
    <div className=" activity-equipment-card">
      <div
        className="eqp-section sect-clickable"
        onClick={() => setOpenShipmentEdit(true)}
      >
        <div>Shipment</div>
        <div className="driver_number_link">{trip.shipmentNumber}</div>
      </div>
      <div className="eqp-section-separator"></div>
      <div
        className="eqp-section sect-clickable"
        onClick={() => setOpenVehicleDetails(true)}
      >
        <div>Vehicle</div>
        <div className="driver_number_link">{trip.unitNumber}</div>
      </div>
      <div className="eqp-section-separator"></div>
      <div className="eqp-section">
        <div>Trailer</div>
        <div className="trip-trailer-numbers">
          {allTrailerNumbers[0]}
          {allTrailerNumbers.length > 1 && (
            <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
              <DropdownToggle tag="div" className="trip-trailer-numbers-total">
                +{allTrailerNumbers.length - 1}
              </DropdownToggle>
              <DropdownMenu className="trip-trailer-numbers-list">
                {allTrailerNumbers.slice(1).map((trailer, index) => (
                  <div key={index}>{trailer}</div>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>

      <SliderModal
        clickState={openShipmentEdit}
        setClickState={setOpenShipmentEdit}
      >
        <ShipmentDetailsWrapper
          setContent={setOpenShipmentEdit}
          shipmentId={trip.shipmentResponse?.shipmentId}
        />
      </SliderModal>

      <SliderModal
        clickState={openVehicleDetails}
        setClickState={setOpenVehicleDetails}
      >
        <VehiclesDetails
          setDetails={setOpenVehicleDetails}
          vehicleId={trip.vehicleCard?.vehicleId}
          getVehicles={() => {}}
        />
      </SliderModal>
    </div>
  );
};

export default EquipInfo;
