import { useContext } from "react";
import { CarrierChargeContext } from "../../carrierChargeContext/CarrierChargeContext";
import CarrierChargeCard from "./CarrierChargeCard";

const CarrierChargeCards = ({ setStatus }) => {
  const { state } = useContext(CarrierChargeContext);

  return (
    <div className="settlement_cards_container">
      {state.carrierCharges.map((carrierCharge) => {
        return (
          <CarrierChargeCard
            carrierCharge={carrierCharge}
            key={carrierCharge.accountId}
            setStatus={setStatus}
          />
        );
      })}
    </div>
  );
};

export default CarrierChargeCards;
