import { useContext, useEffect, useState } from "react";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import { FiRefreshCw } from "react-icons/fi";
import { calculateEta } from "utils/calculateEta";

const ETADisplay = ({ currentTrip }) => {
  const [etaState, setEtaState] = useState({});
  const { getTrip } = useContext(ActivityContext);
  const nextStop = currentTrip.stops.find((element) => {
    return element.checkCall?.refresh;
  });
  const [calculatingEta, setCalculatingEta] = useState(false);

  useEffect(() => {
    const stp = (currentTrip?.stops || []).find((ct) => ct.checkCall);

    if (stp && !stp.checkCall.eta && stp.checkCall.refresh) {
      calculateEta(currentTrip, nextStop, () => {
        getTrip(currentTrip.tripId);
      });
    }
    setEtaState(stp?.checkCall);
  }, []);

  return (
    <>
      {etaState?.etaRelative && (
        <div className="third">
          <div className="itm">
            ETA: <div className="seap">{etaState?.etaRelative}</div>
          </div>
          {etaState?.remainingDistance &&
          parseInt(etaState?.remainingDistance) !== 0 ? (
            <>
              <div className="sep"></div>
              <div className="itm">
                Remaining:{" "}
                <div className="seap">{etaState?.remainingDistance}mi</div>
              </div>
            </>
          ) : (
            <></>
          )}
          {etaState?.refresh && (
            <FiRefreshCw
              className="seap_ico"
              style={{
                animation: calculatingEta ? "spin 2s linear infinite" : "",
              }}
              onClick={() => {
                setCalculatingEta(true);
                calculateEta(
                  currentTrip,
                  nextStop,
                  async () => {
                    await getTrip(currentTrip.tripId);
                    setCalculatingEta(false);
                  },
                  "",
                  {
                    onError: () => {
                      setCalculatingEta(false);
                    },
                  }
                );
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ETADisplay;
