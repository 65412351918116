import "../../invoices_v2/invoicesSidebar/InvoicesSidebar.sass";
import { useContext } from "react";
import CarrierChargeCards from "./carrierChargeCards/CarrierChargeCards";
import InputMiler from "components/inputMiler/InputMiler";
import { CarrierChargeContext } from "../carrierChargeContext/CarrierChargeContext";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";

const CarrierChargeSidebar = ({ setStatus }) => {
  const { state, dispatch } = useContext(CarrierChargeContext);

  return (
    <div className="settlement_sidebar">
      <div className="title">Carriers</div>
      <div className="settlement_sidebar-filter">
        <InputMiler
          type="search"
          value={state.searchedValue}
          onChange={(e) =>
            dispatch({ type: "search", payload: e.target.value })
          }
        />

        <div className="status-filter">
          <ElipsisMenu icon="mil-menu">
            <MenuItem title="All" onClick={() => {}} />
          </ElipsisMenu>
        </div>
      </div>
      <div>
        <CarrierChargeCards setStatus={setStatus} />
      </div>
    </div>
  );
};

export default CarrierChargeSidebar;
