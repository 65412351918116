const convertToNumber = (num) => {
  if (isNaN(num) || num === "") {
    return "";
  } else {
    const number = parseFloat(num).toFixed(2);
    return parseFloat(number).toLocaleString();
  }
};

export default convertToNumber;
