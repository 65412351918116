import MilerButton from "components/milerButton/MilerButton";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import { useEffect, useState } from "react";
import { getAccessToken } from "services/Auth";
import { getElements, postElement } from "services/milerApi";

const ProfileCarriersList = () => {
  const [carriers, setCarriers] = useState([]);

  const carrierOnClicked = (id) => {
    postElement(`/Carrier/${id}/Switch`).then(() => {
      getAccessToken("REFRESH");
      window.location.reload();
    });
  };
  const getCarriers = () => {
    getElements("/Config/User/Profile").then((res) =>
      setCarriers(res.data.carriers || {})
    );
  };

  useEffect(getCarriers, []);

  const testAction = () => {};
  return (
    <div className="profile-carrier-cont">
      <div className="d-flex justify-content-between align-items-centers px-2 py-1 pt-2">
        <span className="profile-carrier-title">My Carriers</span>
        <MilerButton
          title="Manage"
          icon="mil-setting-f"
          sm
          onClick={testAction}
        />
      </div>

      <div className="profile-carriers-list">
        {carriers?.map((carrier, index) => (
          <section className="profile-carrier" key={index}>
            <div className="d-flex align-items-center">
              <ProfilePlaceholder jointName={carrier.company} />
              <div className="profile-carrier-info">
                <div>{carrier.company}</div>
                <div>DOT: {carrier.us_dot}</div>
              </div>
            </div>
            {!carrier.isDefault && (
              <MilerButton
                title="Make Default"
                onClick={() => carrierOnClicked(carrier.carrier_id)}
                xs
                btnDark
              />
            )}
          </section>
        ))}
      </div>
    </div>
  );
};

export default ProfileCarriersList;
