import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import CardForm from "./CardForm";
import { getGatewayApi, postGatewayApi } from "services/gatewayApis";

export default function PaymentSetupForm({
  payment,
  paymentTrigger,
  setPaymentTrigger,
  setPayment,
  paymentLoading,
  setPaymentLoading,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setPaymentLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const result = await stripe.confirmCardSetup(
      payment.payment_details.payment_setup_link,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    if (result.error) {
    } else {
      await postGatewayApi("/carrier/customer/payment_setup", {
        stripe_customer_id: payment.stripe_customer_id,
        default_payment_method: result.setupIntent.payment_method,
      }).then(() => setPaymentTrigger(!paymentTrigger));

      getGatewayApi("/carrier/customer/payment_setup")
        .then(({ data }) => setPayment(data))
        .then(() => setPaymentLoading(false));
    }
  };

  return (
    <CardForm
      onSubmit={handleSubmit}
      disabled={!stripe}
      paymentLoading={paymentLoading}
    />
  );
}
