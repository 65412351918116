import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { digitsToCurrency } from "utils/currencyFormatter";
import EditCharge from "./EditCharge";
import BorderedText from "components/bordered-text/BorderedText";
import dateFormatter from "utils/dateFormater";
import { deleteElement } from "services/milerApi";

const RecurringTable = ({ recurring, updateRecurringList, getPayee }) => {
  const [openForm, setOpenForm] = useState(false);
  const chargesRecurring = {
    ...openForm.charge,
    amount: Math.abs(openForm.charge?.amount || ""),
  };
  const columns = [
    {
      name: "Charge",
      selector: (row) => row.transactionType,
      cell: (row) => row.transactionType,
      grow: 2.5,
    },
    {
      name: "Recurring Amount",
      selector: (row) => row.amount,
      cell: (row) => <div>{digitsToCurrency(row.amount)}</div>,
      grow: 2.5,
    },
    {
      name: "Stop After",
      selector: (row) => row.stopAfter,
      cell: (row) => row.stopAfter,
      grow: 2,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      cell: (row) => row.description,
      grow: 2,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => row.status,
      grow: 2,
    },
    {
      name: "Action",
      cell: (row) => row.actions,
      grow: 1.5,
    },
  ];

  const deleteRecurringData = (id) => {
    deleteElement(`/Carrier/Payee/Recurring/${id}`)
      .then((response) => {
        if (response.success) {
          getPayee();
        }
      })
      .catch(() => {});
  };
  const tableData = recurring.map((charge, index) => {
    const {
      recurringTransactionId,
      transactionType,
      amount,
      status,
      description,
      stopAfter,
      stopAfterAmount,
      stopAfterDate,
    } = charge || {};

    return {
      recurringTransactionId,
      transactionType,
      amount,
      status: <BorderedText text={status} classes={status} />,
      description,
      stopAfter: stopAfter?.toLowerCase()?.includes("amount")
        ? digitsToCurrency(stopAfterAmount || 0)
        : dateFormatter(stopAfterDate, "MMM DD, YYYY"),
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o clickable"
            onClick={() => setOpenForm({ charge, index })}
          />
          {recurringTransactionId && (
            <i
              className="mil-delete-o clickable"
              onClick={() => {
                deleteRecurringData(recurringTransactionId);
              }}
            />
          )}
        </div>
      ),
    };
  });

  return (
    <>
      <div className="summary-list">
        <DataTable data={tableData} columns={columns} />
      </div>
      <SliderModal
        clickState={Boolean(openForm)}
        setClickState={setOpenForm}
        from="bottom"
        height={50}
      >
        <EditCharge
          setOpenForm={setOpenForm}
          chargeDetails={chargesRecurring || {}}
          updateRecurringList={updateRecurringList}
          index={openForm.index}
        />
      </SliderModal>
    </>
  );
};

export default RecurringTable;
