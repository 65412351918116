import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import permissionDeniedToast from "utils/permissionDeniedToast";

import "./MilerButton.sass";

const MilerButton = ({
  onClick,
  btnDark,
  tooltip,
  icon,
  reversed,
  title,
  disabled,
  loading,
  bg,
  sm,
  xs,
  block,
  img,
  className,
  targetActionDenied,
}) => {
  const mainContent = () => (
    <>
      {reversed && title && <span>{title}</span>}
      {icon && <i className={`icon ${icon}`}></i>}
      {!reversed && title && <span>{title}</span>}
    </>
  );
  return (
    <>
      <Tooltip id="message-tooltip" place="bottom" />
      <button
        data-tooltip-id="message-tooltip"
        data-tooltip-content={
          targetActionDenied
            ? "You are not permitted to perfom this action."
            : tooltip
        }
        className={classNames("miler-btn", className, {
          "btn-miler-dark": btnDark && !targetActionDenied,
          "btn-disabled": targetActionDenied,
          disabled: disabled,
          btn_loading: loading,
          xs: xs,
          sm: sm,
          bg: bg,
          block: block,
          img: img,
        })}
        onClick={
          disabled || loading
            ? () => {}
            : targetActionDenied
            ? permissionDeniedToast
            : onClick
        }
      >
        {mainContent()}
      </button>
    </>
  );
};

export default MilerButton;
