import { useContext } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import DataTable from "react-data-table-component";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";

const TripShipReferences = () => {
  const { currentTrip } = useContext(ActivityContext);

  const columns = [
    {
      name: "REFERENCE TYPE",
      selector: (row) => row.referenceType,
      grow: 4,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.referenceType}</div>
      ),
    },
    {
      name: "REFERENCE",
      selector: (row) => row.referenceId,
      grow: 4,
      center: true,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.referenceId}</div>
      ),
    },
  ];
  return (
    <div>
      {" "}
      <Card className="my-2">
        <CardBody className="shipment-detail-card py-1">
          <CardTitle className="shipment-detail-header my-0">
            <p className="shipment-detail-title my-0">SHIPMENT REFERENCE</p>
          </CardTitle>

          <div className="shipment small-table">
            <DataTable
              columns={columns}
              data={currentTrip?.shipmentResponse?.shipmentReferences}
              className="shipment"
              responsive={true}
              noDataComponent={
                <div style={{ color: "#868C97", padding: "30px" }}>
                  No references
                </div>
              }
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default TripShipReferences;
