import classNames from "classnames";
import {
  FormGroup,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import "../InputMiler.sass";
import "./CompoundCheckbox.sass";

const CompoundCheckbox = ({
  value,
  onChange,
  onBlur,
  name,
  placeholder,
  label,
  narrow,
  error,
  hidden,
  required,
  title,
  disabled,
  checkLabel,
  checked,
  onChecked,
}) => {
  return (
    <div>
      <FormGroup
        className={classNames("input-miler", {
          "d-inline-block": narrow,
          hidden: hidden,
        })}
      >
        {label && (
          <>
            <label>{label}</label>
            {required && <span className="required-red">*</span>}
          </>
        )}
        <InputGroup
          className="search-field"
          invalid={Boolean(error).toString()}
        >
          <Input
            value={disabled ? "" : value}
            className={`shadow-none ${
              disabled && "input_miler_compound_check_box_dis"
            }`}
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            title={title}
            name={name}
            placeholder={placeholder ? placeholder : ""}
            invalid={Boolean(error)}
            disabled={disabled}
          ></Input>
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="search-field__search-icon">
              <input
                type="checkbox"
                className="input_miler_compound_check_box_check"
                checked={checked}
                onChange={onChecked}
              />
              <div className="input_miler_compound_check_box_txt">
                {checkLabel}
              </div>
            </InputGroupText>
          </InputGroupAddon>
          <FormFeedback>{error}</FormFeedback>
        </InputGroup>
      </FormGroup>
    </div>
  );
};

export default CompoundCheckbox;
