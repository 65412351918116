import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";

const ConfirmDialog = ({
  open,
  closeModal,
  title,
  text,
  cancelText,
  submitText,
  onSubmit,
  onCancel,
}) => {
  return (
    <Modal isOpen={Boolean(open)} toggle={() => closeModal(false)}>
      <ModalHeader toggle={() => closeModal(false)}>{title}</ModalHeader>
      <ModalBody>
        <div>{text}</div>
      </ModalBody>

      <ModalFooter>
        {cancelText && <MilerButton title={cancelText} onClick={onCancel} />}
        {submitText && (
          <MilerButton title={submitText} btnDark onClick={onSubmit} />
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmDialog;
