import BorderedText from "components/bordered-text/BorderedText";
import AssignTripToDriver from "components/assignTripToDriver/AssignTripToDriver";
import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import joinStrings from "utils/joinStrings";
import ActivityDisplaySlider from "views/activities-v2/activityDisplay/ActivityDisplaySlider";
import DriverEditDetails from "views/activities-v2/driverEditDetails/DriverEditDetail";
import SelectableButton from "components/selectableButton/SelectableButton";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";

const ShipmentTrips = ({ trip, getTrips }) => {
  const [driverDetails, setDriverDetails] = useState(false);
  const [openTrip, setOpenTrip] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  let driverName = joinStrings(
    [trip.driverFirstName?.toUpperCase()[0], trip.driverLastName],
    ". "
  );
  const onAssignCallBack = (res) => {
    if (res.success) {
      getTrips();
      setOpenAssign(false);
    }
  };
  return (
    <div className="associated-trips-container">
      <section className="atc-top">
        <strong
          className="link-like mb-1"
          onClick={() => setOpenTrip(trip.tripId)}
        >
          #{trip.tripId}
        </strong>
      </section>
      {trip.unitNumber ? (
        <>
          <section className="atc-top">
            {getUserInfoCookieProp("subscription_plan") ===
            "Miler Rectify (Free)" ? (
              <div style={{ margin: "4px -5px", display: "flex" }}>
                <SelectableButton
                  title={trip.tripStatus || ""}
                  btnDark
                  sm
                  onClick={() => {}}
                  options={["Re-Assign"]}
                  onOptionSelected={() => setOpenAssign(true)}
                />
              </div>
            ) : (
              <BorderedText
                text={trip.tripStatus}
                classes={`${trip.tripStatus} sm mb-1`}
              />
            )}
          </section>
          {trip.driverId && (
            <section>
              <div className="section-title">Driver</div>
              <div>
                {driverName}{" "}
                <span
                  className="link-like"
                  onClick={() => setDriverDetails(trip.driverId)}
                >
                  ({trip.driverId})
                </span>
              </div>
            </section>
          )}
          <section>
            <div className="section-title">Vehicle</div>
            <div>#{trip.unitNumber}</div>
          </section>
        </>
      ) : (
        <div style={{ margin: "4px -5px", display: "flex" }}>
          <SelectableButton
            title={trip.tripStatus || ""}
            btnDark
            sm
            onClick={() => {}}
            options={["Assign"]}
            onOptionSelected={() => setOpenAssign(true)}
          />
        </div>
      )}
      <SliderModal
        clickState={Boolean(driverDetails)}
        setClickState={setDriverDetails}
      >
        <DriverEditDetails
          driverId={driverDetails}
          setDriverDetails={setDriverDetails}
        />
      </SliderModal>
      <SliderModal clickState={Boolean(openTrip)} setClickState={setOpenTrip}>
        <ActivityDisplaySlider
          tripId={openTrip}
          closeTripSlider={setOpenTrip}
        />
      </SliderModal>
      <SliderModal
        clickState={openAssign}
        setClickState={setOpenAssign}
        width="40"
      >
        <AssignTripToDriver
          type="trip"
          setOpenAssign={setOpenAssign}
          data={{
            id: trip.tripId,
            shipmentNumber: trip.shipmentNumber,
            shipmentType: trip.shipmentResponse?.shipmentType,
            shipperName: trip.shipmentResponse?.shipperName,
          }}
          onAssignCallBack={onAssignCallBack}
        />
      </SliderModal>
    </div>
  );
};

export default ShipmentTrips;
