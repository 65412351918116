import { useContext } from "react";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import ShipmentInfoBox from "./shipment-info-box/ShipmentInfoBox";
import Charges from "./Charges";
import TripShipReferences from "./TripShipReferences";
import IPCard from "../InprogressTrip/inprogressTripCard/IPCard";
import PackageInfo from "components/packageInfo/PackageInfo";
import "./non-inprogress-trip.sass";
import ShipmentTags from "components/shipmentTagsView/ShipmentTags";

export default function AvailableTrip({ stops, getTrips }) {
  const { currentTrip } = useContext(ActivityContext);

  return (
    <div className="non-inprogress-trip trip-display-body-flex">
      <div>
        {stops.map((stop, index) => (
          <IPCard
            key={index}
            stop={stop}
            index={index}
            stopLength={stops.length}
            handleFetchData={getTrips}
          />
        ))}
      </div>

      <div className="inprogress_trip_package_card">
        <div className="w-100 ">
          <ShipmentInfoBox />
        </div>
        {currentTrip?.shipmentResponse?.tags?.length > 0 && (
          <div className="w-100 my-2">
            <ShipmentTags state={currentTrip?.shipmentResponse?.tags} />
          </div>
        )}
        <div className="w-100 my-2">
          <TripShipReferences />
        </div>

        <div className="w-100 my-2">
          <PackageInfo
            data={currentTrip?.shipmentResponse?.shipmentLading || []}
          />
        </div>
        <div className="w-100 my-2">
          <Charges />
        </div>
      </div>
    </div>
  );
}
