import {
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";

import { Link } from "react-router-dom";
import { logoutRedirect } from "services/Auth";
import "./ProfileDropdown.sass";
import "antd/dist/antd.min.css";
import { useState } from "react";
import { getCookie } from "utils/cookies";
import ProfileCarriersList from "./ProfileCarriersList";

const ProfileDropdown = () => {
  const { given_name, family_name, multicarrier, email } =
    getCookie("userInfo") || {};

  const [isOpen, setIsOpen] = useState(false);

  const toggler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={toggler}>
        <DropdownToggle className="pr-0" nav onClick={toggler}>
          <Media className="align-items-center">
            {/* <span className="avatar avatar-sm rounded-circle"> */}
            <span className="avatar avatar-sm rounded-circle">
              <p className="nav-profile-container-image nav-profile-container-image-col">
                {given_name ? given_name.charAt(0).toUpperCase() : ""}
                {/* {family_name ? family_name.charAt(0).toUpperCase() : ""} */}
              </p>
            </span>
          </Media>
        </DropdownToggle>
        {isOpen && (
          <DropdownMenu className="dropdown-menu-arrow user-profile">
            <div className="profile-dropdown-first-section">
              <Media className="align-items-center">
                <div className="profile-dropdown-nav-first-container">
                  <span className="avatar avatar-sm rounded-circle">
                    <span className="nav-profile-container-image-big nav-profile-container-image-col ">
                      {given_name ? given_name.charAt(0).toUpperCase() : ""}
                      {family_name ? family_name.charAt(0).toUpperCase() : ""}
                    </span>
                  </span>

                  <p className="profile-dropdown-nav-first-name">{`${
                    given_name || ""
                  } ${family_name || ""}`}</p>
                  <p className="profile-dropdown-nav-first-email">{email}</p>
                </div>
              </Media>
            </div>
            <DropdownItem divider />
            <div className="profile-buttons">
              <DropdownItem to="/profile" tag={Link} className="profile-button">
                <span>My Profile</span>
              </DropdownItem>
              <DropdownItem
                className="profile-button"
              >
                 <a
                  href="https://miler.com/pages/contact.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </a>
              </DropdownItem>
              <span
                onClick={logoutRedirect}
                className="clickable profile-button"
              >
                Log Out
              </span>
            </div>

            <DropdownItem divider />
            {multicarrier && <ProfileCarriersList />}
            <div className="profile-footer">
              <span>
                <a
                  href="https://miler.com/pages/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
              <span>
                <a
                  href="https://miler.com/pages/terms.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </span>
            </div>
          </DropdownMenu>
        )}
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;
