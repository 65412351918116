import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import { useContext, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { postElement } from "services/milerApi";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import MilerButton from "components/milerButton/MilerButton";
import calculateStopsDistance from "utils/calculateStopsDistance";
import CompoundInput from "components/inputMiler/compoundInput/CompoundInput";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import InputMiler from "components/inputMiler/InputMiler";
import { digitsToCurrency } from "utils/currencyFormatter";

const defaultStop = {
  stopId: 0,
  stopType: "Split",
  actualArrival: "",
  actualDeparture: "",
  earliest_arrival: "",
  address: {
    default: true,
    addressId: 0,
    addressType: "WorkSite",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
  },
};

const SplitTrip = ({ setSpiltTripOpen, getTrips }) => {
  const { currentTrip, getTrip } = useContext(ActivityContext);

  const stops = currentTrip.stops;
  const updateTrip = () => getTrip(currentTrip.tripId);

  const stopsSplitter = () => {
    let _isSplit = false;
    let _stops = [];
    stops.forEach((x, i) => {
      if (x.stopType === "Start") {
        //do nothing
      } else {
        if (_isSplit) {
          x.keyValue = i;
        }
        _isSplit = true;
      }
      _stops.push(x);
    });
    let _splitableStops = _stops
      .filter((x) => x.keyValue)
      .map((stop) => ({
        keyValue: stop.keyValue,
        keyName: `${stop.address.addressLine || ""}, ${
          stop.address.city || ""
        }, ${stop.address.state || ""}`,
      }));

    return _splitableStops;
  };

  let _splitableStops = stopsSplitter();

  const [splitInfo, setSplitInfo] = useState({
    type: "before",
    stopIndex: _splitableStops[0].keyValue,
  });

  const stopChange = (e) => {
    let selectedStopIndex = parseInt(e.target.value);
    let lastStopIndex = stops.length - 1;
    let splitType = splitInfo.type;
    //if selected stop is the last stop, set split type to "before"
    if (selectedStopIndex === lastStopIndex) {
      splitType = "before";
    }
    setSplitInfo({
      type: splitType,
      stopIndex: selectedStopIndex,
    });
  };

  const [distances, setDistances] = useState({
    distance1: 0,
    distance2: 0,
    distanceRatio: 0,
    revenue1: 0,
    revenue2: 0,
    revenueRatio: 0,
    calculated: false,
  });

  const [newStop, setNewStop] = useState(defaultStop);
  const [splitstops, setSplitstops] = useState([]);
  const [splitLoading, setSplitLoading] = useState(false);

  let roundDecimal = (number, places = 2) => {
    let roundedNumber =
      number.toFixed(places) % 1 ? number.toFixed(places) : Math.round(number);
    return roundedNumber;
  };

  const splitDistances = (stops) => {
    if (splitInfo.stopIndex) {
      setDistances({}); // point of failure
      const stopGroup1 = stops.slice(0, splitInfo.stopIndex + 1);
      const stopGroup2 = stops.slice(splitInfo.stopIndex);

      //implement unitDistance
      const changeDistance = (dist, type) => {
        setDistances((prev) => {
          let calculatedProps = {};
          let modifiedPrev = { ...prev, [type]: dist };
          if (modifiedPrev.distance1 && modifiedPrev.distance2) {
            //let r = modifiedPrev.distance1 / modifiedPrev.distance2;

            let distanceRatio =
              modifiedPrev.distance1 /
              (modifiedPrev.distance1 + modifiedPrev.distance2);

            calculatedProps = {
              revenueRatio: roundDecimal(distanceRatio),
              revenue1: roundDecimal(distanceRatio * currentTrip.revenue),
              revenue2: roundDecimal((1 - distanceRatio) * currentTrip.revenue),
              distanceRatio: roundDecimal(distanceRatio),
              calculated: true,
            };
          }
          return { ...modifiedPrev, ...calculatedProps };
        });
      };
      calculateStopsDistance(stopGroup1, (dist) =>
        changeDistance(dist, "distance1")
      );
      calculateStopsDistance(stopGroup2, (dist) =>
        changeDistance(dist, "distance2")
      );
    }
  };
  const splitTrip = () => {
    const stopsList = JSON.parse(JSON.stringify(currentTrip.stops));
    if (splitInfo.type === "on") {
      stopsList[splitInfo.stopIndex].stopType = "Split";
    } else if (splitInfo.type === "before") {
      const { trailerNumber, trailerType } = currentTrip.stops[
        splitInfo.stopIndex
      ];
      stopsList.splice(splitInfo.stopIndex, 0, {
        ...newStop,
        trailerNumber,
        trailerType,
      });
      stopsList
        .filter((stop) => stop.stopType != "Start")
        .forEach((stop, index) => {
          stop.stopSequence = index + 1;
        });
    }
    setSplitstops(stopsList);
    splitDistances(stopsList);
  };

  const postSplit = () => {
    if (distances.calculated) {
      setSplitLoading(true);
      const { distance1, distance2, revenue1, revenue2 } = distances;

      let requestBody;
      if (splitInfo.type === "before") {
        requestBody = JSON.parse(JSON.stringify(splitstops));
        requestBody.splice(splitInfo.stopIndex, 1, {
          ...splitstops[splitInfo.stopIndex],
          earliest_arrival: newStop.earliest_arrival,
          latest_arrival: newStop.earliest_arrival,
          actualArrival: newStop.earliest_arrival,
        });
      } else {
        requestBody = splitstops;
      }
      postElement(
        `/Shipment/trip/${currentTrip.tripId}/split?distance1=${distance1}&distance2=${distance2}&revenue1=${revenue1}&revenue2=${revenue2}`,
        requestBody
      )
        .then((res) => {
          if (res.success) {
            setSpiltTripOpen(false);
            getTrips();
            updateTrip();
          } else {
            //set errors
          }
        })
        .finally(() => setSplitLoading(false));
    }
  };

  useEffect(() => {
    if (!newStop.address.default) splitTrip();
  }, [newStop.address]);
  useEffect(() => {
    if (splitInfo.type === "before") {
      setNewStop(defaultStop);
      setDistances({});
    } else if (splitInfo.type === "on") {
      splitTrip();
    }
  }, [splitInfo]);

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Split Trip</div>
        <div>
          <MilerButton title="Cancel" onClick={() => setSpiltTripOpen(false)} />
          <MilerButton
            title="Submit"
            btnDark
            onClick={postSplit}
            loading={splitLoading}
          />
        </div>
      </div>
      <div className="slider-display-body">
        <Row>
          {_splitableStops.length > 1 && (
            <Col>
              <CompoundInput
                label="Split On or Before "
                type="select"
                inputValue={splitInfo.stopIndex}
                inputOptions={_splitableStops}
                inputOnchange={stopChange}
                required
                selectValue={splitInfo.type}
                selectOnchange={(e) =>
                  setSplitInfo({
                    ...splitInfo,
                    type: e.target.value,
                  })
                }
                options={
                  splitInfo.stopIndex === stops.length - 1
                    ? ["before"]
                    : ["on", "before"]
                }
              />
            </Col>
          )}
        </Row>
        {splitInfo.type === "before" && (
          <>
            <Row>
              <Col sm="12">
                <AddressSearcher
                  address={newStop.address}
                  label="Split Address"
                  required
                  dispatch={(newAddress) =>
                    setNewStop({ ...newStop, address: newAddress })
                  }
                  full
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DateTimeInput
                  label="Scheduled Arrival"
                  value={newStop.earliest_arrival}
                  required
                  disabled={newStop.address.default}
                  onChange={(value) => {
                    setNewStop({
                      ...newStop,
                      earliest_arrival: value,
                    });
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {distances.calculated && (
          <div className="miler-card">
            <div className="row" style={{ fontSize: "0.9em" }}>
              <div className="col-lg-6 text-center mb-3 ">
                Total Distance:{" "}
                <strong>
                  {Math.round(distances.distance1 + distances.distance2)}
                  {currentTrip.unitDistance === "Miles" ? "mi" : "km"}
                </strong>{" "}
              </div>
              <div className="col-lg-6 text-center mb-3">
                Total Revenue:{" "}
                <strong>{digitsToCurrency(currentTrip.revenue)}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <InputMiler
                  label="First Trip Distance"
                  value={distances.distance1}
                  disabled
                  postfix={roundDecimal(distances.distanceRatio * 100) + "%"}
                />
              </div>

              <div className="col-12 col-lg-6">
                <InputMiler
                  label="First Trip Revenue"
                  type="number"
                  value={distances.revenue1}
                  disabled
                  postfix={roundDecimal(distances.revenueRatio * 100) + "%"}
                />
              </div>
              <div className="col-12 col-lg-6">
                <InputMiler
                  label="Second Trip Distance"
                  value={distances.distance2}
                  disabled
                  postfix={
                    roundDecimal((1 - distances.distanceRatio) * 100) + "%"
                  }
                />
              </div>
              <div className="col-12 col-lg-6">
                <InputMiler
                  label="Second Trip Revenue"
                  type="number"
                  value={distances.revenue2}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) <= e.target.max ||
                      !e.target.value
                    ) {
                      let ratio = roundDecimal(
                        (currentTrip.revenue - e.target.value) /
                          currentTrip.revenue
                      );

                      setDistances({
                        ...distances,
                        revenueRatio: ratio,
                        revenue1: roundDecimal(
                          currentTrip.revenue - e.target.value
                        ),
                        revenue2: e.target.value,
                      });
                    }
                  }}
                  max={currentTrip.revenue}
                  postfix={
                    roundDecimal((1 - distances.revenueRatio) * 100) + "%"
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitTrip;
