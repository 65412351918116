export const serviceProvider = {
  serviceProviderName: "",
  website: "",
  mailingAddress: {
    addressType: "WorkSite",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
  },
  billingAddress: {
    addressType: "WorkSite",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "",
  },
  billingContact: {
    firstName: "",
    lastName: "",
    title: "",
    prefix: "",
    postfix: "",
    department: "",
    email: "",
    phones: [
      // {
      //   phoneType: "Cell Phone",
      //   phoneNumber: "",
      // },
    ],
  },
  mailingContact: {
    firstName: "",
    lastName: "",
    title: "",
    prefix: "",
    postfix: "",
    department: "",
    email: "",
    phones: [
      // {
      //   phoneType: "Cell Phone",
      //   phoneNumber: "",
      // },
    ],
  },
  geolocation: {
    latitude: 0,
    longitude: 0,
  },
  internal: true,
};
