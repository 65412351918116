import { Avatar } from "antd";
import classNames from "classnames";
import { useState } from "react";
import dateFormatter from "utils/dateFormater";

const WhatsAppUsers = ({ drivers, onDriverSelected, getColor }) => {
  const [selectedDriver, setSelectedDriver] = useState("");
  return (
    <div className="drivers-list">
      {drivers.map((driver, i) => (
        <div
          className={classNames({
            "driver-item": true,
            blue: selectedDriver
              ? selectedDriver == driver.whatsappSubscriberId
              : i == 0,
          })}
          key={`${driver.whatsappSubscriberId}`}
          onClick={() => {
            driver.bgColor = getColor(i + 1);
            onDriverSelected(driver);
            setSelectedDriver(driver.whatsappSubscriberId);
          }}
        >
          <Avatar
            size={40}
            style={{
              backgroundColor: getColor(i + 1),
              fontWeight: "500",
              color: "#202F53",
            }}
          >
            {driver.profileName.slice(0, 2)}
          </Avatar>
          <div className="message-details">
            <div className="driver-name">{driver.profileName}</div>
            <p className="message-date">Seen: {driver.lastMessageUtcRelative}</p>
          </div>
          <div className="message-date">
            {dateFormatter(driver.lastMessageUtc, "MMM DD")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default WhatsAppUsers;
