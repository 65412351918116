import { useEffect, useState, useReducer } from "react";
import "./NewEmptyTrip.sass";
import MilerButton from "components/milerButton/MilerButton";
import { postElement } from "services/milerApi";
import TripVehicleSearch from "./TripVehicleSearch";
import TripStops from "./TripStops";
import shipmentReducer from "views/shipments/shipmentForm/reducer";
import { toast } from "react-toastify";
import SelectedTripCard from "./SelectedTripCard";

const NewEmptyTrip = ({ setNewEmptyTripOpen, fromList, listData }) => {
  const [hideVehicle, setHideVehicle] = useState(false);
  const [stops, dispatch] = useReducer(shipmentReducer, {
    stops: [
      {
        stopReferences: [],
        stopSequence: 1,
        stopType: "Pickup",
        trailerType: "",
        trailerNumber: "",
        earliest_arrival: "",
        actualArrival: "",
        latest_arrival: "",
        stopNotes: [],
        stopReason: "Retrieve Empty Trailer",
        address: {
          addressType: "WorkSite",
          addressLine: "",
          city: "",
          state: "",
          postalCode: "",
          countryCode: "",
          timezoneOffset: 0,
          latitude: 0,
          longitude: 0,
        },
      },
      {
        stopSequence: 2,
        stopType: "Delivery",
        trailerType: "",
        trailerNumber: "",
        stopNotes: [],
        earliest_arrival: "",
        actualArrival: "",
        actualDeparture: "",
        stopReferences: [],
        stopReason: "Drop Empty Trailer",
        address: {
          addressType: "WorkSite",
          addressLine: "",
          city: "",
          state: "",
          postalCode: "",
          countryCode: "",
          timezoneOffset: 0,
          latitude: 0,
          longitude: 0,
        },
      },
    ],
  });

  const [errors, setFormErrors] = useState([]);

  const [searchedDriver, setSearchedDriver] = useState({
    ids: [],
    name: "",
    driver: {},
    coDriver: {},
    hasdriverpayee: "",
    hascdriverpayee: "",
    vehicleNumber: "",
  });
  const onSearchedItemSelected = (item) => {
    setSearchedDriver(item);
  };
  useEffect(() => {
    if (fromList) {
      if (!listData.driver && !listData.coDriver) {
        toast.error("Vehicle has no associated driver");
      }
      const dId = listData.driver && listData.driver.driverId;
      const cId = listData.coDriver && listData.coDriver.driverId;
      if (dId) {
        setSearchedDriver({
          ...searchedDriver,
          ids: [...searchedDriver.ids, dId],
          driverId: dId,
        });
        setHideVehicle(true);
      }
      if (cId) {
        setSearchedDriver({
          ...searchedDriver,
          ids: [...searchedDriver.ids, cId],
          coDriverId: cId,
        });
        setHideVehicle(true);
      }
    }
  }, []);

  const postTrip = () => {
    const trip = {
      driverId: searchedDriver.driverId,
      coDriverId: searchedDriver.coDriverId,
      stops: stops.stops,
      distance: stops.distance || "",
    };

    postElement(
      `/Equipment/Vehicle/${
        searchedDriver.vehicleId || listData?.vehicleId
      }/EmptyTrip`,
      trip
    ).then((response) => {
      if (response.success) {
        setFormErrors([]);
        setNewEmptyTripOpen(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key &&
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const containsErrors = (ele) => {
    const err = errors.find((error) => error.key && error.key.includes(ele));
    return err && true;
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Empty Trip</div>
        <div className="slider-header-buttons">
          <MilerButton
            icon="mil-close-bg"
            title="Cancel"
            onClick={() => setNewEmptyTripOpen(false)}
          />
          <MilerButton
            btnDark
            icon="mil-save-o"
            title="Save"
            onClick={postTrip}
          />
        </div>
      </div>
      <div className="slider-display-body">
        {!hideVehicle && (
          <div className="miler-card mt-0">
            <div className="miler-card-title">VEHICLE INFORMATION</div>

            <TripVehicleSearch onItemSelect={onSearchedItemSelected} />
          </div>
        )}

        {searchedDriver.driver.driverId && (
          <SelectedTripCard searchedDriver={searchedDriver} />
        )}
        {searchedDriver.ids.length !== 0 && (
          <div className="shipment-form">
            <TripStops
              state={stops}
              dispatch={dispatch}
              findError={findError}
              containsErrors={containsErrors}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewEmptyTrip;
