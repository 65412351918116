import "./DriverRedirection.sass";
import driverIOS from "assets/logo/driver-app-ios.png";
import driverAndroid from "assets/logo/driver-app-android.png";
import driverHeader from "assets/logo/driver-app-header.jpg";
import MilerButton from "components/milerButton/MilerButton";
import { logoutRedirect } from "services/Auth";

const DriverRedirection = () => {
  return (
    <div className="driver-redirection">
      <img src={driverHeader} className="dr-img-header" alt="driver_logo" />
      <h5 className="dr-info">
        You logged in as a driver you should therefore use the dedicated mobile
        app.
      </h5>
      <div className="apps-links">
        <span className="link-to-app">
          <a href="https://play.google.com/store/apps/details?id=com.miler.mobiledriver">
            <img src={driverAndroid} className="img-app" alt="app_image" />
          </a>
        </span>
        <span className="links-separator">OR</span>
        <span className="link-to-app">
          <a href="https://apps.apple.com/us/app/miler-driver/id1618290104">
            <img src={driverIOS} className="img-app" alt="app_image" />
          </a>
        </span>
      </div>
      <div className="mt-5">
        <MilerButton
          title="LOG OUT"
          onClick={logoutRedirect}
          icon="mil-user-group-f"
        />
      </div>
    </div>
  );
};

export default DriverRedirection;
