import { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import ShipperConsigneeBillToDetail from "./shipperConsigneeBillTo/ShipperConsigneeBillToDetail";
import { getElementsV2 } from "services/milerApi";
import { hasPermission } from "utils/hasPermission";
import AssociatedTrips from "./associatedTrips/AssociatedTrips";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import IPCard from "views/activities-v2/activityDisplay/tripDisplay/InprogressTrip/inprogressTripCard/IPCard";
import ShipmentInfoBox from "views/marketplace/offer/ShipmentInfo";
import ShipmentReferences from "views/marketplace/offer/ShipmentReferences";
import PackageInfo from "components/packageInfo/PackageInfo";
import "./shipmentOverviewCards.sass";
import ShipmentTags from "components/shipmentTagsView/ShipmentTags";

const ShipmentOverview = ({ shptDtls, setEldId, getShipment, setTripId }) => {
  const [shipperInfo, setShipperInfo] = useState();
  const [driverInfo, setDriverInfo] = useState([]);
  let inprogFound = false;

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const getShipper = () => {
    shptDtls.shipper_details_refUrl &&
      getElementsV2({
        url: shptDtls.shipper_details_refUrl,
        setLoading: null,
      }).then((response) => {
        setShipperInfo(response?.data);
      });
  };

  const getDriver = () => {
    shptDtls.driver_details_refUrl &&
      getElementsV2({
        url: shptDtls.driver_details_refUrl,
        setLoading: null
      }).then((response) => {
        setDriverInfo(response.data || []);
        JSON.stringify(driverInfo);
        setEldId(response.data[0]?.assigned_vehicle_eld_id);
      });
  };

  const setUp = () => {
    if (shptDtls) {
      getShipper();
      getDriver();
    }
  };

  useEffect(setUp, [shptDtls]);
  return (
    <div>
      <Row>
        {(hasPermission("view.shipment.customer") || isFleetOwner) && (
          <Col md="6">
            <ShipperConsigneeBillToDetail
              cardTitle="Customer Detail"
              shipperInfo={shipperInfo}
              shipperId={shptDtls.shipperId}
              getShipper={getShipper}
            />
          </Col>
        )}

        <Col md="6">
          <AssociatedTrips
            shipmentId={shptDtls.shipmentId}
            setTripId={setTripId}
          />
        </Col>
      </Row>

      <div className="ship-stops-view">
        <div>
          {shptDtls.stops?.map((shipmentStop, index) => {
            let c;
            if (inprogFound) {
              c = "Pending";
            } else if (shipmentStop.actualDeparture) {
              c = "Completed";
            } else {
              c = "Inprogress";
              inprogFound = true;
            }

            if (isFleetOwner) shipmentStop.progress = c;
            return (
              <IPCard
                stop={shipmentStop}
                key={index}
                stopLength={shptDtls.stops?.length}
                index={index}
                getShipment={getShipment}
                hideActionButtons={!isFleetOwner}
                handleFetchData={getShipment}
              />
            );
          })}
        </div>
        <div className="shipment_package_overview_card">
          <ShipmentInfoBox
            shipmentDetails={shptDtls}
            getShipment={getShipment}
          />
          {shptDtls?.tags?.length > 0 && (
            <div className="mt-2">
              <ShipmentTags state={shptDtls.tags} />
            </div>
          )}
          <div className="mt-2">
            <ShipmentReferences shipmentDetails={shptDtls} />
          </div>
          <div className="mt-2">
            <PackageInfo data={shptDtls?.shipmentLading || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentOverview;
