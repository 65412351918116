export const columns = [
  {
    name: "INVOICE #",
    selector: (row) => row.invoiceNumber,
    sortable: true,
    grow: 4,
  },
  {
    name: "SHIPMENT #",
    selector: (row) => row.shipment,
    sortable: true,
    grow: 5,
  },
  {
    name: "PICK UP",
    selector: (row) => row.pickUp,
    grow: 4,
  },
  {
    name: "DROP OFF",
    selector: (row) => row.dropOff,
    grow: 4,
  },
  {
    name: "AMOUNT",
    selector: (row) => row.amount,
    grow: 2,
  },

  {
    name: "CHARGES",
    selector: (row) => row.charges,
    grow: 2,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 2,
  },
];
