export default (state, action) => {
  if (action.type === "draftNewCustomer") {
    return action.payload;
  }
  if (action.type === "billingSameAsMailing") {
    if (action.value) {
      return {
        ...state,
        billingSameAsMailing: true,
        billingContact: {
          ...state.mailingContact,
        },
        billingAddress: {
          ...state.mailingAddres,
        },
      };
    } else {
      return {
        ...state,
        billingSameAsMailing: false,
      };
    }
  } // change all to fetchedCustomer
  else if (action && action.all) {
    return Object.assign({ ...state }, { ...action.all });
  } else if (action && action.type && action.category) {
    if (action.type === "address") {
      return { ...state, [action.category]: action.value };
    }
    return {
      ...state,
      [action.category]: {
        ...state[action.category],
        [action.type]: action.value,
      },
    };
  } else if (action && action.type && !action.category) {
    return { ...state, [action.type]: action.value };
  } else {
    return { ...state };
  }
};
