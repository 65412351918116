import { useContext, useState, useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import CompoundInput from "components/inputMiler/compoundInput/CompoundInput";

import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import { getElements } from "services/milerApi";
import { getGatewayApi } from "services/gatewayApis";
import CustomerSearchInput from "components/payee-form/CustomerSearchInput";
import { getCookie } from "utils/cookies";
import CompoundCheckbox from "components/inputMiler/compoundCheckBox/CompoundCheckBox";

const ShipmentInfo = ({ state, dispatch, findError, isManifest }) => {
  const [duplicateError, setDuplicateError] = useState("");
  const [autoGenerate, setAutoGenerate] = useState(
    !state.shipmentNumber && false
  );
  const [users, setUsers] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const defaultCommodity =
    state.commodity !== "" ? state.commodity : "General Freight";
  const [selectedCommodity, setSelectedCommodity] = useState(defaultCommodity);

  useEffect(() => {
    dispatch({ type: "commodity", value: selectedCommodity });
  }, []);

  const handleCommodityChange = (e) => {
    const newCommodity = e.target.value;
    setSelectedCommodity(newCommodity);
    dispatch({ type: "commodity", value: newCommodity });
  };

  const {
    enums: { ShipmentType, UnitDistance, UnitWeight },
  } = useContext(appContext);

  const getUsers = () => {
    if (state.shipmentNumber === "<Autogenerate>") {
      setAutoGenerate(true);
    }
    getGatewayApi("carrier/admin/user/").then((r) => {
      let usersList = r.data.map((user) => ({
        keyName: user.FirstName + " " + user.LastName,
        keyValue: user.userId,
      }));
      setUsers(usersList);
    });
  };
  const getCommodities = () => {
    getElements("/Shipment/Commodity").then((response) => {
      setCommodities(response.data);
    });
  };
  useEffect(() => {
    getUsers(), getCommodities();
  }, []);
  const onShipperSearch = (item) => {
    dispatch({
      category: "shipper",
      value: {
        shipperId: item.id,
        shipperName: item.customer_name || item.label,
      },
    });
    checkDuplicate(item.id);
  };

  const checkDuplicate = (shipperId) => {
    if (state.shipmentNumber && state.shipperId)
      getElements(
        `/Shipment/DuplicateCheck?shipperId=${
          shipperId || state.shipperId
        }&shipmentNumber=${state.shipmentNumber}&shipmentId=${state.shipmentId}`
      )
        .then(() => setDuplicateError(""))
        .catch(() =>
          setDuplicateError("Shipment number already exists for this shipper")
        );
  };

  const { isMetric } = getCookie("carrierInfo");

  const findDistanceMetrics = isMetric
    ? UnitDistance[1].keyName
    : UnitDistance[0].keyName;

  const findWeightMetrics = isMetric
    ? UnitWeight[1].keyName
    : UnitWeight[0].keyName;

  return (
    <div className="shipment-info">
      <Card>
        <CardBody className="shipment-detail-card">
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">SHIPMENT INFO</p>
          </CardTitle>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <CustomerSearchInput
                value={{ shipperId: state.id, label: state.shipperName }}
                onSelect={onShipperSearch}
                error={findError(`ShipperId`)}
                disable={isManifest}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              {state?.shipmentId ? (
                <InputMiler
                  type="text"
                  label="Shipment Number"
                  required
                  value={state.shipmentNumber}
                  onChange={(event) => {
                    dispatch({
                      type: "shipmentNumber",
                      value: event.target.value,
                    });
                  }}
                  onBlur={checkDuplicate}
                />
              ) : (
                <>
                  <CompoundCheckbox
                    label="Shipment Number"
                    checkLabel="Autogenerate"
                    disabled={autoGenerate}
                    value={state.shipmentNumber}
                    onChange={(e) =>
                      dispatch({
                        type: "shipmentNumber",
                        value: e.target.value,
                      })
                    }
                    required
                    error={findError(`ShipmentNumber`) || duplicateError}
                    onBlur={checkDuplicate}
                    checked={autoGenerate}
                    onChecked={() => {
                      if (autoGenerate) {
                        setAutoGenerate(false);
                        dispatch({ type: "shipmentNumber", value: "" });
                      } else {
                        setAutoGenerate(true);
                        dispatch({
                          type: "shipmentNumber",
                          value: "<Autogenerate>",
                        });
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select"
                label="Shipment Type"
                options={
                  isManifest
                    ? (ShipmentType || []).filter((x) => x.keyName !== "PTL")
                    : ShipmentType
                }
                value={state.shipmentType}
                onChange={(e) =>
                  dispatch({ type: "shipmentType", value: e.target.value })
                }
                required
                error={findError(`ShipmentType`)}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select2"
                label="Commodity"
                options={commodities}
                value={defaultCommodity}
                onChange={handleCommodityChange}
                error={findError(`Commodity`)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                type="select2"
                label="Sales Rep"
                options={users}
                onChange={(e) =>
                  dispatch({ type: "SalesRepUserId", value: e.target.value })
                }
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                label="Revenue Type"
                type="select"
                value={
                  typeof state.isDedicatedLane == "boolean"
                    ? state.isDedicatedLane
                      ? "Dedicated"
                      : "Spot"
                    : "Spot"
                }
                options={["Spot", "Dedicated"]}
                onChange={(e) =>
                  dispatch({
                    type: "isDedicatedLane",
                    value: e.target.value === "Dedicated" ? true : false,
                  })
                }
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6"></div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-6">
              <CompoundInput
                label="Distance"
                inputValue={state.distance}
                inputError={findError("distance")}
                required
                selectValue={findDistanceMetrics}
                selectOnchange={(e) =>
                  dispatch({ type: "unitDistance", value: e.target.value })
                }
                selectDisabled
                selectError={findError("UnitDistance")}
                options={[findDistanceMetrics]}
                disabled
              />
            </div>

            <div className="col-12 col-lg-6 col-xl-6">
              <CompoundInput
                label="Total Weight"
                inputValue={state.weight}
                inputOnchange={(e) =>
                  dispatch({
                    type: "weight",
                    value: e.target.value,
                  })
                }
                inputError={findError("weight")}
                selectValue={findWeightMetrics}
                options={[findWeightMetrics]}
                selectDisabled
                selectError={findError("UnitWeight")}
                selectOnchange={(e) =>
                  dispatch({
                    type: "unitWeight",
                    value: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ShipmentInfo;

/* packages=[
  { packageType: "pallet" ,quantity:"", length:45, height:454}
] */
