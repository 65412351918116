import { useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import DataTable from "react-data-table-component";
import { getCookie } from "utils/cookies";
import { digitsToCurrency } from "utils/currencyFormatter";
import dateFormater from "utils/dateFormater";
import joinStrings from "utils/joinStrings";
import BorderedText from "components/bordered-text/BorderedText";
import getShipmentStatusColor from "views/shipments/getShipmentStatusColor";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import { hasPermission } from "utils/hasPermission";
import { masterShipmentColumns } from "./MasterShipmentData";
import ShipmentDetailsWrapper from "views/shipments/shipmentDetails/ShipmentDetailsWrapper";
import AssignTripToDriver from "components/assignTripToDriver/AssignTripToDriver";
import UnassignTripModal from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/UnassignTripModal";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import SliderModal from "components/sliderModal/SliderModal";
import UndoTripModal from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/UndoTripModal";
import DuplicateShipment from "views/shipments/shipmentDetails/duplicateShipment/DuplicateShipment";
import ManifestShipment from "views/shipments/shipmentDetails/duplicateShipment/ManifestShipment";
import StickySlider from "components/sliderModal/StickySlider";
import ShipmentCharge from "views/shipments/ShipmentCharge";
import NewFreightClaim from "views/compliance/freightClaims/newFreightClaim/NewFreightClaim";
import {
  getElements,
  deleteElement,
  putElement,
  postElement,
} from "services/milerApi";

import "./MaterShipment.sass";
const MasterShipment = ({
  masterShipment,
  getShipments,
  getReccuringShipment,
}) => {
  const { isMetric } = getCookie("carrierInfo");
  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const [shipId, setShipId] = useState();
  const [shipmentContent, setShipmentContent] = useState(false);
  const [shipment, setShipment] = useState({});
  const [openUnAssignModal, setOpenUnAssignModal] = useState(false);
  const [openUndoModal, setOpenUndoModal] = useState(false);
  const [duplicateShipmentOpen, setDuplicateShipmentOpen] = useState(false);
  const [currentDuplicateId, setCurrentDuplicateId] = useState(0);
  const [manifest, setManifest] = useState(false);
  const [freightClaimOpen, setFreightClaimOpen] = useState(false);
  const [openCharge, setOpenCharge] = useState(false);
  const [shipmentId, setShipmeentId] = useState("");

  const duplicateShipment = (dupId) => {
    setCurrentDuplicateId(dupId);
    setDuplicateShipmentOpen(true);
  };

  const [assignShipmentOpen, setAssignShipmentOpen] = useState(false);
  const shipmentInfo = masterShipment?.map((data) => {
    let distance = `${data?.distance} ${isMetric ? "km" : "mi"}`;
    let weight = `${data?.weight} ${isMetric ? "kgs" : "lbs"}`;
    let pricePerMile = `${digitsToCurrency(
      data?.distance && data?.totalRate ? data?.totalRate / data?.distance : 0
    )} ${isMetric ? "/km" : "/mi"}`;
    return {
      scheduledPickupUTC: data.scheduledPickupUTC,
      scheduledDeliveryUTC: data.scheduledDeliveryUTC,
      nextScheduledUtc: data.nextScheduledUtc,
      shipmentNumber: data.shipmentNumber,
      isSplit: data.isSplit,
      shipmentId: data.shipmentId,
      isRecurring: data.isRecurring && !isFleetOwner,
      isManifest: data.isManifest,
      isDedicatedLane: data.isDedicatedLane && !isFleetOwner,
      shipperName: data.shipperName,
      trailerType: data.trailerType,
      shipmentMode: data.shipmentType,
      nextActivity: data.nextActivity,
      nextEtaType: data.nextEtaType,
      nextStop: data.nextStop,
      nextEtaUtc: dateFormater(data.nextEtaUtc, "MMM DD YYYY, HH:mm"),
      nextScheduledUtcTime: (data.nextScheduledUtc, "MMM DD YYYY, HH:mm", true),
      scheduledPickUpTime: dateFormater(
        data.scheduledPickupUTC,
        "MMM DD YYYY, HH:mm",
        true
      ),
      pickup: joinStrings(
        [data.pickupAddress?.city, data.pickupAddress?.state],
        ", "
      ),
      scheduledDeliveryTime: dateFormater(
        data.scheduledDeliveryUTC,
        "MMM DD YYYY, HH:mm",
        true
      ),
      dropoff: joinStrings(
        [data.deliveryAddress?.city, data.deliveryAddress?.state],
        ", "
      ),
      price: digitsToCurrency(data.totalRate),
      distance: distance,
      pricePerMile: pricePerMile,
      weight: weight,
      eta: (
        <>
          <i
            className={`mil-clock-f eta-icon ${data.pickupDeliveryStatus}`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={data.pickupDeliveryStatus}
          ></i>
        </>
      ),
      status: (
        <BorderedText
          text={data.status}
          classes={getShipmentStatusColor(data.status)}
        />
      ),
      actions: (
        <div>
          <div className="table-actions">
            <i
              className="mil-edit-o"
              onClick={() => {
                setShipId(data.shipmentId);
                setShipmentContent(true);
              }}
              style={{ cursor: "pointer", fontSize: "21px" }}
            />

            {isFleetOwner && (
              <ElipsisMenu>
                <MenuItem
                  icon="mil-arrow-sm-right"
                  title="Open"
                  targetActionDenied={!hasPermission("edit.shipment")}
                  onClick={() => {
                    setShipId(data.shipmentId);
                    setShipmentContent(true);
                  }}
                />
                {data.status == "Available" && (
                  <MenuItem
                    icon="mil-truck-o"
                    title="Assign"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      setShipment(data);
                      setAssignShipmentOpen(true);
                    }}
                  />
                )}

                {data.status == "Assigned" && (
                  <MenuItem
                    icon="mil-close-bg"
                    title="UnAssign"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      setShipment(data);
                      setOpenUnAssignModal(true);
                    }}
                  />
                )}

                {data.status != "Available" && data.status != "Void" && (
                  <MenuItem
                    icon="mil-arrow-corner-left"
                    title="Undo"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      setShipment(data);
                      setOpenUndoModal(true);
                    }}
                  />
                )}
              </ElipsisMenu>
            )}

            {!isFleetOwner && (
              <ElipsisMenu>
                <MenuItem
                  icon="mil-arrow-sm-right"
                  title="Open"
                  targetActionDenied={!hasPermission("edit.shipment")}
                  onClick={() => {
                    setShipId(data.shipmentId);
                    setShipmentContent(true);
                  }}
                />
                {data.status != "Void" && (
                  <MenuItem
                    icon="mil-delete-o"
                    title="Void"
                    targetActionDenied={!hasPermission("delete.shipment")}
                    onClick={() => {
                      delShipment(data.shipmentId);
                    }}
                  />
                )}

                {data.status != "Void" && (
                  <MenuItem
                    icon="mil-void-o"
                    title="Hold"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      hold(data.shipmentId);
                    }}
                  />
                )}
                {data.status != "Void" && (
                  <MenuItem
                    icon={
                      data.isLocked ? "mil-lock-opened-o" : "mil-lock-closed-o"
                    }
                    title={`${data.isLocked ? "Unlock" : "Lock"}`}
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      lock(data);
                    }}
                  />
                )}

                {data.status == "Available" && (
                  <MenuItem
                    icon="mil-truck-o"
                    title="Assign"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      setShipment(data);
                      setAssignShipmentOpen(true);
                    }}
                  />
                )}

                {data.status != "Available" && data.status != "Void" && (
                  <MenuItem
                    icon="mil-arrow-corner-left"
                    title="Undo"
                    targetActionDenied={!hasPermission("edit.shipment")}
                    onClick={() => {
                      setShipment(data);
                      setOpenUndoModal(true);
                    }}
                  />
                )}

                <MenuItem
                  icon="mil-contract-f"
                  title="Duplicate"
                  targetActionDenied={!hasPermission("create.shipment")}
                  onClick={() => {
                    duplicateShipment(data.shipmentId);
                  }}
                />

                {data.shipmentType === "PTL" && (
                  <MenuItem
                    importIcon={<VscCombine style={{ fontSize: "22px" }} />}
                    title="Add To Manifest"
                    targetActionDenied={!hasPermission("create.shipment")}
                    onClick={() => {
                      setManifest(data);
                    }}
                  />
                )}

                {data.status != "Void" && (
                  <MenuItem
                    icon="mil-edit"
                    title="File Freight Claim"
                    targetActionDenied={!hasPermission("create.compliance")}
                    onClick={() => {
                      setFreightClaimOpen(true);
                      setShipment(data);
                    }}
                  />
                )}
                {data.status === "Inprogress" && (
                  <MenuItem
                    icon="mil-void-f"
                    title="Mark TONU"
                    targetActionDenied={
                      !hasPermission("complete.shipment.trip")
                    }
                    onClick={() => {
                      setOpenCharge(true);
                      setShipmeentId(data.shipmentId);
                    }}
                  />
                )}
              </ElipsisMenu>
            )}
          </div>
        </div>
      ),
    };
  });

  const unAssignShipment = () => {
    const toastCode = Toast.loading("Please wait");
    getElements(`/Shipment/${shipment.shipmentId}/Trip`).then((res) => {
      let trips = res.data;
      if (trips?.length) {
        deleteElement(`/Shipment/Trip/${trips[0]?.tripId}/Assignment`, {}, "", {
          ignoreToast: true,
        }).then((response) => {
          Toast.success(response.response.data, toastCode);
          getReccuringShipment();
          getShipments();
        });
      }
    });
  };

  const delShipment = (id) => {
    deleteElement(`/shipment?shipmentId=${id}`).then(() => {
      getReccuringShipment();
      getShipments();
    });
  };

  const hold = (id) => {
    postElement(`/Shipment/Hold?shipmentId=${id}&IsHold=true`).then(() => {
      getReccuringShipment();
      getShipments();
    });
  };
  const lock = (data) => {
    postElement(
      `/Shipment/Lock?shipmentId=${data.shipmentId}&IsLocked=${!data.isLocked}`
    ).then(() => {
      getReccuringShipment();
      getShipments();
    });
  };

  const undoShipment = () => {
    putElement(`Shipment/${shipment.shipmentId}/Undo`, {}).then((response) => {
      if (response.success) {
        getReccuringShipment();
        getShipments();
      }
    });
  };

  const reLoadComponent = () => {
    getShipments();
    getReccuringShipment();
  };

  return (
    <section>
      <Card style={{ marginTop: 10 }}>
        <CardBody className="shipment-detail-card">
          <CardTitle className="detail-header">
            <p className="shipment-detail-title">Master Shipment</p>
          </CardTitle>
          <div className="recurring-charges">
            <div className="summary-list">
              <DataTable columns={masterShipmentColumns} data={shipmentInfo} />
            </div>
          </div>
        </CardBody>
      </Card>
      <SliderModal
        clickState={shipmentContent}
        setClickState={setShipmentContent}
      >
        <ShipmentDetailsWrapper
          getShipments={reLoadComponent}
          shipmentId={shipId}
          setContent={setShipmentContent}
        />
      </SliderModal>
      <SliderModal
        clickState={assignShipmentOpen}
        setClickState={setAssignShipmentOpen}
      >
        <AssignTripToDriver
          setOpenAssign={setAssignShipmentOpen}
          type={"Trip"}
          data={{
            id: shipment.shipmentId,
            shipmentNumber: shipment.shipmentNumber,
            shipmentType: shipment.shipmentMode,
            shipperName: shipment.shipperName,
          }}
          onAssignCallBack={() => {
            setAssignShipmentOpen(false);
            reLoadComponent();
          }}
          fromShipment={true}
        />
      </SliderModal>
      <UnassignTripModal
        open={openUnAssignModal}
        closeModal={setOpenUnAssignModal}
        unassign={unAssignShipment}
      />
      <UndoTripModal
        open={openUndoModal}
        closeModal={setOpenUndoModal}
        undoTrip={undoShipment}
        title="Undo Shipment"
      />
      <StickySlider
        clickState={duplicateShipmentOpen}
        setClickState={setDuplicateShipmentOpen}
      >
        <DuplicateShipment
          setClickState={setDuplicateShipmentOpen}
          getShipments={reLoadComponent}
          id={currentDuplicateId}
        />
      </StickySlider>

      <StickySlider clickState={manifest} setClickState={setManifest}>
        <ManifestShipment
          setClickState={setManifest}
          getShipments={reLoadComponent}
          manifestData={manifest}
        />
      </StickySlider>

      <SliderModal
        clickState={freightClaimOpen}
        setClickState={setFreightClaimOpen}
      >
        <NewFreightClaim
          setNewFreightClaimOpen={setFreightClaimOpen}
          shipmentDetails={shipment}
        />
      </SliderModal>
      <SliderModal clickState={openCharge} setClickState={setOpenCharge}>
        <ShipmentCharge
          shipmentId={shipmentId}
          setOpenCharge={setOpenCharge}
          getShipments={reLoadComponent}
        />
      </SliderModal>
    </section>
  );
};

export default MasterShipment;
