import  { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { FaTimes } from "react-icons/fa";
import "./shipmentTags.sass";
import { CardTitle } from "reactstrap";
import { getElements } from "services/milerApi";

const ShipmentTags = ({ state, dispatch }) => {
  const [shpTags, setShpTags] = useState([]);
  const [addTag, setAddTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState(
    (state.tags || []).map((tag) => ({
      label: tag.tagName,
      value: tag.tagId,
    }))
  );

  useEffect(() => {
    getElements("/Shipment/Tags").then((r) => {
      const shptgs = r.data.map((tag) => ({
        label: tag.tagName,
        value: tag.tagId,
      }));
      setShpTags(shptgs);
    });
  }, []);

  const loadOptions = (inputValue, callback) => {
    const filteredTags = shpTags.filter((tag) =>
      tag.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (
      inputValue &&
      !filteredTags.some(
        (tag) => tag.label.toLowerCase() === inputValue.toLowerCase()
      )
    ) {
      filteredTags.push({
        label: (
          <div className="new-tag">{`Create new tag "${inputValue}"`}</div>
        ),
        value: inputValue,
        __isNew__: true,
        className: "new-tag",
      });
    }

    callback(filteredTags);
  };

  const onChange = (selectedOptions) => {
    const newTags = selectedOptions.map((option) => {
      if (option.__isNew__) {
        const newTag = { label: option.value, value: option.value };
        dispatch({
          category: "newShipmentTag",
          value: { tagName: newTag.label },
        });
        return newTag;
      }
      if (
        !state.tags.some(
          (existingTag) =>
            existingTag.tagId === option.value ||
            existingTag.tagName?.toLowerCase() === option.label.toLowerCase()
        )
      ) {
        dispatch({
          category: "newShipmentTag",
          value: { tagName: option.label, tagId: option.value },
        });
      }
      return option;
    });

    setSelectedTags((prev) => [
      ...prev,
      ...newTags.filter(
        (tag) => !prev.some((existingTag) => existingTag.value === tag.value)
      ),
    ]);

    setAddTag(false);
  };

  const removeTag = (tagToRemove) => {
    setSelectedTags(
      selectedTags.filter((tag) => tag.value !== tagToRemove.value)
    );
    dispatch({
      category: "deleteShipmentTag",
      index: selectedTags.findIndex((tag) => tag.value === tagToRemove.value),
    });
  };
  return (
    <div className="shipment-tags miler-card my-2">
      <CardTitle className="shipment-detail-header mb-0">
        <p className="shipment-detail-title">Tags</p>
        <div>
          <button
            className="shipment-detail-down-arrow"
            onClick={() => setAddTag((prev) => !prev)}
          >
            <i
              className={`${addTag ? "tag-active" : "tag-inactive"} mil-plus`}
            />
          </button>
        </div>
      </CardTitle>

      <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          {addTag && (
            <AsyncSelect
              isMulti
              loadOptions={loadOptions}
              value={[]}
              onChange={onChange}
              defaultOptions={shpTags}
              classNamePrefix="ship-tags"
              placeholder="Select tags..."
            />
          )}
        </div>
      </div>
      <div className="container">
        <div className="row row_section tags-container mt-1">
          {selectedTags.length > 0
            ? selectedTags.map((tag, index) => {
                const tagInd  = state.tags?.find(
                  (stateTag) => stateTag.tagId === tag.value
                );

                return (
                  <div
                    className={tagInd ? "tag" : "unsavedTag"}
                    key={index}
                  >
                    <span>{tag.label || tag.tagName}</span>
                    <FaTimes
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      onClick={() => removeTag(tag)}
                    />
                  </div>
                );
              })
            : !addTag && <div className="no-tags-title">No tags selected</div>}
        </div>
      </div>
    </div>
  );
};

export default ShipmentTags;
