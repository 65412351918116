import { useContext } from "react";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";

import "./EmptyStop.sass";
import ShipmentInfoBox from "../AvailableTrip/shipment-info-box/ShipmentInfoBox";
import IPCard from "../InprogressTrip/inprogressTripCard/IPCard";

const EmptyStop = ({ stops }) => {
  const { getTrips } = useContext(ActivityContext);

  return (
    <div className="trip-display-body-flex p-2">
      <div>
        {stops.map((stop, index) => (
          <IPCard
            key={index}
            stop={stop}
            index={index}
            stopLength={stops.length}
            getTrips={getTrips}
            handleFetchData={getTrips}
          />
        ))}
      </div>
      <div className="trip-info-right">
        <ShipmentInfoBox />
      </div>
    </div>
  );
};

export default EmptyStop;
