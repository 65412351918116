const {
  default: CellBuilder,
} = require("components/cellBuilderTable/CellBuilder");

const billingColumns = [
  {
    name: "INVOICE #",
    selector: (row) => row.shipment,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.shipment, row.invoiceDate),
  },
  {
    name: "PICK UP",
    selector: (row) => row.pickUpAddressLine,
    grow: 2,
  },
  {
    name: "DROP OFF",
    selector: (row) => row.dropOffAddressLine,
    grow: 2,
  },
  {
    name: "DUE DATE",
    selector: (row) => row.due,
    grow: 2,
  },
  {
    name: "CHARGES",
    selector: (row) => row.charges,
    grow: 2,
  },
  {
    name: "PAID",
    selector: (row) => row.payment,
    grow: 2,
    cell: (row) => CellBuilder(row.payment, row.paymentDate),
  },
  {
    name: "BALANCE",
    selector: (row) => row.balance,
    grow: 2,
    cell: (row) => CellBuilder(row.balance, "", "blue"),
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 2,
  },
];

export default billingColumns;
