import { useEffect, useState } from "react";
import "./DriverDetails.sass";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import MilerButton from "components/milerButton/MilerButton";
import Overview from "./overview/Overview";
import DriverEdit from "./edit/Edit";
import { getElementsV2, putElement } from "services/milerApi";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import joinStrings from "utils/joinStrings";
import Payee from "./payee/Payee";
import dateFormatter from "utils/dateFormater";
import DriverSettlements from "./settlements/DriverSettlements";
import { hasPermission } from "utils/hasPermission";
import Trips from "views/fleet/vehicles/vehicleDetails/trips/Trips";
import Notes from "./notes/Notes";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import Documents from "views/fleet/trailers/trailerDetails/documents/Documents";
import DrugsTest from "./drugTest/Drug";
import BorderedText from "components/bordered-text/BorderedText";
const DriverDetails = ({ setDetails, driverId, getDrivers, defaultTab }) => {
  const [state, setState] = useState();
  const [payee, setPayee] = useState({});
  const [activeTab, setActiveTab] = useState(
    defaultTab
      ? defaultTab
      : getUserInfoCookieProp("subscription_plan") !== "Miler Rectify (Free)"
      ? "Overview"
      : "Edit"
  );
  const [errors, setFormErrors] = useState([]);
  const [payeeErrors, setPayeeErrors] = useState([]);

  const driverFullName = `${state?.firstName} ${state?.lastName} `;
  const [hos, setHos] = useState({});

  const getDriver = () => {
    getElementsV2({
      url: `Driver/id?driverId=${driverId}`,
      setLoading: null,
    }).then((response) => {
      setState({
        payee: {},
        licenseExpirationDate: dateFormatter(
          response.data.licenseExpiration,
          "YYYY-MM-DD",
          true
        ),
        ...response.data,
      });
    });
  };

  const getPayee = () => {
    getElementsV2({
      url: `/Carrier/Payee/${state.payee.accountId}`,
      setLoading: null
    }).then((res) => {
      setPayee({ ...res.data });
    });
  };

  const putPayee = () => {
    putElement(`/Carrier/Payee/${payee.accountId}`, payee).then((response) => {
      if (response.success) {
      } else if (response.vErrors) {
        setPayeeErrors(response.vErrors);
      }
    });
  };

  const putDriver = () => {
    let accountId;
    let address = null;
    let _address = state.address;
    state.driverType === "Owner Operator Driver"
      ? (accountId = state.payee.accountId)
      : (accountId = null);

    if (
      _address &&
      (_address.addressLine ||
        _address.city ||
        _address.state ||
        _address.countryCode)
    )
      address = _address;
    else address = null;
    putElement(`Driver?driverId=${driverId}`, {
      ...state,
      driverNumber: state?.driverNumber?.trim(),
      accountId,
      address,
    }).then((response) => {
      if (response.success) {
        getDrivers();
        setDetails(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  useEffect(getDriver, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      {state && (
        <>
          <div className="slider-display driver-details">
            <div className="slider-display-header">
              <div className="driver-details-header">
                <div className="driver-details-info">
                  <div>
                    <div className="drv-dtls__driver-number-status">
                      <h3 className="drv-dtls__driver-number">
                        {`${capitalizeFirstLetter(
                          state.firstName
                        )} ${capitalizeFirstLetter(state.lastName)}`}
                      </h3>
                      <BorderedText
                        text={state.status}
                        classes={`${
                          state.status === "Inactive"
                            ? "InActive"
                            : state.status
                        }`}
                      />
                    </div>
                    <span className="drv-dtls__info-description">
                      {joinStrings(
                        [state.driverNumber, state.driverType, hos.DutyStatus],
                        " | "
                      )}
                    </span>
                  </div>
                  <i
                    className="mil-close-bg drv-dtls__close"
                    onClick={() => setDetails(false)}
                    style={{ fontSize: "1.8rem", cursor: "pointer" }}
                  />
                </div>
                <div className="driver-content__tabs-container-items driver-details-tabs">
                  <Nav tabs>
                    {getUserInfoCookieProp("subscription_plan") !==
                      "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("driver-content__tabs-item", {
                            active: activeTab === "Overview",
                          })}
                          onClick={() => {
                            toggle("Overview");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={classnames("driver-content__tabs-item", {
                          active: activeTab === "Edit",
                        })}
                        onClick={() => {
                          toggle("Edit");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Edit
                      </NavLink>
                    </NavItem>
                    {getUserInfoCookieProp("subscription_plan") !==
                      "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("driver-content__tabs-item", {
                            active: activeTab === "Payee",
                          })}
                          onClick={() => {
                            toggle("Payee");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Payee
                        </NavLink>
                      </NavItem>
                    )}

                    {getUserInfoCookieProp("subscription_plan") !==
                      "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("driver-content__tabs-item", {
                            active: activeTab === "Trips",
                          })}
                          onClick={() => {
                            toggle("Trips");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Trips
                        </NavLink>
                      </NavItem>
                    )}
                    {getUserInfoCookieProp("subscription_plan") !==
                      "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("driver-content__tabs-item", {
                            active: activeTab === "Settlements",
                          })}
                          onClick={() => {
                            toggle("Settlements");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Settlements
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={classnames("driver-content__tabs-item", {
                          active: activeTab === "DATests",
                        })}
                        onClick={() => {
                          toggle("DATests");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        D&A Test
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("driver-content__tabs-item", {
                          active: activeTab === "Notes",
                        })}
                        onClick={() => {
                          toggle("Notes");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Notes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("driver-content__tabs-item", {
                          active: activeTab === "Documents",
                        })}
                        onClick={() => {
                          toggle("Documents");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="driver-content__header__buttons">
                    {activeTab === "Edit" && (
                      <MilerButton
                        title="Save"
                        icon="mil-save-o"
                        sm
                        onClick={putDriver}
                        btnDark
                        targetActionDenied={!hasPermission("edit.fleet")}
                      />
                    )}
                    {activeTab === "Payee" && (
                      <MilerButton
                        btnDark
                        icon="mil-save-o"
                        sm
                        title="Save"
                        targetActionDenied={!hasPermission("edit.accounting")}
                        onClick={() => putPayee()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-display-body">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="Overview" className="mt-3">
                  <Overview
                    driverId={state.driverId}
                    hos={hos}
                    setHos={setHos}
                  />
                </TabPane>
                {activeTab === "Edit" && (
                  <TabPane tabId="Edit" className="mt-3">
                    <DriverEdit
                      driverId={driverId}
                      state={state}
                      setState={setState}
                      getDrivers={getDrivers}
                      errors={errors}
                    />
                  </TabPane>
                )}
                {activeTab === "Payee" && (
                  <TabPane tabId="Payee" className="mt-3">
                    <div className="slider-display">
                      <Payee
                        getPayee={getPayee}
                        payee={payee}
                        setPayee={setPayee}
                        payeeErrors={payeeErrors}
                      />
                    </div>
                  </TabPane>
                )}

                {activeTab === "Trips" && (
                  <TabPane tabId="Trips" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <Trips
                          id={driverId}
                          setDetails={setDetails}
                          fromDriver={true}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "Settlements" && (
                  <TabPane tabId="Settlements" className="mt-3">
                    <DriverSettlements driverId={driverId} />
                  </TabPane>
                )}
                {activeTab === "Documents" && (
                  <TabPane tabId="Documents" className="mt-3">
                    <Col sm="12">
                      <Documents
                        documentUrl={`Driver/${driverId}`}
                        documentCategory="Fleet"
                      />
                    </Col>
                  </TabPane>
                )}
                {activeTab === "DATests" && (
                  <TabPane tabId="DATests" className="mt-3">
                    <Col sm="12">
                      <DrugsTest
                        id={driverId}
                        driverName={driverFullName}
                        getDrivers={getDrivers}
                      />
                    </Col>
                  </TabPane>
                )}
                {activeTab === "Notes" && (
                  <TabPane tabId="Notes" className="mt-3">
                    <Notes id={driverId} />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DriverDetails;
