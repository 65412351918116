import SearchComp from "components/inputMiler/advanceSearch/SearchComp";
import { toast } from "react-toastify";

const TripVehicleSearch = ({ onItemSelect, label, error, value }) => {
  const searchResultItem = (item) => {
    let items = item.equipment.split(" ");
    let driver = item.driver || {};
    let coDriver = item.coDriver || {};
    const vehicleNumber = items[0];
    let driversInfo = { ids: [], name: driver.name || coDriver.name || "" };
    driver.driverId && driversInfo.ids.push(driver.driverId);
    coDriver.driverId && driversInfo.ids.push(coDriver.driverId);
    driversInfo.driverId = driver.driverId ? driver.driverId : "";
    driversInfo.coDriverId = coDriver.driverId ? coDriver.driverId : "";

    const onSelect = (item) => {
      if (driversInfo.ids.length)
        onItemSelect({
          ...driversInfo,
          vehicleId: item.id,
          driver,
          coDriver,
          vehicleNumber,
        });
      else {
        toast.error("NO Driver Associated With Selected Vehicle!");
      }
    };
    return (
      <div
        className="advance-search__item container"
        key={item.id}
        onClick={() => onSelect(item)}
      >
        <h5 className="advance-search__item-title row">{items[0] || "N/A"}</h5>
        <span className=" row">
          {items[2]},{items[3]}, {items[4]}
        </span>
        <div className="row justify-content-between">
          <section className="column">
            <label>Driver: </label>
            <span>{driver.name}</span>
          </section>
          <section className="column">
            <label>Co-Driver: </label>
            <span>{coDriver.name}</span>
          </section>
        </div>
      </div>
    );
  };
  return (
    <div>
      <SearchComp
        label={label}
        searchResultItem={searchResultItem}
        url={`Equipment/Search?unitType=Vehicle&unitNumber`}
        error={error}
        value={value}
      />
    </div>
  );
};

export default TripVehicleSearch;
