const { default: MilerButton } = require("components/milerButton/MilerButton");
const { useState } = require("react");
const { default: AddNoteModal } = require("./AddNoteModal");
import dateFormatter from "utils/dateFormater";
import "./associatednotes.sass";
import Loading from "components/loading/Loading";

const AssociatedNotes = ({
  notes,
  onAddNote,
  loadingNotes,
  hideSelectOption,
  deleteNote,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onNoteAdded = (note) => {
    onAddNote(note);
  };

  return (
    <div className="mt-4">
      <AddNoteModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onAddNote={onNoteAdded}
        hideSelectOption={hideSelectOption}
      />
      <div className="associated-notes-div">
        <div className="add-note-button-div">
          <span></span>
          <MilerButton
            title="Add Note"
            btnDark
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </div>

        {loadingNotes ? (
          <div>
            <Loading />
          </div>
        ) : (
          <>
            {notes.length === 0 ? (
              <p className="note-center">There are no records to display</p>
            ) : (
              notes?.map((note, index) => (
                <div className="note-div" key={index}>
                  <span className="note-type">
                    {note?.noteType || "General"}

                    <i
                      className="mil-delete-o"
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteNote(notes, index)}
                    />
                  </span>
                  <span className="note">{note.note}</span>
                  <span className="note-date">
                    <span className="note-footer">
                      <span className="note-creator">
                        {" "}
                        <p>{note?.createdBy}</p>
                      </span>
                      <span
                        style={{
                          marginRight: "1.8rem",
                        }}
                      >
                        {" "}
                        {dateFormatter(
                          note?.createdUtc,
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </span>
                    </span>
                  </span>
                </div>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssociatedNotes;
