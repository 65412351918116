import StickySlider from "components/sliderModal/StickySlider";
import { useContext, useEffect, useState } from "react";
import ProcessInvoice from "./displayHeader/ProcessInvoice";
import { CarrierChargeContext } from "../carrierChargeContext/CarrierChargeContext";
import CarrierDisplay from "./carrierDisplay/CarrierDisplay";
import DisplayHeader from "./displayHeader/DisplayHeader";

const CarrierChargeDisplay = ({ status, setStatus }) => {
  const { state, dispatch } = useContext(CarrierChargeContext);
  const [newProcessInvoiceOpen, setNewProcessInvoiceOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "setCurrent", payload: state.carrierCharges[0] });
  }, []);
  useEffect(() => {}, [state.try]);


  return (
    <>
      <DisplayHeader
        carrierCharge={state.current}
        status={status}
        setStatus={setStatus}
        current={state.current}
        setNewProcessInvoiceOpen={setNewProcessInvoiceOpen}
      />
      <CarrierDisplay
        carrierCharges={state.current}
      />
      <StickySlider
        clickState={newProcessInvoiceOpen}
        setClickState={setNewProcessInvoiceOpen}
      >
        <ProcessInvoice
          setProcessInvoiceOpen={setNewProcessInvoiceOpen}
          setStatus={setStatus}
        />
      </StickySlider>
    </>
  );
};

export default CarrierChargeDisplay;
