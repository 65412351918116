//React
import { useContext } from "react";

//views/ActivityContext
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";

//utils/currenyFormatter

import { digitsToCurrency } from "utils/currencyFormatter";

//sass styling
import "../TripDisplay.sass";

export default function Charges() {
  const { currentTrip } = useContext(ActivityContext);

  return (
    <section className="charges">
      <p className="title">Charges</p>
      {currentTrip.shipmentResponse?.charges.map((charge) => (
        <section className="con" key={charge.chargeId}>
          <p className="con_details">{charge.chargeType}</p>
          <p className="con_details">{digitsToCurrency(charge.subTotal)}</p>
        </section>
      ))}
      <section className="final_con">
        <p className="final_con_details">Total</p>
        <p className="final_con_details">
          {digitsToCurrency(currentTrip.shipmentResponse?.totalCharges)}
        </p>
      </section>
    </section>
  );
}
