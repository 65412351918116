function OnOffFilter({ label, on, request, setRequest, fromProp }) {
  return (
    <span
      onClick={() => {
        setRequest({ ...request, [fromProp]: !on, offset: 1 });
      }}
      style={{
        borderRadius: "0.575rem",
        backgroundColor: on ? "#202f52" : "white",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "0.18rem",
        paddingBottom: "0.18rem",
        fontWeight: 400,
        color: on ? "white" : "#202f52",
        marginRight: "1rem",
        cursor: "pointer",
        fontSize: "0.78rem",
        borderWidth: on ? "0px" : "1px",
        borderStyle: "dashed",
        borderColor: on ? "white" : "#202f52",
      }}
    >
      {label}
    </span>
  );
}

export default OnOffFilter;
