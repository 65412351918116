import {useContext, useState} from "react";
import MilerButton from "components/milerButton/MilerButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";

export default function AddDocumentModal({ isOpen, setIsOpen, addDocument }) {
  const { enums } = useContext(appContext);
  const [document, setDocument] = useState({
    documentType: "",
    file: "",
  });

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  const onDocTypeChange = (e) => {
    setDocument({ ...document, documentType: e.target.value });
  };

  const fileChange = (e) => {
    let files = e.target.files;
    const formFile = new FormData();
    formFile.append("Document", files[0]);
    setDocument({ ...document, file: formFile });
  };

  return (
    <div className="">
      <Modal isOpen={Boolean(isOpen)} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Add Document</ModalHeader>
        <ModalBody>
          <InputMiler
            label="Doc Type"
            type="select"
            options={enums.MaintenanceDocumentType}
            onChange={onDocTypeChange}
          />
          <div className="column">
            <InputMiler label="File" type="file" onChange={fileChange} />
          </div>
        </ModalBody>
        <ModalFooter>
          <MilerButton
            icon="mil-save-o"
            title="Save"
            onClick={() => {
              addDocument(document);
              setIsOpen(false);
            }}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
