import InputMiler from "components/inputMiler/InputMiler";
import { useContext, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { appContext } from "services/AppContext";

import MilerButton from "components/milerButton/MilerButton";

const ChargeForm = ({ setOpenForm, state, dispatch, findError, shipment }) => {
  const {
    enums: { ChargeType, Rating },
  } = useContext(appContext);

  const { index, _charge } = state || {};
  let formExists = index >= 0;
  const [charge, setCharge] = useState(_charge);
  const [errors, setFormErrors] = useState([]);
  const modifiedChargeTypes = ChargeType.filter((r) => r.keyName != "TONU");

  const onChange = (e) => {
    let { value, name } = e.target;
    let unit = charge.unit;
    if (name === "rating") {
      if (value === "Flat") {
        unit = 1;
      } else if (value === "Distance") {
        unit = shipment.distance;
      } else if (value === "Weight") {
        unit = shipment.totalWeight;
      }
    }
    setCharge({ ...charge, unit, [name]: value });
    if (name === "rating") {
      if (errors.includes("Rating is Required")) {
        let index = errors.indexOf("Rating is Required");
        errors.splice(index, 1);
      }
    }
    if (name === "chargeType") {
      if (errors.includes("Charge Type is Required")) {
        let index = errors.indexOf("Charge Type is Required");
        errors.splice(index, 1);
      }
    }
    if (name === "rate") {
      if (errors.includes("Rate is Required")) {
        let index = errors.indexOf("Rate is Required");
        errors.splice(index, 1);
      }
    }
  };

  const onSave = () => {
    let subTotal = Math.round(charge.unit * charge.rate * 100) / 100;

    if (!charge.rating) {
      if (!errors.includes("Rating is Required")) {
        setFormErrors([...errors, "Rating is Required"]);
      }
    }
    if (!charge.rate) {
      if (!errors.includes("Rate is Required")) {
        setFormErrors([...errors, "Rate is Required"]);
      }
    }
    if (!charge.chargeType) {
      if (!errors.includes("Charge Type is Required")) {
        setFormErrors([...errors, "Charge Type is Required"]);
      }
    }
    if (charge.chargeType && charge.rate && charge.rating) {
      dispatch({
        category: formExists ? "editCharge" : "newCharge",
        index,
        value: { ...charge, subTotal },
      });
      setOpenForm(false);
    }

  };
  const filteredRating = Rating.reduce((acc, option) => {
    if (charge.chargeType === "Line Haul" && option.keyName === "Percentage") {
      return acc;
    }
    return [...acc, option];
  }, []);
  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">
          {formExists ? "Edit Charge" : "New Charge"}
        </div>
        <div className="slider-header-buttons">
          <MilerButton
            title="cancel"
            onClick={() => {
              setOpenForm(false);
            }}
          />
          <MilerButton btnDark title="Save" onClick={onSave} />
        </div>
      </div>
      <Card className="mb-4 mx-3 slider-display-body">
        <CardBody className="shipment-detail-card">
          <Row>
            <Col lg="6">
              <InputMiler
                type="select"
                options={modifiedChargeTypes}
                value={charge.chargeType}
                label="Charge Type"
                name="chargeType"
                onChange={onChange}
                required
                error={
                  findError(`Charges[${index}].ChargeType`) ||
                  errors.find((e) => e === "Charge Type is Required")
                }
              />
            </Col>
            <Col lg="6">
              <InputMiler
                value={charge.rating}
                label="Rating"
                type="select"
                options={filteredRating}
                name="rating"
                onChange={onChange}
                required
                error={
                  findError(`Charges[${index}].Rating`) ||
                  errors.find((e) => e === "Rating is Required")
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputMiler label="Unit" value={charge.unit} disabled />
            </Col>
            <Col lg="6">
              <InputMiler
                label="Rate"
                type="number"
                value={charge.rate}
                name="rate"
                onChange={onChange}
                required
                error={
                  findError(`Charges[${index}].Rate`) ||
                  errors.find((e) => e === "Rate is Required")
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default ChargeForm;
