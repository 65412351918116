import "./CustomPagination.sass";

const CustomPagination = ({
  request,
  setRequest,
  nextExists = true,
  showTotals = false,
  hundredPlus = false,
  hideRowPerPage = false,
  rowsPerPage = [100, 50, 20],
}) => {
  if (hundredPlus) {
    rowsPerPage = [100, 200, 500];
  }

  const changePage = (type) => {
    let newOffset = request.offset;
    type === "prev" ? newOffset-- : newOffset++;
    if (newOffset > 0) setRequest({ ...request, offset: newOffset });
  };

  const changePerPage = (e) => {
    setRequest({ ...request, limit: e.target.value, offset: 1 });
  };

  return (
    <div className="custom-pagination">
      <div className="custom-pagination-content">
        {!hideRowPerPage && (
          <>
            <span>Rows per page:</span>
            <select onChange={changePerPage} value={request.limit}>
              {rowsPerPage.map((r, index) => (
                <option value={r} key={index}>
                  {r}
                </option>
              ))}
            </select>
          </>
        )}
        <span className="page-number">
          {showTotals
            ? `${
                request.limit * (request.offset - 1) +
                (request.received_records ? 1 : 0)
              } - ${
                request.limit * (request.offset - 1) + request.received_records
              } of ${request.total_records}`
            : "Page: " + request.offset}
        </span>
        <i className="mil-chevron-bg-left" onClick={() => changePage("prev")} />
        <i
          className={`mil-chevron-bg-right ${!nextExists && "disabled"}`}
          onClick={() => nextExists && changePage("next")}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
