import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import "./CompoundInput.sass";

const CompoundInput = ({
  inputValue,
  inputOnchange,
  inputError,
  inputOptions,
  type,
  label,
  disabled,
  selectOnchange,
  selectValue,
  options,
  inputName,
  selectName,
  required,
  selectError,
  selectDisabled,
  normalLabel,
  optionValue,
}) => {
  return (
    <FormGroup className="CompoundInput">
      {label && (
        <>
          <label className={normalLabel && "compound_input_normal_label"}>
            {label}
          </label>
          {required && <span className="required-red">*</span>}
        </>
      )}
      <InputGroup>
        {type === "select" ? (
          <Input
            type="select"
            className="firstInput"
            value={inputValue}
            onChange={inputOnchange}
            name={inputName || ""}
            disabled={disabled}
          >
            {inputOptions.map((item) => (
              <option
                disabled={Boolean(item.disabled)}
                value={item.keyValue || item}
                key={item.keyValue || item}
              >
                {item.keyName || item}
              </option>
            ))}
          </Input>
        ) : (
          <Input
            type={type || "number"}
            className="firstInput"
            value={inputValue}
            onChange={inputOnchange}
            name={inputName}
            invalid={Boolean(inputError)}
            disabled={disabled}
          />
        )}
        <FormFeedback style={{ position: "absolute", top: "100%" }}>
          {inputError}
        </FormFeedback>

        {optionValue ? (
          <>
            <div className="smaller-input">
              <InputGroupText className="groupText">
                {optionValue}
              </InputGroupText>
            </div>
          </>
        ) : (
          <>
            <div className="smaller-input">
              <Input
                type="select"
                className="second-input"
                value={selectValue}
                name={selectName || ""}
                onChange={selectOnchange}
                invalid={Boolean(selectError)}
                disabled={selectDisabled}
              >
                {options &&
                  options.map((item) => (
                    <option
                      disabled={Boolean(item.disabled)}
                      value={item.keyName || item}
                      key={item.keyValue || item}
                    >
                      {item.keyName || item}
                    </option>
                  ))}
              </Input>
              <FormFeedback>{selectError}</FormFeedback>
            </div>
          </>
        )}
      </InputGroup>
    </FormGroup>
  );
};

export default CompoundInput;
