import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { mapStyles } from "constants/mapStyles";
import TrackingMap from "./trackingVehicle/TrackVehicle";
import SnapshotMap from "./snapshotMap/SnapshotMap";
import { API_KEY } from "constants/config";
import "./Map.sass";
import { MdLocationOff } from "react-icons/md";
const MapsMiler = ({
  type,
  tripStops,
  tripId,
  shipmentId,
  eldId,
  tripMapSnapshot,
  shipmentSource,
  getCurrentTrip = () => {},
  showRefreshIcon = false
}) => {
  const containerStyle = {
    width: "100%",
    height: "88vh",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });

  if (shipmentSource == "COYOTE") {
    return (
      <div className="map-unavailable">
        <MdLocationOff />
        <p> Map data not available.</p>
      </div>
    );
  }

  if (type === "snapshots" && isLoaded) {
    return (
      <SnapshotMap
        tripStops={tripStops}
        tripId={tripId}
        shipmentId={shipmentId}
        tripMapSnapshot={tripMapSnapshot}
        shipmentSource={shipmentSource}
        getCurrentTrip={getCurrentTrip}
        showRefreshIcon = {showRefreshIcon}
      />
    );
  }
  if (type === "tracking" || (type === "trackingVehicles" && isLoaded)) {
    return <TrackingMap type={type} eldId={eldId} />;
  }
  return (
    <GoogleMap
      mapContainerClassName="map"
      zoom={4.4}
      center={{
        lat: 41.666574490894995,
        lng: -93.32836457326965,
      }}
      mapContainerStyle={containerStyle}
      options={{
        styles: mapStyles,
      }}
    ></GoogleMap>
  );
};

export default MapsMiler;
