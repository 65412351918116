import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";

const UnassignWarning = ({ closeModal, unassign, openAssign }) => {
  const onSubmit = () => {
    closeModal(false);
    unassign(openAssign);
  };
  return (
    <Modal isOpen={true} toggle={() => closeModal(false)}>
      <ModalHeader toggle={() => closeModal(false)}>
        Unassign Driver
      </ModalHeader>
      <ModalBody>
        <div>
          This driver will be removed from this vehicle. Are you sure you want
          to do this?
        </div>
      </ModalBody>
      <ModalFooter>
        <MilerButton title="Remove Assignment" btnDark onClick={onSubmit} />
      </ModalFooter>
    </Modal>
  );
};

export default UnassignWarning;
