import { useState, useEffect } from "react";
import AssociatedNotes from "components/associatedNotes/AssociatedNotes";
import { getElementsV2, putElement } from "services/milerApi";
const Notes = ({ carrierId }) => {
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(<></>);

  const getNotes = (id) => {
    getElementsV2({
      url: `/Provider/Carrier/${id}/Notes`,
      setLoading: setLoadingNotes,
    }).then((response) => {
      setNotes(response?.data || []);
    });
  };

  const updateNotes = () => {
    putElement(`/Provider/Carrier/${carrierId}/Notes`, notes).then(
      (response) => {
        if (response.success) {
          getNotes(carrierId);
        }
      }
    );
  };

  const deleteNote = (note, index) => {
    note.splice(index, 1);
    setNotes([...note]);
    updateNotes();
    return;
  };

  useEffect(() => {
    if (carrierId) {
      getNotes(carrierId);
    }
  }, []);
  return (
    <>
      <AssociatedNotes
        notes={notes || []}
        onAddNote={(note) => {
          const notess = notes || [];
          notess.unshift(note);
          updateNotes();
          setNotes(notess);
        }}
        loadingNotes={loadingNotes}
        hideSelectOption={true}
        deleteNote={deleteNote}
      />
    </>
  );
};

export default Notes;
