import { stopsContract } from "./shipmentContract";

export default (state, action) => {
  /* if (action && action.initialState) { 
    return action.payload;
  }
  if (action && action.newState) {
    return action.payload;
  } */
  if (action.type === "draftNewShipment") {
    return action.payload;
  }
  if (action.type === "initializeDuplicateShipment") {
    return action.payload;
  }

  if (action && action.category) {
    const { stops, charges, documents, packages, shipmentLading } = JSON.parse(
      JSON.stringify(state)
    );

    switch (action.category) {
      case "deleteCharge":
        state.totalCharges -= charges[action.index].subTotal;
        charges.splice(action.index, 1);
        return { ...state, charges: charges };

      case "newCharge":
        charges.push(action.value);
        let _totalCharges = charges.reduce(
          (total, charge) => charge.subTotal + total,
          0
        );
        return { ...state, totalCharges: _totalCharges, charges: charges };

      case "applyCustomerRatings":
        const updatedCharges = [...charges, ...action.value];
        const totalratings = updatedCharges.reduce(
          (total, charge) => total + charge.subTotal,
          0
        );
        return {
          ...state,
          totalCharges: totalratings,
          charges: updatedCharges,
        };

      case "editCharge":
        charges.splice(action.index, 1, action.value);
        let totalCharges = charges.reduce(
          (total, charge) => charge.subTotal + total,
          0
        );
        return { ...state, totalCharges, charges: charges };

      case "stop":
        let currentStop = stops[action.index];
        if (action.type === "trailer_unselected") {
          currentStop.trailerNumber = null;
          currentStop.trailerId = null;
        }
        if (action.type === "trailerNumber") {
          currentStop.trailerNumber = action.value.trailerNumber;
          if (action.value.trailerId) {
            currentStop.trailerId = action.value.trailerId;
          }
          if (action.value.trailerType)
            currentStop.trailerType = action.value.trailerType;
          stops.forEach((stop) => {
            if (!stop.trailerNumber) {
              stop.trailerNumber = action.value.trailerNumber;
              if (action.value.trailerType)
                stop.trailerType = action.value.trailerType;
              if (action.value.trailerId)
                stop.trailerId = action.value.trailerId;
            }
          });
        } else if (action.type === "trailerType") {
          currentStop.trailerType = action.value;
          if (action.value === "Power Only") {
            currentStop.trailerNumber = null;
            currentStop.trailerId = null;
          }
          stops.forEach((stop) => {
            if (!stop.trailerType) {
              stop.trailerType = action.value;
            }
          });
        } else if (action.type === "earliest_arrival") {
          currentStop.earliest_arrival = action.value;
          //if (!currentStop.latest_arrival)
          currentStop.latest_arrival = action.value;
        } else if (action.type === "earliest_departure") {
          currentStop.earliest_departure = action.value;
          //if (!currentStop.latest_departure)
          currentStop.latest_departure = action.value;
        } else if (action.type === "stopNote") {
          // reset on empty string
          let noteIndex = currentStop.stopNotes.findIndex(
            (stp) => stp.stopNoteType === "Initial"
          );
          noteIndex >= 0 && currentStop.stopNotes.splice(noteIndex, 1);
          currentStop.stopNotes.unshift({
            stopNoteType: "Initial",
            note: action.value,
          });
        } else {
          currentStop[action.type] = action.value;
        }
        return { ...state, stops: stops };

      case "newStopReference":
        let references = stops[action.index].stopReferences;
        references.push(action.value);
        return { ...state, stops: stops };

      case "deleteStopReference":
        let mreferences = stops[action.index].stopReferences;
        mreferences.splice(action.refIndex, 1);
        return { ...state, stops: stops };
      case "stopAddress":
        let stopAddress = stops[action.index].address;
        if (action.type === "addressType")
          stopAddress.addressType = action.value;
        else if (action.type === "locationName") {
          stopAddress.locationName = action.value;
          stopAddress.addressId = 0;
        } else {
          //on address search change
          stops[action.index].address = { ...stopAddress, ...action.value };

          if (state.shipmentType === "Intermodal") {
            if (action.index === 0 && stops.length !== 3) {
              //create new stop
              let newStop = JSON.parse(JSON.stringify(stopsContract[1]));
              //make the stopsequence of the new stop to be 1 greater than the last stopSequense
              newStop.stopSequence =
                (stops[stops.length - 1].stopSequence || stops[stops.length]) +
                1;

              //clone address,trailer type and number values from the first stop
              newStop.address = { ...stopAddress, ...action.value };
              newStop.trailerType = "Intermodal";
              newStop.trailerNumber = stops[action.index].trailerNumber;
              stops.push(newStop);
            } else if (
              (action.index === 0 && stops.length === 3) ||
              //if it's last stop of 3 or more stops
              (action.index === stops.length - 1 && stops.length > 2)
            ) {
              if (action.index === 0) {
                stops[stops.length - 1].address = {
                  ...stopAddress,
                  ...action.value,
                };
                stops[stops.length - 1].trailerType = "Intermodal";
              } else {
                stops[0].address = {
                  ...stopAddress,
                  ...action.value,
                };
                stops[0].trailerType = "Intermodal";
              }
            }
          }
        }
        return { ...state, stops: stops };

      case "newStopShipmentReference":
        let shipref = state.shipmentReferences || [];
        shipref.push(action.value);
        return {
          ...state,
          shipmentReferences: shipref,
        };
      case "deleteStopShipmentReference":
        const { refIndex } = action;
        const updatedShipmentReferences = [...state.shipmentReferences];

        const indexToRemove = updatedShipmentReferences.findIndex(
          (ref) =>
            ref.referenceType === refIndex.referenceType &&
            ref.referenceId === refIndex.referenceId
        );

        if (indexToRemove !== -1) {
          updatedShipmentReferences.splice(indexToRemove, 1);
          return {
            ...state,
            shipmentReferences: updatedShipmentReferences,
          };
        }

        return state;

      case "newStop":
        let newStop = JSON.parse(JSON.stringify(stopsContract[0]));

        // get last stop
        const lastStop = stops.slice(-1)[0];
        //make the stopsequence of the new stop to be 1 greater than the last stopSequense
        newStop.stopSequence = lastStop.stopSequence + 1;

        //clone trailer type and number values from the last stop
        newStop.trailerType = lastStop.trailerType;
        newStop.trailerNumber = lastStop.trailerNumber;

        if (lastStop.stopType == "Pickup") {
          newStop.stopType = "Delivery";
        }

        stops.push(newStop);
        return { ...state, stops: stops };

      case "AddManifestStops":
        //make the stopsequence of the new stop to be 1 greater than the last stopSequense
        let seq = stops.slice(-1)[0].stopSequence + 1;
        action.stops.forEach((s, i) => {
          s.shipmentId = action.shipmentId;
          s.stopSequence = seq + i;
        });
        return { ...state, stops: [...stops, ...action.stops] };

      case "RomoveManifestStops":
        let _stops = stops.filter((s) => s.shipmentId !== action.shipmentId);
        return { ...state, stops: _stops };

      case "deleteStop":
        if (state.stops.length > 1) {
          stops.splice(action.index, 1);
          stops.forEach((stop, index) => (stop.stopSequence = index + 1));
          return { ...state, stops: stops };
        } else {
          stops.splice(0, 1, stopsContract[0]);
          return { ...state, stops: stops };
        }
      case "moveStop":
        let newIndex = action.destinationIndex;
        let oldIndex = action.currentIndex;
        // the moving stop
        let movingStop = stops[oldIndex];

        if (oldIndex !== -1) {
          stops.splice(oldIndex, 1);
        }

        stops.splice(newIndex, 0, movingStop);

        stops.forEach((stop, index) => {
          let newStopSequence = index + 1;
          stops[index].stopSequence = newStopSequence;
        });

        return { ...state, stops: stops };

      case "preserveStopTypesAfterDrag": //This prevents Delivery StopType stop being first and Pickup being last.
        // Last Stop must be Delivery
        if (stops[stops.length - 1]) {
          stops[stops.length - 1].stopType = "Delivery";
        }

        //First Stop Must be Puckup
        if (stops[0]) {
          stops[0].stopType = "Pickup";
        }

        return { ...state, stops };
      case "newDocument":
        documents.push(action.value);
        return { ...state, documents };
      case "deleteDocument":
        documents.splice(action.index, 1);
        return { ...state, documents };
      case "shipper":
        let { shipperId, shipperName } = action.value;
        return { ...state, shipperId, shipperName };
      case "packages":
        let currentPackage = packages[action.index];

        currentPackage[action.type] = action.value;

        return {
          ...state,
          packages: [{ ...currentPackage }],
        };
      case "newShipmentTag":
        return { ...state, tags: [...state.tags, action.value] };
      case "deleteShipmentTag":
        return {
          ...state,
          tags: state.tags.filter((_, index) => index !== action.index),
        };

      case "addShipmentLading":
        const maxLadingNumber =
          state.shipmentLading.length > 0
            ? Math.max(
                ...state.shipmentLading.map((item) => item.ladingNumber || 0)
              )
            : 0;

        const newLadingNumber = maxLadingNumber + 1;
        const newLading = {
          ...action.payload,
          ladingNumber: newLadingNumber,
        };
        return {
          ...state,
          shipmentLading: [...state.shipmentLading, newLading],
        };
      case "removeShipmentLanding":
        const filteredShipmentLading = state.shipmentLading.filter(
          (_, index) => index !== action.itemIndex
        );

        filteredShipmentLading.forEach((item, index) => {
          item.ladingNumber = index + 1;
        });

        return { ...state, shipmentLading: filteredShipmentLading };

      case "editShipmentLanding":
        shipmentLading.splice(action.payload.index, 1, action.payload);
        return { ...state, shipmentLading: [...shipmentLading] };

      case "moveshipmentLanding":
        const reorderedShipmentLading = action.payload;
        reorderedShipmentLading.forEach((item, index) => {
          item.ladingNumber = index + 1;
        });
        return { ...state, shipmentLading: reorderedShipmentLading };

      default:
        return { ...state };
    }
  } else if (action && action.type) {
    if (action.type === "distance") {
      //do nothing
    }
    if (action.type === "shipmentType") {
      const firstStop = state.stops[0] || {};
      const lastStop = state.stops[state.stops.length - 1] || {};
      if (action.value === "Intermodal") {
        state.stops.forEach((stop) => {
          stop.trailerType = "Intermodal";
        });
        if (state.stops.length !== 3 && firstStop.address?.fullAddress) {
          let newStop = JSON.parse(JSON.stringify(stopsContract[1]));
          //make the stopsequence of the new stop to be 1 greater than the last stopSequense
          newStop.stopSequence =
            (lastStop.stopSequence || state.stops[state.stops.length]) + 1;

          //clone address,trailer type and number values from the first stop
          newStop.address = firstStop.address;
          newStop.trailerType = "Intermodal";
          newStop.trailerNumber = firstStop.trailerNumber;
          return {
            ...state,
            stops: [...state.stops, newStop],
            [action.type]: action.value,
          };
        } else if (
          state.stops.length === 3 &&
          firstStop.address?.fullAddress &&
          firstStop.address?.fullAddress !== lastStop.address?.fullAddress
        ) {
          state.stops[state.stops.length - 1].address = firstStop.address;
          return {
            ...state,
            [action.type]: action.value,
          };
        }
      } else return { ...state, [action.type]: action.value };
    }

    return { ...state, [action.type]: action.value };
  } else if (action && action.reset) {
    return { ...action.reset };
  } else {
    return { ...state };
  }
};
