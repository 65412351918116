import { getCookie } from "utils/cookies";

export const stopsContract = [
  {
    stopReferences: [],
    stopSequence: 1,
    stopType: "Pickup",
    stopReason: "",
    trailerType: "",
    trailerNumber: "",
    actualArrival: "",
    actualDeparture: "",
    earliest_arrival: "",
    latest_arrival: "",
    earliest_departure: "",
    latest_departure: "",
    IsDedicatedLane: "",
    stopNotes: [],

    address: {
      addressType: "WorkSite",
      addressLine: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
      timezoneOffset: 0,
      latitude: 0,
      longitude: 0,
    },
  },
  {
    stopSequence: 2,
    stopType: "Delivery",
    trailerType: "",
    trailerNumber: "",
    stopNotes: [],
    actualArrival: "",
    actualDeparture: "",
    earliest_arrival: "",
    latest_arrival: "",
    earliest_departure: "",
    latest_departure: "",
    stopReferences: [],
    address: {
      addressType: "WorkSite",
      addressLine: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
      timezoneOffset: 0,
      latitude: 0,
      longitude: 0,
    },
  },
];

export const chargeContract = {
  chargeType: "Line Haul",
  rating: "Flat",
  unit: 1,
  rate: "",
  subTotal: 0,
};

const getUnit = (unit) => {
  const { isMetric } = getCookie("carrierInfo") || {};
  if (unit === "weight") return isMetric ? "Kilograms" : "Pounds";
  else if (unit === "distance") return isMetric ? "Kilometers" : "Miles";
  return "";
};

const shipmentContract = {
  shipperId: 0,
  shipmentType: "Truckload",
  shipmentNumber: "",
  IsDedicatedLane: false,
  commodity: "",
  weight: 0,
  totalWeight: "",
  unitWeight: getUnit("weight"),
  distance: "",
  unitDistance: getUnit("distance"),
  totalCharges: 0,
  liftgate: false,
  twic: false,
  rollDoor: false,
  tarps: false,
  driverAssist: false,
  team: false,
  highValue: false,
  amount: "",
  hazmat: false,
  appointmentRequired: false,
  appointmentSet: false,
  status: "Available",
  shipmentLading: [],
  tags: [],
  documents: [],
  stops: stopsContract,
  charges: [],
  shipmentReferences: [],
};

export default shipmentContract;
