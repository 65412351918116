import dateFormatter from "./dateFormater";
import dayjs from "dayjs";

// Enable sorting of dates, to seconds precision
const dateSort = (dateA, dateB) => {
  const a = dateFormatter(dateA, "YYYY-MM-DD hh:mm");
  const b = dateFormatter(dateB, "YYYY-MM-DD hh:mm");
  if (a  && b ) {
    // both are present
    const diff = dayjs(a).diff(dayjs(b), "seconds", true);
    if (diff > 1) {
      return 1;
    }
    if (diff < 1) {
      return -1;
    }
    return 0;
  } 
  
};

export default dateSort;
