import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { postElement } from "services/milerApi";
import PayeeForm from "components/payee-form/PayeeForm";
import { hasPermission } from "utils/hasPermission";

export default function NewPayee({ setOpenNewPayee, state, callback }) {
  const [payee, setPayee] = useState(state || {});
  const [errors, setFormErrors] = useState([]);
  const createUser = () => {
    let modifiedState = JSON.parse(JSON.stringify(payee));
    if (modifiedState.payeeRating !== "Distance") {
      //remove emptyMileRates if method is not distance
      delete modifiedState.emptyMileRate;
      delete modifiedState.emptyTeamRate;
    }
    postElement(`/Carrier/Payee`, modifiedState).then((response) => {
      if (response.success) {
        callback({
          label: payee.name || "",
          accountId: response?.response?.data?.data,
        });
        setOpenNewPayee(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Payee</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="cancel"
            onClick={() => {
              setOpenNewPayee(false);
            }}
          />
          <MilerButton
            btnDark
            title="Save"
            targetActionDenied={!hasPermission("create.accounting")}
            onClick={() => createUser()}
          />
        </div>
      </div>
      <div className="slider-display-body">
        <PayeeForm payee={payee} setPayee={setPayee} errors={errors} />
      </div>
    </div>
  );
}
