import axios from "axios";
//import { env, envEnum } from "services/utils/environment";
import dateFormatter from "./dateFormater";
import CookieService from "encrypted-cookie";
import { env } from "services/utils/environment";
import { baseUrls } from "services/milerApi";

export const carrier_and_user_cookies_setup = async (
  accessToken,
  expiration,
  isFirstTry
) => {
  const updateModifiedCookies = (userInfo, carrierInfo, userPermissions) => {
    setCookie("userInfo", userInfo, expiration, true);
    setCookie("carrierInfo", carrierInfo, expiration, true);
    let userPermissionsMini = userPermissions.map((x) => ({
      name: x.name,
      enabled: x.enabled,
    }));
    setCookie("UserPermissions", userPermissionsMini, expiration, true);
  };

  accessToken = isFirstTry ? accessToken : getCookie("cachedJwt")?.accessToken;
  expiration = isFirstTry
    ? expiration
    : (getCookie("cachedJwt")?.expiresIn || 3600) / 3600 / 24;

  //to populate carrierInfo and userInfo cookies
  const baseUrl = baseUrls[env];
  const result = await axios({
    url: baseUrl + `Config/User/Profile`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "X-Miler-UserId": 0,
      "X-Miler-CarrierId": 0,
      version: 2,
    },
  })
    .then((res) => {
      let carrierRes = res.data;
      let carrierExist = carrierRes.carriers ? true : false;
      let defaultCarrier =
        carrierExist && carrierRes.carriers.find((c) => c.isDefault);
      const {
        is_new_federated_user,
        company: company,
        pay_frequency: payFrequency,
        last_pay_utc: lastPayUTC,
        next_pay_utc: nextPayUtc,
        company_logo_url: companyLogoURL,
        hasMarketplace,
        hasCompliance,
        measurement_unit: measurementUnit,
        carrier_id: carrierId,
        given_name,
        family_name,
        email,
        admin_user: admin_user,
        status: userStatus,
        user_id: userId,
        user_type: usertype,
        subscription_plan,
        multicarrier,
        user_permissions,
      } = { ...defaultCarrier, ...carrierRes };
      let userInfo = {
        is_new_federated_user,
        carrierId,
        userId,
        email,
        usertype,
        given_name,
        family_name,
        subscription_plan,
        userStatus,
        multicarrier,
        admin_user,
      };
      let carrierInfo = {
        company,
        carrierStatus: defaultCarrier?.status,
        payFrequency,
        lastPayUTC: dateFormatter(lastPayUTC, "YYYY-MM-DD", true),
        nextPayUtc: dateFormatter(nextPayUtc, "YYYY-MM-DD", true),
        companyLogoURL,
        hasMarketplace,
        hasCompliance, // to be deleted
        isMetric: measurementUnit === "Metric",
      };
      updateModifiedCookies(
        userInfo,
        carrierExist ? carrierInfo : {},
        user_permissions
      );
      return true;
    })
    .catch((res) => {
      isFirstTry
        ? updateModifiedCookies(
            res.response?.status === 404
              ? { notFound: true }
              : { userError: true },
            {},
            []
          )
        : () => {
            //do nothing
          };
      return false;
    });
  return result;
};

export const setCookie = (key, value, expiresInDays) => {
  CookieService.setCookie(key, JSON.stringify(value), expiresInDays);
  //const domain = env == envEnum.localhost || envEnum.local ? "" : "miler.com";
  return;
};

export const doesCookieExist = (key) => {
  return document.cookie.includes(`${key}=`);
};

//invalidates cookies by setting expiry to a past date
export const inValidateCookies = (clearAll) => {
  const allCookies = document.cookie;

  allCookies.split(";").forEach((keyValuePair) => {
    const key = keyValuePair.split("=")[0]?.trim();

    //leave out refreshToken if clearAll is false
    if (clearAll || key != "refreshToken") CookieService.eraseCookie(key);
  });
};

export const startSession = async (jwt) => {
  const { refreshToken, accessToken, expiresIn, expiresAt, refreshAt } = jwt;
  //expiration in expressed in days (because the library takes only days)
  let expiration = expiresIn / 3600 / 24;
  setCookie(
    "cachedJwt",
    { accessToken, expiresIn, expiresAt, refreshAt },
    expiration,
    true
  );
  if (refreshToken) {
    setCookie("refreshToken", refreshToken, expiration, true);
  }

  await carrier_and_user_cookies_setup(accessToken, expiration, true);

  return;
};

export const getCookie = (searchKey) => {
  let cookieValue = CookieService.getCookie(searchKey);
  if (cookieValue) {
    return JSON.parse(cookieValue);
  } else {
    return null;
  }
};
