const percentDeciConverter = (value, type) => {
  if (value === "" || isNaN(value) || value === null) {
    return "";
  }
  if (type === "divide") {
    return (value / 100).toFixed(2);
  } else {
    return (value * 100).toFixed();
  }
};

export default percentDeciConverter;
