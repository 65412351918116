import { useState, useContext } from "react";
import SliderModal from "components/sliderModal/SliderModal";
import NewTrailer from "views/fleet/trailers/newTrailer/NewTrailer";
import NewDriver from "views/fleet/drivers/newDriver/NewDriver";
import NewCustomer from "views/customers/newCustomer/NewCustomer";
import NewShipment from "views/shipments/newShipment/NewShippment";
import NewVehicle from "views/fleet/vehicles/newVehicle/NewVehicle";
import NewEmptyTrip from "views/fleet/vehicles/newEmptyTrip/NewEmptyTrip";
import NewServiceRequest from "views/maintenance/serviceRequest/newServiceRequest/NewServiceRequest";
import StickySlider from "components/sliderModal/StickySlider";
import { Button, Popover, Tooltip } from "antd";
import {
  HiChevronDown,
  HiTruck,
  HiUserAdd,
  HiPlusCircle,
  HiPhoneIncoming,
  HiUserCircle,
} from "react-icons/hi";
import { FaRoute, FaTruckLoading } from "react-icons/fa";
import "./QuickCreate.sass";
import "antd/dist/antd.min.css";
import { appContext } from "services/AppContext";
import classNames from "classnames";
import { hasPermission } from "utils/hasPermission";
import permissionDeniedToast from "utils/permissionDeniedToast";
import { getCookie } from "utils/cookies";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";

const QuickCreate = () => {
  const [slide, setSlide] = useState(false);
  const [fixedSlide, setFixedSlide] = useState(false);
  const [closePop, setClosePop] = useState(true);
  const { isMobile } = useContext(appContext);

  const { subscription_plan } = getCookie("userInfo") || {};

  const emptyFxn = () => {};

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const content = (
    <div className="actions__wrapper">
      {subscription_plan !== "Miler Rectify (Free)" && (
        <div
          className={classNames({
            actions__item: true,
            action_disabled: !hasPermission("create.shipment"),
          })}
          onClick={() => {
            if (!hasPermission("create.shipment")) {
              permissionDeniedToast();
              return;
            }
            setFixedSlide("shipment");
            setClosePop(false);
          }}
        >
          <FaTruckLoading size={20} fill="#202f53" />
          <span>Shipment</span>
        </div>
      )}
      {isFleetOwner ? (
        <></>
      ) : (
        <div
          className={classNames({
            actions__item: true,
            action_disabled: !hasPermission("create.customer"),
          })}
          onClick={() => {
            if (!hasPermission("create.customer")) {
              permissionDeniedToast();
              return;
            }
            setFixedSlide("customer");
            setClosePop(false);
          }}
        >
          <HiUserAdd size={20} fill="#202f53" />
          <span>Customer</span>
        </div>
      )}
      {subscription_plan !== "Miler 4PL" && (
        <>
          {subscription_plan !== "Miler Rectify (Free)" && (
            <div
              className={classNames({
                actions__item: true,
                action_disabled: !hasPermission("create.shipment"),
              })}
              onClick={() => {
                if (!hasPermission("create.shipment")) {
                  permissionDeniedToast();
                  return;
                }
                setSlide("trip");
                setClosePop(false);
              }}
            >
              <FaRoute size={20} stroke="#202f53" />
              <span>Empty Trip</span>
            </div>
          )}
          <div
            className={classNames({
              actions__item: true,
              action_disabled: !hasPermission(
                "create.maintenance.service_request"
              ),
            })}
            onClick={() => {
              if (!hasPermission("create.maintenance.service_request")) {
                permissionDeniedToast();
                return;
              }
              setSlide("serviceRequest");
              setClosePop(false);
            }}
          >
            <HiPhoneIncoming size={20} fill="#202f53" />
            <span>Service Request</span>
          </div>
          <div
            className={classNames({
              actions__item: true,
              action_disabled: !hasPermission("create.fleet"),
            })}
            onClick={() => {
              if (!hasPermission("create.fleet")) {
                permissionDeniedToast();
                return;
              }
              setSlide("driver");
              setClosePop(false);
            }}
          >
            <HiUserCircle size={20} fill="#202f53" />
            <span>Driver</span>
          </div>
          <div
            className={classNames({
              actions__item: true,
              action_disabled: !hasPermission("create.fleet"),
            })}
            onClick={() => {
              if (!hasPermission("create.fleet")) {
                permissionDeniedToast();
                return;
              }
              setSlide("vehicle");
              setClosePop(false);
            }}
          >
            <i
              className="mil-truck-frontal"
              style={{
                fontSize: "20px",
              }}
            />
            <span>Vehicle</span>
          </div>
          <div
            className={classNames({
              actions__item: true,
              action_disabled: !hasPermission("create.fleet"),
            })}
            onClick={() => {
              if (!hasPermission("create.fleet")) {
                permissionDeniedToast();
                return;
              }
              setSlide("trailer");
              setClosePop(false);
            }}
          >
            <HiTruck size={20} fill="#202f53" />
            <span>Trailer</span>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div>
      <Popover
        content={closePop ? content : ""}
        title={closePop ? "CREATE NEW" : ""}
        trigger="click"
      >
        {isMobile ? (
          <section onClick={() => setClosePop(true)}>
            <Tooltip title="Quick Create" trigger="click">
              <HiPlusCircle size={32} fill="#202f53" />
            </Tooltip>
          </section>
        ) : (
          <section onClick={() => setClosePop(true)}>
            <Button
              type="primary"
              style={{ backgroundColor: "#202f53", border: "none" }}
              shape="round"
            >
              Quick Create
              <HiChevronDown
                size={20}
                fill="white"
                style={{ marginTop: "-2px", marginLeft: "2px" }}
              />
            </Button>
          </section>
        )}
      </Popover>
      <StickySlider
        clickState={Boolean(fixedSlide)}
        setClickState={setFixedSlide}
      >
        {fixedSlide === "shipment" && (
          <NewShipment setClickState={setFixedSlide} getShipments={emptyFxn} />
        )}
        {fixedSlide === "customer" && (
          <NewCustomer setNewCustomerOpen={setFixedSlide} fxn={emptyFxn} />
        )}
      </StickySlider>

      <SliderModal clickState={Boolean(slide)} setClickState={setSlide}>
        {slide === "trip" && <NewEmptyTrip setNewEmptyTripOpen={setSlide} />}
        {slide === "driver" && <NewDriver setNewDriver={setSlide} />}
        {slide === "serviceRequest" && (
          <div>
            <NewServiceRequest setNewServiceOpen={setSlide} />
          </div>
        )}
        {slide === "vehicle" && (
          <NewVehicle setNewVehicle={setSlide} getVehicles={emptyFxn} />
        )}
        {slide === "trailer" && (
          <NewTrailer setNewTrailer={setSlide} getTrailers={emptyFxn} />
        )}
      </SliderModal>
    </div>
  );
};

export default QuickCreate;
