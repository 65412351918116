import MilerButton from "components/milerButton/MilerButton";
import { BsExclamationTriangle } from "react-icons/bs";
import "./WarningBox.sass";
const WarningBox = ({ title, icon, showBtn, btnitle, btnOnclick }) => {
  return (
    <main className="warning_box">
      <section className="account_rebill_warning_box">
        <section className="text">{title}</section>
        {icon && (
          <section className="ico">
            <BsExclamationTriangle
              style={{
                color: "#FA4747",
                width: "22px",
                height: "22px",
                marginLeft: "5px",
              }}
            />
          </section>
        )}
        {showBtn && <MilerButton title={btnitle} onClick={btnOnclick} sm btnDark />}
      </section>
    </main>
  );
};

export default WarningBox;
