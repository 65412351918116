import { useState, createContext, useContext } from "react";
import { getElementsV2 } from "services/milerApi";
import { getGatewayApi } from "services/gatewayApis";
import { ToolBarContext } from "layout/toolbar/context/ToolbarContext";

const ActivityContext = createContext();

const ActivityProvider = ({ children }) => {
  const [displayType, setDisplayType] = useState("trip");

  const { dispatch } = useContext(ToolBarContext);

  const [currentTrip, setCurrentTrip] = useState({});
  const [currentTripLoading, setCurrentTripLoading] = useState(<></>);
  const [tripsLoading, setTripsLoading] = useState(<></>);
  const [trips, setTrips] = useState([]);

  const [currentDriver, setCurrentDriver] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [driversCal, setDriversCal] = useState([]);
  const [request, setRequest] = useState({
    pickup_from: "",
    pickup_to: "",
    dropoff_from: "",
    dropoff_to: "",
    limit: 200,
    offset: 1,
    salesrep_user_id: "",
    territory_id: "",
  });

  const getTrips = async () => {
    const queryStr = new URLSearchParams(request).toString();
    dispatch({ type: "resetCount", payload: "Activities" });
    const res = await getElementsV2({
      url: `/Shipment/Trips?${queryStr}`,
      setLoading: setTripsLoading,
    });
    dispatch({
      type: "setCurrent",
      payload: {
        current: "Activities",
        count: res?.data?.length,
      },
    });
    setTrips(res?.data || []);
  };
  const getTrip = (id) => {
    if (id) {
      getElementsV2({
        url: `/Shipment/Trip/Id?id=${id}`,
        setLoading: setCurrentTripLoading,
      }).then((res) => {
        res?.data?.stops?.sort((a, b) => a.stopSequence - b.stopSequence);
        setCurrentTrip(res?.data || {});
      });
    }
  };

  const getDriverCal = () => {
    getGatewayApi(`carrier/drivers/cal`)
      .then((res) => setDriversCal(res.data))
      .catch(() => {});
  };

  const getDrivers = () =>
    getElementsV2({
      url: "/Driver?status=A",
      setLoading: null,
    }).then((res) => setDrivers(res?.data || []));

  const getDriver = (id) =>
    getElementsV2({
      url: `/Driver/id?driverId=${id}`,
      setLoading: null,
    }).then((res) => setCurrentDriver(res?.data || {}));

  return (
    <ActivityContext.Provider
      value={{
        displayType,
        trips,
        tripsLoading,
        setTripsLoading,
        currentTrip,
        currentTripLoading,
        setCurrentTripLoading,
        driversCal,
        setDisplayType,
        getTrips,
        getTrip,
        getDriverCal,
        drivers,
        currentDriver,
        getDriver,
        getDrivers,
        request,
        setRequest,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

export { ActivityContext, ActivityProvider };
