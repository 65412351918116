import { useState, useEffect } from "react";
import MilerButton from "components/milerButton/MilerButton";

import CustomerRatingTable from "./CustomerRatingTable";
import Loading from "components/loading/Loading";
import { getElements } from "services/milerApi";
const CustomerRatingForm = ({
  id,
  dispatch,
  setShowCustomerRatingForm,
  shipperId

}) => {
  const [customerRatingData, setCustomerRatingData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [loading, setLoading] = useState(Loading);
  const getCustomerRating = () => {
    getElements(`/Shipper/${shipperId}/Rating?shipment_id=${id}`)
      .then((response) => {
        if (response.status === 200) {
          setCustomerRatingData(response.data || []);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerRating();
  }, []);
  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Customer Rating</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setShowCustomerRatingForm(false);
            }}
          />
          <MilerButton
            btnDark
            title="Apply"
            onClick={() => {
              dispatch({
                category: "applyCustomerRatings",
                value: selectedRows,
              });
              setShowCustomerRatingForm(false);
            }}
          />
        </div>
      </div>
      <CustomerRatingTable
        customerRatingData={customerRatingData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        loading={loading}
      />
    </div>
  );
};

export default CustomerRatingForm;
