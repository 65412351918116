import { Card, Col } from "reactstrap";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import { digitsToCurrency } from "utils/currencyFormatter";
import DataTable from "react-data-table-component";
import MilerButton from "components/milerButton/MilerButton";

export const FreightClaimCosts = ({
  state,
  setCostFormOpen,
  dispatch,
  setCostForEdit,
}) => {
  const colums = [
    {
      name: "TYPE",
      selector: (row) => CellBuilder(row.type),
      grow: 2,
    },
    {
      name: "VENDOR",
      selector: (row) => CellBuilder(row.vendor),
      grow: 2,
    },
    {
      name: "INVOICE #",
      selector: (row) => CellBuilder(row.invoiceNumber),
      grow: 2,
    },
    {
      name: "COST",
      selector: (row) => CellBuilder(digitsToCurrency(row.cost || 0)),
      grow: 2,
      sortable: true,
    },
    {
      name: "REIMB.",
      selector: (row) => CellBuilder(digitsToCurrency(row.reimbursement || 0)),
      grow: 2,
    },
    {
      name: "ACTIONS",
      selector: (row, index) => (
        <div className="table-actions">
          <i
            className="mil-edit"
            style={{ cursor: "pointer", fontSize: "17px", margin: "5px" }}
            onClick={() => {
              setCostForEdit({ row, index });
              setCostFormOpen(true);
            }}
          />

          <i
            className="mil-delete-o"
            style={{ cursor: "pointer", fontSize: "17px", margin: "5px" }}
            onClick={() =>
              dispatch({ action: "removeCost", value: row.invoiceNumber })
            }
          />
        </div>
      ),
      grow: 2,
    },
  ];

  return (
    <div className="mt-3">
      <Card style={{ padding: "10px" }}>
        <p className="shipment-detail-title m-2">Costs</p>
        <DataTable columns={colums} data={state?.costs} />

        <Col lg={2}>
          <MilerButton
            title="Add Cost"
            icon="mil-plus"
            onClick={() => {
              setCostFormOpen(true);
            }}
          />
        </Col>
      </Card>
    </div>
  );
};

export default FreightClaimCosts;
