export const freightClaimsContract = {
  claimType: null,
  shipmentNumber: null,
  shipmentId: null,
  sealNumber: null,
  sealIntact: null,
  isInsuranceNotified: null,
  driverId: 0,
  vehicleId: 0,
  trailerId: 0,
  driverName: null,
  unitNumber: null,
  trailerNumber: null,
  claimUtc: null,
  costs: [
    //   {
    //     "freightCostId": 0,
    //     "invoiceNumber": null,
    //     "cost": 0,
    //     "reimbursement": 0,
    //     "vendor": null
    //   }
  ],
  notes: null,
  documents: [
    //   {
    //     "freightDocumentId": 0,
    //     "documentUrl": null,
    //     "createdUtc": "2022-11-23T18:13:21Z",
    //     "documentType": 0
    //   }
  ],
};
