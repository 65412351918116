import { Avatar } from "antd";
import classNames from "classnames";
import { useState } from "react";
import dateFormatter from "utils/dateFormater";

const DriversList = ({ drivers, onDriverSelected, getColor }) => {
  const [selectedDriver, setSelectedDriver] = useState("");

  return (
    <div className="drivers-list">
      {drivers.map((driver, i) => (
        <div
          className={classNames({
            "driver-item": true,
            blue: selectedDriver ? selectedDriver == driver.user_id : i == 0,
          })}
          key={`${driver.user_id}`}
          onClick={() => {
            driver.bgColor = getColor(i+1)
            onDriverSelected(driver);
            setSelectedDriver(driver.user_id);
          }}
        >
          <Avatar
            size={40}
            style={{
              backgroundColor: getColor(i+1),
              fontWeight: "500",
              color: "#202F53"
            }}            
          >
            {driver.first_name.charAt(0)}
            {driver.last_name.charAt(0)}
          </Avatar>
          <div className="message-details">
            <div className="driver-name">
              {driver.first_name} {driver.last_name}
            </div>
            <p className="m-1">{driver.driver_number}</p>
          </div>
          <div className="message-date">
            { dateFormatter(driver.updated_utc, "MMM DD")}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DriversList;
