import { useState, useReducer } from "react";
import "./ShipmentDetails.sass";
import BorderedText from "components/bordered-text/BorderedText";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import MilerButton from "components/milerButton/MilerButton";
import ShipmentRecentActivity from "views/shipments/shipmentDetails/shipmentRecentActivity/ShipmentRecentActivity";
import ShipmentOverview from "views/shipments/shipmentDetails/shipmentOverview/ShipmentOverview";
import EditShipment from "views/shipments/shipmentDetails/shipmentEdit/ShipmentEdit";
import Documents from "./documents/Documents";
import shipmentReducer from "views/shipments/shipmentForm/reducer";
import Messages from "./messages/Messages";
import MapsMiler from "components/mapMiler/MapsMiler";
import { hasPermission } from "utils/hasPermission";
import ShipmentCharges from "./charges/ShipmentCharges";
import SliderModal from "components/sliderModal/SliderModal";
import DeliverTrip from "views/activities-v2/activityDisplay/tripDisplay/tripMenu/DeliverTrip";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import permissionDeniedToast from "utils/permissionDeniedToast";
import Loading from "components/loading/Loading";

const ShipmentDetails = ({
  shptDtls,
  closeSLider,
  deleteShipment,
  lockShipment,
  updateShipment,
  getShipment,
  getShipments,
  updateShipmentCharges,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [errors, setFormErrors] = useState([]);
  const [shipment, dispatch] = useReducer(shipmentReducer, shptDtls);
  const [tripiId, setTripId] = useState();
  const [eldId, setEldId] = useState("");
  const [deliverShipmentOpen, setDeliverShipmentOpen] = useState(false);

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  const allStopsComplete = shptDtls.stops?.every(
    (shptStop) => shptStop.status == "Completed"
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="slider-display shipment-details">
      <div className="slider-display-header">
        <div className="shipment-details-header">
          <div className="shipment-details-info">
            <div>
              <div className="shp-dtls__shipment-number-status">
                <h3 className="shp-dtls__shipment-number">
                  {shptDtls.shipmentNumber}
                </h3>{" "}
                <BorderedText
                  text={shptDtls.status}
                  classes={shptDtls.status}
                />
              </div>
              <span className="shp-dtls__info-description">
                {`${shptDtls.shipperName} | ${shptDtls.shipmentType} | ${
                  shptDtls.weight
                } ${shptDtls.unitWeight === "Pounds" ? "lb" : ""} | $${
                  shptDtls.totalCharges
                }`}
              </span>
            </div>
            <i className="mil-close-bg shp-dtls__close" onClick={closeSLider} />
          </div>
          <div className="shipment-content__tabs-container-items shipment-details-tabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "Overview",
                  })}
                  onClick={() => {
                    toggle("Overview");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "Edit",
                  })}
                  onClick={() => {
                    if (!shptDtls.isLocked) {
                      toggle("Edit");
                    } else {
                      permissionDeniedToast();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Edit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "Charges",
                  })}
                  onClick={() => {
                    toggle("Charges");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Charges
                </NavLink>
              </NavItem>
              <NavItem>
                {shptDtls.status === "Inprogress" && (
                  <NavLink
                    className={classnames("shipment-content__tabs-item", {
                      active: activeTab === "Map",
                    })}
                    onClick={() => {
                      toggle("Map");
                    }}
                    disabled={!eldId}
                    title={eldId ? "" : "ELD is not setup"}
                  >
                    Map
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "Documents",
                  })}
                  onClick={() => {
                    toggle("Documents");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "RecentActivity",
                  })}
                  onClick={() => {
                    toggle("RecentActivity");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Recent Activity
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={classnames("shipment-content__tabs-item", {
                    active: activeTab === "Messages",
                  })}
                  onClick={() => {
                    toggle("Messages");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Messages
                </NavLink>
              </NavItem> */}
            </Nav>
            <div className="shipment-content__header__buttons">
              {activeTab == "Overview" &&
                allStopsComplete &&
                tripiId &&
                isFleetOwner &&
                shptDtls.status == "Inprogress" && (
                  <MilerButton
                    btnDark
                    title="Deliver"
                    onClick={() => setDeliverShipmentOpen(true)}
                  />
                )}
              <MilerButton
                icon={
                  shptDtls.isLocked ? "mil-lock-opened-o" : "mil-lock-closed-o"
                }
                sm
                title={shptDtls.isLocked ? "Unlock" : "Lock"}
                targetActionDenied={!hasPermission("edit.shipment")}
                onClick={lockShipment}
              />
              <MilerButton
                icon="mil-void-o"
                title="Void"
                sm
                targetActionDenied={!hasPermission("delete.shipment")}
                onClick={deleteShipment}
              />
              {(activeTab === "Edit" || activeTab == "Charges") && (
                <MilerButton
                  title="Save"
                  icon="mil-save-o"
                  sm
                  targetActionDenied={!hasPermission("edit.shipment")}
                  onClick={() => {
                    if (activeTab == "Edit") {
                      updateShipment(shipment, setFormErrors);
                    } else {
                      updateShipmentCharges(shipment?.charges, setFormErrors);
                    }
                  }}
                  btnDark
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="slider-display-body">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Overview">
            <Row>
              <Col sm="12">
                {loading ? (
                  <Loading />
                ) : (
                  <ShipmentOverview
                    shptDtls={shptDtls}
                    updateShipment={updateShipment}
                    setEldId={setEldId}
                    setTripId={setTripId}
                    getShipment={() => {
                      getShipment();
                      getShipments();
                    }}
                  />
                )}
              </Col>
            </Row>
          </TabPane>

          {activeTab == "Edit" && (
            <TabPane tabId="Edit">
              <Row>
                <Col sm="12">
                  <EditShipment
                    shipment={shipment}
                    errors={errors}
                    dispatch={dispatch}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab == "Charges" && (
            <TabPane tabId="Charges">
              <Row>
                <Col sm="12">
                  <ShipmentCharges
                    errors={errors}
                    dispatch={dispatch}
                    shipment={shipment}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab == "Map" && (
            <TabPane tabId="Map">
              <Row>
                <Col sm="12">
                  <MapsMiler eldId={eldId} type="tracking" />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab == "Documents" && (
            <TabPane tabId="Documents">
              <Row>
                <Col sm="12">
                  <Documents
                    shptDtls={shptDtls}
                    shipmentId={shptDtls.shipmentId}
                    updateShipment={updateShipment}
                  />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab == "RecentActivity" && (
            <TabPane tabId="RecentActivity">
              <Row>
                <Col sm="12">
                  <ShipmentRecentActivity shptDtls={shptDtls} />
                </Col>
              </Row>
            </TabPane>
          )}

          {activeTab == "Messages" && (
            <TabPane tabId="Messages">
              <Row>
                <Col sm="12">
                  <Messages shptDtls={shptDtls} />
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>

      <SliderModal
        clickState={deliverShipmentOpen}
        setClickState={setDeliverShipmentOpen}
      >
        <DeliverTrip
          setDeliverTripOpen={() => {
            setDeliverShipmentOpen(false);
            getShipment();
            getShipments();
            closeSLider();
          }}
          shipmentId={shptDtls.shipmentId}
          tripId={tripiId}
        />
      </SliderModal>
    </div>
  );
};

export default ShipmentDetails;
