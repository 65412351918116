import {
  getApi,
  postApi,
  putApi,
  deleteApi,
} from "services/utils/axiosWrapper";
import { Toast } from "./Toast";

const apiConfig = {
  baseUrls: {
    local: "https://dev-servicerepair.miler.com/",
    dev: "https://dev-servicerepair.miler.com/",
    qa: "https://dev-servicerepair.miler.com/",
    staging: "https://dev-servicerepair.miler.com/",
    prod: "https://dev-servicerepair.miler.com/",
  },
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 6000,
};

const errorsHandler = (error, toastCode) => {
  let vErrors; //validation errors if they exist
  if (error.response) {
    let response = error.response;
    if (response.status === 400) {
      let title =
        response.title ||
        response.data.errors[0]?.errorMessage ||
        "400: Validation Errors";
      Toast.error(title, toastCode);
      vErrors = response.data.errors;
    } else {
      let title = response.title || `${response.status}: Error`;
      Toast.error(title, toastCode);
    }
  } else {
    Toast.error("Unhandle error: something went wrong", toastCode);
  }
  return { success: false, vErrors };
};

const successHandler = (response, toastCode, options) => {
  let { title, data } = response.data;
  let message = title || data || response.data;
  if (options && options.ignoreToast) {
  } else if (options && options.customToast) {
    Toast.success(options.customToast);
  } else {
    Toast.success(message.toString(), toastCode);
  }
  return { success: true, response, data };
};

export async function getElements(url) {
  return await getApi(url, apiConfig);
}

export async function putElement(url, data, version, options) {
  let toastCode;
  if (!options || !options.ignoreToast) {
    toastCode = Toast.loading("Please wait");
  }
  return putApi(url, apiConfig, data, version)
    .then((response) => successHandler(response, toastCode))
    .catch((error) => errorsHandler(error, toastCode));
}

export async function postElement(url, data) {
  const toastCode = Toast.loading("Please wait");
  return postApi(url, apiConfig, data)
    .then((response) => successHandler(response, toastCode))

    .catch((error) => errorsHandler(error, toastCode));
}
export async function deleteElement(url) {
  const toastCode = Toast.loading("Please wait");
  return deleteApi(url, apiConfig, {})
    .then((response) => successHandler(response, toastCode))
    .catch((error) => {
      Toast.error(error.response, toastCode);
    });
}
