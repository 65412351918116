import InputMiler from "components/inputMiler/InputMiler";
import { BsCheckLg, BsXLg } from "react-icons/bs";

export const PackagingInfoForm = ({
  packagingInfo,
  enums,
  handleInputChange,
  newPackage,
  cancelEdit,
}) => {
  return (
    <div className="shipment-package-form-item-summary">
      <div>
        <i className="mil-menu" />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.ladingNumber || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="ladingNumber"
          autoComplete="off"
          disabled
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.packageType || ""}
          type="select"
          options={enums.PackageType}
          onChange={(e) => handleInputChange(e)}
          name="packageType"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.weight || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="weight"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.quantity || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="quantity"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.length || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="length"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.width || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="width"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.height || ""}
          type="number"
          onChange={(e) => handleInputChange(e)}
          name="height"
          autoComplete="off"
        />
      </div>
      <div>
        <InputMiler
          value={packagingInfo.volume || ""}
          type="number"
          name="volume"
          autoComplete="off"
          disabled
        />
      </div>
      <div className="table-actions">
        <BsCheckLg className="package-icons" onClick={newPackage} style={{color: "#2ECD7D"}} />
        <BsXLg className="package-icons" onClick={cancelEdit} />
      </div>
    </div>
  );
};
