import PayeeForm from "components/payee-form/PayeeForm";
import { useEffect } from "react";

const Payee = ({ getPayee, payee, setPayee, payeeErrors }) => {
  useEffect(getPayee, []);

  return (
    <div className="slider-display-body">
      <>
        <PayeeForm
          payee={payee}
          setPayee={setPayee}
          errors={payeeErrors}
          disablePayee={true}
        />
      </>
    </div>
  );
};

export default Payee;
