import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import getStatusColor from "utils/getStatusColor";

const alcoholColumns = [
  {
    name: "TEST TYPE",
    selector: (row) => row.testType,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.testType),
  },
  {
    name: " ORDERED DATE",
    selector: (row) => row.orderedUtc,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.orderedUtc),
  },
  {
    name: "TEST DATE",
    selector: (row) => row.alcoholTestUtc,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.alcoholTestUtc),
  },
  {
    name: "STATUS",
    selector: (row) => row.alcoholTestStatus,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(        <BorderedText
      classes={getClasses(row.alcoholTestStatus)}
      text={row.alcoholTestStatus}
    />),
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 1.4,
  },
];
export const drugColumns = [
  {
    name: "TEST TYPE",
    selector: (row) => row.testType,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.testType),
  },
  {
    name: " ORDERED DATE",
    selector: (row) => row.orderedUtc,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.orderedUtc),
  },
  {
    name: "TEST DATE",
    selector: (row) => row.drugTestUtc,
    sortable: true,
    grow: 2,
    cell: (row) => CellBuilder(row.drugTestUtc),
  },
  {
    name: "STATUS",
    selector: (row) => row.drugTestStatus,
    sortable: true,
    grow: 2,
    cell: (row) =>
      CellBuilder(
        <BorderedText
          classes={getClasses(row.drugTestStatus)}
          text={row.drugTestStatus}
        />
      ),
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,
    grow: 1.4,
  },
];
export default alcoholColumns;

const getClasses = (status) => {
  return getStatusColor(
    ["Pending", "Scheduled", "Fail", "Pass"],
    ["status_orange", "status_cyan", "status_red", "status_green1"],
    status
  );
};
