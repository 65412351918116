import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import InputMiler from "components/inputMiler/InputMiler";
import { Col } from "reactstrap";
import { postElement } from "services/milerApi";
import DateTimeInput from "components/datetime-input/DateTimeInput";

export default function DetentionInfo({ setEditDeteintion, updateTrip, stop }) {
  const [detentionTime, setDetentionTime] = useState("");

  let detentionAction =
    stop.detentionStartUTC && !stop.detentionEndUTC ? "End" : "Start";

  const detainTrip = () => {
    postElement(
      `/Shipment/Detention?detentionAction=${detentionAction}&createdUTC=${detentionTime}&stopId=${stop.stopId}`
    ).then(() => {
      updateTrip();
      setEditDeteintion(false);
    });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Detention</div>
        <div>
          <MilerButton
            title="Cancel"
            onClick={() => setEditDeteintion(false)}
          />
          <MilerButton title="Save" onClick={detainTrip} btnDark />
        </div>
      </div>
      <div className="slider-display-body">
        <div className="miler-card">
          <div className="miler-card-title">Detention</div>
          <div className="row">
            <Col lg="6">
              <InputMiler
                label="Detention Type"
                value={detentionAction}
                type="text"
                name="detentionAction"
                disabled
              />
            </Col>
            <Col lg="6">
              <DateTimeInput
                label="Earliest Arrival"
                value={detentionTime}
                onChange={(value) => {
                  setDetentionTime(value);
                }}
              />
            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}
