import { useEffect, useState } from "react";
import { getElementsV2 } from "services/milerApi";
import "./associatedTrips.sass";
import ShipmentTrips from "./ShipmentTrips";

const AssociatedTrips = ({ shipmentId, setTripId }) => {
  const [trips, setTrips] = useState([]);
  const getTrips = () => {
    getElementsV2({
      url: `/Shipment/${shipmentId}/Trip`,
      setLoading: null
    }).then((res) => {
      let trips = res?.data;
      if (trips?.length) {
        setTrips(trips);
        if (setTripId) {
          setTripId(trips[0].tripId);
        }
      }
    });
  };
  useEffect(getTrips, []);

  return (
    <div className="ship-associated-trips-cont">
      <strong>TRIPS</strong>
      <div className="ship-associated-trips">
        {trips[0] && <ShipmentTrips trip={trips[0]} getTrips={getTrips} />}
        {trips[1] && <div className="associated-trips-separator"></div>}
        {trips[1] && <ShipmentTrips trip={trips[1]} getTrips={getTrips} />}
      </div>
    </div>
  );
};

export default AssociatedTrips;
