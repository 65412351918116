const removeUnfilledObjs = (state) => {
  let copiedState = JSON.parse(JSON.stringify(state));
  let editableState = JSON.parse(JSON.stringify(state));
  const nullCheck = ['shipperName', 'shipperAlias', 'primaryEmail', 'usdot', 'scac', 'ien', 'shipperWebsite'];
  /* if (!editableState.billingContact.phones[0].phoneNumber) {
    delete editableState.billingContact.phones;
    let foundValue = Object.values(editableState.billingContact).find((i) => i);
    if (!foundValue) copiedState.billingContact = null;
  }
  if (!editableState.mailingContact.phones[0].phoneNumber) {
    delete editableState.mailingContact.phones;
    let foundValue = Object.values(editableState.mailingContact).find((i) => i);
    if (!foundValue) copiedState.mailingContact = null;
  } */

  nullCheck.forEach((prop) => {
    if (!editableState[prop]) {
      copiedState[prop] = null;
    }
  });
  if (!Object.values(editableState.mailingContact || {}).find((i) => i))
    copiedState.mailingContact = null;
  if (!Object.values(editableState.billingContact || {}).find((i) => i))
    copiedState.billingContact = null;

  if (!Object.values(editableState.mailingAddress || {}).find((i) => i))
    copiedState.mailingAddress = null;
  if (!Object.values(editableState.billingAddress || {}).find((i) => i))
    copiedState.billingAddress = null;
  return copiedState;
};

export default removeUnfilledObjs;
