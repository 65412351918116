import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import MilerButton from "components/milerButton/MilerButton";
import { useEffect, useState } from "react";
import { postElement } from "services/serviceRepairApi";
import "./NewServiceRequest.sass";

import ServiceRequestForm from "../serviceRequestForm/ServiceRequestForm";
import { getGatewayApi } from "services/gatewayApis";

// const stripePromise = loadStripe(
//   "pk_test_51Jh6tqEK49ZIwYV1Hatw7jEO7d4ASUCCGxJKlOGUwDSVxyirEPLPyCClhpPE95PV872otAayZbxONzjSnUta5f6I00nMLcSLWu"
// );

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51Jh6tqEK49ZIwYV1Hatw7jEO7d4ASUCCGxJKlOGUwDSVxyirEPLPyCClhpPE95PV872otAayZbxONzjSnUta5f6I00nMLcSLWu"
    );
  }
  return stripePromise;
};

const NewServiceRequest = ({
  setNewServiceOpen,
  selectedVehicle,
  newVehicle,
  callBack,
  getRequests,
}) => {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [payment, setPayment] = useState({ payment_details: {} });
  const [paymentTrigger, setPaymentTrigger] = useState(false);
  useEffect(() => {
    getGatewayApi("carrier/customer/payment_setup").then(({ data }) =>
      setPayment(data)
    );
  }, [paymentTrigger]);
  const [errors, setFormErrors] = useState([]);
  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key.replace(/[.,\s]/g, "").toLowerCase() ===
        inputError.replace(/[.,\s]/g, "").toLowerCase()
    );
    return error ? error.errorMessage : false;
  };
  const [duplicatedError, setDuplicatedError] = useState("");
  const [delay, setDelay] = useState(0);
  const [selectedServiceCodes, setSelectedServiceCodes] = useState([]);
  const [phoneNumberValue, setPhoneNumberValue] = useState();
  const [, setLocations] = useState([
    {
      gps: [
        {
          latitude: 0,
          longitude: 0,
        },
      ],
    },
  ]);

  const setUpVehicle = () => {
    let {
      unitId,
      unitNumber,
      vehicleType,
      make,
      model,
      year,
      vin,
      unitType,
      tireSize,
    } = selectedVehicle || {};
    return {
      unitId: unitId || "",
      unitNumber: unitNumber || "",
      vehicleType: vehicleType || "",
      make: make || "",
      model: model || "",
      year: year || "",
      vin: vin || "",
      unitType: unitType || "",
      tireSize: tireSize || "",
    };
  };
  const [vehicle, setVehicle] = useState(setUpVehicle);
  const [providers, setProviders] = useState([]);
  const [address, setAddress] = useState({ addressLine: "" });
  const [gps, setGps] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [mapGps, setMapGps] = useState({
    latitude: 41.666574490894995,
    longitude: -93.32836457326965,
  });
  const [serviceLocation, setServiceLocation] = useState({
    unitDrivable: "Yes",
    locationType: "",
    serviceRequestedUTC: "",
    phone: "",
  });
  const [docList, setDocList] = useState([]);
  const [posting, setPosting] = useState({
    servicePosting: "Market Place",
    note: "",
    vendorOption: "Any Vendor",
  });

  const addDoc = (document) => {
    setDocList([...docList, document]);
  };
  const delDoc = (index) => {
    const mdoDoc = docList.splice(index, 1);
    setDocList(mdoDoc);
  };

  const handleSubmit = () => {
    const vendor =
      posting.servicePosting === "Other" ? { serviceProviderId: 1 } : {};

    let serCod = [];
    selectedServiceCodes.map((m) =>
      m.services.map((s) => {
        serCod = [
          ...serCod,
          {
            description: s.code,
            item: s.code,
            serviceId: s.serviceId,
          },
        ];
      })
    );

    let service = {
      ...vehicle,
      ...serviceLocation,
      locationType: serviceLocation.locationType,
      ...vendor,
      ...posting,
      serviceRequestNotes: posting.note,
      serviceRequestItems: serCod,
      documents: docList,
      address,
      geolocation: {
        longitude: (address && address.longitude) || 0,
        latitude: (address && address.latitude) || 0,
        heading: 0,
      },
    };
    service.unitDrivable = service.unitDrivable === "Yes" ? true : false;
    postElement("/ServiceRequest", service).then((response) => {
      if (response.success) {
        setNewServiceOpen(false);
        getRequests();
        callBack();
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  return (
    <Elements stripe={getStripe()}>
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Service Request</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="cancel"
              onClick={() => {
                setNewServiceOpen(false);
              }}
            />

            <MilerButton
              btnDark
              title="Save"
              onClick={() => handleSubmit()}
              disabled={!payment.payment_details.isComplete}
            />
          </div>
        </div>
        <ServiceRequestForm
          errors={errors}
          setFormErrors={setFormErrors}
          findError={findError}
          duplicatedError={duplicatedError}
          setDuplicatedError={setDuplicatedError}
          selectedServiceCodes={selectedServiceCodes}
          setSelectedServiceCodes={setSelectedServiceCodes}
          delay={delay}
          setDelay={setDelay}
          phoneNumberValue={phoneNumberValue}
          setPhoneNumberValue={setPhoneNumberValue}
          locations={setLocations}
          vehicle={vehicle}
          setVehicle={setVehicle}
          providers={providers}
          setProviders={setProviders}
          address={address}
          setAddress={setAddress}
          mapGps={mapGps}
          setMapGps={setMapGps}
          serviceLocation={serviceLocation}
          setServiceLocation={setServiceLocation}
          docList={docList}
          setDocList={setDocList}
          posting={posting}
          setPosting={setPosting}
          addDoc={addDoc}
          delDoc={delDoc}
          newVehicle={newVehicle}
          gps={gps}
          setGps={setGps}
          payment={payment}
          setPayment={setPayment}
          setPaymentTrigger={setPaymentTrigger}
          paymentTrigger={setPaymentTrigger}
          paymentLoading={paymentLoading}
          setPaymentLoading={setPaymentLoading}
        />
      </div>
    </Elements>
  );
};

export default NewServiceRequest;
