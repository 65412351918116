import ClaimDetails from "./ClaimDetails";
import GeneralDetails from "./GeneralDetails";
import "./FreightClaimForm.sass";
import FreightClaimCosts from "./Costs";
import Documents from "./Documents";
import Notes from "./Notes";
import SliderModal from "components/sliderModal/SliderModal";
import CostForm from "./CostForm";
import { useState } from "react";


const FreightClaimForm = ({ dispatch, state, errors }) => {

  const [costFormOpen, setCostFormOpen] = useState(false)
  const [costForEdit, setCostForEdit] = useState({})

  return (
    <div>
      <ClaimDetails
        dispatch={dispatch}
        state={state}
        errors={errors}
      />

      <GeneralDetails
        dispatch={dispatch}
        state={state}
        errors={errors}
      />

      <FreightClaimCosts
        state={state}
        setCostFormOpen={setCostFormOpen}
        dispatch={dispatch}
        setCostForEdit={setCostForEdit}
      />

      <Documents state={state} dispatch={dispatch} />

      <Notes state={state} dispatch={dispatch} />

      <SliderModal
        clickState={costFormOpen}
        setClickState={setCostFormOpen}
        from="bottom"
        height={50}
      >
        <CostForm setCostFormOpen={setCostFormOpen} dispatch={dispatch} costForEdit={costForEdit} />
      </SliderModal>

    </div>
  );
};

export default FreightClaimForm;
