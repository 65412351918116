import "./ServiceProviderSearch.sass";
import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getElements } from "services/serviceRepairApi";
import { useEffect } from "react";
import NewServiceProvider from "../serviceProvider/newServiceProvider/NewServiceProvider";

const ServiceProviderSearch = ({ error, value, onSelect = () => {} }) => {
  const [openNewProvider, setOpenNewProvider] = useState(false);
  const [_selectedProvider, setSelectedProvider] = useState(value);
  const [providers, setProviders] = useState([]);

  const [searchedProviders, setSearchedProviders] = useState([]);

  const onChange = (foundProvider) => {
    if (foundProvider) {
      if (foundProvider.__isNew__) {
        setOpenNewProvider(foundProvider.label);
        return;
      }
      setSelectedProvider(foundProvider);

      onSelect(foundProvider);
    } else {
      //clear input & selected value
      let newValue = {};
      setSelectedProvider(newValue);
      onSelect(newValue);
    }
  };

  const onProviderCreated = (newProvider) => {
    getProviders(newProvider);
  };

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = (createdProvider = false) => {
    if (value.label) {
      setSelectedProvider(value);
    }
    getElements(`/ServiceProvider`).then((res) => {
      setProviders(res.data);

      if (createdProvider) {
        const createdProviderApi = res.data.find(
          (item) => item.name === createdProvider.serviceProviderName
        );
        createdProviderApi.label = createdProviderApi.name;
        onChange(createdProviderApi);
      }
    });
  };

  const onInputChange = (value) => {
    const modProvider = providers.filter((p) => p.name.includes(value));
    setSearchedProviders(
      modProvider.map((p) => ({
        ...p,
        label: p.name || "",
      }))
    );
  };

  return (
    <div>
      <label className="m-0">Provider Name</label>

      <CreatableSelect
        isClearable={true}
        onChange={onChange}
        onInputChange={onInputChange}
        options={searchedProviders}
        value={_selectedProvider}
        styles={
          error && {
            control: (provided) => ({
              ...provided,
              borderColor: "red",
            }),
          }
        }
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <SliderModal
        clickState={Boolean(openNewProvider)}
        setClickState={setOpenNewProvider}
      >
        <NewServiceProvider
          setNewProviderOpen={setOpenNewProvider}
          onProviderCreated={onProviderCreated}
          customerName={openNewProvider}
        />
      </SliderModal>
    </div>
  );
};

export default ServiceProviderSearch;
