import { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardFooter,
} from "reactstrap";
import miler from "assets/logo/logo.png";
import { MultiStepForm, Step } from "react-multi-form";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import "./ServiceRequest.sass";
import background from "../background2.jpg";
import { postGatewayApi } from "services/gatewayApis";

const ServiceRequest = () => {
  const [active, setActive] = useState(1);
  const [finished, setFinished] = useState(false);
  const [state, setState] = useState({
    description: "",
    unitType: "Tractor",
    make: "",
    model: "",
    year: 0,
    vin: "",
    odometer: "",
    tireSize: "",
    isUnitDrivable: true,
    address: {
      addressLine: "",
      city: "",
      state: "",
      postalCode: "",
      countryCode: "",
      phone: "",
      latitude: 41.666574490894995,
      longitude: -93.32836457326965,
    },
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    receiveNotification: false,
    locationType: "Roadway",
    vendorOption: "Dealer",
  });

  const createRequest = () => {
    postGatewayApi("https://dev-servicerepair.miler.com/ServiceRequest/Rectify", state).then(() => {
      setFinished(true);
    });
  };
  
  const goToNextPage = () => {
    if (active === 1) {
      if (state.description !== "") {
        setActive(active + 1);
      } else {
        return;
      }
    }
    if (active === 2) {
      if (
        state.make === "" ||
        state.model === "" ||
        state.vin === "" ||
        state.odometer === "" ||
        state === ""
      ) {
        return;
      } else {
        setActive(active + 1);
      }
    }
    if (active === 3) {
      if (state.address.addressLine === "") {
        return;
      } else {
        setActive(active + 1);
      }
    }
    if (active === 4) {
      if (
        state.firstName === "" ||
        state.lastName === "" ||
        state.phone === "" ||
        state.email === ""
      ) {
        return;
      } else {
        setActive(active + 1);
      }
    }
  };

  return (
    <>
      <div className="public_service_request_container">
        {finished ? (
          <div className="public_service_request_card_container">
            <Col lg="8" md="10" className=" offset-md-2 offset-lg-2 my-4">
              <Card className="public_service_request shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={miler} alt="Miler LLC" />
                  </div>
                </CardHeader>

                <CardBody className="px-lg-5 py-lg-5">
                  <h1 className="public_service_request_success">
                    Your request has been successfully submitted!
                  </h1>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn btn-miler"
                    onClick={() =>
                      window.location.replace("https://app.miler.com/")
                    }
                    style={{ float: "right", cursor: "pointer" }}
                  >
                    Discover Miler!
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </div>
        ) : (
          <div className="public_service_request_card_container">
            <Col lg="8" md="10" className=" offset-md-2 offset-lg-2 my-4">
              <Card className="public_service_request shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={miler} alt="Miler LLC" />
                  </div>
                </CardHeader>

                <CardBody className="px-lg-5 py-lg-5">
                  <MultiStepForm activeStep={active} accentColor="#efa324">
                    <Step label="Help">
                      <StepOne state={state} setState={setState} />
                    </Step>
                    <Step label="Equipment">
                      <StepTwo state={state} setState={setState} />
                    </Step>
                    <Step label="Location">
                      <StepThree state={state} setState={setState} />
                    </Step>
                    <Step label="Personal Info">
                      <StepFour
                        state={state}
                        setState={setState}
                        createRequest={createRequest}
                      />
                    </Step>
                  </MultiStepForm>
                </CardBody>
                <CardFooter>
                  {active !== 1 && (
                    <Button
                      className="btn btn-miler"
                      onClick={() => setActive(active - 1)}
                    >
                      Previous
                    </Button>
                  )}
                  {active !== 4 && (
                    <Button
                      className="btn btn-miler"
                      onClick={() => {
                        goToNextPage(active);
                      }}
                      style={{ float: "right" }}
                    >
                      Next
                    </Button>
                  )}
                  {active === 4 && (
                    <Button
                      className="btn btn-miler"
                      onClick={() => createRequest()}
                      style={{ float: "right" }}
                    >
                      Submit
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Col>
          </div>
        )}
        <img src={background} className="public_service_request_image" alt="bg_image" />
      </div>
    </>
  );
};

export default ServiceRequest;
