// google map API key
export const API_KEY =
  window.location.hostname === "app.miler.com"
    ? "AIzaSyB1VrVAgA8fbKIsdtsUNkNHSgxGm3CmblU"
    : "AIzaSyD-TNuz_N5lhRXSUDeGFokYkOpkgkZ9t6w";

// Shipment info URL
export const SHIPMENT_INFO_URL =
  "https://dev-carrier.miler.com/Shipment/Assignment";

// API REQUEST TIMEOUT
export const QUERY_CACHE_TIMEOUT = 60000; // ---> 10 SECONDS
export const AXIOS_TIMEOUT = 10000; // ---> 10 SECONDS

// CARRIER API URL API END POINT
export const CARRIER_URL = "https://dev-carrier.miler.com/";

// API Version
export const API_VERSION = "2.0";
