import CellBuilder from "components/cellBuilderTable/CellBuilder";

export const carriersColumns = [
  {
    name: "Carrier Name",
    selector: (row) => row.company,
    cell: (row) => CellBuilder(row.company, "", "", row.companyLogo),
  },
  {
    name: "Mailing Address",
    selector: (row) => row.mailingStreet,
    cell: (row) => CellBuilder(row.mailingStreet, row.mailingRegion),
  },
  {
    name: "Billing Address",
    selector: (row) => row.billingStreet,
    cell: (row) => CellBuilder(row.billingStreet, row.billingRegion),
  },

  {
    name: "USDOT / MC Number",
    selector: (row) => row.usdot,
    cell: (row) => CellBuilder(row.usdot, row.mcNumber),
    grow: 0.8,
  },
  {
    name: "ACTIONS",
    selector: (row) => row.actions,

    grow: 0.5,
  },
];
