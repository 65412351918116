import { useEffect, useState, useReducer } from "react";

import MilerButton from "../../../../components/milerButton/MilerButton";
import ShipmentForm from "../../shipmentForm/ShipmentForm";
import { postElement, getElementsV2 } from "services/milerApi";
import shipmentContract, {
  chargeContract,
} from "views/shipments/shipmentForm/shipmentContract";
import shipmentReducer from "views/shipments/shipmentForm/reducer";
import AddPartialCard from "./AddPartialCard";
import "./ManifestShipment.sass";

const ManifestShipment = ({ setClickState, getShipments, manifestData }) => {
  const [iniState, setIniState] = useState(false);

  const [shipment, dispatch] = useReducer(shipmentReducer, shipmentContract);
  const [errors, setFormErrors] = useState([]);
  const [selectedShipmentIds, setSelectedShipmentIds] = useState([
    manifestData.shipmentId,
  ]);
  const postShipment = () => {
    postElement(`/Shipment/Manifest`, {
      ...shipment,
      orderIds: selectedShipmentIds,
    }).then((response) => {
      if (response.success) {
        setFormErrors([]);
        getShipments();
        setClickState(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const onCheckShipment = (e, id, removeUncheckedShipmentRow) => {
    if (e.target.checked) {
      getAddedShipmentStops(id);
      setSelectedShipmentIds([...selectedShipmentIds, id]);
    } else {
      removeUncheckedShipmentRow();
      const modifiedIds = selectedShipmentIds.filter((s) => s !== id);
      setSelectedShipmentIds(modifiedIds);
      dispatch({
        category: "RemoveManifestStops",
        shipmentId: id,
      });
    }
  };

  const modifyStops = (stops) => {
    let emptyStopTimes = {
      actualArrival: "",
      actualArrivalLocal: "",
      actualDeparture: "",
      actualDepartureLocal: "",
      detentionEndUTC: "",
      detentionStartUTC: "",
    };

    return stops.map((stop) => ({
      ...stop,
      ...emptyStopTimes,
      address: { ...stop.address, addressId: 0 },
    }));
  };

  const getAddedShipmentStops = (id) => {
    id &&
      getElementsV2({
        url: `/Shipment/Id?shipmentId=${id}`,
        setLoading: null
      }).then((response) => {
        let stops = response.data?.stops || {};
        stops = modifyStops(stops);
        dispatch({
          category: "AddManifestStops",
          shipmentId: id,
          stops: stops,
        });
      });
  };
  useEffect(() => {
    manifestData?.shipmentId &&
      getElementsV2({
        url: `/Shipment/Id?shipmentId=${manifestData.shipmentId}`,
        setLoading: null
      })
        .then((response) => {
          let shipmentMod = response?.data;

          shipmentMod.stops.sort((a, b) => a.stopSequence - b.stopSequence);

          let shipmentModStop = modifyStops(shipmentMod?.stops || {});

          shipmentMod.stops = shipmentModStop;
          dispatch({
            type: "initializeDuplicateShipment",
            payload: {
              ...shipmentMod,
              shipmentNumber: "",
              shipmentType: "Truckload",
              shipperName: "",
              shipperId: -1,
              totalCharges: 0,
              charges: [chargeContract],
              documents: [],
              shipmentId: "",
            },
          });
          setIniState(true);
        })
  }, []);

  return (
    shipment !== undefined &&
    iniState && (
      <div className="slider-display manifest-shipment">
        <div className="slider-display-header">
          <div className="slider-header-title">
            Manifest Shipment ({(selectedShipmentIds || []).length})
          </div>
          <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
            <MilerButton
              icon="mil-close-bg"
              title="Cancel"
              onClick={() => {
                setClickState(false);
              }}
            />
            <MilerButton
              btnDark
              icon="mil-save-o"
              title="Save"
              onClick={postShipment}
            />
          </div>
        </div>

        <div className="slider-display-body">
          <AddPartialCard
            manifestData={manifestData}
            selectedShipmentIds={selectedShipmentIds}
            onCheckShipment={onCheckShipment}
          />
          <ShipmentForm
            shipment={shipment}
            errors={errors}
            dispatch={dispatch}
            isManifest={true}
          />
          <div className="d-flex justify-content-end add-shipment-btn">
            <MilerButton btnDark title="Save" onClick={postShipment} />
          </div>
        </div>
      </div>
    )
  );
};

export default ManifestShipment;
