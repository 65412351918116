export default (state, action) => {
  switch (action.type) {
    case "initialCarrierProfile":
      return {
        ...state,
        carrierProfile: action.payload,
      };
    case "initialFactoring":
      return {
        ...state,
        factoring: action.payload,
      };
    case "changeMainCarrierProfile":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          [action.payload.name]: action.payload.value,
        },
      };
    case "changeFactoring":
      return {
        ...state,
        factoring: {
          ...state.factoring,
          [action.payload.name]: action.payload.value,
        },
      };
    case "mailingAddress":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          mailingAddress: action.payload,
        },
      };
    case "mailingAddressFactoring":
      return {
        ...state,
        factoring: {
          ...state.factoring,
          mailingAddress: action.payload,
        },
      };
    case "billingAddress":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          billingAddress: action.payload,
        },
      };
    case "billingAddressFactoring":
      return {
        ...state,
        factoring: {
          ...state.factoring,
          billingAddress: action.payload,
        },
      };
    case "billingContactFactoring":
      return {
        ...state,
        factoring: {
          ...state.factoring,
          billingContact: {
            ...state.factoring.billingContact,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case "carrierSettingResponse":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          carrierSettingResponse: {
            ...state.carrierProfile.carrierSettingResponse,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case "carrierSettingResponseFactoring":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          carrierSettingResponse: {
            ...state.carrierProfile.carrierSettingResponse,
            factoringCompanyResponse: action.payload,
          },
        },
      };
    case "carrierSettingResponseFactoringReset":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          carrierSettingResponse: {
            ...state.carrierProfile.carrierSettingResponse,
            factoringCompanyResponse: {
              billingAddress: {
                addressLine: "",
              },
              billingContact: {},
            },
          },
        },
      };
    case "uploadUrl":
      const uploadHeader = action.payload ? "https://" : "";
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          companyLogoURL: uploadHeader + action.payload,
        },
      };
    case "errorResponse":
      return {
        ...state,
        errors: action.payload,
      };
    case "carrierSettingResponsePercent":
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          carrierSettingResponse: {
            ...state.carrierProfile.carrierSettingResponse,
            [action.payload.name]: parseInt(action.payload.value) / 100,
          },
        },
      };
    case "addCarrierContact": {
      const contacts = state.carrierProfile?.contacts || [];
      contacts.push(action.payload);
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          contacts,
        },
      };
    }

    case "editCarrierContact": {
      const contacts = state.carrierProfile?.contacts || [];
      const newContactIndex = action.payload.index;
      delete action.payload.index;
      contacts[newContactIndex] = action.payload;
      return {
        ...state,
        carrierProfile: {
          ...state.carrierProfile,
          contacts,
        },
      };
    }

    case "removeCarrierContact": {
      state.carrierProfile?.contacts.splice(action.itemIndex, 1) || [];
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
