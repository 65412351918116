import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import { useState } from "react";
import NewCharge from "./NewCharge";
import RecurringTable from "./RecurringTable";
import "./recurring-charges.sass";

const RecurringCharges = ({ recurring, updateCharges, getPayee }) => {
  const [openForm, setOpenForm] = useState(false);

  const updateRecurringList = (action) => {
    const { charge, type, index } = action;
    const modifiedRecurring = JSON.parse(JSON.stringify(recurring));

    if (type === "delete") {
      modifiedRecurring.splice(index, 1);
    }
    if (type === "edit") {
      modifiedRecurring.splice(index, 1, charge);
    }
    if (type === "add") {
      modifiedRecurring.push(charge);
    }

    updateCharges(modifiedRecurring);
  };

  return (
    <div className="miler-card mt-0 recurring-charges">
      <div className="miler-card-title">Recurring Charges</div>
      {recurring?.length ? (
        <RecurringTable
          recurring={recurring}
          setOpenForm={setOpenForm}
          updateRecurringList={updateRecurringList}
          getPayee={getPayee}
        />
      ) : (
        <div className="no-record">No charges added.</div>
      )}
      <MilerButton
        title="Add Recurring Charge"
        icon="mil-plus"
        onClick={() => setOpenForm(true)}
      />

      <SliderModal
        clickState={openForm}
        setClickState={setOpenForm}
        from="bottom"
        height={50}
      >
        <NewCharge
          setOpenForm={setOpenForm}
          updateRecurringList={updateRecurringList}
        />
      </SliderModal>
    </div>
  );
};

export default RecurringCharges;
