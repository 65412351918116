import MilerButton from "components/milerButton/MilerButton";
import { useState, useEffect } from "react";
import DATestsForm from "./DATestsForm";
import { postElement, getElementsV2, putElement } from "services/milerApi";

const NewDATests = ({
  setNewTestOpen,
  refetchSummary,
  driverName,
  driverId,
  drugId,
  activeTab,
}) => {
  const [state, setState] = useState({});
  const [errors, setErrors] = useState([]);
  const [driver, setDriver] = useState({ driverId, driverName });
  const [testCategory, setTestCategory] = useState("");

  const handleChange = ({ name, value }) => {
    if (name == "driver") {
      setDriver({
        driverId: value.id,
        driverName: value?.driver?.driver,
      });
      return;
    }

    if (name == "testCategory") {
      setTestCategory(value);
      return;
    }

    setState({ ...state, [name]: value });
  };

  const postDATest = () => {
    let hasErrors = false;
    if (!driver?.driverId) {
      setErrors([
        ...errors,
        { key: "driverId", errorMessage: "Driver is required" },
      ]);
      hasErrors = true;
    }

    if (!testCategory) {
      setErrors([
        ...errors,
        { key: "testCategory", errorMessage: "Test category is required" },
      ]);
      hasErrors = true;
    }

    if (hasErrors) return;

    postElement(`/Compliance/Driver/${driver?.driverId}/${testCategory}`, state)
      .then((response) => {
        if (response.vErrors) {
          setErrors(response.vErrors);
        } else {
          refetchSummary();
          setNewTestOpen(false);
        }
      })
      .catch(() => {});
  };

  const updateDATest = () => {
    const tabName = activeTab === "1" ? "Drug" : "Alcohol";

    let hasErrors = false;

    if (!testCategory) {
      setErrors([
        ...errors,
        { key: "testCategory", errorMessage: "Test category is required" },
      ]);
      hasErrors = true;
    }

    if (hasErrors) return;

    putElement(`/Compliance/Driver/${tabName}/${drugId}`, state)
      .then((response) => {
        if (response.vErrors) {
          setErrors(response.vErrors);
        } else if(response.success) {
          setNewTestOpen(false);
          refetchSummary();
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    const tabName = activeTab === "1" ? "Drug" : "Alcohol";
    if (drugId) {
      getElementsV2({
        url:`/Compliance/Driver/${tabName}/${drugId}`,
        setLoading: null
      }).then(
        (response) => {
          if (response.success) {
            setState({
              ...state,
              ...response.data,
            });
          }
          setTestCategory(activeTab === "1" ? "Drug" : "Alcohol");
        }
      );
    }
  }, [drugId, activeTab]);

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="d-flex flex-column">
          <div className="slider-header-title">
            {drugId ? "Edit Drug/Alcohol Test" : "New Drug/Alcohol Test"}
          </div>
        </div>

        <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
          <MilerButton
            icon="mil-close-bg"
            title="Cancel"
            onClick={() => {
              setNewTestOpen(false);
            }}
          />
          <MilerButton
            btnDark
            icon="mil-save-o"
            title="Save"
            onClick={() => {
              if (drugId) {
                updateDATest();
              } else {
                postDATest();
              }
            }}
          />
        </div>
      </div>

      <div className="slider-display-body">
        <DATestsForm
          state={state}
          errors={errors}
          dispatch={handleChange}
          driver={driver}
          setTestCategory={setTestCategory}
          testCategory={testCategory}
          drugId={drugId}
        />
      </div>
    </div>
  );
};

export default NewDATests;
