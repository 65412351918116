import { createContext, useReducer } from "react";
import carrierChargesReducer from "./carrierChargeReducer";

const CarrierChargeContext = createContext();

const CarrierChargeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(carrierChargesReducer, {
    initialCarrierCharges: [],
    initialCurrentCarrierCharges: {},
    carrierCharges: [],
    current: {},
    currentId: 0,
    checkedCarrierCharges: [],
    checkedProcessCarrierCharges: [],
    try: 0,
    allApprovedChecked: false,
    processVerify: false,
    selectedApprovedCarrierCharges: [],
    currentArId: 0,
    searchedValue: "",
  });

  const filterCarrierCharges = (searchedValue) => {
    dispatch({ type: "filterCarrierCharges", payload: searchedValue });
  };

  return (
    <CarrierChargeContext.Provider
      value={{
        state,
        dispatch,

        filterCarrierCharges,
      }}
    >
      {children}
    </CarrierChargeContext.Provider>
  );
};

export { CarrierChargeContext, CarrierChargeProvider };
