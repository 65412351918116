import BorderedText from "components/bordered-text/BorderedText";
import MilerButton from "components/milerButton/MilerButton";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import { useEffect, useState } from "react";
import { getElementsV2 } from "services/milerApi";
import UnassignWarning from "../../UnassignWarningModal";
import { postElement } from "services/milerApi";
import "./Assigment.sass";
import AssignVehicleModal from "../../vehicleSummary/AssignVehicleModal";
import SliderModal from "components/sliderModal/SliderModal";
import DriverEditDetails from "views/activities-v2/driverEditDetails/DriverEditDetail";

const Assignment = ({ vehicle, getVehicle, getVehicles }) => {
  const [driver, setDriver] = useState({ address: {}, payee: {} });
  const [coDriver, setCoDriver] = useState({ address: {}, payee: {} });
  const [openAssign, setOpenAssign] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [driverDetails, setDriverDetails] = useState(false);
  const [driverDetailsId, setDriverDetailsId] = useState(0);

  const { driverId, coDriverId, vehicleId, unitNumber } = vehicle;

  const openAssignModal = () => {
    const driverName = `${driver.firstName || ""}${driver.lastName || ""}`;
    const codriverName = `${coDriver.firstName || ""}${
      coDriver.lastName || ""
    }`;

    setIsOpen({
      unitNumber,
      vehicleId,
      driver: { name: driverName, driverId: driver.driverId },
      coDriver: { name: codriverName, driverId: coDriver.driverId },
    });
  };
  const getDriver = () => {
    getElementsV2({
      url: `/Driver/id/?driverId=${driverId}`,
      setLoading: null
    }).then((response) =>
      setDriver(response.data || { address: {}, payee: {} })
    );
  };
  const getCoDriver = () => {
    getElementsV2({
      url: `/Driver/id/?driverId=${coDriverId}`,
      setLoading: null
    }).then((response) =>
      setCoDriver(response.data || { address: {}, payee: {} })
    );
  };
  useEffect(() => {
    driverId && getDriver();
    coDriverId && getCoDriver();
  }, [vehicleId]);

  const unassign = (type) => {
    let vehId = vehicleId || "";
    let drId = driverId || "";
    let coId = coDriverId || "";
    if (type === "driver") {
      drId = "";
      if (coId) {
        drId = coId;
        coId = "";
      }
    } else if (type === "codriver") {
      coId = "";
    }
    postElement(
      `/Equipment/VehicleAssignment?vehicleId=${vehId}&driverId=${drId}&coDriverId=${coId}`,
      {}
    )
      .then(() => getVehicle())
      .then(() => getVehicles())
      .then(() => {
        setDriver({ address: {}, payee: {} });
        setCoDriver({ address: {}, payee: {} });
      });
  };

  /* const assignVehicle = () => {
    if (mainDriver.driverId || deputyDriver.driverId)
      postElement(
        `/Equipment/VehicleAssignment?vehicleId=${vehicleId}&driverId=${mainDriver.driverId}&coDriverId=${deputyDriver.driverId}`,
        {}
      )
        .then(closeModal)
        .then(getVehicles);
  }; */
  return (
    <div className="vehicle-assigment-container">
      {driverId ? (
        <div className="driver_card">
          <div className="title">
            <span>Driver</span>
            <MilerButton
              title="Remove Assignment"
              sm
              btnDark
              onClick={() => setOpenAssign("driver")}
            />
          </div>
          <div className="body">
            <div className="info">
              <ProfilePlaceholder
                firstName={driver?.firstName}
                lastName={driver?.lastName}
              />
              <div className="desc">
                <div className="name">
                  {driver.firstName} {driver.lastName}{" "}
                  <span
                    className="driver_number_link"
                    onClick={() => {
                      setDriverDetailsId(driver.driverId);
                      setDriverDetails(true);
                    }}
                  >
                    {" "}
                    {driver.driverNumber && `(${driver.driverNumber})`}{" "}
                  </span>
                  <BorderedText text={driver.status} classes="Available" />
                </div>
                <div className="desc_itm">
                  <i className="mil-location-company-o" />
                  <div className="desc_itm_txt">
                    {driver.address.addressLine}, {driver.address.city},{" "}
                    {driver.address.state}
                  </div>
                </div>
                <div className="desc_itm">
                  <i className="mil-phone-o" />
                  <div className="desc_itm_txt">{driver.phone}</div>
                </div>
                <div className="desc_itm">
                  <i className="mil-email-o" />
                  <div className="desc_itm_txt">{driver.email}</div>
                </div>
                <div className="buttons">
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-phone-f"
                    title="Call"
                    sm
                  />
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-email-at"
                    title="Email"
                    sm
                  />
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-email-o"
                    title="Text"
                    sm
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-driver">
          <p>No Driver Associated</p>
          <MilerButton
            title="Assign Driver"
            btnDark
            onClick={openAssignModal}
          />
        </div>
      )}
      {coDriverId ? (
        <div className="driver_card">
          <div className="title">
            <span>Co Driver</span>
            <MilerButton
              title="Remove Assignment"
              sm
              btnDark
              onClick={() => setOpenAssign("codriver")}
            />
          </div>
          <div className="body">
            <div className="info">
              <ProfilePlaceholder
                firstName={coDriver.firstName}
                lastName={coDriver.lastName}
              />
              <div className="desc">
                <div className="name">
                  {coDriver.firstName} {coDriver.lastName}
                  <span
                    className="driver_number_link"
                    onClick={() => {
                      setDriverDetailsId(coDriver.driverId);
                      setDriverDetails(true);
                    }}
                  >
                    {coDriver.driverNumber && `(${coDriver.driverNumber})`}{" "}
                  </span>
                  <BorderedText text={coDriver.status} classes="Available" />
                </div>
                <div className="desc_itm">
                  <i className="mil-location-company-o" />
                  <div className="desc_itm_txt">
                    {coDriver.address.addressLine}, {coDriver.address.city},{" "}
                    {coDriver.address.state}
                  </div>
                </div>
                <div className="desc_itm">
                  <i className="mil-phone-o" />
                  <div className="desc_itm_txt">{coDriver.phone}</div>
                </div>
                <div className="desc_itm">
                  <i className="mil-email-o" />
                  <div className="desc_itm_txt">{coDriver.email}</div>
                </div>
                <div className="buttons">
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-phone-f"
                    title="Call"
                    sm
                  />
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-email-at"
                    title="Email"
                    sm
                  />
                  <MilerButton
                    onClick={() => {}}
                    icon="mil-email-o"
                    title="Text"
                    sm
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-driver">
          <p>No co-driver Associated</p>
          <MilerButton
            title="Assign Driver"
            btnDark
            onClick={openAssignModal}
          />
        </div>
      )}

      {openAssign && (
        <UnassignWarning
          closeModal={setOpenAssign}
          unassign={unassign}
          openAssign={openAssign}
          setDriver={setDriver}
          setCoDriver={setCoDriver}
        />
      )}
      <AssignVehicleModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        getVehicles={getVehicles}
        getVehicle={getVehicle}
        fxn={() => {
          getDriver();
          getCoDriver();
        }}
      />
      <SliderModal clickState={driverDetails} setClickState={setDriverDetails}>
        <DriverEditDetails
          driverId={driverDetailsId}
          setDriverDetails={setDriverDetails}
        />
      </SliderModal>
    </div>
  );
};

export default Assignment;
