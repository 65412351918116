import { useContext, useEffect, useState } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import { getElements, putElement } from "services/milerApi";
import Loading from "components/loading/Loading";

const CustomerDocuments = ({ documentUrl, documentCategory, docTitle }) => {
  const {
    enums: { DocumentTypeCarrier },
  } = useContext(appContext);

  const [documents, setDocuments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const addDoc = (docObject) => {
    let docs = [...documents, docObject];
    putElement(`${documentUrl}`, docs).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const delDoc = (index) => {
    let updatedDocuments = documents;
    updatedDocuments.splice(index, 1);
    putElement(`${documentUrl}`, updatedDocuments).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const getDocuments = (isLoad) => {
    if (isLoad) {
      setIsLoading(true);
    }
    getElements(`${documentUrl}`)
      .then((response) => {
        setDocuments(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDocuments(true);
  }, [documentUrl]);

  return (
    <>
      {isLoading ? (
        <section className="mt-2">
          <Loading />
        </section>
      ) : (
        <AssociatedDocuments
          documents={documents}
          delDoc={delDoc}
          addDoc={addDoc}
          enums={DocumentTypeCarrier}
          documentCategory={documentCategory}
          docTitle={docTitle}
        />
      )}
    </>
  );
};

export default CustomerDocuments;
