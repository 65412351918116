import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { CheckCircleOutlined } from "@ant-design/icons";
import "./goLive.sass";
import { postGatewayApi } from "services/gatewayApis";
import { carrier_and_user_cookies_setup, getCookie } from "utils/cookies";

const GoLive = () => {
  const { accessToken } = getCookie("cachedJwt") || {};

  const [loading, setLoading] = useState(false);

  const resetSession = async () => {
    setLoading(true);
    try {
      const response = await postGatewayApi("carrier/onboarding", {});
      if (response.response.status === 200) {
        const result = await carrier_and_user_cookies_setup(accessToken);

        if (result) {
          location.reload();
        }
      }
    } catch (error) {}
  };

  return (
    <main className="main_section">
      <CheckCircleOutlined style={{ fontSize: "45px" }} />
      <section className="content">
        <p className="title">Well done! You've completed your onboarding.</p>
      </section>
      <MilerButton
        title={loading ? "Activating..." : "Activate"}
        btnDark
        className="live_btn"
        onClick={() => resetSession()}
      />
    </main>
  );
};

export default GoLive;
