import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import MessageCard from "./messageCard/MessageCard";
import "./Messages.sass";

const Messages = () => {
  const messages = [
    {
      senderFirstName: "Jacob",
      senderLastName: "Jones",
      senderTitle: "Driver",
      message:
        "Tellus pellentesque semper sed proin proin. Sed proin nullam id mollis commodo, neque tempus quis. Ultricies nulla ornare diam ut quis.",
      messageReceivedUtc: "2022-08-08T11:00:50.288Z",
    },
    {
      senderFirstName: "Esther",
      senderLastName: "Howard",
      senderTitle: "Company Name",
      message:
        "Tellus pellentesque semper sed proin proin. Sed proin nullam id mollis commodo, neque tempus quis. Ultricies nulla ornare diam ut quis.",
      messageReceivedUtc: "2022-08-08T11:00:50.288Z",
    },
    {
      senderFirstName: "Robert",
      senderLastName: "Fox",
      senderTitle: "FEDEX",
      message:
        "Tellus pellentesque semper sed proin proin. Sed proin nullam id mollis commodo, neque tempus quis. Ultricies nulla ornare diam ut quis.",
      messageReceivedUtc: "2022-08-08T11:00:50.288Z",
    },
    {
      senderFirstName: "Jacob",
      senderLastName: "Jones",
      senderTitle: "Driver",
      message:
        "Pulvinar leo risus porttitor nibh felis. Vel ultrices hendrerit a, pharetra. Nam orci urna, tortor, arcu.",
      messageReceivedUtc: "2022-08-08T11:00:50.288Z",
    },
  ];
  return (
    <div className="shipment_messages_container">
      <div className="messages_list">
        {messages.map((message, index) => (
          <MessageCard key={index} message={message} />
        ))}
      </div>
      <div className="new_message">
        <div className="txt">
          <InputMiler />
        </div>
        <div className="btn">
          <MilerButton title="Send" btnDark />
        </div>
      </div>
    </div>
  );
};

export default Messages;
