import { useState, useContext } from "react";
import BorderedText from "components/bordered-text/BorderedText";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import InprogressDispatchForm from "./InprogressDispatchForm";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import DetentionInfo from "./DetentionInfo";
import dateFormatter from "utils/dateFormater";
import StopDetails from "./stopDetails/StopDetails";
import { putElement } from "services/milerApi";
import "./IPCard.sass";

const IPCard = ({
  stop,
  index,
  stopLength,
  handleFetchData,
  hideActionButtons,
  noElipsis,
}) => {
  const [newSlider, setNewSlider] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [editDetaintion, setEditDeteintion] = useState(false);

  const activityContext = useContext(ActivityContext);

  const getTrip = activityContext?.getTrip;
  const currentTrip = activityContext?.currentTrip;

  let status;

  const updateTrip = () => {
    getTrip ? getTrip(currentTrip.tripId) : handleFetchData();
  };
  // Get shipment is used when we use IPCard on shipment details - overview

  const getTripData = () => {
    getTrip(currentTrip.tripId);
  };

  return (
    <div className="ip_card-container">
      <div className="ip_counter">
        <div
          className={`ip_number ${
            stop.promise === "Overdue"
              ? "red"
              : stop.promise === "Delayed"
              ? "yellow"
              : "green"
          }`}
        >
          {index + 1}
        </div>
        <div className={stopLength - 1 === index ? "" : "ip_line"} />
        <div className={stopLength - 1 === index ? "" : "ip_arrow"}>
          {stopLength - 1 === index ? <></> : <>&#8897;</>}
        </div>
      </div>
      <div className="ip_card">
        <div className="ip_header">
          <div className="first">
            <div className="stopType">{stop.stopType}</div>
            <BorderedText
              text={stop.progress || stop.status}
              classes={stop.progress || stop.status}
            />
          </div>
          <div className="second">
            {(stop.progress === "Inprogress" || stop.status == "Inprogress") &&
              !hideActionButtons && (
                <>
                  <span style={{ display: "none" }}>
                    {stop.actualArrival
                      ? (status = "Departed")
                      : (status = "Arrived")}
                  </span>
                  <MilerButton
                    title={stop.actualArrival ? "Depart" : "Arrive"}
                    btnDark
                    sm
                    onClick={() => setNewSlider(true)}
                  />
                </>
              )}
            {!noElipsis && (
              <ElipsisMenu>
                <MenuItem
                  title="View/Edit"
                  onClick={() => setDetailsOpen(true)}
                />

                {!stop.detentionEndUTC &&
                  (stop.actualArrival ||
                    stop.progress === "Inprogress" ||
                    stop.status === "Inprogress") &&
                  stop.stopType !== "Start" && (
                    <MenuItem
                      title={
                        stop.detentionStartUTC
                          ? "End Detention"
                          : "Start Detention"
                      }
                      onClick={() => setEditDeteintion(true)}
                    />
                  )}
                {stop?.actualArrival != null && (
                  <MenuItem
                    title="Undo"
                    onClick={() => {
                      stop.actualArrival = "";
                      stop.actualDeparture = "";
                      stop.promise = "";
                      stop.promiseDelayReason = "";

                      const removeServiceFailureNotes = stop.stopNotes.filter(
                        (notes) =>
                          notes.stopNoteType.toLowerCase() !==
                          "Service Failure".toLowerCase()
                      );
                      stop.stopNotes = removeServiceFailureNotes;
                      putElement(
                        `/Shipment/Trip/Stop/${stop.stopId}?emptyDistance=""`,
                        stop
                      ).then((response) => {
                        if (response.success) {
                          handleFetchData();
                        }
                      });
                    }}
                  />
                )}
              </ElipsisMenu>
            )}
          </div>
        </div>

        <div className="ip_address">
          {stop?.stopReason ? (
            <div className="ip_add_title">{stop.stopReason}</div>
          ) : (
            <div className="ip_add_title_content">Address</div>
          )}
          <div className="ip_address_body">
            <div className="ip_add_initial">
              {stop.address ? stop.address.locationName : ""}
            </div>
            <div className="ip_address_line">
              {stop.address ? stop.address.fullAddress : ""}
            </div>
          </div>
        </div>
        <div className="ip_datetime_basket">
          <div className="ip_datetime">
            <div className="ip_datetime_title">Earliest Arrival</div>
            <div className="ip_datetime_date">
              {dateFormatter(stop.earliest_arrival, "MMM DD, HH:mm", true)}
            </div>
          </div>
          <div className="ip_datetime">
            <div className="ip_datetime_title">Earliest Departure</div>
            <div className="ip_datetime_date">
              {dateFormatter(stop.earliest_departure, "MMM DD, HH:mm", true)}
            </div>
          </div>
        </div>
        <div className="ip_datetime_basket">
          <div className="ip_datetime">
            <div className="ip_datetime_title">Latest Arrival</div>
            <div className="ip_datetime_date">
              {dateFormatter(stop.latest_arrival, "MMM DD, HH:mm", true)}
            </div>
          </div>
          <div className="ip_datetime">
            <div className="ip_datetime_title">Latest Departure</div>
            <div className="ip_datetime_date">
              {dateFormatter(stop.latest_departure, "MMM DD, HH:mm", true)}
            </div>
          </div>
        </div>
        {(stop.actualDeparture || stop.actualArrival) && (
          <div className="ip_datetime_basket">
            <div className="ip_datetime">
              <div className="ip_datetime_title">Actual Arrival</div>
              <div className="ip_datetime_date">
                {dateFormatter(stop.actualArrivalLocal, "MMM DD, HH:mm")}
              </div>
            </div>
            <div className="ip_datetime">
              <div className="ip_datetime_title">Actual Departure</div>
              <div className="ip_datetime_date">
                {dateFormatter(stop.actualDepartureLocal, "MMM DD, HH:mm")}
              </div>
            </div>
          </div>
        )}
        {/* eta rows */}
        {stop.checkCall?.eta && stop.progress === "Inprogress" && (
          <>
            <div className="ip_datetime_basket">
              <div className="ip_datetime">
                <div className="ip_datetime_title">ETA</div>
                <div className="ip_datetime_date">
                  {stop.checkCall.etaRelative}
                </div>
              </div>
              <div className="ip_datetime">
                <div className="ip_datetime_title">ETA Updated</div>
                <div className="ip_datetime_date">
                  {stop.checkCall.lastUpdatedRelative}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <SliderModal
        clickState={newSlider}
        setClickState={setNewSlider}
        width="40"
      >
        <InprogressDispatchForm
          setClickState={setNewSlider}
          stop={stop}
          updateTrip={updateTrip}
          status={status}
        />
      </SliderModal>

      <SliderModal
        clickState={detailsOpen}
        setClickState={setDetailsOpen}
        width="50"
      >
        <StopDetails
          setDetailsOpen={setDetailsOpen}
          stop={stop}
          customer={
            currentTrip?.shipmentResponse
              ? currentTrip.shipmentResponse.shipperName
              : ""
          }
          handleFetchData={handleFetchData}
          getTripData={getTripData}
        />
      </SliderModal>
      <SliderModal
        clickState={editDetaintion}
        setClickState={setEditDeteintion}
        width="40"
      >
        <DetentionInfo
          setEditDeteintion={setEditDeteintion}
          updateTrip={updateTrip}
          stop={stop}
        />
      </SliderModal>
    </div>
  );
};

export default IPCard;
