import "./EmptyNotification.sass";

export default function EmptyNotification() {
  return (
    <div className="empty-notification">
      <i className="mil-message-o" />
      <h4>No notification found</h4>
    </div>
  );
}
