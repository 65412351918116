import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";
import { postElement } from "services/milerApi";
import { hasPermission } from "utils/hasPermission";

const RecordPayment = ({ invoiceInfo, onToggle }) => {
  const onSave = () => {
    postElement(
      `/Carrier/Invoice/${invoiceInfo.id}/Payment?amount=${amount}`
    ).then(() => {
      onToggle();
    });
  };
  const [amount, setAmount] = useState(invoiceInfo.amount);

  const changed = (e) => {
    let a = parseInt(e.target.value);
    if (a > invoiceInfo.amount) return;
    else setAmount(a);
  };

  return (
    <Modal isOpen={Boolean(invoiceInfo)} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>Record Payment</ModalHeader>
      <ModalBody>
        <InputMiler type="number" value={amount} onChange={changed} />
      </ModalBody>
      <ModalFooter>
        <MilerButton
          title="Submit"
          onClick={onSave}
          targetActionDenied={!hasPermission("edit.accounting")}
        />
      </ModalFooter>
    </Modal>
  );
};

export default RecordPayment;
