import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import MilerButton from "components/milerButton/MilerButton";
import { hasPermission } from "utils/hasPermission";

const CustomHeader = ({ name, email }) => (
  <div
    style={{
      marginTop: "4px",
      display: "flex",
      flexDirection: "column",
      gap: "0.3rem",
    }}
  >
    <div>{name}</div>
    <div style={{ fontSize: "10px", color: "#626365" }}>{email}</div>
  </div>
);
export const columns = (handlePasswordReset, reInvite) => [
  {
    name: "NAME",
    selector: (row) => row.username,
    cell: (row) => {
      const name = row?.given_name + " " + row?.family_name;
      return CellBuilder(<CustomHeader name={name} email={row?.email} />);
    },
    grow: 3,
  },
  {
    name: "USERNAME",
    selector: (row) => row.username,
    cell: (row) => CellBuilder(row.username),
    grow: 1,
  },
  {
    name: "STATUS",
    selector: (row) => row.confirmation_status,
    center: true,
    cell: (row) =>
      CellBuilder(
        <BorderedText classes={"Available "} text={row.confirmation_status} />
      ),
    grow: 3,
  },
  {
    name: "ACTIONS",
    grow: 2,
    center: true,
    cell: (row) => (
      <div className="table-actions d-flex justify-content-center align-items-center">
        {row.confirmation_status &&
          row.confirmation_status.toLowerCase() === "force change password" && (
            <MilerButton
              btnDark
              title="Re-Invite"
              onClick={() => reInvite()}
              targetActionDenied={!hasPermission("reactivate.user")}
            />
          )}
        <ElipsisMenu>
          <MenuItem
            title="Reset Password"
            onClick={() => handlePasswordReset()}
            targetActionDenied={!hasPermission("reactivate.user")}
          />
        </ElipsisMenu>
      </div>
    ),
  },
];
