import {
  getApi,
  postApi,
  putApi,
  deleteApi,
} from "services/utils/axiosWrapper";
import { Toast } from "./Toast";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";

export const baseUrls = {
  //local: "https://localhost:5001/",
  local: "https://dev-carrier.miler.com/",
  dev: "https://dev-carrier.miler.com/",
  qa: "https://dev-carrier.miler.com/" /* "https://qa-carrier.miler.com/", */,
  staging:
    "https://dev-carrier.miler.com/" /* "https://staging-carrier.miler.com/", */,
  prod: "https://carrier.miler.com/",
};
const apiConfig = {
  baseUrls,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 6000,
};

const errorsSeparation = (errors) => {
  let fieldErrors = [];
  let customErrors = [];
  errors.forEach((error) => {
    if (error.key) {
      fieldErrors.push(error);
    } else {
      customErrors.push(error);
    }
  });
  return [fieldErrors, customErrors];
};

const errorsHandler = (error, toastCode) => {
  let responseStatus;
  let vErrors;
  let errorMessage =
    "Something went wrong. Please try again or call our support if issue persists";
  if (error.response) {
    let { data, status } = error.response;
    let { errors } = data || {};
    if (status === 400) {
      errorMessage = "400: Bad Request";
      if (errors?.length) {
        let [fieldErrors, customErrors] = errorsSeparation(errors);
        let inputErrors;
        let DescErrors;
        if (fieldErrors.length) {
          vErrors = fieldErrors;
          inputErrors = (
            <strong>
              There is one or more missing or invalid required fields
            </strong>
          );
        }
        if (customErrors.length) {
          DescErrors = customErrors.map((err, index) => (
            <p key={index}>{err.errorMessage}</p>
          ));
        }
        errorMessage = (
          <div>
            {inputErrors}
            {DescErrors}
          </div>
        );
      }
    } else if (status === 403) {
      errorMessage = "You do not have permission to perform this action";
    } else if (status === 404) {
      errorMessage = "Record not found";
    } else if (status === 409) {
      errorMessage = "Record not saved";
      responseStatus = 409;
      vErrors = (errors || [])[0]?.errorMessage || "";
    } else if (status === 500) {
      errorMessage = "An error occurred";
    }
  }
  Toast.error(errorMessage, toastCode);
  return { success: false, vErrors, responseStatus };
};

const successHandler = (response, toastCode, options) => {
  let { title, data } = response.data;
  let message = title || data || response.data;
  if (options && options.ignoreToast) {
  } else if (options && options.customToast) {
    Toast.success(options.customToast);
  } else {
    Toast.success(message.toString(), toastCode);
  }
  return { success: true, response, data };
};

export async function getElementsV2({
  url,
  version,
  timeout,
  setLoading,
  showErrorMessage = true,
  maxRetries = 0,
}) {
  if (setLoading || setLoading === null) {
    setLoading = setLoading ? setLoading : () => {};
    setLoading(Loading);
    return await getApi(
      url,
      {
        ...apiConfig,
        timeout: parseInt(timeout) || apiConfig.timeout,
      },
      version,
      maxRetries
    )
      .then((response) => {
        setLoading(false);

        return { success: true, res: response, data: response.data };
      })
      .catch(() => {
        let onFail = showErrorMessage ? <FailedToFetch /> : false;
        setLoading(onFail);
        return { success: false };
      });
  } else {
    throw new Error("Missing Loading Prop");
  }
}
export async function getElements(url, version, timeout) {
  return await getApi(
    url,
    { ...apiConfig, timeout: parseInt(timeout) || apiConfig.timeout },
    version
  );
}
export async function putElement(url, data, version, options) {
  let toastCode;
  if (!options || !options.ignoreToast) {
    toastCode = Toast.loading("Please wait");
  }
  return putApi(url, apiConfig, data, version)
    .then((res) => successHandler(res, toastCode))
    .catch((error) => errorsHandler(error, toastCode));
}

export async function postElement(url, data, version, options, timeout) {
  let toastCode;
  if (!options || !options.ignoreToast) {
    toastCode = Toast.loading("Please wait");
  }

  return postApi(
    url,
    { ...apiConfig, timeout: parseInt(timeout) || apiConfig.timeout },
    data,
    version
  )
    .then((res) => successHandler(res, toastCode, options))
    .catch((error) => errorsHandler(error, toastCode));
}
export async function deleteElement(url, data, version, options) {
  let toastCode;
  if (!options || !options.ignoreToast) {
    toastCode = Toast.loading("Please wait");
  }
  return deleteApi(url, apiConfig, data, version)
    .then((res) => successHandler(res, toastCode))
    .catch((error) => errorsHandler(error, toastCode));
}
