import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState, useEffect, useRef, useContext } from "react";

import { getElementsV2 } from "services/milerApi";
import CheckCallCard from "./CheckCallCard";
import dayjs from "dayjs";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import { calculateEta } from "utils/calculateEta";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

function CheckCallsBody() {
  const { currentTrip } = useContext(ActivityContext);
  const [loadingCalls, setLoadingCalls] = useState(<></>);
  const [notes, setNotes] = useState({});
  const [newNote, setNewNote] = useState("");
  const callsEndRef = useRef(null);
  const notesInputRef = useRef(null);

  const nextStop = currentTrip.stops.find((element) => {
    return element.checkCall?.refresh;
  });

  const [locationError] = useState(false);
  const [creatingAutoCheck] = useState(false);

  const scrollToBottom = (behavior) => {
    callsEndRef.current?.scrollIntoView({ behavior: behavior });
  };

  const fetchCalls = async () => {
    return await getElementsV2(
      {
        url:  `/Shipment/Trip/${currentTrip.tripId}/AutoCheck?offset=1&limit=100`,
        setLoading: setLoadingCalls
      }
    )
      .then((res) => {
        // setServiceCodes(response.data)
        setNotes(res?.data ||{});
        return res?.data || {};
      })
     
  };

  useEffect(() => {
    fetchCalls().then(() => {
      scrollToBottom("auto");
    });
  }, []);

  return (
    <>
      <div className="slider-display-body checkcall-body">
        {!loadingCalls ? (
          <>
            {notes.length != 0 ? (
              <>
                {notes.map((call, index) => {
                  return <CheckCallCard callData={call} key={index} />;
                })}
              </>
            ) : (
              <span>No check calls available</span>
            )}
            <div ref={callsEndRef} />
          </>
        ) : (
           {loadingCalls}
        )}
      </div>
      <div className="checkcall-bottom">
        <div className="input-container">
          <InputMiler
            disabled={locationError || !currentTrip.eldId}
            innerRef={notesInputRef}
            value={newNote.note}
            onChange={(e) => {
              setNewNote(e.target.value);
            }}
            type="text"
            placeholder="Type here..."
          />
        </div>
        <div className="button-container">
          <MilerButton
            disabled={
              !newNote ||
              locationError ||
              creatingAutoCheck ||
              !currentTrip.eldId
            }
            onClick={() => {
              calculateEta(
                currentTrip,
                nextStop,
                async () => {
                  await fetchCalls();
                  setNewNote("");
                  notesInputRef.current.value = "";
                  scrollToBottom("smooth");
                },
                newNote
              );
            }}
            title="Add Check Call"
            btnDark
            tooltip={!currentTrip.eldId ? "ELD is not setup!" : ""}
          />
        </div>
      </div>
    </>
  );
}

export default CheckCallsBody;
