export const countryFullName = (countryCode) => {
  const countryEnum = [
    { name: "United States", code: "us" },
    { name: "Canada", code: "ca" },
  ];
  let country = countryEnum.find(
    (country) => country.code === countryCode.toLowerCase()
  );
  let countryName = country ? country.name : "";
  return countryName;
};
