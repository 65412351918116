import { useEffect, useReducer, useState } from "react";
import MilerButton from "../../../components/milerButton/MilerButton";
import ShipmentForm from "../shipmentForm/ShipmentForm";
import "./NewShipment.sass";
import shipmentContract from "views/shipments/shipmentForm/shipmentContract";
import shipmentReducer from "views/shipments/shipmentForm/reducer";
import { toast } from "react-toastify";
import { postElement } from "services/milerApi";
import { useMediaQuery } from "react-responsive";
const NewShipment = ({ setClickState, getShipments }) => {
  let initialValue;

  const [shipment, dispatch] = useReducer(shipmentReducer, initialValue);
  const [errors, setFormErrors] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const postShipment = () => {
    if (shipment.stops[0].stopType == "Delivery") {
      toast.error("First stop must be Pickup");
      return;
    }

    if (shipment.stops[shipment.stops.length - 1].stopType == "Pickup") {
      toast.error("Last stop must be Delivery");
      return;
    }
    const trimmedShipment = { ...shipment, shipmentNumber: shipment?.shipmentNumber?.trim() };
    postElement(`/Shipment`, trimmedShipment).then((response) => {
      if (response.success) {
        setFormErrors([]);
        getShipments();
        setClickState(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };
  useEffect(() => {
    const saved = localStorage.getItem("draftShipment");
    initialValue = saved
      ? dispatch({ type: "draftNewShipment", payload: JSON.parse(saved) })
      : dispatch({ type: "draftNewShipment", payload: shipmentContract });
  }, []);
  const saveToDraft = () => {
    localStorage.setItem("draftShipment", JSON.stringify(shipment));
    toast.success("Shipment saved to drafts");
    setClickState(false);
  };
  return (
    shipment !== undefined && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Shipment</div>
          <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
            {isMobile ? (
              <section className="shipment_top_header_btn">
                <MilerButton
                  title="Cancel"
                  onClick={() => {
                    localStorage.removeItem("draftShipment");
                    setClickState(false);
                  }}
                  sm
                />
                <MilerButton title="Save as draft" onClick={saveToDraft} sm />
                <MilerButton btnDark title="Save" onClick={postShipment} sm />
              </section>
            ) : (
              <>
                <MilerButton
                  title="Cancel"
                  onClick={() => {
                    localStorage.removeItem("draftShipment");
                    setClickState(false);
                  }}
                />
                <MilerButton title="Save as draft" onClick={saveToDraft} />
                <MilerButton btnDark title="Save" onClick={postShipment} />
              </>
            )}
          </div>
        </div>
        <div className="slider-display-body">
          <ShipmentForm
            shipment={shipment}
            errors={errors}
            dispatch={dispatch}
          />
        </div>
      </div>
    )
  );
};

export default NewShipment;
