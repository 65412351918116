import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import findError from "utils/findError";
import EquipmentForm from "./EquipmentForm";

const EquipComp = ({
  vehicle,
  setVehicle,
  newVehicle,
  hideCreateNew,
  error,
  errorName,
}) => {
  const onEquipSelect = (equip) => {
    setVehicle({
      ...equip.split,
      equipmentType: equip.equipmentType,
      eldId: equip.eldId,
    });
  };

  const joinEquip = () => {
    return `${vehicle.unitNumber ? vehicle.unitNumber + " " : ""}${
      vehicle.make ? vehicle.make + " " : ""
    }${vehicle.model ? vehicle.model + " " : ""}`;
  };

  return (
    <>
      {newVehicle ? (
        <EquipmentForm vehicle={vehicle} setVehicle={setVehicle} />
      ) : (
        <div className="col-12 col-lg-12 col-xl-12">
          <Equipmentsearcher
            value={joinEquip()}
            onItemSelect={onEquipSelect}
            label="Equipment"
            error={findError(errorName || "UnitId", error)}
            hideCreateNew={hideCreateNew}
            required
          />
        </div>
      )}
    </>
  );
};

export default EquipComp;
