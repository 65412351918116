import SearchComp from "./SearchComp";

const AddressBookSearcher = ({
  onItemSelect,
  label,
  value,
  error,
  required,
}) => {
  const onSelect = (item) => {
    onItemSelect(item);
  };
  const searchResultItem = (item) => {
    const { locationName, fullAddress, addressId } = item.address;
    return (
      <div
        className="advance-search__item container"
        key={addressId}
        onClick={() => onSelect(item)}
      >
        <h5 className="advance-search__item-title row">
          {locationName || "N/A"}
        </h5>
        <div className=" row">{fullAddress || ""}</div>
      </div>
    );
  };
  return (
    <div>
      <SearchComp
        label={label}
        searchResultItem={searchResultItem}
        url={`/Carrier/AddressBook/Search?locationName`}
        value={value}
        error={error}
        required={required}
      />
    </div>
  );
};
export default AddressBookSearcher;
