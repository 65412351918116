import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { columns } from "./InspectionHistoryData";
import "./InspectionHistory.sass";
import { getElements } from "services/serviceRepairApi";
import CustomPagination from "components/custom-pagination/CustomPagination";
import Loading from "components/loading/Loading";
import FailedToFetch from "components/loading/FailedToFetch";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import dateFormatter from "utils/dateFormater";
import SliderModal from "components/sliderModal/SliderModal";
import InspectionDetails from "views/maintenance/inspection/inspectionDetails/InspectionDetails";
const InspectionHistory = ({ id, fromVehicle = false }) => {
  const [inspections, setInspections] = useState([]);
  const [inspectionDetailsOpen, setInspectionDetailsOpen] = useState(false);

  const [inpectionId, setInspectionId] = useState();
  const [request, setRequest] = useState({
    Status: "Active",
    offset: 1,
    limit: 50,
  });
  const [loading, setLoading] = useState(Loading);
  let repairsList = inspections.map((data) => {
    return {
      id: data.inspectionId,
      totalInspectedItems: data.countInspectedItems,
      date: (
        <span className="repair_date">
          {dateFormatter(data.inspectedUTC, "MMM DD, YYYY")}
        </span>
      ),
      failedItems: (
        <div className="inspect_fail">
          <span>{`${data.countFailedItems}/${data.countInspectedItems}`}</span>
          {data.countFailedItems > 0 && (
            <>
              <BsFillExclamationCircleFill className="inspect_fail_danger" />
            </>
          )}
        </div>
      ),
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            onClick={() => {
              setInspectionDetailsOpen(true);
              setInspectionId(data.inspectionId)
            }}
            style={{
              fontSize: "21px",
              cursor: "pointer",
            }}
          ></i>
          {/* <i className="mil-more-vertical"></i> */}
        </div>
      ),
    };
  });

  useEffect(() => {
    getElements(
      `/Equipment/${fromVehicle ? "Vehicle" : "Trailer"}/${id}/Inspection`
    )
      .then((res) => {
        setInspections(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(<FailedToFetch />);
      });
  }, []);

  return (
    <div className="ServiceRepairs">
      <div className="summary-list">
        {loading ? (
          loading
        ) : (
          <>
            <DataTable columns={columns} data={repairsList} responsive={true} />
            <CustomPagination setRequest={setRequest} request={request} />
          </>
        )}
      </div>
      <SliderModal
        clickState={inspectionDetailsOpen}
        setClickState={setInspectionDetailsOpen}
      >
        <InspectionDetails
          setInspectionDetailsOpen={setInspectionDetailsOpen}
          id={inpectionId}
        />
      </SliderModal>
    </div>
  );
};

export default InspectionHistory;
