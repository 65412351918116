import { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import getCarrierId from "utils/getCarrierId";
import "./NotifComponent.sass";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import AllNotification from "./viewAll/AllNotification";
import { getGatewayApi, postGatewayApi } from "services/gatewayApis";
import EmptyNotification from "./dimissAll/EmptyNotification";
import { HiBell } from "react-icons/hi";
import { Tooltip, Badge as AntBadge } from "antd";
import "antd/dist/antd.min.css";
import dateFormatter from "utils/dateFormater";

const Notifcomponent = () => {
  const [open, setOpen] = useState(false);
  const [notifs, setNotifs] = useState([]);
  const [notificationContent, setNotificationContent] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  // get notifications
  const getNotifications = () => {
    getGatewayApi(
      `carrier/notification?carrierId=${getCarrierId()}`
    ).then((response) => setNotifs(response.data));
  };


  const handleDelete = (id) => {
    postGatewayApi("").then(() => {
      setNotifs({
        ...notifs,
        notifs: notifs.filter((notif) => notif.CarrierNotificationId !== id),
      });
    }).catch(() => {});
  };

  return (
    <div className='notif-comp'>
      <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
        {/* <img src={notification} alt='notification' /> */}
        <DropdownToggle tag='div' className='pr-0'>
          <Tooltip title='Notifications'>
            <AntBadge count={notifs.length > 99 ? "99+" : notifs.length}>
              <HiBell size={32} fill='#202f53' />
            </AntBadge>
          </Tooltip>
        </DropdownToggle>

        <DropdownMenu className='notif-comp-menu'>
          <div className='d-flex justify-content-end mt-3 mb-3 flex-row'>
            <MilerButton className="mr-2" title='Dismiss All' sm onClick={() => {}} />
            <MilerButton
              title='View All'
              sm
              onClick={() => {
                setNotificationContent(true);
                setOpen(false);
              }}
            />
          </div>
          {notifs.length !== 0 ? (
            notifs.map((notif, index) => (
              <DropdownItem className='notif-menu-item' key={index}>
                <div className='notif-item-head d-flex justify-content-between'>
                  <h6>{notif.Title}</h6>
                  <h6>{notif.EventSource}</h6>
                </div>
                <p>{notif.Message}</p>
                <p className='notif-date'>
                  {dateFormatter(notif.EventUtc, "MMM DD, YYYY")}
                </p>
              </DropdownItem>
            ))
          ) : (
            <EmptyNotification />
          )}
        </DropdownMenu>
      </Dropdown>
      <SliderModal
        clickState={notificationContent}
        setClickState={setNotificationContent}
        width={50}
      >
        <AllNotification
          notifications={notifs}
          handleDelete={handleDelete}
          setNotificationContent={setNotificationContent}
        />
      </SliderModal>
    </div>
  );
};

export default Notifcomponent;
