//package import
import {useEffect} from "react";
import { Marker } from "@react-google-maps/api";

import { getGatewayApi } from "services/gatewayApis";

const DisTripDriverMarker = ({
  unitNumber,
  locations,
  setLocations,
}) => {

  const trackVehicle = (vehicleExtId) => {
    setLocations([vehicleExtId]);
  };

  const getVehicleExtId = (unitNumber) => {
    getGatewayApi(`carrier/equipment/location?unit_number=${unitNumber}&include=gps`).then(({data}) => {
      const vehicleTracking = data;
      trackVehicle(vehicleTracking);
    });
  };

  useEffect(() => {
    getVehicleExtId(unitNumber);
  }, []); 

  return locations.map((location, index) => {
    const gsp = location.gps[0];
    const pos = { lat: gsp.latitude, lng: gsp.longitude };
    const heading = location.gps[0].headingDegrees;

    const markerTypes = {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      color: "#efa324",
    };

    return (
      <Marker
        key={index}
        options={{
          icon: {
            path: markerTypes.path,
            scale: 5,
            fillColor: markerTypes.color,
            fillOpacity: 0.8,
            strokeWeight: 2,
            strokeColor: "white",
            rotation: heading,
          },
        }}
        title={location.name}
        position={pos}
      />
    );
  });
};

export default DisTripDriverMarker;
