import { useState } from "react";
import "../Maintenance.sass";
import "react-bootstrap-typeahead/css/Typeahead.css";
import RepairItemSearchComp from "../RepairItemSearchComp";
import MilerButton from "components/milerButton/MilerButton";

const RepairItemSearch = ({
  label,
  error,
  type,
  assignValue,
  setItem,
  createItem,
  dispatch,
  setItemId,
  showSaveBtn,
  setNewServiceOpen,
  setShowSaveBtn,
}) => {
  //const [value, setValue] = useState("");
  const onItemSelect = (item) => {
    //setValue(item.code);
    setItem && setItem(item.code);
    setItemId && setItemId(item.serviceId);
    dispatch && dispatch(item);
  };
  const [searchState, setSearch] = useState({
    result: [],
    searchDisplay: false,
  });

  // const onSubmit = () => {};

  const onSelect = (item) => {
    onItemSelect(item);
    setSearch({
      result: [],
      searchDisplay: false,
    });
  };

  const searchResultItem = (item) => {
    return (
      <div
        className="vehicle-search__item"
        key={item.id}
        onClick={() => {
          onSelect(item);
          setShowSaveBtn(false);
        }}
      >
        <div>
          <h5 className="vehicle-search__item-title">{item.code}</h5>
          <div className="vehicle-search__item-desc">{item.categoryName}</div>
        </div>
      </div>
    );
  };

  const notFoundItem = (item) => {
    return (
      <div
        className="vehicle-search__item"
        onClick={() => {
          createItem(item);
          //setValue(item.code)
          setShowSaveBtn(true);
        }}
      >
        <div className="not_found">
          <small>{item.categoryName}</small>
          <h6 className="vehicle-search__item-title">{item.code}</h6>
        </div>
      </div>
    );
  };

  return (
    <div className={type === "vehicle" ? "" : "vehicle_search_body"}>
      <RepairItemSearchComp
        label={label}
        searchResultItem={searchResultItem}
        notFoundItem={notFoundItem}
        url={`/ServiceRequest/Services/Search?name`}
        error={error}
        value={assignValue}
        // type === "vehicle" ? assignValue : value
        searchState={searchState}
        setSearch={setSearch}
        type={type}
      />
      {showSaveBtn && (
        <MilerButton
          title="Save service Item"
          btnDark
          sm
          onClick={() => {
            setNewServiceOpen(true);
          }}
        />
      )}
    </div>
  );
};

export default RepairItemSearch;
