import { useReducer, useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import FreightClaimForm from "../freightClaimForm/FreightClaimForm";
import { postElement } from "services/milerApi";
import { freightClaimsContract } from "../freightClaimsContract";
import { freightClaimReducer } from "../reducer";

const NewFreightClaim = ({ setNewFreightClaimOpen, shipmentDetails }) => {
  const [errors, setFormErrors] = useState([]);
  const [state, dispatch] = useReducer(
    freightClaimReducer,
    freightClaimsContract
  );

  const createFreightClaim = () => {
    const freightClaimPayload = {
      ...state,
      shipmentNumber: shipmentDetails.shipmentNumber,
      shipmentId: shipmentDetails.shipmentId,
    };
    postElement("/Compliance/FreightClaims", freightClaimPayload).then(
      (response) => {
        if (response.success) {
          setNewFreightClaimOpen(false);
        } else if (response.vErrors) {
          setFormErrors(response.vErrors);
        }
      }
    );
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="d-flex flex-column">
          <div className="slider-header-title">New Freight Claim</div>
        </div>

        <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
          <MilerButton
            icon="mil-close-bg"
            title="Cancel"
            onClick={() => setNewFreightClaimOpen(false)}
          />
          <MilerButton
            btnDark
            icon="mil-save-o"
            title="Save"
            onClick={() => createFreightClaim()}
          />
        </div>
      </div>

      <div className="slider-display-body">
        <FreightClaimForm
          dispatch={dispatch}
          state={{ ...state, shipmentNumber: shipmentDetails?.shipmentNumber }}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default NewFreightClaim;
