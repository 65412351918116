import MilerButton from "components/milerButton/MilerButton";
import { useContext, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";

const AddNoteModal = ({ isOpen, setIsOpen, onAddNote, hideSelectOption }) => {
  const { enums } = useContext(appContext);

  const [noteType, setNoteType] = useState("General");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");

  const closeModal = () => setIsOpen(!isOpen);

  const postNote = () => {
    if (note && note != "") {
      onAddNote({ noteType, note });
      !hideSelectOption && setNoteType("");
      setNote("");
      closeModal();
    } else {
      setError("Note is required");
    }
  };

  return (
    <Modal isOpen={Boolean(isOpen)} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Add Note</ModalHeader>
      <ModalBody>
        {hideSelectOption ? null : (
          <InputMiler
            type="select"
            label="Note Type"
            options={enums.NoteType}
            onChange={(e) => setNoteType(e.target.value)}
            selectType="notes"
          />
        )}

        <InputMiler
          type="textarea"
          label="Note"
          placeholder="Write here..."
          onChange={(e) => setNote(e.target.value)}
          required
          error={error}
        />
      </ModalBody>
      <ModalFooter>
        <MilerButton title="Add Note" onClick={() => postNote()} btnDark />
      </ModalFooter>
    </Modal>
  );
};

export default AddNoteModal;
