import SearchComp from "components/inputMiler/advanceSearch/SearchComp";

const AssignDriversSearcher = ({ onItemSelect, label, error, value }) => {
  const searchResultItem = (item) => {
    let items = item.equipment.split(" ");
    let driver = item.driver || {};
    let coDriver = item.coDriver || {};
    let driversInfo = { ids: [], name: driver.name || coDriver.name || "" };
    driver.driverId && driversInfo.ids.push(driver.driverId);
    coDriver.driverId && driversInfo.ids.push(coDriver.driverId);
    if (driver.driverId) driversInfo.driverId = driver.driverId;
    if (coDriver.driverId) driversInfo.coDriverId = coDriver.driverId;

    let itemInfo = {
      vehicleId: item.id,
      assignedVehicleID: item.id,
      vehicleUnitNumber: items[0],
      driverId: driver.driverId,
      coDriverId: coDriver.driverId,
      fromSearch: true,
      hasdriverpayee: item.hasdriverpayee,
      hascdriverpayee: item.hascdriverpayee,
    };
    if (driver.driverId && driver.name) {
      const [driverFirstName, driverLastName] = driver.name.split(" ");
      itemInfo.driverFirstName = driverFirstName;
      itemInfo.driverLastName = driverLastName;
    }
    if (coDriver.driverId && coDriver.name) {
      const [coDriverFirstName, coDriverLastName] = coDriver.name.split(" ");
      itemInfo.coDriverFirstName = coDriverFirstName;
      itemInfo.coDriverLastName = coDriverLastName;
    }

    const onSelect = () => {
      onItemSelect(itemInfo);
      /* if (driversInfo.ids.length) onItemSelect(driversInfo, item.id);
      else {
        toast.error("NO Driver Associated With Selected Vehicle!");
      } */
    };
    return (
      <div
        className="advance-search__item container equipm-searcher"
        key={item.id}
      >
        <h5
          className="advance-search__item-title row gold-search-title"
          onClick={() => onSelect(item)}
        >
          {items[0] || "N/A"}
        </h5>
        <span className=" row">
          {items[2]},{items[3]}, {items[4]}
        </span>
        <div className="row justify-content-between">
          <section className="column">
            <label>Driver: </label>
            <span>{driver.name}</span>
          </section>
          <section className="column">
            <label>Co-Driver: </label>
            <span>{coDriver.name}</span>
          </section>
        </div>
      </div>
    );
  };
  return (
    <div>
      <SearchComp
        placeholder="Search by unit #"
        label={label}
        searchResultItem={searchResultItem}
        url={`Equipment/Search?unitType=Vehicle&unitNumber`}
        error={error}
        value={value}
      />
    </div>
  );
};

export default AssignDriversSearcher;
