import { useContext } from "react";
import TripHeader from "views/activities-v2/activityDisplay/tripDisplay/TripHeader";
import AssignedInfoCard from "views/activities-v2/activityDisplay/tripDisplay/assigned-info-card/AssignedInfoCard";
import "views/activities-v2/activityDisplay/tripDisplay/TripDisplay.sass";
import { MockContext, MockProvider } from "./ActivityViewerContext";
import InprogressTrip from "./components/InProgressTrip";

export const SetupView = () => {
  return (
    <MockProvider>
      <New />
    </MockProvider>
  );
};

const New = () => {
  const { currentTrip, getTrips } = useContext(MockContext);

  return (
    <div className="avaliable_shipment container">
      <>
        <TripHeader currentTrip={currentTrip} title="Shipment" />
        {currentTrip.driverFirstName && <AssignedInfoCard trip={currentTrip} />}

        {currentTrip.tripStatus === "Inprogress" && (
          <InprogressTrip currentTrip={currentTrip} getTrips={getTrips} />
        )}
      </>
    </div>
  );
};
