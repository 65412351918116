import { useState, useEffect } from "react";
import {
  deleteElement,
  getElementsV2,
  postElement,
  putElement,
} from "services/milerApi";
import ShipmentDetails from "./ShipmentDetails";
import { toast } from "react-toastify";

const ShipmentDetailsWrapper = ({ setContent, shipmentId, getShipments }) => {
  const [shptDtls, setShptDtls] = useState();

  const [loading, setLoading] = useState(<></>);
  const getShipmentDetails = () => {
    if (shipmentId)
      getElementsV2({
        url: `/Shipment/Id?shipmentId=${shipmentId}`,
        setLoading: setLoading,
      }).then((response) => {

        let shipment = response?.data || {};
        delete shipment.documents;
        shipment?.stops.sort((a, b) => a.stopSequence - b.stopSequence);
        setShptDtls(shipment || {});
      });
  };

  const closeSLider = () => {
    setContent(false);
  };

  const lockShipment = () => {
    postElement(
      `/Shipment/Lock?shipmentId=${
        shptDtls.shipmentId
      }&IsLocked=${!shptDtls.isLocked}`
    ).then(() => {
      getShipmentDetails(shipmentId);
    });
  };

  const updateShipment = (shipment, setFormErrors, dontCloseSlider) => {
    if (shipment.stops[0].stopType == "Delivery") {
      toast.error("First stop must be Pickup");
      return;
    }

    if (shipment.stops[shipment.stops.length - 1].stopType == "Pickup") {
      toast.error("Last stop must be Delivery");
      return;
    }
    const trimmedShipment = {
      ...shipment,
      shipmentNumber: shipment?.shipmentNumber?.trim(),
    };
    putElement(
      `/Shipment?shipmentId=${shipment.shipmentId}`,
      trimmedShipment
    ).then((response) => {
      if (response.success) {
        setFormErrors([]);
        getShipmentDetails();
        getShipments();
        if (!dontCloseSlider) {
          closeSLider();
        }
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const deleteShipment = () => {
    deleteElement(`/Shipment?shipmentId=${shptDtls.shipmentId}`).then((res) => {
      if (res.success) {
        setContent(false);
        getShipments();
      }
    });
  };

  const updateShipmentCharges = (charges, setFormErrors) => {
    putElement(`/Shipment/${shptDtls.shipmentId}/Charges`, charges).then(
      (response) => {
        if (response.success) {
          setFormErrors([]);
          getShipmentDetails();
          getShipments();
        } else if (response.vErrors) {
          setFormErrors(response.vErrors);
        }
      }
    );
  };

  useEffect(getShipmentDetails, [shipmentId]);
  return (
    <div>
      {shptDtls && (
        <ShipmentDetails
          shptDtls={shptDtls}
          closeSLider={closeSLider}
          deleteShipment={deleteShipment}
          lockShipment={lockShipment}
          updateShipment={updateShipment}
          getShipment={getShipmentDetails}
          getShipments={getShipments}
          updateShipmentCharges={updateShipmentCharges}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ShipmentDetailsWrapper;
