import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, CardTitle } from "reactstrap";
import ShipmentSearcher from "./ShipmentSearcher";
import BorderedText from "components/bordered-text/BorderedText";
import getShipmentStatusColor from "views/shipments/getShipmentStatusColor";
import joinStrings from "utils/joinStrings";
import dateFormater from "utils/dateFormater";
import { digitsToCurrency } from "utils/currencyFormatter";
import { manifestColumn } from "./manifestColumn";
import { toast } from "react-toastify";

const AddPartialCard = ({
  manifestData,
  selectedShipmentIds,
  onCheckShipment,
}) => {
  const [shipmentList, setShipmentList] = useState([manifestData]);
  const [tableData, setTableData] = useState([]);
  const onSearchSelect = (data) => {
    const isShipmentDuplicate = shipmentList.find(
      (sh) => sh.shipmentId == data.shipmentId
    );
    if (isShipmentDuplicate) {
      toast.error(
        `Shipment #${data.shipmentNumber} was already added to the manifest`
      );
    } else if (data.shipmentType != "PTL") {
      toast.error(`Only PTL shipments are eligible to be part of a manifest`);
    } else setShipmentList([...shipmentList, data]);
  };
  useEffect(() => {
    const _tableData = shipmentList.map((data) => {
      return {
        ...data,
        check: (
          <input
            type={"checkbox"}
            disabled={data.shipmentId === manifestData.shipmentId}
            checked={selectedShipmentIds.includes(data.shipmentId)}
            onChange={(e) => {
              const removeUncheckedShipmentRow = () => {
                let modifiedData = shipmentList.filter(
                  (d) => d.shipmentId !== data.shipmentId
                );
                setShipmentList(modifiedData);
              };
              onCheckShipment(e, data.shipmentId, removeUncheckedShipmentRow);
            }}
          />
        ),
        nextEtaUtc: dateFormater(data.nextEtaUtc, "MMM DD YYYY, HH:mm"),
        nextScheduledUtcTime:
          (data.nextScheduledUtc, "MMM DD YYYY, HH:mm", true),
        scheduledPickUpTime: dateFormater(
          data.scheduledPickupUTC,
          "MMM DD YYYY, HH:mm",
          true
        ),
        pickup: joinStrings(
          [data.pickupAddress?.city, data.pickupAddress?.state],
          ", "
        ),
        scheduledDeliveryTime: dateFormater(
          data.scheduledDeliveryUTC,
          "MMM DD YYYY, HH:mm",
          true
        ),
        dropoff: joinStrings(
          [data.deliveryAddress?.city, data.deliveryAddress?.state],
          ", "
        ),
        price: digitsToCurrency(data.totalRate),
        status: (
          <BorderedText
            text={data.status}
            classes={getShipmentStatusColor(data.status)}
          />
        ),
      };
    });
    setTableData(_tableData);
  }, [shipmentList, selectedShipmentIds]);

  return (
    <Card className="mb-2">
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title">ADD SHIPMENT TO MANIFEST</p>
        </CardTitle>
        <div>
          <ShipmentSearcher onItemSelect={onSearchSelect} label="Shipment #" />
          <div className="summary-list small-table">
            <DataTable
              columns={manifestColumn.filter((r) => {
                if (
                  r.name !== "NEXT ETA" &&
                  r.name !== "ACTIONS" &&
                  r.name !== "MODE" &&
                  r.name !== "DISTANCE"
                )
                  return true;
              })}
              data={tableData}
              /*  onSort={(selectedColumn, sortDirection, rows) => {
              let sortedRows = selectedColumn.customSorter(
                selectedColumn,
                sortDirection,
                rows
              );
              setTableData(sortedRows);
            }} */
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddPartialCard;
