import DataTable from "react-data-table-component";
import BorderedText from "components/bordered-text/BorderedText";

import "./ServiceTasks.sass";
const ServiceTasksData = ({
  serviceTasks,
  selectedServiceTasks,
  setSelectedServiceTasks,
  hideColumn = false,
}) => {
  const handleRowClick = (event, row) => {
    if (event.target.checked) {
      setSelectedServiceTasks([...selectedServiceTasks, row]);
      return;
    } else {
      const deleteServiceTask = selectedServiceTasks.filter(
        (serviceTask) => serviceTask.inspectedItemId !== row.inspectedItemId
      );
      setSelectedServiceTasks([...deleteServiceTask]);
    }
  };

  const columns = [
    {
      name: "",
      cell: (row) => (
        <input
          type="checkbox"
          onClick={(event) => {
            handleRowClick(event, row);
          }}
        />
      ),
      omit: hideColumn,
    },
    {
      name: "SERVICE TASK",
      selector: (row) => row.serviceCode,
      grow: 1,
    },

    {
      name: "NOTE",
      selector: (row) => row.note,
      grow: 1,
    },
    {
      name: "STATUS",
      selector: (row) => row.resolutionStatus,
      grow: 1,
      cell: (row) => (
        <div>
          <BorderedText
            text={row.resolutionStatus}
            classes={row.resolutionStatus}
          />
        </div>
      ),
    },
  ];

  return (
    <main className="service_tasks_container">
      <DataTable columns={columns} data={serviceTasks} />
    </main>
  );
};

export default ServiceTasksData;
