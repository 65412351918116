import { useState } from "react";
import classNames from "classnames";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import SliderModal from "components/sliderModal/SliderModal";
import DriverEditDetails from "views/activities-v2/driverEditDetails/DriverEditDetail";
import "./VehicleDriversCard.sass";
import MilerButton from "components/milerButton/MilerButton";
const VehicleDriversCard = ({
  driver,
  setSelectedVehDrivers,
  selectedVehicle,
  selectedVehDrivers,
  assignError,
  assignTrip,
}) => {
  const selected = driver.assignedVehicleID === selectedVehicle;

  const [driverDetails, setDriverDetails] = useState(false);

  const onSelectVehicle = () => {
    !selected &&
      setSelectedVehDrivers({
        vehicleId: driver.assignedVehicleID,
        driverId: driver.driverId,
        coDriverId: driver.coDriverID || driver.coDriverId,
      });
  };

  /* const onUnselect = () => {
    if (!selectedVehDrivers.driverId && !selectedVehDrivers.coDriverId)
      setSelectedVehDrivers({});
  }; */

  const changeDriver = (e) => {
    //e.stopPropagation();
    const { name, checked } = e.target;
    let driverId =
      name === "driverId"
        ? driver.driverId
        : driver.coDriverID || driver.coDriverId;
    const value = checked ? driverId : undefined;
    setSelectedVehDrivers((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div
      className={classNames("miler-card vd-card", { selected: selected })}
      onClick={onSelectVehicle}
    >
      <div className="miler-card-header">
        <div className="miler-card-title">{`#${driver.vehicleUnitNumber}`}</div>

        <div>
          {(driver.vehicle_distance || driver.driver_distance) && (
            <div className="vd-card-distance-away">
              {Math.round(
                driver.vehicle_distance
                  ? driver.vehicle_distance
                  : driver.driver_distance
              )}
              mi away
            </div>
          )}
        </div>
      </div>
      {selected && assignError && (
        <>
          <hr />
          <div className="vd-card-error">
            <div className="vd-card-error-message">{assignError}</div>
            <div className="d-flex justify-content-between">
              <i />
              <MilerButton
                title="Override"
                onClick={() => assignTrip(true)}
                btnDark
                xs
              />
            </div>
          </div>
        </>
      )}
      <hr />
      <div>
        {driver.driverId && (
          <div className="vd-card-driver">
            <div className="vd-card-driver-info">
              {driver.hasdriverpayee ? (
                <ProfilePlaceholder
                  firstName={driver.driverFirstName}
                  lastName={driver.driverLastName}
                />
              ) : (
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Payee Not Setup For Driver"
                >
                  <ProfilePlaceholder error />
                </div>
              )}

              <div className="driver-name">
                {`${driver.driverFirstName} ${driver.driverLastName}`}{" "}
                {driver.driverNumber && (
                  <span
                    className="driver_number_link"
                    onClick={() => setDriverDetails(true)}
                  >
                    ({`${driver.driverNumber}`})
                  </span>
                )}
              </div>
            </div>
            {selected && (
              <input
                name="driverId"
                type="checkbox"
                className="vd-card-driver-check"
                onChange={changeDriver}
                checked={selectedVehDrivers.driverId}
              />
            )}
          </div>
        )}
        {(driver.coDriverID || driver.coDriverId) && (
          <div className="vd-card-driver">
            <div className="vd-card-driver-info">
              {driver.hascdriverpayee ? (
                <ProfilePlaceholder
                  firstName={driver.coDriverFirstName}
                  lastName={driver.coDriverLastName}
                />
              ) : (
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Payee Not Set up For Driver"
                >
                  <ProfilePlaceholder error />
                </div>
              )}
              <div className="driver-name">
                {`${driver.coDriverFirstName} ${driver.coDriverLastName}`}{" "}
                {driver.coDriverNumber && (
                  <span className="text-muted">({driver.coDriverNumber})</span>
                )}
              </div>
            </div>

            {selected && (
              <input
                name="coDriverId"
                type="checkbox"
                className="vd-card-driver-check"
                onChange={changeDriver}
                checked={selectedVehDrivers.coDriverId}
              />
            )}
          </div>
        )}
      </div>
      <SliderModal clickState={driverDetails} setClickState={setDriverDetails}>
        <DriverEditDetails
          driverId={driver.driverId}
          setDriverDetails={setDriverDetails}
        />
      </SliderModal>
    </div>
  );
};

export default VehicleDriversCard;
