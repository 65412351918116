import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import { postElement } from "services/serviceRepairApi";
import "./NewServiceRepair.sass";

const NewService = ({
  setNewServiceOpen,
  newServiceCode,
  onItemCreated,
  setItemId,
}) => {
  const [state, setState] = useState({ code: newServiceCode });

  const createService = () => {
    state.serviceCategory = 7;
    postElement(`/Carrier/Service`, state).then((response) => {
      if (response.success) {
        setItemId(response.response.data.data.serviceId);
      }
      if (onItemCreated) {
        onItemCreated(state);
        setNewServiceOpen(false);
      }
    });
  };

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Service</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setNewServiceOpen(false);
            }}
          />

          <MilerButton btnDark title="Save" onClick={() => createService()} />
        </div>
      </div>

      <div className="slider-display-body">
        <div className="miler-card mt-0">
          <div className="miler-card-title">Service Details</div>
          <div className="row">
            <div className="col-12 col-md-12">
              <InputMiler
                value={state.code}
                type="text"
                label="Service Item Name"
                required
                onChange={(e) =>
                  onChange({ name: "code", value: e.target.value })
                }
              />
            </div>

            <div className="col-12 col-md-12">
              <InputMiler
                type="text"
                label="Description"
                required
                onChange={(e) =>
                  onChange({ name: "description", value: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewService;
