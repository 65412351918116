import DataTable from "react-data-table-component";
const DrugTable = ({ columns, data }) => {
  return (
    <div className="summary-list">
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default DrugTable;
