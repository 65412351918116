import { Avatar } from "antd";
import classNames from "classnames";
import { getCookie } from "utils/cookies";
import dateFormatter from "utils/dateFormater";
import joinStrings from "utils/joinStrings";
const MessageCard = ({ message, getColor, index, showAvatar }) => {
  const { userId } = getCookie("userInfo");
  if (userId === message.sender?.sender_user_id) {
    if (!message.sender.full_name.includes("(You)")) {
      message.sender.full_name = message.sender?.full_name + " (You).";
    }
  }
  return (
    <div
      className={classNames({
        "message-container": true,
        "flex-row-reverse": message.direction === "Outbound",
      })}
    >
      {showAvatar && (
        <Avatar
          size={30}
          style={{
            alignSelf: "flex-end",
            marginBottom: 10,
            backgroundColor: getColor(index + 1),
            fontWeight: "500",
            color: "#202F53",
            fontSize: "15px",
            textTransform: "uppercase",
          }}
        >
          {message.sender.first_name?.charAt(0)}
          {message.sender.last_name?.charAt(0)}
        </Avatar>
      )}

      <div
        className={classNames({
          message: true,
          "align-left": message.direction !== "Outbound",
          "margin-left": message.direction !== "Outbound" && !showAvatar,
          "align-right": message.direction === "Outbound",
          "margin-right": message.direction === "Outbound" && !showAvatar,
        })}
      >
        {message.direction === "Outbound" && (
          <div className="sender-name">
            {message.sender?.full_name ??
              joinStrings(
                [message.sender?.first_name, message.sender?.last_name],
                "\u00A0"
              )}

            {/* <span className="dot-sep">.</span> &nbsp; */}
            <span className="user-type">
              {message.sender?.user_type?.replace("Carrier", " ")}
            </span>
          </div>
        )}
        <pre
          className={classNames({
            "text-message": true,
            "text-message-white": message.direction === "Outbound",
            "text-message-black": message.direction !== "Outbound",
          })}
        >
          {message.message}
        </pre>
        <div className="send-date">
          {dateFormatter(
            message.message_utc || message.created_utc,
            "MMM DD, YYYY h:mm A"
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
