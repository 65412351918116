import BorderedText from "components/bordered-text/BorderedText";
import "./StopDetails.sass";
import { useState } from "react";
import Overview from "./overview/Overview";
import Edit from "./edit/Edit";
import Notes from "./notes/Notes";

const StopDetails = ({ setDetailsOpen, stop, customer, handleFetchData}) => {
  const [currentTab, setCurrentTab] = useState(1);
  const setUpServiceFailureNote = () => {
    let serviceFailure = {
      promiseDelayReason: "",
      ServiceFailure: "",
    };
    const foundServiceFailure = (stop?.stopNotes || []).find(
      (l) => l.stopNoteType === "Service Failure"
    );
    serviceFailure.promiseDelayReason = stop.promiseDelayReason || "";
    serviceFailure.ServiceFailure = foundServiceFailure?.note || "";

    return serviceFailure;
  };
  return (
    <div className="stop_card_details_container">
      <div className="header">
        <div className="first">
          <div className="text">
            <div className="title">
              <div>{stop.stopType}</div>
              <BorderedText text={stop.progress} classes={stop.progress} />
            </div>
            <div className="desc">
              {`${customer} | ${stop.address && stop.address?.fullAddress}`}
            </div>
          </div>
          <div className="close_x" onClick={() => setDetailsOpen(false)}>
            <i className="mil-close-bg" />
          </div>
        </div>
        <div className="second">
          <div className="tabs">
            <div
              className={currentTab === 1 ? "tab_selected" : "tab"}
              onClick={() => setCurrentTab(1)}
            >
              Overview
            </div>
            <div
              className={currentTab === 2 ? "tab_selected" : "tab"}
              onClick={() => setCurrentTab(2)}
            >
              Edit
            </div>
            <div
              className={currentTab === 3 ? "tab_selected" : "tab"}
              onClick={() => setCurrentTab(3)}
            >
              Notes
            </div>
          </div>
        </div>
      </div>
      <div className="body">
        {currentTab === 1 ? (
          <Overview stop={stop} serviceFailure={setUpServiceFailureNote()} />
        ) : currentTab === 2 ? (
          <Edit
            stop={stop}
            setDetailsOpen={setDetailsOpen}
            serviceFailure={setUpServiceFailureNote()}
            handleFetchData={handleFetchData}
          />
        ) : (
          
          <Notes stop={stop} handleFetchData={handleFetchData} />
         
           
        )}
      </div>
    </div>
  );
};

export default StopDetails;
