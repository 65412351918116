import { useEffect, useState } from "react";
import AssociatedNotes from "components/associatedNotes/AssociatedNotes";

import { getElements, putElement } from "services/serviceRepairApi";
import Loading from "components/loading/Loading";
const Notes = ({ id }) => {
  const [repairOrderNotes, setRepairOrderNotes] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState({
    loadingRepairOrdersNotes: false,
  });

  const getRepairOrdersNotes = (id, load) => {
    if (load) {
      setLoadingStatus((prev) => ({ ...prev, loadingRepairOrdersNotes: true }));
    }
    getElements(`/RepairOrder/${id}/Notes`)
      .then((response) => {
        setRepairOrderNotes(response.data);
        setLoadingStatus((prev) => ({
          ...prev,
          loadingRepairOrdersNotes: false,
        }));
      })
      .catch(() => {
        setLoadingStatus((prev) => ({
          ...prev,
          loadingRepairOrdersNotes: false,
        }));
      });
  };

  const updateRepairOrderNotes = () => {
    putElement(`/RepairOrder/${id}/Notes`, repairOrderNotes).then(
      (response) => {
        if (response.success) {
          getRepairOrdersNotes(id, false);
        }
      }
    );
  };

  const deleteNote = (note, index) => {
    note.splice(index, 1);
    setRepairOrderNotes([...note]);
    updateRepairOrderNotes();
    return;
  };

  useEffect(() => {
    if (id) {
      getRepairOrdersNotes(id, true);
    }
  }, [id]);

  const { loadingRepairOrdersNotes } = loadingStatus;
  return (
    <>
      {loadingRepairOrdersNotes ? (
        <section className="mt-4">
          <Loading />
        </section>
      ) : (
        <AssociatedNotes
          notes={repairOrderNotes || []}
          onAddNote={(note) => {
            const notes = repairOrderNotes || [];
            notes.push(note);
            updateRepairOrderNotes();
            setRepairOrderNotes(notes);
          }}
          loadingRepairOrdersNotes={loadingRepairOrdersNotes}
          hideSelectOption={true}
          deleteNote={deleteNote}
        />
      )}
    </>
  );
};

export default Notes;
