import { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { getCookie } from "utils/cookies";
import downloadDocument from "utils/downloadDocument";
import Loading from "components/loading/Loading";
import "./docPreview.sass";
import joinStrings from "utils/joinStrings";
import SliderModal from "components/sliderModal/SliderModal";
import EmailComponent from "components/email/Email";
const DocDialog = ({
  open,
  customType = "",
  url,
  isDirectory = false,
  onCancel,
  settlement,
  filteredCurrentSettlement,
}) => {
  const cachedJwt = getCookie("cachedJwt") || {};
  const { userId, carrierId, given_name, family_name, email } =
    getCookie("userInfo") || {};
  const carrierName = joinStrings([given_name, family_name], " ");

  const [openEmail, setOpenEmail] = useState(false);

  const [documentPreview, setDocumentPreview] = useState({
    showDocument: false,
    url: null,
  });

  const headers = {
    Authorization: `Bearer ${cachedJwt.accessToken}`,
    "X-Miler-UserId": userId,
    "X-Miler-CarrierId": carrierId,
    version: 2,
  };
  const docs = [
    {
      uri: `https://dev-carrier.miler.com/Document/Download?documentUrl=${
        documentPreview.url || url
      }${isDirectory ? "&isDirectory=true" : ""}`,
    },
  ];
  const LoadingRenderer = () => {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  };

  return (
    <section>
      <Modal
        style={{
          minWidth: "50vw",
          minHeight: "50vh",
          maxHeight: "50vh",
        }}
        isOpen={Boolean(open || documentPreview.showDocument)}
        toggle={() => {
          onCancel();
          setDocumentPreview((prev) => ({
            ...prev,
            showDocument: false,
            url: null,
          }));
        }}
      >
        <ModalHeader
          toggle={() => {
            onCancel();
            setDocumentPreview((prev) => ({
              ...prev,
              showDocument: false,
              url: null,
            }));
          }}
        >
          Preview
        </ModalHeader>
        <ModalBody>
          <div
            className=""
            style={{
              minHeight: "50%",
              maxHeight: "100%",
              minWidth: "40%",
              overflowY: "auto",
            }}
          >
            <DocViewer
              style={{
                width: "100%",
                height: "70vh",
                backgroundColor: "#e2e6ea",
              }}
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              prefetchMethod="GET"
              requestHeaders={headers}
              config={{
                loadingRenderer: {
                  overrideComponent: LoadingRenderer,
                  showLoadingTimeout: false,
                },
                header: { disableHeader: true, disableFileName: true },
                pdfZoom: {
                  defaultZoom: 1.1, // 1 as default,
                  zoomJump: 0.2, // 0.1 as default,
                },
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <MilerButton
            title="Cancel"
            onClick={() => {
              onCancel();
              setDocumentPreview((prev) => ({
                ...prev,
                showDocument: false,
                url: null,
              }));
            }}
          />
          {settlement &&
            filteredCurrentSettlement &&
            filteredCurrentSettlement.length > 0 &&
            filteredCurrentSettlement[0].payeeEmail &&
            settlement.accountPayableId &&
            !documentPreview.showDocument && (
              <MilerButton
                onClick={() => {
                  // sendDocToMail();
                  setOpenEmail(true);
                  onCancel();
                }}
                title="Send Email"
                btnDark
              />
            )}

          <MilerButton
            onClick={() => {
              downloadDocument(url, isDirectory, customType);
              onCancel();
              setDocumentPreview((prev) => ({
                ...prev,
                showDocument: false,
                url: null,
              }));
            }}
            title="Download"
            btnDark
          />
        </ModalFooter>
      </Modal>
      {settlement && filteredCurrentSettlement && (
        <SliderModal
          clickState={openEmail}
          setClickState={setOpenEmail}
          width="50"
        >
          <EmailComponent
            recipientEmailAddresses={filteredCurrentSettlement[0]?.payeeEmail}
            senderEmailAddress={email}
            subject={`Settlement #${settlement?.accountPayableId}`}
            message={`Your settlement #${settlement?.accountPayableId} is attached for your review`}
            settlement={settlement}
            filteredCurrentSettlement={filteredCurrentSettlement}
            carrierName={carrierName}
            url={url}
            setOpenEmail={setOpenEmail}
            setDocumentPreview={setDocumentPreview}
          />
        </SliderModal>
      )}
    </section>
  );
};

export default DocDialog;
