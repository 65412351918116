import DataTable from "react-data-table-component";
import { digitsToCurrency } from "utils/currencyFormatter";
import "./ShipmentCharges.sass";
import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import ChargeForm from "components/shipment/shipmentForm/charges/ChargeForm";
import SliderModal from "components/sliderModal/SliderModal";
import { toast } from "react-toastify";
import { chargeContract } from "views/shipments/shipmentForm/shipmentContract";
import CustomerRatingForm from "components/shipment/shipmentForm/customRating/CustomerRating";

const ShipmentCharges = ({ shipment, dispatch, errors }) => {
  const [showChargesForm, setShowChargesForm] = useState(false);
  const [showCustomerRatingForm, setShowCustomerRatingForm] = useState(false);

  const handleAddCharge = (charge) => {
    if (charge.value.chargeType != "Line Haul" || !shipment?.brokerId) {
      dispatch(charge);
    } else {
      toast.error("Cannot add or edit Line Haul for this shipment");
    }
  };

  const handleEditChargeClick = (charge) => {
    if (charge._charge.chargeType != "Line Haul" || !shipment?.brokerId) {
      setShowChargesForm(charge);
    } else {
      toast.error("Cannot add or edit Line Haul for this shipment");
    }
  };

  const handleDeleteCharge = (charge, index) => {
    if (charge.chargeType != "Line Haul" || !shipment?.brokerId) {
      dispatch({ category: "deleteCharge", index });
    } else {
      toast.error("Cannot add or edit Line Haul for this shipment");
    }
  };

  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key &&
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const charges = shipment?.charges?.map((charge, index) => ({
    ...charge,
    actions: (
      <div className="shipment-form-stops-charges-actions">
        <i
          style={{ marginRight: "10px", cursor: "pointer" }}
          className="mil-edit-o"
          onClick={() => handleEditChargeClick({ _charge: charge, index })}
        />
        <i
          className="mil-delete-o"
          onClick={() => handleDeleteCharge(charge, index)}
          style={{ cursor: "pointer" }}
        />
      </div>
    ),
  }));
  const conditionalRowStyles = [
    {
      when: (row) => row.isFooter,
      style: {
        fontWeight: "bolder",
        fontSize: "14px",
        border: "1px solid #E2E6EA",
        marginBottom: "10px",
      },
    },
  ];

  const ChargesColumns = [
    {
      name: "TYPE",
      selector: (row) => row.chargeType,
      grow: 1,
    },
    {
      name: "RATING",
      selector: (row) => row.rating,
      grow: 1,
    },
    {
      name: "RATE",
      selector: (row) => (row.isFooter ? "" : digitsToCurrency(row.rate)),
      grow: 1,
    },
    {
      name: "UNIT",
      selector: (row) => row.unit,
      grow: 1,
    },
    {
      name: "SUBTOTAL",
      selector: (row) => digitsToCurrency(row.subTotal),
      grow: 1,
    },
    {
      name: "ACTIONS",
      selector: (row) => row.actions,
      right: true,
    },
  ];
  const chargesData =
    charges.length > 0
      ? [
          ...charges,
          {
            isFooter: true,
            chargeType: "Total",
            subTotal: shipment?.totalCharges,
          },
        ]
      : charges || [];
      
  return (
    <div className="miler-card">
      <p style={{ fontWeight: "bold" }} className="charges-title">
        Charges
      </p>
      <div className="shipment-charges">
        <div className="summary-lis t small-table">
          <DataTable
            columns={ChargesColumns}
            conditionalRowStyles={conditionalRowStyles}
            data={chargesData}
          />
        </div>
      </div>

      <MilerButton
        title="New Charge"
        icon="mil-plus"
        onClick={() =>
          setShowChargesForm({
            _charge: chargeContract,
            index: -1,
          })
        }
      />
      <MilerButton
        title="Calculate Customer Rating"
        onClick={() => {
          setShowCustomerRatingForm(true);
        }}
      />

      <SliderModal
        setClickState={setShowChargesForm}
        clickState={Boolean(showChargesForm)}
        from="bottom"
      >
        <ChargeForm
          setOpenForm={setShowChargesForm}
          state={showChargesForm}
          dispatch={handleAddCharge}
          findError={findError}
          shipment={shipment}
        />
      </SliderModal>
      <SliderModal
        clickState={showCustomerRatingForm}
        setClickState={setShowCustomerRatingForm}
        from="bottom"
        minHeight
      >
        <CustomerRatingForm
          id={shipment?.shipmentId}
          dispatch={dispatch}
          showCustomerRatingForm={showCustomerRatingForm}
          setShowCustomerRatingForm={setShowCustomerRatingForm}
          shipperId={shipment.shipperId}
        />
      </SliderModal>
    </div>
  );
};

export default ShipmentCharges;
