import { useEffect, useState } from "react";
import { carriersColumns } from "./carriers-columns";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import DataTable from "react-data-table-component";
import joinStrings from "utils/joinStrings";
import SliderModal from "components/sliderModal/SliderModal";
import CarrierDetails from "../carrierDetails/carrierDetails";
import { getElements, postElement } from "services/milerApi";
import { getAccessToken } from "services/Auth";
const CarrierSummary = ({ carriers }) => {
  const [carrierDetailsOpen, setCarrierDetailsOpen] = useState(false);
  const [switchableCarriers, setSwitchableCarriers] = useState([]);

  const switchCarrier = (id) => {
    let switchable = switchableCarriers.find((c) => c === id);
    switchable &&
      postElement(`/Carrier/${id}/Switch`).then(() => {
        getAccessToken("REFRESH");
        window.location.reload();
      });
  };

  const getCarriers = () => {
    getElements("/Config/User/Profile").then((res) => {
      let ids = (res.data.carriers || []).map((c) => c.carrier_id);
      setSwitchableCarriers(ids);
    });
  };

  useEffect(getCarriers, []);

  const _data = carriers.map((data) => {
    let billingStreet = data.billingAddress?.addressLine || "";
    let billingCity = data.billingAddress?.city || "";
    let billingState = data.billingAddress?.state || "";
    let billingRegion = joinStrings([billingCity, billingState], " ");
    let mailingStreet = data.mailingAddress?.addressLine || "";
    let mailingCity = data.mailingAddress?.city || "";
    let mailingState = data.mailingAddress?.state || "";
    let mailingRegion = joinStrings([mailingCity, mailingState], ", ");
    return {
      usdot: data.usdot,
      dba: data.dba,
      company: data.company,
      companyLogo: data.companyLogoURL,
      mcNumber: data.mcNumber,
      billingStreet,
      billingRegion,
      mailingStreet,
      mailingRegion,
      dimensions: "",
      actions: (
        <div className="table-actions">
          <i
            className="mil-edit-o"
            style={{ cursor: "pointer" }}
            onClick={() => setCarrierDetailsOpen(data)}
          />
          <ElipsisMenu>
            <MenuItem
              title="Switch"
              onClick={() => switchCarrier(data.carrierId)}
            />
          </ElipsisMenu>
        </div>
      ),
    };
  });

  return (
    <div className="summary-list">
      <DataTable columns={carriersColumns} data={_data} />
      <SliderModal
        clickState={Boolean(carrierDetailsOpen)}
        setClickState={setCarrierDetailsOpen}
      >
        <CarrierDetails
          carrier={carrierDetailsOpen}
          setCarrierDetailsOpen={setCarrierDetailsOpen}
        />
      </SliderModal>
    </div>
  );
};

export default CarrierSummary;
