import InputMiler from "components/inputMiler/InputMiler";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { Col } from "reactstrap";
import findError from "utils/findError";

export const FuelSurChargeForm = ({
  bracket,
  onSave,
  onChange,
  onCancel,
  errors,
}) => (
  <div className="fsc-form">
    <Col>
      <InputMiler
        type="number"
        disabled
        value={bracket.startingPrice}
        onChange={(e) => onChange("startingPrice", e.target.value)}
        error={findError("startingPrice", errors)}
      />
    </Col>
    <Col>
      <InputMiler
        type="number"
        value={bracket.endingPrice}
        onChange={(e) => onChange("endingPrice", e.target.value)}
        error={findError("endingPrice", errors)}
      />
    </Col>
    <Col>
      <InputMiler
        type="number"
        value={bracket.surcharge}
        onChange={(e) => onChange("surcharge", e.target.value)}
        error={findError("surcharge", errors)}
      />
    </Col>
    <Col className="action mb-0">
      <BsCheckLg
        onClick={onSave}
        style={{
          color: "#2ECD7D",
          cursor: "pointer",
          fontSize: "1.3rem",
          fontWeight: "bolder",
        }}
      />
      <BsXLg
        onClick={onCancel}
        style={{
          color: "#FF4D4F",
          cursor: "pointer",
          fontSize: "16px",
          marginLeft: "8px",
          fontWeight: "bolder",
        }}
      />
    </Col>
  </div>
);
