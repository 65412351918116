import { digitsToCurrency } from "utils/currencyFormatter";
import DataTable from "react-data-table-component";
import "./RepairSummary.sass";

const RepairSummary = ({ repairItems }) => {
  const repairSummaryTableColumns = [
    {
      name: "ITEM NAME",
      selector: (row) => (
        <div
          style={{
            maxWidth: "350px",
            whiteSpace: "wrap",
          }}
        >
          {row.serviceCode}
        </div>
      ),
      grow: 4,
    },
    {
      name: "LABOR",
      selector: (row) => digitsToCurrency(row.laborPrice),
      grow: 1.5,
    },
    {
      name: "PARTS",
      selector: (row) => digitsToCurrency(row.partsPrice),
      grow: 1.5,
    },
    {
      name: "TOTAL",
      selector: (row) => digitsToCurrency(row.totalCharges),
      grow: 1,
    },
  ];

  const summaryTableColumns = [
    {
      name: "Summary",
      selector: (row) => (
        <div
          style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#202F53" }}
        >
          {row.title}
        </div>
      ),
      grow: 4,
    },
    {
      name: "LABOR",
      selector: (row) => (
        <div
          style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#202F53" }}
        >
          {digitsToCurrency(row.totalLabor)}
        </div>
      ),
      grow: 1.5,
    },
    {
      name: "PARTS",
      selector: (row) => (
        <div
          style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#202F53" }}
        >
          {digitsToCurrency(row.totalPartsPrice)}
        </div>
      ),
      grow: 1.5,
    },
    {
      name: "TOTAL",
      selector: (row) => (
        <div
          style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#202F53" }}
        >
          {digitsToCurrency(row.totalCharges)}
        </div>
      ),
      grow: 1,
    },
  ];

  const totalLabor = repairItems.reduce(
    (acc, current) => acc + current.laborPrice,
    0
  );
  const totalPartsPrice = repairItems.reduce(
    (acc, current) => acc + current.partsPrice,
    0
  );
  const totalCharges = repairItems.reduce(
    (acc, current) => acc + current.totalCharges,
    0
  );
  const summaryData = [
    {
      title: "Summary",
      totalLabor: totalLabor,
      totalPartsPrice: totalPartsPrice,
      totalCharges: totalCharges,
    },
  ];
  return (
    <div className="repair_summary_charges_table">
      <div className="summary-list">
        <DataTable
          columns={repairSummaryTableColumns}
          data={repairItems || []}
        />
      </div>
      <div
        style={{
          border: "1px solid #E2E6EA",
        }}
        className="summary-list"
      >
        <DataTable
          columns={summaryTableColumns}
          data={summaryData || []}
          noTableHead
          noHeader
        />
      </div>
    </div>
  );
};

export default RepairSummary;
