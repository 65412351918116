import MilerButton from "components/milerButton/MilerButton";
import "./profile.sass";
const GetStarted = ({ setCurrentStep, invited_by }) => {
  return (
    <section className="profile">
      <section className="profile_content">
        <p className="content">
          Welcome to Miler
          {invited_by && (
            <p className="sub_content">
              You have been invited by {invited_by} to join their preferred
              carrier network. To help you get up and running in no time, please
              complete the onboarding steps.
            </p>
          )}
          {!invited_by && (
            <p className="subcontent_info">
              To help you get up and running in no time, please complete the
              onboarding steps.
            </p>
          )}
        </p>

        <MilerButton
          title="Get Started"
          btnDark
          className="action_btn"
          onClick={() => setCurrentStep((prev) => prev + 1)}
        />
      </section>
    </section>
  );
};

export default GetStarted;
