//unify="Miler Unify (TMS + Maintenance)";
//ramify="Miler Ramify (TMS + Maintenance)";
//tms="Miler Amplify (TMS)";
//mnt="Miler Rectify Plus (Maintenance)";
//4pl="Miler 4PL";
//free="Miler Rectify (Free)";

const sidebarRoutes = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: "mil-dashboard",
    showFor: "unify,ramify,tms,mnt,free", //type of subscription_plan to show the menu for.
    permissionPostFix: "none", // Permission postfix as it appears on `name` attribute of permission
    // e.g in { name: view.fleet } the postfix is `fleet`
    submenu: [],
  },
  {
    label: "Activities",
    path: "/activity",
    icon: "mil-stroke",
    showFor: "unify,ramify,tms",
    permissionPostFix: "none",
    submenu: [],
  },
  {
    label: "Marketplace",
    path: "/marketplace",
    icon: "mil-market-f",
    showFor: "unify,ramify,tms,4pl,free",
    permissionPostFix: "none",
    submenu: [],
  },
  {
    label: "Shipments",
    path: "/shipments",
    icon: "mil-truck-delivery-f",
    showFor: "unify,ramify,tms,4pl,free",
    permissionPostFix: "shipment",
    submenu: [],
  },
  {
    label: "Customers",
    path: "/customers",
    icon: "mil-user-group-f",
    showFor: "unify,ramify,tms,4pl,free",
    permissionPostFix: "customer",
    submenu: [],
  },
  {
    label: "Carriers",
    path: "/carriers",
    icon: "mil-truck-fast-f",
    showFor: "4pl",
    //permissionPostFix: "",
    submenu: [],
  },
  {
    label: "Lanes",
    path: "/freight",
    icon: "mil-location-company-f",
    showFor: "unify,ramify,tms,4pl",
    permissionPostFix: "none",
    submenu: [
      {
        title: "Address Books",
        target: "/addressBooks",
        showFor: "unify,ramify,tms,4pl",
      },
      {
        title: "Territory",
        target: "/territory",
        showFor: "unify,ramify,tms,4pl",
      },
      {
        title: "Preferred Lanes",
        target: "/preferredLane",
        showFor: "unify,ramify,tms,4pl",
      },
    ],
  },
  {
    label: "Communication",
    path: "/communication",
    icon: "mil-message-f",
    showFor: "unify,ramify,tms,4pl",
    permissionPostFix: "none",
    submenu: [
      {
        title: "Messaging",
        target: "/messages",
        showFor: "unify,ramify,tms,4pl,free",
      },
      {
        title: "WhatsApp",
        target: "/whatsapp",
        showFor: "unify,ramify,tms,4pl,free",
      },
      {
        title: "Calls",
        target: "/calls",
        showFor: "unify,ramify,tms,4pl,free",
      },
      {
        title: "Edi Logs",
        target: "/ediLogs",
        showFor: "unify,ramify,tms,4pl,free",
      },
    ],
  },
  {
    label: "Fleet",
    path: "/fleet",
    icon: "mil-fleet",
    showFor: "unify,ramify,tms,mnt,free",
    permissionPostFix: "fleet",
    submenu: [
      {
        title: "Drivers",
        target: "/drivers",
        showFor: "unify,ramify,tms,mnt,free",
      },
      {
        title: "Vehicles",
        target: "/vehicles",
        showFor: "unify,ramify,tms,mnt,free",
      },
      {
        title: "Trailers",
        target: "/trailers",
        showFor: "unify,ramify,tms,mnt,free",
      },
      {
        title: "Fleet Groups",
        target: "/fleetgroups",
        showFor: "unify,ramify,tms,mnt",
      },
      {
        title: "Fleet Owners",
        target: "/fleet_owner",
        showFor: "unify,ramify,tms",
      },
      {
        title: "Fuel Card",
        target: "/fuel_card",
        showFor: "unify,ramify,tms,mnt,free",
      },
      {
        title: "FSC Programs",
        target: "/fuel_surcharges",
        showFor: "unify,ramify,tms,mnt",
      },
      {
        title: "Fuel Price",
        target: "/fuel_price",
        showFor: "unify,ramify,tms,mnt",
      },
      {
        title: "IFTA Report",
        target: "/iftareport",
        showFor: "unify,ramify,tms,mnt,free",
      },
    ],
  },
  {
    label: "Accounting",
    path: "/accounting",
    icon: "mil-accounting-f",
    showFor: "unify,ramify,tms,4pl",
    permissionPostFix: "accounting",
    submenu: [
      {
        title: "Settlements",
        target: "/settlements",
        showFor: "unify,ramify,tms",
      },
      {
        title: "Invoices",
        target: "/invoices",
        showFor: "unify,ramify,tms",
      },

      {
        title: "Carrier Charges",
        target: "/carrier_charges",
        showFor: "4pl",
      },
      {
        title: "Receivables",
        target: "/accountReceivable",
        showFor: "unify,ramify,tms",
      },
      {
        title: "Transactions",
        target: "/transactions",
        showFor: "unify,ramify,tms",
      },
      {
        title: "Payees",
        target: "/payees",
        showFor: "unify,ramify,tms",
      },
    ],
  },
  {
    label: "Compliance",
    path: "/compliance",
    icon: "mil-compliance",
    showFor: "unify",
    permissionPostFix: "compliance",
    submenu: [
      {
        title: "Overview",
        target: "/overview",
        showFor: "unify",
      },
      {
        title: "Driver Logs",
        target: "/driverLogs",
        showFor: "unify",
      },
      {
        title: "Roadside Inspections",
        target: "/roadsideInspections",
        showFor: "unify",
      },
      {
        title: "Accidents",
        target: "/accidents",
        showFor: "unify",
      },
      {
        title: "Driver D&A Tests",
        target: "/daTests",
        showFor: "unify",
      },
      {
        title: "ELD Disconnections",
        target: "/eldDisconnections",
        showFor: "unify",
      },
      {
        title: "Freight Claims",
        target: "/freightClaims",
        showFor: "unify",
      },
    ],
  },
  {
    label: "Maintenance",
    path: "/maintenance",
    icon: "mil-maintenance-f",
    showFor: "unify,ramify,mnt",
    permissionPostFix: "maintenance",
    submenu: [
      {
        title: "Overview",
        target: "maintenance_dashboard",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Service Requests",
        target: "/service_requests",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Service Repair Orders",
        target: "/service_repairs",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Service Tasks",
        target: "/service_tasks",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Service Reminders",
        target: "/service_reminders",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Inspections",
        target: "/inspections",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Parts",
        target: "/parts",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Purchase Orders",
        target: "/purchase_orders",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Part Vendors",
        target: "/vendors",
        showFor: "unify,ramify,mnt",
      },
      {
        title: "Service Providers",
        target: "/service_providers",
        showFor: "unify,ramify,mnt",
      },
    ],
  },
  {
    label: "Reports",
    path: "/reports",
    icon: "mil-report-f",
    showFor: "unify,ramify,tms,4pl",
    permissionPostFix: "reports", //Customized in Sidebar.js
    submenu: [],
  },
  {
    label: "Settings",
    path: "/settings",
    icon: "mil-setting-f",
    showFor: "unify,ramify,tms,4pl,mnt,free",
    permissionPostFix: "carrier.info",
    submenu: [
      {
        title: "Carrier Settings",
        target: "/carrier-profile-settings",
        showFor: "unify,ramify,tms,4pl,mnt,free",
      },
      {
        title: "Notification Setting",
        target: "/notification-settings",
        showFor: "unify,ramify,tms,4pl,mnt,free",
      },
      {
        title: "Data Import",
        target: "/data-import",
        showFor: "unify,ramify,tms,4pl,mnt,free",
      },
      {
        title: "Integration",
        target: "/integration",
        showFor: "unify,ramify,tms,4pl,mnt,free",
      },
      {
        title: "User Management",
        target: "/user-management",
        showFor: "unify,ramify,tms,4pl,mnt",
        permissionPostFix: "user",
      },
    ],
  },
];

export default sidebarRoutes;
