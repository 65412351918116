import InputMiler from "components/inputMiler/InputMiler"
import { Card } from "reactstrap";

const Notes = ({ dispatch, state }) => {
    return (
        <div className="mt-3">
            <Card style={{ padding: "10px" }}>
                <p>Notes</p>
                <InputMiler
                    value={state?.notes}
                    type="textarea"
                    onChange={(e) => dispatch({ action: 'notes', value: e.target.value })}
                />
            </Card>
        </div>
    )
}

export default Notes;