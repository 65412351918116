import CellBuilder from "components/cellBuilderTable/CellBuilder";
import dateFormatter from "utils/dateFormater";

export const EarningsColumns = [
  {
    name: "Trip #",
    selector: (row) => row.tripId,
    cell: (row) => CellBuilder(row.tripId),
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    cell: (row) => CellBuilder(row.amount),
  },

  {
    name: "Approval Date",
    selector: (row) => dateFormatter(row.approvedUtc),
    cell: (row) => CellBuilder(dateFormatter(row.approvedUtc)),
    grow: 0.8,
  },

  {
    name: "Approved By",
    selector: (row) => row.approvedBy,
    cell: (row) => CellBuilder(row.approvedBy),
    grow: 0.8,
  },
];
