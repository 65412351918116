import InputMiler from "components/inputMiler/InputMiler";
import "./Edit.sass";
import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import MilerButton from "components/milerButton/MilerButton";
import { useContext, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { appContext } from "services/AppContext";
import Equipmentsearcher from "components/inputMiler/advanceSearch/EquipmentSearcher";
import { putElement } from "services/milerApi";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import { toast } from "react-toastify";
import calculateStopsDistance from "utils/calculateStopsDistance";
import ServiceFailureCard from "../ServiceFailureCard";

const Edit = ({ stop, setDetailsOpen, serviceFailure, handleFetchData }) => {
  const { enums } = useContext(appContext);
  const [trailer, setTrailer] = useState({
    trailerNumber: stop.trailerNumber,
    trailerType: stop.trailerType,
  });
  const [stopReason, setStopReason] = useState(stop.stopReason);
  const [openNew, setOpenNew] = useState(false);
  const activityContext = useContext(ActivityContext);
  const getTrip = activityContext?.getTrip;
  const currentTrip = activityContext?.currentTrip;
  // eslint-disable-next-line
  const [reference, setReference] = useState([...stop.stopReferences]);
  const [address, setAddress] = useState(stop.address);
  const [emptyDistance, setEmptyDistance] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [originalReference, setOriginalReference] = useState({});
  const [newReference, setNewReference] = useState({});
  const [stopTimes, setStopTimes] = useState({
    actualArrival: stop.actualArrivalLocal,
    actualDeparture: stop.actualDepartureLocal,
  });

  const [delayedInfo, setDalayedInfo] = useState(serviceFailure);
  const onDelayedInfoChanged = (e) => {
    const { name, value } = e.target;
    setDalayedInfo({ ...delayedInfo, [name]: value });
  };

  const addReference = () => {
    setReference([...reference, newReference]);
    setNewReference({});
  };
  const deleteRef = (i) => {
    const modParts = reference.filter((r, index) => index !== i);
    setReference(modParts);
  };
  const onSearchedItemSelected = (item) => {
    if (item.newEntry) {
      setTrailer({ ...trailer, trailerNumber: item.value });
    } else {
      let items = item.equipment.split(" ");
      setTrailer({
        ...trailer,
        trailerNumber: items[0],
        trailerType: items[1],
        trailerId: item.id,
      });
    }
  };

  const onAddressChange = (newAddress) => {
    setAddress(newAddress);
    const firstStop =
      currentTrip?.stops
        .sort((a, b) => a.stopSequence - b.stopSequence)
        .find((x) => x.stopType === "Pickup") || {};

    calculateStopsDistance(
      [{ address: newAddress }, firstStop],
      setEmptyDistance
    );
  };
  const updateStop = () => {
    let upStop = stop;
    upStop = {
      ...upStop,
      ...stopTimes,
      ...trailer,
      stopReferences: reference,
      address,
      stopReason,
    };
    if (
      new Date(stop?.latest_arrival?.replace("Z", "")) <
      new Date(stopTimes?.actualArrival?.replace("Z", ""))
    ) {
      if (!delayedInfo.ServiceFailure || !delayedInfo.promiseDelayReason) {
        toast.error("Delayed! Please provide Service Failure Reason & Note.");
        return;
      } else {
        let modifiedStopNotes = JSON.parse(
          JSON.stringify(stop.stopNotes || [])
        );
        modifiedStopNotes.push({
          stopNoteType: "Service Failure",
          note: delayedInfo.ServiceFailure,
        });
        upStop = {
          ...upStop,
          promiseDelayReason: delayedInfo.promiseDelayReason,
          stopNotes: modifiedStopNotes,
        };
      }
    }

    //remove service failure reason and note if on time
    if (
      new Date(stopTimes?.actualArrival?.replace("Z", "")) <=
      new Date(stop?.latest_arrival?.replace("Z", ""))
    ) {
      let modifiedStopNotes = (stop?.stopNotes || []).filter(
        (_stop) => _stop.stopNoteType !== "Service Failure"
      );
      delete upStop.promiseDelayReason;

      upStop = {
        ...upStop,
        stopNotes: modifiedStopNotes,
      };
    }
    putElement(
      `/Shipment/Trip/Stop/${stop.stopId}?emptyDistance=${emptyDistance}`,
      upStop
    ).then(() => {
      setDetailsOpen(false);
      handleFetchData();
      if (getTrip) {
        getTrip(currentTrip?.tripId);
      }
    });
  };
  const stopTypeMappings = {
    pickup: [
      "Full Live Loading",
      "Partial Live Loading",
      "Retrieve Loaded Trailer",
      "Retrieve Empty Trailer",
    ],
    delivery: [
      "Full Live Unloading",
      "Partial Live Unloading",
      "Drop Loaded Trailer",
      "Drop Empty Trailer",
    ],
  };

  const filteredStopReasons = enums.StopReason.filter((reason) => {
    const result = stopTypeMappings[stop.stopType?.toLowerCase()]?.includes(
      reason.keyName
    );
    return result;
  });
  return (
    <div className="stop_card_details_edit">
      <div className="slider-display-body customer-form m-3">
        <div className="miler-card mt-0">
          <div className="miler-card-title">General</div>
          <div className="row">
            <div className="col-6 col-lg-6 col-xl-6">
              <InputMiler
                label="Stop Type"
                options={enums.StopType}
                type="select"
                value={stop.stopType}
                disabled
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                label="Trailer Type"
                value={trailer.trailerType}
                type="select"
                required
                options={enums.TrailerType}
                onChange={(e) =>
                  setTrailer({ ...trailer, trailerType: e.target.value })
                }
              />
            </div>
            <div className="col-6 col-lg-6 col-xl-6">
              <InputMiler
                label="Stop Reason"
                options={filteredStopReasons}
                type="select"
                value={stopReason}
                onChange={(e) => setStopReason(e.target.value)}
                disabled={
                  stop?.stopType?.toLowerCase() != "pickup" &&
                  stop?.stopType?.toLowerCase() != "delivery"
                }
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-6">
              <Equipmentsearcher
                onItemSelect={onSearchedItemSelected}
                label="Trailer Number"
                value={trailer.trailerNumber}
                eqType="Trailer"
                required={false}
              />
            </div>
          </div>
        </div>
        <div className="miler-card mt-0">
          <div className="miler-card-title">Location</div>
          <div className="row">
            <div className="col-12">
              <AddressSearcher
                dispatch={onAddressChange}
                address={address}
                disabled={stop.stopType !== "Start"}
              />
            </div>
            {stop.actualArrival && (
              <div className="col-12 col-lg-6 col-xl-6">
                <DateTimeInput
                  label="Actual Arrival"
                  value={stopTimes.actualArrival}
                  onChange={(value) =>
                    setStopTimes({ ...stopTimes, actualArrival: value })
                  }
                />
              </div>
            )}
            {stop.actualDeparture && (
              <div className="col-12 col-lg-6 col-xl-6">
                <DateTimeInput
                  label="Actual Departure"
                  value={stopTimes.actualDeparture}
                  onChange={(value) =>
                    setStopTimes({ ...stopTimes, actualDeparture: value })
                  }
                />
              </div>
            )}
          </div>
        </div>

        {new Date(stop?.latest_arrival?.replace("Z", "")) <
          new Date(stopTimes?.actualArrival?.replace("Z", "")) && (
          <ServiceFailureCard
            reasonValue={delayedInfo.promiseDelayReason}
            reasonOnchange={onDelayedInfoChanged}
            noteValue={delayedInfo.ServiceFailure}
            noteOnchange={onDelayedInfoChanged}
          />
        )}
        <div className="car">
          <div className="parts_title">
            <div className="tit">Reference</div>
            <i
              className="mil-plus ico"
              onClick={() => setOpenNew(true)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {!openNew ? (
            reference.length === 0 ? (
              <div className="no_parts">No Reference</div>
            ) : (
              <div className="parts_bdy">
                <div className="tabl">
                  {!openNew ? (
                    reference.length === 0 ? (
                      ""
                    ) : (
                      <div className="head">
                        <div className="part">Type</div>
                        <div className="no">Value</div>
                        <div className="action">Action</div>
                      </div>
                    )
                  ) : (
                    <div className="head">
                      <div className="part">Type</div>
                      <div className="no">Value</div>
                      <div className="action">Action</div>
                    </div>
                  )}
                  <div className="bdy">
                    {reference.map((ref, index) => (
                      <>
                        {index === editIndex ? (
                          <div className="new_cell">
                            <div className="new_part">
                              <InputMiler
                                options={enums.StopReferenceType}
                                value={reference[index].stopReferenceType}
                                onChange={(e) => {
                                  let modParts = [...reference];
                                  modParts[index].stopReferenceType =
                                    e.target.value;
                                  setReference(modParts);
                                }}
                              />
                            </div>
                            <div className="new_no">
                              <InputMiler
                                value={reference[index].reference}
                                onChange={(e) => {
                                  let modParts = [...reference];
                                  modParts[index].reference = e.target.value;
                                  setReference(modParts);
                                }}
                              />
                            </div>
                            <div className="new_action">
                              <div
                                className="check"
                                onClick={() => {
                                  setEditIndex(-1);
                                }}
                              >
                                &#x2713;
                              </div>

                              <i
                                className="mil-close-bg ex"
                                onClick={() => {
                                  let modParts = [...reference];
                                  modParts[index] = originalReference;
                                  setReference(modParts);
                                  setEditIndex(-1);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="cell">
                            <div className="part">{ref.stopReferenceType}</div>
                            <div className="no">{ref.reference}</div>
                            <div className="action">
                              <i
                                className="mil-edit-o"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setEditIndex(index);
                                  setOpenNew(false);
                                  const ad = reference.filter(
                                    (pt, i) => index === i
                                  )[0];
                                  setOriginalReference(ad);
                                }}
                              />
                              <i
                                className="mil-delete-o"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteRef(index)}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                    {openNew && (
                      <div className="new_cell">
                        <div className="new_part">
                          <InputMiler
                            options={enums.StopReferenceType}
                            value={newReference.stopReferenceType}
                            onChange={(e) =>
                              setNewReference({
                                ...newReference,
                                stopReferenceType: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="new_no">
                          <InputMiler
                            value={newReference.reference}
                            onChange={(e) =>
                              setNewReference({
                                ...newReference,
                                reference: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="new_action">
                          <BsCheckLg
                            className="check"
                            onClick={() => {
                              addReference();
                              setOpenNew(false);
                            }}
                            style={{ fontSize: "1.2rem", background: "red" }}
                          />
                          <i
                            className="mil-close-bg ex"
                            onClick={() => setOpenNew(false)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="parts_bdy">
              <div className="tabl">
                {!openNew ? (
                  reference.length === 0 ? (
                    ""
                  ) : (
                    <div className="head">
                      <div className="part">Type</div>
                      <div className="no">Value</div>
                      <div className="action">Action</div>
                    </div>
                  )
                ) : (
                  <div className="head">
                    <div className="part">Type</div>
                    <div className="no">Value</div>
                    <div className="action">Action</div>
                  </div>
                )}
                <div className="bdy">
                  {reference.map((ref, index) => (
                    <div className="cell" key={index}>
                      <div className="part">{ref.stopReferenceType}</div>
                      <div className="no">{ref.reference}</div>
                      <div className="action">
                        <i className="mil-edit-o"></i>
                        <i className="mil-delete-o"></i>
                      </div>
                    </div>
                  ))}

                  {openNew && (
                    <div className="new_cell">
                      <div className="new_part">
                        <InputMiler
                          options={enums.StopReferenceType}
                          type="select"
                          value={newReference.stopReferenceType}
                          onChange={(e) =>
                            setNewReference({
                              ...newReference,
                              stopReferenceType: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="new_no">
                        <InputMiler
                          value={newReference.reference}
                          onChange={(e) =>
                            setNewReference({
                              ...newReference,
                              reference: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="new_action">
                        <div
                          className="check"
                          onClick={() => {
                            addReference();
                            setOpenNew(false);
                          }}
                        >
                          &#x2713;
                        </div>

                        <i
                          className="mil-close-bg ex"
                          onClick={() => setOpenNew(false)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <MilerButton title={"Cancel"} onClick={() => setDetailsOpen(false)} />
          <MilerButton title={"Save"} btnDark onClick={updateStop} />
        </div>
      </div>
    </div>
  );
};

export default Edit;
