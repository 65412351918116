import { useEffect, useState } from "react";
import { getElements } from "services/milerApi";
import InputMiler from "components/inputMiler/InputMiler";

const MakeSelector = ({ state, onChange, findError, type="trailer" }) => {
  const [makes, setMakes] = useState([]);
  useEffect(() => {
    getElements(`/Equipment/${type}/Makes`)
      .then((res) => {
        setMakes(res.data);
        return res;
      })
      .then((res) => {
        if (state.make) {
          let trueMake = false;
          res.data.forEach((mak) => {
            if (mak.toLowerCase() === state.make.toLowerCase()) {
              trueMake = true;
            }
          });
          if (!trueMake) {
            onChange({
              target: {
                name: "make",
                value: "",
              },
            });
          }
        }
      });
  }, []);
  return (
    <>
      <InputMiler
        name="make"
        type="select"
        options={makes}
        label="Make"
        error={findError("Make")}
        required
        value={state.make}
        onChange={(e) => onChange(e)}
      />
    </>
  );
};

export default MakeSelector;
