import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import permissionDeniedToast from "utils/permissionDeniedToast";

import "./SelectableButton.sass";
import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const SelectableButton = ({
  onClick,
  btnDark,
  tooltip,
  icon,
  reversed,
  title,
  disabled,
  bg,
  sm,
  xs,
  block,
  img,
  className,
  targetActionDenied,
  options,
  onOptionSelected,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const mainContent = () => (
    <>
      {reversed && title && <span>{title}</span>}
      {icon && <i className={`icon ${icon}`}></i>}
      {!reversed && title && <span>{title}</span>}
    </>
  );
  return (
    <div
      className={classNames({
        "button-container": true,
        "btn-miler-dark": btnDark && !targetActionDenied,
        "btn-disabled": targetActionDenied,
      })}
    >
      <Tooltip id="message-tooltip" place="bottom" />
      <button
        data-tooltip-id="message-tooltip"
        data-tooltip-content={
          targetActionDenied
            ? "You are not permitted to perfom this action."
            : tooltip
        }
        className={classNames("miler-btn", className, {
          "btn-miler-dark": btnDark && !targetActionDenied,
          "btn-disabled": targetActionDenied,
          disabled: disabled,
          xs: xs,
          sm: sm,
          bg: bg,
          block: block,
          img: img,
        })}
        onClick={
          disabled
            ? () => {}
            : targetActionDenied
            ? permissionDeniedToast
            : onClick
        }
      >
        {mainContent()}
      </button>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={() => setDropdownOpen(!dropdownOpen)}
        disabled={disabled || targetActionDenied}
      >
        <DropdownToggle
          caret
          style={{
            backgroundColor: btnDark ? "#202f53" : "#FFFFFF",
            color: btnDark ? "#FFFFFF" : "#202f53",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            border: "none",
            borderLeft: `0.3px solid ${btnDark ? "#FFFFFF" : "#202f53"}`,
          }}
        />
        <DropdownMenu>
          {options?.map((optionItem) => (
            <DropdownItem
              onClick={() => onOptionSelected(optionItem)}
              key={optionItem.label || optionItem}
            >
              {optionItem.label || optionItem}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SelectableButton;
