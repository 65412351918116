export const customer = {
  shipperName: "",
  shipperAlias: "",
  primaryEmail: "",
  billingContact: {
    firstName: "",
    lastName: "",
    title: "",
    prefix: "",
    postfix: "",
    department: "",
    email: "",
    dayPhone: "",
  },
  mailingContact: {
    firstName: "",
    lastName: "",
    title: "",
    prefix: "",
    postfix: "",
    department: "",
    email: "",
    dayPhone: "",
  },
  usdot: "",
  scac: "",
  ien: "",
  shipperWebsite: "",
  billingSameAsMailing: false,
  setting: {
    transferToBillingWhen: "Delivered",
    paymentTerms: "Weekly",
    salesRepresentative: null,
    invoiceDeliveryType: "",
    invoiceRequirement: "Invoice Only",
    commissionRate: null,
    creditLimit: null,
    creditWarning: null,
    currencyUsed: "Dollar",
  },
};
