import { useEffect } from "react";
import { Marker } from "@react-google-maps/api";
// import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import redMarker from "assets/map/red-marker.png";
import greenMarker from "assets/map/green-marker.png";
import "./Map.sass";

const StopMarkers = ({ tripStops }) => {
  // const [locations, setLocations] = useState([]);

  useEffect(() => {}, []);

  return tripStops.map((stop, index) => {
    const pos = {
      lat: stop.address ? stop.address.latitude : null,
      lng: stop.address ? stop.address.longitude : null,
    };

    var icon =
      stop.stopType === "Pickup"
        ? {
            url: greenMarker, // url
            labelOrigin: new window.google.maps.Point(25, -15),
          }
        : {
            url: redMarker, // url
            labelOrigin: new window.google.maps.Point(25, -15),
          };
    return (
      <Marker
        key={index}
        label={{
          text: stop.address ? stop.address.addressLine : "",
          className: "trip-marker-label",
          fontSize: "17px",
          color: "#444",
        }}
        icon={icon}
        position={pos}
      />
    );
  });
};

export default StopMarkers;
