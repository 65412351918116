import MilerButton from "components/milerButton/MilerButton";
import { Card } from "reactstrap";
import findError from "utils/findError";
import InputMiler from "components/inputMiler/InputMiler";
import { useState } from "react";

const CostForm = ({ dispatch, setCostFormOpen, costForEdit }) => {
  const { row, index } = costForEdit;

  const [errors, setFormErrors] = useState([]);


  const [costFormFields, setCostFormFields] = useState({
    cost: row?.cost,
    reimbursement: row?.reimbursement,
    invoiceNumber: row?.invoiceNumber,
    type: row?.type,
    freightCostId: row?.freightCostId,
    vendor: row?.vendor,
  });

  const { cost, reimbursement, invoiceNumber, type, vendor } = costFormFields;

  const handleChange = (event) => {
    const { value, name } = event.target;

    setCostFormFields((prev) => ({ ...prev, [name]: value }));
  };

  const saveCost = (addAnother = false) => {
    if (row) {
      if(row.cost&& row.invoiceNumber && row.type){
        dispatch({
          action: "updateCost",
          value: {
            currentIndex: index,
            formValues: costFormFields,
          },
        });
      }
    } else {
      if(!type){
        if(!errors.includes("Type is Required")){
          setFormErrors([...errors, 
             "Type is Required"
          ])
        }
        return;
      } 
      if(!invoiceNumber){
        if(!errors.includes("Invoice Number is Required")){
          setFormErrors([...errors, 
             "Invoice Number is Required"
          ])
        }
        return;
      } 
      if(!cost){
        if(!errors.includes("Cost is Required")){
          setFormErrors([...errors, 
             "Cost is Required"
          ])
        }
        return;
      } 
      
      
    if(cost && type && invoiceNumber){
      dispatch({
        action: "addCost",
        value: { cost, type, reimbursement, invoiceNumber, vendor },
      });
    }
    }

    if (!addAnother) {
      setCostFormOpen(false);
    } else {
      setCostFormFields((prev) => ({
        ...prev,
        cost: "",
        costType: "",
        reimbursement: "",
        invoiceNumber: "",
        freightCostId: null,
      }));
    }
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="d-flex flex-column">
          <div className="slider-header-title">New Cost</div>
        </div>

        <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
          <MilerButton
            icon="mil-close-bg"
            title="Cancel"
            onClick={() => {
              setCostFormOpen(false);
            }}
          />
          <MilerButton
            icon="mil-close-bg"
            title="Save & Add another"
            onClick={() => {
              saveCost(true);
            }}
          />
          <MilerButton
            btnDark
            icon="mil-save-o"
            title="Save"
            onClick={() => {
              saveCost();
            }}
          />
        </div>
      </div>

      <div className="slider-display-body">
        <Card>
          <div className="row p-3">
            <div className="col-12 col-lg-6">
              <InputMiler
                value={type}
                type="select"
                onChange={handleChange}
                name="type"
                label="Type"
                options={["Delivery", "Disposal", "Compensation", "Other"]}
                error={findError("costType", errors) || errors.find(e=> e==="Type is Required")}
                required
              />
            </div>
            <div className="col-12 col-lg-6">
              <InputMiler
                value={invoiceNumber}
                type="text"
                onChange={handleChange}
                name="invoiceNumber"
                label="Invoice #"
                error={findError("invoiceNumber", errors) || errors.find(e=> e==="Invoice Number is Required")}
                required
              />
            </div>
            <div className="col-12 col-lg-6">
              <InputMiler
                value={vendor}
                type="text"
                onChange={handleChange}
                name="vendor"
                label="Vendor"
              />
            </div>
            <div className="col-12 col-lg-6">
              <InputMiler
                value={cost}
                type="number"
                onChange={handleChange}
                name="cost"
                label="Cost"
                error={findError("cost", errors)|| errors.find(e=> e==="Cost is Required")}
                required
              />
            </div>
            <div className="col-12 col-lg-6">
              <InputMiler
                value={reimbursement}
                type="number"
                onChange={handleChange}
                name="reimbursement"
                label="Reimbursement"
                error={findError("reimbursement", errors)}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CostForm;
