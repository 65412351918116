import "./CheckCall.sass";
import CheckCallsBody from "./CheckCallBody";

const CheckCalls = ({ setCheckCallSliderOpen }) => {
  return (
    <div className="slider-display checkcall">
      <div className="slider-display-header">
        <div className="slider-header-title">Check Call</div>
        <div className="icon-close-calls">
          <i
            onClick={() => {
              setCheckCallSliderOpen(false);
            }}
            className="icon-close mil-close-bg "
          />
        </div>
      </div>
      <CheckCallsBody />
    </div>
  );
};

export default CheckCalls;
