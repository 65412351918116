import { useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { API_KEY } from "constants/config";

import DisTripDriverMarker from "./DisTripDriverMarker";
import TripMarkers from "./TripDriverMarkers";
import StopMarkers from "./StopMarkers";
import { mapStyles } from "constants/mapStyles";
import "./Map.sass";

const TripMap = ({ tripStops, currentTrip }) => {
  // let cachedJwt = JSON.parse(sessionStorage.getItem("cachedJwt"));
  const [disableTracker, setDisableTracker] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });
  const [delay, setDelay] = useState(0);
  const [stat] = useState(false);
  const [statLoading] = useState(false);
  const [locations, setLocations] = useState([
    {
      gps: [
        {
          latitude: 0,
          longitude: 0,
        },
      ],
    },
  ]);

  const containerStyle = {
    width: "100%",
    height: "88vh",
  };

  return isLoaded ? (
    <>
      {statLoading ? (
        <>
          {stat ? (
            <GoogleMap
              mapContainerClassName="map"
              zoom={4.4}
              center={{
                lat: 41.666574490894995,
                lng: -93.32836457326965,
              }}
              mapContainerStyle={containerStyle}
              options={{
                styles: mapStyles,
              }}
            >
              <StopMarkers tripStops={tripStops} />
            </GoogleMap>
          ) : disableTracker ? (
            <GoogleMap
              mapContainerClassName="map"
              zoom={17}
              center={{
                lat: locations[0].gps[0].latitude,
                lng: locations[0].gps[0].longitude,
              }}
              mapContainerStyle={containerStyle}
              options={{
                styles: mapStyles,
              }}
            >
              <StopMarkers tripStops={tripStops} />

              <DisTripDriverMarker
                unitNumber={currentTrip.unitNumber}
                locations={locations}
                setLocations={setLocations}
                delay={delay}
                setDelay={setDelay}
              />
              <button
                className="trip-map-tracker-button"
                onClick={() => setDisableTracker(!disableTracker)}
              >
                {disableTracker
                  ? "Enable Live Tracking"
                  : "Disable Live Tracking"}
              </button>
            </GoogleMap>
          ) : (
            <GoogleMap
              mapContainerClassName="map"
              zoom={17}
              center={{
                lat: locations[0].gps[0].latitude,
                lng: locations[0].gps[0].longitude,
              }}
              mapContainerStyle={containerStyle}
              options={{
                styles: mapStyles,
              }}
            >
              <StopMarkers tripStops={tripStops} />

              <TripMarkers
                unitNumber={currentTrip.unitNumber}
                locations={locations}
                setLocations={setLocations}
                delay={delay}
                setDelay={setDelay}
              />
              <button
                className="trip-map-tracker-button"
                onClick={() => setDisableTracker(!disableTracker)}
              >
                {disableTracker
                  ? "Enable Live Tracking"
                  : "Disable Live Tracking"}
              </button>
            </GoogleMap>
          )}
        </>
      ) : (
        <>
          <GoogleMap
            mapContainerClassName="map"
            zoom={4.4}
            center={{
              lat: 41.666574490894995,
              lng: -93.32836457326965,
            }}
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
            }}
          ></GoogleMap>
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default TripMap;
