import Topbar from "layout/toolbar/ContentTopbar";
import "./AppContent.sass";
import { ToolBarProvider } from "./toolbar/context/ToolbarContext";
import RoutesRenderer from "layout/routes/RoutesRenderer";

const AppContent = () => (
  <ToolBarProvider>
    <div className="app-content">
      <Topbar />
      <div className="view">
        <RoutesRenderer />
      </div>
    </div>
  </ToolBarProvider>
);

export default AppContent;
