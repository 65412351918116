import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import TrailerForm from "../trailerForm/TrailerForm";
import { postElement } from "services/milerApi";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";

const NewTrailer = ({ setNewTrailer, getTrailers }) => {
  const [state, setState] = useState({
    plateExpiration: "",
  });
  const [errors, setFormErrors] = useState([]);
  const [yearError, setYearError] = useState(null);
  const [addingTrailer, setAddingTrailer] = useState(false);

  const createTrailer = () => {
    setAddingTrailer(true);
    const trimmedState = { ...state, unitNumber: state?.unitNumber?.trim() };
    postElement("/Equipment/Trailer", trimmedState).then((response) => {
      setAddingTrailer(false);
      if (response.success) {
        getTrailers();
        setNewTrailer(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const onChange = (e) => {
    if (e.target.name === "unitNumber") {
      if (e.target.value.length <= 6) {
        setState({ ...state, [e.target.name]: e.target.value });
        return;
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const onChangeYear = (e) => {
    const min = new Date().getFullYear() - 70;
    const max = new Date().getFullYear() + 2;
    if (e.target.value > max || e.target.value < min) {
      setYearError("Invalid Year");
    } else {
      let newarr = errors.filter((a) => a.key !== "Year");
      setFormErrors(newarr);
      setYearError(null);
    }
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    state && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Trailer</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="cancel"
              onClick={() => {
                localStorage.removeItem("draftTrailer");
                setNewTrailer(false);
              }}
            />

            <MilerButton
              btnDark
              disabled={yearError || addingTrailer}
              title={addingTrailer ? "Saving..." : "Save"}
              onClick={() => createTrailer()}
            />
          </div>
        </div>
        <ErrorBoundary message="New Trailer Form">
          <TrailerForm
            onChange={onChange}
            onChangeYear={onChangeYear}
            yearError={yearError}
            state={state}
            errors={errors}
          />
        </ErrorBoundary>
      </div>
    )
  );
};

export default NewTrailer;
