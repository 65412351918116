import { useState, createContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { getCookie } from "utils/cookies";
import { hasPermission } from "utils/hasPermission";
const appContext = createContext();

const AppContext = (props) => {
  const carrierInfo = getCookie("carrierInfo") || {};

  const { companyLogoURL } = carrierInfo;
  const [enums, setEnums] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [undoModal, setUndoModal] = useState(false);
  const [factoryId, setFactoryId] = useState(0);
  const [carrierState, setCarrierState] = useState({}); //INFO:to be deleted
  const [tokenExpirationState, setTokenExpirationState] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [appLogo, setAppLogo] = useState("");
  const [current, setCurrent] = useState(
    hasPermission("view.report.accounting") ? "AP" : "SH"
  );

  const [clickedReport, setClickedReport] = useState(null);
  const [currentTab, setCurrentTab] = useState("Accounting");

  const enumObject = (a) => {
    const obj = {};
    a.map(
      (b) =>
        (obj[b.type] = b.keys.map((k) => {
          return { keyName: k.keyName, keyValue: k.keyValue };
        }))
    );
    setEnums(obj);
  };

  const getEnums = (x) => {
    enumObject(x);
  };

  useEffect(() => {
    if (companyLogoURL) {
      setAppLogo(companyLogoURL);
    }
  }, [companyLogoURL]);

  return (
    <div>
      <appContext.Provider
        value={{
          getEnums,
          enums,
          isMobile,
          formErrors,
          setFormErrors,
          undoModal,
          setUndoModal,
          factoryId,
          setFactoryId,
          setCarrierState,
          carrierState,
          tokenExpirationState,
          setTokenExpirationState,
          appLogo,
          setAppLogo,
          current,
          setCurrent,
          clickedReport,
          setClickedReport,
          currentTab,
          setCurrentTab,
        }}
      >
        {props.children}
      </appContext.Provider>
    </div>
  );
};

export { AppContext, appContext };
