import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AiFillCheckCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { ListGroup, ListGroupItem } from "reactstrap";
import { getElements } from "services/serviceRepairApi";
import "./ServiceCodes.sass";

const ServiceCodes = ({ setSelectedServiceCodes, selectedServiceCodes }) => {
  const [serviceCodes, setServiceCodes] = useState([]);

  const handleDetailChange = (event, key, id) => {
    event.preventDefault();
    let modData = [...serviceCodes];
    const myRowIndex = modData.findIndex((row) => row.name === key.name);
    const subRowIndex = modData[myRowIndex].services.findIndex(
      (s) => s.serviceId === id
    );

    modData[myRowIndex].services[subRowIndex].showDetails =
      modData[myRowIndex].services[subRowIndex].showDetails === undefined
        ? true
        : !modData[myRowIndex].services[subRowIndex].showDetails;

    setServiceCodes(modData);
  };

  const handleIconChange = (id, key, value) => {
    let modData = [...serviceCodes];
    const myRowIndex = modData.findIndex((row) => row.name === key.name);
    const subRowIndex = modData[myRowIndex].services.findIndex(
      (s) => s.serviceId === id
    );

    modData[myRowIndex].services[subRowIndex].checked = value;

    setServiceCodes(modData);

    if (value === true) {
      const nameIsThere = selectedServiceCodes.find(
        (element) => element.name === key.name
      );
      if (nameIsThere) {
        const mdd = selectedServiceCodes.map((s) => {
          if (s.name === key.name) {
            return {
              ...s,
              services: [
                ...s.services,
                modData[myRowIndex].services[subRowIndex],
              ],
            };
          }
          return s;
        });
        setSelectedServiceCodes(mdd);
      } else {
        const as = {
          ...key,
          services: [modData[myRowIndex].services[subRowIndex]],
        };
        setSelectedServiceCodes([...selectedServiceCodes, as]);
      }
    }

    if (value === false) {
      let as = selectedServiceCodes.map((ms) => {
        return {
          name: ms.name,
          services: ms.services.filter((ma) => ma.serviceId !== id),
        };
      });
      as = as.filter((mm) => mm.services.length !== 0);

      setSelectedServiceCodes(as);
    }
  };

  useEffect(() => {
    getElements("/ServiceRequest/Services/?onMarketplace=true").then((response) => {
      setServiceCodes(response.data);
    });
  }, []);

  return (
    <div className="maintenance_service_codes">
      <div>
        <Accordion allowZeroExpanded>
          {serviceCodes.map((res, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton style={{ color: "black" }}>
                  {res.name}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {res.services.map((service) => (
                  <div className="pl-lg-4" key={service.serviceId}>
                    <ListGroup flush className="items">
                      <ListGroupItem>
                        <div className="item">
                          <div className="first">
                            {service.checked === undefined ||
                            service.checked === false ? (
                              <AiOutlinePlusCircle
                                className="plus"
                                onClick={() =>
                                  handleIconChange(service.serviceId, res, true)
                                }
                              />
                            ) : (
                              <AiFillCheckCircle
                                className="check"
                                onClick={() =>
                                  handleIconChange(
                                    service.serviceId,
                                    res,
                                    false
                                  )
                                }
                              />
                            )}
                            <p className="item-name">{service.code}</p>
                          </div>
                          <button
                            className="item-btn"
                            onClick={(event) =>
                              handleDetailChange(event, res, service.serviceId)
                            }
                          >
                            Details
                          </button>
                        </div>
                        {service.showDetails && (
                          <>
                            <p className="item-description">
                              Description: {service.description}
                            </p>
                            <p className="item-description">
                              Note: {service.note}
                            </p>
                          </>
                        )}
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default ServiceCodes;
