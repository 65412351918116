import "../../../invoices_v2/invoicesSidebar/invoicesCards/InvoiceCard.sass";
import { useContext } from "react";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import { CarrierChargeContext } from "../../carrierChargeContext/CarrierChargeContext";

const CarrierChargeCard = ({ carrierCharge, setStatus }) => {
  const { state, dispatch } = useContext(CarrierChargeContext);
  return (
    <>
      {carrierCharge.carrierProfile && carrierCharge.carrierProfile.company && (
        <div
          className={`settlements_sidebar_card ${
            state.current.carrierProfile &&
            carrierCharge.carrierProfile &&
            carrierCharge.carrierProfile.carrier_id ===
              state.current.carrierProfile.carrier_id
              ? "settlements_sidebar_card_selected"
              : "settlements_sidebar_card_not"
          }`}
          onClick={() => {
            dispatch({ type: "setCurrent", payload: carrierCharge });
            dispatch({ type: "resetCurrent" });
            setStatus("pending");
          }}
        >
          <div className="first">
            <ProfilePlaceholder
              jointName={
                carrierCharge.carrierProfile &&
                carrierCharge.carrierProfile.company
              }
            />
            <div className="desc">
              <div className="name">{`${
                carrierCharge.carrierProfile &&
                carrierCharge.carrierProfile.company
              }`}</div>
              <div className="type"></div>
            </div>
          </div>
          <div className="second">
            <div>
              <i className="mil-chevron-bg-right ico" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CarrierChargeCard;
