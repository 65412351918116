import { useState, useEffect, useRef } from "react";
import { mapStyles } from "constants/mapStyles";
import { GoogleMap } from "@react-google-maps/api";
import { getGatewayApi } from "services/gatewayApis";
import VehicleTracker from "../VehicleTracker";
import "../Map.sass";
export default function TrackingMap({ eldId, type }) {
  const containerStyle = {
    width: "100%",
    height: "88vh",
  };
  const [statLoading, setStatLoading] = useState(false);
  const [locations, setLocations] = useState([
    {
      address: {
        latitude: 0,
        longitude: 0,
      },
    },
  ]);
  const [disableTracker, setDisableTracker] = useState(false);

  const [unTrackable, setUntrackable] = useState(false);

  const intervalRef = useRef();

  const getVehicleExtId = () => {
    if (type === "trackingVehicles") {
      return getGatewayApi(
        "carrier/integration/eld/vehicles/location?include=gps"
      )
        .then((res) => {
          if (res.data && res.data.errorMessage) {
            setDisableTracker(true);
          }
          setStatLoading(true);
          setLocations(res.data?.data || []);
        })
        .catch(() => {
          setStatLoading(false);
        });
    } else {
      getGatewayApi(
        `carrier/integration/eld/vehicles/location?include=gps&eld_id=${eldId}`
      )
        .then((res) => {
          setLocations(res.data?.data || []);
          setStatLoading(true);
        })
        .catch(() => {
          setUntrackable(true);
          setStatLoading(false);
        });
    }
  };

  useEffect(() => {
    const idleTimer = Date.now();
    const idleMaxAmount = 5 * 60 * 1000; //in miliseconds

    if (disableTracker || unTrackable) {
      getVehicleExtId();
    } else if (type === "tracking") {
      getVehicleExtId();
      intervalRef.current = setInterval(() => {
        if (Date.now() - idleTimer > idleMaxAmount) {
          clearInterval(intervalRef.current);
          setDisableTracker(true);
        } else getVehicleExtId();
      }, 30000);

      return () => {
        clearInterval(intervalRef.current);
      };
    } else {
      getVehicleExtId();
    }
  }, [disableTracker, unTrackable, eldId]);

  return (
    <GoogleMap
      mapContainerClassName="map"
      zoom={type === "trackingVehicles" ? 4.5 : 17}
      center={{
        lat:
          type === "trackingVehicles"
            ? 41.666574490894995
            : locations[0]?.address?.latitude,
        lng:
          type === "trackingVehicles"
            ? -93.32836457326965
            : locations[0]?.address?.longitude,
      }}
      mapContainerStyle={containerStyle}
      options={{
        styles: mapStyles,
      }}
    >
      {statLoading ? (
        <>
          {locations && <VehicleTracker locations={locations} />}
          <button
            className="trip-map-tracker-button"
            onClick={() => setDisableTracker(!disableTracker)}
          >
            {disableTracker ? "Enable Live Tracking" : "Disable Live Tracking"}
          </button>
        </>
      ) : (
        <>
          <GoogleMap
            mapContainerClassName="map"
            zoom={4.4}
            center={{
              lat: 41.666574490894995,
              lng: -93.32836457326965,
            }}
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
            }}
          ></GoogleMap>
        </>
      )}
    </GoogleMap>
  );
}
