import { useEffect, useState } from "react";
import { getGatewayApi } from "services/gatewayApis";
import { CheckCircleOutlined, CaretRightOutlined } from "@ant-design/icons";
import "antd/dist/antd.min.css";
import { Collapse } from "antd";
import Loading from "components/loading/Loading";
import "./Activation.sass";
import FailedToFetch from "components/loading/FailedToFetch";
import { Card, CardBody, Label } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import SliderModal from "components/sliderModal/SliderModal";
import AddCustomerPortal from "./AddCustomerPortal";

const Activation = ({ shipperDetail, shipperId, getCustomers }) => {
  const [onBoardingStatus, setOnBoardingStatus] = useState({});
  const [loading, setLoading] = useState(<Loading />);

  const { Panel } = Collapse;

  const genExtra = () => (
    <CheckCircleOutlined
      style={{
        color: "green",
        fontWeight: "bolder",
        fontSize: "20px",
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );

  const getActivationDetails = () => {
    getGatewayApi(`carrier/onboarding/customer/${shipperId}`)
      .then((response) => {
        setOnBoardingStatus(response.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status == 404) {
          setLoading(
            <CustomerNotFound
              shipperDetail={shipperDetail}
              getCustomers={getCustomers}
              refetchActivation={getActivationDetails}
            />
          );
        } else {
          setLoading(<FailedToFetch />);
        }
      });
  };

  useEffect(() => {
    getActivationDetails();
  }, []);

  return (
    <section className="main">
      <Card>
        <CardBody>
          {/* <CardTitle className="main_header_title">
            <p className="shipment-detail-title">Activation</p>
            <InputMiler
              type="select"
              options={SubscriberStatus}
              value={state.status}
              onChange={(event) => {
                setState({
                  ...state,
                  status: event.target.value,
                });

                if (event.target.value !== state.status) {
                  postElement(
                    `Carrier/Customer/${shipperId}/Status?subscriber_status=${event.target.value}`
                  ).then(() => {
                    getCustomers();
                  });
                }
              }}
            />
          </CardTitle> */}
          {loading ? (
            loading
          ) : (
            <>
              <Collapse
                accordion
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header="Profile Setup"
                  className="site-collapse-custom-panel"
                  extra={onBoardingStatus?.profile_setup && genExtra()}
                  key="1"
                >
                  {onBoardingStatus?.profile_setup ? (
                    <p className="main_panel__text-content">
                      Customer completed their profile
                    </p>
                  ) : (
                    <p className="main_panel__text-content">
                      Check the customer has completed their profile, including
                      customer contact, billing address, DOT, and MC
                    </p>
                  )}
                </Panel>
                <Panel
                  header="Paperwork"
                  key="4"
                  extra={onBoardingStatus?.paperwork_done && genExtra()}
                  className="site-collapse-custom-panel"
                >
                  {onBoardingStatus?.paperwork_done ? (
                    <p className="main_panel__text-content">
                      Paperwork completed
                    </p>
                  ) : (
                    <p className="main_panel__text-content">
                      Confirm the carrier packet documents, including insurance,
                      MC Authority, etc., are on file
                    </p>
                  )}
                </Panel>
                <Panel
                  header="Activation"
                  key="5"
                  extra={onBoardingStatus?.activation_done && genExtra()}
                  className="site-collapse-custom-panel"
                >
                  {onBoardingStatus?.activation_done ? (
                    <p className="main_panel__text-content">
                      This carrier has completed all onboarding steps and
                      currently active in your private network.
                    </p>
                  ) : (
                    <p className="main_panel__text-content">
                      After onboarding steps are complete, you may activate the
                      carrier in your private network.
                    </p>
                  )}
                </Panel>
              </Collapse>
              <br />
            </>
          )}
        </CardBody>
      </Card>
    </section>
  );
};

const CustomerNotFound = ({
  shipperDetail,
  getCustomers,
  refetchActivation,
}) => {
  const [newCustomerPortalOpen, setNewCustomerPortalOpen] = useState(false);
  return (
    <div className="miler-card d-flex flex-column align-items-center justify-content-center">
      <Label className="mt-2">
        Customer portal is not enabled for this customer
      </Label>

      <MilerButton
        btnDark
        title="Enable Customer Portal"
        onClick={() => setNewCustomerPortalOpen(true)}
      />

      <SliderModal
        clickState={newCustomerPortalOpen}
        setClickState={setNewCustomerPortalOpen}
        from="bottom"
      >
        <AddCustomerPortal
          setAddCustomerPortal={(isOpen, refetchActivationDetail = false) => {
            setNewCustomerPortalOpen(isOpen);
            if (refetchActivationDetail) {
              refetchActivation();
            }
          }}
          getCustomers={getCustomers}
          shipperDetail={shipperDetail}
        />
      </SliderModal>
    </div>
  );
};

export default Activation;
