import NewDATests from "views/compliance/drugAlcoholTests/DATestsForm/NewDATests";

const EditDATests = ({ drugId, driverName, activeTab, setOpenEditSlider, getDrivers }) => {
  return (
    <>
      <NewDATests
        drugId={drugId}
        driverName={driverName}
        setNewTestOpen={setOpenEditSlider}
        activeTab={activeTab}
        refetchSummary={getDrivers}
      />
    </>
  );
};

export default EditDATests;
