import { FormGroup, Input, Label } from "reactstrap";

import InputMiler from "components/inputMiler/InputMiler";
import "./AddOns.sass";

const AddOns = ({ state, dispatch }) => {
  return (
    <div className="add-ons miler-card my-2">
      <div className="form-card-title">ADD ONS</div>
      <div className="container">
        <div className="row row_section">
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="Liftgate"
                checked={state.liftgate}
                onChange={(e) =>
                  dispatch({ type: "liftgate", value: e.target.checked })
                }
              />
              <Label for="Liftgate" className="shadow-none" check>
                Liftgate
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="twic"
                checked={state.twic}
                onChange={(e) =>
                  dispatch({ type: "twic", value: e.target.checked })
                }
              />
              <Label for="twic" className="shadow-none" check>
                TWIC
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="appointmentRequired"
                checked={state.appointmentRequired}
                onChange={(e) =>
                  dispatch({
                    type: "appointmentRequired",
                    value: e.target.checked,
                  })
                }
              />
              <Label for="appointmentRequired" className="shadow-none" check>
                Appointment Required
              </Label>
            </FormGroup>
          </div>
          {state.appointmentRequired && (
            <div className="col-md-3">
              <FormGroup check>
                <>
                  <Input
                    type="checkbox"
                    name="check"
                    id="appointmentSet"
                    checked={state.appointmentSet}
                    onChange={(e) =>
                      dispatch({
                        type: "appointmentSet",
                        value: e.target.checked,
                      })
                    }
                  />
                  <Label for="appointmentSet" className="shadow-none" check>
                    Appointment Set
                  </Label>
                </>
              </FormGroup>
            </div>
          )}
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="rollDoor"
                checked={state.rollDoor}
                onChange={(e) =>
                  dispatch({ type: "rollDoor", value: e.target.checked })
                }
              />
              <Label for="rollDoor" className="shadow-none" check>
                Roll Door
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="tarps"
                checked={state.tarps}
                onChange={(e) =>
                  dispatch({ type: "tarps", value: e.target.checked })
                }
              />
              <Label for="tarps" className="shadow-none" check>
                Tarps
              </Label>
            </FormGroup>
          </div>

          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="driverAssist"
                checked={state.driverAssist}
                onChange={(e) =>
                  dispatch({
                    type: "driverAssist",
                    value: e.target.checked,
                  })
                }
              />
              <Label for="driverAssist" className="shadow-none" check>
                Driver Assist
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="team"
                checked={state.team}
                onChange={(e) =>
                  dispatch({ type: "team", value: e.target.checked })
                }
              />
              <Label for="team" className="shadow-none" check>
                Team
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="hazmat"
                checked={state.hazmat}
                onChange={(e) =>
                  dispatch({ type: "hazmat", value: e.target.checked })
                }
              />
              <Label for="hazmat" className="shadow-none" check>
                Hazmat
              </Label>
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup check>
              <Input
                type="checkbox"
                name="check"
                id="highValue"
                checked={state.highValue}
                onChange={(e) =>
                  dispatch({ type: "highValue", value: e.target.checked })
                }
              />
              <Label for="highValue" className="shadow-none" check>
                High Value
              </Label>
            </FormGroup>
            {state.highValue && (
              <InputMiler
                type="number"
                label="Amount"
                value={state.amount}
                onChange={(e) =>
                  dispatch({ type: "amount", value: e.target.value })
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOns;
