import BorderedText from "components/bordered-text/BorderedText";
import InputMiler from "components/inputMiler/InputMiler";
import React, { useContext, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { appContext } from "services/AppContext";
import DataTable from "react-data-table-component";
import Equipmentsearcher from "../../inputMiler/advanceSearch/EquipmentSearcher";
import calculateStopsDistances from "utils/calculateStopsDistance";
import classNames from "classnames";
import ReferenceModal from "./ReferenceModal";
import AddressToggle from "./AddressToggle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import dateFormatter from "utils/dateFormater";

// do not re-render if the stops list reference has not changed
const InnerList = React.memo(function InnerList({
  state,
  stops,
  op,
  setOp,
  dispatch,
  findError,
  containsErrors,
  draggableOutOfZone,
  draggableInWrongZone,
  isManifest,
}) {
  return stops.map((stop, index) => (
    <Draggable
      key={stop.stopSequence?.toString()}
      draggableId={stop.stopSequence?.toString()}
      index={index}
    >
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          provided.draggableProps.style = {
            ...provided.draggableProps.style,
            left: provided.draggableProps.style.offsetLeft,
          };
        }
        const style = {
          backgroundColor: snapshot.isDragging
            ? draggableOutOfZone || draggableInWrongZone
              ? "#f87171"
              : "#a3a3a3"
            : "white",
          ...provided.draggableProps.style,
        };

        return (
          <div
            {...provided.draggableProps}
            // {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={style}
          >
            <RowTable
              op={op}
              setOp={setOp}
              provided={provided}
              stop={stop}
              index={index}
              key={index}
              dispatch={dispatch}
              findError={findError}
              containsErrors={containsErrors}
              state={state}
              stops={state.stops}
              isManifest={isManifest}
            />
          </div>
        );
      }}
    </Draggable>
  ));
});

const StopListRow = ({
  state,
  dispatch,
  findError,
  containsErrors,
  isManifest,
}) => {
  const [draggableOutOfZone, setDraggableOutOfZone] = useState(false);
  const [draggableInWrongZone, setDraggableInWrongZone] = useState(false);
  const [op, setOp] = useState(null);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    dispatch({
      category: "moveStop",
      destinationIndex: destination.index,
      currentIndex: source.index,
    });

    !isManifest &&
      dispatch({
        category: "preserveStopTypesAfterDrag",
      });
    if (!draggableInWrongZone && !draggableOutOfZone) {
      setOp(op === source.index ? destination.index : null);
    }
  };

  return (
    <Card className="my-2">
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title">Stops</p>
          <div>
            <button
              className="shipment-detail-down-arrow"
              onClick={() => dispatch({ category: "newStop" })}
            >
              <i className="mil-plus" />
            </button>
          </div>
        </CardTitle>
        <Row style={{ margin: "0 7px" }}>
          <div className="stops-table shipment-form-stops">
            <div className="stops-table-title shipment-form-stops-title">
              <div>SORT</div>
              <div>Type</div>
              <div>Location</div>
              <div>Arrival</div>
              <div>Action</div>
            </div>
            <div>
              <DragDropContext
                onDragEnd={(result) => {
                  onDragEnd(result);
                }}
                onDragUpdate={(result) => {
                  // let movingStop = state.stops[result.source.index];
                  setDraggableOutOfZone(result.destination == null);
                  if (result.destination) {
                    // let draggableInWrongZone = (result.destination.index === 0 && movingStop.stopType === "Delivery") || result.destination.index === state.stops.length - 1 && movingStop.stopType === "Pickup"
                    setDraggableInWrongZone(false); // draggable is never in wrong zone. Temporary prevent issue with sudden wrong red banner
                    // setDraggableInWrongZone(draggableInWrongZone)
                  }
                }}
              >
                <Droppable droppableId="drop" key="drop" direction="vertical">
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        style={{
                          minHeight: "12rem",
                          backgroundColor: snapshot.isDraggingOver
                            ? "#fffbeb"
                            : "white",
                        }}
                        className="drag-context"
                        ref={provided.innerRef}
                      >
                        <InnerList
                          state={state}
                          draggableOutOfZone={draggableOutOfZone}
                          draggableInWrongZone={draggableInWrongZone}
                          stops={state.stops}
                          op={op}
                          setOp={setOp}
                          dispatch={dispatch}
                          findError={findError}
                          containsErrors={containsErrors}
                          isManifest={isManifest}
                        />
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

const RowTable = ({
  op,
  setOp,
  provided,
  stop,
  index,
  dispatch,
  findError,
  containsErrors,
  yellowTitle,
  stops,
  state,
  isManifest,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSearchedItemSelected = (item) => {
    if (item.unselect) {
      dispatch({
        type: "trailer_unselected",
        category: "stop",
        index,
        value: {},
      });
    } else if (item.newEntry) {
      dispatch({
        type: "trailerNumber",
        category: "stop",
        index,
        value: { trailerNumber: item.value },
      });
    } else {
      let items = item.equipment.split(" ");
      dispatch({
        type: "trailerNumber",
        category: "stop",
        index,
        value: {
          trailerNumber: items[0],
          trailerType: items[1],
          trailerId: item.id,
        },
      });
    }
  };

  const {
    enums: { StopType, TrailerType, StopReason },
  } = useContext(appContext);

  const calcShipmentDistance = (address) => {
    const addresses = stops.map((s, i) => {
      if (i === index) return { address };
      else return { address: s.address };
    });
    const fullAddresses = addresses.filter((ad) => {
      if (
        ad?.address?.addressLine ||
        ad?.address?.countryCode ||
        ad?.address?.latitude
      ) {
        return true;
      }
    });

    fullAddresses.length > 1 &&
      calculateStopsDistances(addresses, (total) =>
        dispatch({ type: "distance", value: total })
      );
  };

  const stopTypeMappings = {
    pickup: [
      "Full Live Loading",
      "Partial Live Loading",
      "Retrieve Loaded Trailer",
      "Retrieve Empty Trailer",
    ],
    delivery: [
      "Full Live Unloading",
      "Partial Live Unloading",
      "Drop Loaded Trailer",
      "Drop Empty Trailer",
    ],
  };

  const filteredStopReasons = StopReason.filter((reason) => {
    const result = stopTypeMappings[stop.stopType?.toLowerCase()]?.includes(
      reason.keyName
    );
    return result;
  });

  const findInitialNote = () => {
    let initialNote = stop.stopNotes.find((n) => n.stopNoteType === "Initial");
    return initialNote ? initialNote.note : "";
  };

  let St = StopType.filter((typ) => typ.keyValue != 202 && typ.keyValue != 247);

  const columns = [
    {
      name: "REFERENCE TYPE",
      selector: (row) => row.stopReferenceType,
      sortable: true,
      grow: 4,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.stopReferenceType}</div>
      ),
    },
    {
      name: "REFERENCE",
      selector: (row) => row.reference,
      grow: 4,
      center: true,
      cell: (row) => (
        <div className="ship-detail-charge-text">{row.reference}</div>
      ),
    },
    {
      name: "",
      selector: (row) => row.reference,
      grow: 1,
      cell: (row, itemIdex) => (
        <i
          className="mil-delete-o"
          style={{
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch({
              category: "deleteStopReference",
              refIndex: itemIdex,
              index: index,
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div
        className={classNames("shipment-form-charge-stop-item", {
          "contains-errors": containsErrors(`Stops[${index}]`),
        })}
      >
        <div
          className={`stops-table-row-title shipment-form-charge-stop-item-summary`}
        >
          <div ref={provided.innerRef} {...provided.dragHandleProps}>
            <i className="mil-menu" />
          </div>

          <div
            className="ship-detail-stops-type-text"
            style={{
              color:
                stop.stopType === "Pickup"
                  ? "#2ECD7D"
                  : stop.stopType === "Split"
                  ? "#EEA123"
                  : "#4a81d9",
            }}
          >
            {stop.stopType === "Pickup" ? "Pick up" : stop.stopType}
          </div>
          <div className="ship-detail-stops-type-container">
            <div className="ship-detail-stops-type-text">
              {stop.address.addressLine}
            </div>
            <BorderedText
              text={`${stop.address.city} ${stop.address.state} ${stop.address.postalCode} `}
            />
          </div>
          <div>
            <div>{dateFormatter(stop.latest_arrival, "MMM DD", true)}</div>
            <BorderedText
              text={dateFormatter(stop.latest_arrival, "HH:mm", true)}
            />
          </div>
          <div className="shipment-form-stops-charges-actions">
            {!isManifest && (
              <i
                className="mil-delete-o"
                onClick={() => dispatch({ category: "deleteStop", index })}
                style={{ cursor: "pointer" }}
              />
            )}

            <i
              style={{ marginLeft: "5px", cursor: "pointer" }}
              className={
                op === index ? "mil-chevron-bg-up" : "mil-chevron-bg-down"
              }
              onClick={() => setOp(op === null || op != index ? index : null)}
            />
          </div>
        </div>

        {op === index && (
          <div className="shipment-form-charge-stop-item-details">
            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card">
                <CardTitle className="shipment-detail-header">
                  <p className="shipment-detail-title">GENERAL</p>
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <InputMiler
                      label="Stop Type"
                      options={St}
                      type="select"
                      value={stop.stopType}
                      onChange={(e) =>
                        dispatch({
                          type: "stopType",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      required
                      error={findError(`Stops[${index}].StopType`)}
                    />
                  </Col>
                  <Col lg="6">
                    <InputMiler
                      label="Trailer Type"
                      value={stop.trailerType}
                      type="select"
                      required
                      options={TrailerType}
                      onChange={(e) =>
                        dispatch({
                          type: "trailerType",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      error={findError(`Stops[${index}].TrailerType`)}
                    />
                  </Col>
                  <Col lg="6">
                    <InputMiler
                      label="Stop Reason"
                      options={filteredStopReasons}
                      type="select"
                      value={stop.stopReason}
                      onChange={(e) =>
                        dispatch({
                          type: "stopReason",
                          category: "stop",
                          index,
                          value: e.target.value,
                        })
                      }
                      error={findError(`Stops[${index}].StopReason`)}
                      disabled={
                        stop?.stopType?.toLowerCase() != "pickup" &&
                        stop?.stopType?.toLowerCase() != "delivery"
                      }
                    />
                  </Col>
                  <Col lg="6">
                    <Equipmentsearcher
                      onItemSelect={onSearchedItemSelected}
                      label="Trailer Number"
                      value={stop.trailerNumber}
                      error={findError(`Stops[${index}].TrailerNumber`)}
                      eqType="Trailer"
                      required={false}
                      disabled={stop.trailerType === "Power Only"}
                      canUnselect
                    />
                  </Col>

                  {stop.trailerType === "Reefer" && index === 0 && (
                    <>
                      <Col lg="6">
                        <InputMiler
                          label="Min Temp"
                          value={stop.minTemp}
                          type="number"
                          name={"minTemp"}
                          onChange={(e) => {
                            dispatch({
                              type: "minTemp",
                              category: "stop",
                              index,
                              value: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col lg="6">
                        <InputMiler
                          label="Max Temp"
                          value={stop.maxTemp}
                          type="number"
                          name={"maxTemp"}
                          onChange={(e) =>
                            dispatch({
                              type: "maxTemp",
                              category: "stop",
                              index,
                              value: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>

            <AddressToggle
              address={stop.address}
              findError={findError}
              index={index}
              dispatch={dispatch}
              calcShipmentDistance={calcShipmentDistance}
              shipmentType={state.shipmentType}
            />

            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card">
                <CardTitle className="shipment-detail-header">
                  <p className="shipment-detail-title"> TIMES</p>
                </CardTitle>
                <Row>
                  <Col lg="6" className="my-6 mb-2">
                    <DateTimeInput
                      label="Earliest Arrival"
                      error={findError(`Stops[${index}].earliest_arrival`)}
                      value={stop.earliest_arrival}
                      required
                      onChange={(value) => {
                        dispatch({
                          type: "earliest_arrival",
                          category: "stop",
                          index,
                          value: value,
                        });
                      }}
                    />
                  </Col>

                  <Col lg="6">
                    <DateTimeInput
                      label="Latest Arrival"
                      error={findError(`Stops[${index}].latest_arrival`)}
                      value={stop.latest_arrival}
                      required
                      onChange={(value) => {
                        dispatch({
                          type: "latest_arrival",
                          category: "stop",
                          index,
                          value: value,
                        });
                      }}
                    />
                  </Col>
                  <Col lg="6" className="my-6 mb-2">
                    <DateTimeInput
                      label="Earliest Departure"
                      error={findError(`Stops[${index}].earliest_departure`)}
                      value={stop.earliest_departure}
                      onChange={(value) => {
                        dispatch({
                          type: "earliest_departure",
                          category: "stop",
                          index,
                          value: value,
                        });
                      }}
                    />
                  </Col>
                  <Col lg="6">
                    <DateTimeInput
                      label="Latest Departure"
                      error={findError(`Stops[${index}].latest_departure`)}
                      value={stop.latest_departure}
                      onChange={(value) => {
                        dispatch({
                          type: "latest_departure",
                          category: "stop",
                          index,
                          value: value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                {!stop.stopId && (
                  <Row>
                    <Col lg="12">
                      <InputMiler
                        name="note"
                        label="Note"
                        type="textarea"
                        value={findInitialNote()}
                        onChange={(e) =>
                          dispatch({
                            type: "stopNote",
                            category: "stop",
                            index,
                            value: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>

            <Card className=" mx-2 my-2">
              <CardBody className="shipment-detail-card py-1">
                <CardTitle className="shipment-detail-header my-0">
                  <p
                    className={`shipment-detail-title my-0 ${
                      yellowTitle && "yellow"
                    }`}
                  >
                    REFERENCE
                  </p>
                  <div className="d-flex align-items-center">
                    <button
                      className="shipment-detail-down-arrow"
                      onClick={() => setIsOpen(true)}
                    >
                      <i className="mil-plus" />
                    </button>
                  </div>
                </CardTitle>
                {stop.stopReferences.length !== 0 && (
                  <div className="new-charge-container">
                    <DataTable
                      columns={columns}
                      data={stop.stopReferences}
                      className="shipment"
                      responsive={true}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <ReferenceModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dispatch={dispatch}
        index={index}
      />
    </>
  );
};

export default StopListRow;
