import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import DriverForm from "../driverForm/DriverForm";
import { postElement } from "../../../../services/milerApi";
import data from "./driverData";


const NewDriver = ({ setNewDriver, getDrivers }) => {
  const [state, setState] = useState(data);
  const [errors, setFormErrors] = useState([]);

  const dispatch = (action) => {
    if (action.type === "geo") {
      setState({
        ...state,
        homeLocation: {
          longitude: action.lat,
          latitude: action.lng,
        },
      });
    }

    if (action.category) {
      setState({
        ...state,
        [action.category]: {
          ...state[action.category],
          [action.type]: action.value,
        },
      });
    } else {
      setState({ ...state, [action.type]: action.value });
    }
  };

  const createDriver = () => {
    let accountId;
    let address = null;
    let _address = state.address;
    state.driverType === "Owner Operator Driver"
      ? (accountId = state.payee.accountId)
      : (accountId = null);

    if (
      _address &&
      (_address.addressLine ||
        _address.city ||
        _address.state ||
        _address.countryCode)
    )
      address = _address;
    else address = null;
    /* let reqState =
      state.driverType === "Owner Operator Driver"
        ? { ...state, accountId: parseInt(selectedPayee) }
        : state;
    reqState = {
      ...reqState,
      homeLocation: {
        latitude: reqState.address.latitude,
        longitude: reqState.address.longitude,
      },
    }; */
    postElement("/Driver/Application", { ...state,driverNumber: state?.driverNumber?.trim(), address, accountId }).then(
      (response) => {
        if (response.success) {
          setNewDriver(false);
          getDrivers();
        } else if (response.vErrors) {
          setFormErrors(response.vErrors);
        }
      }
    );
  };

  return (
    state !== null && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Driver</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="cancel"
              onClick={() => {
                localStorage.removeItem("draftDriver");
                setNewDriver(false);
              }}
            />

            <MilerButton btnDark title="Save" onClick={() => createDriver()} />
          </div>
        </div>
        <DriverForm
          state={state}
          dispatch={dispatch}
          errors={errors}
          setFormErrors={setFormErrors}
          fromEdit={false}
        />
      </div>
    )
  );
};

export default NewDriver;
