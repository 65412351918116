import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputMiler from "components/inputMiler/InputMiler";
import { postElement } from "services/milerApi";
import findError from "utils/findError";

export default function AddDocumentModal({
  closeModal,
  addDoc,
  docTypes,
  documentCategory,
  isMarkTonu,
  isEmailComponent,
}) {
  const [document, setDocument] = useState({
    documentType: isMarkTonu ? "TONU" : "",
    file: "",
  });
  const [errors, setErrors] = useState([]);

  const onDocTypeChange = (e) => {
    setDocument({ ...document, documentType: e.target.value });
  };

  const fileChange = (e) => {
    let files = e.target.files;
    const formFile = new FormData();
    formFile.append("Document", files[0]);
    setDocument({ ...document, file: formFile });
  };

  const addDocument = (document) => {
    isEmailComponent
      ? postElement(
          `/Document?DocumentCategory=${documentCategory}`,
          document.file,
          "", //version
          { ignoreToast: true }, //toastOptions
          30000 //timeout )
        ).then((response) => {
          if (response.success) {
            addDoc({
              documentUrl: response.response.data,
            });
          }
        })
      : postElement(
          `/Document?DocumentCategory=${documentCategory}`,
          document.file,
          "", //version
          { ignoreToast: true }, //toastOptions
          30000 //timeout
        ).then((response) => {
          if (response.success) {
            addDoc({
              createdUTC: new Date().toISOString(),
              documentType: document.documentType,
              documentUrl: response.response.data,
            });
            closeModal();
          } else if (response.vErrors) {
            setErrors(response.vErrors);
          }
        });
  };

  return (
    <div className="">
      <Modal isOpen={true} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Add Document</ModalHeader>
        <ModalBody>
          {isEmailComponent ? (
            <></>
          ) : (
            <InputMiler
              label="Doc Type"
              type="select"
              options={docTypes}
              onChange={onDocTypeChange}
              value={document.documentType}
              disabled={isMarkTonu}
            />
          )}

          <div className="column">
            <InputMiler
              label="File"
              type="file"
              onChange={fileChange}
              error={findError("Document", errors)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <MilerButton
            icon="mil-save-o"
            title="Save"
            onClick={() => {
              addDocument(document);
            }}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
