import MilerButton from "components/milerButton/MilerButton";
import SearchComp from "./SearchComp";

const Equipmentsearcher = ({
  onItemSelect,
  label,
  value,
  eqType,
  drivers,
  error,
  required,
  hideCreateNew,
  //canUnselect,
  disabled,
}) => {
  const onSelect = (item) => {
    let items = item.equipment.split(" ");
    const [unitNumber, equipType, make, model, year, plate] = items;
    let unitType = item.equipmentType === "Vehicle" ? "Tractor" : "Trailer";
    let split = {
      unitId: item.id,
      unitNumber,
      equipType,
      unitType,
      make,
      model,
      year,
      plate,
    };
    onItemSelect({ ...item, split });
  };
  const newNumberInput = (item) => {
    //toBeFixed
    return (
      <>
        <div className="advance-search__item container typed-value-item  ">
          <span>{item}</span>
          <div className="ml-4">
            <MilerButton
              title="Select"
              btnDark
              xs
              onClick={() => {
                onItemSelect({ value: item, newEntry: true });
              }}
            />
          </div>
        </div>

        {/* {canUnselect && (
          <div className="advance-search__item power-only-value">
            <span>None</span>
            <MilerButton
              title="Select"
              btnDark
              xs
              onClick={() => {
                onItemSelect({ unselect: true });
              }}
            />
          </div>
        )} */}
      </>
    );
  };
  const searchResultItem = (item) => {
    let items = item.equipment.split(" ");
    let driver = {};
    let coDriver = {};
    if (item.driver) driver.name = item.driver.name;
    if (item.coDriver) coDriver.name = item.coDriver.name;
    return drivers ? (
      <div className="advance-search__item container" key={item.id}>
        <div className="advance-search__item-title row justify-content-between  align-items-center">
          <span>{items[0] || "N/A"}</span>
          <MilerButton
            title="Select"
            btnDark
            xs
            onClick={() => onSelect(item)}
          />
        </div>
        <span className=" row">
          {items[2]},{items[3]}, {items[4]}
        </span>
        <div className="row justify-content-between">
          <section className="column">
            <label>Driver: </label>
            <span>{driver.name}</span>
          </section>
          <section className="column">
            <label>Co-Driver: </label>
            <span>{coDriver.name}</span>
          </section>
        </div>
      </div>
    ) : (
      <div
        className="advance-search__item container equipm-searcher"
        key={item.id}
      >
        <div className="advance-search__item-title row justify-content-between  align-items-center">
          <span>{items[0] || "N/A"}</span>
          <MilerButton
            title="Select"
            btnDark
            xs
            onClick={() => onSelect(item)}
          />
        </div>
        <span className=" row">
          {items[2]},{items[3]}, {items[4]}
        </span>
        <div className="row justify-content-between">
          <section className="column">
            <label>Plate: </label>
            <span>{items[5]}</span>
          </section>
          <section className="column">
            <label>Type: </label>
            <span>{item.equipmentType}</span>
          </section>
        </div>
      </div>
    );
  };
  return (
    <div>
      <SearchComp
        label={label}
        searchResultItem={searchResultItem}
        url={`Equipment/Search?unitType=${eqType}&unitNumber`}
        error={error}
        value={value}
        newNumberInput={hideCreateNew ? null : newNumberInput}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export default Equipmentsearcher;
