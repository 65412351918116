
// reactstrap components
import {
  Form,
  Input,
} from "reactstrap";

const StepOne = ({ state, setState }) => {
  return (
    <>
      <Form role="form">
        <label>What can we help you with?</label>
        <Input
          placeholder="Enter service request"
          type="textarea"
          rows="5"
          value={state.description}
          onChange={(e) => setState({ ...state, description: e.target.value })}
        />
      </Form>
    </>
  );
};

export default StepOne;
