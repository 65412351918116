import { useState } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import permissionDeniedToast from "utils/permissionDeniedToast";
import "./elipsis-menu.sass";

const ElipsisMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  return (
    <Dropdown isOpen={!collapsed} toggle={toggle} className="elipsis-menu">
      <DropdownToggle>
        <i
          className={`${
            props.icon ? props.icon : "mil-more-vertical"
          } dropdown-icon`}
        ></i>
      </DropdownToggle>
      <DropdownMenu>{props.children}</DropdownMenu>
    </Dropdown>
  );
};
export const MenuItem = ({
  icon,
  title,
  onClick,
targetActionDenied,
  disableMenuItem,
  importIcon,

}) => (
  <DropdownItem
    className={targetActionDenied || disableMenuItem ? "item-disabled" : ""}
    onClick={targetActionDenied ? permissionDeniedToast : onClick}
  >
    {importIcon && importIcon}
    <i className={icon}></i> <span>{title}</span>
  </DropdownItem>
);

export default ElipsisMenu;
