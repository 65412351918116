import "./ProfilePlaceholder.sass";
import { BsExclamationLg } from "react-icons/bs";

export default function ProfilePlaceholder({
  firstName,
  lastName,
  jointName,
  error,
}) {
  let f = "";
  let l = "";
  if (jointName) {
    let names = jointName.split(" ");
    firstName = names[0];
    lastName = names[1];
  }
  if (firstName) {
    f = firstName.charAt(0).toUpperCase();
  }
  if (lastName) {
    l = lastName.charAt(0).toUpperCase();
  }

  return (
    <div className="profile-placeholder">
      {error ? (
        <div className="circle-style">
          <BsExclamationLg style={{ fontSize: "22px", color: "red" }} />
        </div>
      ) : (
        <div className={(f || l) && "circle-style"}>{f + l}</div>
      )}
    </div>
  );
}
