import { useContext } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import findError from "utils/findError";
import RecurringCharges from "./recurring-charges/RecurringCharges";

const PayeeForm = ({ payee, setPayee, errors, disablePayee, getPayee }) => {
  const { enums } = useContext(appContext);
  const onChange = (e, type) => {
    const { name, value, type: _type } = e.target;

    if (!type) {
      if (name === "payeeRating") {
        setPayee((prev) => ({
          ...prev,
          [name]: value,
          payRate: "",
          teamRate: "",
        }));
      } else if (_type === "number") {
        let _value = "";
        if (1 <= value) _value = value;
        setPayee((prev) => ({ ...prev, [name]: _value }));
      } else {
        setPayee((prev) => ({ ...prev, [name]: value }));
      }

      return;
    } else if (type === "deduction") {
      setPayee((prev) => ({
        ...prev,
        deduction: { ...prev.deduction, [name]: value },
      }));

      return;
    } else if (type === "earning") {
      setPayee((prev) => ({
        ...prev,
        earning: { ...prev.earning, [name]: value },
      }));

      return;
    }
  };

  const updateCharges = (charges) => {
    setPayee({ ...payee, recurring: charges });
  };

  const payRateConverter = (value, type) => {
    if (value === "" || isNaN(value)) {
      return "";
    }
    if (type === "divide") {
      return eval((value / 100).toFixed(4));
    } else {
      return eval((value * 100).toFixed(2));
    }
  };

  return (
    <>
      <div className="miler-card mt-0">
        <div className="miler-card-title">Payee</div>
        <div className="row">
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              type="text"
              value={payee.name || ""}
              name="name"
              label="Name"
              onChange={onChange}
              //error={findError("Name")}
              disabled={disablePayee}
            />
          </div>
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              type="select"
              value={payee.accountType}
              onChange={onChange}
              name="accountType"
              options={enums.AccountType}
              label="Payee Type"
              disabled={disablePayee}
              //error={findError("AccountType")}
            />
          </div>
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              type="text"
              value={payee.email}
              name="email"
              label="Payee Email"
              onChange={onChange}
              error={findError("Email", errors)}
              disabled={disablePayee}
              required
            />
          </div>
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              type="number"
              value={payee?.ytdEarning}
              onChange={onChange}
              name="ytdEarning"
              label="YTD Earning"
            />
          </div>
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              type="text"
              value={payee.code}
              name="code"
              label="Payee Code"
              onChange={onChange}
              error={findError("Code", errors)}
              disabled={disablePayee}
              required
            />
          </div>
          <div className="col col-12 col-lg-6 ">
            <InputMiler
              value={payee.payeeRating}
              type="select"
              onChange={onChange}
              name="payeeRating"
              options={enums.Rating}
              label="Payee Method"
            />
          </div>

          {payee.payeeRating === "Percentage" ? (
            <>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.payRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="payRate"
                  label="Pay Rate"
                  postfix="%"
                />
              </div>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.teamRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="teamRate"
                  label="Team Pay Rate"
                  postfix="%"
                />
              </div>
            </>
          ) : payee.payeeRating === "Distance" ? (
            <>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.payRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="payRate"
                  label={"Loaded Rate"}
                  postfix="&#162;"
                />
              </div>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.teamRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="teamRate"
                  label="Team Loaded Rate"
                  postfix="&#162;"
                />
              </div>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.emptyMileRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="emptyMileRate"
                  label="Empty Mile Rate"
                  postfix="&#162;"
                />
              </div>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payRateConverter(payee.emptyTeamRate)}
                  onChange={(e) => {
                    let { name, value } = e.target;
                    value = payRateConverter(value, "divide");
                    onChange({ target: { name, value } });
                  }}
                  name="emptyTeamRate"
                  label="Team Empty Mile Rate"
                  postfix="&#162;"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payee.payRate}
                  onChange={onChange}
                  name="payRate"
                  label={"Flat Rate"}
                  postfix="$"
                />
              </div>
              <div className="col col-12 col-lg-6 ">
                <InputMiler
                  type="number"
                  value={payee.teamRate}
                  onChange={onChange}
                  name="teamRate"
                  label={"Team Flat Rate"}
                  postfix="$"
                />
              </div>
            </>
          )}
        </div>
      </div>

      <RecurringCharges
        recurring={payee.recurring || []}
        updateCharges={updateCharges}
        getPayee={getPayee}
      />
    </>
  );
};

export default PayeeForm;
