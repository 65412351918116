import { toast } from "react-toastify";

let loadingToast;

export const Toast = {
  loading: (text) => {
    loadingToast = toast.loading(text, { isLoading: true });
    return loadingToast;
  },

  success: (message, toastCode = "") => {
    toast.update(toastCode || loadingToast, {
      render: message,
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  },

  error: (message, toastCode = "") => {
    toast.update(toastCode || loadingToast, {
      render: message,
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });
  },

  close: (message, toastCode) => {
    toast.update(toastCode || loadingToast, {
      render: message || "",
      isLoading: false,
      autoClose: message ? 2000 : 1,
    });
  },
};
