import CellBuilder from "components/cellBuilderTable/CellBuilder";
import dateFormatter from "utils/dateFormater";
import { digitsToCurrency } from "utils/currencyFormatter";

export const TransactionsColumsa = [
  {
    name: "Transaction #",
    selector: (row) => row.transactionId,
    cell: (row) => CellBuilder(row.transactionId),
  },
  {
    name: "Account #",
    selector: (row) => row.accountId,
    cell: (row) => CellBuilder(row.accountId),
  },
  {
    name: "Creation Date",
    selector: (row) => dateFormatter(row.createdutc),
    cell: (row) => CellBuilder(dateFormatter(row.createdutc)),
  },

  {
    name: "Transaction Date",
    selector: (row) => dateFormatter(row.transactionUtc),
    cell: (row) => CellBuilder(dateFormatter(row.transactionUtc)),
    grow: 0.8,
  },

  {
    name: "Amount",
    selector: (row) => digitsToCurrency(row.transactionAmount),
    cell: (row) =>
      CellBuilder(digitsToCurrency(row.transactionAmount), row.transactionType, "blue"),
    grow: 0.8,
  },

  {
    name: "Code",
    selector: (row) => row.transactionCode,
    grow: 0.5,
  },
];
