import { useContext, useState } from "react";
import "./ShipmentForm.sass";
//import AddShipmentDocuments from "components/shipment/newShippment/AddShipmentDocuments";
import ShipmentInfo from "components/shipment/shipmentForm/ShipmentInfo";
import PackagingInfo from "components/shipment/shipmentForm/PackagingInfo";
import Stops from "components/shipment/shipmentForm/Stops";
import Charges from "components/shipment/shipmentForm/charges/Charges";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import "./ShipmentDetails.css";
import AddOns from "components/shipment/shipmentForm/AddOns";
import { Card, CardBody, CardTitle } from "reactstrap";
import DataTable from "react-data-table-component";
import ShipmentReferenceModal from "components/shipment/shipmentForm/ShipmentReferenceModal";
import ShipmentTags from "components/shipment/shipmentForm/tags/ShipmentTags";

const ShipmentForm = ({ shipment, errors, dispatch, isManifest }) => {
  const {
    enums: { DocumentTypeShipment },
  } = useContext(appContext);

  const [isOpen, setIsOpen] = useState(false);

  const addDoc = (document) => {
    dispatch({ category: "newDocument", value: document });
  };
  const delDoc = (index) => {
    dispatch({ category: "deleteDocument", index });
  };

  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key &&
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const containsErrors = (ele) => {
    const err = errors.find((error) => error.key && error.key.includes(ele));
    return err && true;
  };

  const ShipmentReference = () => {
    const { shipmentReferences } = shipment;
    const columns = [
      {
        name: "REFERENCE TYPE",
        selector: (row) => row.referenceType,
        sortable: true,
        grow: 4,
        cell: (row) => (
          <div className="ship-detail-charge-text">{row.referenceType}</div>
        ),
      },
      {
        name: "REFERENCE",
        selector: (row) => row.referenceId,
        grow: 4,
        cell: (row) => (
          <div className="ship-detail-charge-text">{row.referenceId}</div>
        ),
      },
      {
        name: "",
        selector: (row) => row.referenceId,
        grow: 1,
        cell: (itemIdex) => (
          <i
            className="mil-delete-o"
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch({
                category: "deleteStopShipmentReference",
                refIndex: itemIdex,
              });
            }}
          />
        ),
      },
    ];
    return (
      <>
        <Card className="my-2">
          <CardBody className="shipment-detail-card py-1">
            <CardTitle className="shipment-detail-header my-0">
              <p className="shipment-detail-title">SHIPMENT REFERENCE</p>
              <div className="d-flex align-items-center">
                <button
                  className="shipment-detail-down-arrow"
                  onClick={() => setIsOpen(true)}
                >
                  <i className="mil-plus" />
                </button>
              </div>
            </CardTitle>
            <div className="new-charge-container">
              <DataTable
                columns={columns}
                data={shipmentReferences ?? []}
                noDataComponent={
                  <div style={{ color: "#868C97", padding: "30px" }}>
                    No references
                  </div>
                }
                className="shipment"
                responsive={true}
              />
            </div>
          </CardBody>
        </Card>
        <ShipmentReferenceModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dispatch={dispatch}
        />
      </>
    );
  };
  return (
    <div className="shipment-form">
      <ShipmentInfo
        state={shipment}
        dispatch={dispatch}
        findError={findError}
        isManifest={isManifest}
      />
      <AddOns state={shipment} dispatch={dispatch} />
      <ShipmentTags state={shipment} dispatch={dispatch} />
      <PackagingInfo state={shipment} dispatch={dispatch} errors={errors} />
      <ShipmentReference />
      <Stops
        state={shipment}
        dispatch={dispatch}
        findError={findError}
        containsErrors={containsErrors}
        isManifest={isManifest}
      />
      {!isManifest && (
        <Charges
          state={shipment}
          dispatch={dispatch}
          findError={findError}
          containsErrors={containsErrors}
        />
      )}

      {!shipment.shipmentId && (
        <AssociatedDocuments
          documents={shipment.documents}
          delDoc={delDoc}
          addDoc={addDoc}
          enums={DocumentTypeShipment}
          documentCategory="Shipment"
        />
      )}
    </div>
  );
};

export default ShipmentForm;
