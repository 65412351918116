import BorderedText from "components/bordered-text/BorderedText";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import SliderModal from "components/sliderModal/SliderModal";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { getElementsV2 } from "services/milerApi";
import { getCookie } from "utils/cookies";
import dateFormatter from "utils/dateFormater";
import dateSort from "utils/dateSort";
import joinStrings from "utils/joinStrings";
import ActivityDisplaySlider from "views/activities-v2/activityDisplay/ActivityDisplaySlider";
import "./Trips.sass";
import CustomPagination from "components/custom-pagination/CustomPagination";
import { digitsToCurrency } from "utils/currencyFormatter";

const Trips = ({ id, setDetails, fromVehicle, fromDriver }) => {
  const [trips, setTrips] = useState([]);
  const [openTrip, setOpenTrip] = useState(false);
  const [loading, setLoading] = useState();
  const history = useHistory();
  const [request, setRequest] = useState({
    limit: 50,
    offset: 1,
  });
  const { isMetric } = getCookie("carrierInfo");
  const _onActivityClickHandler = (value, tripId, shipmentNumber) => {
    let path = `/activity`;
    if (value === 0) return;
    setDetails(false);
    window.sessionStorage.setItem("activity_activeTab", 1);
    history.push({
      pathname: path,
      search: shipmentNumber
        ? `?shipmentNumber=${shipmentNumber}`
        : `?tripId=${tripId}`,
    });
  };

  const columns = [
    {
      name: "SHIPMENT#",
      selector: (row) => row.shipmentNumber,
      grow: 3,
      cell: (row) => row.shipmentNumberJsx,
    },
    {
      name: "TRIP#",
      selector: (row) => row.tripId,
      grow: 2,
      cell: (row) => row.tripIdJsx,
    },
    {
      name: "PICKUP",
      selector: (row) => row.pickup,
      grow: 3,
      cell: (row) => CellBuilder(row.pickup, row.pickUpTime),
      sortFunction: (rowA, rowB) =>
        dateSort(rowA.tripStartUtc, rowB.tripStartUtc),
    },
    {
      name: "DROPOFF",
      selector: (row) => row.dropoff,
      grow: 3,
      cell: (row) => CellBuilder(row.dropoff, row.dropOffTime),
      sortFunction: (rowA, rowB) => dateSort(rowA.tripEndUtc, rowB.tripEndUtc),
    },
    {
      name: "DISTANCE",
      selector: (row) => row.distance,
      grow: 2,
      cell: (row) => CellBuilder(row.distance, row.distanceEmpty, ""),
    },
    {
      name: "Revenue",
      selector: (row) => row.revenue,
      grow: 2,
      cell: (row) => CellBuilder(row.revenue ? digitsToCurrency(row.revenue) : digitsToCurrency(0)),
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      grow: 3.2,
    },
  ];
  const renderTrip = (tripId, tripBasis) => {
    return (
      <div className="trip-ship-column">
        <a
          onClick={() => {
            setOpenTrip(tripId);
          }}
          className={`head font-weight-bold trip`}
        >
          {tripId}
        </a>

        {/* <span className="trip-basis">{tripBasis}</span> */}
        <BorderedText text={tripBasis} />
      </div>
    );
  };

  const renderShipmentNumber = (shipmentNumber) => {
    return (
      <div className="trip-ship-column">
        <a
          onClick={() => {
            _onActivityClickHandler("", "", shipmentNumber);
          }}
          className={`head font-weight-bold trip`}
        >
          {shipmentNumber}
        </a>
      </div>
    );
  };
  let tripsList = trips.map((data) => {
    let distance = `${data.distance ? data.distance : 0} ${
      isMetric ? "km" : "mi"
    }`;
    let distanceEmpty = `${data.distanceEmpty ? data.distanceEmpty : 0} ${
      isMetric ? "km" : "mi"
    }`;
    return {
      tripStartUtc: data.tripStartUtc,
      tripEndUtc: data.tripEndUtc,
      shipmentNumber: data.shipmentNumber,
      shipmentNumberJsx: renderShipmentNumber(
        data.shipmentNumber,
        data.tripStatus
      ),
      tripId: data.tripId,
      tripIdJsx: renderTrip(data.tripId, data.tripBasis),
      status: <BorderedText text={data.tripStatus} classes={data.tripStatus} />,
      rawStatus: data.tripStatus,
      pickup: joinStrings(
        [data.pickUpAddress?.city, data.pickUpAddress?.state],
        ", "
      ),
      dropoff: joinStrings(
        [data.deliveryAddress?.city, data.deliveryAddress?.state],
        ", "
      ),
      pickUpTime: dateFormatter(data.tripStartUtc, "MMM DD, HH:mm"),
      dropOffTime: dateFormatter(data.tripEndUtc, "MMM DD, HH:mm"),
      distance,
      revenue:data?.revenue,
      distanceEmpty,
    };
  });

  useEffect(() => {
    const queryStr = new URLSearchParams(request).toString();
    if (id) {
      if (fromDriver) {
        getElementsV2({
          url: `/Driver/${id}/Trips?${queryStr}`,
          setLoading:setLoading
        }).then((res) => {
          setTrips(res.data);
        });
      } else {
        getElementsV2(
          {
            url: `/Equipment/${
              fromVehicle ? "Vehicle" : "Trailer"
            }/${id}/Trips?${queryStr}`,
            setLoading: setLoading
          }
        )
          .then((res) => {
            setTrips(res.data || []);
          })
      }
    }
  }, [request]);

  return (
    <div className="vehicle_details_container">
      <div className="summary-list">
        {loading ? (
          loading
        ) : (
          <>
            <DataTable columns={columns} data={tripsList} responsive={true} />
            <CustomPagination
              request={request}
              setRequest={setRequest}
              nextExists={trips.length >= request.limit}
            />
          </>
        )}

        <SliderModal clickState={Boolean(openTrip)} setClickState={setOpenTrip}>
          <ActivityDisplaySlider
            tripId={openTrip}
            closeTripSlider={setOpenTrip}
          />
        </SliderModal>
      </div>
    </div>
  );
};

export default Trips;
