
// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";


const StepFour = ({ state, setState}) => {
  return (
    <>
      <Form role="form">
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-first-name">
                First Name
              </label>
              <Input
                name="first-name"
                id="input-first-name"
                className="form-control"
                type="text"
                value={state.firstName}
                onChange={(e) =>
                  setState({ ...state, firstName: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-last-name">
                Last Name
              </label>
              <Input
                name="last-name"
                id="input-last-name"
                className="form-control"
                type="text"
                value={state.lastName}
                onChange={(e) =>
                  setState({ ...state, lastName: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-phone">
                Phone
              </label>
              <Input
                name="phone"
                id="input-phone"
                className="form-control"
                type="text"
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
              />
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-email">
                Email
              </label>
              <Input
                name="email"
                id="input-email"
                className="form-control"
                type="text"
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckRegister"
                type="checkbox"
                checked={state.receiveNotification}
                onChange={() =>
                  setState({
                    ...state,
                    receiveNotification: !state.receiveNotification,
                  })
                }
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckRegister"
              >
                <span className="text-muted">
                  Would you like to receive timely notifications about the
                  progress of this service request?
                </span>
              </label>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default StepFour;
