import dayjs from "dayjs";
import { postElement } from "services/milerApi";
import calculateStopsDistance from "./calculateStopsDistance";

const { getGatewayApi } = require("services/gatewayApis");

export const calculateEta = (
  currentTrip,
  nextStop,
  fn,
  note,
  { onError } = {}
) => {
  getGatewayApi(
    `carrier/integration/eld/vehicles/location?include=gps&eld_id=${currentTrip.eldId}`
  )
    .then((r) => {
      let data = (r.data?.data || [])[0] || {};
      let address = data.address || {};
      const currentAddress = {
        addressLine: address.addressLine,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        countryCode: address.countryCode.toLowerCase(),
        latitude: address.latitude,
        longitude: address.longitude,
        timeAddressObtained: data.last_seen,
        formattedLocation: data.last_seen_location,
      };

      calculateStopsDistance(
        [{ address: currentAddress }, nextStop],
        () => {},
        (duration, distance) => {
          let eta = dayjs().add(duration, "seconds").toISOString();

          let check = {
            longitude: currentAddress.longitude,
            latitude: currentAddress.latitude,
            nextStopId: nextStop.stopId,
            eta,
            vehicleLocation: currentAddress.formattedLocation,
            notes: "Automated Check Call Refresh",
            remainigDistance: distance,
            NextStopTimeZone: nextStop.timezoneOffset,
          };
          if (note) {
            check = { ...check, notes: note };
          }

          postElement(
            `/Shipment/Trip/${currentTrip.tripId}/AutoCheck`,
            check
          ).then(fn);
        }
      );

      return currentAddress;
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    });
};
