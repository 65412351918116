import IPCard from "./inprogressTripCard/IPCard";

import "./InprogressTrip.sass";
import MapsMiler from "components/mapMiler/MapsMiler";
import ShipmentInfoBox from "../AvailableTrip/shipment-info-box/ShipmentInfoBox";

export default function InprogressTrip({ currentTrip, getTrips, tracking }) {
  const tripsList = [...currentTrip.stops];
  let inprogFound = false;
  return (
    <div className="trip-display-body-flex p-2">
      <div>
        {tripsList.map((stop, index) => {
          let c;
          if (inprogFound) {
            c = "Pending";
          } else if (stop.actualDeparture) {
            c = "Completed";
          } else {
            c = "Inprogress";
            inprogFound = true;
          }
          stop.progress = c;
          return (
            <IPCard
              key={index}
              stop={stop}
              index={index}
              stopLength={tripsList.length}
              handleFetchData={getTrips}
            />
          );
        })}
      </div>
      <div className="trip-info-right w-100">
        {tripsList &&
          (tracking ? (
            <MapsMiler
              unitNumber={currentTrip.unitNumber}
              type="tracking"
              eldId={currentTrip.eldId}
            />
          ) : (
            <ShipmentInfoBox />
          ))}
      </div>
    </div>
  );
}
