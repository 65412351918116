import { useEffect, useState } from "react";
import RecurringShipmentDates from "views/shipments/recurringShipment/recurringShipmentForm/DateInputs";
import RecurringShipmentFrequency from "views/shipments/recurringShipment/recurringShipmentForm/Frequency";
import Loading from "components/loading/Loading";
import { getElements } from "services/milerApi";
import MasterShipment from "./recurringShipmentForm/masterShipment/MasterShipment";

const RecurringShipmentForm = ({
  type,
  current,
  setCurrent,
  onChange,
  shipmentId,
  errors,
  getShipments
}) => {
  const [loading, setLoading] = useState(false);

  const getReccuringShipment = () => {
    setLoading(true);
    getElements(`/Shipment/${shipmentId}/Recurring`)
      .then((response) => {
        if (response.data) {
          setCurrent({
            ...response.data,
            startUTC: response?.data?.startUtc,
            endUTC: response?.data?.endUtc,
            masterShipment:
              Object.keys(response?.data?.masterShipment).length > 0
                ? [response?.data?.masterShipment]
                : [],
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReccuringShipment();
  }, []);

  return (
    <div className="shipment-form-container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <RecurringShipmentDates
            type={type}
            current={current}
            onChange={onChange}
            setCurrent={setCurrent}
            errors={errors}
          />

          <MasterShipment masterShipment={current?.masterShipment} getShipments={getShipments} getReccuringShipment={getReccuringShipment} />
          <RecurringShipmentFrequency
            type={type}
            current={current}
            onChange={onChange}
            setCurrent={setCurrent}
          />
        </>
      )}
    </div>
  );
};

export default RecurringShipmentForm;
