import { createContext, useState } from "react";
import { getElements } from "services/communicationsApi";

export const MessagingContext = createContext();
export const WhatsAppContext = createContext();

export const MessagingContextProvider = ({ children }) => {
  const [messagesArr, setMessagesFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const getMessages = (driver) => {
    if (driver) {
      getElements(`/Messaging/User/${driver.user_id}`).then((response) => {
        setMessagesFunc(response.data);
        setLoading(false);
      });
    }
  };

  return (
    <MessagingContext.Provider
      value={{
        messages: messagesArr,
        setMessages: setMessagesFunc,
        getMessages,
        loading,
        setLoading,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};

export const WhatsAppContextProvider = ({ children }) => {
  const [messagesArr, setMessagesFunc] = useState([]);
  const [loading, setLoading] = useState(true);
  const getMessages = (driver) => {
    if (driver) {
      getElements(`/Whatsapp/User/${driver.whatsappSubscriberId}`).then(
        (response) => {
          setMessagesFunc(response.data);
          setLoading(false);
        }
      );
    }
  };

  return (
    <WhatsAppContext.Provider
      value={{
        messages: messagesArr,
        setMessages: setMessagesFunc,
        getMessages,
        loading,
        setLoading,
      }}
    >
      {children}
    </WhatsAppContext.Provider>
  );
};
