import React, { useContext, useState } from "react";
import Loading from "components/loading/Loading";
import CarriersHeader from "./carriers-summary/CarriersHeader";
import FailedToFetch from "components/loading/FailedToFetch";
import { getElements } from "services/milerApi";
import { ToolBarContext } from "layout/toolbar/context/ToolbarContext";
import CarrierSummary from "./carriers-summary/CarrierSummary";
function Carriers() {
  const { dispatch } = useContext(ToolBarContext);
  const [loading, setLoading] = useState(Loading);
  const [carriers, setCarriers] = useState([]);

  const getCarriers = () => {
    dispatch({ type: "resetCount", payload: "Carriers" });
    setLoading(Loading);
    getElements(`/Provider/Carriers?offset=1&limit=50`)
      .then((res) => {
        dispatch({
          type: "setCurrent",
          payload: {
            current: "Carriers",
            count: res.data.length,
          },
        });
        setLoading(false);

        setCarriers(res.data);
      })
      .catch(() => setLoading(<FailedToFetch />));
  };
  React.useEffect(getCarriers, []);
  return (
    <div className="summary-list">
      <CarriersHeader />
      {loading ? loading : <CarrierSummary carriers={carriers} />}
    </div>
  );
}

export default Carriers;
