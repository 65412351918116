import { useState, useEffect } from "react";
import { getElements } from "services/serviceRepairApi";
import InspectionForm from "../inspectionForm/InspectionForm";
import "./InspectionDetails.sass";
const InspectionDetails = ({ setInspectionDetailsOpen, id }) => {
  const [state, setState] = useState(null);

  const getInspectionDetails = () => {
    getElements(`/Inspection/${id}`).then((response) => {
      setState(response.data);
    });
  };

  useEffect(getInspectionDetails, []);

  return (
    state && (
      <div className="inspection_details">
        <div className="new-vehicle">
          <div className="d-flex justify-content-between new-vehicle__top mb-4">
            <h4>Inspection Details</h4>
            <div className="new-vehicle-action-btns d-flex justify-content-center align-items-center">
              <i
                className="mil-close-bg"
                style={{ fontSize: "1.8rem", cursor: "pointer" }}
                onClick={() => setInspectionDetailsOpen(false)}
              />
            </div>
          </div>
          <InspectionForm state={state} setState={setState}  inspectionId={id}/>
        </div>
      </div>
    )
  );
};

export default InspectionDetails;
