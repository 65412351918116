import MessageCard from "./MessageCard";
import { useContext, useEffect, useRef } from "react";
import { TbMessagesOff } from "react-icons/tb";
import { MessagingContext } from "services/contexts/MessagingContext";
import dateFormatter from "utils/dateFormater";

const Messages = ({ getColor }) => {
  const shouldAutoFocusDiv = useRef();

  const { messages } = useContext(MessagingContext);

  const scrollToBottom = () => {
    if (shouldAutoFocusDiv.current) {
      shouldAutoFocusDiv.current.scrollTop =
        shouldAutoFocusDiv.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <MessagingContext.Consumer>
      {(value) => (
        <div className="chat-container">
          <div className="messages-container" ref={shouldAutoFocusDiv}>
            {value.messages.map((message, index) => {
              let showAvatar = true;

              if (index + 1 < messages.length) {
                const nextSameAsCurrent =
                  message.sender.sender_user_id ===
                  messages[index + 1].sender.sender_user_id;
                showAvatar = !nextSameAsCurrent;
              }

              let showGroupedDate = true;
              let groupedDate = dateFormatter(
                messages[0].message_utc,
                "MMM DD, YYYY"
              );

              if (index != 0) {
                showGroupedDate =
                  dateFormatter(message.message_utc, "MMM DD, YYYY") !==
                  dateFormatter(
                    messages[index - 1].message_utc,
                    "MMM DD, YYYY"
                  );

                if (showGroupedDate) {
                  showAvatar = true;
                  groupedDate = dateFormatter(
                    messages[index].message_utc,
                    "MMM DD, YYYY"
                  );
                }
              }

              return (
                <>
                  {showGroupedDate && (
                    <div className="grouped-send-date">{groupedDate}</div>
                  )}

                  <MessageCard
                    message={message}
                    key={message.message_id}
                    index={index}
                    getColor={getColor}
                    showAvatar={showAvatar}
                  />
                </>
              );
            })}

            {!value.messages.length && (
              <div
                style={{
                  alignSelf: "center",
                  justifySelf: "center",
                  marginTop: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TbMessagesOff size={100} />
                <h6>No messages here yet.</h6>
              </div>
            )}
          </div>
        </div>
      )}
    </MessagingContext.Consumer>
  );
};

export default Messages;
