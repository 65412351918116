import { GoogleMap, Marker } from "@react-google-maps/api";
import MilerButton from "components/milerButton/MilerButton";
import { mapStyles } from "constants/mapStyles";
import { useEffect, useState } from "react";
import redMarker from "assets/map/red-marker.png";

const MarkOnMap = ({ setMarkMapOpen, address }) => {
  const [pos, setPos] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    setPos({
      lat: address.latitude ? address.latitude : 0,
      lng: address.longitude ? address.longitude : 0,
    });
  }, []);
  var icon = {
    url: redMarker, // url
    labelOrigin: new window.google.maps.Point(25, -15),
  };
  const containerStyle = {
    width: "100%",
    height: "88vh",
  };
  return (
    <div className='slider-display'>
      <div className='slider-display-header'>
        <div className='slider-header-title'>Service Request Location</div>
        <div className='new-vehicle-action-btns d-flex justify-content-center align-items-center'>
          <MilerButton
            icon='mil-close-bg'
            title='Cancel'
            onClick={() => {
              setMarkMapOpen(false);
            }}
          />
        </div>
      </div>
      <div className='bdy'>
        {address.latitude ? (
          <GoogleMap
            mapContainerClassName='map'
            zoom={16}
            center={pos}
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
            }}
          >
            <Marker
              key={1}
              label={{
                text: address.addressLine,
                fontSize: "17px",
                color: "#444",
              }}
              icon={icon}
              position={pos}
            />
          </GoogleMap>
        ) : (
          <GoogleMap
            mapContainerClassName='map'
            zoom={4.4}
            center={{
              lat: 41.666574490894995,
              lng: -93.32836457326965,
            }}
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
            }}
          ></GoogleMap>
        )}
      </div>
    </div>
  );
};

export default MarkOnMap;
