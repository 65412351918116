import { useEffect, useRef, useState } from "react";
import "./email.sass";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MilerButton from "components/milerButton/MilerButton";
import { Input, Tag } from "antd";
import { MdOutlineAttachment } from "react-icons/md";
import AddDocumentModal from "components/associatedDocuments/AddDocumentModal";
import { postElement } from "services/communicationsApi";
import draftToHtml from "draftjs-to-html";
const EmailComponent = ({
  recipientEmailAddresses,
  senderEmailAddress,
  subject,
  settlement,
  filteredCurrentSettlement,
  carrierName,
  url,
  setOpenEmail,
  setDocumentPreview,
  message,
  isDriverActivityCard,
}) => {
  const inputRef = useRef(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isFocused, setIsFocused] = useState(false);

  const [emailInfo, setEmailInfo] = useState({
    to: recipientEmailAddresses,
    cc: "",
    bcc: "",
    subject: subject,
    message: "",
    htmlContent: "",
  });

  const [showCc, setShowCc] = useState(false);

  const [showBcc, setShowBcc] = useState(false);

  const [showDocumentDialog, setShowDocumentDialog] = useState(false);

  const [emailDocuments, setEmailDocuments] = useState(
    isDriverActivityCard
      ? []
      : [
          {
            documentUrl: url,
          },
        ]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus();
      setShowBcc(false);
      setShowCc(false);
    }
  }, [isFocused]);

  useEffect(() => {
    getHtmlContent();
  }, [editorState]);

  useEffect(() => {
    if (message) {
      const contentState = ContentState.createFromText(message);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [message]);

  const onChange = (event) => {
    const { value, name } = event.target;
    setEmailInfo((prev) => ({ ...prev, [name]: value }));
  };

  const addDoc = (doc) => {
    setEmailDocuments([...emailDocuments, doc]);
    setShowDocumentDialog(false);
  };

  const sendDocToMail = () => {
    if (
      settlement &&
      filteredCurrentSettlement &&
      filteredCurrentSettlement.length > 0 &&
      filteredCurrentSettlement[0].payeeEmail &&
      settlement.accountPayableId
    ) {
      const emialReceipents = [
        emailInfo.to,
        emailInfo.bcc,
        emailInfo.cc,
      ].filter((email) => email !== "");
      const docMailPayload = {
        senderFullName: carrierName,
        senderEmailAddress: senderEmailAddress,
        recipientEmailAddresses: emialReceipents,
        subject: emailInfo.subject,
        plainContent: emailInfo.message,
        attachments: emailDocuments,
        htmlContent: emailInfo.htmlContent,
      };

      postElement(`/Email`, docMailPayload);
    }
  };

  const sendDocToMailDriverActivity = () => {
    const emialReceipents = [emailInfo.to, emailInfo.bcc, emailInfo.cc].filter(
      (email) => email !== ""
    );
    const docMailPayload = {
      senderFullName: carrierName,
      senderEmailAddress: senderEmailAddress,
      recipientEmailAddresses: emialReceipents,
      subject: emailInfo.subject,
      plainContent: emailInfo.message,
      attachments: emailDocuments,
      htmlContent: emailInfo.htmlContent,
    };

    postElement(`/Email`, docMailPayload);
  };
  const IconComponent = () => {
    return (
      <MdOutlineAttachment
        size={27}
        style={{
          cursor: "pointer",
        }}
        onClick={() => setShowDocumentDialog(true)}
      />
    );
  };
  function extractWords(url) {
    const settlementIndex = url?.indexOf("/settlement");
    const shipmentStoreIndex = url?.indexOf("shipmentstore.dev");

    if (settlementIndex !== -1) {
      const afterSettlement = url?.substring(
        settlementIndex + "/settlement".length + 1
      );
      return afterSettlement;
    }

    if (shipmentStoreIndex !== -1) {
      const afterShipmentStore = url?.substring(
        shipmentStoreIndex + "shipmentstore.dev".length + 1
      );
      return afterShipmentStore;
    }
  }

  const deleteDocument = (index) => {
    emailDocuments.splice(index, 1);
  };

  const handleEditorStateChange = (state) => {
    setEditorState(state);
  };

  const getHtmlContent = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    const plainContent = rawContentState.blocks[0]?.text;

    const htmlContent = draftToHtml(rawContentState);

    setEmailInfo({
      ...emailInfo,
      message: plainContent,
      htmlContent: htmlContent,
    });
  };

  return (
    <main className="main">
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Email</div>
          <div className="slider-header-buttons">
            <MilerButton
              btnDark
              title="Send"
              onClick={() => {
                isDriverActivityCard
                  ? sendDocToMailDriverActivity()
                  : sendDocToMail();
              }}
            />
            <MilerButton title="cancel" onClick={() => setOpenEmail(false)} />
          </div>
        </div>
      </div>

      <Input
        placeholder={!isFocused && "Recipients"}
        prefix={isFocused && "To"}
        suffix={
          isFocused && (
            <section className="email_info">
              <p
                className="email_info_two"
                onClick={() => {
                  setShowCc(true);
                  setIsFocused(false);
                }}
              >
                Cc
              </p>
              <p
                className="email_info_one"
                onClick={() => {
                  setShowBcc(true);
                  setIsFocused(false);
                }}
              >
                Bcc
              </p>
            </section>
          )
        }
        onFocus={() => {
          setIsFocused(true);
        }}
        ref={inputRef}
        className="ant-input-focus"
        name="to"
        onChange={onChange}
        value={emailInfo.to}
      />

      {showCc && (
        <Input
          type="text"
          prefix="Cc"
          className="ant-input-focus"
          value={emailInfo.cc}
          name="cc"
          onChange={onChange}
          onFocus={() => {
            setIsFocused(false);
          }}
          suffix={
            !showBcc && (
              <section className="email_info">
                <p
                  className="email_info_one"
                  onClick={() => {
                    setShowBcc(true);
                    setIsFocused(false);
                  }}
                >
                  Bcc
                </p>
              </section>
            )
          }
        />
      )}

      {showBcc && (
        <Input
          type="text"
          prefix="Bcc"
          value={emailInfo.bcc}
          name="bcc"
          onChange={onChange}
          onFocus={() => {
            setIsFocused(false);
          }}
          className="ant-input-focus"
        />
      )}
      <section className="email_subject">
        <input
          type="text"
          placeholder="Subject"
          className="email_input"
          name="subject"
          onChange={onChange}
          onFocus={() => {
            setShowBcc(false);
            setShowCc(false);
          }}
          value={emailInfo.subject}
        />
      </section>

      <Editor
        editorState={editorState}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "list",
            "history",
            "image",
            "colorPicker",
          ],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          image: {
            urlEnabled: false,
            uploadEnabled: false,
            component: IconComponent,
          },
        }}
        onEditorStateChange={handleEditorStateChange}
      />
      {showDocumentDialog && (
        <AddDocumentModal
          closeModal={() => setShowDocumentDialog(false)}
          isEmailComponent={true}
          addDoc={addDoc}
          documentCategory="Shipment"
        />
      )}
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "flex-end",
          height: "60vh",
        }}
      >
        {emailDocuments.length > 0 &&
          emailDocuments.map((email, index) => {
            return (
              <Tag
                key={email.documentUrl}
                closable
                style={{
                  width: "fit-content",
                  cursor: "pointer",
                  borderRadius: "10px",
                }}
                onClose={() => deleteDocument(index)}
                onClick={() => {
                  setDocumentPreview((prev) => ({
                    ...prev,
                    showDocument: true,
                    url: email.documentUrl,
                  }));
                }}
              >
                {extractWords(email.documentUrl)}
              </Tag>
            );
          })}
      </section>
    </main>
  );
};

export default EmailComponent;
