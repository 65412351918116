import { useEffect, useState, useRef, useContext, useReducer } from "react";
import { appContext } from "services/AppContext";
import { postElement } from "services/serviceRepairApi";
import ServiceProviderForm from "../serviceProviderForm/ServiceProviderForm";
import serviceProviderReducer from "../serviceProviderForm/serviceProviderReducer";
import { serviceProvider } from "./initialServiceProvider";

import MilerButton from "components/milerButton/MilerButton";

const NewServiceProvider = ({ setNewProviderOpen, onProviderCreated, customerName }) => {
  const { enums } = useContext(appContext);
  const hiddenFileInput = useRef(null);
  const [errors, setFormErrors] = useState([]);

  const [state, dispatch] = useReducer(serviceProviderReducer, serviceProvider);

  const sameAsFxn = () => {
    if (state && state.billingSameasMailing) {
      dispatch({ sameAs: state.billingSameasMailing });
    }
    dispatch({ type: "serviceProviderName", value: customerName });
  };

  useEffect(sameAsFxn, [state && state.billingSameasMailing]);

  const post = async (e) => {
    e.preventDefault();

    postElement("/ServiceProvider", state).then((response) => {
      if (response.success) {
        setNewProviderOpen(false);
        onProviderCreated(state);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    state !== null && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Service Provider</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="Cancel"
              onClick={() => {
                localStorage.removeItem("draftServiceProvider");
                setNewProviderOpen(false);
              }}
            />

            <MilerButton btnDark title="Save" onClick={post} />
          </div>
        </div>
        <ServiceProviderForm
          state={state}
          dispatch={dispatch}
          enums={enums}
          errors={errors}
          handleClick={handleClick}
        />
      </div>
    )
  );
};

export default NewServiceProvider;
