import BorderedText from "components/bordered-text/BorderedText";
import Maps from "components/mapMiler/MapsMiler";
import joinStrings from "utils/joinStrings";
import "../../activities-v2/activityDisplay/tripDisplay/AvailableTrip/shipment-info-box/shipment-info-box.sass";
import { useEffect, useState } from "react";

export default function ShipmentInfoBox({ shipmentDetails, getShipment }) {
  const [temp, setTemp] = useState("");

  let allTrailerTypes = [];
  let allTrailerFunc = () => {
    allTrailerTypes = [];
    shipmentDetails?.stops?.forEach((stop) => {
      if (stop.trailerType && !allTrailerTypes.includes(stop.trailerType)) {
        allTrailerTypes.push(stop.trailerType);
      }
    });

    return allTrailerTypes;
  };

  useEffect(() => {
    const extractMaxMin = () => {
      const reeferStop = shipmentDetails?.stops?.find(
        (stop) => stop.trailerType === "Reefer"
      );
      if (reeferStop) {
        const { minTemp, maxTemp } = reeferStop;

        if (minTemp !== undefined || maxTemp !== undefined) {
          const temperatures = `${
            minTemp !== undefined ? `Min ${minTemp}°F` : ""
          }, ${maxTemp !== undefined ? `Max ${maxTemp}°F` : ""}`;
          setTemp(temperatures);
        }
      } else {
        setTemp("");
      }
    };

    extractMaxMin();
  }, []);

  return (
    <div className="shipment-info-box">
      <div className="title">Shipment Info</div>
      <div className="shipment_map">
        <Maps
          type="snapshots"
          tripStops={shipmentDetails?.stops}
          shipmentId={shipmentDetails?.shipmentId}
          tripMapSnapshot={shipmentDetails?.snapshotUrl}
          shipmentSource={shipmentDetails?.source}
          getCurrentTrip={getShipment}
          showRefreshIcon = {true}
        />
      </div>

      <div className="description">
        <div className="first">
          <div className="contain">
            <div className="name">Shipper</div>
            <div className="value">{shipmentDetails.shipperName}</div>
          </div>
          <div className="contain">
            <div className="name">Shipment Number</div>
            <div className="value">{shipmentDetails.shipmentNumber}</div>
          </div>
          <div className="contain">
            <div className="name">Shipment Type</div>
            <div className="value">{shipmentDetails.shipmentType}</div>
          </div>
        </div>
        <div className="first">
          <div className="contain">
            <div className="name">Equipment</div>
            <div className="value">
              {joinStrings(allTrailerFunc(), " | ") ||
                shipmentDetails.trailerType}
            </div>
          </div>
          <div className="contain">
            <div className="name">Commodity</div>
            <div className="value">
              {shipmentDetails.commodity || "None"}
              {/* {shipmentDetails.commodity !== ""
                ? shipmentDetails.commodity
                : "None"} */}
            </div>
          </div>
          <div className="contain">
            <div className="name">Distance</div>
            <div className="value">{`${shipmentDetails.distance} ${
              shipmentDetails.unitDistance || "mi"
            }`}</div>
          </div>
        </div>

        {temp && (
          <div className="first">
            <div className="contain">
              <div className="name">Temperature Controlled</div>
              <div className="value">{temp}</div>
            </div>

            <div className="contain"> </div>
          </div>
        )}

        <div className="third">
          <div className="contain">
            <div className="name">Add ons</div>
            <div>
              {shipmentDetails.liftgate && (
                <BorderedText classes="blue" text="Liftgate" />
              )}
              {shipmentDetails.twic && (
                <BorderedText classes="blue" text="TWIC" />
              )}
              {shipmentDetails.rollDoor && (
                <BorderedText classes="blue" text="Roll Door" />
              )}
              {shipmentDetails.tarps && (
                <BorderedText classes="blue" text="Tarps" />
              )}
              {shipmentDetails.driverAssist && (
                <BorderedText classes="blue" text="Driver Assist" />
              )}
              {shipmentDetails.team && (
                <BorderedText classes="blue" text="Team" />
              )}
              {shipmentDetails.highValue && (
                <BorderedText classes="blue" text="High Value" />
              )}
              {shipmentDetails.appointmentRequired && (
                <BorderedText classes="blue" text="Appointment Required" />
              )}
              {shipmentDetails.appointmentSet && (
                <BorderedText classes="blue" text="Appointment Set" />
              )}
              {shipmentDetails.hazmat && (
                <BorderedText classes="blue" text="Hazmat" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
