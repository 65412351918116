import Searchcomponent from "components/inputMiler/advanceSearch/SearchComp";

const ShipmentSearcher = ({ onItemSelect, label, value }) => {
  const onSelect = (item) => {
    onItemSelect({ ...item });
  };

  const searchResultItem = (item) => {
    return (
      <div className="advance-search__item container" key={item.shipmentId}>
        <div className="advance-search__item-title row justify-content-between  align-items-center">
          <span onClick={() => onSelect(item)}>
            {item.shipmentNumber || "N/A"}
          </span>
        </div>
        <span className=" row">{item.shipmentType}</span>
        <div className="row justify-content-between">
          <section className="column">
            <label>Pickup: </label>
            <span>{`${item.pickupAddress?.state || ""} ${
              item.pickupAddress?.city || ""
            }`}</span>
          </section>
          <section className="column">
            <label>Delivery: </label>
            <span>{`${item.deliveryAddress?.state || ""} ${
              item.deliveryAddress?.city || ""
            }`}</span>
          </section>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Searchcomponent
        label={label}
        searchResultItem={searchResultItem}
        url={`shipment?limit=50&offset=1&shipment_number`}
        value={value}
      />
    </div>
  );
};

export default ShipmentSearcher;
