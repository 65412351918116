export const mockData = {
  tripId: 1330,
  shipmentNumber: "agxship719",
  tripStatus: "Inprogress",
  tripBasis: "Loaded",
  unitDistance: "Miles",
  createdUTC: "2022-07-19T16:41:56Z",
  make: "FREIGHTLINER",
  model: "CASCADIA",
  vehicleType: "Van",
  unitNumber: "319",
  plateNumber: "PWG3807",
  driverId: 70,
  driverFirstName: "HABIB",
  driverLastName: "MAHAMED",
  driverPhone: "(614) 404-8149",
  driverEmail: "HABIIB21@GMAILCOM",
  driverType: "Company Driver",
  driverOnDutyStatus: "OnDuty",
  coDriverId: 121,
  coDriverFirstName: "HASSAN",
  coDriverLastName: "BARRE",
  coDriverPhone: "(614) 307-6455",
  coDriverEmail: "marwan143ilove@gmailcom",
  coDriverType: "Company Driver",
  payout: 217.6,
  stops: [
    {
      stopId: 6267,
      address: {
        addressId: 2212,
        addressType: "WorkSite",
        addressLine: "3221 W 86th St",
        city: "Indianapolis",
        state: "IN",
        postalCode: "46268",
        countryCode: "us",
        timezoneOffset: -5,
        latitude: 39.9095091,
        longitude: -86.2164159,
        fullAddress: "3221 W 86th St Indianapolis, IN 46268",
        locationName: "Walmart Indianapolos",
      },
      stopType: "Pickup",
      scheduledArrival: "2022-07-19T12:40:00Z",
      scheduledArrivalLocal: "2022-07-19T12:40:00",
      actualArrival: "2022-07-18T14:46:00Z",
      actualArrivalLocal: "2022-07-18T14:46:00",
      actualDeparture: "2022-07-20T19:12:56Z",
      actualDepartureLocal: "2022-07-20T19:12:56",
      status: "Completed",
      trailerType: "Van",
      trailerNumber: "GG5810",
      stopSequence: 1,
      promise: "OnTime",
      earliest_arrival: "2022-07-19T12:40:00Z",
      latest_arrival: "2022-07-19T12:40:00Z",
      stopReferences: [],
      stopNotes: [],
    },
    {
      stopId: 6268,
      address: {
        addressId: 5954,
        addressType: "WorkSite",
        addressLine: "410 Lakeville Rd",
        city: "New Hyde Park",
        state: "NY",
        postalCode: "11042",
        countryCode: "us",
        timezoneOffset: -4,
        latitude: 40.757242,
        longitude: -73.7061323,
        fullAddress: "410 Lakeville Rd New Hyde Park, NY 11042",
        locationName: "GREENEYES",
      },
      stopType: "Delivery",
      scheduledArrival: "2022-07-20T12:40:00Z",
      scheduledArrivalLocal: "2022-07-20T12:40:00",
      status: "Pending",
      trailerType: "Van",
      trailerNumber: "GG5810",
      stopSequence: 2,
      earliest_arrival: "2022-07-20T12:40:00Z",
      latest_arrival: "2022-07-20T12:40:00Z",
      stopReferences: [],
      stopNotes: [],
    },
    {
      stopId: 6272,
      address: {
        addressId: 6495,
        addressLine: "No street",
        city: " Merrillville",
        state: " IN",
        postalCode: " 46385",
        countryCode: "US",
        fullAddress: "No street  Merrillville,  IN  46385",
      },
      stopType: "Start",
      scheduledArrival: "2022-07-19T18:48:26Z",
      scheduledArrivalLocal: "2022-07-19T18:48:26",
      actualArrival: "2022-07-19T18:48:26Z",
      actualArrivalLocal: "2022-07-19T18:48:26",
      scheduledDeparture: "2022-07-19T18:48:26Z",
      scheduledDepartureLocal: "2022-07-19T18:48:26",
      actualDeparture: "2022-07-19T18:48:26Z",
      actualDepartureLocal: "2022-07-19T18:48:26",
      status: "Completed",
      trailerType: "Van",
      trailerNumber: "GG5810",
      stopSequence: 0,
      stopReason: "CompleteLoad",
      promise: "OnTime",
      earliest_arrival: "2022-07-19T13:48:00Z",
      latest_arrival: "2022-07-19T13:48:00Z",
      earliest_departure: "2022-07-19T18:48:26Z",
      latest_departure: "2022-07-19T18:48:26Z",
      stopReferences: [],
      stopNotes: [],
    },
  ],
  shipmentResponse: {
    shipmentId: 1500,
    shipperName: "AGX FREIGHT LOGISTICS",
    shipperId: 4,
    shipmentNumber: "agxship719",
    commodity: "",
    shipmentType: "Truckload",
    weight: 0.0,
    unitWeight: "Pounds",
    packages: [
      {
        packageType: "Palletes",
        weight: 0.0,
      },
    ],
    unitDistance: "Miles",
    distance: 764.0,
    status: "Inprogress",
    liftgate: false,
    twic: false,
    rollDoor: false,
    tarps: false,
    driverAssist: false,
    team: false,
    highValue: false,
    totalCharges: 600.0,
    createdUTC: "2022-07-19T16:41:56Z",
    carrierId: 2,
    lockedUserName: "Mohamed Abdulle",
    lockedDateTimeUTC: "2022-07-19T20:46:38Z",
    shipmentEvents: [],
    charges: [
      {
        chargeId: 1435,
        chargeType: "Line Haul",
        rating: "Flat",
        unit: 0.0,
        rate: 600.0,
        subTotal: 600.0,
      },
    ],
    documents: [
      {
        documentType: 189,
        documentUrl: "shipmentstore.dev/2f7b3094-690b-4d77-94de-727923eca2bb",
        createdUTC: "2022-07-19T16:41:49Z",
      },
    ],
    tags: [],
    shipper_details_refUrl: "/shipper/id?shipperid=80",
    driver_details_refUrl: "/shipment/Assignment?ShipmentId=2000",
  },
  payeeId: 571,
  payees: [{}],
};
