import { useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import "./AssociatedDocuments.sass";
import { deleteElement } from "services/milerApi";
import AddDocumentModal from "./AddDocumentModal";
import Loading from "components/loading/Loading";
import dateFormatter from "utils/dateFormater";
import DocDialog from "components/docDialog/docPreviewDialog";
import downloadDocument from "utils/downloadDocument";

const AssociatedDocuments = ({
  documents,
  delDoc,
  addDoc,
  enums,
  documentCategory,
  loadingDocuments,
  isDocumentOne,
  errorMessage,
  setShowDocumentError,
  isMarkTonu,
  docTitle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [docDialog, setDocDialog] = useState(false);
  const [url, setUrl] = useState(null);
  const findDocumentTypeName = (value) => {
    let docType = enums?.find(
      (item) =>
        item.keyValue?.toString() === value?.toString() ||
        item.keyName?.toString() === value?.toString()
    );
    return docType?.keyName;
  };

  const deleteDocument = (index, url) => {
    deleteElement(`/Document?documentUrl=${url}`,"","",{ ignoreToast: true }).then(() => delDoc(index));
  };

  const ShowErrorMessage = () => {
    return (
      <>
        {errorMessage ? (
          <p className="error">{errorMessage}</p>
        ) : (
          <p>There are no records to display</p>
        )}
      </>
    );
  };

  const columns = [
    {
      name: "Document Type",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="ship-document-text">
          {findDocumentTypeName(row.documentType)}
        </div>
      ),
    },
    {
      name: "DATE",
      selector: (row) => row.createdUTC,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="ship-document-text">
          {row.createdUTC || row.createdUtc
            ? dateFormatter(
                row.createdUTC || row.createdUtc,
                "MMM DD, YYYY hh:mm A"
              )
            : ""}
        </div>
      ),
    },
    {
      name: "DOWNLOAD",
      selector: (row) => row.actions,
      grow: 1,
      cell: (row) => (
        <div className="ship-document-text ship-document-text-download-ico">
          <i
            className="mil-arrow-sm-down ship-document-text-download-ico-item"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (row.documentType.toLowerCase() === "Video".toLowerCase()) {
                downloadDocument(row.documentUrl, row.documentType);
                return;
              }
              setDocDialog(true);
              setUrl(row.documentUrl);
            }}
          />
        </div>
      ),
    },
    {
      name: "ACTIONS",
      selector: (row) => row.actions,
      grow: 1,
      center: true,
      cell: (row, index) => (
        <div className="ship-document-text ship-document-text-action-ico">
          <i
            className="mil-delete-o"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => deleteDocument(index, row.documentUrl)}
          />
        </div>
      ),
    },
  ];

  return (
    <Card className={`${errorMessage && "associated-documents"}`}>
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header"></CardTitle>
        <CardTitle className="shipment-detail-header">
          <p className={`shipment-detail-title `}>
            {docTitle ? docTitle : "Documents"}
          </p>
          <div className="d-flex align-items-center">
            {isDocumentOne ? (
              <></>
            ) : (
              <button
                className="shipment-detail-down-arrow"
                onClick={() => {
                  setIsOpen(true);
                  {
                    isMarkTonu && setShowDocumentError("");
                  }
                }}
              >
                <i className="mil-plus" />
              </button>
            )}
          </div>
        </CardTitle>
        {isOpen && (
          <AddDocumentModal
            addDoc={addDoc}
            docTypes={enums}
            closeModal={() => setIsOpen(false)}
            documentCategory={documentCategory}
            isMarkTonu={isMarkTonu}
          />
        )}
        <Row style={{ margin: "0 7px" }}>
          {loadingDocuments ? (
            <div className="document-loading-spinner">
              <Loading />
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={documents}
              className="shipment"
              responsive={true}
              noDataComponent={<ShowErrorMessage />}
            />
          )}
        </Row>
      </CardBody>
      {docDialog && (
        <DocDialog
          open={docDialog}
          url={url}
          onCancel={() => setDocDialog(false)}
        />
      )}
    </Card>
  );
};

export default AssociatedDocuments;
