import InputMiler from "components/inputMiler/InputMiler";

const CarriersHeader = () => {
  return (
    <div className="summary-list-header">
      <div className="d-flex justify-content-between align-items-start my-3">
        <div className="summary-list-header__filter">
          <InputMiler
            //placeholder="Search by Carrier name"
            type="search"
            //value={searchedValue}
            //onChange={() => {}}
            narrow
          />
        </div>
      </div>
    </div>
  );
};

export default CarriersHeader;
