import { useContext, useEffect, useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import { getElementsV2, postElement } from "services/milerApi";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { ActivityContext } from "views/activities-v2/activityContext/ActivityContext";
import { appContext } from "services/AppContext";

const DeliverTrip = ({
  setDeliverTripOpen,
  stopsSkipped,
  shipmentId,
  tripId,
}) => {
  const activityContext = useContext(ActivityContext);

  const { currentTrip, getTrips, getTrip } = activityContext || {};

  const {
    enums: { DocumentTypeShipment },
  } = useContext(appContext);

  const [docList, setDocList] = useState([]);
  const [isLoading, setIsLoading] = useState(<></>);

  const getDocuments = () => {
    getElementsV2(
       {
        url: `/Shipment/${
        shipmentId || currentTrip?.shipmentResponse?.shipmentId
      }/Document`,
      setLoading: setIsLoading,
       }
    )
      .then((response) => {
        setDocList(response?.data || []);
      })
     
  };

  const addDoc = (docObject) => {
    let docs = [...docList, docObject];
    postElement(
      `/Shipment/${
        shipmentId || currentTrip.shipmentResponse?.shipmentId
      }/Document`,
      docs
    ).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const delDoc = (index) => {
    const modDoc = JSON.parse(JSON.stringify(docList));
    modDoc.splice(index, 1);
    postElement(
      `/Shipment/${
        shipmentId || currentTrip?.shipmentResponse?.shipmentId
      }/Document`,
      modDoc
    ).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const deliverTrip = () => {
    let endpoint;
    {
      endpoint = stopsSkipped
        ? postElement(
            `/shipment/trip/${
              tripId || currentTrip?.tripId
            }/complete?ignoreDispatch=true`,
            docList
          )
        : postElement(
            `shipment/trip/${tripId || currentTrip?.tripId}/delivery`,
            docList
          );
    }
    endpoint.then(() => {
      if (currentTrip) {
        getTrips();
        getTrip(currentTrip.tripId);
      }
      setDeliverTripOpen(false);
    });
  };

  useEffect(getDocuments, []);
  return (
    <div className="slider-display">
      <div className="slider-display-header">
        {stopsSkipped ? (
          <div className="slider-header-title">Complete Trip</div>
        ) : (
          <div className="slider-header-title">Deliver Trip</div>
        )}
        <div>
          <MilerButton
            title="Cancel"
            onClick={() => setDeliverTripOpen(false)}
          />
          <MilerButton title="Save" onClick={deliverTrip} btnDark />
        </div>
      </div>
      <div className="slider-display-body">
        {isLoading ? (
          isLoading
        ) : (
          <AssociatedDocuments
            documents={docList}
            addDoc={addDoc}
            delDoc={delDoc}
            enums={DocumentTypeShipment}
            documentCategory="Shipment"
          />
        )}
      </div>
    </div>
  );
};

export default DeliverTrip;
