import { useContext, useEffect, useState } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import { getElementsV2, putElement } from "services/milerApi";
import Loading from "components/loading/Loading";

const ProfileDocuments = ({
  documentUrl,
  documentCategory,
  docTitle,
  setOnBoardingLoading = () => {},
}) => {
  const {
    enums: { DocumentTypeCarrier },
  } = useContext(appContext);

  const [documents, setDocuments] = useState([]);
  const keys = [
    "MC Authority",
    "Taxpayer W9",
    "Certificate Of Insurance",
    "Factoring Assignment",
  ];
  const profileEnums = DocumentTypeCarrier.filter((item) =>
    keys.includes(item.keyName)
  );
  const [isLoading, setIsLoading] = useState(<></>);
  const addDoc = (docObject) => {
    let docs = [...documents, docObject];
    putElement(`${documentUrl}`, docs).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const delDoc = (index) => {
    let updatedDocuments = documents;
    updatedDocuments.splice(index, 1);
    putElement(`${documentUrl}`, updatedDocuments).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const getDocuments = () => {
    getElementsV2({
      url: `${documentUrl}`,
      setLoading: setIsLoading
    })
      .then((response) => {
        setDocuments(response.data || []);
        setOnBoardingLoading(false);
      })
     
  };

  useEffect(() => {
    getDocuments();
  }, [documentUrl]);

  return (
    <>
      {isLoading ? (
        <section className="mt-2">
          <Loading />
        </section>
      ) : (
        <section className="mt-3">
          <AssociatedDocuments
            documents={documents}
            delDoc={delDoc}
            addDoc={addDoc}
            enums={profileEnums}
            documentCategory={documentCategory}
            docTitle={docTitle}
          />
        </section>
      )}
    </>
  );
};

export default ProfileDocuments;
