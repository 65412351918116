import { useContext } from "react";
import InputMiler from "components/inputMiler/InputMiler";
import { appContext } from "services/AppContext";
import CountryRegionInput from "components/country-region-input/CountryRegionInput";
import MakeSelector from "./makeSelector/MakeSelector";
import findError from "utils/findError";
import dateFormatter from "utils/dateFormater";
import DateTimeInput from "components/datetime-input/DateTimeInput";

const TrailerForm = ({ onChange, state,setState, errors, yearError, onChangeYear }) => {
  const { enums } = useContext(appContext);

  const findMakeErrors = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key &&
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };
  return (
    <div className="slider-display-body">
      <div className="miler-card mt-0">
        <div className="miler-card-title">General</div>
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.trailerType}
              type="select"
              options={enums.TrailerType}
              onChange={(e) => onChange(e)}
              name="trailerType"
              label="Trailer Type"
              error={findError("TrailerType", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.unitNumber}
              type="text"
              onChange={(e) => onChange(e)}
              name="unitNumber"
              label="Unit Number"
              error={findError("UnitNumber", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            {/* <InputMiler
              value={state.make}
              type="text"
              onChange={(e) => onChange(e)}
              name="make"
              label="Make"
              error={findError("Make")}
              required
            /> */}
            {Object.keys(state).length !== 0 && (
              <MakeSelector
                state={state}
                onChange={onChange}
                findError={findMakeErrors}
              />
            )}
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.model}
              type="text"
              onChange={(e) => onChange(e)}
              name="model"
              label="Model"
              error={findError("Model", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.year}
              type="number"
              onChange={onChangeYear}
              name="year"
              label="Year"
              error={yearError ? yearError : findError("Year", errors)}
              required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.vin}
              type="Text"
              onChange={(e) => onChange(e)}
              name="vin"
              label="Vin"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state?.length}
              type="number"
              onChange={onChange}
              name="length"
              label="Equipment Length"
            />
          </div>

          <div className="col col-lg-6 col-xl-6">
            <InputMiler
              value={state.ownership}
              type="select"
              options={enums.VehicleOwnership}
              onChange={onChange}
              name="ownership"
              label="Ownership"
              error={findError("Ownership", errors)}
              // required
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state?.capacity}
              type="number"
              onChange={onChange}
              name="capacity"
              label="Capacity"
            />
          </div>
          {state?.trailerId && (
            <div className="col-12 col-lg-6 col-xl-6">
              <InputMiler
                value={state.loadStatus}
                type="select"
                options={enums.TripBasis}
                onChange={(e) => onChange(e)}
                name="loadStatus"
                label="Load Status"
              />
            </div>
          )}
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.alternativeNumber}
              type="text"
              onChange={(e) => onChange(e)}
              name="alternativeNumber"
              label="Alternative Number"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.color}
              type="text"
              onChange={(e) => onChange(e)}
              name="color"
              label="Color"
              error={findError("Color", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.plateNumber}
              type="text"
              onChange={(e) => onChange(e)}
              name="plateNumber"
              label="Plate Number"
              error={findError("PlateNumber", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <CountryRegionInput
              onChange={onChange}
              value={state.plateState}
              name="plateState"
              error={findError("PlateState", errors)}
              label="Plate State"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <DateTimeInput
              value={dateFormatter(state.plateExpiration, "YYYY-MM-DD", true)}
              type="date"
              onChange={(value) => {
                setState({ ...state, plateExpiration: value });
              }}
              name="plateExpiration"
              label="Plate Expiration"
              error={findError("PlateExpiration", errors)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <DateTimeInput
              value={dateFormatter(state.annualExpiration, "YYYY-MM-DD", true)}
              type="date"
              onChange={(value) => {
                setState({ ...state, annualExpiration: value });
              }}
              name="annualExpiration"
              label="Annual Expiration"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <DateTimeInput
              value={dateFormatter(
                state.physicalExpiration,
                "YYYY-MM-DD",
                true
              )}
              type="date"
              onChange={(value) => {
                setState({ ...state, physicalExpiration: value });
              }}
              name="physicalExpiration"
              label="Phys Dam Expiration"
            />
          </div>
          <div className="col col-lg-6 col-xl-6">
            <InputMiler
              value={state.floorType}
              type="select"
              options={enums.Material}
              onChange={onChange}
              name="floorType"
              label="Floor Type"
              error={findError("FloorType", errors)}
              // required
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              value={state.tireSize}
              type="text"
              onChange={(e) => onChange(e)}
              name="tireSize"
              label="Tire Size"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrailerForm;
