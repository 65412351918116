import { useContext, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import "./FuelSurcharge.sass";
import { appContext } from "services/AppContext";
import InputMiler from "components/inputMiler/InputMiler";
import { FuelSurChargeForm } from "./FueSurchargeForm";
import findError from "utils/findError";
import { digitsToCurrency } from "utils/currencyFormatter";
import SliderModal from "components/sliderModal/SliderModal";
import { FuelSurChargeBracketForm } from "./FuelSurchargeBracketForm";
import { getElements } from "services/milerApi";

const FuelSurcharge = ({ id, errors, data, setData, fscrefresh }) => {
  const { enums } = useContext(appContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchFuelSurcharge = async () => {
        try {
          const response = await getElements(`/Shipper/${id}/FuelSurcharge`);
          setData(response.data);
        } catch (error) {}
      };
      fetchFuelSurcharge();
    }
  }, [fscrefresh, id]);

  const handleInputChange = (field, value) => {
    if (activeIndex !== null) {
      const updatedSurcharges = data.surcharges.map((surcharge, index) =>
        index === activeIndex
          ? { ...surcharge, [field]: value === "" ? "" : parseFloat(value) }
          : surcharge
      );
      setData({ ...data, surcharges: updatedSurcharges });
    }
  };

  const saveBracket = async () => {
    if (activeIndex !== null) {
      setActiveIndex(null);
    }
  };

  const removePriceBracket = async (index) => {
    const updatedSurcharges = data.surcharges.filter((_, i) => i !== index);
    setData({ ...data, surcharges: updatedSurcharges });
    if (activeIndex === index) setActiveIndex(null);
  };

  const stopTypes = enums.StopType.filter((item) =>
    ["Pickup", "Delivery"].includes(item.keyName)
  );
  const shipmentMode = enums.ShipmentType.filter(
    (item) => item.keyName === "Truckload"
  );
  return (
    <div className="repair_order_new_repair_item_container slider-display">
      <Card
        className="mb-2"
        style={{
          borderRadius: "10px",
        }}
      >
        <CardBody>
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">FSC Program</p>
          </CardTitle>
          <Row className="align-items-center">
            <Col xs="6">
              <InputMiler
                label="FSC Name"
                type="text"
                value={data.fuelSurchargeName}
                required
                error={findError("FuelSurchargeName", errors)}
                onChange={(e) => {
                  let val = e.target.value;
                  setData((prev) => ({ ...prev, fuelSurchargeName: val }));
                }}
                autoComplete="off"
              />
            </Col>
            <Col xs="6">
              <InputMiler
                label="Shipment Type"
                type="select"
                options={shipmentMode}
                value="Truckload"
                autoComplete="off"
                disabled
              />
            </Col>
            <Col xs="6">
              <InputMiler
                label="Apply FSC On"
                type="select"
                value={data.applyOn}
                options={stopTypes}
                onChange={(e) => {
                  let val = e.target.value;
                  setData((prev) => ({ ...prev, applyOn: val }));
                }}
                autoComplete="off"
              />
            </Col>

            <Col xs="6">
              <InputMiler
                label="Use National Average"
                type="select"
                options={["Yes", "No"]}
                value={data.useNationalAverage ? "Yes" : "No"}
                onChange={(e) => {
                  let val = e.target.value;
                  setData((prev) => ({
                    ...prev,
                    useNationalAverage: val === "Yes" ? true : false,
                  }));
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card
        style={{
          borderRadius: "10px",
        }}
      >
        <CardBody>
          <CardTitle className="shipment-detail-header">
            <p className="shipment-detail-title">Surcharge</p>
          </CardTitle>

          {data?.surcharges?.length === 0 ? (
            <Row className="text-center mt-3">
              <Col>
                <p>
                  {errors.length > 0 ? (
                    <div>
                      {" "}
                      <p>
                        {" "}
                        <span className="warning-fsc-text">
                          {findError("Surcharges", errors)}.
                        </span>
                        <span
                          onClick={() => setOpenForm(true)}
                          className="clickable-fsc-link"
                        >
                          Build FSC brackets
                        </span>
                      </p>
                    </div>
                  ) : (
                    <>
                      <p>
                        No fuel surcharge available.
                        <span
                          onClick={() => setOpenForm(true)}
                          className="clickable-fsc-link"
                        >
                          Build FSC brackets
                        </span>
                      </p>
                    </>
                  )}
                </p>
              </Col>
            </Row>
          ) : (
            <div className="fsc_bdy">
              <div className="tabl">
                <Row className="justify-content-center head mb-0">
                  <Col className="text-center">
                    <p>Starting Price</p>
                  </Col>
                  <Col className="text-center">
                    <p>Ending Price</p>
                  </Col>
                  <Col className="text-center">
                    <p>Surcharge</p>
                  </Col>
                  <Col className="text-center">
                    <p>Actions</p>
                  </Col>
                </Row>

                {data?.surcharges?.map((bracket, index) => (
                  <div key={index}>
                    {activeIndex === index ? (
                      <FuelSurChargeForm
                        bracket={bracket}
                        onSave={saveBracket}
                        onCancel={() => setActiveIndex(null)}
                        errors={errors}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <Row className="fsc-item-summary">
                        <Col>{digitsToCurrency(bracket.startingPrice)}</Col>
                        <Col>{digitsToCurrency(bracket.endingPrice)}</Col>
                        <Col>{digitsToCurrency(bracket.surcharge)}</Col>
                        <Col className="action mb-0">
                          <i
                            className="mil-edit"
                            onClick={() => setActiveIndex(index)}
                            style={{ cursor: "pointer", fontSize: "16px" }}
                          />
                          <i
                            className="mil-delete-o"
                            onClick={() => removePriceBracket(index)}
                            style={{
                              color: "#FF4D4F",
                              cursor: "pointer",
                              fontSize: "16px",
                              marginLeft: "8px",
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      <SliderModal
        clickState={Boolean(openForm)}
        setClickState={setOpenForm}
        from="bottom"
        minHeight
      >
        <FuelSurChargeBracketForm setData={setData} setOpenForm={setOpenForm} />
      </SliderModal>
    </div>
  );
};

export default FuelSurcharge;
