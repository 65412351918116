import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import RepairItemSearch from "components/maintenance/searches/RepairItemSearch";
import { BsCheckLg } from "react-icons/bs";
import { postElement, putElement } from "services/serviceRepairApi";
import { toast } from "react-toastify";
import SliderModal from "components/sliderModal/SliderModal";
import NewService from "../../newServiceRepairV2/NewService";
import { digitsToCurrency } from "utils/currencyFormatter";
import NewPartSlider from "../../newServiceRepairV2/NewPartSlider";
import RepairPartSearcher from "components/maintenance/searches/RepairPartSearcher";
import CompoundInput from "components/inputMiler/compoundInput/CompoundInput";
import "./NewRepairItem.sass";

const NewRepairItem = ({
  repairItems,
  setRepairItems,
  setNewRepairOpen,
  getRepairDetails,
  fromDetails,
  selectedServiceTasks,
  repairOrder,
  repairOrderId,
}) => {
  const [newServiceOpen, setNewServiceOpen] = useState(false);
  const [newServiceCode, setNewServiceCode] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [parts, setParts] = useState([]);
  const [item, setItem] = useState("");
  const [itemId, setItemId] = useState(null);
  const [errors, setFormErrors] = useState([]);
  const [newPartOpen, setNewPartOpen] = useState(false);
  const [newItem] = useState({
    subtotal: 0,
    repairLineItems: [],
    partsCode: "s",
    partsDescription: "s",
    laborCode: "s",
    status: "Requested",
  });
  const [editIndex, setEditIndex] = useState(-1);
  const [newlabor, setNewlabor] = useState({
    type: "Labor",
    unit: "",
    rate: "",
    minutes: "00",
    subtotal: 0,
  });

  let defaultPart = {
    type: "Parts",
    unit: 1,
    partNumber: "",
    rate: 0,
    subtotal: 0,
  };
  const [editPart, setEditPart] = useState({});
  const [newPart, setNewPart] = useState(defaultPart);

  const addPart = () => {
    setParts([
      ...parts,
      {
        ...newPart,
        //subtotal: parseFloat(newPart.rate) * parseFloat(newPart.unit),
      },
    ]);
    setNewPart(defaultPart);
  };
  const editPartAction = (index) => {
    let modifiedParts = JSON.parse(JSON.stringify(parts));
    modifiedParts.splice(index, 1, editPart);
    setParts(modifiedParts);
    setEditIndex(-1);
    setEditPart({});
  };

  const addItem = () => {
    let newLaborDetail = {
      ...newlabor,
    };

    delete newLaborDetail.minutes;
    const totalhours = Number(newlabor.unit) + Number(newlabor.minutes / 60);
    const totalLabour =
      (parseFloat(totalhours) || 0) * (parseFloat(newlabor.rate) || 0);
    const partSub = parts.reduce((partialSum, a) => partialSum + a.subtotal, 0);

    if (!item) {
      toast.error("Add at least one repair item");
      return;
    }

    setRepairItems([
      ...repairItems,
      {
        ...newItem,
        laborPrice: totalLabour,
        partsPrice: partSub,
        laborHours: Number(newlabor.unit) + Number(newlabor.minutes / 60),
        totalCharges: totalLabour + partSub,
        serviceCode: item,
        serviceId: itemId,
        serviceTaskItemId: selectedServiceTasks?.selectedServiceTasks || "",
        repairLineItems: [
          {
            ...newLaborDetail,
            subtotal: totalLabour,
            unit: totalhours,
          },
          ...parts,
        ],
      },
    ]);
    setNewRepairOpen(false);
  };

  const addItemExist = () => {
    const totalhours = Number(newlabor.unit) + Number(newlabor.minutes / 60);
    const totalLabour =
      (parseFloat(totalhours) || 0) * (parseFloat(newlabor.rate) || 0);
    const partSub = parts.reduce((partialSum, a) => partialSum + a.subtotal, 0);
    const totalCharges =
      (parseFloat(totalLabour) || 0) + (parseFloat(partSub) || 0);

    if (!item) {
      toast.error("Add at least one repair item");
      return;
    }

    const repItm = {
      ...newItem,
      laborPrice: totalLabour,
      partsPrice: partSub,
      laborHours: Number(newlabor.unit) + Number(newlabor.minutes / 60),

      totalCharges: totalCharges,
      serviceCode: item,
      repairLineItems: [
        {
          ...newlabor,
          subtotal: newlabor.unit * newlabor.rate,
          unit: totalhours,
        },
        ...parts,
      ],
    };

    putElement(`/RepairOrder/${repairOrderId}`, repairOrder, "", {
      ignoreToast: true,
    }).then((response) => {
      if (response.success) {
        postElement(`/RepairOrder/${repairOrderId}/RepairItem`, repItm).then(
          (res) => {
            if (res.success) {
              setNewRepairOpen(false);
              getRepairDetails();
            } else if (res.vErrors) {
              setFormErrors(res.vErrors);
            }
          }
        );
      } else if (response.vErrors) {
      }
    });
  };

  const onSearchPartSelect = (result, _part, _setPart) => {
    let subtotal = 0;
    if (result.sellingPrice || _part.unit) {
      subtotal = Number(
        (
          (parseFloat(result.sellingPrice) || 0) * (parseFloat(_part.unit) || 0)
        ).toFixed(2)
      );
    }
    _setPart({
      ..._part,
      partNumber: result.partNumber,
      partId: result.partId || null,
      rate: result.sellingPrice,
      partDescription: result.partDescription,
      subtotal,
    });
  };

  const onPartChange = (e, _part, _setPart) => {
    const { value, name } = e.target;
    let rate = name === "rate" ? value : _part.rate;
    let unit = name === "unit" ? value : _part.unit;
    let subtotal = _part.subtotal || 0;
    if (rate || unit) {
      subtotal = Number(
        ((parseFloat(rate) || 0) * (parseFloat(unit) || 0)).toFixed(2)
      );
    }
    _setPart({
      ..._part,
      subtotal,
      [name]: value,
    });
  };

  const onCreatePart = (result) => {
    openNew
      ? onSearchPartSelect(result, newPart, setNewPart)
      : onSearchPartSelect(result, editPart, setEditPart);
  };

  const deletePart = (i) => {
    const modParts = parts.filter((r, index) => index !== i);
    setParts(modParts);
  };

  const createSearchItem = (item) => {
    setItem(item.code);
    setNewServiceCode(item.code);
  };

  const onNewServiceCreated = (newService) => {
    setItem(newService.code);
    setShowSaveBtn(false);
  };

  return (
    <div className="repair_order_new_repair_item_container slider-display">
      <div className="head slider-display-header">
        <div className="title ml-3 mt-4">New Repair Item</div>
        <div className="btns mt-2 d-flex ml-auto">
          <MilerButton title="Cancel" onClick={() => setNewRepairOpen(false)} />
          <MilerButton
            title={fromDetails ? "Save" : "Apply"}
            btnDark
            onClick={() => {
              if (openNew || editIndex >= 0) {
                toast.error(
                  <div>
                    You have unsaved PART. Please add (
                    <i
                      className="mil-check"
                      style={{
                        color: "#48BF84",
                        fontSize: "1.5rem",
                        verticalAlign: "middle",
                      }}
                    />
                    ) or clear (
                    <i
                      className="mil-close-bg"
                      style={{
                        color: "red",
                        fontSize: "1.5rem",
                        verticalAlign: "middle",
                      }}
                    />
                    ) it
                  </div>
                );
              } else {
                fromDetails ? addItemExist() : addItem();
              }
            }}
          />
        </div>
      </div>
      <div className="body">
        <div className="car">
          <div className="title">Item Details</div>
          <div className="itm_details_bdy">
            <RepairItemSearch
              assignValue={item || ""}
              setItem={setItem}
              setItemId={setItemId}
              error={errors}
              createItem={createSearchItem}
              showSaveBtn={showSaveBtn}
              setShowSaveBtn={setShowSaveBtn}
              setNewServiceOpen={setNewServiceOpen}
            />
          </div>
        </div>
        {itemId && (
          <>
            <div className="car">
              <div className="title">Labor</div>
              <div className="row">
                <div className="col-12 col-lg-4 col-xl-4">
                  <CompoundInput
                    type="number"
                    inputValue={newlabor.unit}
                    selectDisabled
                    selectValue={newlabor.unit}
                    inputOnchange={(e) => {
                      if (
                        Number(e.target.value) >= 0 &&
                        Number(e.target.value) <= 24
                      ) {
                        setNewlabor({ ...newlabor, unit: e.target.value });
                      }
                    }}
                    optionValue="Hour(s)"
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-4">
                  <CompoundInput
                    type="select"
                    inputOptions={["00", "15", "30", "45"]}
                    inputValue={newlabor.minutes}
                    selectValue={newlabor.minutes}
                    inputOnchange={(e) => {
                      setNewlabor({ ...newlabor, minutes: e.target.value });
                    }}
                    optionValue="Minute(s)"
                    selectDisabled
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-4">
                  <CompoundInput
                    type="number"
                    inputValue={newlabor.rate}
                    selectDisabled
                    selectValue={newlabor.rate}
                    inputOnchange={(e) => {
                      if (e.target.value.length <= 6) {
                        setNewlabor({ ...newlabor, rate: e.target.value });
                      }
                    }}
                    optionValue="Hourly Rate"
                  />
                </div>
              </div>
            </div>

            <div className="car">
              <div className="parts_title">
                <div className="tit">Parts</div>
                <i
                  className="mil-plus ico clickable"
                  onClick={() => {
                    setEditIndex(-1);
                    setOpenNew(true);
                  }}
                />
              </div>
              <div className="parts_bdy">
                <div className="tabl">
                  {
                    // no parts component
                    !openNew && parts.length === 0 ? (
                      <div className="no_parts">No Parts Added</div>
                    ) : null
                  }
                  {
                    //table head
                    openNew || parts.length ? (
                      <div className="head">
                        <div className="edit_parts">Part #</div>
                        <section>
                          <div className="edit_no">QTY</div>
                          <div className="edit_quantity">Rate</div>
                          <div className="tot">Total</div>
                          <div className="action">Action</div>
                        </section>
                      </div>
                    ) : null
                  }
                  {
                    //table body
                    parts.length
                      ? parts.map((part, index) => (
                          <div className="bdy" key={index}>
                            {index === editIndex ? (
                              <div className="cell new-part-cell">
                                <div className="parts">
                                  <RepairPartSearcher
                                    onItemSelect={(result) =>
                                      onSearchPartSelect(
                                        result,
                                        editPart,
                                        setEditPart
                                      )
                                    }
                                    setNewPartOpen={setNewPartOpen}
                                    value={editPart.partNumber || ""}
                                  />
                                </div>
                                <section>
                                  <div className="no">
                                    <InputMiler
                                      value={editPart.unit}
                                      type="number"
                                      name="unit"
                                      onChange={(e) =>
                                        onPartChange(e, editPart, setEditPart)
                                      }
                                    />
                                  </div>
                                  <div className="quantity">
                                    <InputMiler
                                      value={editPart.rate}
                                      type="number"
                                      name="rate"
                                      onChange={(e) =>
                                        onPartChange(e, editPart, setEditPart)
                                      }
                                    />
                                  </div>
                                  <div className="tot">
                                    <p>{digitsToCurrency(editPart.subtotal)}</p>
                                  </div>
                                  <div className="action">
                                    <BsCheckLg
                                      className="check"
                                      onClick={() => {
                                        editPartAction(index);
                                      }}
                                    />
                                    <i
                                      className="mil-close-bg ex"
                                      onClick={() => {
                                        setEditIndex(-1);
                                        setEditPart({});
                                      }}
                                    />
                                  </div>
                                </section>
                              </div>
                            ) : (
                              <div className="cell" key={index}>
                                <div className="parts">{part.partNumber}</div>
                                <section>
                                  <div className="no">{part.unit}</div>
                                  <div className="quantity">
                                    {digitsToCurrency(part.rate || 0)}
                                  </div>
                                  <div className="tot">
                                    {digitsToCurrency(
                                      Number(part.subtotal).toFixed(2) || 0
                                    )}
                                  </div>
                                  <div className="action">
                                    {
                                      <i
                                        className="mil-edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setEditIndex(index);
                                          setOpenNew(false);
                                          setEditPart(part);
                                        }}
                                      />
                                    }
                                    <i
                                      className="mil-delete-o"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => deletePart(index)}
                                    />
                                  </div>
                                </section>
                              </div>
                            )}
                          </div>
                        ))
                      : null
                  }
                  {
                    //add new part component
                    openNew ? (
                      <div className="bdy">
                        <div className="cell new-part-cell">
                          <div className="parts">
                            <RepairPartSearcher
                              onItemSelect={(result) =>
                                onSearchPartSelect(result, newPart, setNewPart)
                              }
                              setNewPartOpen={setNewPartOpen}
                              value={newPart.partNumber || ""}
                            />
                          </div>
                          <section>
                            <div className="no">
                              <InputMiler
                                value={newPart.unit}
                                type="number"
                                name="unit"
                                onChange={(e) =>
                                  onPartChange(e, newPart, setNewPart)
                                }
                              />
                            </div>
                            <div className="quantity">
                              <InputMiler
                                value={newPart.rate}
                                type="number"
                                name="rate"
                                onChange={(e) =>
                                  onPartChange(e, newPart, setNewPart)
                                }
                              />
                            </div>
                            <div className="tot">
                              <p>{digitsToCurrency(newPart.subtotal)}</p>
                            </div>
                            <div className="action">
                              <BsCheckLg
                                className="check"
                                onClick={() => {
                                  addPart();
                                  setOpenNew(false);
                                }}
                              />
                              <i
                                className="mil-close-bg ex"
                                onClick={() => {
                                  setOpenNew(false);
                                  setNewPart(defaultPart);
                                }}
                              />
                            </div>
                          </section>
                        </div>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <SliderModal
        clickState={newServiceOpen}
        setClickState={() => {}}
        from="bottom"
        height={50}
      >
        <NewService
          setNewServiceOpen={setNewServiceOpen}
          newServiceCode={newServiceCode}
          onItemCreated={onNewServiceCreated}
          setItemId={setItemId}
        />
      </SliderModal>
      <SliderModal
        clickState={newPartOpen}
        setClickState={() => {}}
        from="bottom"
        height={50}
      >
        <NewPartSlider
          setNewPartOpen={setNewPartOpen}
          newPartOpen={newPartOpen}
          onCreatePart={onCreatePart}
        />
      </SliderModal>
    </div>
  );
};

export default NewRepairItem;
