import BorderedText from "components/bordered-text/BorderedText";
import { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import "views/accounting/invoices_v2/invoicesDisplay/approvedComponent/ApprovedComponent.sass";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import RecordPayment from "views/accounting/invoices_v2/invoicesDisplay/approvedComponent/RecordPayment";
import SliderModal from "components/sliderModal/SliderModal";
import ShipmentDetailsWrapper from "views/shipments/shipmentDetails/ShipmentDetailsWrapper";
import { digitsToCurrency } from "utils/currencyFormatter";
import dateFormatter from "utils/dateFormater";
import { putElement } from "services/milerApi";
import { BsExclamationTriangle } from "react-icons/bs";
import { CarrierChargeContext } from "../../carrierChargeContext/CarrierChargeContext";
import { columns } from "./carrierData";
import CellBuilder from "components/cellBuilderTable/CellBuilder";
import DocDialog from "components/docDialog/docPreviewDialog";

const CarrierDisplay = ({ carrierCharges }) => {
  const [openPayment, setOpenPayment] = useState(false);
  const [shipId, setShipId] = useState();
  const [shipmentContent, setShipmentContent] = useState(false);
  const getShipments = () => {};
  const [docDialog, setDocDialog] = useState(false);
  const [url, setUrl] = useState(null);

  const { state, dispatch } = useContext(CarrierChargeContext);
  const pCarrierCharges = carrierCharges.charges ? carrierCharges.charges : [];

  const reprocessInvoice = (shipId) => {
    putElement(`/Shipment/${shipId}​/Rebill`).then(() => {
      dispatch({ type: "carrierCharges", payload: res.data });
      dispatch({
        type: "setCurrent",
        payload: res.data.find((a) => a.shipperId === state.current.shipperId),
      });
    });
  };
  let carrierChargesList = pCarrierCharges
    ? pCarrierCharges.map((data) => {
        let paidCurr = data.payment ? data.payment : 0;
        let amountCurr = data.amount ? data.amount : 0;
        let balanceCurr = amountCurr - paidCurr;
        return {
          invoiceNumber: CellBuilder(data.invoiceNumber),
          trip: CellBuilder(
            data.shipmentNumber,
            dateFormatter(data.invoiceDateUTC, "MMM DD, YYYY")
          ),
          shipment: CellBuilder(
            data.shipmentNumber,
            dateFormatter(data.invoiceDateUTC, "MMM DD, YYYY")
          ),
          pickUp: CellBuilder(
            data.pickupAddress.addressLine && data.pickupAddress.addressLine,
            data.pickupAddress.city &&
              `${data.pickupAddress.city}, ${data.pickupAddress.state}`
          ),
          dropOff: CellBuilder(
            data.deliveryAddress.addressLine &&
              data.deliveryAddress.addressLine,
            data.deliveryAddress.city &&
              `${data.deliveryAddress.city}, ${data.deliveryAddress.state}`
          ),
          amount: (
            <div>
              <div className="settlement_revenue_row">
                {digitsToCurrency(data.amount)}
              </div>
            </div>
          ),
          charges: (
            <div>
              <div className="settlement_revenue_row">
                {digitsToCurrency(data.charge)}
              </div>
            </div>
          ),
          payment: CellBuilder(
            data.payment && digitsToCurrency(data.payment),
            `${dateFormatter(data.paymentUtc, "MMM DD, YYYY")}`,
            "blue"
          ),
          balance: CellBuilder(digitsToCurrency(balanceCurr), "", "blue"),
          status:
            data.status === "Failure" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BorderedText text={data.status} classes={data.status} />
                <BsExclamationTriangle
                  style={{
                    color: "#FA4747",
                    width: "16px",
                    height: "16px",
                    marginLeft: "5px",
                  }}
                />
              </div>
            ) : (
              <BorderedText text={data.status} classes={data.status} />
            ),
          actions: (
            <div className="table-actions">
              <i
                className="mil-edit-o"
                onClick={() => {
                  setShipId(data.shipmentId);
                  setShipmentContent(true);
                }}
                style={{ cursor: "pointer" }}
              />
              {data.invoiceUrl && (
                <i
                  className="mil-arrow-sm-down"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setUrl(data.invoiceUrl);
                    setDocDialog(true);
                  }}
                />
              )}
              <ElipsisMenu>
                <MenuItem
                  title="Record Payment"
                  onClick={() =>
                    setOpenPayment({ id: data.invoiceId, amount: data.amount })
                  }
                />
                <MenuItem
                  title="Reprocess"
                  onClick={() => reprocessInvoice(data.shipmentId)}
                />
              </ElipsisMenu>
            </div>
          ),
        };
      })
    : [];

  return (
    <div className="settlement-approved-component">
      <div className="summary-list">
        <DataTable
          columns={columns}
          data={carrierChargesList}
          responsive={true}
          pagination
        />
      </div>
      {openPayment && (
        <RecordPayment
          invoiceInfo={openPayment}
          onToggle={() => setOpenPayment(!openPayment)}
        />
      )}
      <SliderModal
        clickState={shipmentContent}
        setClickState={setShipmentContent}
      >
        <ShipmentDetailsWrapper
          getShipments={getShipments}
          setContent={setShipmentContent}
          shipmentId={shipId}
        />
      </SliderModal>
      <DocDialog
        open={docDialog}
        url={url}
        onCancel={() => setDocDialog(false)}
      />
    </div>
  );
};

export default CarrierDisplay;
