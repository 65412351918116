import { useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";

import DataTable from "react-data-table-component";
import dateFormatter from "utils/dateFormater";

import "./AssociatedDocuments.sass";
import { deleteElement, postElement } from "services/milerApi";
import AddDocumentModal from "./AddDocumentModal";
import DocDialog from "components/docDialog/docPreviewDialog";

const AssociatedDocuments = ({ documents, delDoc, addDoc, yellowTitle }) => {
  const [docDialog, setDocDialog] = useState(false);
  const [url, setUrl] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  // const downloadDocument = (data) => {
  //   const url = data.documentUrl || data.documentURL;
  //   axios({
  //     url: `https://dev-carrier.miler.com/Document/Download?documentUrl=${url}`,
  //     method: "GET",
  //     responseType: "blob", // important
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //       "X-Miler-UserId": userId,
  //       "X-Miler-CarrierId": carrierId,
  //       version: "2.0",
  //     },
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "file.pdf");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  //   // getElements(`/Document/Download?documentUrl=${url}`).then((response) => {

  //   // });
  // };

  const findDocumentType = (value, type) => {
    let documentDictionary = [{ num: 255, str: "Image" }];
    let toReturn =
      type === "str" ? documentDictionary[0].num : documentDictionary[0].str;
    return toReturn;
  };

  const deleteDocument = (index, url) => {
    deleteElement(`/Document?documentUrl=${url}`).then(() => delDoc(index));
  };

  const addDocument = (document) => {
    postElement("/Document?DocumentCategory=Maintenance", document.file, "", {
      ignoreToast: true,
    }).then((response) => {
      response.response &&
        addDoc({
          documentType: findDocumentType(document.documentType, "str"),
          documentUrl: response.response.data,
        });
    });
  };

  const columns = [
    {
      name: "FILE TYPE",
      selector: (row) => row.documentType,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="ship-document-text">
          {findDocumentType(row.documentType, "num")}
        </div>
      ),
    },
    {
      name: "DATE",
      selector: (row) => row.createdUTC,
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="ship-document-text">
          {dateFormatter(row.createdUTC, "MMM DD, YYYY")}
        </div>
      ),
    },
    {
      name: "DOWNLOAD",
      selector: (row) => row.actions,
      grow: 1,
      cell: (row) => (
        <div className="ship-document-text ship-document-text-download-ico">
          <i
            className="mil-arrow-sm-down ship-document-text-download-ico-item"
            onClick={() => {
              setUrl(row);
              setDocDialog(true);
            }}
          />
        </div>
      ),
    },
    {
      name: "ACTIONS",
      selector: (row) => row.actions,
      grow: 1,
      cell: (row, index) => (
        <div className="ship-document-text ship-document-text-action-ico">
          <i
            className="mil-delete-o"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => deleteDocument(index, row.documentUrl)}
          />
          <i className="mil-message-o" />
        </div>
      ),
    },
  ];

  return (
    <Card>
      <CardBody className="shipment-detail-card">
        <CardTitle className="shipment-detail-header"></CardTitle>
        <CardTitle className="shipment-detail-header">
          <p className={`shipment-detail-title ${yellowTitle && "yellow"}`}>
            Documents
          </p>
          <div className="d-flex align-items-center">
            <button
              className="shipment-detail-down-arrow"
              onClick={() => setIsOpen(true)}
            >
              <i className="mil-plus" />
            </button>
          </div>
        </CardTitle>
        <AddDocumentModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          addDocument={addDocument}
        />
        <Row style={{ margin: "0 7px" }}>
          <DataTable
            columns={columns}
            data={documents}
            className="shipment"
            responsive={true}
          />
        </Row>
      </CardBody>
      <DocDialog
        open={docDialog}
        url={url}
        onCancel={() => setDocDialog(false)}
      />
    </Card>
  );
};

export default AssociatedDocuments;
