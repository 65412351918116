import { DirectionsRenderer } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import "./Map.sass";
import TripMarkers from "./TripMarkers";
const TripLine = ({ tripStops, onLoad }) => {
  if (!tripStops?.length) {
    return <></>;
  }

  const [direction, setDirection] = useState();

  const setupOrigin = () => {
    const { latitude: lat, longitude: lng, fullAddress } =
      tripStops[0]?.address || {};
    let address;
    let addressInfo;
    if (lat && lng) {
      address = { lat, lng };
    } else {
      address = fullAddress || "";
    }
    addressInfo = {
      lat: lat || "",
      lng: lng || "",
      fullAddress,
      stopType: tripStops[0]?.stopType,
    };
    return { address, addressInfo };
  };
  const setupDestination = () => {
    const { latitude: lat, longitude: lng, fullAddress } =
      tripStops[tripStops.length - 1]?.address || {};
    let address;
    let addressInfo;
    if (lat && lng) {
      address = { lat, lng };
    } else {
      address = fullAddress || "";
    }
    addressInfo = {
      lat: lat || "",
      lng: lng || "",
      fullAddress,
      stopType: tripStops[tripStops.length - 1]?.stopType,
    };
    return { address, addressInfo };
  };
  const setupWayPoints = (locationOnly) => {
    return ([...tripStops].slice(1, -1) || []).map((point) => {
      const { latitude: lat, longitude: lng, fullAddress } =
        point?.address || {};
      let location;
      let addressInfo;
      if (lat && lng) {
        location = { lat, lng, stopover: true };
      } else {
        location = fullAddress || "";
      }
      addressInfo = {
        lat: lat || "",
        lng: lng || "",
        fullAddress,
        stopType: point?.stopType,
      };
      return locationOnly ? { location } : addressInfo;
    });
  };

  const directionService = new google.maps.DirectionsService();

  useEffect(() => {
    directionService.route(
      {
        origin: setupOrigin().address,
        destination: setupDestination().address,
        waypoints: setupWayPoints(true),
        travelMode: google.maps.TravelMode.DRIVING,
        optimizeWaypoints: true,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          setDirection(result);
        }
      }
    );
  }, [tripStops]);

  return (
    <>
      <DirectionsRenderer
        directions={direction}
        options={{
          polylineOptions: {
            zIndex: 50,
            strokeColor: "#202F53",
            strokeWeight: 4,
          },
          suppressMarkers: true,
        }}
        onLoad={() => {
          if (onLoad) {
            onLoad();
          }
        }}
      />
      <TripMarkers position={setupOrigin().addressInfo} />
      <TripMarkers position={setupDestination().addressInfo} />
      {setupWayPoints().map((point, index) => (
        <TripMarkers position={point} key={index} />
      ))}
    </>
  );
};

export default TripLine;
