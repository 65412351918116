import PayeeForm from "components/payee-form/PayeeForm";
import { useEffect } from "react";
import "./Payee.sass";

const Payee = ({ getPayee, payee, setPayee, payeeErrors }) => {
  useEffect(getPayee, []);

  return (
    <div className="slider-display-body">
      <>
        <PayeeForm payee={payee} setPayee={setPayee} errors={payeeErrors} />
      </>
    </div>
  );
};

export default Payee;
