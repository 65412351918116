import dateFormatter from "./dateFormater";
import dayjs from "dayjs";

export const stringSorter = (selectedColumn, direction, rows) => {
  //case Insensitive Sorter
  rows.sort((row1, row2) => {
    const minus = direction === "asc" ? -1 : 1;
    const plus = direction === "asc" ? 1 : -1;
    const value1 = selectedColumn.selector(row1)
      ? String(selectedColumn.selector(row1)).toLowerCase()
      : "";
    const value2 = selectedColumn.selector(row2)
      ? String(selectedColumn.selector(row2)).toLowerCase()
      : "";
    if (value1 && value2) {
      // both are present
      if (value1 > value2) {
        return plus;
      }
      if (value2 > value1) {
        return minus;
      }
      return 0;
    } else if (!value1 && value2) {
      return 1;
    } else if (!value2 && value1) {
      return -1;
    } else {
      return 0;
    }
  });

  return rows;
};

export const dateSorter = (selectedColumn, direction, rows) => {
  rows.sort((row1, row2) => {
    const minus = direction === "asc" ? -1 : 1;
    const plus = direction === "asc" ? 1 : -1;
    const value1 = dateFormatter(
      selectedColumn.selector(row1),
      "YYYY-MM-DD HH:mm"
    );
    const value2 = dateFormatter(
      selectedColumn.selector(row2),
      "YYYY-MM-DD HH:mm"
    );
    if (value1 && value2) {
      // both are present
      const diff = dayjs(value1).diff(dayjs(value2), "seconds", true);
      if (diff > 1) {
        return plus;
      }
      if (diff < 1) {
        return minus;
      }
      return 0;
    } else if (!value1 && value2) {
      return 1;
    } else if (!value2 && value1) {
      return -1;
    } else {
      return 0;
    }
  });

  return rows;
};

export const numericSorter = (selectedColumn, direction, rows) => {
  rows.sort((row1, row2) => {
    const minus = direction === "asc" ? -1 : 1;
    const plus = direction === "asc" ? 1 : -1;
    const value1 = selectedColumn.selector(row1);
    const value2 = selectedColumn.selector(row2);
    if (value1 > value2) {
      return plus;
    }
    if (value2 > value1) {
      return minus;
    }
    return 0;
    // if (a !== 0 && b === 0) return -1;
    // if (a === 0 && b !== 0) return 1;
    // return 0;
  });
  return rows;
};
