
import getStatusColor from "utils/getStatusColor";
const getShipmentStatusColor = (status) =>{
    return getStatusColor(
        [
          "Available",
          "Assigned",
          "Inprogress",
          "Billed",
          "Billing",
          "Paid",
          "Delivered",
          "Hold",
          "Accepted",
          "Void",
          "Tender"
        ],
        [
          "status_miler_blue",
          "status_dark_violet",
          "status_cyan",
          "status_gold_tips",
          "status_gold_tips",
          "status_green1",
          "status_green1",
          "status_red",
          "status_dark_violet",
          "status_red",
          "status_light_green"
        ],
        status
      );
}

export default getShipmentStatusColor;