import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { getElements } from "services/milerApi";
import SliderModal from "components/sliderModal/SliderModal";
import NewFleet from "../newFleet/NewFleet";
const FleetSearchInput = ({
  error,
  _selectedFleet,
  setSelectedFleet,
  onChangeFleet,
  fleets,
  setFleets,
  value,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const onChange = (foundFleet) => {
    if (foundFleet) {
      setSelectedFleet({
        ..._selectedFleet,
        fleetName: foundFleet.label,
        fleetId: foundFleet.fleetId,
      });
      onChangeFleet(foundFleet.fleetId);
    } else {
      // clear input & selected value
      let newValue = {};
      setSelectedFleet(newValue);
    }
  };

  const onInputChange = (value) => {
    value;
  };

  const getFleets = async () => {
    return getElements(`Equipment/Fleet`).then((response) => {
      setFleets(
        (response.data || []).map((p) => ({
          ...p,
          label: p.fleetName || "",
        }))
      );
      return response;
    });
  };



  const [fleet, setFleet] = useState({
    fleetManager: "",
    fleetName: "",
  });
  useEffect(() => {
    getFleets().then((response) => {
      setSelectedFleet({
        ..._selectedFleet,
        fleetName: response.data.find(
          (el) => el.fleetId === _selectedFleet.fleetId
        )?.fleetName,
      });

    });
  }, []);

  return (
    <div>
      <label className="m-0">Fleet Name</label>
      {/* <span className="required-red">*</span> */}
      <CreatableSelect
        isClearable={true}
        onChange={onChange}
        placeholder="Search/Create Fleet"
        onInputChange={onInputChange}
        onCreateOption={(e) => {
          setOpenForm(true);
          setFleet((prev)=>({...prev, fleetName: e}))
        }}
        value={value}
        options={fleets.map((el) => {
          return { label: el.fleetName, fleetId: el.fleetId };
        })}
        styles={
          error && {
            control: (provided) => ({
              ...provided,
              borderColor: "red",
            }),
          }
        }
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <SliderModal
        clickState={openForm}
        setClickState={setOpenForm}
        from="bottom"
        height={50}
      >
        <NewFleet
          setOpenForm={setOpenForm}
          setSelectedFleet={setSelectedFleet}
          getFleets={getFleets}
          fleet ={fleet} 
          setFleet ={setFleet}
        />
      </SliderModal>
    </div>
  );
};

export default FleetSearchInput;
