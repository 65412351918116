import MilerButton from "components/milerButton/MilerButton";
import { useEffect, useState } from "react";
import { putElement, deleteElement } from "services/serviceRepairApi";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ServiceRepairForm from "../serviceRepairForm/ServiceRepairForm";
import { getElements, postElement } from "services/serviceRepairApi";
import BorderedText from "components/bordered-text/BorderedText";
import classnames from "classnames";
import Notes from "./Notes";
import Documents from "./Documents";
import Loading from "components/loading/Loading";
import joinStrings from "utils/joinStrings";
import { digitsToCurrency } from "utils/currencyFormatter";
import OverViewTab from "./OverviewTab";
import RepairOrderRecentHistory from "./RecentHistory";
import SelectableButton from "components/selectableButton/SelectableButton";
import ServiceTasks from "../serviceTasks/serviceTasks";
import "./ServiceRepairDetails.sass";
import SliderModal from "components/sliderModal/SliderModal";

const ServiceRepairDetails = ({
  setServiceRepairDetailsOpen,
  getRepairs,
  id,
  status,
  repairOrderDocuments,
  setRepairOrderDocuments,
  setCurrentStatus,
  vehicle,
  setVehicle,
}) => {
  const [errors, setFormErrors] = useState([]);
  const [repairItems, setRepairItems] = useState([]);
  const [initialVehicle, setInitialVehicle] = useState("");
  const [newRepairOpen, setNewRepairOpen] = useState(false);
  const [editRepairOpen, setEditRepairOpen] = useState(false);

  const [vehicleStatus, setVehicleStatus] = useState({
    issuedBy: "",
    openedUTC: "",
    closedUTC: "",
    serviceAdvisorName: "",
    requestedUTC: "",
    promisedUTC: "",
  });
  const [activeTab, setActiveTab] = useState("1");

  // function formatDate(d) {
  //   let date = new Date(d);
  //   var dd = date.getDate();
  //   var mm = date.getMonth() + 1;
  //   var yyyy = date.getFullYear();
  //   if (dd < 10) {
  //     dd = "0" + dd;
  //   }
  //   if (mm < 10) {
  //     mm = "0" + mm;
  //   }
  //   return (d = yyyy + "-" + mm + "-" + dd);
  // }
  const findError = (inputError) => {
    let error = errors.find(
      (responseError) =>
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  };

  const [note, setNote] = useState("");
  const [repairDate, setRepairDate] = useState("");
  const [unitType, setUnitType] = useState("Tractor");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalLabourPrice, setTotalLabourPrice] = useState(0);
  const [totalPartsPrice, setTotalPartsPrice] = useState(0);
  const [poNumber, setPoNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [promisedUTC, setPromiseUtc] = useState("");
  const [repairOrderStatus, setRepairOrderStatus] = useState("");
  const [serviceProvider, setServiceProvider] = useState({});
  const [odometer, setOdometer] = useState("");
  const [loading, setLoading] = useState();

  const [serviceTasks, setServiceTasks] = useState([]);
  const [selectedServiceTasks, setSelectedServiceTasks] = useState([]);
  const [openServiceTasksSlider, setOpenServiceTasksSlider] = useState(false);

  const getRepairDetails = () => {
    setLoading(true);
    getElements(`/RepairOrder/${id}`)
      .then((res) => {
        const { data } = res;
        setCurrentStatus(data.repairOrderStatus);
        setServiceProvider({
          name: data.serviceProviderName,
          label: data.serviceProviderName,
          serviceProviderId: data.serviceProviderId,
        });
        setNote(data.note);
        setPoNumber(data.poNumber);
        setRepairDate(data.requestedUTC);
        setPromiseUtc(data.promisedUTC);
        setInvoiceNumber(data.invoiceNumber);
        setTotalPrice(data.totalCharges);
        setTotalLabourPrice(data.partsTotal);
        setTotalPartsPrice(data.laborTotal);
        setRepairItems([...data.repairItems]);
        setRepairOrderStatus(data.repairOrderStatus);
        setVehicle({
          unitNumber: data?.unitNumber,
          unitType: data?.unitType,
          make: data?.make,
          model: data?.model,
          year: data?.year,
          plate: data?.plate,
          totalCharges: data?.totalCharges,
          unitId: Number(data?.unitNumber),
        });
        setVehicleStatus({
          ...data,
        });
        setInitialVehicle(
          `${data.unitNumber} ${data.unitType} ${data.make} ${data.model} ${data.year}`
        );
        setOdometer(data.odometer);
        setSelectedServiceTasks(data.serviceTasks || []);

        setRepairOrderDocuments(data.documentResponses || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const isoStr = repairDate ? new Date(repairDate).toISOString() : "";
  const pt = repairItems.reduce((acc, current) => acc + current.partsPrice, 0);
  const lt = repairItems.reduce((acc, current) => acc + current.laborPrice, 0);
  const handleSubmit = async () => {
    let repairOrder = {
      ...vehicle,
      odometer: odometer,
      locationType: "Roadway",
      repairPriorityType: "High",
      requestedUTC: isoStr,
      schedualedUTC: "",
      promisedUTC: promisedUTC,
      openedUTC: vehicleStatus?.openedUTC,
      closedUTC: vehicleStatus?.closedUTC,
      repairOrderStatus: repairOrderStatus,
      documents: repairOrderDocuments,
      serviceProviderId: serviceProvider.serviceProviderId,
      note,
      poNumber,
      invoiceNumber: invoiceNumber,
      partsTotal: pt,
      laborTotal: lt,
      repairItems: [...repairItems],
    };
    putElement(`/RepairOrder/${id}`, repairOrder).then((response) => {
      if (response.success) {
        setServiceRepairDetailsOpen(false);
        setRepairOrderDocuments([]);

        getRepairs();
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const closeRO = () => {
    postElement(`/RepairOrder/${id}/Close`).then((response) => {
      if (response.success) {
        getRepairDetails();
        getRepairs();
      }
    });
  };
  const openRO = () => {
    postElement(`/RepairOrder/${id}/Open`).then((response) => {
      if (response.success) {
        getRepairDetails();
        getRepairs();
      }
    });
  };

  const deleteRepairOrder = () => {
    deleteElement(`/RepairOrder/${id}`).then((response) => {
      if (response.success) {
        getRepairDetails();
        getRepairs();
      }
    });
  };

  const getServiceTasks = (id) => {
    const unitType = vehicle?.unitType.toLowerCase();

    const serviceTaskUrl =
      unitType === "trailer"
        ? `/Equipment/Trailer/${id}/ServiceTask?status=New`
        : unitType === "tractor"
        ? `/Equipment/Vehicle/${id}/ServiceTask?status=New`
        : null;

    if (serviceTaskUrl && id) {
      getElements(serviceTaskUrl).then((response) => {
        if (response.status === 200) {
          setServiceTasks(response?.data?.data || []);
        }
      });
    }
  };

  useEffect(() => {
    if (vehicle.unitId) {
      getServiceTasks(vehicle?.unitId);
    }
  }, [vehicle?.unitId]);

  useEffect(() => {
    getRepairDetails();
  }, []);

  const editRepairOrderDetailsScreenPayload = {
    ...vehicle,
    odometer: odometer,
    locationType: "Roadway",
    repairPriorityType: "High",
    requestedUTC: isoStr,
    schedualedUTC: "",
    promisedUTC: promisedUTC,
    openedUTC: vehicleStatus?.openedUTC,
    closedUTC: vehicleStatus?.closedUTC,
    repairOrderStatus: repairOrderStatus,
    documents: repairOrderDocuments,
    serviceProviderId: serviceProvider.serviceProviderId,
    note,
    poNumber,
    invoiceNumber: invoiceNumber,
    partsTotal: pt,
    laborTotal: lt,
    repairItems: [...repairItems],
  };

  return (
    <main>
      <div className="slider-display vehicle-details">
        <div className="slider-display-header">
          <div className="vehicle-details-header">
            <div className="vehicle-details-info">
              <div>
                <div className="vhe-dtls__vehicle-number-status">
                  <h3 className="vhe-dtls__vehicle-number">RO# {id}</h3>
                  <BorderedText text={status} classes={status} />
                </div>
                <span className="vhe-dtls__info-description">
                  {joinStrings(
                    [
                      vehicle.unitNumber,
                      vehicle.model,
                      vehicle.make,
                      vehicle.year,
                      digitsToCurrency(vehicle.totalCharges || 0),
                    ],
                    " | "
                  )}
                </span>
              </div>
              <div className="vehicle-content__header__buttons">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <MilerButton
                    title="Cancel"
                    onClick={() => {
                      setServiceRepairDetailsOpen(false);
                      setRepairOrderDocuments([]);
                    }}
                  />
                  {activeTab === "1" && status?.toLowerCase() === "available" && (
                    <SelectableButton
                      title="Open RO"
                      btnDark
                      onClick={() => openRO()}
                      options={["Void"]}
                      onOptionSelected={(selectedOption) => {
                        if (selectedOption == "Void") {
                          deleteRepairOrder();
                        }
                        return;
                      }}
                    />
                  )}
                  {activeTab === "1" && status?.toLowerCase() === "open" && (
                    <SelectableButton
                      title="Close RO"
                      btnDark
                      options={["Void"]}
                      onClick={() => closeRO()}
                      onOptionSelected={(selectedOption) => {
                        if (selectedOption == "Void") {
                          deleteRepairOrder();
                        }
                        return;
                      }}
                    />
                  )}
                  {activeTab === "2" && (
                    <SelectableButton
                      btnDark
                      title="Save"
                      onClick={() => {
                        handleSubmit();
                      }}
                      options={["Void"]}
                      onOptionSelected={(selectedOption) => {
                        if (selectedOption == "Void") {
                          deleteRepairOrder();
                        }
                        return;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="vehicle-content__tabs-container-items vehicle-details-tabs">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames("vehicle-content__tabs-item", {
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("vehicle-content__tabs-item", {
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Edit
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("vehicle-content__tabs-item", {
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggle("3");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Notes
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames("vehicle-content__tabs-item", {
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggle("4");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Documents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames("vehicle-content__tabs-item", {
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggle("5");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Recent History
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="p-4">
            <Loading />
          </div>
        ) : (
          <div className="slider-display-body">
            <TabContent activeTab={activeTab}>
              {activeTab === "1" && (
                <TabPane tabId="1" className="mt-3">
                  <OverViewTab
                    state={vehicleStatus}
                    repairItems={repairItems}
                  />
                </TabPane>
              )}
              <TabPane tabId="2" className="mt-3">
                {id && activeTab == "2" && (
                  <ServiceRepairForm
                    fromDetails={true}
                    initialVehicle={initialVehicle}
                    totalPrice={totalPrice}
                    totalLabourPrice={totalLabourPrice}
                    totalPartsPrice={totalPartsPrice}
                    setTotalLabourPrice={setTotalLabourPrice}
                    errors={errors}
                    setFormErrors={setFormErrors}
                    findError={findError}
                    vehicle={vehicle}
                    setVehicle={setVehicle}
                    unitType={unitType}
                    setUnitType={setUnitType}
                    setTotalPrice={setTotalPrice}
                    setTotalPartsPrice={setTotalPartsPrice}
                    repairItems={repairItems}
                    setRepairItems={setRepairItems}
                    note={note}
                    setNote={setNote}
                    poNumber={poNumber}
                    setPoNumber={setPoNumber}
                    invoiceNumber={invoiceNumber}
                    setInvoiceNumber={setInvoiceNumber}
                    promisedUTC={promisedUTC}
                    setPromiseUtc={setPromiseUtc}
                    repairDate={repairDate}
                    setRepairDate={setRepairDate}
                    repId={id}
                    newRepairOpen={newRepairOpen}
                    setNewRepairOpen={setNewRepairOpen}
                    editRepairOpen={editRepairOpen}
                    setEditRepairOpen={setEditRepairOpen}
                    getRepairDetails={getRepairDetails}
                    serviceProvider={serviceProvider}
                    repairOrderStatus={repairOrderStatus}
                    setRepairOrderStatus={setRepairOrderStatus}
                    setServiceProvider={setServiceProvider}
                    odometer={odometer}
                    setOdometer={setOdometer}
                    repairOrderDocuments={repairOrderDocuments}
                    setRepairOrderDocuments={setRepairOrderDocuments}
                    serviceTasks={serviceTasks}
                    setOpenServiceTasksSlider={setOpenServiceTasksSlider}
                    selectedServiceTasks={selectedServiceTasks}
                    repairOrder={editRepairOrderDetailsScreenPayload}
                  />
                )}
              </TabPane>
              {activeTab === "3" && (
                <TabPane tabId="3" className="mt-3">
                  <Notes id={id} />
                </TabPane>
              )}
              {activeTab === "4" && (
                <TabPane tabId="4" className="mt-3">
                  <Documents
                    id={id}
                    activeTab={activeTab}
                    repairOrderDocuments={repairOrderDocuments}
                    setRepairOrderDocuments={setRepairOrderDocuments}
                  />
                </TabPane>
              )}
              {activeTab === "5" && (
                <TabPane tabId="5" className="mt-3">
                  <RepairOrderRecentHistory repairOrderId={id} />
                </TabPane>
              )}
            </TabContent>
          </div>
        )}
      </div>
      <SliderModal
        clickState={openServiceTasksSlider}
        setClickState={setOpenServiceTasksSlider}
      >
        <ServiceTasks
          repairItems={repairItems}
          setRepairItems={setRepairItems}
          selectedServiceTasks={selectedServiceTasks}
          serviceTasks={serviceTasks}
          setSelectedServiceTasks={setSelectedServiceTasks}
          setOpenServiceTasksSlider={setOpenServiceTasksSlider}
        />
      </SliderModal>
    </main>
  );
};

export default ServiceRepairDetails;
