import joinStrings from "./joinStrings";

export const geocodeLocationLatLng = async ({ lat, lng }) => {
  const service = new google.maps.Geocoder();

  const addressRes = await service.geocode({
    location: {
      lat,
      lng,
    },
  });

  return getFormattedAddress(addressRes.results.at(0));
};

export const geocodeLocationAddress = async (addressLine) => {
  const service = new google.maps.Geocoder();

  const addressRes = await service.geocode({
    address: addressLine,
  });

  if (addressRes.results.length > 1) {
    //Throw error
    throw new Error("Cannot determine location, multiple results");
  }

  return getFormattedAddress(addressRes.results.at(0));
};

const processAddress = (searchable, raw_address) => {
  let foundItem;

  for (let i = 0; i < searchable.length; i++) {
    foundItem = raw_address.find((x) => x.types.includes(searchable[i]));
    if (foundItem) break;
  }

  return foundItem ? foundItem.short_name : "";
};

const getFormattedAddress = (addr) => {
  const raw_address = addr.address_components;

  const newAddress = {
    addressLine:
      joinStrings(
        [
          processAddress(["street_number"], raw_address),
          processAddress(["route"], raw_address),
        ],
        " "
      ) || "No street",
    city:
      processAddress(
        ["locality", "administrative_area_level_3", "sublocality"],
        raw_address
      ) || "No city",
    state: processAddress(["administrative_area_level_1"], raw_address),
    postalCode: processAddress(["postal_code"], raw_address),
    countryCode: processAddress(["country"], raw_address).toLowerCase(),
    timezoneOffset: addr.utc_offset_minutes / 60,
    latitude: addr.geometry.location.lat(),
    longitude: addr.geometry.location.lng(),
  };
  newAddress.fullAddress = joinStrings(
    [
      newAddress.addressLine,
      newAddress.city,
      newAddress.countryCode,
      newAddress.postalCode,
    ],
    ", "
  );

  return newAddress;
};
