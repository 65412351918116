import { Card } from "reactstrap";

import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import { useState } from "react";
import CustomerDetails from "views/customers/customerDetails/CustomerDetails";
import SliderModal from "components/sliderModal/SliderModal";

const ShipperConsigneeBillToDetail = ({ cardTitle, shipperInfo,shipperId,getShipper}) => {
  const [openShiper, setOpenShipper] = useState(false);
  shipperInfo = {
    mailingContact: { phones: [{}] },
    mailingAddress: {},
    ...shipperInfo,
  };
  return (
    <Card className="overview-driver-customer-card ship-overview-card">
      <div className="mb-2">
        <span className="bold text-uppercase">{cardTitle}</span>
      </div>
      <div className="main-content">
        {shipperInfo.shipperId ? (
          <div className="main-content__sub">
            <div className="image-card ">
              <ProfilePlaceholder jointName={shipperInfo.shipperName} />
            </div>
            <div>
              <div>
                <span className="main-title" onClick={()=>{setOpenShipper(true)}}>
                  {shipperInfo.shipperName}
                  <br />
                </span>
              </div>
              <div>
                <div className="iconNtext">
                  <i className="mil-location-company-o"></i>
                  <span className="secondary-title__texts">{`${
                    shipperInfo.mailingAddress.addressLine || ""
                  }, ${shipperInfo.mailingAddress.city || ""} ${
                    shipperInfo.mailingAddress.state || ""
                  } ${shipperInfo.mailingAddress.postalCode || ""}`}</span>
                </div >
              </div>
              <div>
                <div className="iconNtext">
                  <i className="mil-user-o"></i>
                  <span className="secondary-title__text">{`${
                    shipperInfo.mailingContact.title || ""
                  } ${shipperInfo.mailingContact.firstName || ""} ${
                    shipperInfo.mailingContact.lastName || ""
                  }, ${shipperInfo.mailingContact.department || ""}`}</span>
                </div >
              </div>
              <div>
                <div className="iconNtext">
                  <i className="mil-phone-o"></i>
                  <span className="secondary-title__text">
                    {shipperInfo.mailingContact.phones[0].phoneNumber}
                  </span>
                </div >
              </div>
              <div>
                <div className="iconNtext">
                  <i className="mil-email-o"></i>
                  <span className="secondary-title__text">
                    {shipperInfo.mailingContact.email}
                  </span>
                </div >
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-5 text-center">
            <h4>NO ASSOCIATED SHIPPER</h4>
          </div>
        )}
      </div>{" "}
      <SliderModal clickState={openShiper} setClickState={setOpenShipper}>
        <CustomerDetails
          setCustomerDetailsOpen={setOpenShipper}
          shipperId={shipperId}
          getCustomers={getShipper}
        />
      </SliderModal>
    </Card>
  );
};

export default ShipperConsigneeBillToDetail;
