import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import "components/phoneInputFieldStyle/PhoneInput.sass";
import "react-phone-number-input/style.css";
import RepairItems from "../repairItems/RepairItems";
import SliderModal from "components/sliderModal/SliderModal";
import NewRepairItem from "../repairItems/newRepairItem/NewRepairItem";
import EquipComp from "../../serviceRequest/serviceRequestForm/EquipComp";
import ServiceProviderSearch from "views/maintenance/serviceProviderSearch/ServiceProviderSearch";
import DateTimeInput from "components/datetime-input/DateTimeInput";
import { appContext } from "services/AppContext";
import { useContext } from "react";
import Documents from "../serviceRepairDetails/Documents";
import dateFormatter from "utils/dateFormater";
import WarningBox from "components/warningBox/WarningBox";
import "./ServiceRepairForm.sass";

const ServiceRepairForm = ({
  vehicle,
  setVehicle,
  repairItems,
  setRepairItems,
  note,
  setNote,
  poNumber,
  setPoNumber,
  promisedUTC,
  setPromiseUtc,
  invoiceNumber,
  setInvoiceNumber,
  repairDate,
  setRepairDate,
  fromDetails,
  repId,
  newRepairOpen,
  setNewRepairOpen,
  editRepairOpen,
  setEditRepairOpen,
  errors,
  getRepairDetails,
  serviceProvider,
  setServiceProvider,
  repairOrderStatus,
  setRepairOrderStatus,
  odometer,
  setOdometer,
  repairOrderDocuments,
  setRepairOrderDocuments,
  serviceTasks,
  setOpenServiceTasksSlider,
  selectedServiceTasks,
  repairOrder,
}) => {
  const { enums } = useContext(appContext);

  return (
    <div className="slider-display-body">
      <div className="miler-card mt-0">
        <div className="miler-card-title miler-text">RO Details</div>
        {serviceTasks.length > 0 && (
          <WarningBox
            title={`There are ${serviceTasks.length} service tasks for this ${vehicle.unitType}`}
            showBtn
            btnitle={"Add Service Tasks"}
            btnOnclick={() => {
              setOpenServiceTasksSlider(true);
            }}
          />
        )}
        <div className="row mt-2">
          <div className="service_repair_equipment_searcher">
            <EquipComp
              vehicle={vehicle}
              setVehicle={setVehicle}
              hideCreateNew={true}
              error={errors}
              errorName="UnitNumber"
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <ServiceProviderSearch
              value={serviceProvider}
              onSelect={(item) => setServiceProvider(item)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <DateTimeInput
              name="serviceRequestedUTC"
              label="Repair Date"
              type="date"
              value={dateFormatter(repairDate, "YYYY-MM-DD", true)}
              onChange={(value) => {
                setRepairDate(value);
              }}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <DateTimeInput
              name="promisedUTC"
              label="Promised Date & Time"
              value={promisedUTC}
              onChange={(value) => {
                setPromiseUtc(value);
              }}
            />
          </div>

          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="Invoice Number"
              name="invoiceNumber"
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-6">
            <InputMiler
              label="PO Number"
              name="poNumber"
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
            />
          </div>

          {repId ? (
            <>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  value={odometer}
                  onChange={(e) => setOdometer(e.target.value)}
                  name="odometer"
                  label="Odometer"
                />
              </div>
              <div className="col-12 col-lg-6 col-xl-6">
                <InputMiler
                  value={repairOrderStatus}
                  type="select"
                  options={enums?.OrderStatus || []}
                  onChange={(e) => setRepairOrderStatus(e.target.value)}
                  name="status"
                  label="Status"
                  disabled
                />
              </div>
            </>
          ) : (
            <div className="col-12 ">
              <InputMiler
                value={odometer}
                onChange={(e) => setOdometer(e.target.value)}
                name="odometer"
                label="Odometer"
              />
            </div>
          )}
        </div>
      </div>

      <div className="miler-card mt-0">
        <div
          className="miler-card-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="tit">
            Repair Items
            {repairItems.length === 0 ? (
              <span className="required-red">*</span>
            ) : null}{" "}
            {repairItems.length > 0 ? ` (${repairItems.length})` : ""}
          </div>
          <div className="service_repair_new_item_btn">
            <MilerButton
              btnDark
              title="New Item"
              onClick={() => setNewRepairOpen(true)}
            />
          </div>
        </div>
        {repairItems.length === 0 ? (
          <>
            {errors.find((err) => err.key === "RepairItems") ? (
              <div className="service_repair_form_no_repair_item_error">
                At least one repair item is required
              </div>
            ) : (
              <div className="service_repair_form_no_repair_item">
                No Repair Items Added
              </div>
            )}
          </>
        ) : (
          <RepairItems
            repairItems={repairItems}
            setRepairItems={setRepairItems}
            fromDetails={fromDetails}
            editRepairOpen={editRepairOpen}
            setEditRepairOpen={setEditRepairOpen}
            errors={errors}
            getRepairDetails={getRepairDetails}
            repId={repId}
            repairOrder={repairOrder}
          />
        )}
        <div style={{ marginTop: "26px" }}>
          <InputMiler
            name="note"
            label="Repair Description"
            type="textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </div>

      {repId ? (
        <></>
      ) : (
        <Documents
          repairOrderDocuments={repairOrderDocuments}
          setRepairOrderDocuments={setRepairOrderDocuments}
        />
      )}
      <SliderModal
        clickState={newRepairOpen}
        setClickState={() => {}}
        from="bottom"
      >
        <NewRepairItem
          setNewRepairOpen={setNewRepairOpen}
          repairItems={repairItems}
          setRepairItems={setRepairItems}
          getRepairDetails={getRepairDetails}
          fromDetails={fromDetails}
          repairOrderId={repId}
          selectedServiceTasks={selectedServiceTasks}
          repairOrder={repairOrder}
        />
      </SliderModal>
    </div>
  );
};

export default ServiceRepairForm;
