import { useContext, useEffect, useState } from "react";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import { appContext } from "services/AppContext";
import { getElementsV2, postElement } from "services/milerApi";
import Loading from "components/loading/Loading";
const Documents = ({ shipmentId }) => {
  const {
    enums: { DocumentTypeShipment },
  } = useContext(appContext);

  const [documents, setDocuments] = useState([]);

  const [isLoading, setIsLoading] = useState(<></>);

  const delDoc = (index) => {
    let updatedDocuments = documents;
    updatedDocuments.splice(index, 1);
    postElement(`/Shipment/${shipmentId}/Document`, updatedDocuments).then(
      (res) => {
        if (res.success) getDocuments();
      }
    );
  };
  const addDoc = (docObject) => {
    let docs = [...documents, docObject];
    postElement(`/Shipment/${shipmentId}/Document`, docs).then((res) => {
      if (res.success) getDocuments();
    });
  };

  const getDocuments = () => {
    getElementsV2({
      url: `/Shipment/${shipmentId}/Document`,
      setLoading: setIsLoading,
    }).then((response) => {
      setDocuments(response.data || []);
    });
  };

  useEffect(() => {
    getDocuments();
  }, []);
  return (
    <>
      {isLoading ? (
        <section className="mt-2">
          <Loading />
        </section>
      ) : (
        <AssociatedDocuments
          documents={documents}
          delDoc={delDoc}
          addDoc={addDoc}
          enums={DocumentTypeShipment}
          documentCategory="Shipment"
        />
      )}
    </>
  );
};

export default Documents;
