import { Marker, InfoWindow } from "@react-google-maps/api";
// import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import truck from "assets/icons-clip/location-company-o.svg";
import truck2 from "assets/icons-clip/location-o.svg";
import "./Map.sass";
import { useState } from "react";
const TripMarkers = ({ position }) => {
  const [showInfoWindow, setShowInfowWindow] = useState(false);
  const { stopType, fullAddress } = position || {};

  return (
    <Marker
      label={{
        // text: stop.address ? stop.address.addressLine : "",
        className: "trip-marker-label",
        fontSize: "17px",
        color: "#444",
      }}
      icon={{
        url: stopType === "Delivery" ? truck2 : truck,
      }}
      position={position}
      clickable
      onMouseOver={() => setShowInfowWindow(true)}
      onMouseOut={() => setShowInfowWindow(false)}
    >
      {showInfoWindow ? (
        <InfoWindow
          position={position}
          onCloseClick={() => setShowInfowWindow(false)}
          options={{
            disableAutoPan: true,
          }}
        >
          <>
            <p>{fullAddress}</p>
            <section className="marker-info-window">
              <div
                className={
                  stopType === "Delivery" ? "green-marker" : "red-marker"
                }
              ></div>
              <p>{stopType}</p>
            </section>
          </>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default TripMarkers;
