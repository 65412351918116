import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import { toast } from "react-toastify";

const NewPartSlider = ({ setNewPartOpen, newPartOpen, onCreatePart }) => {
  const [state, setState] = useState({
    partNumber: newPartOpen || "",
    partDescription: "",
  });

  const onChange = ({ name, value }) => {
    setState({ ...state, [name]: value });
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">New Part</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setNewPartOpen(false);
            }}
          />

          <MilerButton
            btnDark
            title="Apply"
            onClick={() => {
              if (state.partNumber) {
                onCreatePart(state);
                setNewPartOpen(false);
              } else toast.error("Part Number Is required");
            }}
          />
        </div>
      </div>

      <div className="slider-display-body">
        <div className="miler-card mt-0">
          <div className="miler-card-title">Part Details</div>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputMiler
                value={state.partNumber}
                type="text"
                label="Part Number"
                required
                onChange={(e) =>
                  onChange({ name: "partNumber", value: e.target.value })
                }
              />
            </div>

            <div className="col-12 col-md-6">
              <InputMiler
                type="text"
                label="Part Description"
                value={state.partDescription}
                onChange={(e) =>
                  onChange({ name: "partDescription", value: e.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPartSlider;
