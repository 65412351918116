import { useContext, useState } from "react";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { appContext } from "services/AppContext";
import "./PackagingInfo.sass";
import MilerButton from "components/milerButton/MilerButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PackagingInfoForm } from "./PackagingInfoForm";

const PackagingInfo = ({ state, dispatch }) => {
  const { enums } = useContext(appContext);
  const [packagingInfoOpen, setPackagInfoOpen] = useState(false);
  const [packagingInfo, setPackagInfo] = useState({});
  const [editIndex, setEditIndex] = useState(null);
  const [draggableOutOfZone, setDraggableOutOfZone] = useState(false);
  const [draggableInWrongZone, setDraggableInWrongZone] = useState(false);
  const [op, setOp] = useState(null);

  const packagingList = state?.shipmentLading || [];

  const deletePackageInfo = (dataItem, index) => {
    dispatch({
      category: "removeShipmentLanding",
      payload: dataItem,
      itemIndex: index,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPackagInfo((prev) => {
      const newPackagInfo = {
        ...prev,
        [name]: value,
      };

      const { length, width, height } = newPackagInfo;

      if (length && width && height) {
        const volume = (length * width * height) / 1728
        newPackagInfo.volume = volume.toFixed(4);
      } else {
        newPackagInfo.volume = "";
      }
      return newPackagInfo;
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const items = Array.from(packagingList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    items.forEach((item, index) => {
      item.ladingNumber = index + 1;
    });

    dispatch({
      category: "moveshipmentLanding",
      payload: items,
    });

    if (!draggableInWrongZone && !draggableOutOfZone) {
      setOp(op === source.index ? destination.index : null);
    }
  };
  const addPackageInfo = () => {
    dispatch({
      category: "addShipmentLading",
      payload: packagingInfo,
    });
    setPackagInfo({});
    setPackagInfoOpen(false);
  };
  const saveEdit = () => {
    dispatch({
      category: "editShipmentLanding",
      payload: packagingInfo,
    });

    setEditIndex(null);
    setPackagInfo({});
    setPackagInfoOpen(false);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setPackagInfo({});
    setPackagInfoOpen(false);
  };

  const addNewRow = () => {
    setPackagInfo({});
    setPackagInfoOpen(true);
    setEditIndex(null);
  };

  return (
    <Card>
      <CardBody className="shipment-packages shipment-detail-card">
        <CardTitle className="shipment-detail-header">
          <p className="shipment-detail-title">PACKAGING INFO</p>
        </CardTitle>
        <Row style={{ margin: "0 7px" }}>
          <div className="stops-table shipment-form-stops">
            {packagingList.length > 0 || packagingInfoOpen ? (
              <div className="shipment-package-form-title">
                <div>Sort</div>
                <div>Lading #</div>
                <div>Type</div>
                <div>Weight</div>
                <div>Quantity</div>
                <div>Length (in.)</div>
                <div>Width (in.)</div>
                <div>Height (in.)</div>
                <div>Cubic Feet</div>
                <div>Actions</div>
              </div>
            ) : (
              <div className="no-packaging-info">No packaging information available</div>
            )}

            <DragDropContext
              onDragEnd={handleDragEnd}
              onDragUpdate={(result) => {
                setDraggableOutOfZone(result.destination == null);
                if (result.destination) {
                  setDraggableInWrongZone(false);
                }
              }}
            >
              <Droppable droppableId="drop" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? "#fffbeb"
                        : "white",
                    }}
                    className="drag-context"
                  >
                    {packagingList.map((dataItem, index) => (
                      <Draggable
                        key={index}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style = {
                              ...provided.draggableProps.style,
                              left: provided.draggableProps.style.offsetLeft,
                            };
                          }
                          const style = {
                            backgroundColor: snapshot.isDragging
                              ? draggableOutOfZone || draggableInWrongZone
                                ? "#f87171"
                                : "#a3a3a3"
                              : "#E2E6EA",
                            ...provided.draggableProps.style,
                          };

                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="shipment-package-form-item-summary"
                              style={style}
                            >
                              {editIndex === index ? (
                                <div className="" style={{ width: "100%" }}>
                                  <PackagingInfoForm
                                    packagingInfo={packagingInfo}
                                    enums={enums}
                                    handleInputChange={handleInputChange}
                                    newPackage={saveEdit}
                                    cancelEdit={cancelEdit}
                                    index={index}
                                  />
                                </div>
                              ) : (
                                <>
                                  <div>
                                    <i className="mil-menu" />
                                  </div>
                                  <div>{dataItem.ladingNumber}</div>
                                  <div>{dataItem.packageType}</div>
                                  <div>{dataItem?.weight?.toLocaleString()}</div>
                                  <div>{dataItem.quantity}</div>
                                  <div>{dataItem.length}</div>
                                  <div>{dataItem.width}</div>
                                  <div>{dataItem.height}</div>
                                  <div>{dataItem.volume}</div>
                                  <div className="table-actions">
                                    <i
                                      className="mil-edit"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "17px",
                                        margin: "5px",
                                      }}
                                      onClick={() => {
                                        setPackagInfo({ ...dataItem, index });
                                        setPackagInfoOpen(true);
                                        setEditIndex(index);
                                      }}
                                    />
                                    <i
                                      className="mil-delete-o"
                                      style={{
                                        cursor: "pointer",
                                        fontSize: "17px",
                                        margin: "5px",
                                      }}
                                      onClick={() => {
                                        deletePackageInfo(dataItem, index);
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {packagingInfoOpen && editIndex === null && (
                      <PackagingInfoForm
                        packagingInfo={packagingInfo}
                        enums={enums}
                        handleInputChange={handleInputChange}
                        newPackage={addPackageInfo}
                        cancelEdit={cancelEdit}
                        index={packagingList.length}
                      />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ marginTop: 30 }}>
            <MilerButton
              title="Add Packaging Info"
              icon="mil-plus"
              onClick={addNewRow}
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PackagingInfo;
