import { useEffect, useState } from "react";
import "./VehicleDetails.sass";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import MilerButton from "components/milerButton/MilerButton";
import Overview from "./overview/Overview";
import VehicleEdit from "./edit/Edit";
import { getElementsV2, putElement } from "services/milerApi";
import joinStrings from "utils/joinStrings";
import MapsMiler from "components/mapMiler/MapsMiler";
import Trips from "./trips/Trips";
import ServiceHistory from "./servicehistory/ServiceHistory";
import InspectionHistory from "./inspectionhistory/servicehistory/InspectionHistory";
import Payee from "./Payee";
import { hasPermission } from "utils/hasPermission";
import Notes from "./notes/Notes";
import { getCookie } from "utils/cookies";
import Documents from "views/fleet/trailers/trailerDetails/documents/Documents";
import BorderedText from "components/bordered-text/BorderedText";
import Fuel from "./fuel/Fuel";
const VehiclesDetails = ({ setDetails, vehicleId, getVehicles }) => {
  const { subscription_plan } = getCookie("userInfo") || {};

  const [state, setState] = useState();
  const [activeTab, setActiveTab] = useState(
    subscription_plan != "Miler Rectify (Free)" ? "1" : "2"
  );
  const [payee, setPayee] = useState({});
  const [errors, setFormErrors] = useState([]);
  const [payeeErrors, setPayeeErrors] = useState([]);
  const [yearError, setYearError] = useState(null);

  const put = () => {
    const bodyState = JSON.parse(JSON.stringify(state));
    //set company driver's accountId to null
    if (
      state.ownership !== "Owner Operator Vehicle" &&
      state.ownership !== "Leased Vehicle"
    )
      bodyState.accountId = null;
    //remove payee object
    delete bodyState.payee;
    const trimmedbodyState = {
      ...bodyState,
      unitNumber: bodyState?.unitNumber?.trim(),
    };
    putElement(
      `Equipment/vehicle?vehicleId=${vehicleId}`,
      trimmedbodyState
    ).then((response) => {
      if (response.success) {
        getVehicles();
        setDetails(false);
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const getPayee = () => {
    getElementsV2({
      url: `/Carrier/Payee/${state.accountId}`,
      setLoading: null
    }).then((res) => {
      setPayee({ ...res.data || {} });
    });
  };

  const updatePayee = () => {
    putElement(`/Carrier/Payee/${state.accountId}`, payee).then((response) => {
      if (response.success) {
      } else if (response.vErrors) {
        setPayeeErrors(response.vErrors);
      }
    });
  };

  const getVehicle = () => {
    getElementsV2({
      url: `Equipment/Vehicle/Id?vehicleId=${vehicleId}`,
      setLoading: null
    }).then(
      (response) => {
        setState(response.data || []);
      }
    );
  };

  useEffect(getVehicle, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      {state && (
        <>
          <div className="slider-display vehicle-details">
            <div className="slider-display-header">
              <div className="vehicle-details-header">
                <div className="vehicle-details-info">
                  <div>
                    <div className="vhe-dtls__vehicle-number-status">
                      <h3 className="vhe-dtls__vehicle-number">
                        {state.unitNumber}
                      </h3>
                      <BorderedText
                        text={state.status}
                        classes={`${
                          state.status === "Inactive"
                            ? "InActive"
                            : state.status
                        }`}
                      />
                    </div>
                    <span className="vhe-dtls__info-description">
                      {joinStrings(
                        [state.make, state.model, state.year],
                        " | "
                      )}
                    </span>
                  </div>
                  <i
                    className="mil-close-bg vhe-dtls__close"
                    onClick={() => setDetails(false)}
                    style={{ fontSize: "1.8rem", cursor: "pointer" }}
                  />
                </div>
                <div className="vehicle-content__tabs-container-items vehicle-details-tabs">
                  <Nav tabs>
                    {subscription_plan != "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={classnames("vehicle-content__tabs-item", {
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Edit
                      </NavLink>
                    </NavItem>

                    {/* <NavItem>
                      <NavLink
                        className={classnames("vehicle-content__tabs-item", {
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggle("3");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Assignment
                      </NavLink>
                    </NavItem> */}

                    {subscription_plan != "Miler Rectify (Free)" &&
                      (state.ownership === "Owner Operator Vehicle" ||
                        state.ownership === "Leased Vehicle") && (
                        <NavItem>
                          <NavLink
                            className={classnames(
                              "vehicle-content__tabs-item",
                              {
                                active: activeTab === "4",
                              }
                            )}
                            onClick={() => {
                              toggle("4");
                            }}
                          >
                            Payee
                          </NavLink>
                        </NavItem>
                      )}
                    {subscription_plan !== "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "FuelCard",
                          })}
                          onClick={() => {
                            toggle("FuelCard");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Fuel Card
                        </NavLink>
                      </NavItem>
                    )}
                    {subscription_plan != "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "5",
                          })}
                          disabled={!state.eldId}
                          title={state.eldId ? "" : "ELD is not setup"}
                          onClick={() => {
                            toggle("5");
                          }}
                        >
                          Map
                        </NavLink>
                      </NavItem>
                    )}
                    {subscription_plan != "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "6",
                          })}
                          onClick={() => {
                            toggle("6");
                          }}
                        >
                          Trips
                        </NavLink>
                      </NavItem>
                    )}
                    {subscription_plan != "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "7",
                          })}
                          onClick={() => {
                            toggle("7");
                          }}
                        >
                          Service History
                        </NavLink>
                      </NavItem>
                    )}
                    {subscription_plan != "Miler Rectify (Free)" && (
                      <NavItem>
                        <NavLink
                          className={classnames("vehicle-content__tabs-item", {
                            active: activeTab === "8",
                          })}
                          onClick={() => {
                            toggle("8");
                          }}
                        >
                          Inspection History
                        </NavLink>
                      </NavItem>
                    )}
                    <NavItem>
                      <NavLink
                        className={classnames("vehicle-content__tabs-item", {
                          active: activeTab === "10",
                        })}
                        onClick={() => {
                          toggle("10");
                        }}
                      >
                        Notes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("vehicle-content__tabs-item", {
                          active: activeTab === "9",
                        })}
                        onClick={() => {
                          toggle("9");
                        }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <div className="vehicle-content__header__buttons">
                    {activeTab === "2" && (
                      <MilerButton
                        title="Save"
                        icon="mil-save-o"
                        disabled={yearError}
                        sm
                        targetActionDenied={!hasPermission("edit.fleet")}
                        onClick={put}
                        btnDark
                      />
                    )}
                    {activeTab === "4" && (
                      <MilerButton
                        btnDark
                        icon="mil-save-o"
                        sm
                        title="Save"
                        targetActionDenied={!hasPermission("edit.accounting")}
                        onClick={() => updatePayee()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-display-body">
              <TabContent activeTab={activeTab}>
                {activeTab === "1" && (
                  <TabPane tabId="1" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <Overview
                          driverId={state.driverId}
                          vehicleId={vehicleId}
                          vehicle={state}
                          getVehicle={getVehicle}
                          getVehicles={getVehicles}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "2" && (
                  <TabPane tabId="2" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <VehicleEdit
                          vehicleId={vehicleId}
                          setYearError={setYearError}
                          yearError={yearError}
                          state={state}
                          setState={setState}
                          getVehicles={getVehicles}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}

                {activeTab === "4" && (
                  <TabPane tabId="4" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <div className="slider-display">
                          <Payee
                            payee={payee}
                            setPayee={setPayee}
                            errors={payeeErrors}
                            getPayee={getPayee}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "FuelCard" && (
                  <TabPane tabId="FuelCard" className="mt-3">
                    <Fuel fuelId={vehicleId} />
                  </TabPane>
                )}
                {activeTab === "5" && (
                  <TabPane tabId="5" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <MapsMiler
                          unitNumber={state.unitNumber}
                          eldId={state.eldId}
                          type="tracking"
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "6" && (
                  <TabPane tabId="6" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <Trips
                          id={state.vehicleId}
                          setDetails={setDetails}
                          fromVehicle={true}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "7" && (
                  <TabPane tabId="7" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <ServiceHistory
                          id={state.vehicleId}
                          fromVehicle={true}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "8" && (
                  <TabPane tabId="8" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <InspectionHistory
                          id={state.vehicleId}
                          fromVehicle={true}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "9" && (
                  <TabPane tabId="9" className="mt-3">
                    <Row>
                      <Col sm="12">
                        <Documents
                          documentUrl={`/Equipment/Vehicle/${vehicleId}`}
                          documentCategory="Fleet"
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {activeTab === "10" && (
                  <TabPane tabId="10" className="mt-3">
                    <Notes id={vehicleId} />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VehiclesDetails;
