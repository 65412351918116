import {useState} from "react";
import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { postElement } from "services/milerApi";

export default function NoteInfo({ setEditNote, updateTrip, stop }) {
  const [note, setNote] = useState({
    note: "",
    stopNoteType: "General",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  const postNote = () => {
    note.note &&
      postElement(`/Shipment/Trip/Stop/${stop.stopId}/Notes`, note).then(() => {
        updateTrip();
      });
  };

  return (
    <div className="slider-display trip-add-note">
      <div className="slider-display-header">
        <div className="slider-header-title">Stop Notes</div>
        <div>
          <MilerButton title="Close" onClick={() => setEditNote(false)} />
        </div>
      </div>
      <div className="slider-display-body">
        <div className="miler-card">
          <div className="miler-card-header">
            <div className="miler-card-title">Add Note</div>
            <MilerButton title="Save" onClick={postNote} btnDark />
          </div>

          <div className="row trip-add-note_add_new">
            <div className="col-12">
              <InputMiler
                label="General Note"
                type="textarea"
                name="note"
                value={note.note}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="miler-card">
          <div className="miler-card-title">Notes</div>

          {stop.stopNotes.length ? (
            stop.stopNotes.map((note, index) => (
              <div key={index} className="trip-notes-list row py-2">
                <div className="col-4">
                  <strong>{note.stopNoteType}</strong>
                </div>
                <div className="col-8 text-bold fw-bold">{note.note}</div>
              </div>
            ))
          ) : (
            <div className="text-center text-muted mb-3">no notes saved</div>
          )}
        </div>
      </div>
    </div>
  );
}
