import "./ActivityDisplay.sass";
import Trip from "./tripDisplay/Trip";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import {
  ActivityContext,
  ActivityProvider,
} from "../activityContext/ActivityContext";
import { useContext, useEffect } from "react";

const ActivityDisplaySlider = ({ tripId, closeTripSlider }) => {
  return (
    <div className="activity_display">
      <ErrorBoundary message="Display Trip">
        <ActivityProvider>
          <Dd tripId={tripId} closeTripSlider={closeTripSlider} />
        </ActivityProvider>
      </ErrorBoundary>
    </div>
  );
};
export default ActivityDisplaySlider;

const Dd = ({ tripId, closeTripSlider }) => {
  const { getTrip, currentTrip } = useContext(ActivityContext);
  useEffect(() => {
    getTrip(tripId);
  }, []);
  return (
    <div>
      {currentTrip.tripId ? <Trip closeTripSlider={closeTripSlider} /> : null}
    </div>
  );
};
