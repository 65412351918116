import InputMiler from "components/inputMiler/InputMiler";
import { useState, useContext } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";
import { postElement } from "services/milerApi";
import { appContext } from "services/AppContext";
import AssociatedDocuments from "components/associatedDocuments/AssociatedDocuments";
import findError from "utils/findError";

const ShipmentCharge = ({ shipmentId, setOpenCharge, getShipments }) => {
  const [charge, setCharge] = useState({
    chargeType: "TONU",
    rating: "Flat",
    unit: 1,
    rate: null,
    subTotal: 0,
  });
  const {
    enums: { Rating, DocumentTypeShipment },
  } = useContext(appContext);

  const onChange = (event) => {
    const { name, value } = event.target;
    setCharge({
      ...charge,
      [name]: value,
    });
  };
  const [chargeDocuments, setChargeDocuments] = useState([]);
  const [errors, setFormErrors] = useState([]);

  const [isDocumentOne, setIsDocumentOne] = useState(0);

  const showTonuDocumentType = DocumentTypeShipment.filter(
    (doc) => doc.keyName.toLowerCase() === "tonu"
  );

  const [showDocumentError, setShowDocumentError] = useState();

  const onSave = () => {
    let chargeItems;
    if (isDocumentOne) {
      chargeItems = {
        charge: {
          ...charge,
          subTotal: Number(charge.rate) * Number(charge.unit),
        },
        rateCon: {
          documentType: "TONU",
          documentUrl: chargeDocuments[0].documentUrl,
          createdUTC: chargeDocuments[0].createdUTC,
        },
      };
    } else {
      chargeItems = {
        charge: {
          ...charge,
          subTotal: Number(charge.rate) * Number(charge.unit),
        },
      };
    }

    postElement(`Shipment/${shipmentId}/TONU`, chargeItems).then((response) => {
      if (response.success) {
        setOpenCharge(false);
        getShipments();
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
        const hasDocumentHaveError = response.vErrors.find(
          (error) => error.key === "RateCon"
        );
        setShowDocumentError(hasDocumentHaveError?.errorMessage);
      }
    });
  };

  const filteredRating = Rating.reduce((acc, option) => {
    if (charge.chargeType === "Line Haul" && option.keyName === "Percentage") {
      return acc;
    }
    return [...acc, option];
  }, []);

  const deleteDocuments = (documents, index) => {
    documents.splice(index, 1);
    setChargeDocuments([...documents]);
    setIsDocumentOne(chargeDocuments.length);
    return;
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Mark TONU</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="cancel"
            onClick={() => {
              setOpenCharge(false);
            }}
          />
          <MilerButton btnDark title="Save" onClick={onSave} />
        </div>
      </div>
      <Card className="mb-4 mx-3 slider-display-body">
        <CardBody className="shipment-detail-card">
          <Row>
            <Col lg="6">
              <InputMiler
                type="select"
                options={["TONU"]}
                value={"TONU"}
                label="Charge Type"
                name="chargeType"
                onChange={onChange}
                disabled
                required
              />
            </Col>
            <Col lg="6">
              <InputMiler
                value={charge.rating}
                label="Rating"
                type="select"
                options={filteredRating}
                name="rating"
                onChange={onChange}
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InputMiler label="Unit" value={charge.unit || 1} disabled />
            </Col>
            <Col lg="6">
              <InputMiler
                label="Rate"
                type="number"
                value={charge.rate}
                name="rate"
                onChange={onChange}
                required
                error={findError("Charge.Rate", errors)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <section className="p-3">
        <AssociatedDocuments
          documents={chargeDocuments}
          addDoc={(doc) => {
            const docs = chargeDocuments || [];
            docs.unshift(doc);
            setChargeDocuments(docs);
            setIsDocumentOne(chargeDocuments.length);
          }}
          delDoc={(index) => {
            deleteDocuments(chargeDocuments, index);
          }}
          enums={showTonuDocumentType}
          documentCategory="Shipment"
          isDocumentOne={isDocumentOne}
          errorMessage={showDocumentError}
          setShowDocumentError={setShowDocumentError}
          isMarkTonu={true}
        />
      </section>
    </div>
  );
};

export default ShipmentCharge;
