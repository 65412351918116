import MilerButton from "components/milerButton/MilerButton";
import { useState } from "react";
import RecurringForm from "./RecurringForm";

const EditCharge = ({
  setOpenForm,
  chargeDetails,
  updateRecurringList,
  index,
}) => {
  const [charge, setCharge] = useState(chargeDetails);


  const [chargeErrors, setChargeErrors] = useState({
    transactionType: "",
    amount: "",
    stopAfterDate: "",
    stopAfterAmount: "",
    emailBeforeLast: false,
    lastAmount: "",
    descriptiokkn: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "stopAfter") {
      setCharge((prev) => ({
        ...prev,
        stopAfterAmount: "",
        stopAfterDate: "",
        [name]: value,
      }));
      if (value === "Specified Amount") {
        setCharge((prev) => ({ ...prev, stopAfterDate: "" }));
      }
    } else {
      setCharge({ ...charge, [name]: value });
      setChargeErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const onSave = () => {
    //form input error set-up
    let transErr = charge.transactionType ? "" : "Transactiontype's required";
    let amountErr = charge.amount ? "" : "Amount's required";
    setChargeErrors({ transactionType: transErr, amount: amountErr });

    //run only when there is no errors
    if (!transErr && !amountErr) {
      updateRecurringList({ type: "edit", charge, index });
      setOpenForm(false);
    }
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Edit Charge</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => {
              setOpenForm(false);
            }}
          />
          <MilerButton btnDark title="Apply" onClick={onSave} />
        </div>
      </div>
      <div className="slider-display-body">
        <RecurringForm
          charge={charge}
          setCharge={setCharge}
          onChange={onChange}
          chargeErrors={chargeErrors}
          chargeDetails={chargeDetails}
        />
      </div>
    </div>
  );
};

export default EditCharge;
