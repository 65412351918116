import dateFormatter from "utils/dateFormater";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(relativeTime);
function CheckCallCard({ callData }) {
  // let utc=nextStop.address.timezoneOffset;
  return (
    <>
      <div className="checkcall-card">
        <div className="card-top">
          <div className="top-row">
            <div>
              <span className="checkcard-icon ">
                <i className="mil-location-company-f" />
              </span>
              <span>{callData.vehicleLocation}</span>
            </div>
            <div className="text-nowrap">
              {dateFormatter(callData.lastUpdateUtc, "MMM DD, HH:mm", false)}
            </div>
          </div>
          <div className="main-content">
            <span>{callData.notes}</span>
          </div>
        </div>
        <div className="divider"></div>
        <div className="card-bottom">
          <div>
            <span className="checkcard-icon">
              <i className="mil-location-marker" />
            </span>
            {callData.nextStop && (
              <span>
                {callData.nextStop.address.addressLine},
                {callData.nextStop.address.city},{" "}
                {callData.nextStop.address.countryCode}
              </span>
            )}
          </div>
          <div>
            {/* <span className='checkcard-icon'>
              <i className='mil-clock-f ico' />
            </span> */}
            <span>
              {callData.etaRelative && (
                <>
                  <strong>ETA: </strong>
                  <>{callData.etaRelative}</>
                </>
              )}
              <div style={{ display: "inline", marginLeft: "15px" }}></div>
              {callData.remainigDistance && (
                <>
                  <strong>Remaining Distance: </strong>
                  <>{callData.remainigDistance}mi</>
                </>
              )}
              {/* {dateFormatter(callData.etaRelative, "MMM DD, HH:mm", true)} */}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckCallCard;
