import ServiceTasksData from "./ServiceTasksData";
import MilerButton from "components/milerButton/MilerButton";

const ServiceTasks = ({
  setOpenServiceTasksSlider,
  serviceTasks,
  selectedServiceTasks,
  setSelectedServiceTasks,
  setRepairItems,
  repairItems,
}) => {
  return (
    <main>
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">Add Service Task</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="Cancel"
              onClick={() => {
                setOpenServiceTasksSlider(false);
              }}
            />

            <MilerButton
              btnDark
              title="Add"
              onClick={() => {
                const generateSelectedSeerviceTask = selectedServiceTasks.map(
                  (serviceTask) => {
                    return {
                      serviceCode: serviceTask.serviceCode,
                      serviceId: serviceTask.serviceId,
                      serviceTaskItemId: serviceTask.serviceTaskItemId,
                      description: "",
                      partsPrice: 0,
                      laborPrice: 0,
                      totalCharges: 0,
                      repairLineItems: [],
                    };
                  }
                );
                setRepairItems([
                  ...repairItems,
                  ...generateSelectedSeerviceTask,
                ]);
                setOpenServiceTasksSlider(false);
              }}
            />
          </div>
        </div>
        <section className="p-3">
          <ServiceTasksData
            serviceTasks={serviceTasks}
            selectedServiceTasks={selectedServiceTasks}
            setSelectedServiceTasks={setSelectedServiceTasks}
          />
        </section>
      </div>
    </main>
  );
};

export default ServiceTasks;
