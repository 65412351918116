import { useState } from "react";
import MilerButton from "components/milerButton/MilerButton";
import InputMiler from "components/inputMiler/InputMiler";
import NewShippment from "../newShipment/NewShippment";
import ShipmentsFilter from "./ShipmentsFilter";
import ListAndMapToggle from "../../../components/list-map-toggle/ListAndMapToggle";
import { hasPermission } from "utils/hasPermission";
import getUserInfoCookieProp from "utils/getUserInfoCookieProp";
import SliderModal from "components/sliderModal/SliderModal";

const ShipmentHeader = ({
  setRequest,
  request,
  getShipments,
  setShowMap,
  showMap,
  setSearchedValue,
  searchedValue,
  useAsUtil,
  activeTab,
}) => {
  const [newShipment, setNewShipment] = useState(false);

  const isFleetOwner =
    getUserInfoCookieProp("subscription_plan") === "Miler Rectify (Free)";

  return (
    <div className="summary-list-header">
      <div className="d-flex justify-content-between mt-3 align-items-start">
        <div className="">
          {!showMap && !activeTab && (
            <InputMiler
              narrow
              type="search"
              value={searchedValue}
              placeholder="Search Shipment#"
              onChange={(e) => {
                setSearchedValue(e.target.value);
              }}
            />
          )}
        </div>
        <div className="d-flex align-items-start">
          {!activeTab && (
            <ListAndMapToggle showMap={showMap} setShowMap={setShowMap} />
          )}
          {!useAsUtil && !isFleetOwner && !activeTab && (
            <MilerButton
              title="New Shipment"
              icon="mil-plus"
              btnDark
              targetActionDenied={!hasPermission("create.shipment")}
              onClick={() => setNewShipment(true)}
            />
          )}
        </div>
      </div>

      <ShipmentsFilter setRequest={setRequest} request={request} />

      <SliderModal clickState={newShipment} setClickState={() => {}}>
        <NewShippment
          setClickState={setNewShipment}
          getShipments={getShipments}
        />
      </SliderModal>
    </div>
  );
};

export default ShipmentHeader;
