import { useContext, useEffect, useState } from "react";
import {
  CarrierChargeContext,
  CarrierChargeProvider,
} from "./carrierChargeContext/CarrierChargeContext";
import CarrierChargeSidebar from "./carrierChargeSidebar/CarrierChargeSidebar";
import CarrierChargeDisplay from "./carrierChargeDisplay/CarrierChargeDisplay";
import { ToolBarContext } from "layout/toolbar/context/ToolbarContext";
import { getElements } from "services/milerApi";

const CarrierChargesWrapper = () => {
  return (
    <CarrierChargeProvider>
      <CarrierCharges />
    </CarrierChargeProvider>
  );
};

const CarrierCharges = () => {
  const { dispatch: toolbarDispatch } = useContext(ToolBarContext);
  const { state, dispatch } = useContext(CarrierChargeContext);
  const tDate = new Date(Date.now());
  const [date, setDate] = useState(tDate.toISOString().split("T")[0]);
  const [shipmentContent, setShipmentContent] = useState(false);
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    getElements(`/Carrier/Charges`, "2.0")
      .then((res) => {
        dispatch({ type: "carrierCharges", payload: res.data });
        return res;
      })
      .then((response) => {
        toolbarDispatch({
          type: "setCurrent",
          payload: {
            current: "Carrier Charges",
            count: response.data.length,
          },
        });
      });
  }, [date, state.try]);
  return (
    <div className="d-flex activities">
      <CarrierChargeSidebar
        date={date}
        setDate={setDate}
        setStatus={setStatus}
      />
      <div className="flex-grow-1">
        {state.carrierCharges[0] && (
          <CarrierChargeDisplay
            shipmentContent={shipmentContent}
            setShipmentContent={setShipmentContent}
            date={date}
            status={status}
            setStatus={setStatus}
          />
        )}
      </div>
    </div>
  );
};

export default CarrierChargesWrapper;
