import { useState, useRef, useContext, useReducer } from "react";
import { appContext } from "services/AppContext";
import { postElement } from "services/milerApi";
import CustomerForm from "../customerForm/CustomerForm";
import customerReducer from "../customerForm/customerReducer";
import { customer } from "./initialCustomer";

import MilerButton from "components/milerButton/MilerButton";
import { toast } from "react-toastify";
import removeUnfilledObjs from "../customerForm/RemoveUnfilledObjs";
import { ErrorBoundary } from "components/errorBoundary/ErrorBoundary";
import { postGatewayApi } from "services/gatewayApis";
import { hasPermission } from "utils/hasPermission";

const NewCustomer = ({ setNewCustomerOpen, customerName, fxn }) => {
  const { enums } = useContext(appContext);
  const [errors, setFormErrors] = useState([]);
  const [profile, setProfile] = useState(null);
  const hiddenFileInput = useRef(null);

  const [state, dispatch] = useReducer(customerReducer, {
    ...customer,
    shipperName: customerName || "",
  });

  const post = (e) => {
    e.preventDefault();
    let modState = removeUnfilledObjs(state);
    postElement(`/Shipper`, modState).then((response) => {
      if (response.success) {
        setNewCustomerOpen(false);
        fxn({ id: response.data, label: modState.shipperName });
      } else if (response.vErrors) {
        setFormErrors(response.vErrors);
      }
    });
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const types = ["image/png", "image/jpeg"];
    const formFile = new FormData();
    if (fileUploaded && types.includes(fileUploaded.type)) {
      formFile.append("ProfileImg", fileUploaded);

      postGatewayApi("", formFile)
        .then((res) => {
          setProfile({ ...profile, profile: res.data });
          toast.success("Successfully uploaded Image");
        })
        .catch(() => setProfile(null));
    }
  };

  const handleRemove = () => {
    setProfile(null);
  };

  return (
    state !== null && (
      <div className="slider-display">
        <div className="slider-display-header">
          <div className="slider-header-title">New Customer</div>
          <div className="slider-header-buttons">
            <MilerButton
              title="Cancel"
              onClick={() => {
                localStorage.removeItem("draftCustomer");
                setNewCustomerOpen(false);
              }}
            />
            <MilerButton
              btnDark
              title="Save"
              onClick={post}
              targetActionDenied={!hasPermission("create.customer")}
            />
          </div>
        </div>
        <ErrorBoundary message="Create Customer">
          <div className="slider-display-body">
            <CustomerForm
              state={state}
              dispatch={dispatch}
              enums={enums}
              errors={errors}
              handleClick={handleClick}
              handleChange={handleChange}
              handleRemove={handleRemove}
              hiddenFileInput={hiddenFileInput}
              profile={profile}
            />
          </div>
        </ErrorBoundary>
      </div>
    )
  );
};

export default NewCustomer;
