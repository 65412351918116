import { useState, useEffect } from "react";
import { Autocomplete } from "@react-google-maps/api";
import InputMiler from "../InputMiler";
import joinStrings from "utils/joinStrings";

const AddressSearcher = ({
  address,
  error,
  dispatch,
  required,
  label,
  full,
  disabled,
  hideLabel = false,
}) => {
  label = label ? label : "Address";
  const addressToString = (addressObj) => {
    const { addressLine, city, state, countryCode } = { ...addressObj };
    return `${addressLine ? addressLine + ", " : ""}${city ? city + ", " : ""}${
      state ? state + ", " : ""
    }${countryCode ? countryCode.toUpperCase() : ""}`;
  };
  const [addressTemp, setAddressTemp] = useState();
  // eslint-disable-next-line
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(addressToString(address));
  }, [address]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    setInputValue(value);
  };

  const handleSelect = () => {
    let newAddress;

    let addr = addressTemp.getPlace();
    let raw_address = addr.address_components;

    let processAddress = (searchable) => {
      let foundItem;

      for (let i = 0; i < searchable.length; i++) {
        foundItem = raw_address.find((x) => x.types.includes(searchable[i]));
        if (foundItem) break;
      }

      return foundItem ? foundItem.short_name : "";
    };
    newAddress = {
      addressLine:
        joinStrings(
          [processAddress(["street_number"]), processAddress(["route"])],
          " "
        ) || "No street",
      city:
        processAddress([
          "locality",
          "administrative_area_level_3",
          "sublocality",
        ]) || "No city",
      state: processAddress(["administrative_area_level_1"]),
      postalCode: processAddress(["postal_code"]),
      countryCode: processAddress(["country"]).toLowerCase(),
      timezoneOffset: addr.utc_offset_minutes / 60,
      latitude: addr.geometry.location.lat(),
      longitude: addr.geometry.location.lng(),
    };
    newAddress.fullAddress = joinStrings(
      [
        newAddress.addressLine,
        newAddress.city,
        newAddress.countryCode,
        newAddress.postalCode,
      ],
      ", "
    );
    dispatch(newAddress);
  };

  return (
    <div className={full ? "address_search_full" : "searcher address-searcher"}>
      <Autocomplete
        onLoad={(addrTemp) => setAddressTemp(addrTemp)}
        onPlaceChanged={handleSelect}
      >
        <InputMiler
          type="search"
          onChange={handleInputChange}
          value={inputValue}
          label={!hideLabel ? label : ""}
          error={error}
          required={required}
          disabled={disabled}
        />
      </Autocomplete>
    </div>
  );
};
export default AddressSearcher;
