import { Card } from "reactstrap";
import ProfilePlaceholder from "components/profilePlaceholder/ProfilePlaceholder";
import "./ServiceProvider.sass";
const ServiceProviderComponent = ({ serviceProvider }) => {
  return (
    <>
      <Card className="overview-driver-customer-card ship-overview-card service_provider_card">
        <div className="mb-2">
          <span className="card_title">{"Service Provider Detail" ?? ""}</span>
        </div>
        <div className="main-content">
          {serviceProvider?.serviceProviderId ? (
            <div className="main-content__sub">
              <div className="image-card ">
                <ProfilePlaceholder
                  jointName={serviceProvider?.serviceProviderName || ""}
                />
              </div>
              <div>
                <div>
                  <span
                    className="secondary-title__text"
                    onClick={() => {
                      setOpenShipper(true);
                    }}
                  >
                    {serviceProvider?.serviceProviderName || ""}
                    <br />
                  </span>
                </div>
                {/* <div>
                <div className="iconNtext">
                  <i className="mil-user-o"></i>
                  <span className="secondary-title__text">
                  {serviceProvider?.serviceProviderName || ""}
                   </span>
                </div>
              </div> */}
                <div>
                  <div className="iconNtext">
                    <i className="mil-phone-o"></i>
                    <span className="secondary-title__text">
                      {serviceProvider?.phone || ""}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="iconNtext">
                    <i className="mil-email-o"></i>
                    <span className="secondary-title__text">
                      {serviceProvider?.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="pt-5 text-center">
                <h4>NO ASSOCIATED SERVICE PROVIDER</h4>
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default ServiceProviderComponent;
