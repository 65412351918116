import { AiFillCar} from "react-icons/ai";

const K_WIDTH = 40;
const K_HEIGHT = 40;

const customMarkerStyle = {
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
  border: "5px solid #efa324",
  borderRadius: K_HEIGHT,
  backgroundColor: "white",
  textAlign: "center",
  color: "#efa324",
  fontSize: 16,
  fontWeight: "bold",
  padding: 4,
};

const CustomMarker = () => {
  return (
    <div style={customMarkerStyle}>
      {/*{props.text}*/}
      <AiFillCar />
    </div>
  );
};

export default CustomMarker;
