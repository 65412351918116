import AddressSearcher from "components/inputMiler/advanceSearch/AddressSearceher";
import InputMiler from "components/inputMiler/InputMiler";
import MilerButton from "components/milerButton/MilerButton";
import { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { Label } from "reactstrap";
import { appContext } from "services/AppContext";
import { getElements, postElement } from "services/milerApi";
import { Toast } from "services/Toast";
import findError from "utils/findError";
import { COLUMNS } from "./CustomerResData";
import { hasPermission } from "utils/hasPermission";

const AddCustomerPortal = ({
  setAddCustomerPortal,
  shipperDetail,
  getCustomers,
}) => {
  const defaultShipperDetail = {
    customerName: shipperDetail.shipperName,
    usdot: shipperDetail.usdot,
    billingAddress: shipperDetail.billingAddress,
    billingContact: shipperDetail.billingContact,
  };

  const { enums } = useContext(appContext);
  const [state, setState] = useState(defaultShipperDetail);
  const [errors, setErrors] = useState([]);
  const [usdotNumber, setUsdotNumber] = useState("");
  const [mcNumber, setMcNumber] = useState("");
  const [inputError, setInputError] = useState("");
  const [customersFound, setCustomersFound] = useState(null);
  const [showSeachForm, setShowSeachForm] = useState(true);

  const onChange = ({ category, name, value }) => {
    if (category) {
      let categoryValue = state[category] || {};
      categoryValue = { ...categoryValue, [name]: value };
      setState({ ...state, [category]: categoryValue });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  let toastCode;
  const searchCustomer = () => {
    if (usdotNumber || mcNumber) {
      setInputError("");
      if (!toastCode) {
        toastCode = Toast.loading("Searching");
      }

      getElements(
        `/Carrier/Customer/Activation/Search/?dot_number=${usdotNumber}&mc_number=${mcNumber}`
      )
        .then((response) => {
          const customers = response.data?.map((carrier) => {
            return {
              ...carrier,
              action: (
                <MilerButton
                  btnDark
                  title="Add"
                  onClick={() => {
                    setShowSeachForm(false);
                    setState({ ...carrier, customerName: carrier.company });
                    delete state.company;
                  }}
                  targetActionDenied={!hasPermission("create.fleet")}
                />
              ),
            };
          });
          setCustomersFound(customers);
          Toast.close("", toastCode);
        })
        .catch(() => {
          Toast.close("", toastCode);
        });
    } else {
      setInputError("USDOT Number or MC Number is required");
    }
  };

  const inviteCustomer = () => {
    setErrors([]);
    postElement(`/Shipper/${shipperDetail.shipperId}/Portal`, state).then(
      (response) => {
        if (response.success) {
          getCustomers();
          setAddCustomerPortal(false, true);
        } else {
          setErrors(response.vErrors);
        }
      }
    );
  };

  return (
    <div className="slider-display">
      <div className="slider-display-header">
        <div className="slider-header-title">Enable Customer Portal</div>
        <div className="slider-header-buttons">
          <MilerButton
            title="Cancel"
            onClick={() => setAddCustomerPortal(false)}
          />
          <MilerButton btnDark title="Save" onClick={() => inviteCustomer()} />
        </div>
      </div>
      <div className="slider-display-body">
        {showSeachForm && (
          <>
            <div className="miler-card mt-0">
              <div className="row ">
                <div className="col col-md-3">
                  <InputMiler
                    label="USDOT Number"
                    type="text"
                    value={usdotNumber}
                    required
                    onChange={(e) => {
                      setCustomersFound(null);
                      setUsdotNumber(e.target.value);
                    }}
                    error={inputError}
                    maxLength={8}
                  />
                </div>
                <div className="col col-md-1 mt-4">OR</div>

                <div className="col col-md-3">
                  <InputMiler
                    label="MC Number"
                    type="text"
                    value={mcNumber}
                    required
                    onChange={(e) => {
                      setCustomersFound(null);
                      setMcNumber(e.target.value);
                    }}
                    error={inputError}
                    maxLength={8}
                  />
                </div>

                <div className="col-12 col-md-4 mt-4">
                  <MilerButton
                    btnDark
                    title="Search Customer"
                    onClick={() => searchCustomer()}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {!state.brokerId && (
          <>
            {customersFound?.length > 0 && (
              <>
                <Label>Search Results</Label>
                <div className="summary-list">
                  <DataTable
                    columns={COLUMNS}
                    data={customersFound}
                    responsive
                  />
                </div>
              </>
            )}

            {customersFound && customersFound.length == 0 ? (
              <div className="miler-card d-flex flex-column align-items-center justify-content-center">
                <Label className="mt-2">No results found.</Label>
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        {state.brokerId && (
          <>
            <div className="miler-card mt-0">
              <div className="row ">
                <div className="col-12 col-md-6">
                  <InputMiler
                    label="USDOT Number"
                    type="text"
                    value={state.usdot || ""}
                    required
                    error={findError("USDOT", errors)}
                    onChange={(e) =>
                      onChange({ name: "usdot", value: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label="MC Number"
                    type="text"
                    value={state.mcNumber || ""}
                    required
                    error={findError("McNumber", errors)}
                    onChange={(e) =>
                      onChange({ name: "mcNumber", value: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label="Customer Name"
                    type="text"
                    required
                    value={state.customerName || ""}
                    error={findError("CustomerName", errors)}
                    onChange={(e) =>
                      onChange({ name: "customerName", value: e.target.value })
                    }
                  />
                </div>

                <div className="col-12 col-md-6 col-xl-6">
                  <InputMiler
                    type="select"
                    label="Customer Type"
                    options={enums.CustomerType}
                    value={state.customerType || ""}
                    onChange={(e) => {
                      onChange({
                        name: "customerType",
                        value: e.target.value,
                      });
                    }}
                    required
                    error={findError("Setting.InvoiceDeliveryType")}
                  />
                </div>

                <div className="col-12 col-md-12">
                  <AddressSearcher
                    label="Billing Address"
                    required
                    error={findError("BillingAddress", errors)}
                    address={state.billingAddress}
                    dispatch={(address) => {
                      onChange({ name: "billingAddress", value: address });
                    }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label={"City"}
                    disabled
                    value={state.billingAddress?.city || ""}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label={"State"}
                    disabled
                    value={state.billingAddress?.state || ""}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label={"Postal Code"}
                    disabled
                    value={state.billingAddress?.postalCode || ""}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label={"Country"}
                    disabled
                    value={state.billingAddress?.countryCode || ""}
                  />
                </div>
              </div>
            </div>

            <div
              className="miler-card mt-0"
              style={{
                border: findError("BillingContact", errors)
                  ? "1px solid red"
                  : "",
              }}
            >
              <h6>Contact</h6>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputMiler
                    label="First Name"
                    type="text"
                    required
                    error={findError("BillingContact.FirstName", errors)}
                    value={state.billingContact?.firstName || ""}
                    onChange={(e) =>
                      onChange({
                        category: "billingContact",
                        name: "firstName",
                        value: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label="Last Name"
                    type="text"
                    required
                    value={state.billingContact?.lastName || ""}
                    error={findError("BillingContact.LastName", errors)}
                    onChange={(e) =>
                      onChange({
                        category: "billingContact",
                        name: "lastName",
                        value: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label="Email"
                    type="text"
                    required
                    error={findError("BillingContact.Email", errors)}
                    value={state.billingContact?.email || ""}
                    onChange={(e) =>
                      onChange({
                        category: "billingContact",
                        name: "email",
                        value: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-12 col-md-6">
                  <InputMiler
                    label="Phone Number"
                    type="text"
                    required
                    value={state.billingContact?.phone || ""}
                    error={findError("BillingContact.Phone", errors)}
                    onChange={(e) =>
                      onChange({
                        category: "billingContact",
                        name: "phone",
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCustomerPortal;
