import AssociatedNotes from "components/associatedNotes/AssociatedNotes";
import { useEffect, useState } from "react";
import { putElement } from "services/milerApi";

const Notes = ({ stop, handleFetchData }) => {
  useEffect(() => {
    const updatedNotes = stop.stopNotes.map(({ stopNoteType, ...rest }) => ({
      ...rest,
      noteType: stopNoteType,
    }));

    setStopNotes(updatedNotes);
  }, []);

  const [stopNotes, setStopNotes] = useState([]);

  const deleteNote = (note, index) => {
    note.splice(index, 1);
    setStopNotes([...note]);
    postNote();
    return;
  };

  const postNote = () => {
      putElement(`/Shipment/Trip/Stop/${stop.stopId}/Notes`, stopNotes).then(
        (response) => {
          if (response) {
            handleFetchData();
          }
        }
      );
  };

  return (
    <div>
      <AssociatedNotes
        notes={stopNotes || []}
        onAddNote={(note) => {
          const notes = stopNotes || [];
          notes.unshift(note);
          postNote();
          setStopNotes(notes);
        }}
        loadingNotes={false}
        hideSelectOption={true}
        deleteNote={deleteNote}
      />
    </div>
  );
};

export default Notes;
