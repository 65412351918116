import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MilerButton from "components/milerButton/MilerButton";

const UndoTripModal = ({ open, closeModal, undoTrip, title }) => {
  const onSubmit = () => {
    closeModal(false);
    undoTrip();
  };
  return (
    <Modal isOpen={Boolean(open)} toggle={() => closeModal(false)}>
      <ModalHeader toggle={() => closeModal(false)}>
        {title || "Undo Trip"}
      </ModalHeader>
      <ModalBody>
        <div>
          Undo will irreversibly rollback this shipment back to Available
          status. Are you sure you want to do this?
        </div>
      </ModalBody>
      <ModalFooter>
        <MilerButton title={title || "Undo Trip"} btnDark onClick={onSubmit} />
      </ModalFooter>
    </Modal>
  );
};

export default UndoTripModal;
