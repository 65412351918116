import RepairSummary from "./RepairSummary";
import ServiceProviderComponent from "./ServiceProvider";
import RepairSummaryComponent from "./RepairSummaryComponet";
import "./OverviewTab.sass";

const OverViewTab = ({ state, repairItems }) => {
  return (
    <section>
      <section
        style={{
          display: "flex",
          width: "100%",
          gap: "1rem",
        }}
      >
        <section
          style={{
            width: "50%",
          }}
        >
          <ServiceProviderComponent serviceProvider={state?.serviceProvider} />
        </section>
        <section
          style={{
            width: "50%",
          }}
        >
          <RepairSummaryComponent state={state} />
        </section>
      </section>
      <div className="order_reapir_summary_container">
        <div className="content">
          <p className="title"> Repair Order Items</p>
          <RepairSummary repairItems={repairItems} />
        </div>
      </div>
    </section>
  );
};

export default OverViewTab;
